import React from 'react'

const ProfitLossItemList = ({ data, index }) => {
  return (
    <tr key={data?.instrument_token}>
      <td>
        <div className="d-flex align-items-center ">{index + 1}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.trading_symbol}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.profit_loss_amount}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.profit_loss_type}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.mis_cnc}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.instrument_token}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.added_date}</div>
      </td>
    </tr>
  )
}

export default ProfitLossItemList
