import React, { useEffect, useRef, useState } from 'react'
import watch from '../../../fantasy/fantasyAssets/img/watch.svg'

const TimeCard = ({ start_date }) => {
  // start_date
  // start_date  = time to convert
  //  stocks = boolean 
  const [timeLeft, setTimeLeft] = useState('')
  const [hrs, setHrs] = useState('')
  const timerRef = useRef(null)

  function timeCount(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    setHrs(`${formattedHours}:${formattedMinutes} ${ampm}`)
    return `${formattedHours}:${formattedMinutes} ${ampm}`;

  }


  const getRemainingDays = (date) => {
    const targetDate = new Date(date)
    // const targetDate = new Date("Mon Oct 9 2023 05: 30:00 GMT +0530(India Standard Time)")

    const now = new Date();
    const difference = targetDate - now;

    const days = Math.abs(Math.floor(difference / (1000 * 60 * 60 * 24)));
    const hours = Math.abs(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    const minutes = Math.abs(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)));
    const seconds = Math.abs(Math.floor((difference % (1000 * 60)) / 1000));
    const timeDiffString = `${days}d:${hours}h:${minutes}m:${seconds}s`;
    // console.log({ targetDate, now, days, hours, minutes, seconds })
    timeCount(targetDate)
    setTimeLeft(timeDiffString)
    // return 
  }

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
    timerRef.current = setInterval(() => {
      getRemainingDays(start_date)

    }, 500)
    return () => {
      clearInterval(timerRef.current)
    }
  }, [start_date])
  return (
    <div className="row mx-0">
      <div className="col-12">
        <div className="warningCard addcase card cursor-pointer border-0 rounded4 position-relative">
          <div class="card-body py-5">
            <div class="row mx-0 align-items-center stockTimer justify-content-center">
              <div class="col-12 ps-0 align-items-center justify-content-center d-flex">
                <div class=" watchIcon">
                  <img src={watch} alt='' />
                </div>
              </div>
              <div className="col-12 py-2 text-center">
                <div className="watchText">THE CONTEST WILL START IN</div>
              </div>
              <div className="col-12 text-center">
                <div className="watchTimer d-inline-block">{start_date ? timeLeft : "--:---"} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimeCard