import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { REPORTS, USER } from '../../AdminConstants/apiEndpoints'
import { errorToaster } from '../../../utils/toaster'
import { useSelector } from 'react-redux'
import { returnEndDate } from '../../../utils/returnEndDate'
import { adminGetAPIAuth } from '../../AdminServices'
import AccountStatementTab from './AccountStatementTab'
import BetListTab from './BetListTab'
import Balance from './Balance'
import { PORTAL, USER_MANAGEMENT_URL } from '../../AdminConstants/urlPaths'


const tabOptions = [
  {
    name: "Balance",
    key: 'balance'
  },
  {
    name: "Bet List",
    key: 'betList'
  },
  {
    name: "Account Statement",
    key: 'accountStatement'
  },
  // {
  //   name: "Transfer Statement",
  //   key: 'transferStatement'
  // },
  // {
  //   name: "Net Exposure",
  //   key: 'netExposure'
  // }
]

const UserDetail = () => {
  const adminData = useSelector(state => state.adminData)
  const [activeTab, setActiveTab] = useState('accountStatement')
  const navigate = useNavigate()
  const location = useLocation()
  const [hierarchy, setHierarchy] = useState([])
  const [username, setUsername] = useState('')

  useEffect(()=> {
    setHierarchy(location?.state?.breadcrumData ? location?.state?.breadcrumData : '')
    setUsername(location?.state?.username ? location?.state?.username : '')
  }, [location])


  console.log('location', hierarchy)
  
  return (
    <>
      <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner ">
                <header className="page-title-bar">
                    <div className="page-title pt-0">
                      <nav aria-label="breadcrumb text-nowrap">
                          <ol class="breadcrumb flex-nowrap overflow-auto pt-0 m-0">
                            <li class="breadcrumb-item cursor-pointer"
                              onClick={() => navigate(`/${PORTAL}/${adminData.role}/${USER_MANAGEMENT_URL}`)}
                            >
                              <div className="d-grid align-items-center">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M11.03 2.59a1.501 1.501 0 0 1 1.94 0l7.5 6.363a1.5 1.5 0 0 1 .53 1.144V19.5a1.5 1.5 0 0 1-1.5 1.5h-5.75a.75.75 0 0 1-.75-.75V14h-2v6.25a.75.75 0 0 1-.75.75H4.5A1.5 1.5 0 0 1 3 19.5v-9.403c0-.44.194-.859.53-1.144ZM12 3.734l-7.5 6.363V19.5h5v-6.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 .75.75v6.25h5v-9.403Z"></path></svg>
                              </div>
                            </li>
                            {hierarchy.length > 1 && hierarchy.map((item, index) => (
                            index === 0 ? '' : <li class="breadcrumb-item cursor-pointer"
                            onClick={
                              // index === hierarchy?.length - 1
                              //   ? () => {}
                              //   : 
                                () => navigate(`/${PORTAL}/${adminData.role}/${USER_MANAGEMENT_URL}`, {state: {level : item}})
                            }
                            > {item.username} </li>
                          ))}
                          <li class="breadcrumb-item cursor-pointer"> {username} </li>
                        </ol>
                      </nav>
                    </div>
                  </header>

                {/* <div className="sportsTabs">
                  {
                    tabOptions?.map((item) => (
                      <div
                        className={`sportTab ${activeTab === item?.key ? 'active' : ''}`}
                        onClick={() => setActiveTab(item?.key)}
                        key={item?.key}
                      >{item?.name}</div>
                    ))
                  }
                </div> */}
                <div className='col-12 px-0'>
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    {tabOptions?.map((item) => (<li class="nav-item" role="presentation" key={item?.key}>
                      <button class={`nav-link eventBtn ${activeTab === item?.key ? 'active' : ''}`} onClick={() => setActiveTab(item?.key)}>{item?.name}</button>
                    </li>))}
                  </ul>
                </div>
                <div>
                  {
                    activeTab == "accountStatement" ? <AccountStatementTab /> : 
                    activeTab === "betList" ? <BetListTab/> : 
                    activeTab === "balance" ? <Balance/> : <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default UserDetail