import { Modal } from "react-bootstrap";
import DepositForm from "../ActionForms/DepositForm/DepositForm";
import { CHANGE_STATUS, COMMISSION, DEPOSIT, RESET_USER_PASSWORD, SETTLEMENT, USER_DETAILS, WITHDRAW, SHARE_SETTLEMENT, USER_LOGS } from "../../AdminConstants/actionButtonsType";
import { MARKET_COMMISSION } from "../../AdminConstants/urlPaths";
import './actionModal.css'
import WithdrawForm from "../ActionForms/WithdrawForm/WithdrawForm";
import CommissionForm from "../ActionForms/CommissionForm/CommissionForm";
import SettlementsForm from "../ActionForms/SettlementsForm/SettlementsForm";
import ResetUserPassword from "../ActionForms/ResetUserPasswordForm/ResetUserPasswordForm";
import ChangeStatusForm from "../ActionForms/ChangeStatusForm/ChangeStatusForm";
import UserDetailsForm from "../ActionForms/UserDetailsForm/UserDetailsForm";
import ShareSettlementsForm from "../ActionForms/SettlementsForm/ShareSettlementsForm";
import UserLogsForm from "../UserLogsForm/UserLogsForm";
import MarketCatagoriesForm from "../MarketCatagoriesForm/MarketCatagoriesForm";

//eslint-disable-next-line
const ActionModal = ({ show, setShow, type, data, parentData, updateData }) => {
  // console.log("cheddk", data);
  return (
    <Modal show={show}
      onHide={() => setShow(false)}
      scrollable
      centered
      size={type === USER_LOGS ? 'xl' : 'md'}>
      <Modal.Header>
        <Modal.Title>{type}</Modal.Title>
        <span
          className="p-2 close-modal-btn"
          onClick={() => setShow(false)}
        >
          <i className="bi bi-x-lg"></i>
        </span>
      </Modal.Header>

      <Modal.Body>
        {type === DEPOSIT && <DepositForm setShow={setShow} data={data} parentData={parentData} updateData={updateData} />}
        {type === WITHDRAW && <WithdrawForm setShow={setShow} data={data} parentData={parentData} updateData={updateData} />}
        {type === COMMISSION && <CommissionForm setShow={setShow} data={data} parentData={parentData} updateData={updateData} />}
        {type === SHARE_SETTLEMENT && <ShareSettlementsForm setShow={setShow} data={data} parentData={parentData} updateData={updateData} />}
        {type === SHARE_SETTLEMENT && <ShareSettlementsForm setShow={setShow} data={data} parentData={parentData} updateData={updateData} />}
        {type === SETTLEMENT && <SettlementsForm setShow={setShow} data={data} parentData={parentData} updateData={updateData} />}
        {type === MARKET_COMMISSION && <MarketCatagoriesForm setShow={setShow} data={data} parentData={parentData} updateData={updateData} />}
        {type === RESET_USER_PASSWORD && <ResetUserPassword setShow={setShow} data={data} parentData={parentData} updateData={updateData} />}
        {type === CHANGE_STATUS && <ChangeStatusForm setShow={setShow} data={data} parentData={parentData} updateData={updateData} />}
        {type === USER_DETAILS && <UserDetailsForm setShow={setShow} data={data} parentData={parentData} updateData={updateData} />}
        {type === USER_LOGS && <UserLogsForm setShow={setShow} data={data} parentData={parentData} updateData={updateData} />}

      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ActionModal;
