import { useState,useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PasswordInput from "../../passwordInput/PasswordInput";
import { useSelector } from "react-redux";
import { succesToaster as successToaster } from "../../../../utils/toaster";
import { adminPostAPI, adminPostAPIAuth } from "../../../AdminServices";
import { USER } from "../../../AdminConstants/apiEndpoints";
import { ADMIN_AUTH_ACCESS } from "../../../../constants";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";

const WithdrawForm = ({ setShow, data, parentData,updateData }) => {
  const [formValues, setFormValues] = useState({
    fromUser: parentData?.username,
    currentPoints: parentData?.points,
    toUser: data?.username,
    toCurrentPoints: data?.points,
    points: "",
    creditReference: data?.totalPoints,
    remarks: "",
    password: "",
  });

  const [formText, setFormText] = useState({
    parentPoints: parentData?.points || 0,
    childPoints: data?.points || 0,
    childCreditReference: data?.totalPoints || 0,
  });
  const adminData = useSelector((state) => state.adminData);
  const [error, setError] = useState("");
  const adminToken=useLocalStorage(ADMIN_AUTH_ACCESS,"")

  const handleChange = (e) => {
    const nameArray = ["points", "creditReference"];

    if (nameArray.includes(e.target.name) && isNaN(Number(e.target.value))) {
      setFormValues((prev) => ({
        ...prev,
        [e.target.name]: formValues[e.target.name],
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  useEffect(() => {
    if (!isNaN(Number(formValues.points))) {
      setFormText((prev) => ({
        ...prev,
        parentPoints: parentData?.points + Number(formValues.points),
        childPoints: data?.points - Number(formValues.points),
        childCreditReference:data?.totalPoints - Number(formValues.points)
      }));

      if (Number(formValues.points > data?.points)) {
        setError(`Points should be less than ${data?.username}'s Curr. Points`)
      }else{
        setError("")
      }
    }
    //eslint-disable-next-line
  }, [formValues.points]);

  const handleClick = (e) => {
    e.preventDefault();
    if (
      formValues.remarks === "" ||
      formValues.points === "" ||
      formValues.password === ""
    ) {
      setError("Please fill all the fields ");
    } else {
      handleApiCall();
    }
  };

  const handleApiCall = async () => {
    const loginCred = {
      username: adminData?.username,
      password: formValues.password,
    };
    setError("");
    try {
      const res = await adminPostAPI(USER.login, loginCred, true);
      if (res?.data?.data?.success) {
        adminToken.updateValue(res?.data?.data.token)
        withdrawApi();
      }
    } catch (error) {
      setError(error?.response?.data?.data?.message);
    }
  };

  const withdrawApi = async () => {
    const payload = {
      userId: data?._id,
      transferedBy: parentData?._id,
      type: "debit",
      points: Number(formValues.points),
      desc: formValues.remarks,
    };
    try {
      const res = await adminPostAPIAuth(USER.transferPoints, payload);
      if (res.status === 200) {
        successToaster("Updated Successfully!");
        setError("");
        setShow(false);
        updateData()
      }
    } catch (error) {
      setError(error?.response?.data?.data?.message);
    }
  };

  return (
    <Form>
      <Container>

      <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="toUser">From User</Form.Label>
              <Form.Control
                type="text"
                id="toUser"
                name="toUser"
                value={formValues.toUser}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="toCurrentPoints">Curr Points</Form.Label>
              <Form.Control
                type="text"
                id="toCurrentPoints"
                name="toCurrentPoints"
                value={formValues.toCurrentPoints}
                onChange={handleChange}
                disabled
              />
              <Form.Text 
              className={
                  formText.childPoints < 0
                    ? "admin-forms-error-text"
                    : "admin-forms-success-text"
                }>
                {formText.childPoints}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="fromUser">To User</Form.Label>
              <Form.Control
                type="text"
                id="fromUser"
                name="fromUser"
                value={formValues.fromUser}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="currentPoints">Curr Points</Form.Label>
              <Form.Control
                type="text"
                id="currentPoints"
                name="currentPoints"
                value={formValues.currentPoints}
                onChange={handleChange}
                disabled
              />
              <Form.Text
                className={
                  formText.parentPoints < 0
                    ? "admin-forms-error-text"
                    : "admin-forms-success-text"
                }
              >
                {formText.parentPoints}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        

        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="points">Points</Form.Label>
              <Form.Control
                type="text"
                id="points"
                name="points"
                value={formValues.points}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="creditReference">
                Credit Reference
              </Form.Label>
              <Form.Control
                type="text"
                id="creditReference"
                name="creditReference"
                value={formValues.creditReference}
                onChange={handleChange}
                disabled
              />
              <Form.Text 
              className={
                  formText.childCreditReference < 0
                    ? "admin-forms-error-text"
                    : "admin-forms-success-text"
                }>
                {formText.childCreditReference}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="remarks">Remarks</Form.Label>
              <Form.Control
                type="text"
                id="remarks"
                name="remarks"
                value={formValues.remarks}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Label htmlFor="password">Password</Form.Label>
            <PasswordInput
              id="password"
              name="password"
              value={formValues.password}
              onChange={handleChange}
            />
          </Col>
        </Row>

        {/* admin-forms-error-text has css in userManagement.css  */}
        {error && (
          <Row className="mb-2 admin-forms-error-text">
            <Col>
              <div>{error}</div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Button type="submit" onClick={handleClick} disabled={Number(formValues.points) > data?.points} >
              Withdraw
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default WithdrawForm;
