import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useBtAuth } from "../../../hooks/useBtAuth";
import { useAuth } from "../../../hooks/useAuth";
import { useSignInModal } from "../../../hooks/useSignInModal";
import { postAPI } from "../../../service/apiInstance";
import { succesToaster, errorToaster } from "../../../utils/toaster";
import { useAuthModal } from "../../../hooks/useAuthModal";
import { useSelector } from "react-redux";
import { USER_LANG } from "../../../constants";
import { Spinner } from "react-bootstrap";
const LoginOtp = ({ setLogOn, setPhoneOtp, phoneOtp, emailOrNumber }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpLoader, setOtpLoader] = useState(false);
  const inputRefs = useRef([]);
  const { setBtToken } = useBtAuth();
  const { setToken } = useAuth();
  const { authModalObject, setAuthModalObject } = useAuthModal();
  const ipAddress = useSelector((state) => state.ipAddress);
  const country = useSelector((state) => state.country);
  const country_id = localStorage.getItem("country_Id");
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (!authModalObject.isAuthOpen) {
      setPhoneOtp(false);
      setLogOn(true);
    }
  }, [authModalObject.isAuthOpen]);

  useEffect(() => {
    inputRefs.current[0].focus()
  }, [])

  const handleOtpChange = (e, index) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = e.target.value;
    setOtp(updatedOtp);

    if (e.target.value !== "" && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && event.target.value === "") {
      inputRefs.current[index - 1].focus();
    }
  };
  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleVerifyOtp = (event) => {
    const otpValue = otp.join("");
    if (otpValue.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        ?.confirm(otpValue)
        .then((result) => {
          let user = result.user;
          const lang = localStorage.getItem(USER_LANG)
          const body = {
            phone: emailOrNumber,
            ipAddress: ipAddress ? ipAddress : "",
            country: country ? country : "",
            language: lang,
            phoneCodeId: country_id,
          };
          postAPI("user/login-user", body)
            .then((res) => {
              if (res?.data?.success) {
                succesToaster(res.data.message);
                setBtToken(res?.data.data?.betByToken);
                setToken(res?.data.data?.auth_token);
                setLogOn(true);
                setPhoneOtp(false);
                setOtpLoader(false);
                setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));
              } else {
                errorToaster(res.data.message);
                setOtpLoader(false);
              }
            })
            .catch((err) => {
              errorToaster("something went wrong");
              setOtpLoader(false);
            });
          setOtp(["", "", "", "", "", ""]);
          setIsLoading(false);
        })
        .catch((error) => {
          setLogOn(true);
          setPhoneOtp(false);
          setOtp(["", "", "", "", "", ""]);
          errorToaster("User couldn't sign in (bad verification code?)");
          setIsLoading(false);
        })

    }

  };
  useEffect(() => {
    if (!phoneOtp) {
      setIsLoading(false)
    }
  }, [phoneOtp])
  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      <div className="row gx-0">
        <div className="col-12 inputdata">
          <label htmlFor="text" className="inputLabel">
            Enter your OTP
            <span>*</span>
          </label>
        </div>
        <div className="col-12 inputdata d-flex">
          {otp.map((digit, index) => (
            <input
              type="text"
              className="form-control inputlogin otpInp border-0 shadow-none w-25 mx-2 "
              key={index}
              maxLength={1}
              value={digit}
              onChange={(e) => handleOtpChange(e, index)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const otpValue = otp.join("");
                  if (otpValue.length !== 6) {
                    errorToaster("OTP Field can't be empty")
                    return
                  }
                  setIsLoading(true)
                  handleVerifyOtp(event)
                }
                handleKeyDown(event, index)
              }
              }
              ref={(ref) => (inputRefs.current[index] = ref)}
            />
          ))}
        </div>
      </div>
      <div className="row mt-4 gap-4">
        <div className="col-12 mx-auto">
          <button
            onClick={(e) => {
              setIsLoading(true)
              handleVerifyOtp(e)
            }}

            className="playBtn border-0 position-relative d-flex align-items-center justify-content-center cursor-pointer w-100"
          >
            <div className="playBtnInner d-flex align-items-center justify-content-center w-100">
              {
                isLoading ? <Spinner size="sm" color="dark" /> : " Play now!"
              }
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginOtp;
