import React, { useEffect, useState } from "react";
import LoginEmailOrNo from "./LoginEmailOrNo";
import LoginPassword from "./LoginPassword";
import LoginOtp from "./LoginOtp";
import LoginForgotPassword from "./LoginForgotPassword";
import { useAuthModal } from "../../../hooks/useAuthModal";
import { AUTH_MODAL_TABS } from "../../../constants";

const LoginTab = ({ track }) => {
    const [forgetpass, setForgetpass] = useState(false);
    const [logOn, setLogOn] = useState(true);
    const [emailPassword, setEmailPassword] = useState(false);
    const [phoneOtp, setPhoneOtp] = useState(false);
    const [emailOrNumber, setEmailOrNumber] = useState('')
    const { authModalObject } = useAuthModal()

    useEffect(() => {
        if (authModalObject.selectedTab === AUTH_MODAL_TABS.LOG_IN) {
            setLogOn(true)
            setForgetpass(false)
            setEmailPassword(false)
            setPhoneOtp(false)
            setForgetpass(false)
        }
    }, [track])

    return (
        <div
            className=" fade show active"
        >
            {
                logOn ? (
                    <LoginEmailOrNo
                        setLogOn={setLogOn}
                        setEmailPassword={setEmailPassword}
                        setPhoneOtp={setPhoneOtp}
                        setEmailOrNumber={setEmailOrNumber}
                        setForgetpass={setForgetpass}
                        logOn={logOn}
                    />
                ) : (
                    <></>
                )
            }
            {
                emailPassword ? (
                    <LoginPassword
                        setLogOn={setLogOn}
                        setEmailPassword={setEmailPassword}
                        emailOrNumber={emailOrNumber}
                        setForgetpass={setForgetpass}
                        emailPassword={emailPassword}
                    />
                ) : (
                    <></>
                )
            }
            {
                phoneOtp ? (
                    <LoginOtp
                        setLogOn={setLogOn}
                        setPhoneOtp={setPhoneOtp}
                        phoneOtp={phoneOtp}
                        emailOrNumber={emailOrNumber}
                    />
                ) : (
                    <></>
                )
            }
            {
                forgetpass ? (
                    <LoginForgotPassword
                        setForgetpass={setForgetpass}
                        setLogOn={setLogOn}
                        forgetpass={forgetpass}
                    />
                ) : (
                    <></>
                )
            }

        </div>
    )
};

export default LoginTab;
