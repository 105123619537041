export const filterItem = [
    {
      id: 1,
      name: 'Entry',
      value: [
        { id: 1, label: '₹ 1 to ₹ 50', min: 1, max: 50, type: 'Entry' },
        { id: 2, label: '₹ 51 to ₹ 100', min: 51, max: 100, type: 'Entry' },
        { id: 3, label: '₹ 101 to ₹ 1,000', min: 101, max: 1000, type: 'Entry' },
        {
          id: 4,
          label: '₹ 1,001 & above',
          min: 1001,
          max: 'above',
          type: 'Entry',
        },
      ],
    },
    {
      id: 2,
      name: 'Number of Teams',
      value: [
        { id: 1, label: '2', min: 2, max: 2, type: 'Number of Teams' },
        { id: 2, label: '3 - 10', min: 3, max: 10, type: 'Number of Teams' },
        { id: 3, label: '11 - 100', min: 11, max: 100, type: 'Number of Teams' },
        {
          id: 4,
          label: '101 - 1,000',
          min: 101,
          max: 1000,
          type: 'Number of Teams',
        },
        {
          id: 5,
          label: '1,001 & above',
          min: 1001,
          max: 'above',
          type: 'Number of Teams',
        },
      ],
    },
    {
      id: 3,
      name: 'Prize Pool',
      value: [
        {
          id: 1,
          label: '₹ 1 - ₹ 10,000',
          min: 1,
          max: 10000,
          type: 'Prize Pool',
        },
        {
          id: 2,
          label: '₹ 10,001 - ₹ 1 Lakh',
          min: 10001,
          max: 100000,
          type: 'Prize Pool',
        },
        {
          id: 3,
          label: '₹ 1 Lakh - ₹ 10 Lakh',
          min: 100001,
          max: 1000000,
          type: 'Prize Pool',
        },
        {
          id: 4,
          label: '₹ 10 Lakh to ₹ 25 Lakh',
          min: 1000001,
          max: 2500000,
          type: 'Prize Pool',
        },
        {
          id: 5,
          label: '₹ 25 Lakh & above',
          min: 2500001,
          max: 'above',
          type: 'Prize Pool',
        },
      ],
    },
    {
      id: 4,
      name: 'Contest Type',
      value: [
        { id: 1, label: 'Single Entry', type: 'Contest Type' },
        { id: 2, label: 'Multi Entry', type: 'Contest Type' },
        { id: 3, label: 'Single Winner', type: 'Contest Type' },
        { id: 4, label: 'Multi Winner', type: 'Contest Type' },
        { id: 5, label: 'Confirmed', type: 'Contest Type' },
      ],
    },
  ];