import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

const BetBanner = () => {
  return (
    <>
      <div className="betBanner">
        <Swiper className="swiper h-100"
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          modules={[Autoplay,]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}>
            <div className="swiper-wrapper h-100">
              <SwiperSlide>
                  <img className='h-100 w-100 object-fit-cover' style={{objectPosition: 'top' }} src="assets/img/betBanner/betBanner1.jpg" alt="" />
              </SwiperSlide>
            <SwiperSlide>
                  <img className='h-100 w-100 object-fit-cover' style={{objectPosition: 'top' }} src="assets/img/betBanner/betBanner5.jpg" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                  <img className='h-100 w-100 object-fit-cover' style={{objectPosition: 'top' }} src="assets/img/betBanner/betBanner6.jpeg" alt="" />
              </SwiperSlide>
              {/* <SwiperSlide>
                  <img className='h-100 w-100 object-fit-cover' style={{objectPosition: 'top' }} src="assets/img/betBanner/betBanner3.webp" alt="" />
              </SwiperSlide> */}
              
            </div>
        </Swiper>
    </div>
    </>
  )
}

export default BetBanner