import React from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import { useState } from 'react'
import MyRank from './MyRank'
import PlayerStats from './PlayerStats'
import PriceBreakup from './PriceBreakup'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUsableBalance } from '../../fantasyComponents/commonFunctions'
import MatchCard from '../../fantasyComponents/matchCard/MatchCard'
import { getFantasyAuth } from '../../fantasyServices'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { useEffect } from 'react'

const LeaderBoard = () => {
    const [activeTab, setActiveTab] = useState('myRank')
    const { state } = useLocation()
    const navigate = useNavigate()
    const [selectedPlayers, setselectedPlayers] = React.useState([]);
    const [modalBalance, setModalBalance] = React.useState();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [contestData, setcontestData] = useState('');

console.log('stateeeeeeeee', state)
    // Function to handle View team
    const handleViewTeam = async data => {
        getFantasyAuth(
            `/viewteam?matchkey=${state.el.matchkey}&jointeamid=${data.jointeamid}&teamnumber=${data.teamnumber}`,
        ).then(resp => {
            let params = {}
            if (!state.joinedMatches) {
                params = {
                    myteam: true,
                    players: resp.data.data,
                    data: state.data,
                    contest: state.el,
                    edit: data.teamnumber,
                    live: state.live,
                    playerData: { player: state.data, jointeamid: data.jointeamid },
                }
            } else {
                params = {
                    myteam: true,
                    players: resp.data.data,
                    data: state.data,
                    contest: state.contest,
                    joined: data,
                    noEdit: true,
                }
            }
            navigate(ROUTES_CONST.VIEW_TEAM, params);
        })
    };

    // Function to get contest data
    const getContest = async () => {
        const res = await getFantasyAuth(
            `/getContest?matchchallengeid=${state.el.matchchallengeid}`,
        );
        setcontestData(res.data.data);
    };

    useEffect(() => {
        getContest()
    }, [])

    // // Function for team Selecting
    // const TeamSelection = (elem) => {
    //     selectingTeam(navigate, match, elem, contests, setSelectionData)
    // }

    // // Function for updating state data
    // const setSelectionData = async (players, contest) => {
    //     setselectedPlayers(players);
    //     setselectedContest(contest);
    //     let balanceData = await getUsableBalance(contest);
    //     setModalBalance(balanceData)
    //     setModalVisible(true)
    // }

    console.log(state, "...")

    return (
        <>
            <div className="row fantasyInner">
                <FantasyHeader gameHeader={true} title={'LeaderBoard'} />
                <div className="col-12 px-0 flex-fill leaderBoardPage" data-page-name="indexPage">
                    <div className="row mx-0 h-100 flex-column flex-nowrap createTeam">
                        <div className="col-12 px-0 cricketheader z-2">
                            <div className="card border-0 matchesCard overflow-hidden border border-1 border-light rounded-0">
                                <div className="card-body p-0">
                                    <MatchCard
                                        key={state?.data?.id}
                                        matchName={state?.data?.name ? state?.data?.name : state?.data?.matchname}
                                        series_name={state?.data?.seriesname ? state?.data?.seriesname : state?.data?.series_name}
                                        team1ShortName={state?.data?.team1name ? state?.data?.team1name : state?.data?.team1ShortName}
                                        team2ShortName={state?.data?.team2name ? state?.data?.team2name : state?.data?.team2ShortName}
                                        status={state?.data?.status}
                                        match={state?.match}
                                        final_status={state?.data?.final_status}
                                        winning_status={state?.data?.winnerstatus}
                                        team1logo={state?.data?.team1logo}
                                        team2logo={state?.data?.team2logo}
                                        start_date={state?.data?.start_date}
                                        playing11_status={state?.data?.playing11_status}
                                        teamfullname1={state?.data?.teamfullname1 ? state?.data?.teamfullname1 : state?.data?.team1fullname}
                                        teamfullname2={state?.data?.teamfullname2 ? state?.data?.teamfullname2 : state?.data?.team2fullname}
                                        time_start={state?.data?.time_start}
                                        id={state?.data?.id ? state?.data?.id : state?.data?.matchkey}
                                        leaderBoard={true}
                                    />
                                    <div className="innerBody firstMatchBody p-2 pb-0 px-0">
                                        <div className="row align-items-center mx-0">

                                            <div className="col-12 px-0 cricketheader z-1">
                                                <div className="row mx-0 align-items-center justify-content-between contestOptionsTabInner">
                                                    <div className="col-4">
                                                        <div
                                                            onClick={() => setActiveTab('prizeBreakup')}
                                                            className={`contestOptionTab text-center text-uppercase ${activeTab === 'prizeBreakup' ? 'active' : ''}`}>Prize Breakup</div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div
                                                            onClick={() => setActiveTab('myRank')}
                                                            className={`contestOptionTab text-center text-uppercase ${activeTab === 'myRank' ? 'active' : ''}`}>My Rank</div>
                                                    </div>
                                                    {/* <div className="col-4">
                                                        <div
                                                            onClick={() => setActiveTab('playerStats')}
                                                            className={`contestOptionTab text-center text-uppercase ${activeTab === 'playerStats' ? 'active' : ''}`}>Player Stats</div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            activeTab === 'prizeBreakup' ? <PriceBreakup data={contestData} /> :
                                activeTab === 'myRank' ?
                                    <MyRank
                                        handleViewTeam={handleViewTeam}
                                        joinedMatches={state.joinedMatches}
                                        live={state.live}
                                        data={state.el}
                                        item={state.data} /> : ''

                        }
                    </div>
                </div>
                <FantasyBottomBar />
            </div>
        </>
    )
}

export default LeaderBoard