import React, { useEffect, useState } from "react";
import { adminPostAPIAuthFormData } from "../../AdminServices";
import { errorToaster, succesToaster } from "../../../utils/toaster";

const UpdateQrDetails = ({ item, getPaymentDetails }) => {

    const initialValue = { type: item.type, displayName: item.displayName, status: item.status }
    const [uTableData, setUTableData] = useState(initialValue)
    const [file, setFile] = useState("")
    const [validationError, setValidationError] = useState(false)

    useEffect(() =>{
        setUTableData({ type: item.type, displayName: item.displayName, status: item.status })
    },[item])

    const handleChange = (e) => {
        const { name, value } = e.target
        setUTableData({ ...uTableData, [name]: value })
    }

    const updatePaymentDetails = async(e) => {
        e.preventDefault()
        if (uTableData.displayName === '' || uTableData.status === '') {
            setValidationError(true)
        } else {
            try {
                const formData = new FormData()
                formData.append('typename', 'userPaymentDetails')
                formData.append('flag', uTableData.type)
                formData.append('displayName', uTableData.displayName)
                formData.append('status', uTableData.status)
                {file !== '' && formData.append('image', file)}
                formData.append('id', item._id)
                const res = await adminPostAPIAuthFormData('user/updateUserPaymentDetails', formData)
                if (res?.data?.data?.message) {
                    setUTableData(res?.data?.data?.data)
                    getPaymentDetails()
                    succesToaster('QR Details Successfully Updated')
                    setValidationError(false)
                }
            } catch (error) {
                errorToaster(error?.response?.data?.message)
            }
        }
    }

    return (
        <>
            <form onSubmit={updatePaymentDetails}>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                        Type
                        <span className="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        name="type"
                        value={uTableData.type}
                        onChange={handleChange}
                    />
                </div>
                <h6>Editable Details</h6>
                <div className="mt-4 d-flex gap-2">
                    <div>
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Display Name
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            name="displayName"
                            value={uTableData.displayName}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            QR Image
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="file"
                            className="form-control"
                            id="exampleInputEmail1"
                            name="image"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                        {file === '' && <div className="mt-1">
                            Selected Image : <img src={item.image} alt="" style={{width: '38px', height: '38px', paddingLeft: '5px'}}/>
                        </div>}
                    </div>
                </div>
                <div className="mt-4 d-flex gap-2">
                    <div>
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Status
                            <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            name="status"
                            value={uTableData.status}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className={`mt-2 ${validationError === true ? (uTableData.displayName === '' || uTableData.status === '') ? 'd-block' : 'd-none' : 'd-none' }`}>
                    <span style={{color: 'red'}}>Fill all the fields</span>
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary mt-4" 
                    data-bs-dismiss={`${(uTableData.displayName === '' || uTableData.status === '') ? '' : "offcanvas"}`}
                    aria-label={`${(uTableData.displayName === '' || uTableData.status === '') ? '' : "Close"}`}
                >
                    Submit
                </button>
            </form>

        </>
    );
};

export default UpdateQrDetails;
