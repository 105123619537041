import React, { useState } from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import playerIcon from './../../fantasyAssets/img/ban.png'
import WicketKeepers from './WicketKeepers'
import BatsMan from './BatsMan'
import AllRounders from './AllRounders'
import Bowlers from './Bowlers'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { getFantasyAuth } from '../../fantasyServices'
import { useEffect } from 'react'
import { TeamConstants } from '../../../constants/createTeamConstants'
import { errorToaster } from '../../../utils/toaster'
import { useDispatch, useSelector } from 'react-redux'
import { playerConditionsAction, selectTeamsAction } from '../../../store/action'

const CreateTeam = () => {
  const data = useLocation()
  const [selPlayers, setSelPlayers] = useState([]);
  const [capVice, setcapVice] = useState({ captain: '', viceCaptain: '' });
  const [keepers, setKeepers] = useState([]);
  const [batsman, setBatsman] = useState([]);
  const [bowlers, setBowlers] = useState([]);
  const [allRounders, setAllRounders] = useState([]);
  const [loading, setloading] = useState(false);
  const [currentHeading, setCurrentHeading] = useState(
    TeamConstants.WK_HEADING,
  );
  // alert("hele")

  const [selectedRole, setSelectedRole] = useState(TeamConstants.WK);
  const getAllPlayersByRoles = {
    keeper: [],
    batsman: [],
    allrounder: [],
    bowler: [],
  };
  const [allPlayersByRoles, setAllPlayersByRoles] =
    useState(getAllPlayersByRoles);
  const getPlayerConditions = {
    keeper: {
      min: 1,
      max: 4,
      count: 0,
    },
    batsman: {
      min: 3,
      max: 6,
      count: 0,
    },
    allrounder: {
      min: 1,
      max: 4,
      count: 0,
    },
    bowler: {
      min: 3,
      max: 6,
      count: 0,
    },
  };
  // const [playerConditionsData, setPlayerConditions] = useState([]);
  const playerConditionsData = useSelector(state => state.playerConditionsData) || []
  const [credits, setCredits] = useState(100);
  const [teamData, setTeamData] = useState([])

  const navigate = useNavigate()
  const { matchKey } = useParams()
  const dispatch = useDispatch()
  const selectedTeams = useSelector(state => state.selectedTeams)
  useEffect(() => {
    if (playerConditionsData?.length === 0) {
      dispatch(playerConditionsAction(getPlayerConditions))
    }
  }, [playerConditionsData])
  // useEffect(() => {
  //   if (!!playerConditionsData?.length) {
  //     // alert("heello")
  //     setPlayerConditions(playerConditionsData)
  //   } else {
  //     setPlayerConditions(getPlayerConditions)
  //   }
  // }, [playerConditionsData])

  const selectTabs = selectRole => {
    setSelectedRole(selectRole);
    if (selectRole == TeamConstants.WK) {
      setCurrentHeading(TeamConstants.WK_HEADING);
    }

    if (selectRole == TeamConstants.BAT) {
      setCurrentHeading(TeamConstants.BAT_HEADING);
    }

    if (selectRole == TeamConstants.BOWL) {
      setCurrentHeading(TeamConstants.BALL_HEADING);
    }

    if (selectRole == TeamConstants.AR) {
      setCurrentHeading(TeamConstants.AR_HEADING);
    }
  };

  const getTeamData = async () => {
    try {
      let url = ''
      if (!!data?.state?.players) {
        url = `getallplayers/${matchKey}?teamId=${data?.state?.players?.jointeamid
          }`
      } else {
        url = `getallplayers/${matchKey}`
      }
      const res = await getFantasyAuth(url);
      if (res?.data?.success) {
        const { data: dataItem, ttlCridit } = res?.data;
        setCredits(100 - ttlCridit);
        setTeamData(dataItem)
        selectTabs(TeamConstants.WK);

        let selectPlyr = [];
        if (!!data?.state?.players) {
          setcapVice({
            captain: data?.state?.capVicePlr?.captain,
            viceCaptain: data?.state?.capVicePlr?.viceCaptain,
          });
          for (var plyr of dataItem) {
            if (plyr.isSelectedPlayer) {
              getPlayerConditions[plyr.role].count++;
              selectPlyr.push(plyr);
            } else {
              plyr.disabled = true;
            }
          }
          setSelPlayers(selectPlyr);
          // setPlayerConditions(getPlayerConditions);
          dispatch(playerConditionsAction(getPlayerConditions))

        }
        await filterPlayers(dataItem);
      }
    } catch (error) {

    } finally {
      setloading(false);
    }
  };


  const filterPlayers = async data => {
    var getAllWicketKeepers = data.filter(function (plyr) {
      return plyr.role === 'keeper';
    });
    getAllPlayersByRoles.keeper = getAllWicketKeepers;
    setKeepers(getAllWicketKeepers);
    var getAllBatsman = data.filter(function (plyr) {
      return plyr.role === 'batsman';
    });
    getAllPlayersByRoles.batsman = getAllBatsman;
    setBatsman(getAllBatsman);
    var getAllRounders = data.filter(function (plyr) {
      return plyr.role === 'allrounder';
    });
    getAllPlayersByRoles.allrounder = getAllRounders;
    setAllRounders(getAllRounders);
    var getAllBowlers = data.filter(function (plyr) {
      return plyr.role === 'bowler';
    });
    getAllPlayersByRoles.bowler = getAllBowlers;
    setBowlers(getAllBowlers);
    setAllPlayersByRoles(getAllPlayersByRoles);
  };
  // console.log({ playerConditionsData })
  const handleSelection = async playerDetails => {
    // console.log("playerDetails", playerDetails)
    // console.log("player in selPalers", { includes: selPlayers.includes(playerDetails), filter: !!selPlayers.filter(item => item.playerid === playerDetails.playerid).length, filterCount: selPlayers.filter(item => item.playerid === playerDetails.playerid) })
    // if (selPlayers.includes(playerDetails)) {
    if (!!selPlayers.filter(item => item.playerid === playerDetails.playerid).length) {
      let updated = selPlayers.filter(
        el => el.playerid != playerDetails.playerid,
      );
      setCredits(prev => prev + playerDetails.credit);
      if (playerConditionsData[playerDetails?.role]?.count) {
        playerConditionsData[playerDetails.role].count--;

      } else {
        // playerConditionsData[playerDetails.role].count = -1
        playerConditionsData[playerDetails.role].count--
      }
      // setPlayerConditions(playerConditionsData);
      dispatch(playerConditionsAction(playerConditionsData))
      // dispatch(playerConditionsAction(playerConditionsData))
      setSelPlayers(updated);
    } else {
      setCredits(prev => prev - playerDetails.credit);
      if (playerConditionsData[playerDetails.role].count) {
        // playerConditionsData[playerDetails.role].count--;
        playerConditionsData[playerDetails.role].count++;

      } else {
        // playerConditionsData[playerDetails.role].count = +1
        playerConditionsData[playerDetails.role].count++
      }

      // setPlayerConditions(playerConditionsData);
      dispatch(playerConditionsAction(playerConditionsData))
      setSelPlayers(prev => [...prev, playerDetails]);
    }
  };
  // console.log("selPlayers", selPlayers)
  useEffect(() => {
    if (selectedTeams) {
      setSelPlayers(selectedTeams)
    }
  }, [])

  useEffect(() => {
    dispatch(selectTeamsAction(selPlayers))
    // dispatch(playerConditionsAction(playerConditionsData))
  }, [selPlayers])

  const setDisabledPlayerLists = () => {
    // console.log("running setDisabledPlayerLists",allPlayersByRoles)
    const role = selectedRole;
    const getTeam1Length = selPlayers.filter(el => el?.team == 'team1').length;
    const getTeam2Length = selPlayers.filter(el => el?.team == 'team2').length;
    const getTotalPlayers = selPlayers.length;
    const getRemainingPlayers = 11 - getTotalPlayers;
    let playersList = { ...allPlayersByRoles };
    let minimumPlayersPending = 0;
    let minimumPlayersRole = [];
    for (var playerkey in playerConditionsData) {
      if (playerConditionsData[playerkey].count < playerConditionsData[playerkey].min) {
        minimumPlayersPending =
          minimumPlayersPending +
          (playerConditionsData[playerkey].min - playerConditionsData[playerkey].count);
        minimumPlayersRole.push(playerkey);
      }
    }

    playersList[role].forEach(function (item, key) {
      if (!selPlayers.includes(item)) {
        if (getTotalPlayers >= 11) {
          playersList[role][key].disabled = true;
          return;
        }
        if (
          playerConditionsData[role].count > playerConditionsData[role].min &&
          playerConditionsData[role].count >= playerConditionsData[role].max
        ) {
          playersList[role][key].disabled = true;
          return;
        } else if (
          (item?.team == 'team1' && getTeam1Length >= 7) ||
          (item?.team == 'team2' && getTeam2Length >= 7)
        ) {
          playersList[role][key].disabled = true;
          return;
        } else if (item?.credit > credits) {
          playersList[role][key].disabled = true;
          return;
        } else if (
          playerConditionsData[role].count >= playerConditionsData[role].min &&
          minimumPlayersPending >= getRemainingPlayers &&
          !minimumPlayersRole.includes(playerConditionsData[role])
        ) {
          playersList[role][key].disabled = true;
          return;
        } else {
          playersList[role][key].disabled = false;
          return;
        }
      }
    });
    setAllPlayersByRoles(playersList);
  };

  useEffect(() => {
    getTeamData()
  }, [])

  useEffect(() => {
    setDisabledPlayerLists();
  }, [selPlayers, selectedRole,selectedTeams]);

  // console.log({ playerConditionsData, TeamConstants, WK_count: playerConditionsData[TeamConstants.WK]?.count, playerConditionsData })
  return (
    <>
      <div className="row fantasyInner">
        <FantasyHeader gameHeader={true} title={'Create Team'} />
        <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
          <div className="row createTeam mx-0">
            <div className="col-12 px-0">
              <div className="playersCard">
                <div className="plyerHeading text-center mb-3">Max 7 Players from a team</div>
                <div className="row align-items-center">
                  <div className="col-12 d-flex align-items-center justify-content-between px-3">
                    <div className="teamHeading ">{data?.state?.data?.teamfullname1}</div>
                    <div className="teamHeading  text-end">{data?.state?.data?.teamfullname2}</div>
                  </div>
                  <div className="col">
                    <div className="temCard d-flex align-items-center justify-content-between">
                      <div className="teamIcon"> <img src={data?.state?.data?.team1logo} alt="bsk" className="img-fluid w-100 h-100" /></div>
                      <div className="teamName">{data?.state?.data?.team1name}</div>
                      <div className="teamPoint d-flex align-items-center justify-content-center">{selPlayers.filter(el => el?.team == 'team1').length}</div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="creaditScore text-center"> Credit Left</div>
                    <div className="creaditScore text-center"> {parseFloat(credits).toFixed(1)}</div>
                  </div>
                  <div className="col">
                    <div className="temCard d-flex align-items-center justify-content-between">
                      <div className="teamPoint d-flex align-items-center justify-content-center">{selPlayers.filter(el => el?.team == 'team2').length}</div>
                      <div className="teamName">{data?.state?.data?.team2name}</div>
                      <div className="teamIcon"> <img src={data?.state?.data?.team2logo} alt="bsk" className="img-fluid w-100 h-100" /></div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-12">
              <div className="wkCard">
                <div>
                  <ul className="d-flex gap-2 align-items-center justify-content-center list-unstyled mb-0">
                    {
                      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((el) => (
                        <li><div className={`d-flex align-items-center dotCircle ${selPlayers.length >= el ? 'active' : ''}`}></div></li>
                      ))
                    }
                  </ul>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div className={`wkHeading ${selectedRole === TeamConstants.WK ? 'active' : ''}`}
                    onClick={() => setSelectedRole(TeamConstants.WK)}>WK({playerConditionsData[TeamConstants.WK]?.count})</div>
                  <div className={`wkHeading ${selectedRole === TeamConstants.BAT ? 'active' : ''}`} p
                    onClick={() => setSelectedRole(TeamConstants.BAT)}>BAT({playerConditionsData[TeamConstants.BAT]?.count})</div>
                  <div className={`wkHeading ${selectedRole === TeamConstants.AR ? 'active' : ''}`}
                    onClick={() => setSelectedRole(TeamConstants.AR)}>AR({playerConditionsData[TeamConstants.AR]?.count})</div>
                  <div className={`wkHeading ${selectedRole === TeamConstants.BOWL ? 'active' : ''}`}
                    onClick={() => setSelectedRole(TeamConstants.BOWL)}>BOWL({playerConditionsData[TeamConstants.BOWL]?.count})</div>
                </div>
              </div>
            </div>
            {
              selectedRole === TeamConstants.WK ? <WicketKeepers selPlayers={selPlayers} handleSelection={handleSelection} playerList={allPlayersByRoles[selectedRole]} /> :
                selectedRole === TeamConstants.BAT ? <BatsMan selPlayers={selPlayers} handleSelection={handleSelection} playerList={allPlayersByRoles[selectedRole]} /> :
                  selectedRole === TeamConstants.AR ? <AllRounders selPlayers={selPlayers} handleSelection={handleSelection} playerList={allPlayersByRoles[selectedRole]} /> :
                    selectedRole === TeamConstants.BOWL ? <Bowlers selPlayers={selPlayers} handleSelection={handleSelection} playerList={allPlayersByRoles[selectedRole]} /> : ''
            }
          </div>
        </div>
      </div>
      <div className="fixedBtns">

        <button
          className='createTeamBtn2'
          // disabled={!selPlayers?.length}
          onClick={() => {
            if (!selPlayers?.length) {
              errorToaster("Select one or more players to preview team")
              return
            }
            return navigate(ROUTES_CONST.VIEW_TEAM, {
              state: {
                players: selPlayers,
                myteam: false,
                data: data?.state?.data,
                credits: credits
              }
            })
          }}>Team Preview</button>
        <button className={`createTeamBtn2 ${selPlayers?.length == 11 ? 'active' : ''}`} onClick={() => {
          if (selPlayers?.length == 11) {
            navigate(ROUTES_CONST.SELECT_CAPTAIN_VICECAPTAIN, {
              state: {
                data: selPlayers,
                teamData: data?.state?.data,
                contest: data?.state?.contest,
                challenge: data?.state?.challenge,
                edit: data?.state?.edit,
                capVice: capVice,
                // credits: credits
              }
            })
          } else {
            errorToaster('Please select all players')
          }
        }}>Continue</button>
      </div>
    </>
  )
}

export default CreateTeam