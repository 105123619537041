import React, { useState } from 'react'
import Pagination from '../../Admincomponents/pagination/Pagination'
import { adminGetAPIAuth } from '../../AdminServices';
import { REPORTS, USER } from '../../AdminConstants/apiEndpoints';
import { useEffect } from 'react';
import { dateFormatter } from '../../AdminUtils/Utils';
import { errorToaster } from '../../../utils/toaster';
import { useSelector } from 'react-redux';
import { returnEndDate } from '../../../utils/returnEndDate';
import { returnStartDate } from '../../../utils/returnStartDate';

const transactionArr = [
  {
    value: '',
    label: "All",
  },
  {
    value: 'Deposit',
    label: "Deposit",
  },
  {
    value: 'Withdraw',
    label: "Withdraw",
  },
  {
    value: 'Settlement Deposit',
    label: "Settlement Deposit",
  },
  {
    value: 'Settlement Withdraw',
    label: "Settlement Withdraw",
  },
  // {
  //   value:'bet-settlements',
  //   label:"Bet Settlements",
  // },
  // {
  //   value:'rollback',
  //   label:"Rollback",
  // },
  // {
  //   value:'Voided',
  //   label:"voided",
  // },
]

const AccountStatement = () => {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [totalSum, setTotalSum] = useState([])

  const [childrenList, setChildrenList] = useState([]);
  const [searchUser, setSearchUser] = useState("")
  const [selectedUser, setSelectUser] = useState({})
  const [searchedUserResult, setSearchedUserResult] = useState([])
  const [inputFocused, setInputFocused] = useState(false)
  const[userData, setUserData] = useState({});
  const [filterValues, setFilterValues] = useState({
    type: "",
    startDate: "",
    endDate: "",
  });
  const [dateSort, setDateSort] = useState('')
  const [pointSort, setPointSort] = useState('')
  const [openingBalSort, setOpeningBalSort] = useState('')
  const [clossingBalSort, setClossingBalSort] = useState('')


  const adminData = useSelector((state) => state?.adminData);

  // set today's date as end date
  useEffect(() => {
    const today = new Date()
    setFilterValues((prev) => ({
      ...prev,
      endDate: returnEndDate(today),
      // startDate: returnStartDate(today)
    }))
  }, [])

  const getChildrenList = async () => {
    try {
      const res = await adminGetAPIAuth(
        USER.children + "/" + adminData?._id + "?perPage=0",
      );
      if (res?.data?.status === 200) {
        setChildrenList(res?.data?.data?.data?.children);
      }
    } catch (error) {
      setChildrenList([]);
    }
  }

  useEffect(() => {
    if (adminData?._id) {
      getChildrenList()
    }
    getUserDetails();
    //eslint-disable-next-line
  }, [adminData])

  const handleChange = (e) => {
    setFilterValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  // const adminData = useSelector((state) => state?.adminData);
  console.log(adminData);
  const handleGetList = async () => {
    const queryParams = Object.entries(filterValues)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    try {
      const res = await adminGetAPIAuth(
        REPORTS.myaccountstatement + `?page=${currentPage}&perPage=10&dateSort=${dateSort}&pointSort=${pointSort}&openingBalSort=${openingBalSort}&clossingBalSort=${clossingBalSort}&username=${searchUser}` + (queryParams ? "&" + queryParams : "") + (selectedUser._id ? "&id=" + selectedUser._id : "")
      );
      console.log(res)
  if (res?.data?.data?.success) {
        setTableData(res?.data?.data?.data);
        setTotalPages(res?.data?.data?.pagination?.totalPages)
        setTotalSum(res?.data?.data?.totalSum)
      } else {
        errorToaster("Something went wrong in fetching data");
      }
    } catch (error) {


      errorToaster(error?.response?.data?.data?.message);
      setTableData([])
      console.log("error -------------->", error);
    }
  };

  useEffect(() => {
    handleGetList();
    //eslint-disable-next-line
  }, [
    filterValues,
    selectedUser,
    filterValues.type,
    filterValues.startDate,
    filterValues.endDate,
    currentPage,
    dateSort,
    pointSort,
    openingBalSort,
    clossingBalSort
  ]);

  useEffect(() => {
    if (searchUser) {
      const filtered = childrenList.filter((item) => item.name.toLowerCase().includes(searchUser.toLowerCase()) || item.username.toLowerCase().includes(searchUser.toLowerCase()))
      setSearchedUserResult(filtered)
    } else {
      setSearchedUserResult([])
      setSelectUser("")
    }
    //eslint-disable-next-line
  }, [searchUser])

  const handleInputBlur = () => {
    setTimeout(() => {
      setInputFocused(false)
    }, 100)
  }

  const handleDateSort = () => {
    if (dateSort === '-1') {
      setDateSort('1')
      setPointSort('')
      setOpeningBalSort('')
      setClossingBalSort('')
    } else {
      setDateSort('-1')
      setPointSort('')
      setOpeningBalSort('')
      setClossingBalSort('')
    }
  }

  const handlePointSort = () => {
    if (pointSort === '-1') {
      setPointSort('1')
      setDateSort('')
      setOpeningBalSort('')
      setClossingBalSort('')
    } else {
      setPointSort('-1')
      setDateSort('')
      setOpeningBalSort('')
      setClossingBalSort('')
    }
  }

  const handleOpenBalSort = () => {
    if (openingBalSort === '-1') {
      setOpeningBalSort('1')
      setDateSort('')
      setPointSort('')
      setClossingBalSort('')
    } else {
      setOpeningBalSort('-1')
      setDateSort('')
      setPointSort('')
      setClossingBalSort('')
    }
  }

  const handleCloseBalSort = () => {
    if (clossingBalSort === '-1') {
      setClossingBalSort('1')
      setDateSort('')
      setPointSort('')
      setOpeningBalSort('')
    } else {
      setClossingBalSort('-1')
      setDateSort('')
      setPointSort('')
      setOpeningBalSort('')
    }
  }


  const getUserDetails = async () => {
    try {
      const res = await adminGetAPIAuth(USER.profile + "/" + adminData._id)
      if (res?.data?.status === 200) {
        setUserData(res.data.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner ">

                <header className="page-title-bar justify-content-between row align-items-center">
                  <h1 className="page-title mb-0 text-nowrap"> My Account Statement </h1>
                </header>
                <div className="row g-3 mb-4">
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Opening Balance</div>
                          <div className="adminTopCardSubtitle">{userData?.openingPoints?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Total Points</div>
                          <div className="adminTopCardSubtitle">{userData?.totalPoints?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Total Cash</div>
                          <div className="adminTopCardSubtitle">{userData?.availablePoints?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Closing Balance</div>
                          <div className="adminTopCardSubtitle">{userData?.totalPoints?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row align-items-end">
                      <div className="col-md-auto pe-md-0 mb-2 mb-md-0">
                        <div className="adminSearchInput">
                          <input type="text" placeholder='Search User' name="searchUser" className='form-control adminFilterInp' value={searchUser} onChange={(e) => setSearchUser(e?.target?.value)} onFocus={() => setInputFocused(true)}
                            onBlur={handleInputBlur}
                          />
                          <div className='overflow-hidden'>
                            {(searchedUserResult?.length && inputFocused) ? <ul className='list-unstyled gap-2 adminsearchDropDown overflow-Y-auto'>
                              {searchedUserResult?.map((item) => (
                                <li className='userName' onClick={() => {
                                  setSearchUser(item.username)
                                  setSelectUser(item)
                                  setSearchedUserResult([])
                                  setCurrentPage(1)
                                }} >
                                  {item.username}
                                </li>
                              ))}
                            </ul> : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="row justify-content-end mx-md-0">
                          <div className="col-md-auto col-12 col-sm-4 pe-md-0 mb-2 mb-sm-0">
                            <label htmlFor="type" className="form-label">Transactions</label>
                            <select name="type" id="type" onChange={handleChange} className="form-select adminFilterInp">
                              {
                                transactionArr.map((item) => (
                                  <option value={item.value} >{item.label}</option>
                                ))
                              }
                            </select>
                          </div>
                          <div className="col-md-auto col-6 col-sm-4 pe-md-0">
                            <label htmlFor="startDate" className="form-label">From</label>
                            <input type="date" id="startDate" name="startDate" value={filterValues?.startDate} onChange={handleChange} className='form-control adminFilterInp' />
                          </div>
                          <div className="col-md-auto col-6 col-sm-4 pe-md-0">
                            <label htmlFor="endDate" className="form-label">To</label>
                            <input type="date" id="endDate" name="endDate" value={filterValues?.endDate} onChange={handleChange} className='form-control adminFilterInp' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table">
                        <thead className='text-uppercase text-nowrap'>
                          <tr>
                            <th>
                              <div className="d-flex cursor-pointer" onClick={handleDateSort}>
                                Date
                                <span className="ms-1">
                                  {dateSort === '-1' ?
                                    <i className="bi bi-arrow-down"></i>
                                    : <i className="bi bi-arrow-up"></i>}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                From & To
                              </div>
                            </th>
                            <th>
                              <div className="d-flex cursor-pointer" onClick={handleOpenBalSort}>
                                Opening Balance
                                <span className="ms-1">
                                  {openingBalSort === '-1' ?
                                    <i className="bi bi-sort-numeric-down"></i>
                                    : <i className="bi bi-sort-numeric-down-alt"></i>}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex cursor-pointer" onClick={handlePointSort}>
                                points
                                <span className="ms-1">
                                  {pointSort === '-1' ?
                                    <i className="bi bi-sort-numeric-down"></i>
                                    : <i className="bi bi-sort-numeric-down-alt"></i>}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                my profit
                              </div>
                            </th>
                            <th>
                              <div className="d-flex cursor-pointer" onClick={handleCloseBalSort}>
                                closing balance
                                <span className="ms-1">
                                  {clossingBalSort === '-1' ?
                                    <i className="bi bi-sort-numeric-down"></i>
                                    : <i className="bi bi-sort-numeric-down-alt"></i>}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                transaction
                              </div>
                            </th>
                          </tr>
                        </thead>
                        {tableData.length ?
                          <tbody>
                            {tableData.map((item) => (
                              <tr>
                                <td className='text-nowrap'>{dateFormatter(item.createdAt, true)}</td>
                                <td className='text-nowrap'>{item?.transferedByUser} {"->"} {item?.transferedToUser} </td>
                                <td>{item?.opningBal?.toFixed(2)}</td>
                                <td className={(item?.transactionTypeOfLoggedUser === "Withdraw") || (item?.transactionTypeOfLoggedUser === "Settlement Withdraw") ? "admin-forms-error-text" : "admin-forms-success-text"} >{item?.points?.toFixed(2)}</td>
                                <td>{item?.profitLossPoints}</td>
                                <td>{item?.clossingBal?.toFixed(2)}</td>
                                <td>{item?.transactionTypeOfLoggedUser}</td>
                              </tr>
                            ))}
                          </tbody>
                          : <tr>
                            <td colSpan={10} className="text-center" style={{ fontSize: 16, fontWeight: 500 }}>Account statement not found</td>
                          </tr>
                        }
                      </table>
                    </div>
                  </div>
                  {
                    totalPages > 1 ?
                      <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                      : ''
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default AccountStatement