import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PasswordInput from "../../passwordInput/PasswordInput";
import { USER } from "../../../AdminConstants/apiEndpoints";
import { useSelector } from "react-redux";
import { succesToaster as successToaster  } from "../../../../utils/toaster"
import { adminPostAPI,adminPostAPIAuth } from '../../../AdminServices';
import { ADMIN_AUTH_ACCESS } from "../../../../constants";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";

const ResetUserPassword = ({ setShow, data }) => {
  const [formValues, setFormValues] = useState({
    newPassword: "",
    confirmPassword: "",
    password: "",
  });

  const [ error,setError ]=useState("") 

  const adminData = useSelector((state) => state.adminData);
  const adminToken=useLocalStorage(ADMIN_AUTH_ACCESS,"")

  const handleChange = (e) => {
      setFormValues((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (formValues.confirmPassword==="" || formValues.newPassword==="" || formValues.password==="") {
      setError("Please fill all the fields")
    }else if (formValues.confirmPassword !== formValues.newPassword) {
      setError("Passwords should match")
    }else{
      handleApiCall();
    }
  };

  const handleApiCall = async () => {
    const loginCred = {
      username: adminData.username,
      password: formValues.password,
    };
    setError("")
    try {
      const res = await adminPostAPI(USER.login, loginCred, true);
      if (res?.data?.data?.success) {
        adminToken.updateValue(res?.data?.data.token)
        changePasswordApi();
      }
    } catch (error) {
        setError(error?.response?.data?.data?.message)
    }
  };

  const changePasswordApi = async () => {
    const payload = {
      newPass: formValues.newPassword,
      confirmPass: formValues.confirmPassword,
      userId: data?._id,
    };
    try {
      const res = await adminPostAPIAuth(USER.changePassword, payload, true);
      if (res.status === 200) {
        successToaster("Password Changed Successfully! ")
        setError("")
        setShow(false);
      }
    } catch (error) {
        setError(error?.response?.data?.data?.message)
    }
  };

  return (
    <Form>
      <Container>
        <Row>
          <Col>
            <Form.Label htmlFor="newPassword">Enter New Password</Form.Label>
            <PasswordInput
              id="newPassword"
              name="newPassword"
              value={formValues.newPassword}
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Label htmlFor="confirmPassword">Confirm Password</Form.Label>
            <PasswordInput
              id="confirmPassword"
              name="confirmPassword"
              value={formValues.confirmPassword}
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Label htmlFor="password">Password</Form.Label>
            <PasswordInput
              id="password"
              name="password"
              value={formValues.password}
              onChange={handleChange}
            />
          </Col>
        </Row>
        {/* admin-forms-error-text has css in userManagement.css  */}
        {error && <Row className="mb-2 admin-forms-error-text" >
          <Col><div>{error}</div></Col>
        </Row>}

        <Row>
          <Col>
            <Button type="submit" onClick={handleClick}>
              Confirm
            </Button>
          </Col>
        </Row>
        
      </Container>
    </Form>
  );
};

export default ResetUserPassword;
