import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Pagination from '../../Admincomponents/pagination/Pagination'
import reload from './../../AdminAssets/img/reload.svg'
import './style.css'
import { returnEndDate } from '../../../utils/returnEndDate'
import { useSelector } from 'react-redux'
import { adminGetAPIAuth } from '../../AdminServices'
import { REPORTS, USER } from '../../AdminConstants/apiEndpoints'
import { errorToaster } from '../../../utils/toaster'
import { dateFormatter } from '../../AdminUtils/Utils'

const CommissionReport = () => {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const [childrenList,setChildrenList]=useState([]);
  const [searchUser,setSearchUser]=useState("")
  const [selectedUser,setSelectUser]=useState({})
  const [searchedUserResult,setSearchedUserResult]=useState([])
  const [inputFocused,setInputFocused]=useState(false)
  const [tab, setTab] = useState('event-level')

  const [filterValues, setFilterValues] = useState({
    startDate: "",
    endDate: "",
  });

  const adminData = useSelector((state) => state?.adminData);

    // set today's date as end date
    useEffect(()=> {
      const today = new Date()
      setFilterValues((prev)=>({
        ...prev,
        endDate: returnEndDate(today),
        // startDate: returnStartDate(today)
      }))
    },[])

    const getChildrenList=async ()=>{
      try {
        const res = await adminGetAPIAuth(
          USER.children + "/" + adminData?._id + "?perPage=0",
        );
        if (res?.data?.status === 200) {
          setChildrenList(res?.data?.data?.data?.children);
        }
      } catch (error) {
        setChildrenList([]);
      }
    }

    useEffect(()=>{
      if (adminData?._id) {
        getChildrenList()
      }
      //eslint-disable-next-line
    },[adminData])

    const handleChange = (e) => {
      setFilterValues((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    };

    const handleGetList = async () => {
      const queryParams = Object.entries(filterValues)
        .filter(([key, value]) => value !== "")
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
      try {
        if (tab === 'event-level') {
          const res = await adminGetAPIAuth(
            REPORTS.eventCommissionReport + `?page=${currentPage}&perPage=10` + (queryParams?"&"+queryParams:"")
          );
          if (res?.data?.data?.success) {
            setTableData(res?.data?.data?.data);
            setTotalPages(res?.data?.data?.pagination?.totalPages)
          } else {
            errorToaster("Something went wrong in fetching data");
          }
        } else {
          const res = await adminGetAPIAuth(
            REPORTS.commissionReport + `?page=${currentPage}&perPage=10&username=${searchUser}` + (queryParams?"&"+queryParams:"")
          );
          if (res?.data?.data?.success) {
            setTableData(res?.data?.data?.data);
            setTotalPages(res?.data?.data?.pagination?.totalPages)
          } else {
            errorToaster("Something went wrong in fetching data");
          }
        }
      } catch (error) {
        errorToaster(error?.response?.data?.data?.message);
        setTableData([])
        console.log("error -------------->", error);
      }
    };

    useEffect(() => {
      handleGetList();
      //eslint-disable-next-line
    }, [
      filterValues,
      selectedUser,
      filterValues.startDate,
      filterValues.endDate,
      currentPage,
      tab
    ]);

    useEffect(()=>{
      if (searchUser) {
        const filtered=childrenList.filter((item)=>item.name.toLowerCase().includes(searchUser.toLowerCase()) || item.username.toLowerCase().includes(searchUser.toLowerCase()))
        setSearchedUserResult(filtered)
      }else{
        setSearchedUserResult([])
        setSelectUser("")
      }
      //eslint-disable-next-line
    },[searchUser])

    const handleInputBlur=()=>{
      setTimeout(()=>{
        setInputFocused(false)
      },100)
    }

  return (
    <>
    <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner ">
                
                <header className="page-title-bar justify-content-between row align-items-center">
                  <h1 className="page-title mb-0 text-nowrap"> Commission Report </h1>
                </header>
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row align-items-end">
                      {/* <div className="col-md-auto pe-md-0 mb-2 mb-md-0">
                        <div className="adminSearchInput">
                          <input type="text" placeholder='Search User' name="searchUser" className='form-control adminFilterInp' value={searchUser} onChange={(e)=>setSearchUser(e?.target?.value)} onFocus={()=>setInputFocused(true)} 
                          onBlur={handleInputBlur}
                           />
                          <div className='overflow-hidden'>
                          {(searchedUserResult?.length && inputFocused) ? <ul className='list-unstyled gap-2 adminsearchDropDown overflow-Y-auto'>
                            {searchedUserResult?.map((item)=>(
                              <li className='userName' onClick={()=>{
                                setSearchUser(item.username)
                                setSelectUser(item)
                                setSearchedUserResult([])
                                }} >
                              {item.username}
                            </li>
                            ))}
                          </ul>:""}
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md">
                        <div className="row justify-content-end mx-md-0">
                          {/* <div className="col-md-auto col-12 col-sm-4 pe-md-0 mb-2 mb-sm-0">
                            <label htmlFor="" className="form-label">Transactions</label>
                            <select name="" id="" className="form-select adminFilterInp">
                              <option value="">All</option>
                            </select>
                          </div> */}
                         <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="" className="form-label">From</label>
                            <input type="date" name='startDate' value={filterValues?.startDate} onChange={handleChange} className='form-control adminFilterInp' />
                          </div>
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="" className="form-label">To</label>
                            <input type="date" name='endDate' value={filterValues?.endDate} onChange={handleChange} className='form-control adminFilterInp' />
                          </div>
                          {/* <div className="col-md-auto col-6 d-flex align-items-end col-sm-6 pe-md-0">
                            <button type="button" class="mx-2 btn btn-primary"><img className='reloadImg' src={reload}/>Refresh</button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12'>
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link eventBtn active" onClick={() => setTab('event-level')} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Events Level</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link eventBtn" onClick={() => setTab('user-level')} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">User Level</button>
                    </li>
                  </ul>
                    <div class="tab-content" id="pills-tabContent">
                      <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                        <div className='row'>
                          <div className="col-12">
                            <div className="table-responsive">
                              <table className="table">
                                <thead className='text-uppercase text-nowrap'>
                                  <tr>
                                    <th>
                                      <div className="d-flex">
                                          event name
                                        <span className='ms-1'>
                                          {/* <i className="bi bi-sort-alpha-down"></i> */}
                                          {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                        </span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="d-flex">
                                        event date
                                        <span className='ms-1'>
                                          {/* <i className="bi bi-sort-alpha-down"></i> */}
                                          {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                        </span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="d-flex">
                                        commission 
                                        <span className='ms-1'>
                                          {/* <i className="bi bi-sort-alpha-down"></i> */}
                                          {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                        </span>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                {tableData?.length ?
                                  <tbody>
                                    {tableData.map((item)=>(
                                    <tr>
                                      <td>{item?.evnetName}</td>
                                      <td>{dateFormatter(
                                        item?.evnetDate,
                                        true
                                      )}</td>
                                      <td>{item?.totalCommission}</td>
                                    </tr>
                                    ))}
                                  </tbody>
                                  :
                                  <tbody>
                                    <tr>
                                      <td colSpan={3} className="text-center" style={{fontSize: 16, fontWeight: 500}}>No Data Available</td>
                                    </tr>
                                  </tbody>
                                }
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                      <div className='row'>
                          <div className="col-12">
                            <div className="table-responsive">
                              <table className="table">
                                <thead className='text-uppercase text-nowrap'>
                                  <tr>
                                    <th>
                                      <div className="d-flex">
                                          user
                                        <span className='ms-1'>
                                          {/* <i className="bi bi-sort-alpha-down"></i> */}
                                          {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                        </span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="d-flex">
                                        commission
                                        <span className='ms-1'>
                                          {/* <i className="bi bi-sort-alpha-down"></i> */}
                                          {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                        </span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="d-flex">
                                        upline 
                                        <span className='ms-1'>
                                          {/* <i className="bi bi-sort-alpha-down"></i> */}
                                          {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                        </span>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                {tableData?.length ?
                                  <tbody>
                                    {tableData.map((item)=>(
                                    <tr>
                                      <td>{item?.username}</td>
                                      <td>{item?.commissionData?.totalCommission}</td>
                                      <td>{item?.commissionData?.totalTurnOverCommission}</td>
                                    </tr>
                                    ))}
                                  </tbody>
                                  :
                                  <tbody>
                                    <tr>
                                      <td colSpan={3} className="text-center" style={{fontSize: 16, fontWeight: 500}}>No Data Available</td>
                                    </tr>
                                  </tbody>
                                }
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    totalPages > 1 ? 
                      <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        // handlePagination={() => getChildren(userData)}
                      />
                    : ''
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
    </div>
    </>
  )
}

export default CommissionReport