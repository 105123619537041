import React from 'react'
import { useState } from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import Contest from './Contest'
import Team from './Team'
import ScoreCard from './ScoreCard'
import { useLocation } from 'react-router-dom'
import { getFantasyAuth } from '../../fantasyServices'
import { useEffect } from 'react'

const MyMatchesDetail = () => {
  const [activeTab, setActiveTab] = useState('myContest')
  const { state } = useLocation()
  const [PlayerStatsData, setPlayerStatsData] = useState([]);
  const [myTeams, SetMyTeams] = useState([]);
  const [liveMatches, SetLiveMatches] = useState([]);
  const [Contests, SetContests] = useState([]);

  // Function to get contest
  const getContest = async () => {
    let res = await getFantasyAuth(`/myjoinedcontests?matchkey=${state?.match?.matchkey}`);
    SetContests(res?.data?.data);
    getPlayerStats();
  };

  //   Function to get live score
  const getLiveScore = async () => {
    let res = await getFantasyAuth(`/getlivescores?matchkey=${state?.match?.matchkey}`);
    SetLiveMatches(res?.data?.data);
    getContest();
  };

  //   Function to get playerStats
  const getPlayerStats = async () => {
    let res = await getFantasyAuth(
      `/getAllPlayersWith_PlayingStatus/${state?.match?.matchkey}`,
    );
    setPlayerStatsData(res?.data?.data);
    getMyTeams();
  };

  //   Function to get my teams data
  const getMyTeams = async () => {
    let res = await getFantasyAuth(`/getMyTeams?matchkey=${state?.match?.matchkey}`);
    SetMyTeams(res?.data?.data);
    // getLiveScore();
  };
  console.log(myTeams)

  // console.log(state)

  useEffect(() => {
    getLiveScore();
  }, []);

  return (
    <>
      <div className="row fantasyInner">
        <FantasyHeader gameHeader={true} title={'My Matches'} />
        <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
          <div className="row mx-0">
            <div className="col-12 px-0 createTeam matchDetailSec">
              <div className="playersCard">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="temCard d-flex align-items-center justify-content-start">
                      <div className="teamIcon"> <img src={state?.match?.team1logo} alt="bsk" className="img-fluid w-100 h-100" /></div>
                      <div className="teamName text-center ms-4">
                        <div>
                          <div className='text-light'>{state?.match?.team1ShortName}</div>
                          <div>{liveMatches?.Team1_Totalruns1}/
                            {liveMatches?.Team1_Totalwickets1} ({liveMatches?.Team1_Totalovers1})</div>
                          {liveMatches?.Team1_Totalovers2 != '0' ?
                            <div>{liveMatches?.Team1_Totalruns2}/
                              {liveMatches?.Team1_Totalwickets2} ({liveMatches?.Team1_Totalovers2})</div> : ''
                          }
                        </div>
                      </div>
                      {/* <div className="teamPoint d-flex align-items-center justify-content-center">0</div> */}
                    </div>
                  </div>
                  <div className="col">
                    <div className="temCard d-flex align-items-center justify-content-end">
                      {/* <div className="teamPoint d-flex align-items-center justify-content-center">0</div> */}
                      <div>
                        <div className='text-light'>{state?.match?.team2ShortName}</div>
                        <div className="teamName text-center me-4">
                          <div>{liveMatches?.Team2_Totalruns1}/
                            {liveMatches?.Team2_Totalwickets1} ({liveMatches?.Team2_Totalovers1})</div>
                        </div>
                        {
                          liveMatches?.Team2_Totalovers2 != '0' ? <div className="teamName text-center me-4">
                            <div>{liveMatches?.Team2_Totalruns2}/
                        {liveMatches?.Team2_Totalwickets2} ({liveMatches?.Team2_Totalovers2})</div>
                          </div> : ''
                        }
                      </div>
                      <div className="teamIcon"> <img src={state?.match?.team2logo} alt="bsk" className="img-fluid w-100 h-100" /></div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-12 contestOptionsTabInner matchDetailSec mb-3">
              <div className="row mx-0 align-items-center justify-content-between">
                <div
                  onClick={() => setActiveTab('myContest')}
                  className='col-4'>
                  <div className={`contestOptionTab text-center text-uppercase ${activeTab === 'myContest' ? 'active' : ''}`}>
                    My Contest ({Contests?.length})
                  </div>
                </div>
                <div
                  onClick={() => setActiveTab('myTeam')}
                  className='col-4'>
                  <div className={`contestOptionTab text-center text-uppercase ${activeTab === 'myTeam' ? 'active' : ''}`}>
                    My Team ({myTeams?.length})
                  </div>
                </div>
                <div
                  onClick={() => setActiveTab('scoreCard')}
                  className='col-4'>
                  <div className={`contestOptionTab text-center text-uppercase ${activeTab === 'scoreCard' ? 'active' : ''}`}>
                    Scorecard
                  </div>
                </div>
              </div>
            </div>
            {
              activeTab === 'myContest' ? <Contest Contests={Contests} match={state.match}  /> :
                activeTab === 'myTeam' ? <Team myTeams={myTeams} id={state.match.matchkey}  match={state.match} contests={Contests} /> :
                  activeTab === 'scoreCard' ? <ScoreCard matchKey={state?.match?.matchkey} /> : ''
            }
          </div>
        </div>
        <FantasyBottomBar />
      </div>
    </>
  )
}

export default MyMatchesDetail