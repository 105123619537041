import React, { useState, useRef, useEffect } from "react";
import "./otp.css"
import { postAPI } from "../../service/apiInstance";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { useBtAuth } from "../../hooks/useBtAuth";
import { useAuth } from "../../hooks/useAuth";
import { useSignUpModal } from "../../hooks/useSignUpModal";

const OtpInput = ({ setotpid, setActive }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const { btToken, setBtToken } = useBtAuth();
  const { token, setToken } = useAuth();
  const { isSignUpOpen, setIsSignUpOpen } = useSignUpModal();

  useEffect(() => {
    if (!isSignUpOpen) {
      setotpid(false)
    }
  }, [isSignUpOpen])

  const handleOtpChange = (e, index) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = e.target.value;
    setOtp(updatedOtp);

    if (e.target.value !== "" && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && index > 0 && event.target.value === '') {
      inputRefs.current[index - 1].focus();
    }
  };
  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  console.log('otpp', otp)

  const handleVerifyOtp = async (event) => {
    const otpValue = otp.join("");
    // Perform verification logic here
    // let otp = event.target.value;
    // setOtp(otp);

    if (otpValue.length === 6) {
      // verifu otp
      let confirmationResult = window.confirmationResult;
      confirmationResult?.confirm(otpValue).then((result) => {
        // User signed in successfully.
        let user = result?.user;
        console.log("user=====>>>>>>>>>>", user)

        //backend
        const body = {
          // email: userCredential._tokenResponse.email,
          // idToken: userCredential._tokenResponse.idToken,
          // localId: userCredential._tokenResponse.localId,
          // refreshToken: userCredential._tokenResponse.refreshToken,
          // password: values.password,
          // email: '',
          phone: user.phoneNumber,
          // refreshToken: "",
          // localId: "",
          // idToken: "",
          accessToken: user.accessToken,
          refreshToken: user.stsTokenManager.refreshToken
        }

        postAPI("user/register-user", body).then((res) => {
          console.log(res)
          if (res?.data?.success) {
            succesToaster(res.data.message);
            setBtToken(res?.data.data?.betByToken);
            setToken(res?.data.data?.auth_token);
            setotpid(false)
            setActive(true)
            setIsSignUpOpen(false)
          } else {
            errorToaster(res.data.message)
            setotpid(false)
            setActive(true)
          }
        }).catch((err) => {
          errorToaster("something went wrong")
        });
        setOtp(["", "", "", "", "", ""])
        // alert('User signed in successfully');
        // setIsSignUpOpen
      }).catch((error) => {
        setOtp(["", "", "", "", "", ""])
        errorToaster('User couldn\'t sign in (bad verification code?)');
      });
    }

    console.log("Verifying OTP:", otpValue);
  };

  return (
    <>
      <div className="otp-container col-auto mx-auto my-sm-3">
        {otp?.map((digit, index) => (
          <input
            key={index}
            type="text"
            maxLength={1}
            value={digit}
            onChange={(e) => handleOtpChange(e, index)}
            onKeyDown={(event) => handleKeyDown(event, index)}
            onKeyPress={handleKeyPress}
            ref={(ref) => (inputRefs.current[index] = ref)}
            className="otp-input mx-sm-3 mx-lg-1 mx-1"
          />
        ))}
      </div>
      {/* <div className=" d-flex justify-content-center">
        <button type='button' className="col-auto" onClick={handleVerifyOtp}></button>
      </div> */}
      <div className="col-12 mx-auto">
        <button
          type="button"
          className="btn ragisterBtn w-100 border-0"
          onClick={handleVerifyOtp}
        >
          Verify OTP
        </button>
      </div>
    </>
  );
};

export default OtpInput;
