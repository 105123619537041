import React from 'react'
import { useState } from 'react'

export const FantasyLoaderContext = React.createContext(null)

const LoaderContextProvider = ({children}) => {
  const [showFantasyLoader, setShowFantasyLoader] = useState(false)
  return <FantasyLoaderContext.Provider value={{showFantasyLoader, setShowFantasyLoader}}>{children}</FantasyLoaderContext.Provider>
}

export default LoaderContextProvider