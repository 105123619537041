import React from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import transaction from './../../fantasyAssets/img/transactionhistory.svg'
import withdraw from './../../fantasyAssets/img/withdrow.svg'
import depositt from './../../fantasyAssets/img/Deposit.svg'
import bonus from './../../fantasyAssets/img/bonus.svg'
import rytArrow from './../../fantasyAssets/img/rytChewan.png'
import addcash from './../../fantasyAssets/img/Addcase.svg'
import TrophyIcon from './../../fantasyAssets/img/trophyIcon.png'
import profileImg from './../../fantasyAssets/img/profile.jpg'
import editIcon from './../../fantasyAssets/img/editIcon.png'
import PlayIcon from './../../fantasyAssets/img/Play.svg'
import logout from './../../fantasyAssets/img/logout.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getFantasyAuth, postFantasyAuthFormData } from '../../fantasyServices'
import { fantasyAddUserData } from '../../../store/action'
import { Link } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { useLogoutModal } from '../../../hooks/useLogout'
import LogoutModal from '../../../components/logoutModal/LogoutModal'

const Profile = () => {
    const fantasyUserData = useSelector(state => state?.fantasyUserData)
    const dispatch = useDispatch() ;
    const { setIsLogoutOpen } = useLogoutModal()
    console.log('fantasyUserData', fantasyUserData)

    const handleProfileChange = async (e)=> {
        const fileObj = e.target.files && e.target.files[0]
        if(!fileObj) {
            return
        }
        const formData = new FormData()
        formData.append("typename", "uploadImage");
        formData.append('image', fileObj)

        const res = await postFantasyAuthFormData('imageUploadUser', formData)
        if(res?.data?.status) {
            getProfileData()
        }
    }

    

    const getProfileData = async () => {
        const res = await getFantasyAuth(`userFullDetails`);
        dispatch(fantasyAddUserData(res.data.data));
    };

    const logoutFunction = () => {
        setIsLogoutOpen(true)
      };

    return(
        <>
        <div className="row fantasyInner">
            <FantasyHeader gameHeader={true} title={'Profile'}/>
                <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                <div className="row mainCenterPart g-4 mx-0 py-4">
                    <div className='col-12'>
                        <div className='profileBack'>
                            <div className='profileImg position-relative'>
                                <img className='w-100 h-100' src={fantasyUserData?.image} alt=''/>
                                
                                <input class="form-control" onChange={handleProfileChange} type="file" id="file" hidden/>
                                <label for='file' className='editicon'>
                                    <img className=' ' src={editIcon} alt='' />
                                </label>
                            </div>
                        <div className='profileName'>{fantasyUserData?.name}</div>
                        </div>
                    </div>
                    {/* <div className="row mx-0 align-items-center">
                        <div className="col-auto"><div className="rounded-circle d-flex align-items-center justify-content-center rupees text-white fw-bold">₹</div></div>
                        <div className="col ps-0">
                            <div className="balance text-white">Total Balance</div>
                            <div className="balanceAmount text-white">₹ 100.00</div>
                        </div>
                    </div> */}
                {/* <div className="col-12">
                    <div className="warningCard card border-0">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={TrophyIcon} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <div className="balanceAmountSubTxt text-white ">Your Winnings</div>
                                    <div className="balanceSubTxt text-white">Withdraw Your Winnings</div>
                                </div>
                                <div className="col-auto pe-0"><div className="winningAmount balanceAmount">₹ 0.00</div></div>
                            </div>
                        </div>
                    </div>
                    <div className="withdrawAmount card border-0  pt-3 position-relative">
                        <a href="verifyAccount.html" className="py-4 pb-3 text-decoration-none balanceAmount text-center text-white">Withdraw Amount</a>
                    </div>
                </div> */}
                <div className="col-4">
                    <div className="dipositCard py-3 shadow p-2 text-center">
                        <div className="warningImg rounded-circle mx-auto mb-2"><img src={depositt} className="w-100" alt=""/></div>
                        <div className="balance depositText ">Deposited</div>
                        {/* <div className="subbalance ">Mony You Add</div> */}
                        <div className="subbalanceTwo text-danger">₹ {fantasyUserData?.totalbalance?fantasyUserData?.totalbalance:0}</div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="dipositCard py-3  shadow  p-2 text-center">
                        <div className="warningImg rounded-circle mx-auto mb-2"><img src={TrophyIcon} className="w-100" alt=""/></div>
                        <div className="balance depositText">Winnings</div>
                        {/* <div className="subbalance ">Offer Earned Cash Bonus</div> */}
                        <div className="subbalanceTwo fw-bold text-success">₹ {fantasyUserData?.totalwon?fantasyUserData?.totalwon:0}</div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="dipositCard py-3  shadow  p-2 text-center">
                        <div className="warningImg rounded-circle mx-auto mb-2"><img src={bonus} className="w-100" alt=""/></div>
                        <div className="balance depositText">Bonus</div>
                        {/* <div className="subbalance ">Offer Earned Cash Bonus</div> */}
                        <div className="subbalanceTwo fw-bold text-success">₹ {fantasyUserData?.totalbonus?fantasyUserData?.totalbonus:0}</div>
                    </div>
                </div>
                <div className="col-12">
                    <Link to={ROUTES_CONST.FANTASY_DEPOSIT_CASH} className="warningCard addcase card border-0 rounded-4 position-relative">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={addcash} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <div className="balanceAmount balanceAmountTxt stretched-link ">Add Cash</div>
                                    <div className="balance text-white">UPI,Credit Card,Debit Card & more</div>
                                </div>
                                <div className='col-auto'><img className='rytArrow' src={rytArrow} alt=''/></div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12"> 
                    <Link to={ROUTES_CONST.FANTASY_TRANSACTION} className="warningCard card border-0 rounded-4 position-relative">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={transaction} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <div className="balanceAmount balanceAmountTxt stretched-link ">Transaction</div>
                                </div>
                                <div className='col-auto'><img className='rytArrow' src={rytArrow} alt='' /></div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12">
                    <Link to={ROUTES_CONST.FANTASY_VERIFY_ACCOUNT} className="warningCard card border-0 rounded-4 position-relative">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={withdraw} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <div className="balanceAmount balanceAmountTxt stretched-link">Withdraw</div>
                                </div>
                                <div className='col-auto'><img className='rytArrow' src={rytArrow} alt='' /></div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12">
                    <Link to={ROUTES_CONST.FANTASY_PLAY_INSIGHT_PROFILE} className="warningCard card border-0 rounded-4 position-relative">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={PlayIcon} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <div className="balanceAmount balanceAmountTxt stretched-link">Play Insights</div>
                                </div>
                                <div className='col-auto'><img className='rytArrow' src={rytArrow} alt='' /></div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12">
                    <div className="warningCard card border-0 rounded-4 position-relative" onClick={logoutFunction}>
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={logout} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <div className="balanceAmount balanceAmountTxt stretched-link">Log Out</div>
                                </div>
                                <div className='col-auto'><img className='rytArrow' src={rytArrow} alt=''/></div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>  
            <FantasyBottomBar/>
            </div>
            <LogoutModal/>
        </>
    )
}

export default Profile