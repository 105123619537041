import React from "react";
import TeamCard from "../../fantasyComponents/myTeamCard/TeamCard";
import { ROUTES_CONST } from "../../../constants/routeConstant";

const Team = ({myTeams, id, match, contests }) => {
  console.log(contests)
    return (
        <div className="col-12 mt-3">
            <div className="row ">
                {myTeams?.map((item) => (
                    <TeamCard
                        Route1={`${ROUTES_CONST?.CREATE_TEAM}/${id}`}
                        team={item}
                        mainRoute={ROUTES_CONST.VIEW_TEAM}
                        mainParams={{
                            state: {
                                myteam: true,
                                data: match,
                                contest: contests?.length ? contests[0] : '',
                                edit: item.teamnumber,
                                playerData: item,
                            }
                        }}
                        params1={{
                            capVicePlr: {
                                captain: item.captin_name,
                                viceCaptain: item.viceCaptain_name,
                            },
                            players: item,
                            myteam: true,
                            contest: contests?.length ? contests[0] : '',
                            data: match,
                            edit: item.teamnumber,
                        }}
                    />
                ))}
            </div>
        </div>
    )
}

export default Team