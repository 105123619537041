import React, { useEffect, useState } from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import Quiz from './quiz/Quiz'
import Normal from './normal/Normal'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { selectTeamsAction, setCapAndViceCap } from '../../../store/action'

const FantasyGameDetail = () => {
  const [isQuizActive, setIsQuizActive] = useState(false)
  const [params, setParams] = useSearchParams()
  const contestName = params.get("contest_name")
  const decodedString = decodeURIComponent(contestName);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(selectTeamsAction([]))
    dispatch(setCapAndViceCap({
      captain: [],
      viceCaptian: []
    }))
  }, [])
  return (
    <div className="row fantasyInner">
      <FantasyHeader gameHeader={true} title={decodedString} />
      <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
        <div className="row mx-0">
          <div className="col-12 gameOptionsTabWrapper sticky-top">
            <div className="row mx-0 align-items-center justify-content-between gameOptionsTabInner">
              <div
                onClick={() => setIsQuizActive(false)}
                className={`gameOptionTab col-4 text-center text-uppercase ${!isQuizActive ? 'active' : ''}`}>normal</div>
              <div
                onClick={() => setIsQuizActive(true)}
                className={`gameOptionTab col-4 text-center text-uppercase ${isQuizActive ? 'active' : ''}`}>quiz</div>
            </div>
          </div>
          <div className="col-12 offcanvasParentDiv">
            {isQuizActive ? <Quiz /> : <Normal />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FantasyGameDetail