import { useContext } from 'react'
import { FantasyLoaderContext } from '../context/LoaderContext'

export const useFantasyLoader = () => {
  const {showFantasyLoader, setShowFantasyLoader} = useContext(FantasyLoaderContext)
  return {
    showFantasyLoader, setShowFantasyLoader
  }
    
}
