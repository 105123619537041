import React, { useState } from 'react'
import AppFooter from '../../../components/AppFooter/AppFooter'
import { useEffect } from 'react';
import { getAPIAuth } from '../../../service/apiInstance';
import DarkPagination from '../../../components/darkPagination/DarkPagination';
import { useAuth } from '../../../hooks/useAuth';

const filterBetsOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Open",
    value: "open",
  },
  {
    label: "Win",
    value: "win",
  },
  {
    label: "Lose",
    value: "lose",
  },
  {
    label: "Closed ",
    value: "closed ",
  },
];


const CasinoBetHistoryPage = () => {
  const [selectedBet, setSelectedBet] = useState('')
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true)
  const [games, setGames] = useState([])
  const [betSummary, setBetSummary] = useState({})
  const [totalPages, setTotalPages] = useState(null);
  const [itemsPerPage, setItemPerPage] = useState()
  console.log("itemsPerPage", itemsPerPage);
  const { token } = useAuth()


  const getMyBets = async () => {
    setLoader(true)
    try {
      const res = await getAPIAuth(`casinoApi/casinoReports?page=${currentPage}&perPage=${itemsPerPage}&status=${selectedBet}`, token)

      // console.log("res?.data?.[0]?.pagination?.perPage", res?.data.data?.[0].pagination.to);

      if (res?.data?.success) {

        console.log("resss", res);
        setTotalPages(res?.data.data?.[0].pagination?.totalPages)
        setItemPerPage(res?.data.data?.[0].pagination?.perPage)
        setData(res?.data?.data?.[0]?.fetchDetails)
        setBetSummary(res?.data?.data?.[0]?.status?.[0]);

      }
    } catch (error) {
      setData([])
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getMyBets()
  }, [selectedBet, currentPage])//eslint-disable-line

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedBet])
  const getGames = async () => {
    try {
      const res = await getAPIAuth('bet/games', token);
      if (res?.data?.data?.success) {
        setGames(res?.data?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getGames();
  }, []);//eslint-disable-line


  const dateFormatter = (date, time) => {
    const newDate = new Date(date);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    let formattedDate = newDate.toLocaleDateString(undefined, options);

    if (time) {
      const hours = newDate.getHours();
      const minutes = newDate.getMinutes();

      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

      const formattedTime = `${formattedHours}:${minutes} ${ampm}`;

      formattedDate += ` ${formattedTime}`;
    }

    return formattedDate;
  };


  console.log("ssss", data, selectedBet);
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage tradingPages">
            {/* start here */}
            <div className="row mx-0 mt-2">
              <div className="col-12">
                <div className="row gap-3">
                  <div className="col-12 px-0">
                    <div className="row g-3 align-items-center ">
                      <div className="col-lg">
                        <div className="tradingSecHead">Bet History</div>
                      </div>

                      <div className="col-lg-2 col-md-3 col-6">
                        <div className="reportsDropdownTitle">Filter Bets</div>
                        <div className="dropdown reportsDropdown">
                          <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {selectedBet ? selectedBet : 'All'}
                          </button>
                          <ul className="dropdown-menu dropdownMenu w-100" >
                            {
                              filterBetsOptions?.map((item) => (
                                <li className={`menuLinkDrop ${item?.label === selectedBet ? 'active' : ''}`}
                                  onClick={() => setSelectedBet(item?.label?.toLowerCase() === "all" ? '' : item?.label?.toLowerCase())}
                                  key={item?.label}
                                >{item?.label}
                                </li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 px-0">
                    <div className="row pb-1 pt-1 g-3 align-items-center ">
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Total Bets</div>
                              <div class="adminTopCardSubtitle">{betSummary?.totalBets ? betSummary?.totalBets : 0}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Won</div>
                              <div class="adminTopCardSubtitle">{betSummary?.wonBets ? betSummary?.wonBets : 0}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Lost</div>
                              <div class="adminTopCardSubtitle">{betSummary?.lostBets ? betSummary?.lostBets : 0}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Open</div>
                              <div class="adminTopCardSubtitle">{betSummary?.openBets ? betSummary.openBets : 0}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Closed</div>
                              <div class="adminTopCardSubtitle">{betSummary?.closedBets ? betSummary.closedBets : 0}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Total Exposure</div>
                              <div class="adminTopCardSubtitle">{data[1]?.userData?.exposer}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 px-0 dambleTable reportsTable sectionInner tableCard tab-content tab-pane table-responsive mb-0 mt-4">
                <table className="table align-middle text-white text-nowrap mb0">
                  <thead>
                    <tr>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Sr No.
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Game Name
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Provider Round Id
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Place date
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Returns
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Balance
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Status
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !loader ?
                        data?.length > 0 ?
                          data?.map((item, index) => (
                            <tr>
                              <td>{startIndex + index}</td>
                              <td>{item?.gameDetail?.name}</td>
                              <td>{item?.gameData?.providerRoundId}</td>
                              <td>  {dateFormatter(
                                item?.createdAt,
                                true
                              )}</td>
                              <td>{item?.amount?.toFixed(2)}</td>
                              <td>{item?.balance}</td>
                              <td className='text-capitalize'>{item?.status}</td>
                              {/* <td className={`${item?.status === 'lost' ? 'text-danger' : item?.status === 'won' ? 'text-success' : ''}`}>{item?.potentialWin?.toFixed(2)}</td> */}
                            </tr>
                          ))
                          : <tr>
                            <td colSpan={10} className='text-center'>No Data</td>
                          </tr>
                        : 'loading'
                    }
                  </tbody>
                </table>
              </div>
              {
                totalPages > 1 ?
                  <DarkPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                  : ''
              }
            </div>
          </div>
        </div>

        <AppFooter />
      </main>
    </>
  )
}

export default CasinoBetHistoryPage;
