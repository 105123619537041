import React from "react";
import ContestCard from "../../fantasyComponents/contestCard/ContestCard";
import JoinedContestCard from "../../fantasyComponents/JoinedContestCard.jsx";

const MyContest = ({ contest }) => {
    return (
        <>
            {
                contest?.map((elem) => (
                    <JoinedContestCard
                        // practice={item?.name?.toLowerCase().includes("practice")}
                        win_amount={elem?.win_amount}
                        entryfee={elem?.entryfee}
                        maximum_user={elem?.maximum_user}
                        joinedusers={elem?.joinedusers}
                        winning_percentage={elem?.winning_percentage}
                        multi_entry={elem?.multi_entry}
                        confirmed_challenge={elem?.confirmed_challenge}
                    // handleJoin={(e) => { e.stopPropagation(); TeamSelection(elem) }}
                    // handleLeader={(e) => {
                    //     e.stopPropagation();
                    //     navigate(ROUTES_CONST.FANTASY_LEADERbOARD, {
                    //         state: {
                    //             data: match,
                    //             el: elem,
                    //             contest: elem,
                    //             // selectingTeam: TeamSelection,
                    //             live: true,
                    //             // type: type,
                    //         }
                    //     })
                    // }}
                    />
                ))
            }
        </>
    )
}

export default MyContest