import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Nav, Row } from "react-bootstrap";
import PasswordInput from "../../passwordInput/PasswordInput";
import { useSelector } from "react-redux";
import { adminPostAPI, adminPostAPIAuth } from "../../../AdminServices";
import { USER } from "../../../AdminConstants/apiEndpoints";
import { succesToaster } from "../../../../utils/toaster";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { ADMIN_AUTH_ACCESS } from "../../../../constants";

//eslint-disable-next-line
const ShareSettlementsForm = ({ setShow, data, parentData, updateData }) => {

    console.log("parentData", data);


    const [showWithdraw, setShowWithdraw] = useState(false);
    const [formValues, setFormValues] = useState({
        fromUser: parentData?.username,
        adminShare: data?.adminShare,
        toUser: data?.username,
        profitLoss: data?.profitLossPoints,
        amount: "",
        remarks: "",
        password: "",
    });

    // console.log("formValues", formValues);

    const [formValuesWithdraw, setFormValuesWithdraw] = useState({
        fromUser: parentData?.username,
        upline: parentData?.profitLossPoints,
        toUser: data?.username,
        profitLoss: data?.profitLossPoints,
        amount: "",
        remarks: "",
        password: "",
    });

    const adminData = useSelector((state) => state.adminData);
    const [error, setError] = useState({
        deposit: "",
        withdraw: ""
    });

    const [formTextDeposit, setFormTextDeposit] = useState({
        upline: parentData?.profitLossPoints,
        profitLoss: data?.profitLossPoints,
    })

    const [formTextWithdraw, setFormTextWithdraw] = useState({
        upline: parentData?.profitLossPoints,
        profitLoss: data?.profitLossPoints,
    })

    const adminToken = useLocalStorage(ADMIN_AUTH_ACCESS, "")

    const handleChange = (e) => {
        const nameArray = ["amount"];

        if (!showWithdraw) {
            if (nameArray.includes(e.target.name) && isNaN(Number(e.target.value))) {
                setFormValues((prev) => ({
                    ...prev,
                    [e.target.name]: formValues[e.target.name],
                }));
            } else {
                setFormValues((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                }));
            }
        } else {
            if (nameArray.includes(e.target.name) && isNaN(Number(e.target.value))) {
                setFormValuesWithdraw((prev) => ({
                    ...prev,
                    [e.target.name]: formValuesWithdraw[e.target.name],
                }));
            } else {
                setFormValuesWithdraw((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                }));
            }
        }
    };

    useEffect(() => {
        if (showWithdraw) {
            if (!isNaN(Number(formValuesWithdraw.amount))) {
                setFormTextWithdraw((prev) => ({
                    ...prev,
                    upline: parentData?.profitLossPoints + Number(formValuesWithdraw.amount),
                    profitLoss: data?.profitLossPoints - Number(formValuesWithdraw.amount),
                }))
            }
        } else {
            if (!isNaN(Number(formValues.amount))) {
                setFormTextDeposit((prev) => ({
                    ...prev,
                    upline: parentData?.profitLossPoints + Number(formValues.amount),
                    profitLoss: data?.profitLossPoints - Number(formValues.amount),
                }))
            }
        }
        //eslint-disable-next-line
    }, [formValues.amount, formValuesWithdraw.amount])

    const handleClick = (e) => {
        e.preventDefault();
        if (!showWithdraw) {
            if (formValues.remarks === "" || formValues.amount === "" || formValues.password === "") {
                setError(prev => ({ ...prev, deposit: "Please fill all the fields " }))
            } else {
                handleApiCall()
            }
        } else {
            if (formValuesWithdraw.remarks === "" || formValuesWithdraw.amount === "" || formValuesWithdraw.password === "") {
                setError(prev => ({ ...prev, withdraw: "Please fill all the fields " }))
            } else {
                handleApiCall()
            }
        }
    };

    const handleApiCall = async () => {
        const loginCred = {
            username: adminData?.username,
            password: showWithdraw ? formValuesWithdraw.password : formValues.password,
        };
        if (showWithdraw) {
            setError(prev => ({
                ...prev,
                withdraw: ""
            }));
        } else {
            setError(prev => ({
                ...prev,
                deposit: "",
            }));
        }

        try {
            const res = await adminPostAPI(USER.login, loginCred, true);
            if (res?.data?.data?.success) {
                adminToken.updateValue(res?.data?.data.token)
                settlementApi();
            }
        } catch (error) {
            if (!showWithdraw) {
                setError((prev) => ({ ...prev, deposit: error?.response?.data?.data?.message }))
            } else {
                setError((prev) => ({ ...prev, withdraw: error?.response?.data?.data?.message }))
            }
        }
    };

    const settlementApi = async () => {
        // const payload = {
        //     userId: data?._id,
        //     transferedBy: parentData?._id,
        //     type: showWithdraw ? "debit" : "credit",
        //     points: showWithdraw ? Number(formValuesWithdraw.amount) : Number(formValues.amount),
        //     desc: showWithdraw ? formValuesWithdraw.amount : formValues.remarks
        // };
        const payload = {
            userId: data._id,
            transferedBy: data.createdBy,
            type: !showWithdraw ? "debit" : "credit",
            desc: showWithdraw ? formValuesWithdraw.amount : formValues.remarks,
            amount: showWithdraw ? Number(formValuesWithdraw.amount) : Number(formValues.amount),
        };

        console.log("asfdsgdfpayload", payload);

        try {
            const res = await adminPostAPIAuth(USER.share_settlement, payload, true);
            if (res.status === 200) {
                succesToaster("Settlement Successful!");
                if (!showWithdraw) {
                    setError((prev) => ({ ...prev, deposit: "" }))
                } else {
                    setError((prev) => ({ ...prev, withdraw: "" }))
                }
                setShow(false);
                updateData()
            }
        } catch (error) {
            if (!showWithdraw) {
                setError((prev) => ({ ...prev, deposit: error?.response?.data?.data?.message }))
            } else {
                setError((prev) => ({ ...prev, withdraw: error?.response?.data?.data?.message }))
            }
        }
    };

    const handleToggleDeposit = () => {
        setShowWithdraw(false);
    };

    const handleToggleWithdraw = () => {
        setShowWithdraw(true);
    };
    return (
        <>
            <Nav variant="tabs" className="mb-3">
                <Nav.Item onClick={handleToggleDeposit}>
                    <Nav.Link className={!showWithdraw ? "active" : ""}>DEPOSIT</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={handleToggleWithdraw}>
                    <Nav.Link className={showWithdraw ? "active" : ""}>WITHDRAW</Nav.Link>
                </Nav.Item>
            </Nav>

            <Form>
                <Container>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="fromUser">From User</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="fromUser"
                                    name="fromUser"
                                    value={
                                        !showWithdraw
                                            ? formValues.fromUser
                                            : formValuesWithdraw.fromUser
                                    }
                                    onChange={handleChange}
                                    disabled
                                />
                            </Form.Group>
                        </Col>

                        {
                            !showWithdraw ? (<Col>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="profitLoss">Admin Share</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="profitLoss"
                                        name="profitLoss"
                                        value={
                                            !showWithdraw
                                                ? formValues.profitLoss
                                                : formValuesWithdraw.profitLoss
                                        }
                                        onChange={handleChange}
                                        disabled
                                    />
                                    <Form.Text className="admin-forms-success-text" >{formTextDeposit.profitLoss}</Form.Text>
                                </Form.Group>
                            </Col>) : (
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="upline">User Share</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="upline"
                                            name="upline"
                                            value={
                                                !showWithdraw
                                                    ? formValues.upline
                                                    : formValuesWithdraw.upline
                                            }
                                            onChange={handleChange}
                                            disabled
                                        />
                                        <Form.Text className="admin-forms-success-text" >{formTextWithdraw.upline === 0 ? formTextWithdraw.upline : "-" + formTextWithdraw.upline}</Form.Text>
                                    </Form.Group>
                                </Col>
                            )
                        }
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="toUser">To User</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="toUser"
                                    name="toUser"
                                    value={
                                        !showWithdraw
                                            ? formValues.toUser
                                            : formValuesWithdraw.toUser
                                    }
                                    onChange={handleChange}
                                    disabled
                                />
                            </Form.Group>
                        </Col>

                        {
                            showWithdraw ? (<Col>
                                {/* <Form.Group className="mb-3">
                                    <Form.Label htmlFor="profitLoss">Profit/Lossssssss</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="profitLoss"
                                        name="profitLoss"
                                        value={
                                            !showWithdraw
                                                ? formValues.profitLoss
                                                : formValuesWithdraw.profitLoss
                                        }
                                        onChange={handleChange}
                                        disabled
                                    />
                                    <Form.Text className="admin-forms-success-text" >{formTextWithdraw.profitLoss}</Form.Text>
                                </Form.Group> */}
                            </Col>) : (
                                <Col>
                                    {/* <Form.Group className="mb-3">
                                        <Form.Label htmlFor="upline">Upline</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="upline"
                                            name="upline"
                                            value={
                                                !showWithdraw
                                                    ? formValues.upline
                                                    : formValuesWithdraw.upline
                                            }
                                            onChange={handleChange}
                                            disabled
                                        />
                                        <Form.Text className="admin-forms-success-text" >{formTextDeposit.upline === 0 ? formTextDeposit.upline : "-" + formTextDeposit.upline}</Form.Text>
                                    </Form.Group> */}
                                </Col>
                            )
                        }

                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="amount">Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="amount"
                                    name="amount"
                                    value={
                                        !showWithdraw
                                            ? formValues.amount
                                            : formValuesWithdraw.amount
                                    }
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="remarks">Remarks</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="remarks"
                                    name="remarks"
                                    value={
                                        !showWithdraw
                                            ? formValues.remarks
                                            : formValuesWithdraw.remarks
                                    }
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Label htmlFor="password">Password</Form.Label>
                            <PasswordInput
                                id="password"
                                name="password"
                                value={
                                    !showWithdraw
                                        ? formValues.password
                                        : formValuesWithdraw.password
                                }
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>

                    {/* admin-forms-error-text has css in userManagement.css  */}
                    {error.deposit || error.withdraw ? (
                        <Row className="mb-2 admin-forms-error-text">
                            <Col>
                                <div>{showWithdraw ? error.withdraw : error.deposit}</div>
                            </Col>
                        </Row>
                    ) : ""}

                    <Row>
                        <Col>
                            <Button type="submit" onClick={handleClick}>
                                {!showWithdraw ? "Add Funds" : "Withdraw"}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </>
    );
};

export default ShareSettlementsForm;
