import { useState } from "react"

// Custom hook for managing values in local storage.
export const useLocalStorage = (key, initialValue) => {

    // Retrieve the stored value from local storage based on the provided key.
    const storedValue = localStorage.getItem(key)

    // Use the stored value if it exists; otherwise, use the initial value provided.
    const initial = storedValue ? storedValue : initialValue

    // Initialize a state variable with the initial value.
    const [value, setValue] = useState(initial)
    // Function to update the stored value and state with a new value.
    const updateValue = (newValue) => {
        setValue(newValue)
        localStorage.setItem(key, newValue)
    }

    // Function to remove the item from local storage and reset the state to the initial value.
    const removeItem = () => {
        setValue(initialValue)
        localStorage.removeItem(key)
    }

    // Return an object with the current value, update function, and remove function.
    return { value, updateValue, removeItem }
}
