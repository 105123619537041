import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { isEmail } from "../../../utils/isEmail";
import { GoogleLogin } from "../../socialMedia/GoogleLogin";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import * as Yup from "yup";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { getAPI, postAPI } from "../../../service/apiInstance";
import {
  auth,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "../../../config/firebase";
import countryCode from "../../../constants/countryCode.json";
// import MetaMaskModal from "../../socialMedia/MetamaskModal";
import { useAuthModal } from "../../../hooks/useAuthModal";
import TelegramComp from "../../socialMedia/TelegramLoginButton";
import TelegramInputModal from "../../socialMedia/TelegramInputModal";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

const validationSchema = Yup.object({
  // username: Yup.string().required("This field is required"),
  number: Yup.string()
    .required("This field is required")
    .matches(
      // /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
      /^[0-9]{10}$/,
      "Invalid mobile number"
    ),
  // agreement: Yup.boolean().required(),
});

const NumberField = ({
  setOtpId,
  setActive,
  setUsername,
  setChecks,
  checks,
  Active,
  setNumber,
  setIsNumber,
  isNumber,
  referral,
  setReferral,
  setIsReferral
}) => {
  const [select, setSelect] = useState("");
  const [isMetamask, setIsMetamask] = useState(false);
  const { authModalObject } = useAuthModal();
  const [isTelegram, setIsTelegram] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [matchedItem, setmatchedItem] = useState([]);
  const [countrycodedata, setCountryCode] = useState([]);
  const countryName = useSelector((state) => state.country);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const onSubmit = async (values, resetForm) => {
    if (!checks.terms) {
      errorToaster("Please accept the User Agreement");
      setIsLoading(false);
      return;
    }
    setNumber(values.number);
    setUsername(values.username);
    // if (select == 0) {
    //   errorToaster("Choose a country code");
    //   setIsLoading(false);
    //   return;
    // }
    handleSend(values, resetForm);
  };

  const generateRecaptcha = (random) => {
    // if (window.recaptchaVerifier) {
    //   window.recaptchaVerifier.clear();
    // }

    // window.recaptchaVerifier = new RecaptchaVerifier(
    //   "recaptcha2",
    //   {
    //     size: "invisible",
    //     callback: (response) => { },
    //   },
    //   auth
    // );
    // window.recaptchaVerifier.render().then((widgetId) => {
    //   // console.log("widgetId", widgetId)
    //   window.recaptchaWidgetId = widgetId;
    // });
    const recaptchaParent = document.getElementById("recaptcha-parent");
    if (recaptchaParent) {
      recaptchaParent.innerHTML = `<span id=${random} />`
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      // "recaptcha-container",
      `${random}`,
      {
        size: "invisible",
        callback: (response) => { },
      },
      auth
    );
    window.recaptchaVerifier.render().then((widgetId) => {
      // console.log("widgetId", widgetId)
      window.recaptchaWidgetId = widgetId;
    });
  };

  const handleSend = (values, resetForm) => {
    const random = Math.random() + 1000000
    generateRecaptcha(random);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      auth,
      `${select?.phoneCode ? select.phoneCode : "+91"} ${values.number}`,
      appVerifier
    ) //dynamic code
      .then((confirmationResult) => {
        succesToaster("OTP SENT!");
        setOtpId(confirmationResult.verificationId);
        window.confirmationResult = confirmationResult;
        setActive(false);
        setOtpId(true);
        setIsNumber(false)
        window?.recaptchaVerifier?.recaptcha?.reset(window.recaptchaWidgetId)
      })
      .catch((error) => {
        errorToaster(error.message);
        const recaptchaContainer = document.getElementById(`${random}`)
        if (recaptchaContainer) { recaptchaContainer.remove(); }
        window?.recaptchaVerifier?.recaptcha?.reset(window.recaptchaWidgetId)
      })
      .finally(() => {
        setIsLoading(false);
        resetForm()
        setChecks(pre => ({ ...pre, terms: false }))
      });
  };
  const CountryCodeFun = async () => {
    const res = await getAPI("get-countries");
    setCountryCode(res.data.data);
  };
  useEffect(() => {
    const codedata =
      countrycodedata.filter((item) => item.country == countryName).length == 0
        ? countrycodedata
        : countrycodedata.filter((item) => item.country === countryName);
    setmatchedItem(countrycodedata);
    setSelect(countryName && codedata[0]);
    localStorage.setItem("country_Id", codedata[0]?._id);
  }, [countryName, countrycodedata]);

  // useEffect(() => {
  //   CountryCodeFun();
  // }, []);
  useEffect(() => {
    if (!isNumber) {
      setIsLoading(false);
    }
  }, [isNumber]);
  // console.log("select", select)
  const handlesearch = (val) => {
    if (val.length > 0) {
      const filterdata = countrycodedata?.filter((item) =>
        item.country.toLowerCase().includes(val.toLowerCase())
      );
      setmatchedItem(filterdata);
    } else {
      setmatchedItem(countrycodedata)
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          number: "",
          username: "",
        }}
        // validate={validationSchema}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          setIsLoading(true);
          onSubmit(values, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form>
            <div className="row gap-3 gx-0">
              {/* <div className="col-12 inputdata">
                <label htmlFor="text" className="inputLabel">
                  USERNAME
                  <span>*</span>
                </label>
                <input
                  type="text"
                  id="text"
                  className="form-control inputlogin border-0 shadow-none"
                  placeholder="Username"
                  value={values.username}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="username"
                />
                <ErrorMessage>
                  {errors.username && touched.username && errors.username}
                </ErrorMessage>
              </div> */}
              <div className="col-12 inputdata">
                {/* email / password */}
                <label htmlFor="email12" className="inputLabel">
                  {/* SELECT */}
                  PHONE NO
                  <span>*</span>
                </label>

                <div className="inputlogin position-relative d-flex align-items-center rounded-2">
                  <div className="dropdown countycodedata position-static">
                    <button
                      className={`btn bg-transparent code rounded-0 py-1 border-0 dropdown-toggle ${isOpen ? "show" : ""}`}
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                      onClick={() => setIsOpen(true)}
                    >
                      {select?.phoneCode ? select?.phoneCode : "+91"}
                    </button>
                    <div
                      className={`dropdown-menu countrydata ${isOpen ? "show" : ""}`}>
                      <div className="input-group mb-2 rounded-2 searchinput flex-nowrap">
                        <span
                          className="input-group-text py-0 pe-0 shadow-none border-0 bg-transparent searchbtn"
                          id="basic-addon2"
                        >
                          <img src="assets/img/search.png" alt="search" />
                        </span>
                        <input
                          type="text"
                          className="form-control py-0 selectInput inputlogin m-0 border-0 bg-transparent shadow-none"
                          placeholder="Search"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          onChange={(e) => handlesearch(e.target.value)}
                        />
                      </div>
                      <ul className="list-unstyled m-0 d-flex flex-column gap-1 innerdata overflow-y-auto">
                        {matchedItem.map((item) => (
                          <li>
                            <div className=" text-decoration-none innerlink  rounded-2 d-flex align-items-center gap-3"
                              onClick={() => {
                                setSelect(item);
                                localStorage.setItem("country_Id", item?._id)
                                setIsOpen(false)
                              }}>
                              <div
                                className="countrycode"
                              >
                                {item.phoneCode}
                              </div>
                              <div className="countryname" >{item.country}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <input
                    type="text"
                    id="email12"
                    className="form-control selectInput inputlogin mt-0 bg-transparent border-0 shadow-none text-white"
                    placeholder="Phone Number"
                    value={values.number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="number"
                  />
                </div>
                <ErrorMessage>
                  {errors.number &&
                    touched.number &&
                    errors.number}
                </ErrorMessage>
              </div>
              <div className="col-12 inputdata">
                {/* referral */}
                <label htmlFor="text" className="inputLabel referralLabel">
                  Enter Referral/Promo code
                  <img src="" alt="" />
                </label>
                <input
                  type="text"
                  id="text"
                  className="form-control inputlogin border-0 shadow-none"
                  placeholder="Enter code"
                  name="referral"
                  value={referral}
                  onChange={(e) => setReferral(e.target.value)}
                />
                {/* <ErrorMessage>
                                    {errors.username && touched.username && errors.username}
                                </ErrorMessage> */}
              </div>
              <div id="recaptcha-parent"> </div>
              {/* checks */}
              <div className="col-12 inputdata forgotmain">
                <div className="form-check ">
                  <input
                    className="form-check-input shadow-none"
                    type="checkbox"
                    id="flexCheckC"
                    value={checks.terms}
                    name="terms"
                    onChange={(e) =>
                      setChecks((pre) => ({
                        ...pre,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />

                  <label
                    className="form-check-label forgotPassword"
                    htmlFor="flexCheckC"
                  >
                    I agree to the User Agreement & confirm I am at least 18
                    years old
                  </label>
                </div>
                {/* <div className="form-check">
                  <input
                    className="form-check-input shadow-none"
                    type="checkbox"
                    id="flexCheckChecked"
                    value={checks.promotions}
                    name="promotions"
                    onChange={(e) =>
                      setChecks((pre) => ({
                        ...pre,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />
                  <label
                    className="form-check-label forgotPassword"
                    htmlFor="flexCheckChecked"
                  >
                    I agree to receive marketing promotions from Damble.
                  </label>
                </div> */}
              </div>
            </div>
            {/* footer section*/}
            <div className="row mt-4 gap-4">
              {/* button section*/}
              <div className="col-12 mx-auto">
                <span className="playBtn position-relative d-flex align-items-center justify-content-center cursor-pointer">
                  <button
                    type="submit"
                    id="recaptcha2"
                    className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"
                    onClick={handleSubmit}
                  >
                    {/* Play now! */}
                    {isLoading ? (
                      <Spinner size="sm" color="dark" />
                    ) : (
                      "Continue"
                    )}
                  </button>
                </span>
              </div>

              {/* divider*/}
              <div className="col-12 mx-auto ">
                <div className="loginbutton text-center">
                  <span className="directLogin text-decoration-none">
                    Log in directly with
                  </span>
                </div>
              </div>
              {/* social links*/}
              <div className="col-12 mx-auto">
                <div className="row align-items-center justify-content-center g-2">
                  {/* google */}
                  <div className="col-auto">
                    <span className="text-decoration-none">
                      <div className="link">
                        <GoogleLogin parent={"signUp"}
                        setIsReferral={setIsReferral}
                        >
                          <img src="assets/img/newIcon/google.svg" alt="" />
                        </GoogleLogin>
                      </div>
                    </span>
                  </div>
                  {/* <div className="col-auto">
                                            <span className="text-decoration-none">
                                                <div className="link">
                                                    <img src="assets/img/newIcon/telegram.svg" alt="" />
                                                </div>
                                            </span>
                                        </div> */}
                  {/* metamask */}
                  <div className="col-auto">
                    <span className="text-decoration-none cursor-pointer">
                      <div className="link" onClick={() => setIsMetamask(true)}>
                        <img
                          src="assets/img/newIcon/metamask-grayscale-logo.svg"
                          alt=""
                        />
                      </div>
                    </span>
                  </div>
                  {/* whatsApp */}
                  {/* <div className="col-auto">
                                            <span className="text-decoration-none">
                                                <div className="link"><img src="assets/img/newIcon/whatsapp.svg" alt="" /></div>
                                            </span>
                                        </div> */}
                  {authModalObject.isAuthOpen ? (
                    <div className="col-auto d-flex justify-content-center my-3">
                      <TelegramComp
                        isOpen={isTelegram}
                        setIsOpen={setIsTelegram}
                        userObj={userObj}
                        setUserObj={setUserObj}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      {/* <MetaMaskModal
        isOpen={isMetamask}
        setIsOpen={setIsMetamask}
        parent={"signUp"}
        setIsReferral={setIsReferral}
      /> */}
      <TelegramInputModal
        isOpen={isTelegram}
        setIsOpen={setIsTelegram}
        userObj={userObj}
        setUserObj={setUserObj}
        setIsReferral={setIsReferral}
      />
    </>
  );
};

export default NumberField;
