import React, { useEffect, useRef, useState } from "react";
import { getAPIAuth } from "../../service/apiInstance";
import { useAuth } from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ROUTES_CONST } from "../../constants/routeConstant";
import { useDispatch } from "react-redux";
import { getMainWalletData } from "../../store/action";
import logoutFunction from "../../utils/returnLogoutFunction";
import { useLogoutModal } from "../../hooks/useLogout";
import { useAuthModal } from "../../hooks/useAuthModal";
import { AUTH_MODAL_TABS } from "../../constants";

const UserBalanceComp = ({ setShowBets }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token, setToken } = useAuth();
    const [userBal, setUserBal] = useState({
        points: "",
        exposer: "",
        mainWallet: ""
    })
    const intervalRef = useRef()
    const location = useLocation();
    const subscribeUser = useSelector((state) => state?.getSubscribeUser);
    const { isLogoutOpen, setIsLogoutOpen } = useLogoutModal();
    const { authModalObject, setAuthModalObject } = useAuthModal();
    // console.log("subscribeUser", subscribeUser);

    const getUserBal = async () => {
        try {
            const res = await getAPIAuth(`user/balance`, token)
            console.log("resresresresresuserbalanveee", res);
            if (res?.data?.data?.success) {
                setUserBal((pre) => ({
                    points: res.data.data.data.points,
                    exposer: res.data.data.data.exposer,
                    mainWallet: res.data.data.data.mainWallet
                }))

            }
        } catch (error) {

            // console.log("getbalancetokenerrorerrorerror", error);
            if (error?.response?.data?.status == 401) {
                logoutFunction(setToken, dispatch, setIsLogoutOpen);
                setAuthModalObject({
                    isAuthOpen: false,
                    selectedTab: AUTH_MODAL_TABS.LOG_IN
                })

            }

        }
    }

    useEffect(() => {
        dispatch(getMainWalletData({
            "userBalance": userBal?.mainWallet,
            "getUserBal": getUserBal
        }));

    }, [userBal.mainWallet]);

    useEffect(() => {
        if (token) {
            getUserBal()
        }
    }, [token, subscribeUser])

    // to show updated user balance 
    useEffect(() => {
        if (token) {
            // polling
            // if (location?.pathname?.includes(ROUTES_CONST.SPORTS) || location?.pathname?.includes(ROUTES_CONST.CASINO_HOME) || location?.pathname?.includes(ROUTES_CONST.ACCOUNT)) {//to call the api only on sports and all-games page

            //     if (intervalRef.current) {//if previously any interval is running ,cancel it 
            //         clearInterval(intervalRef.current)
            //     }
            //     // start a new interval
            //     intervalRef.current = setInterval(() => {
            //         getUserBal()
            //     }, 3000)
            // }
            if (intervalRef.current) {//if previously any interval is running ,cancel it 
                clearInterval(intervalRef.current)
            }
            // start a new interval
            intervalRef.current = setInterval(() => {
                getUserBal()
            }, 3000);
        }
        return () => clearInterval(intervalRef.current)// clean up , when component unmounts

    }, [token, location.pathname])
    const viewConvert = (tab) => {
        navigate(`${ROUTES_CONST.ACCOUNT}`, { state: { tabname: tab, tabopen: "Converter", openaccordian: "Converter" } });
    }

    const viewPointsNavigate = () => {
        navigate(`${ROUTES_CONST.CASINO_REPORTS}`);

    }

    return (
        <>
            <span
                className="cursor-pointer text-light text-decoration-none d-flex align-items-center justify-content-center bitcoinBtn me-2"
                style={{
                    fontSize: "12px"
                }}
                onClick={() => viewConvert("hometab")}
            >
                M:  ₹ {userBal?.mainWallet ? Number(userBal?.mainWallet).toFixed(2) : '0.00'}
            </span>


            <span
                className=" text-light cursor-pointer text-decoration-none d-flex align-items-center justify-content-center bitcoinBtn me-2"
                onClick={() => setShowBets(true)}
                style={{
                    fontSize: "12px"
                }}
            >
                Exp: ₹ {userBal?.exposer ? Number(userBal?.exposer).toFixed(2) : '0.00'}
            </span>
            <span
                className=" text-decoration-none d-flex align-items-center justify-content-center bitcoinBtn"
                onClick={viewPointsNavigate}
            >

                Points:   ₹ {userBal?.points ? Number(userBal?.points).toFixed(2) : '0.00'}
            </span>
            {/* {tabopen==="Converter" &&
           <Converter tabopenn={tabopen} openaccordian={"Converter"}/>} */}
        </>
    );
};

export default UserBalanceComp;
