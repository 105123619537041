import React from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { getAPIAuth } from '../../service/apiInstance'
import { useState } from 'react'
import { useEffect } from 'react'
import { returnEndDate } from '../../utils/returnEndDate'
import { Spinner } from 'react-bootstrap'

const CasinoReports = () => {
    const [data, setData] = useState({})
    const [loader, setLoader] = useState(true)
    const [filterValues, setFilterValues] = useState({
        startDate: "",
        endDate: "",
    });

    console.log("datadata", data);




    // set today's date as end date
    useEffect(() => {
        const today = new Date()
        setFilterValues((prev) => ({
            ...prev,
            endDate: returnEndDate(today)
        }))
    }, [])


    const handleChange = (e) => {
        setFilterValues((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    // gey p/l report data
    const getData = async () => {
        setLoader(true)
        try {
            const res = await getAPIAuth(`casino/myprofitlossreport?startDate=${filterValues?.startDate}&endDate=${filterValues?.endDate}`)
            console.log(res)
            setData(res?.data?.data?.data)
        } catch (error) {
            console.log("error", error)
        } finally {
            setLoader(false)
        }
    }
    useEffect(() => {
        getData();
    }, [filterValues?.startDate, filterValues?.endDate])



    return (
        <main className="main vh-100" id="main">
            <div className="mainContaint">
                <div className="homepage tradingPages">
                    {/* start here */}
                    <div className="row mx-0 mt-2">
                        <div className="col-12">
                            <div className="row gap-3">
                                <div className="col-12 px-0">
                                    <div className="row g-3 align-items-center ">
                                        <div className="col-md">
                                            <div className="tradingSecHead">Profit Loss Report</div>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-6">
                                            <div className="reportsDropdownTitle">From</div>
                                            <input type="date" class="dateTimeInput"
                                                name="startDate"
                                                value={filterValues?.startDate}
                                                onChange={handleChange}></input>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-6">
                                            <div className="reportsDropdownTitle">To</div>
                                            <input type="date" class="dateTimeInput"
                                                name="endDate"
                                                value={filterValues?.endDate}
                                                onChange={handleChange}></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 px-0">
                                    <div className="row pb-1 pt-1 g-3 align-items-center ">
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div class="adminTopCard p-3">
                                                <div class="d-flex align-items-center">
                                                    <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                        <img src="assets/img/present.svg" alt="" />
                                                    </div>
                                                    <div class="">
                                                        <div class="adminTopCardTitle">Total Credits</div>
                                                        <div class="adminTopCardSubtitle">
                                                            {(() => {
                                                                const sportsWonAmount = data?.sports?.wonAmount || 0;
                                                                const casinoWonAmount = data?.casino?.wonAmount || 0;
                                                                const totalAmount = sportsWonAmount + casinoWonAmount;
                                                                return totalAmount.toFixed(2);
                                                            })()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div class="adminTopCard p-3">
                                                <div class="d-flex align-items-center">
                                                    <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                        <img src="assets/img/present.svg" alt="" />
                                                    </div>
                                                    <div class="">
                                                        <div class="adminTopCardTitle">Total Debit</div>
                                                        {/* <div class="adminTopCardSubtitle">{data?.sports?.lossAmount + data?.casino?.lossAmount}</div> */}
                                                        <div class="adminTopCardSubtitle">
                                                            {(() => {
                                                                const sportsWonAmount = data?.sports?.wonAmount || 0;
                                                                const casinoWonAmount = data?.casino?.wonAmount || 0;
                                                                const totalAmount = sportsWonAmount + casinoWonAmount;
                                                                return totalAmount.toFixed(2);
                                                            })()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div class="adminTopCard p-3">
                                                <div class="d-flex align-items-center">
                                                    <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                        <img src="assets/img/present.svg" alt="" />
                                                    </div>
                                                    <div class="">
                                                        <div class="adminTopCardTitle">Total Profit</div>
                                                        <div class="adminTopCardSubtitle">{data?.sports?.wonAmount + data?.casino?.wonAmount}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div class="adminTopCard p-3">
                                                <div class="d-flex align-items-center">
                                                    <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                        <img src="assets/img/present.svg" alt="" />
                                                    </div>
                                                    <div class="">
                                                        <div class="adminTopCardTitle">Total Loss</div>
                                                        <div class="adminTopCardSubtitle">{data?.sports?.lossAmount + data?.casino?.lossAmount}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div class="adminTopCard p-3">
                                                <div class="d-flex align-items-center">
                                                    <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                        <img src="assets/img/present.svg" alt="" />
                                                    </div>
                                                    <div class="">
                                                        <div class="adminTopCardTitle">Total Exposure</div>
                                                        <div class="adminTopCardSubtitle">{data?.sports?.exposer + data?.casino?.exposer}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle"> Total Exploser</div>
                              <div class="adminTopCardSubtitle">347890</div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="col-12 px-0 dambleTable sectionInner tableCard tab-content tab-pane table-responsive mb-0 mt-4">
                            <table className="table align-middle text-white text-nowrap mb0">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="tabletxt d-flex align-items-center">
                                                GAME
                                            </div>
                                        </th>
                                        <th>
                                            <div className="tabletxt d-flex align-items-center">
                                                TOTAL BETS
                                            </div>
                                        </th>
                                        <th>
                                            <div className="tabletxt d-flex align-items-center">
                                                WON
                                            </div>
                                        </th>
                                        <th>
                                            <div className="tabletxt d-flex align-items-center">
                                                LOST
                                            </div>
                                        </th>
                                        <th>
                                            <div className="tabletxt d-flex align-items-center">
                                                NOT DECLARED
                                            </div>
                                        </th>
                                        <th>
                                            <div className="tabletxt d-flex align-items-center">
                                                EXPOSER
                                            </div>
                                        </th>
                                        <th>
                                            <div className="tabletxt d-flex align-items-center">
                                                WON AMOUNT
                                            </div>
                                        </th>
                                        <th>
                                            <div className="tabletxt d-flex align-items-center">
                                                LOST AMOUNT
                                            </div>
                                        </th>
                                        <th>
                                            <div className="tabletxt d-flex align-items-center">
                                                OVERALL PROFIT/LOSS
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        console.log("data", data)
                                    }
                                    {
                                        !loader ?
                                            Object?.keys(data)?.map((cat) => {

                                                return (
                                                    <tr>
                                                        <td className='text-capitalize'>{cat}</td>
                                                        <td>{data?.[cat]?.totalBets ? data?.[cat]?.totalBets : 0}</td>
                                                        <td>{data?.[cat]?.won ? data?.[cat]?.won : 0}</td>
                                                        <td>{data?.[cat]?.loss ? data?.[cat]?.loss : 0}</td>
                                                        <td>{data?.[cat]?.notDeclared ? data?.[cat]?.notDeclared : 0}</td>
                                                        <td>{data?.[cat]?.exposer ? data?.[cat]?.exposer : 0}</td>
                                                        <td>{data?.[cat]?.wonAmount ? data?.[cat]?.wonAmount : 0}</td>
                                                        <td>{data?.[cat]?.lossAmount ? data?.[cat]?.lossAmount : 0}</td>
                                                        <td className={`${data?.[cat]?.overallProfitLoss < 0 ? 'text-danger' : 'text-success'}`}>{data?.[cat]?.overallProfitLoss ? data?.[cat]?.overallProfitLoss.toFixed(2) : 0}</td>
                                                    </tr>
                                                )
                                            })
                                            : <tr>
                                                <td colSpan={9} className=''>
                                                    <div className="d-flex align-items-center justify-content-center py-3">
                                                        <Spinner size="sm" color="white" />
                                                    </div>
                                                </td>

                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* end here */}
                </div>
            </div>

            {/*  **** Footer section *** */}
            <AppFooter />
        </main>
    )
}

export default CasinoReports