import React, { useState } from 'react'
import { adminPostAPIAuthFormData } from '../../AdminServices';
import { errorToaster, succesToaster } from '../../../utils/toaster';

const AddQrDetails = ({ getPaymentDetails }) => {
    const initialValue = {type: "QR Code", displayName: "", status: "",};
      const [addTableData, setAddTableData] = useState(initialValue);
      const [file, setFile] = useState("")
      const [validationError, setValidationError] = useState(false)
    
      const addPaymentDetails = async (e) => {
        e.preventDefault();
        if (addTableData.displayName === '' || file === '' || addTableData.status === '') {
          setValidationError(true)
        } else {
          try {
            const formData = new FormData();
            formData.append("typename", "userPaymentDetails");
            formData.append("image", file);
            formData.append("flag", addTableData.type);
            formData.append("displayName", addTableData.displayName);
            const res = await adminPostAPIAuthFormData("user/createUserPaymentDetails", formData);
            if (res?.data?.data?.data) {
              setAddTableData(res?.data?.data?.data);
              getPaymentDetails()
              succesToaster("QR Details Successfully Added")
              setValidationError(false)
            }
            setAddTableData(initialValue);
            setFile("")
          } catch (error) {
            errorToaster(error?.response?.data?.message)
          }
        }
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setAddTableData({ ...addTableData, [name]: value });
      };

  return (
    <div>
      <button
        className="btn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        Add
      </button>

      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Offcanvas right
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <form onSubmit={addPaymentDetails}>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Type<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                name="type"
                value={addTableData.type}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <h6>Editable Details</h6>
            <div className="mt-4 d-flex gap-2">
              <div>
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Display Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="displayName"
                  value={addTableData.displayName}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="exampleInputEmail1" className="form-label">
                  QR Image<span className="text-danger">*</span>
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="image"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
            </div>
            <div className="mt-4 d-flex gap-2">
              <div>
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Status<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="status"
                  value={addTableData.status}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={`mt-2 ${validationError === true ? (addTableData.displayName === '' || file === '' || addTableData.status === '') ? 'd-block' : 'd-none' : 'd-none' }`}>
              <span style={{color: 'red'}}>Fill all the fields</span>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-4"
              data-bs-dismiss={`${(addTableData.displayName === '' || file === '' || addTableData.status === '') ? '' : "offcanvas"}`}
              aria-label={`${(addTableData.displayName === '' || file === '' || addTableData.status === '') ? '' : "Close"}`}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddQrDetails
