import { useNavigate } from "react-router-dom"
import { ADMIN_AUTH_ACCESS, ADMIN_AUTH_ROLE, ADMIN_LOGGED_IN_ID } from "../../constants"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { LOGIN_URL, PORTAL } from "../AdminConstants/urlPaths"


export const useAdminLogout=()=>{
    const adminId=useLocalStorage(ADMIN_LOGGED_IN_ID)
    const adminToken=useLocalStorage(ADMIN_AUTH_ACCESS)
    const adminRole = useLocalStorage(ADMIN_AUTH_ROLE)
    const navigate=useNavigate()

    const logOut=()=>{
        adminId.removeItem()
        adminToken.removeItem()
        adminRole.removeItem()

        navigate("/" + PORTAL + "/" + LOGIN_URL)
    }

    return logOut
}


export const dateFormatter = (date, time) => {
    const newDate = new Date(date);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    let formattedDate = newDate.toLocaleDateString(undefined, options);
  
    if (time) {
      const hours = newDate.getHours();
      const minutes = newDate.getMinutes();
  
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  
      const formattedTime = `${formattedHours}:${minutes} ${ampm}`;
  
      formattedDate += ` ${formattedTime}`;
    }
  
    return formattedDate;
  };
  