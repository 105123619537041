import React, { useState } from "react";
import FantasyHeader from "../../fantasyComponents/fantasyHeader/FantasyHeader";
import FantasyBottomBar from "../../fantasyComponents/fantasyBottombar/FantasyBottomBar";
import search from "./../../fantasyAssets/img/search.svg";
import filterIcon from "./../../fantasyAssets/img/filterIcon.png";
import check from "./../../fantasyAssets/img/check.svg";
import downArrow from "./../../fantasyAssets/img/down.svg";
import { getFantasyAuth, postFantasyAuth } from "../../fantasyServices";
import { useEffect } from "react";
import { succesToaster, errorToaster } from "../../../utils/toaster";

const MyPortfolio = () => {
  const [activeTab, setActiveTab] = useState("STOCKS");
  const [company, setCompany] = useState([]);
  const [myPortfolio, setMyPortfolio] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isPortfolioUpdated, setIsPortfolioUpdated] = useState(false)

  console.log(filteredData);
  console.log("selectedCompany", selectedCompany);

  const searchPortfolio = () => {
    if (searchQuery?.length > 0) {
      let data = company?.filter((el) =>
        el?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
      );
      let arr = [];
      data.forEach((item) => {
        if (!myPortfolio?.filter((el) => el?._id === item?._id)?.length) {
          arr.push(item);
        }
      });
      setFilteredData(arr);
    } else {
      setFilteredData(company);
    }
  };

  useEffect(() => {
    searchPortfolio();
  }, [searchQuery]);

  const getContestData = async () => {
    try {
      const res = await getFantasyAuth(
        `/get-portfolio-stocks?stockCat=${activeTab}`
      );
      setCompany(res.data.data);
      setFilteredData(res.data.data);
      console.log("res.data.data.company", res);
    } catch (error) {
      console.log("error-------------------->>", error);
    }
  };

  const getMyPortfolio = async () => {
    try {
      const resp = await getFantasyAuth(
        `/my-portfolio-data?portfolioCat=${activeTab}`
      );
      if (resp.data.status) {
        setMyPortfolio(resp.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getContestData();
    getMyPortfolio();
    //eslint-disable-next-line
  }, [activeTab]);

  const handleCompany = (item) => {
    setSelectedCompany((prev) => [...prev, item]);
    setMyPortfolio((prev) => [...prev, item]);
    setIsPortfolioUpdated(true)
  };

  const handleSelectedCompany = (item) => {
    let filtered = myPortfolio.filter((el) => el._id !== item._id);
    setSelectedCompany(filtered);
    setMyPortfolio(filtered);
  };
  console.log('myPortfolio', myPortfolio)

  const createPortFolio = async () => {
    if(isPortfolioUpdated) {
    let id = myPortfolio.map((el) => el._id);
    const params = {
      stocks: id,
      portfolioCat: activeTab,
    };
    console.log(params, "params");
    try {
      const res = await postFantasyAuth("/create-portfolio-stocks", params);
      console.log("res", res);
      if (res?.data?.success) {
        console.log("if");
        succesToaster(res?.data?.message);
      } else {
        console.log("else");
        errorToaster(res?.data?.message);
      }
    } catch (error) {
      console.log("errorrrr", error);
    }
  }else {
    errorToaster('Please Update Portfolio first');
  }
  };

  return (
    <>
      <div className="row fantasyInner">
        <FantasyHeader gameHeader={true} title={"My Portfolio"} />
        <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
          <div className="col-12 contestOptionsTabInner tabPortfolio mb-3">
            <div className="row mx-0 align-items-center justify-content-between">
              <div className="col-4">
                <div
                  onClick={() => setActiveTab("STOCKS")}
                  className={`contestOptionTab text-center text-uppercase ${
                    activeTab === "STOCKS" ? "active" : ""
                  }`}
                >
                  Stocks
                </div>
              </div>
              <div className="col-4">
                <div
                  onClick={() => setActiveTab("MCX")}
                  className={`contestOptionTab text-center text-uppercase ${
                    activeTab === "MCX" ? "active" : ""
                  }`}
                >
                  MCX
                </div>
              </div>
              <div className="col-4">
                <div
                  onClick={() => setActiveTab("COMMODITY")}
                  className={`contestOptionTab text-center text-uppercase ${
                    activeTab === "COMMODITY" ? "active" : ""
                  }`}
                >
                  Commodity
                </div>
              </div>
            </div>
          </div>
          {activeTab === "STOCKS" ? (
            <>
              <div className="row myPortfolioHeader">
                <div
                  className="col-12 portfolioBtn mb-2"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <div className="row mx-0">
                    <div className="col headerTitle">
                      My Portfolio ({myPortfolio?.length})
                    </div>
                    <div className="col-auto">
                      <img className="headerIcon" src={downArrow} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 portfolioCollapse collapse"
                  id="collapseExample"
                >
                  <div className="row mainCenterPart gy-3 mx-0">
                    {myPortfolio?.map((item) => (
                      <div className="col-12">
                        <div
                          className="warningCard addcase card cursor-pointer border-0 rounded-4 position-relative"
                          onClick={() => handleSelectedCompany(item)}
                        >
                          <div className="card-body">
                            <div className="row mx-0 align-items-center">
                              <div className="col-auto ps-0">
                                <div className="circle "></div>
                              </div>
                              <div className="col px-0">
                                <div className="portfolioCardTxt">
                                  {item?.name}
                                </div>
                                <div className="percentage">
                                  {Number(item.closePrice) >
                                  Number(item?.openPrice)
                                    ? (
                                        ((Number(item?.closePrice) -
                                          Number(item?.openPrice)) *
                                          100) /
                                        Number(item?.closePrice)
                                      ).toFixed(2)
                                    : Number(item?.closePrice) <
                                      Number(item?.openPrice)
                                    ? (
                                        ((Number(item?.openPrice) -
                                          Number(item?.closePrice)) *
                                          100) /
                                        Number(item?.openPrice)
                                      ).toFixed(2)
                                    : 0}
                                  %
                                  <span className="mx-2">
                                    {item?.closePrice}
                                  </span>
                                </div>
                              </div>
                              <div className="col-auto pe-0">
                                <img className="checkImg" src={check} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row stocksTabWrapper d-flex align-items-center mx-0 mt-4">
                <div className="col">
                  <div className="inputWrapper position-relative d-flex align-items-center">
                    <input
                      className="searchInp"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <img className="searchImg" src={search} alt="" />
                  </div>
                </div>
                {/* <div className="col-auto ps-1">
                  <img className="filterIcon" src={filterIcon} alt="" />
                </div> */}
              </div>
              <div className="row mainCenterPart gy-3 mx-0 py-4">
                {filteredData?.length > 0 ? (
                  filteredData?.map((item) => {
                    if (
                      !myPortfolio?.filter((el) => el?._id === item?._id)
                        ?.length
                    ) {
                      return (
                        <div className="col-12">
                          <div
                            className="warningCard addcase card cursor-pointer border-0 rounded-4 position-relative"
                            onClick={() => handleCompany(item)}
                          >
                            <div className="card-body">
                              <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0">
                                  <div className="circle "></div>
                                </div>
                                <div className="col px-0">
                                  <div className="portfolioCardTxt">
                                    {item?.name}
                                  </div>
                                  <div className="percentage">
                                    {Number(item?.closePrice) >
                                    Number(item?.openPrice)
                                      ? (
                                          ((Number(item?.closePrice) -
                                            Number(item?.openPrice)) *
                                            100) /
                                          Number(item?.closePrice)
                                        ).toFixed(2)
                                      : Number(item?.closePrice) <
                                        Number(item?.openPrice)
                                      ? (
                                          ((Number(item?.openPrice) -
                                            Number(item?.closePrice)) *
                                            100) /
                                          Number(item?.openPrice)
                                        ).toFixed(2)
                                      : 0}
                                    %
                                    <span className="mx-2">
                                      {item?.closePrice}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-auto pe-0">
                                  <img
                                    className="checkImg"
                                    src={check}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return "";
                  })
                ) : (
                  <div className="col-12 text-center text-light">
                    No Data Available
                  </div>
                )}
              </div>
              <button
                className="createTeamBtn bottomUp"
                onClick={createPortFolio}
              >
                Update My Portfolio
              </button>
            </>
          ) : activeTab === "MCX" ? (
            <>
              <div className="text-light text-center">No Data Available</div>
            </>
          ) : activeTab === "COMMODITY" ? (
            <>
              <div className="text-light text-center">No Data Available</div>
            </>
          ) : (
            ""
          )}
        </div>
        <FantasyBottomBar />
      </div>
    </>
  );
};

export default MyPortfolio;
