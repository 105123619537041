import axios from 'axios'
import { STOCK_USER_ID, TRADING_TOKEN_NAME, tradingBaseUrl } from '../../constants/index'

export const getTrading = async (url, body) => {
    const response = await axios.get(`${tradingBaseUrl}/${url}`, body, {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",

        }
    })
    return response
}

export const getTradingAuth = async (url, tokenInit) => {
    const bURL = tradingBaseUrl
    const token = localStorage.getItem(TRADING_TOKEN_NAME)
    const response = await axios.get(`${bURL}/${url}`, {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
            Authorization: `Bearer ${tokenInit ? tokenInit : token}`,
        }
    })
    return response
}

// no auth token required
export const postTradingAPI = async (url, params) => {
    const bURL = tradingBaseUrl
    const stockId = localStorage.getItem(STOCK_USER_ID)
    const response = fetch(`${bURL}/${url}`,
    {
      method: "POST", 
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({ lt_user_id: stockId,...params }), // body data type must match "Content-Type" header
    });
    return response
}

export const postTradingAuth = async (url, params, admin) => {
    const bURL = tradingBaseUrl
    const token = localStorage.getItem(TRADING_TOKEN_NAME)
    const response = await axios.post(`${bURL}/${url}`, params, {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        }
    })
    return response
}

export const postTradingAuthFormData = async (url, params) => {
    const token = localStorage.getItem(TRADING_TOKEN_NAME)
    const response = await axios({
        method: "post",
        url: `${tradingBaseUrl}/${url}`,
        data: params,
        headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}`, },
    })
    return response
}

export const deleteTradingAuth = async (url) => {
    const token = localStorage.getItem(TRADING_TOKEN_NAME);
    const response = await axios.delete(`${tradingBaseUrl}/${url}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response;
};