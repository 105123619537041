import React, { useState } from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { useEffect } from 'react';
import { getAPIAuth } from '../../service/apiInstance';
import DarkPagination from '../../components/darkPagination/DarkPagination';
import { useAuth } from '../../hooks/useAuth';
import SubscriptionBettingCards from '../../Subscribtion-Sports/subscribtionSportsPages/SportsHome/SubscribtionBettingCards';
import './style.css'


const filterBetsOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Open",
    value: "open",
  },
  {
    label: "won",
    value: "won",
  },
  {
    label: "lost",
    value: "lost",
  },
  {
    label: "settled ",
    value: "settled ",
  },
];


const SubscriptionBetListPage = () => {
  const [selectedGame, setSelectedGame] = useState('All')
  const [selectedBet, setSelectedBet] = useState('')
  const [data, setData] = useState([]);
  const [gameId, setGameId] = useState('')
  const [status, setStatus] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true)
  const [games, setGames] = useState([])
  const [betSummary, setBetSummary] = useState({});
  const [usersubscricription, setUserSubscription] = useState([]);
  const [userId, setUserID] = useState('');
  const [selecteduser, setSelectedUser] = useState('All')
  const [totalPages, setTotalPages] = useState(null);
  const itemsPerPage = 10;
  const { token } = useAuth();

  // console.log("sadfsdfssss", userId);

  const getMyBets = async () => {
    setLoader(true)
    try {
      const res = await getAPIAuth(`bet/getMyAllSubsBets?gameId=${gameId}&status=${selectedBet}&packageId=${userId}&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&perPage=${itemsPerPage}`, token)
      console.log('resresres', res)
      if (res?.data?.data?.success) {
        setTotalPages(res?.data?.data?.data?.pagination?.totalPages)
        setData(res?.data?.data?.data?.getUserBets)
        setBetSummary(res?.data?.data?.data?.betDetails)
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getMyBets()
  }, [selectedBet, gameId, startDate, endDate, currentPage, userId])


  const getGames = async () => {
    try {
      const res = await getAPIAuth('bet/games', token);
      if (res?.data?.data?.success) {
        setGames(res?.data?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getUserAllSub = async () => {
    try {
      const res = await getAPIAuth('user/getUserAllSub', token);
      if (res?.data?.data?.success) {
        console.log("asdfdd", res);
        setUserSubscription(res?.data?.data?.response)
        // setGames(res?.data?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getGames();
    getUserAllSub();
  }, []);

  console.log('data', games)

  const dateFormatter = (date, time) => {
    const newDate = new Date(date);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    let formattedDate = newDate.toLocaleDateString(undefined, options);

    if (time) {
      const hours = newDate.getHours();
      const minutes = newDate.getMinutes();

      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

      const formattedTime = `${formattedHours}:${minutes} ${ampm}`;

      formattedDate += ` ${formattedTime}`;
    }

    return formattedDate;
  };


  const FilterSubscribtionBetData = data?.filter((item) => {
    return item?.packageSubscriptionId !== null;
  });

  // console.log("FilterSubscribtionBetData" , FilterSubscribtionBetData);

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage tradingPages">
            {/* start here */}
            <div className="row mx-0 mt-2">
              <div className="col-12">
                <div className="row gap-3">
                  <div className="col-12 px-0">
                    <div className="row g-3 align-items-center ">
                      <div className="col-lg">
                        <div className="tradingSecHead"> Bet History</div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-6">
                        <div className="reportsDropdownTitle">Select Games</div>
                        <div className="dropdown reportsDropdown">
                          <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {selectedGame}
                          </button>
                          <ul className="dropdown-menu dropdownMenu w-100">
                            <li className={`menuLinkDrop ${selectedGame === "All" ? 'active' : ''}`}
                              onClick={() => {
                                setSelectedGame('All')
                                setGameId('')
                              }}>All</li>
                            {
                              games?.map((item) => (
                                <li className={`menuLinkDrop ${item?.name === selectedGame ? 'active' : ''}`}
                                  key={item?.name}
                                  onClick={() => {
                                    setSelectedGame(item?.name)
                                    setGameId(item?.gameId)
                                  }}
                                >{item?.name}</li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-6">
                        <div className="reportsDropdownTitle">My Subscription</div>
                        <div className="dropdown reportsDropdown">
                          <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {selecteduser}
                          </button>
                          <ul className="dropdown-menu dropdownMenu w-100">
                            <li className={`menuLinkDrop ${selecteduser === "All" ? 'active' : ''}`}
                              onClick={() => {
                                setSelectedUser('All')
                                setUserID('')
                              }}>All</li>
                            {
                              usersubscricription?.map((item) => (
                                <li className={`menuLinkDrop ${item?.name === selecteduser ? 'active' : ''}`}
                                  key={item?.name}
                                  onClick={() => {
                                    setSelectedUser(item?.name)
                                    setUserID(item?._id)
                                  }}
                                >{item?.name}</li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-6">
                        <div className="reportsDropdownTitle">Filter Bets</div>
                        <div className="dropdown reportsDropdown">
                          <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {selectedBet ? selectedBet : 'All'}
                          </button>
                          <ul className="dropdown-menu dropdownMenu w-100">
                            {
                              filterBetsOptions?.map((item) => (
                                <li className={`menuLinkDrop ${item?.label === selectedBet ? 'active' : ''}`}
                                  onClick={() => setSelectedBet(item?.label?.toLowerCase() === "all" ? '' : item?.label?.toLowerCase())}
                                  key={item?.label}
                                >{item?.label}
                                </li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-6">
                        <div className="reportsDropdownTitle">From</div>
                        <input type="date" class="dateTimeInput" value={startDate} onChange={(e) => setStartDate(e.target.value)}></input>
                      </div>
                      <div className="col-lg-2 col-md-3 col-6">
                        <div className="reportsDropdownTitle">To</div>
                        <input type="date" class="dateTimeInput" min={startDate} value={endDate} onChange={(e) => setEndDate(e.target.value)}></input>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 px-0">
                    <div className="row pb-1 pt-1 g-3 align-items-center ">
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Total Bets</div>
                              <div class="adminTopCardSubtitle">{betSummary?.totalBets ? betSummary?.totalBets : 0}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Won</div>
                              <div class="adminTopCardSubtitle">{betSummary?.wonBets ? betSummary?.wonBets : 0}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Lost</div>
                              <div class="adminTopCardSubtitle">{betSummary?.lostBets ? betSummary?.lostBets : 0}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Open</div>
                              <div class="adminTopCardSubtitle">{betSummary.openBets ? betSummary?.openBets : 0}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 px-0 subscription-bet-data">

                    <SubscriptionBettingCards />


                  </div>
                </div>
              </div>

              <div className="col-12 px-0 dambleTable reportsTable sectionInner tableCard tab-content tab-pane table-responsive mb-0 mt-4">
                <table className="table align-middle text-white text-nowrap mb0">
                  <thead>
                    <tr>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Sr No.
                        </div>
                      </th>
                      {/* <th>
                        <div className="tabletxt d-flex align-items-center">
                          username
                        </div>
                      </th> */}
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          place date
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          match
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          match date
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          market
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          bet on
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          odds
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          stake
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          outcome
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          returns
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !loader ?
                        FilterSubscribtionBetData?.length > 0 ?
                          FilterSubscribtionBetData?.map((item, index) => (
                            <tr>
                              <td>{startIndex + index}</td>
                              {/* <td>{item?.userId?.username}</td> */}
                              <td>  {dateFormatter(
                                item?.matchId?.event?.openDate,
                                true
                              )}</td>
                              <td>{item?.matchId?.event?.name}</td>
                              <td>  {dateFormatter(
                                item?.matchId?.event?.openDate,
                                false
                              )}</td>
                              <td>{item?.marketName}</td>
                              <td>{item?.selectionCountryName ? item?.selectionCountryName : '_'}</td>
                              <td>{item?.odds}</td>
                              <td>{item?.amount?.toFixed(2)}</td>
                              <td className='text-capitalize'>{item?.status}</td>
                              <td className={`${item?.status === 'lost' ? 'text-danger' : item?.status === 'won' ? 'text-success' : ''}`}>{item?.potentialWin?.toFixed(2)}</td>
                            </tr>
                          ))
                          : <tr>
                            <td colSpan={10} className='text-center'>No Data</td>
                          </tr>
                        : 'loading'
                    }
                  </tbody>
                </table>
              </div>
              {
                totalPages > 1 ?
                  <DarkPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                  : ''
              }
            </div>
          </div>
        </div>

        <AppFooter />
      </main>
    </>
  )
}

export default SubscriptionBetListPage
