import React, { useEffect, useRef, useState } from "react";
import { postAPI, postAPIAuth, casinoPostAPIAuth } from "../../service/apiInstance";
import { useSelector } from "react-redux";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { useAuth } from "../../hooks/useAuth";
import { useAuthModal } from "../../hooks/useAuthModal";
import { AUTH_MODAL_TABS, MOBILE_SCREEN_WIDTH } from "../../constants";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { getAPIAuth, postAPIDerbyAuth } from "../../service/apiInstance";
import './style.css'

const DerbyPage = () => {

    const { token } = useAuth();
    const userdetails = useSelector(state => state?.userFullDetails);
    // console.log("userdetails", userdetails);
    const iframeRef = useRef()
    const [useriframedata, setUserIframeData] = useState('');
    const [queryParams, setQueryParams] = useState({});


    // console.log("useriframedata", useriframedata);

    // const queryString = useriframedata?.split('?')[1];
    // const params = new URLSearchParams(queryString);
    // const infoToken = params.get('info');

    // console.log("infoToken", infoToken);

    const extractQueryParams = (url) => {
        const queryString = url.split('?')[1];
        const params = new URLSearchParams(queryString);
        const info = params.get('info');
        const userId = params.get('userId');
        const name = params.get('name');
        const token = params.get('token');

        setQueryParams({ info, userId, name, token });
    };

    console.log('queryParamsqueryParams', queryParams)

    // console.log("aqaqqaq ", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NjJmNjA5NjViNWU2YTI0MjQwMWJhYWMiLCJBcGlLZXkiOiJGSVJTVEFHRU5UQ09NIiwiQXBpU2VjcmV0IjoiZDRiNjU1NjBlODcxYzg2NWVhODM3ODJlYzM5MTVkZjM2NDRjNDk3YzUyYmMwMDEyOTNjNzFlOWU0NzRkNmRiNCIsImlhdCI6MTcxNzEzMTc3NH0.xGdOuVkINdDgvKiNyKJycQsKJaUJ1AXed0OdOFKTLYc" == queryParams.info);


    // const user = {
    //     name: userdetails?.username,
    //     email: userdetails?.email,
    // }

    // console.log("useruser", user);



    const getUserIframe = async () => {

        // alert("hiii");

        if (token) {
            try {
                const params = {
                    website: "BX",
                    userId: userdetails?._id,
                    user: {
                        name: userdetails?.username,
                        email: userdetails?.email,
                    }
                }
                // alert("hello");
                const res = await postAPIDerbyAuth("https://api.zeroott.com/api/v1/agent/agent-iframe", params);
                // alert("hiiii")
                console.log("ressdsswetyaudssf", res);

                if (res?.data?.success) {
                    // alert("hii")
                    localStorage.setItem("extra-derby-token", queryParams?.info);
                    localStorage.setItem("s_userToken", queryParams?.token);
                    localStorage.setItem("s_user_name", JSON.stringify(queryParams?.name));
                    localStorage.setItem("ifuserId", JSON.stringify(queryParams?.userId));


                    // setUserIframeData(res?.data?.data?.agentResponse);
                    setUserIframeData(res?.data?.data?.agentResponse);
                    extractQueryParams(res?.data?.data?.agentResponse);
                    // succesToaster(res?.data?.message)
                }
            } catch (error) {
                // console.log("error", error);
                errorToaster(error?.response?.data?.data?.message)
            }
        }

    }

    // console.log("upper console", userdetails?.username);


    useEffect(() => {
        if (userdetails?._id) {

            getUserIframe();
        }

    }, [userdetails]);

    // console.log("lower console", userdetails?.username);



    return (
        <div className="row pt-3 sdafsg">
            <div className="col-12 px-0 position-relative">
                {
                    <div className="iframeMain derbyiframe-data rounded-2 overflow-hidden">
                        <iframe

                            title="game iframe"
                            ref={iframeRef}
                            className="game-iframe w-100 h-100"
                            src={`${useriframedata}`}
                            allow="autopaly"
                            allowFullScreen />
                    </div>
                }
            </div>
        </div>
    )
}

export default DerbyPage