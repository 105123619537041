// import React, { useEffect, useState } from "react";
// import { errorToaster, succesToaster } from "../../utils/toaster";

// const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i

// const TelegramComp = ({ userObj, setUserObj, setIsOpen, isOpen }) => {
//   console.log("setUserObj.....", isOpen)

//   useEffect(() => {
//     try {
//       const script = document.createElement("script");
//       script.src = "https://telegram.org/js/telegram-widget.js?22";
//       script.async = true;
//       document.body.appendChild(script);
//       window.onTelegramAuth = (user) => {
//         console.log("telegram  user", user)

//         // "user": {
//         // "id": 637186763,
//         // "first_name": "Sagar",
//         // "last_name": "(mr.J)",
//         // "username": "Sagar_didel56",
//         //       "photo_url": "https:\/\/t.me\/i\/userpic\/320\/5GP51LEuonYXGVFX9k4-v_J1jdLite3MHknaPKKuaKE.jpg",
//         //       "auth_date": 1690283286,
//         //       "hash": "d7c859dd440dd0156eab08efefc172c98e3605d074af6ec9dfa8ff83fc236b88"
//         // },
//         // "html": "<button class=\"btn tgme_widget_login_button\" onclick=\"return TWidgetLogin.auth();\"><i class=\"tgme_widget_login_button_icon\"><\/i>Log in as <span dir=\"auto\">Sagar<\/span><\/button><i class=\"tgme_widget_login_user_photo bgcolor3\" data-content=\"S\" onclick=\"return TWidgetLogin.auth();\"><img src=\"https:\/\/cdn5.telesco.pe\/file\/RdtXdCp8-I88Z_pPan6iHwzz0nizwmhpn1k7C51BTTKvBRp7vRjHbWCiocBXg9hJXsj8yy7Sur5-bUh5Y6zB7lGsG_pOxMkGSVj5nUfM1Fq97VS-TtIeMt0puO5qnzMCjGF8Lwg0gvTIJJhujX_7IbgKv4cheJAShW_IBOeHHjqtAWy8QqhXazDDGGCsNE4Tr7IWnaUV0Au8HaZ-fAOPf4y-Qg6k43XFnrf2vMKtYwFhaeAErA-MRLSyWqY0ScUkleS7kxqHjqmUA9mavybJHX_qrSkBXXOR_6NZcl8wPOGqeV4sfr4Fukl3JEQ-8I3ZycslQVvRrpU7a3DCA64osw.jpg\"><\/i>",
//         //   "origin": "https:\/\/damble.io"

//         // --------------------
//         // const userObj = {
//         //   firstName: user.first_name,
//         //   lastName: user.last_name,
//         //   userId: user.id,
//         //   username: user.username,
//         //   photoUrl: user.photo_url,
//         //   hash: user.hash
//         // }
//         console.log("setIsOpen", setIsOpen)
//         setIsOpen(prev => !prev)
//         setUserObj(user)
//         // alert("hello")
//         // console.log()
//         // telegramHandler(userObj)
//       };
//     } catch (error) {
//       console.log("error in catch", error)
//       errorToaster('Something went wrong')
//     }
//   }, []);

//   // useEffect(() => {
//   //   if (userObj?.id && Object.keys(userObj).length) {
//   //     setIsOpen(true)
//   //   }
//   // }, [userObj?.id, userObj.firstName, userObj.username])

//   useEffect(() => {
//     const loginButton = document.querySelector(".tgme_widget_login_button");
//     if (loginButton) {
//       const textContent = loginButton.textContent;
//       loginButton.innerHTML = textContent;
//     }
//   }, []);
//   return (
//     <>
//       <div className="TelegramComp"
//         style={{ color: "red", display: "flex" }}
//       >
//         <script
//           async
//           src="https://telegram.org/js/telegram-widget.js?22"
//           data-telegram-login="damble_01_bot"
//           data-size="small"
//           data-userpic="false"
//           data-radius="14"
//           data-onauth="onTelegramAuth(user)"
//           data-request-access="write"
//         />
//       </div>
//     </>
//   );
// };

// export default TelegramComp;


// TelegramLoginButton.js
import React from 'react';
import TelegramLogin from 'react-telegram-login';

const TelegramComp = ({ userObj, setUserObj, setIsOpen, isOpen }) => {

  const handleTelegramResponse = (response) => {
    // const userObj = {
    //         //   firstName: user.first_name,
    //         //   lastName: user.last_name,
    //         //   userId: user.id,
    //         //   username: user.username,
    //         //   photoUrl: user.photo_url,
    //         //   hash: user.hash
    //         // }
    // console.log("setIsOpen", setIsOpen)
    setIsOpen(prev => !prev)
    // console.log(response)
    setUserObj(response)
  };

  return (
    <TelegramLogin
      dataOnauth={handleTelegramResponse}
      botName="damble_01_bot"
      buttonSize="small"
    />
  );
};

export default TelegramComp;