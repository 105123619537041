import React from "react";

const UserManagementBadges = ({ userData,parentData }) => {
  return (
    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5 g-3 mb-3 ">
      {/* <div className="col-12 col-sm-6 col-lg-2">
        <div className="card-metric">
          <div className="metric">
            <p className="metric-value h3">
              <sub>
                <i className="oi oi-people" />
              </sub>{" "}
              <span className="value">{userData?.totalPoints} </span>
            </p>
            <h2 className="metric-label">Credit Reference</h2>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-lg-2">
        <div className="card-metric">
          <div className="metric">
            <p className="metric-value h3">
              <sub>
                <i className="oi oi-fork" />
              </sub>{" "}
              <span className="value">{userData?.availablePoints}</span>
            </p>
            <h2 className="metric-label"> Available Balance</h2>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-lg-2">
        <div className="card-metric">
          <div className="metric">
            <p className="metric-value h3">
              <sub>
                <i className="fa fa-tasks" />
              </sub>{" "}
              <span className="value">{userData?.downLevelBalance}</span>
            </p>
            <h2 className="metric-label"> Down Level Balance </h2>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-lg-2">
        <div className="card-metric">
          <div className="metric">
            <p className="metric-value h3">
              <sub>
                <i className="oi oi-timer" />
              </sub>{" "}
              <span
                className={`value ${
                  userData.profitLossPoints > 0
                    ? "text-success"
                    : userData.isProfit < 0
                    ? "text-red-PL"
                    : ""
                }`}
              >
                {userData?.profitLossPoints || 0}
              </span>
            </p>
            <h2 className="metric-label"> My P/L </h2>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-lg-2">
        <div className="card-metric">
          <div className="metric">
            <p className="metric-value h3">
              <sub>
                <i className="oi oi-timer" />
              </sub>{" "}
              <span className="value">{parentData?.profitLossPoints || 0}</span>
            </p>
            <h2 className="metric-label"> Upline P/L </h2>
          </div>
        </div>
      </div> */}
      <div className="col">
        <div class="adminTopCard bg-white p-3">
          <div class="d-flex align-items-center">
            {/* <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
              <img src="assets/img/present.svg" alt=""/>
            </div> */}
            <div class="">
              <div class="adminTopCardTitle">Credit Reference</div>
              <div class="adminTopCardSubtitle">{userData?.totalPoints?.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div class="adminTopCard bg-white p-3">
          <div class="d-flex align-items-center">
            {/* <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
              <img src="assets/img/present.svg" alt=""/>
            </div> */}
            <div class="">
              <div class="adminTopCardTitle">Available Balance</div>
              <div class="adminTopCardSubtitle">{userData?.availablePoints?.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div class="adminTopCard bg-white p-3">
          <div class="d-flex align-items-center">
            {/* <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
              <img src="assets/img/present.svg" alt=""/>
            </div> */}
            <div class="">
              <div class="adminTopCardTitle">Down Level Balance</div>
              <div class="adminTopCardSubtitle">{userData?.downLevelBalance?.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div class="adminTopCard bg-white p-3">
          <div class="d-flex align-items-center">
            {/* <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
              <img src="assets/img/present.svg" alt=""/>
            </div> */}
            <div class="">
              <div class="adminTopCardTitle">My P/L</div>
              <div class="adminTopCardSubtitle">{userData?.profitLossPoints || 0}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div class="adminTopCard bg-white p-3">
          <div class="d-flex align-items-center">
            {/* <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
              <img src="assets/img/present.svg" alt=""/>
            </div> */}
            <div class="">
              <div class="adminTopCardTitle">Upline P/L</div>
              <div class="adminTopCardSubtitle">{parentData?.profitLossPoints || 0}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagementBadges;
