import React from 'react'
import { useSelector } from 'react-redux'
import ContestCard from '../../../fantasyComponents/contestCard/ContestCard'
import { ROUTES_CONST } from '../../../../constants/routeConstant'
import { useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'

const MyContest = ({ joinedContest, match, joinContestLoading }) => {
  const joined = useSelector(state => state.joinedContest)
  const navigate = useNavigate()
  return (
    <div className="col-12 mt-3">
      {
        joinContestLoading ? (
          <div className='w-100 h-100 d-flex justify-content-center'>
            <Spinner color='white' size='sm' />
          </div>
        ) : (
          joinedContest?.length === 0 ? (
            <div className='w-100 h-100 d-flex justify-content-center text-light'>
              No Data Available
            </div>
          ) : (
            joined?.map((elem) => (
              <ContestCard
                myContest={true}
                practice={true}
                win_amount={elem?.winamount}
                entryfee={elem?.entryfee}
                maximum_user={elem?.maximum_user}
                joinedusers={elem?.joinedusers}
                winning_percentage={elem?.winning_percentage}
                multi_entry={elem?.multi_entry}
                confirmed_challenge={elem?.confirmed_challenge}
                // handleJoin={() => TeamSelection(elem)}
                handleLeader={(e) => {
                  e.stopPropagation();
                  navigate(ROUTES_CONST.FANTASY_LEADERbOARD, {
                    state: {
                      data: match,
                      el: elem,
                      contest: elem,
                      // selectingTeam: TeamSelection,
                      live: true,
                      // type: type,
                    }
                  })
                }}
              />
            ))
          )

        )

      }
    </div>
  )
}

export default MyContest