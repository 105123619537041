import React, { useEffect, useRef, useState } from "react";
import { postAPI, postAPIAuth, casinoPostAPIAuth } from "../../service/apiInstance";
import { useSelector } from "react-redux";
import { errorToaster, succesToaster } from "../../utils/toaster";
import InGameModal from "./inGameModal";
import { useAuth } from "../../hooks/useAuth";
import { useAuthModal } from "../../hooks/useAuthModal";
import { AUTH_MODAL_TABS, MOBILE_SCREEN_WIDTH } from "../../constants";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const PlayingGame = ({ setIsOpen, data, game, getGameData, providerName, isLoading }) => {

    // console.log("gameeeeeeeenaemee", game?.sessionId);
    const { token } = useAuth()
    const { userDetails } = useSelector(state => state)
    const { setAuthModalObject } = useAuthModal()
    const isSmScreen = useMediaQuery({ maxWidth: 330 })
    const iframeRef = useRef()
    const [showIframe, setShowIframe] = useState(false)
    const navigate = useNavigate()


    // useEffect(() => {
    //     if (isLoading) {
    //         setTimeout(() => {
    //             setShowIframe(true)
    //         }, 4000)
    //     }
    // }, [isLoading])

    // console.log("data--",data)
    // Function for to add fav
    const FavouriteGame = async () => {
        if (token) {
            let params = {
                "gameId": data?._id,
                "userId": userDetails?.id
            }
            console.log(data)
            const res = await postAPIAuth('crypto/create-favourite-games', params)
            if (res.data.status) succesToaster(res.data.message)
            getGameData()
        } else {
            setAuthModalObject(pre => ({ isAuthOpen: true, selectedTab: AUTH_MODAL_TABS.LOG_IN }))
        }
    }

    const toggleFullScreen = (e) => {
        e.stopPropagation();
        console.log("iframe click")
        const iframe = iframeRef.current;

        if (iframe) {
            if (iframe.requestFullscreen) {
                iframe.requestFullscreen();
            } else if (iframe.mozRequestFullScreen) {
                iframe.mozRequestFullScreen();
            } else if (iframe.webkitRequestFullscreen) {
                iframe.webkitRequestFullscreen();
            } else if (iframe.msRequestFullscreen) {
                iframe.msRequestFullscreen();
            }
        }
    };
    //  Function for Like
    const LikingGame = async () => {
        let params = {
            "gameId": data?._id,
            "userId": userDetails?.id
        }
        const res = await postAPIAuth('crypto/like-games', params)
        if (res.data.status) succesToaster(res.data.message)
        getGameData()
    }

    const getBalanceIframeData = async () => {

        // console.log("game?.sessionId", game?.sessionId);
        try {

            if (token) {

                const obj = {
                    partnerKey: "nXJ4rDcd46N7s9ysLYlzeXCpDS9rj9yOoTbYdrLYLidCZ0n13iCmB4DH9j3eG7SFhqjHDVX5Im8=",
                    userId: "66139dd63a84b1e307f22f36",
                    timestamp: "1628773197932"
                }

                try {
                    const res = await casinoPostAPIAuth(`casinoApi/Balance`, obj, token, game?.sessionId);
                    if (res?.data?.data?.success) {
                        console.log("getBalanceIframeData", res);
                    }
                } catch (error) {
                    // toast(`${error.response.data.data.message}`);
                    console.log("getBalanceIframeDataerror", error);

                }

            }

        } catch (error) {

        }

    }

    useEffect(() => {
        getBalanceIframeData()
    }, [game]);

    return (
        <div className="container-fluid holdwinHero gamesHeroSection pb-3">
            <div className="row gamesHeader gap-sm-0 gap-3 align-items-center">
                <div className="col pe-1">
                    <div className="d-flex align-items-center gap-3">
                        <div className="gamesHeading">{game?.gameName}</div>
                        <div onClick={() => navigate("/casino", { state: { provider_id: providerName } })} className="Hacksaw text-decoration-none cursor-pointer fw-bold">{providerName}</div>
                    </div>
                </div>
                <div className="col-auto ps-0">
                    <div className="d-flex align-items-center gap-2 justify-content-sm-start justify-content-between h-100">
                        <div className="d-flex align-items-center gap-2">

                            <button className="expandBtn" onClick={toggleFullScreen}>Expand</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-3">
                {token ? <>
                    <div className="col-12 px-0 position-relative">
                        {
                            !isSmScreen ? <>
                                <div className="iframeMain rounded-2 overflow-hidden">
                                    <iframe
                                        title="game iframe"
                                        ref={iframeRef}
                                        className="game-iframe w-100 h-100"
                                        src={game?.launchURL}
                                        allow="autopaly"
                                        allowFullScreen />
                                </div>
                                <InGameModal game={game} />
                            </> : ''
                        }
                    </div>
                    <div className="col-12 px-0 pt-md-4 pt-sm-3 pt-2">
                        <div className="d-flex align-itemscenter gap-2">
                            <div className="provablyfairCard d-flex align-items-center">
                                <div className="verifiedMain h-100 d-flex align-items-center ">
                                    <div className="verifiedIcon d-flex align-items-center justify-content-center">
                                        <img src="assets/img/sidebar/verified.png" alt="verified" className="h-100 w-100 img-fluid" />
                                    </div>
                                </div>
                                <div className="verifiedTitle text-center w-100 ">
                                    THIS GAME IS PROVABLY FAIR
                                </div>
                            </div>
                            <div className="lh-sm d-inline-flex align-items-center justify-content-center flex-column">
                                <a href="javascript:;" className="verifiedTitle">Verify Fairness</a>
                                <div className="verifiedTitle pt-1">{`(`}Advanced Users{`)`}</div>
                            </div>
                        </div>
                    </div>
                    {
                        isSmScreen ? <>
                            {
                                !showIframe ?
                                    <div className="vh-100 vw-100 iframeLoader position-fixed d-flex align-items-center justify-content-center top-0 start-0">
                                        <img src="assets/img/gameLoader.gif" alt="" className="h-100 w-100 object-fit-contain" />
                                    </div>
                                    : ''
                            }
                            <div class={`modal fade p-0 show d-block gameModalScreen ${showIframe ? '' : 'invisible'}`} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-fullscreen">
                                    <div class="modal-content p-0" >
                                        <div class="modal-body p-0" style={{ background: '#0d131c' }}
                                        >
                                            <div className="iframeMain rounde overflow-hidden">
                                                <iframe
                                                    title="game iframe"
                                                    ref={iframeRef}
                                                    className="game-iframe w-100 h-100"
                                                    src={game?.url}
                                                    allow="autopaly"
                                                    allowFullScreen />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> : ''
                    }
                </> : <>
                    <div className="col-12 ">
                        <div className="blinkLayer">
                            <div className="msgText">Please Log In To Play</div>
                        </div>
                    </div>
                </>
                }
            </div>
        </div>
    )
}

export default PlayingGame