import React from 'react'
import trophyIcon from './../../fantasyAssets/img/trophyIcon.png'
import { useSelector } from 'react-redux'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { useNavigate } from 'react-router-dom'

const Contest = ({ Contests, match }) => {
  const navigate = useNavigate()
  const profileData = useSelector(state => state.profileData)

  const handleLeader = (e, elem) => {
    e.stopPropagation();
    navigate(ROUTES_CONST.FANTASY_LEADERbOARD, {
      state: {
        data: match,
        el: elem,
        contest: elem,
        // selectingTeam: TeamSelection,
        live: true,
        // type: type,
      }
    })
  }

  return (
    <>
      {Contests?.map((item) => (
        <div onClick={e => handleLeader(e, item)} className="col-12 mb-3 cursor-pointer"  >
          <div className="row mx-0 position-relative contestCard">
            <div className="col-12">
              <div className="row align-items-center justify-content-between">
                <div className="col">
                  <div className="winnerCount position-absolute top-0 start-0">{item?.totalwinners} Winner</div>
                </div>
                <div className="col-auto">
                  <div className="entryPrz2">
                    <img src={trophyIcon} alt="" />
                    ₹ {item?.totalwinning}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="row align-items-center justify-content-between">
                <div className="col-4 winnerPrz winnerPrz2 text-white">{item.gift_type == 'gift' && !!item.gift_image ? (
                  <img src={item.gift_image} alt="" />
                ) : (
                  <div>🥇 ₹ {item.winamount}</div>
                )} </div>
                <div className="col-4 leftSpots text-center">
                  <span className='d-block bold'>{item.maximum_user}</span>
                  <span className="d-block light">Spots </span>
                </div>
                <div className="col-4 leftSpots text-end text-white">
                  <span className='d-block bold'>₹ {item.entryfee}</span>
                  <span className="d-block light">{item?.maximum_user - item?.joinedusers} Spots Left</span>
                </div>
              </div>
            </div>
            <div className="col-12 contestCardFooter mt-3 py-2">
              <div className="row align-items-center">
                <div className="col-4 d-flex align-items-center">
                  <div className="gurantyTxt">
                    {profileData?.team} ({item?.userteamnumber})
                  </div>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <div className="gurantyTxt text-center w-100">
                    {item?.totalwinning}
                  </div>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <div className="gurantyTxt text-end w-100">
                    #{item?.userrank}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default Contest