import React, { useEffect, useState } from 'react'
import './style.scss'
import { getAPIAuth } from '../../service/apiInstance'
import { useAuth } from '../../hooks/useAuth'
import moment from 'moment'
import DarkPagination from '../darkPagination/DarkPagination'
import { dateFormatter } from '../../Admin/AdminUtils/Utils'
import { errorToaster } from '../../utils/toaster'
import { useNavigate } from 'react-router-dom'

const OpenBetModal = ({ showBets, setShowBets }) => {
  const [loader, setLoader] = useState(true)
  const [totalPages, setTotalPages] = useState(null);
  // console.log("totalPages", totalPages);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([])
  const [casinoData, setCasinoData] = useState([])
  const [activeTab, setActiveTab] = useState('sports');
  const [binaryData, setBinaryData] = useState([]);
  const [derbybets, setDerbyBets] = useState([]);
  const navigate = useNavigate()
  console.log("derbybets", derbybets);
  const { token } = useAuth()
  const itemsPerPage = 10;

  // sports bets
  const getMyBets = async () => {
    setLoader(true)
    try {
      const res = await getAPIAuth(`bet/mybets?status=open&page=${currentPage}&perPage=${itemsPerPage}`, token)
      if (res?.data?.data?.success) {
        setData(res?.data?.data?.data)
        setTotalPages(res?.data?.data?.pagination?.totalPages)
      }
    } catch (error) {
      setData([])
    } finally {
      setLoader(false)
    }
  }

  const getCasinoBets = async () => {
    setLoader(true)
    try {
      const res = await getAPIAuth(`casino/myBets?status=open&page=${currentPage}&perPage=${itemsPerPage}`, token)
      if (res?.data?.data?.success) {
        setCasinoData(res?.data?.data?.data)
        setTotalPages(res?.data?.data?.pagination?.totalPages)
      }
    } catch (error) {
      setCasinoData([])
    } finally {
      setLoader(false)
    }
  }

  const getMyBinaryBets = async () => {
    setLoader(true)
    try {
      const res = await getAPIAuth(`bet/getAllMyBinaryBets?status=open&page=${currentPage}&perPage=${itemsPerPage}`, token);
      console.log('binaarttresresres', res)
      if (res?.data?.data?.success) {
        setTotalPages(res?.data?.data?.data?.pagination?.totalPages)
        setBinaryData(res?.data?.data?.data?.getUserBets)
        // setBetSummary(res?.data?.data?.data?.betDetails)
      }
    } catch (error) {
      setData([])
    } finally {
      setLoader(false)
    }
  }

  const getMyderbyBets = async () => {
    setLoader(true)


    try {

      const res = await getAPIAuth(`bet/derbyBetLists?status=open&page=${currentPage}&perPage=${itemsPerPage}`, token);
      console.log('qazresresres', res)
      if (res?.data?.data?.success) {
        setTotalPages(res?.data?.data?.data?.pagination?.totalPages)
        setDerbyBets(res?.data?.data?.data?.getUserBets)
        // setBetSummary(res?.data?.data?.data?.betDetails)
      }
    } catch (error) {
      setDerbyBets([])
      console.log("tttttttttttttt", error);
      errorToaster(error?.response?.data?.data?.message);
    } finally {
      setLoader(false)
    }
  }

  // useEffect(() => {
  //   getMyBets()
  // }, [selectedBet, gameId, startDate, endDate, currentPage])


  useEffect(() => {
    if (showBets) {
      if (activeTab === 'sports') {
        getMyBets()
      } else if (activeTab === 'casino') {
        getCasinoBets()
      }
      else if (activeTab === 'binary') {
        getMyBinaryBets()

      }
      else {
        getMyderbyBets();
      }
    }
  }, [showBets, activeTab, currentPage, itemsPerPage])

  return (
    <>
      <div class={`modal openBetModal fade ${showBets ? 'show d-block' : ''}`} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <div className="row mx-0 align-items-center w-100 justify-content-between">
                <div className="col modalTitle">
                  Open Bets
                </div>
                <div className="col-auto modalClose" onClick={() => setShowBets(false)}>
                  <img src="assets/img/sidebar/close.png" alt="" />
                </div>
              </div>
            </div>
            <div class="modal-body">
              <div className="d-flex align-items-center openBetsTabs ms-3">
                <button className={`openBetTab ${activeTab === 'sports' ? 'active' : ''}`} onClick={() => setActiveTab('sports')}>Sports</button>
                <button className={`openBetTab ${activeTab === 'casino' ? 'active' : ''}`} onClick={() => { setActiveTab('sports'); navigate('/casino-bet-history'); setShowBets(false) }} >Casino</button>
                <button className={`openBetTab ${activeTab === 'binary' ? 'active' : ''}`} onClick={() => setActiveTab('binary')}>Binary</button>
                <button className={`openBetTab ${activeTab === 'derby' ? 'active' : ''}`} onClick={() => setActiveTab('derby')}>Derby</button>

              </div>
              {
                activeTab === 'sports' ? (
                  <>
                    <div className="row mx-0 tradingPages">
                      <div className="col-12 dambleTable reportsTable sectionInner tableCard tab-content tab-pane table-responsive my-0">
                        <table className="table align-middle text-white text-nowrap mb0">
                          <thead>
                            <tr>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  Sr No.
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  place date
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  match
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  match date
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  market
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  bet on
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  bet type
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  odds
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  stake
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  returns
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              !loader ?
                                data?.length > 0 ?
                                  data?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{((currentPage - 1) * itemsPerPage + 1) + index}</td>
                                      <td className='text-uppercase'>
                                        {moment(item?.matchdetails?.event?.openDate).format('l, h:mm a')}
                                      </td>
                                      <td>{item?.matchdetails?.event?.name}</td>
                                      <td className='text-uppercase'>
                                        {moment(item?.matchdetails?.event?.openDate).format('l')}
                                      </td>
                                      <td>{item?.marketName}</td>
                                      <td>{item?.selectionCountryName}</td>
                                      <td>{item?.betType}</td>
                                      <td>{item?.odds}</td>
                                      <td>{item?.amount?.toFixed(2)}</td>
                                      <td>{item?.potentialWin?.toFixed(2)}</td>
                                    </tr>
                                  ))
                                  : 'no data'
                                : 'loading'
                            }
                          </tbody>
                        </table>
                      </div>
                      {
                        totalPages > 1 &&
                        <DarkPagination
                          totalPages={totalPages}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      }
                    </div>
                  </>
                ) : activeTab === 'casino' ? (
                  <>
                    <div className="row mx-0 tradingPages">
                      <div className="col-12 dambleTable reportsTable sectionInner tableCard tab-content tab-pane table-responsive my-0">
                        <table className="table align-middle text-white text-nowrap mb0">
                          <thead>
                            <tr>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  Sr No.
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  date
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  match
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  bet type
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  odds
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  stake
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              casinoData?.length > 0 ?
                                casinoData?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{((currentPage - 1) * itemsPerPage + 1) + index}</td>
                                    <td className='text-uppercase'>
                                      {moment(item?.matchdetails?.event?.openDate).format('l, h:mm a')}
                                    </td>
                                    <td className='text-capitalize'>{item?.matchdetails?.name}</td>
                                    <td>{item?.betType}</td>
                                    <td>{item?.roundId}</td>
                                    <td>{item?.amount?.toFixed(2)}</td>
                                  </tr>
                                ))
                                : 'no data'
                            }
                          </tbody>
                        </table>
                      </div>
                      {
                        casinoData.length && totalPages > 1 &&
                        <DarkPagination
                          totalPages={totalPages}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      }
                    </div>
                  </>
                ) : activeTab === 'derby' ? (
                  <>
                    <div className="row mx-0 tradingPages">
                      <div className="col-12 dambleTable reportsTable sectionInner tableCard tab-content tab-pane table-responsive my-0">
                        <table className="table align-middle text-white text-nowrap mb0">
                          <thead>
                            <tr>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  Sr No.
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  User Name
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  Transaction Id
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  User Id
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  GameId
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  Type
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  Amount
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  status
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  Potential Win
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            {
                              !loader ?
                                derbybets?.length > 0 ?
                                  derbybets?.map((item, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item?.username}</td>

                                      <td>{item?.transaction_id}</td>
                                      <td>{item?.userId}</td>
                                      <td>{item?.gameId}</td>
                                      <td>{item?.type}</td>
                                      <td>{item?.amount}</td>
                                      <td>{item?.status}</td>
                                      <td className={`${item?.status === 'lost' ? 'text-danger' : item?.status === 'won' ? 'text-success' : ''}`}>{item?.potentialWin?.toFixed(2)}</td>
                                    </tr>
                                  ))
                                  : <tr>
                                    <td colSpan={10} className='text-center'>No Data</td>
                                  </tr>
                                : 'loading'
                            }
                          </tbody>
                        </table>
                      </div>
                      {
                        derbybets.length && totalPages > 1 &&
                        <DarkPagination
                          totalPages={totalPages}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      }
                    </div>
                  </>
                ) : activeTab === 'binary' ? (
                  <>
                    <div className="row mx-0 tradingPages">
                      <div className="col-12 dambleTable reportsTable sectionInner tableCard tab-content tab-pane table-responsive my-0">
                        <table className="table align-middle text-white text-nowrap mb0">
                          <thead>
                            <tr>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  Sr No.
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  place date
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  Market Name
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  Match Date
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  market
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  Bet Type
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  odds
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  stake
                                </div>
                              </th>
                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  outcome
                                </div>
                              </th>

                              <th>
                                <div className="tabletxt d-flex align-items-center">
                                  returns
                                </div>
                              </th>

                            </tr>
                          </thead>
                          <tbody>
                            {
                              binaryData?.length > 0 ?
                                binaryData?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    {/* <td>{item?.userId?.username}</td> */}
                                    <td>  {dateFormatter(
                                      item?.createdAt,
                                      true
                                    )}</td>
                                    <td>{item?.marketName}</td>
                                    <td>  {dateFormatter(
                                      item?.updatedAt,
                                      false
                                    )}</td>
                                    <td>{item?.marketName}</td>
                                    <td>{item?.betType}</td>
                                    <td>{Number(item?.odds).toFixed(2)}</td>
                                    <td>{item?.amount?.toFixed(2)}</td>
                                    <td className='text-capitalize'>{item?.status}</td>
                                    <td className={`${item?.status === 'lost' ? 'text-danger' : item?.status === 'won' ? 'text-success' : ''}`}>{item?.amount?.toFixed(2)}</td>
                                  </tr>
                                ))
                                : 'no data'
                            }
                          </tbody>
                        </table>
                      </div>
                      {
                        binaryData.length && totalPages > 1 &&
                        <DarkPagination
                          totalPages={totalPages}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      }
                    </div>
                  </>
                ) : null
              }

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OpenBetModal