import React from 'react'
import AppFooter from '../../../components/AppFooter/AppFooter'
import downArrow from './../../TradingAssets/img/downarrowtable.svg'
import redArrow from './../../TradingAssets/img/redicon.svg'
import greenArrow from './../../TradingAssets/img/greenicon.svg'
import closeIcon from './../../TradingAssets/img/closeicon.svg'
import searchIcon from './../../TradingAssets/img/search.png'
import plusAdd from './../../TradingAssets/img/plus.svg'
import tabletop from './../../TradingAssets/img/tabletop.svg'
import tabledown from './../../TradingAssets/img/tabledown.svg'
import paginationArrow from './../../TradingAssets/img/peginationArrow.png'
import paginationarrowright from './../../TradingAssets/img/paginationarrowright.png'

import './../../TradingAssets/scss/trading.scss'




const TradingSummaryReport = () => {
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage tradingPages">
            {/* start here */}

            <div class="row gap-4">
            <div class="col-12">
                <div class="row gap-3">
                  <div class="col-12">
                    <div class="row g-3 align-items-center ">
                      <div className="col-lg-2 col-sm-6">
                        <div className="row">
                          <div className="col-12">
                            <label For="Tarde" className="tradeLabel text-white">SELECT MARKET</label>
                          </div>
                          <div className="col-12">
                          <div class="dropdown">
                            <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Select Market
                            </button>
                            <ul class="dropdown-menu dropdownMenu w-100">
                              <li>
                                <div class="dropdown-item dropdownItem">
                                <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                    <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                      <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                    </span>
                                    <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                                </div>
                                  <div className="txt">
                                    Please enter 1 or More Character
                                  </div>
                                </div>
                                </li>
                            </ul>
                          </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6">
                        <div className="row">
                          <div className="col-12">
                            <label For="Tarde" className="tradeLabel text-white">SELECT SCRIPT</label>
                          </div>
                          <div className="col-12">
                          <div class="dropdown">
                            <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Script
                            </button>
                            <ul class="dropdown-menu dropdownMenu w-100">
                              <li>
                                <div class="dropdown-item dropdownItem">
                                <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                    <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                      <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                    </span>
                                    <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                                </div>
                                  <div className="txt">
                                    Please enter 1 or More Character
                                  </div>
                                </div>
                                </li>
                            </ul>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6">
                        <div className="row">
                          <div className="col-12">
                            <label For="Tarde" className="tradeLabel text-white">SELECT VALAN</label>
                          </div>
                          <div className="col-12">
                          <div class="dropdown">
                            <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Select Valan
                            </button>
                            <ul class="dropdown-menu dropdownMenu w-100">
                              <li>
                                <div class="dropdown-item dropdownItem">
                                <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                    <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                      <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                    </span>
                                    <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                                </div>
                                  <div className="txt">
                                    Please enter 1 or More Character
                                  </div>
                                </div>
                                </li>
                            </ul>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6">
                        <div className="row">
                          <div className="col-12">
                            <label For="Tarde" className="tradeLabel text-white">SELECT MASTER</label>
                          </div>
                          <div className="col-12">
                          <div class="dropdown">
                            <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Select Master
                            </button>
                            <ul class="dropdown-menu dropdownMenu w-100">
                              <li>
                                <div class="dropdown-item dropdownItem">
                                <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                    <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                      <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                    </span>
                                    <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                                </div>
                                  <div className="txt">
                                    Please enter 1 or More Character
                                  </div>
                                </div>
                                </li>
                            </ul>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6">
                        <div className="row">
                          <div className="col-12">
                            <label For="Tarde" className="tradeLabel text-white">SELECT BROKER</label>
                          </div>
                          <div className="col-12">
                          <div class="dropdown">
                            <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Select Broker
                            </button>
                            <ul class="dropdown-menu dropdownMenu w-100">
                              <li>
                                <div class="dropdown-item dropdownItem">
                                <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                    <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                      <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                    </span>
                                    <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                                </div>
                                  <div className="txt">
                                    Please enter 1 or More Character
                                  </div>
                                </div>
                                </li>
                            </ul>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6">
                        <div className="row">
                          <div className="col-12">
                            <label For="Tarde" className="tradeLabel text-white">SELECT CLIENT</label>
                          </div>
                          <div className="col-12">
                          <div class="dropdown">
                            <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Select Client
                            </button>
                            <ul class="dropdown-menu dropdownMenu w-100">
                              <li>
                                <div class="dropdown-item dropdownItem">
                                <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                    <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                      <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                    </span>
                                    <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                                </div>
                                  <div className="txt">
                                    Please enter 1 or More Character
                                  </div>
                                </div>
                                </li>
                            </ul>
                        </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row gap-3">
                  <div class="col-12">
                    <div class="row row-cols-6 g-3 align-items-end justify-content-center">
                      <div className="col-xxl col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="row">
                          <div className="col-12">
                            <label For="Tarde" className="tradeLabel text-white">START DATE</label>
                          </div>
                          <div className="col-12">
                            <input type="datetime-local" class="dateTimeInput"/>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="row">
                          <div className="col-12">
                            <label For="Tarde" className="tradeLabel text-white">END DATE</label>
                          </div>
                          <div className="col-12">
                            <input type="datetime-local" class="dateTimeInput"/>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="row">
                          <div className="col-12"><a href="javascript:;" class="sumbitBtn">SUMBIT </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="row">
                          <div className="col-12"><a href="javascript:;" class="sumbitBtn redBtn">CLEAR FILTER </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="row">
                          <div className="col-12"><a href="javascript:;" class="sumbitBtn blueLightBtn">SCRIPT WISE SUMMARY </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="row">
                          <div className="col-12"><a href="javascript:;" class="sumbitBtn">BUY/SELL TURNOVER </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </div>
            

            <div className="row mx-0 pt-4 justify-content-sm-between justify-content-center text-sm-start text-center align-items-center gap-sm-0 gap-2">
              <div className="col-sm-auto col">
                <div className="row">
                  <div className="col-sm-auto col px-1">
                    <div className="txt">
                      SHOW
                    </div>
                  </div>
                  <div className="col px-1">
                    <div className="row">
                      <div className="col-12">
                      </div>
                      <div className="col-12">
                      <div class="dropdown w-100">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            10
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100 entryMenu">
                            <li>
                              <div class="dropdown-item dropdownItem">
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">20</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">50</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">80</a></li>
                              </div>
                              </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col px-1">
                    <div className="txt">
                      Entries
                    </div>
                  </div>
                </div>
              </div>
              <div className='col d-flex justify-content-center'>
                <label for='csv' className='csvLbl me-2'>CSV</label>
                <input type='file' className='d-none' id='csv'/>
                <label for='pdf' className='csvLbl'>PDF</label>
                <input type='file' className='d-none' id='pdf'/>
              </div>
              <div className="col-lg-3 col-sm-6 ">
              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                  <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                </span>
                <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
              </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col-12 px-0 dambleTable sectionInner mb-0 tableCard tab-content tab-pane table-responsive">
                <table class="table align-middle text-white text-nowrap">
                <thead>
                  <tr>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                      SR NO.
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        {/* <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        {/* <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    NAME
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        {/* <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        {/* <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    ALL
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        {/* <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        {/* <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    OUTSTANDING
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        {/* <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        {/* <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    NET MTM
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        {/* <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        {/* <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    TOTAL MTM
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        {/* <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        {/* <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    DOWNLOAD MTM
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        {/* <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        {/* <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    UPLINE MTM
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        {/* <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        {/* <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    SELF MTM
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        {/* <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        {/* <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    NET POSITION
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        {/* <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        {/* <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/> */}
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td >
                      <div className="d-flex align-items-center ">
                      a b c
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                    123
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                      ---
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                     xxx
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                    xyz
                      </div>
                    </td>
                
            
                    <td >
                      <div className=" d-flex align-items-center ">
                      a b c
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                   qwer
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                      a b c
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                5963
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                      a b d
                      </div>
                    </td>

                  </tr>
                </tbody>
                </table>
              </div>
              <div class="col-12">
                <div class="peginationTable d-flex align-items-center gap-2 justify-content-end">
                  <a href='javascript:;' class="arrowpagination d-inline-flex align-items-center justify-content-center">
                  <img src={paginationArrow} class="img-fluid w-100 h-100 " alt="table accordion"/>
                  </a>
                  <a href='javascript:;' class="arrowpagination d-inline-flex align-items-center justify-content-center">1</a>
                  <a href='javascript:;' class="arrowpagination d-inline-flex align-items-center justify-content-center">2</a>
                  <a href='javascript:;' class="arrowpagination d-inline-flex align-items-center justify-content-center">3</a>
                  <a href='javascript:;' class="arrowpagination d-inline-flex align-items-center justify-content-center">
                  <img src={paginationarrowright} class="img-fluid w-100 h-100 " alt="table accordion"/>
                  </a>
                </div>
              </div>
            </div>




            {/* end here */}
          </div>
        </div>
        {/*  **** Footer section *** */}
        <AppFooter />
      </main>
    </>
  )
}

export default TradingSummaryReport