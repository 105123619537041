import React ,{ useState, createContext  } from 'react'

export const DownloadModalContext = createContext()

const DownloadModalContextProvider = ({children}) => {
  const [downloadObject, setDownloadObject] = useState({
    isDownloadOpen : false
  })
  return (
      <DownloadModalContext.Provider value={{downloadObject, setDownloadObject}}>{children}</DownloadModalContext.Provider>
  )
}

export default DownloadModalContextProvider