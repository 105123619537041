import React, { useEffect, useState } from 'react'
import { adminPostAPIAuth } from '../../AdminServices'
import { errorToaster, succesToaster } from '../../../utils/toaster'

const UpdateBankDetails = ({ item, getPaymentDetails }) => {
    const initialValue = { type: item.type, bankName: item.bankName, accountNumber: item.AccountNumber, ifscCode: item.ifscCode, accHolderName: item.accHolderName, status: item.status }
    const [uTableData, setUTableData] = useState(initialValue)
    const [validationError, setValidationError] = useState(false)

    useEffect(() => {
        setUTableData({ type: item.type, bankName: item.bankName, accountNumber: item.AccountNumber, ifscCode: item.ifscCode, accHolderName: item.accHolderName, status: item.status })
    }, [item])

    const handleChange = (e) => {
        const { name, value } = e.target
        setUTableData({ ...uTableData, [name]: value })
    }

    const updatePaymentDetails = async(e) => {
        e.preventDefault()
        if (uTableData.bankName === '' || uTableData.accountNumber === '' || uTableData.ifscCode === '' || uTableData.accHolderName === '' || uTableData.status === '') {
            setValidationError(true)
        } else {
            try {
                const body = {
                    typename : "userPaymentDetails",
                    flag : uTableData.type,
                    bankName : uTableData.bankName,
                    AccountNumber : uTableData.accountNumber,
                    ifscCode : uTableData.ifscCode,
                    accHolderName : uTableData.accHolderName,
                    status : uTableData.status,
                    id : item._id,
                }
                const res = await adminPostAPIAuth('user/updateUserPaymentDetails', body)
                if (res?.data?.data?.message) {
                    setUTableData(res?.data?.data?.data)
                    getPaymentDetails()
                    succesToaster('Bank Details Successfully Updated')
                    setValidationError(false)
                }
            } catch (error) {
                errorToaster(error?.response?.data?.message)
            }
        }
    }

    return (
        <>
            <form onSubmit={updatePaymentDetails}>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Type<span className="text-danger">*</span></label>
                    <input type="text" name='type' className="form-control" id="exampleInputEmail1" value={uTableData.type} onChange={handleChange}/>
                </div>
                <h6>Editable Details</h6>
                <div className="mt-4 d-flex gap-2">
                    <div>
                        <label htmlFor="exampleInputEmail1" className="form-label">Bank Name<span className="text-danger">*</span></label>
                        <input type="text" name='bankName' className="form-control" id="exampleInputEmail1" value={uTableData.bankName} onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="exampleInputEmail1" className="form-label">Account No.<span className="text-danger">*</span></label>
                        <input type="text" name='accountNumber' className="form-control" id="exampleInputEmail1" value={uTableData.accountNumber} onChange={handleChange}/>
                    </div>
                </div>
                <div className="mt-4 d-flex gap-2">
                    <div>
                        <label htmlFor="exampleInputEmail1" className="form-label">IFSC Code<span className="text-danger">*</span></label>
                        <input type="text" name='ifscCode' className="form-control" id="exampleInputEmail1" value={uTableData.ifscCode} onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="exampleInputEmail1" className="form-label">Account Holder Name<span className="text-danger">*</span></label>
                        <input type="text" name='accHolderName' className="form-control" id="exampleInputEmail1" value={uTableData.accHolderName} onChange={handleChange}/>
                    </div>
                </div>
                <div className="mt-4 d-flex gap-2">
                    <div>
                        <label htmlFor="exampleInputEmail1" className="form-label">Status<span className="text-danger">*</span></label>
                        <input type="text" name='status' className="form-control" id="exampleInputEmail1" value={uTableData.status} onChange={handleChange}/>
                    </div>
                </div>
                <div className={`mt-2 ${validationError === true ? (uTableData.bankName === '' || uTableData.accountNumber === '' || uTableData.ifscCode === '' || uTableData.accHolderName === '' || uTableData.status === '') ? 'd-block' : 'd-none' : 'd-none' }`}>
                    <span style={{color: 'red'}}>Fill all the fields</span>
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary mt-4" 
                    data-bs-dismiss={`${(uTableData.bankName === '' || uTableData.accountNumber === '' || uTableData.ifscCode === '' || uTableData.accHolderName === '' || uTableData.status === '') ? '' : "offcanvas"}`}
                    aria-label={`${(uTableData.bankName === '' || uTableData.accountNumber === '' || uTableData.ifscCode === '' || uTableData.accHolderName === '' || uTableData.status === '') ? '' : "Close"}`}
                >
                    Submit
                </button>
            </form>

        </>
    )
}

export default UpdateBankDetails
