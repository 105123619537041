// import { useContext } from "react"
// import { WalletModalContext } from "../context/walletModalContext"


// export const useWalletModal = () => {
//     const { walletInOpen, setWalletInOpen } = useContext(WalletModalContext)
//     return {
//         walletInOpen,
//         setWalletInOpen
//     }
// }

import { useContext } from "react"
import { WalletModalContext } from "../context/walletModalContext"


export const useWalletModal = () => {
    const { walletModalObject, setWalletModalObject } = useContext(WalletModalContext)
    return {
        walletModalObject,
        setWalletModalObject
    }
}
