import React from 'react'
import upcomingIcon from '../../sportsAssets/img/upBetCard.svg'
import { useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'

const RelatedMatches = ({ data }) => {
  // console.log('>>>>>>>>>>>>>>>>>>>>>>>', data)
  const navigate = useNavigate()
  return (
    <>
      <div className="col-auto">
        {/* add active class to selected match */}
        <div
          className="sportsUpcomingMatches"
          onClick={() => navigate(`${ROUTES_CONST.SPORTS}/${data?._id}|+|${data?.event?.id}|+|${data?.marketId}`)}
        >
          <div className="spMatchName">
            <img src={upcomingIcon} alt="" />
            {data?.competition?.name}
          </div>
          <div className="spMatchTeam">
            {data?.runners?.[0]?.runnerName}
          </div>
          <div className="spMatchTeam">
            {data?.runners?.[1]?.runnerName}
          </div>
        </div>
      </div>
    </>
  )
}

export default RelatedMatches