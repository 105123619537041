import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_URL, PORTAL } from "../../AdminConstants/urlPaths";
import CustomButton from "../../Admincomponents/customButton/CustomButton";
import { USER } from "../../AdminConstants/apiEndpoints";
import { adminLogIn } from "../../../store/action";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { ADMIN_AUTH_ACCESS, ADMIN_AUTH_ROLE, ADMIN_LOGGED_IN_ID } from "../../../constants";
import { ROUTES_CONST } from "../../../constants/routeConstant";
import { adminPostAPI } from "../../AdminServices";
import PasswordInput from "../../Admincomponents/passwordInput/PasswordInput";
import AdminLoader from "../../Admincomponents/adminLoader/AdminLoader";

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminToken = useLocalStorage(ADMIN_AUTH_ACCESS, "");
  const adminID = useLocalStorage(ADMIN_LOGGED_IN_ID, "");
  const adminRole = useLocalStorage(ADMIN_AUTH_ROLE, '')
  const [formValues, setFormValues] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const handleChange = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      username: formValues.username,
      password: formValues.password,
    };
    try {
      const res = await adminPostAPI(USER.login, payload);
      if (res.status === 200) {
        dispatch(adminLogIn(res?.data));
        adminToken.updateValue(res?.data?.data?.token);
        adminID.updateValue(res?.data?.data?.data?._id);
        adminRole.updateValue(res?.data?.data?.data?.role);
        if (res?.data?.data?.data?.role === "user") {
          navigate(ROUTES_CONST.INDEX);
        } else {
          navigate(
            `/${PORTAL}/` + res?.data?.data?.data?.role + "/" + DASHBOARD_URL
          );
        }
      }
    } catch (error) {
      setError(error?.response?.data?.data?.message);
    }
  };

  useEffect(()=> {
    setTimeout(() => {
      setLoading(true)
    }, 3000);
  },[])


  return (
    <>
    {loading ? 
      <main className="auth signinCustom" style={{ height: "100vh" }}>
        <header
          id="auth-header"
          className="auth-header"
          style={{ backgroundImage: "/assets/images/illustration/img-1.png" }}
        >
          {/* SignIn-textSize have css in app */}
          <p className="SignIn-textSize">Sign In </p>
        </header>
        <form className="auth-form">
          <div className="mb-3">
            <label htmlFor="username" className="form-label">
            Username
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              value={formValues.username}
              onChange={handleChange}
            />
          </div>

          <div>
          <label htmlFor="exampleFormControlInput1" className="form-label">
              Password
            </label>
            <PasswordInput
              id="password"
              name="password"
              value={formValues.password}
              onChange={handleChange}
            />
          </div>
          {error && (
            <div className="admin-forms-error-text mb-2">
              <div>{error}</div>
            </div>
          )}
          <div className="form-group pt-4">
            <CustomButton type="submit" handleClick={handleSubmit}>
              Sign In
            </CustomButton>
          </div>
        </form>
      </main>
      : <AdminLoader/>
    }
    </>
  );
};

export default SignIn;
