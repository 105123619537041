import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'

const CustomStockList = ({
  companyData,
  distribute2,
  distribute1,
  stockContest,
  percent1,
  percent2,
  setDistribute2,
  setDistribute1,
  setpercent1,
  editflag,
  EditStocks
}) => {
  const [rangeVal, setRangeVal] = useState(5)
  const [secondRangeVal, setSecondRangeVal] = useState(5)
  const navigate = useNavigate()
  const rangeChangeHandler = (e, type) => {
    if (type == "firstRange") {
      let total = Number(e.target.value) + Number(secondRangeVal)
      if (total <= 100 && e.target.value >= 5 && e.target.value <= 95) {
        setRangeVal(Number(e.target.value))
        setDistribute1(Number(e.target.value))
        companyData[0].percentage = e.target.value

      }
    } else if (type == "secondRange") {
      let total = Number(rangeVal) + Number(e.target.value)

      if (total <= 100 && e.target.value >= 5 && e.target.value <= 95) {
        setSecondRangeVal(Number(e.target.value))
        setDistribute2(Number(e.target.value))
        companyData[1].percentage = e.target.value
      }
    }
  }

  useEffect(() => {
    setDistribute2(Number(rangeVal))
    companyData[0].percentage = rangeVal
    setDistribute1(Number(secondRangeVal))
    companyData[1].percentage = secondRangeVal
  }, [])
  // console.log({ distribute2, distribute1, entryfee: stockContest?.entryfee, stockContest }, rangeVal + secondRangeVal === 100)
  // console.log(Math.floor(distribute2 + distribute1, stockContest?.entryfee - (stockContest?.entryfee * (distribute2 + distribute1) / 100)));
  // console.log(Math.floor("--->>>", stockContest?.entryfee - (stockContest?.entryfee * (distribute2 + distribute1) / 100)));

  return (
    <>
      <div className="row mx-0 mt-3">
        <div className="col-12 customStockProgress text-center">
          <div className="bigTxt1">Allocate virtual coins in your stocks</div>
        </div>
        <div className="col-12 customStockProgress text-center">
          <svg>
            <circle cx="70" cy="70" r="70"></circle>
            <circle cx="70" cy="70" r="70" style={{ '--percent': distribute2 + distribute1 }}></circle>
          </svg>
          <div className="stockProgressTxt">
            <div className="txt1">
              {/* 100% */}
              {Math.floor(distribute2 + distribute1)}%
            </div>
            <div className="txt2">
              {Number(stockContest?.entryfee - (stockContest?.entryfee * (distribute2 + distribute1) / 100)).toFixed(2)}
            </div>
            <div className="txt2">Price Left</div>
          </div>
        </div>
      </div>
      <div className="row mx-0 gy-3 mt-3">
        <div className="col-12">
          <div className="row mx-0 inputRangeCard">
            <div className="col-12">
              <div className="row align-items-center inputRangeTxt mb-2">
                <div className="col-auto">
                  <div className="circle">
                    {/* <img src="" alt="" /> */}
                  </div>
                </div>
                <div className="col ps-0">
                  <div className="title">{companyData?.length && companyData[0]?.name}</div>
                  <div className="subVal">
                    <span>0% </span>
                    <span>{companyData?.length && companyData[0]?.closePrice}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 rangeInput">
              <div className="row">
                <div className="col">
                  <input
                    type="range"
                    min="0"
                    max="100"
                    style={{ background: `linear-gradient(to right, #6f64e4 ${rangeVal}%, #242937 ${rangeVal}%)` }}
                    value={rangeVal}
                    // onChange={(e) => setRangeVal(e.target.value)}
                    // onChange={rangeChangeHandler}
                    onChange={(e) => rangeChangeHandler(e, "firstRange")}
                  />
                </div>
                <div className="col-auto">
                  <div className="rangeVal">{rangeVal}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row mx-0 inputRangeCard">
            <div className="col-12">
              <div className="row align-items-center inputRangeTxt mb-2">
                <div className="col-auto">
                  <div className="circle">
                    {/* <img src="" alt="" /> */}
                  </div>
                </div>
                <div className="col ps-0">
                  <div className="title">{companyData?.length && companyData[1]?.name}</div>
                  <div className="subVal">
                    <span>0% </span>
                    <span>{companyData?.length && companyData[1]?.closePrice}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 rangeInput">
              <div className="row">
                <div className="col">
                  <input type="range" min="0" max="100"
                    style={{ background: `linear-gradient(to right, #6f64e4 ${secondRangeVal}%, #242937 ${secondRangeVal}%)` }}
                    value={secondRangeVal}
                    // onChange={(e) => setSecondRangeVal(e.target.value)}
                    onChange={(e) => rangeChangeHandler(e, "secondRange")}

                  />
                </div>
                <div className="col-auto">
                  <div className="rangeVal">{secondRangeVal}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="createTeamBtn"
        onClick={() => {
          if (rangeVal + secondRangeVal === 100 && (stockContest?.entryfee - (stockContest?.entryfee * (distribute2 + distribute1) / 100) === 0)) {
            if (editflag) {
              EditStocks();
              return
            } else {
              navigate(ROUTES_CONST.FANTASY_STOCK_USABLE_BALANCE, {
                state: {
                  companyData: companyData,
                  stockContest: stockContest,
                }
              })
            }
          }
        }}
      >
        Continue
      </button>
    </>
  )
}

export default CustomStockList