import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { adminGetAPI, adminGetAPIAuth } from "../../AdminServices";
import UpdateCryptoDetails from "../cryptoDetails/UpdateCryptoDetails";
import AddCryptoDetails from "../cryptoDetails/AddCryptoDetails";
import { useSelector } from "react-redux";
import Pagination from "../../Admincomponents/pagination/Pagination";

const ViewPayments = () => {
    const adminDetails = useSelector(state => state.adminData)
    const [currentPage, setCurrentPage] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [id, setId] = useState('');
    const [itemsPerPage, setItemPerPage] = useState()



    const getPaymentDetails = async () => {
        try {
            const res = await adminGetAPIAuth("user/getUserPaymentDetails?flag=crypto")
            setTableData(res?.data?.data?.data);
        } catch (error) {
            console.log('error------', error)
        }
    }
    const getPaymentVerifictionDetails = async () => {
        try {
            const res = await adminGetAPIAuth("user/getPaymentVerifictionAccRole"+`?_id=${adminDetails?._id}&page=${currentPage}`,)
            console.log(res.data.data.pagination)
            setCurrentPage(res.data.data.pagination.page);
            setTotalPages(res.data.data.pagination.totalPages);
            setItemPerPage(res.data.data.pagination.perPage);
            setPaymentData(res.data.data.response);
        } catch (error) {
            console.log('error------', error)
        }
     }
        const getActionsButton=async(userId, flag)=>{
        try {
            const res = await adminGetAPIAuth("user/paymentVerificationAcceptReject"+`?id=${userId}&flag=${flag}`,)
            if(res.data.status){
                getPaymentVerifictionDetails();
            }
        } catch (error) {
            console.log('error------', error)
        }
        }

    useEffect(() => {
        getPaymentDetails()
        if(adminDetails?._id){
        getPaymentVerifictionDetails();
        }
    }, [adminDetails?._id, currentPage])

    const startIndex = (currentPage - 1) * itemsPerPage + 1;

    return (
        <>
            <div className="app">
                <main className="app-main">
                    <div className="wrapper">
                        <div className="page">
                            <div className="page-inner ">
                                <header className="page-title-bar justify-content-between row">
                                    <h1 className="page-title col-md"> View Payments</h1>
                                    <div className=" page-section align-items-center w-auto m-0  flex-row-reverse col-md-auto">
                                        {!tableData.length > 0 && <AddCryptoDetails getPaymentDetails={getPaymentDetails} />}
                                    </div>
                                </header>
                                <div className="d-flex justify-content-between my-3">
                                    <div>
                                        <ol className="breadcrumb"></ol>
                                    </div>
                                </div>

                                <div className="page-section">
                                    <div className=" card-fluid">
                                        <div className="card-body px-0">
                                            <div className="table-responsive">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                style={{ minWidth: "140px", cursor: "pointer" }}
                                                                className="p-2 d-flex justify-content-evenly"
                                                            >
                                                                <span>S.No</span>
                                                            </th>

                                                            <th
                                                                style={{ minWidth: "150px", cursor: "pointer" }}
                                                                className="p-2"
                                                            >
                                                                <span>User Name</span>
                                                            </th>
                                                            <th
                                                                style={{ minWidth: "140px", cursor: "pointer" }}
                                                                className="p-2"
                                                            >
                                                                <span>Email</span>
                                                            </th>
                                                            <th
                                                                style={{ minWidth: "140px", cursor: "pointer" }}
                                                                className="p-2"
                                                            >
                                                                <span>Amount</span>
                                                            </th>
                                                            <th
                                                                style={{ minWidth: "140px", cursor: "pointer" }}
                                                                className="p-2"
                                                            >
                                                                <span>Image</span>
                                                            </th>
                                                            <th
                                                                style={{ minWidth: "140px", cursor: "pointer" }}
                                                                className="p-2"
                                                            >
                                                                <span>Transaction ID</span>
                                                            </th>
                                                            <th
                                                                style={{ minWidth: "140px", cursor: "pointer" }}
                                                                className="p-2"
                                                            >
                                                                <span>Action</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paymentData?.length > 0 ? paymentData?.map((item, index) => (
                                                            <tr key={item?._id}>
                                                                <td className="align-middle  d-flex justify-content-evenly pt-3">{startIndex+index}</td>
                                                                <td className="align-middle">{item?.username}</td>
                                                                <td className="align-middle">{item?.email}</td>
                                                                <td className="align-middle">{item?.amount}</td>
                                                                <td className="align-middle"><img className="rounded-circle" src={item?.image} alt="err" width = "50" height = "50"/></td>
                                                                <td className="align-middle">{item?.userTransactionId}</td>
                                                                {item.status==="pending" &&(<>
                                                                <td className="align-middle d-flex" ><Button className="mx-1 fw-bold" variant="info" onClick={()=>getActionsButton(item._id,1)}>VERIFY</Button>
                                                               <Button className="mx-1 fw-bold" variant="info" onClick={()=>getActionsButton(item._id,2)}>Reject</Button></td>
                                                                </> )}
                                                                {item.status==="approved" &&(<td className="align-middle "><Button className="mx-1 fw-bold" variant="success">{item.status}</Button></td>)}
                                                                {item.status==="rejected" &&(<td className="align-middle"><Button className="mx-1 fw-bold" variant="danger">{item.status}</Button></td>)}
                                                            </tr>
                                                        )) :

                                                            <tr>
                                                                <td colSpan={6} className="text-center" style={{ fontSize: 16, fontWeight: 500 }}>No Data Found</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                             {
                                                    totalPages > 1 ? 
                                                    <Pagination
                                                    totalPages={totalPages}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    />
                                                    : ''
                                                    }
                                            <div
                                                className="offcanvas offcanvas-end"
                                                tabindex="-1"
                                                id="offcanvasRight"
                                                aria-labelledby="offcanvasRightLabel"
                                            >
                                                <div className="offcanvas-header">
                                                    <h5
                                                        className="offcanvas-title"
                                                        id="offcanvasRightLabel"
                                                    >
                                                        Edit Details
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="offcanvas"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div className="offcanvas-body">
                                                    <UpdateCryptoDetails item={id} getPaymentDetails={getPaymentDetails} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <hr className="my-5" />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default ViewPayments;
