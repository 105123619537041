import React, { useEffect, useState } from 'react'
import { sportsGetAPIAuth } from '../../sportsServices'
import { useSelector } from 'react-redux'

const MatchScore = ({eventId, selectedGameId}) => {
  const [matchScoreData, setMatchScoreData] = useState([])

  const getMatchScore = async ()=> {
    try {
      const res = await sportsGetAPIAuth(`bet/getScore?eventTypeId=${selectedGameId}&eventId=${eventId}`)
      if(res?.data?.status === 200) {
        setMatchScoreData(res?.data?.data?.data?.data)
      }
    } catch (error) {
      
    }
  }



  useEffect(()=> {
    getMatchScore()
    const interval = setInterval(() => {
      getMatchScore()
    }, 30000 );
    return ()=> {
      clearInterval(interval)
    }
  }, [selectedGameId, eventId])



  return (
    <>
    {
      matchScoreData?.teams?.length ? 
          <div className="matchScoreBox">
            {
              matchScoreData?.teams?.map(item=> (
                <>
                  <div className="row align-items-center teamStats">
                    <div className="col-sm-6">
                            <div className="row">
                              <div className="col-md">{item?.team_short_name}</div>
                              <div className="col-md-auto">
                                {item?.score}
                                
                                {item?.team_short_name === matchScoreData?.current_inning ? <span className='ps-2'>CRR {matchScoreData?.currentRunRate}</span> : ''}
                              </div>
                            </div>
                    </div>
                    <div className="col-sm-6">
                              {
                                item?.team_short_name === matchScoreData?.current_inning ? 
                                  <div className="text-sm-end">{matchScoreData?.msg}</div>
                                : <>
                                  <div className="oversData d-flex justify-content-sm-end mt-1 mt-sm-0">
                                    {
                                      matchScoreData?.last24ballsNew?.map(ele=> (
                                        <div className={`over ${ele?.score_card === 'ww' ? 'wicket' : ''}`}>{ele?.score_card}</div>
                                      ))
                                    }
                                  </div>
                                </>
                              }
                    </div>
                  </div>
                </>
              )) 
            }
          </div>
      : ''
    }
    </>
  )
}

export default MatchScore