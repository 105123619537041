import React from "react";
import FantasyHeader from "../../fantasyComponents/fantasyHeader/FantasyHeader";
import { useLocation, useSearchParams } from "react-router-dom";
import MatchCard from "../../fantasyComponents/matchCard/MatchCard";
import { useState } from "react";
import MyContest from "./MyContest";
import Myteam from "./MyTeam";
import MyStats from "./MyStats";
import { getFantasyAuth } from "../../fantasyServices";
import { useEffect } from "react";

const MyMatchesContest = () => {
    const [activeTab, setActiveTab] = useState('contest')
    const { state } = useLocation()
    const [PlayerStatsData, setPlayerStatsData] = useState([]);
    const [myTeams, SetMyTeams] = useState([]);
    const [liveMatches, SetLiveMatches] = useState([]);
    const [Contests, SetContests] = useState([]);


    // Function to get contest
    const getContest = async () => {
        let res = await getFantasyAuth(`/myjoinedcontests?matchkey=${state.match.matchkey}`);
        SetContests(res?.data?.data);
        getPlayerStats();
    };

    //   Function to get live score
    const getLiveScore = async () => {
        let res = await getFantasyAuth(`/getlivescores?matchkey=${state.match.matchkey}`);
        SetLiveMatches(res?.data?.data);
        getContest();
    };

    //   Function to get playerStats
    const getPlayerStats = async () => {
        let res = await getFantasyAuth(
            `/getAllPlayersWith_PlayingStatus/${state.match.matchkey}`,
        );
        setPlayerStatsData(res?.data?.data);
        getMyTeams();
    };


    //   Function to get my teams data
    const getMyTeams = async () => {
        let res = await getFantasyAuth(`/getMyTeams?matchkey=${state.match.matchkey}`);
        SetMyTeams(res?.data?.data);
        // getLiveScore();
    };


    useEffect(() => {
        getLiveScore();
    }, []);

    return (
        <>
            <div className="row fantasyInner">
                <FantasyHeader gameHeader={true} title={`${state?.match?.team1ShortName} - ${state?.match?.team2ShortName}`} />
                {/* <div class="row mx-0  flex-column flex-nowrap createTeam mt-3"> */}
                <div class="col-12 px-0 cricketheader z-2">
                    <div class="card border-0 matchesCard overflow-hidden border border-1 border-light rounded-0">
                        <div class="card-body p-0">
                            <MatchCard
                                key={state?.match?.matchkey}
                                matchName={state?.match?.matchname}
                                series_name={state?.match?.series_name}
                                team1ShortName={state?.match?.team1ShortName}
                                team2ShortName={state?.match?.team2ShortName}
                                status={state?.match?.status}
                                match={state?.match}
                                final_status={state?.match?.final_status}
                                winning_status={state?.match?.winnerstatus}
                                team1logo={state?.match?.team1logo}
                                team2logo={state?.match?.team2logo}
                                start_date={state?.match?.start_date}
                                playing11_status={state?.match?.playing11_status}
                                teamfullname1={state?.match?.team1fullname}
                                teamfullname2={state?.match?.team2fullname}
                                time_start={state?.match?.start_date}
                                id={state?.match?.matchkey}
                            />
                        </div>
                    </div>
                </div>
                {/* </div> */}
                <div className="row mt-3 align-items-center justify-content-between contestOptionsTabInner">
                    <div className="col-4">
                        <div
                            onClick={() => setActiveTab('contest')}
                            className={`contestOptionTab text-center text-uppercase ${activeTab === 'contest' ? 'active' : ''}`}>Contest</div>
                    </div>
                    <div className="col-4">
                        <div
                            onClick={() => setActiveTab('myTeam')}
                            className={`contestOptionTab text-center text-uppercase ${activeTab === 'myTeam' ? 'active' : ''}`}>My Teams</div>
                    </div>
                    <div className="col-4">
                        <div
                            onClick={() => setActiveTab('mystats')}
                            className={`contestOptionTab text-center text-uppercase ${activeTab === 'mystats' ? 'active' : ''}`}>My Stats</div>
                    </div>
                </div>
                {
                    activeTab === 'contest' ? <MyContest contest={Contests} /> :
                        activeTab === 'myTeam' ? <Myteam /> :
                            activeTab === 'mystats' ? <MyStats /> : ''
                }
            </div>

        </>
    )
}

export default MyMatchesContest