import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Nav, Row } from "react-bootstrap";
import PasswordInput from "../passwordInput/PasswordInput";
import { useSelector } from "react-redux";
import { adminGetAPIAuth, adminPostAPI, adminPostAPIAuth } from "../../AdminServices";
import { REPORTS, USER } from "../../AdminConstants/apiEndpoints";
import { succesToaster } from "../../../utils/toaster";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { ADMIN_AUTH_ACCESS } from "../../../constants";
// import { succesToaster } from "../../../utils/toaster";

//eslint-disable-next-line
const MarketCatagoriesForm = ({ setShow, data }) => {
    // console.log("datadatadatadatadatadata", data);
    const [name, setName] = useState("");

    const changeStatusApi = async () => {

        const payload = {
            name: `${name}`,
        };
        try {
            const res = await adminPostAPIAuth(USER.addStockCategory, payload);
            console.log("fdsghasdfshfdgy", res);
            if (res.status === 200) {
                // succesToaster("Status Updated Successfully! ");
                data(true);
                setName("");
                setShow(false);

            }
        } catch (error) {
            // setError(error?.response?.data?.data?.message);
        }
    };




    return (
        <>


            <Form onClick={(e) => e.preventDefault()}>
                <Container>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="remarks">Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={
                                        name
                                    }
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <Button type="submit" onClick={changeStatusApi}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </>
    );
};

export default MarketCatagoriesForm;
