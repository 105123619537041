import React, { useState } from "react";

export const ReferralModalContext = React.createContext(null)

const ReferralModalContextProvider = ({ children }) => {
    const [isReferral, setIsReferral] = useState(false)
    return <ReferralModalContext.Provider value={{ isReferral, setIsReferral }}>{children}</ReferralModalContext.Provider>;
};

export default ReferralModalContextProvider;
