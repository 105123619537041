import React, { useState } from "react";
import { AUTH_MODAL_TABS } from "../constants";


export const AuthModalContext = React.createContext(null)

const AuthModalContextProvider = ({ children }) => {
    // const [isAuthOpen, setIsAuthOpen] = useState(false)
    const [authModalObject, setAuthModalObject] = useState({
        isAuthOpen: false,
        selectedTab: AUTH_MODAL_TABS.LOG_IN
    })
    return <AuthModalContext.Provider value={{ authModalObject, setAuthModalObject }}>{children}</AuthModalContext.Provider>;
};

export default AuthModalContextProvider;
