import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAPIAuth, postAPIAuth } from "../../../service/apiInstance";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import moment from "moment";
import DarkPagination from "../../darkPagination/DarkPagination";

function Settings({ openaccordian, tabopen }) {
  const fantasyUserData = useSelector((state) => state?.fantasyUserData);
  const [userEmail, setUserEmail] = useState()
  const [userPassword, setUserPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [userMobile, setUserMobile] = useState('')
  const [error, setError] = useState({
    email: '',
    password: '',
    oldPassword: '',
    mobile: ''
  })
  const [isLoading, setIsLoading] = useState(true)
  const [loginData, setLoginData] = useState([])
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/


  useEffect(()=> {
    setUserEmail(fantasyUserData?.email)
    setUserMobile(fantasyUserData?.mobile)
  }, [fantasyUserData])

  // change user email password
  const handleEmail = async () => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmail)) {
      setError((prev) => ({
        ...prev,
        email: '',
      }));
      try {
        const res = await postAPIAuth('user/updateUserCridential', {
          email: userEmail
        })
        if (res?.data?.data?.success) {
          succesToaster('Email Changed Successfully')
          // setUserEmail('')
        } else {
          errorToaster('Something error has happened')
        }
      } catch (error) {

      }
    } else {
      setError((prev) => ({
        ...prev,
        email: 'Enter valid email',
      }));
    }
  }
  // change user password
  const handlePassword = async () => {
    // if (!passwordRegx.test(oldPassword)) {
    //   setError((prev) => ({
    //     ...prev,
    //     oldPassword: '',
    //   }));
    console.log('userPassword', userPassword)
    console.log('userPasswordddd', passwordRegx.test(userPassword))

      if (passwordRegx.test(userPassword)) {
        setError((prev) => ({
          ...prev,
          password: '',
        }));
        try {
          const res = await postAPIAuth('user/updateUserCridential', {
            oldPassword: oldPassword,
            password: userPassword
          })
          if (res?.data?.data?.success) {
            succesToaster('Password Changed Successfully')
            // setUserPassword('')
          } else {
            errorToaster('Something error has happened')
          }
        } catch (error) {
          console.log(error)
          errorToaster(error?.response?.data?.data?.message)
        }
      }
      else {
        setError((prev) => ({
          ...prev,
          password: 'Password must be at least 8 characters and include one uppercase letter, one lowercase letter, and one number.',
        }));
      }
    // } else {
    //   setError((prev) => ({
    //     ...prev,
    //     oldPassword: 'Password must be at least 8 characters and include one uppercase letter, one lowercase letter, and one number.',
    //   }));
    // }
  }

    // change user mobile
    const handleMobile = async () => {
      if (userMobile.length === 10) {
        setError((prev) => ({
          ...prev,
          mobile: '',
        }));
        try {
          const res = await postAPIAuth('user/updateUserCridential', {
            mobile: userMobile
          })
          if (res?.data?.data?.success) {
            succesToaster('Mobile Number Changed Successfully')
            // setUserPassword('')
          } else {
            errorToaster('Something error has happened')
          }
        } catch (error) {
  
        }
      } else {
        setError((prev) => ({
          ...prev,
          mobile: 'Enter valid mobile number',
        }));
      }
    }

  const getLoginHistory = async () => {
    try {
      const res = await getAPIAuth(`user/getLogginHistory?page=${currentPage}&perPage=${itemsPerPage}`)
      if (res?.data?.data?.success) {
        setLoginData(res?.data?.data?.data)
        setTotalPages(res?.data?.data?.pagination?.totalPages)
      } else {
        //error
      }
    } catch (error) {
      console.log("login-error", error)
    } finally {
      setIsLoading(false)
    }
  }
  
  useEffect(() => {
    if (tabopen === 'Settings' || openaccordian === "Settings") {
      getLoginHistory()
    }
  }, [tabopen, openaccordian, currentPage])
  return (
    <>
      <div className="row">
        <div className="col-12 d-none d-md-block">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/setting.png"
                alt="SETTINGS"
                className="h-100 w-100 img-fluid "
              />
            </div>
            SETTINGS
          </div>
        </div>
        <div className="col-12 cardsubHeading settingHeading">CHANGE EMAIL</div>
        <div className="col-12 pt-2 pt-sm-3">
          <div className="d-flex align-items-sm-center flex-column flex-sm-row gap-2 gap-sm-3">
            <div className="inputGroup w-100 position-relative d-flex">
              <input
                className="EmailInput form-control w-100 shadow-none"
                type="email"
                id="email"
                placeholder={"New Email"}
                value={userEmail}
                onChange={(e) => {setUserEmail(e.target.value)}}
              />
              {/* <label className="emailLabel position-absolute" htmlFor="email">
                NEW EMAIL <span>*</span>{" "}
              </label> */}
            </div>
            <button
              onClick={handleEmail}
              className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center justify-content-center "
            >
              CHANGE EMAIL
            </button>
          </div>
          {error?.email ? <div className="text-danger errorTxt">{error?.email}</div> : ''}
        </div>
        <div className="col-12 cardsubHeading settingHeading pt-4">CHANGE MOBILE NUMBER</div>
        <div className="col-12 pt-2 pt-sm-3">
          <div className="d-flex align-items-sm-center flex-column flex-sm-row gap-2 gap-sm-3">
            <div className="inputGroup w-100 position-relative d-flex">
              <input
                className="EmailInput form-control w-100 shadow-none"
                type="text"
                id="mobile"
                placeholder={"New Mobile Number"}
                value={userMobile}
                onChange={(e) => {setUserMobile(e.target.value)}}
              />
              {/* <label className="emailLabel position-absolute" htmlFor="email">
                NEW EMAIL <span>*</span>{" "}
              </label> */}
            </div>
            <button
              onClick={handleMobile}
              className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center justify-content-center "
            >
              CHANGE MOBILE
            </button>
          </div>
          {error?.email ? <div className="text-danger errorTxt">{error?.email}</div> : ''}
        </div>
        <div className="col-12 cardsubHeading settingHeading pt-4">CREATE PASSWORD</div>
        <div className="col-12 pt-2 pt-sm-3">
        <div className="d-flex align-items-sm-center flex-column flex-sm-row gap-2 gap-sm-3 mb-3">
            <div className="inputGroup w-100 position-relative d-flex">
              <input
                className="EmailInput form-control w-100 shadow-none"
                type="password"
                id="password"
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex align-items-sm-center flex-column flex-sm-row gap-2 gap-sm-3">
            <div className="inputGroup w-100 position-relative d-flex">
              <input
                className="EmailInput form-control w-100 shadow-none"
                type="password"
                id="password"
                placeholder="New Password"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
              />
            </div>
            <button
              onClick={handlePassword}
              className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center justify-content-center "
            >
              CHANGE PASSWORD
            </button>
          </div>
          {error?.password ? <div className="text-danger errorTxt">{error?.password}</div> : ''}
        </div>
        {/* will be of future use */}
        {/* <div className="col-12 cardsubHeading settingHeading pt-5">
          TWO-FACTOR AUTHENTICATION{" "}
          <button
            className="border-0 cardsubHeading settingHeading disabled bg-transparent"
            disabled
          >
            DISABLED
          </button>
        </div>
        <div className="col-12 affiliateCardTitle pt-3">
          Using two-factor authentication is highly recommended because it
          protects your account with both your password and your phone.
        </div>
        <div className="col-12 pb-2 affiliateCardTitle pt-3">
          An email and password must be set to activate 2FA. While 2FA is
          enabled, you will not be able to login via Steam.
        </div>
         <div className="col-auto pt-4">
          <a
            href="javascript:;"
            className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center justify-content-center "
          >
            ENABLE 1 FA
          </a>
        </div> */}
        {/* <div className="col-12 cardsubHeading settingHeading pt-4 pt-sm-5">
          VERIFY YOUR IDENTITY (KYC)
        </div>
        <div className="col-auto pt-3">
          <a
            href="javascript:;"
            className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center justify-content-center "
          >
            VERIFY
          </a>
        </div> */}
        <div className="col-12 cardsubHeading settingHeading pt-4 pt-sm-5">LOGIN HISTORY</div>
        <div className="col-12 pt-3">
          <div className="table-responsive scrollShortTable">
            <table className="table affiliateTable mb-2 align-middle">
              <thead>
                <tr className="tableHead text-nowrap">
                  <th scope="col" className="border-0 rounded-start-2 px-4">
                    DATE
                  </th>
                  <th scope="col" className="border-0 px-4">
                    LOGIN METHOD
                  </th>
                  <th scope="col" className="border-0 px-4 texter">
                    COUNTRY
                  </th>
                  <th
                    scope="col"
                    className="border-0 px-4 text-er rounded-end-2"
                  >
                    IP
                  </th>
                </tr>
              </thead>
              {
                isLoading ? (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    {/* <Spinner animation="border" variant="light" size="sm" /> */}
                  </div>
                ) : (
                  <tbody className="tablebody">
                    {
                      loginData?.length === 0 ? (
                        <div className="w-100 h-100">
                          <h6 className="text-light"> No data available</h6>
                        </div>
                      ) : (
                        loginData?.map(
                          (item) => (
                            <tr key={item?._id} className="text-nowrap">
                              <td scope="row" className="border-0  rounded-start-2 px-4">
                                {/* Aug 14 2023, 11:17 */}
                                {moment(item?.createdAt).format("MMM DD YYYY , HH:mm")}
                              </td>
                              <td className="border-0 px-4">{item?.login_method === "Registeration" ? "Registration" : item?.login_method}</td>
                              <td className="border-0 px-4">{item?.country ? item?.country : '_'}</td>
                              <td className="border-0 px-4">{item?.ip_address ? item?.ip_address : "_"}</td>
                            </tr>
                          )
                        )
                      )
                    }
                  </tbody>
                )
              }
            </table>
          </div>    {
                totalPages > 1 ? 
                  <DarkPagination 
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
                : ''
              }
        </div>
      </div>
    </>
  );
}

export default Settings;
