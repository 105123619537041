import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { REPORTS, USER } from "../../AdminConstants/apiEndpoints";
import { returnEndDate } from "../../../utils/returnEndDate";
import { adminGetAPIAuth } from "../../AdminServices";
import { errorToaster } from "../../../utils/toaster";
import { dateFormatter } from "../../AdminUtils/Utils";
import Pagination from "../../Admincomponents/pagination/Pagination";
import { useParams } from "react-router-dom";
import AdminTxModal from "../../Admincomponents/AdminTxModal/AdminTxModal";

const transactionArr = [
    {
        value: "",
        label: "All",
    },
    {
        value: "deposit",
        label: "Deposit",
    },
    {
        value: "withdraw",
        label: "Withdraw",
    },
    // {
    //   value: "settlements-deposit",
    //   label: "Settlements Deposit",
    // },
    // {
    //   value: "settlements-withdraw",
    //   label: "Settlements Withdraw",
    // },
    // {
    //   value: "bet-settlements",
    //   label: "Bet Settlements",
    // },
    // {
    //   value: "rollback",
    //   label: "Rollback",
    // },
    // {
    //   value: "Voided",
    //   label: "voided",
    // },
];

const AccountStatementTab = () => {
    const [tableData, setTableData] = useState([]);
    const [showTxModal, setShowTxModal] = useState(false);
    const [txDetails, setTxDetails] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);

    const [childrenList, setChildrenList] = useState([]);
    const [searchUser, setSearchUser] = useState("");
    const [selectedUser, setSelectUser] = useState({});
    const [searchedUserResult, setSearchedUserResult] = useState([]);
    const [inputFocused, setInputFocused] = useState(false);
    const { userId } = useParams()
    const [filterValues, setFilterValues] = useState({
        transactions: "",
        startDate: "",
        endDate: "",
    });


    const adminData = useSelector((state) => state?.adminData);
    // console.log(adminData);

    // set today's date as end date
    useEffect(() => {
        const today = new Date()
        setFilterValues((prev) => ({
            ...prev,
            endDate: returnEndDate(today)
        }))
    }, [])

    const getChildrenList = async () => {
        try {
            const res = await adminGetAPIAuth(
                USER.children + "/" + adminData?._id + "?perPage=0"
            );
            if (res?.data?.status === 200) {
                setChildrenList(res?.data?.data?.data?.children);
            }
        } catch (error) {
            setChildrenList([]);
        }
    };

    useEffect(() => {
        if (adminData?._id) {
            getChildrenList();
        }
        //eslint-disable-next-line
    }, [adminData]);

    const handleChange = (e) => {
        setFilterValues((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleGetList = async (item) => {
        const queryParams = Object.entries(filterValues)
            .filter(([key, value]) => value !== "")
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join("&");
        try {
            const res = await adminGetAPIAuth(
                REPORTS?.myaccountstatement +
                `?page=${currentPage}&perPage=10&type=${filterValues?.transactions === "withdraw" ? 'debit' : filterValues?.transactions === "deposit" ? 'credit' : ''}&startDate=${filterValues?.startDate}&endDate=${filterValues?.endDate}&userId=` +
                (userId ? userId : item?._id)
                // + (queryParams?"&"+queryParams:"")
            );
            if (res?.data?.data?.success) {
                setTableData(res?.data?.data?.data);
                setTotalPages(res?.data?.data?.pagination?.totalPages);
                // setSearchUser('');
            } else {
                errorToaster("Something went wrong in fetching data");
            }
        } catch (error) {
            errorToaster(error?.response?.data?.data?.message);
            setTableData([]);
            console.log("error -------------->", error);
        }
    };
    // useEffect(()=>{

    // })
    useEffect(() => {
        if (searchUser) {
            const filtered = childrenList?.filter(
                (item) =>
                    item?.name?.toLowerCase()?.includes(searchUser?.toLowerCase()) ||
                    item?.username?.toLowerCase()?.includes(searchUser?.toLowerCase())
            );
            setSearchedUserResult(filtered);
        } else {
            setSearchedUserResult([]);
            setSelectUser({});
        }
        //eslint-disable-next-line
    }, [searchUser]);

    useEffect(() => {
        if (userId) {
            handleGetList();
        }
        //eslint-disable-next-line
    }, [
        selectedUser,
        currentPage,
        filterValues.endDate,
        filterValues.startDate,
        filterValues.transactions,
    ]);

    // console.log('selectedUser', selectedUser)
    // console.log('searchUser', searchUser)
    // console.log('searchedUserResult', searchedUserResult)



    const handleInputBlur = () => {
        setTimeout(() => {
            setInputFocused(false);
        }, 500);
    };

    return (<>
        <div className="page">
            <div className="page-inner ">
                {/* <header className="page-title-bar justify-content-between row align-items-center">
                    <h1 className="page-title mb-0 text-nowrap">
                        {" "}
                        User Account Statement{" "}
                    </h1>
                </header> */}
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="row align-items-end">
                            <div className="col-md-auto pe-md-0 mb-2 mb-md-0">
                                {/* <div className="adminSearchInput ">
                                    <input
                                        type="text"
                                        placeholder="Search User"
                                        value={searchUser}
                                        onChange={(e) => setSearchUser(e?.target?.value)}
                                        className="form-control adminFilterInp"
                                        onFocus={() => setInputFocused(true)}
                                        onBlur={handleInputBlur}
                                    />
                                    <div className="overflow-hidden">
                                        {searchedUserResult?.length && inputFocused ? (
                                            <>
                                                <ul className="list-unstyled adminsearchDropDown overflow-Y-auto">
                                                    {
                                                        searchedUserResult?.map((item) => (
                                                            <li
                                                                className="userName"
                                                                key={item?._id}
                                                                onClick={() => {
                                                                    console.log('item', item)
                                                                    setSearchUser(item.username);
                                                                    setSelectUser(item);
                                                                    // handleGetList(item);
                                                                }}
                                                            >
                                                                {item.username}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-md px-0">
                                <div className="row justify-content-end mx-md-0">
                                    <div className="col-md-auto col-12 col-sm-4 pe-md-0 mb-2 mb-sm-0">
                                        <label
                                            htmlFor="transactions"
                                            className="form-label"
                                        >
                                            Transactions
                                        </label>
                                        <select
                                            name="transactions"
                                            id="transactions"
                                            className="form-select adminFilterInp"
                                            onChange={handleChange}
                                        >
                                            {transactionArr.map((item) => (
                                                <option value={item.value}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                                        <label htmlFor="startDate" className="form-label">
                                            From
                                        </label>
                                        <input
                                            type="date"
                                            name="startDate"
                                            id="startDate"
                                            className="form-control adminFilterInp"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                                        <label htmlFor="endDate" className="form-label">
                                            To
                                        </label>
                                        <input
                                            type="date"
                                            name="endDate"
                                            min={filterValues?.startDate}
                                            value={filterValues?.endDate}
                                            id="endDate"
                                            className="form-control adminFilterInp"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 px-0">
                        <div className="table-responsive">
                            <table className="table">
                                <thead className="text-uppercase text-nowrap">
                                    <tr>
                                        <th>
                                            <div className="d-flex">Date & Time</div>
                                        </th>
                                        <th>
                                            <div className="d-flex">Transaction Type</div>
                                        </th>
                                        {/* <th>
                                  <div className="d-flex">Event</div>
                                </th>
                                <th>
                                  <div className="d-flex">Market Type</div>
                                </th> */}
                                        <th>
                                            <div className="d-flex">Credit/Debit</div>
                                        </th>
                                        <th>
                                            <div className="d-flex">Balance</div>
                                        </th>
                                        <th>
                                            <div className="d-flex">Transaction ID</div>
                                        </th>
                                    </tr>
                                </thead>
                                {
                                    // Object.keys(selectedUser).length ?
                                    tableData.length ?
                                        <tbody>
                                            {tableData.map((item) => (
                                                <tr>
                                                    <td>{dateFormatter(item.createdAt, true)}</td>
                                                    <td
                                                        className="fw-bold cursor-pointer"
                                                        onClick={() => {
                                                            setTxDetails(item);
                                                            setShowTxModal(true);
                                                        }}
                                                    >
                                                        {item?.transactionType}{" "}
                                                    </td>
                                                    {/* <td> </td>
                                    <td> </td> */}
                                                    <td className={`${!item?.isDeposit ? 'admin-forms-error-text' : 'admin-forms-success-text'}`}>
                                                        {item?.points ? item?.points?.toFixed(2) : 0.00}
                                                    </td>
                                                    <td>{item?.totalPoints?.toFixed(2)}</td>
                                                    <td>{item._id}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        :
                                        <tbody>
                                            <tr>
                                                <td colSpan={9}>
                                                    <div className="text-center" style={{ fontSize: 18, fontWeight: 500 }} >Account statement not found</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    // :
                                    // <tbody>
                                    //     <tr>
                                    //         <td colSpan={9}>
                                    //             <div className="text-center" style={{ fontSize: 18, fontWeight: 500 }} >Please select a user to view statement</div>
                                    //         </td>
                                    //     </tr>
                                    // </tbody>
                                }
                            </table>
                        </div>
                    </div>
                    {
                        totalPages > 1 ?
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            /> : ''
                    }
                </div>
            </div>
        </div>
        <AdminTxModal
            show={showTxModal}
            setShow={setShowTxModal}
            data={txDetails}
        />
    </>);
};

export default AccountStatementTab;
