import React from 'react'
import './style.scss'
import AppFooter from '../../components/AppFooter/AppFooter'
import { useAuth } from '../../hooks/useAuth';
import BannerBeforeLogin from './BannerBeforeLogin';
import BannerAfterLogin from './BannerAfterLogin';
import { useState, useEffect } from 'react';
import SportsTab from './ArchiveTab';
import CasinoTab from './PromotionTab';
import { getAPIAuth } from '../../service/apiInstance';
import Ticketcards from './ticketscards/Ticketcards';
import AllTickets from './AllTickets';

const Jackpot = () => {
  const { token } = useAuth();
  const [archiveActive, setArchiveActive] = useState('jackports');

  const toggleTab = (tab) => {
    setArchiveActive(tab);
  };

  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage promotionPage">
            <div className="row h-100 align-items-center">
              <div className="col-12">
                <div className="promotionBtn d-inline-flex align-items-center justify-content-center cursor-pointer">
                  <img src="./assets/img/promotions/promotionWhite.png" alt="provider icon" className='promotionIcon me-3' />
                  Jackpot
                </div>
              </div>
              <div className="col-12">
                <div className="promotionBanner">
                  {!token ?
                    <>
                      {/* before Login */}
                      <BannerBeforeLogin />
                    </>
                    :
                    <>
                      {/* after login */}
                      <BannerAfterLogin />
                    </>
                  }
                </div>
              </div>
              <div className="col-12">
                <div className="promotionsTabs d-inline-flex align-items-center">
                  <div
                    className={`promotionTab d-flex align-items-center justify-content-center ${archiveActive === 'jackports' ? 'active' : ''}`}
                    onClick={() => toggleTab('jackports')}>
                    Jackpots
                  </div>
                  {/* <div
                    className={`promotionTab d-flex align-items-center justify-content-center ${archiveActive === 'result' ? 'active' : ''}`}
                    onClick={() => toggleTab('result')}>
                    Result
                  </div> */}
                  <div
                    className={`promotionTab d-flex align-items-center justify-content-center ${archiveActive === 'myTickets' ? 'active' : ''}`}
                    onClick={() => toggleTab('myTickets')}>
                    My Tickets
                  </div>
                </div>
              </div>
              <div className="col-12">
                {archiveActive === 'jackports' ? <CasinoTab /> : archiveActive === 'result' ? <SportsTab /> : archiveActive === 'myTickets' ? <AllTickets /> : null}
              </div>

            </div>
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  )
}

export default Jackpot