import React, { useEffect, useState } from 'react'
import plusIcon from './../../fantasyAssets/img/plus.png'
import playerIcon from './../../fantasyAssets/img/ban.png'
import { errorToaster } from '../../../utils/toaster'

const WicketKeepers = ({ playerList, handleSelection, selPlayers }) => {
  const data = [1, 2, 3, 4]
  // console.log("selPlayers", { selPlayers })
  const [ids, setIds] = useState([])
  useEffect(() => {
    // if (selPlayers.length) {
    const arr = []
    selPlayers?.forEach((item) => (
      arr.push(item.p_id)
    ))
    setIds(arr)
    // }
  }, [selPlayers])
  return (
    <>
      <div className="col-12">
        <div className="wicketkipperCard">
          <div className="headingkipper">Select 1-4 Wicket Keepers</div>
        </div>
      </div>
      <div className="col-12 px-0">
        <div className="tableSectionMain">
          <table className="table tableSection  align-middle table-hover">
            <thead>
              <tr>
                <th scope="col">Players</th>
                <th scope="col">Points</th>
                <th scope="col" className='text-end'>Credits</th>
              </tr>
            </thead>
            <tbody>
              {playerList?.map((item, index) => {
                // console.log(index, item.disabled && !ids.includes(item.p_id), ids, item, item.disabled)
                return (
                  <tr
                    className={`${ids.includes(item.p_id) ? 'active' : ''} ${item.disabled && !ids.includes(item.p_id) ? 'disable' : ''}`}
                    onClick={() => {
                      if (item.disabled && !ids.includes(item.p_id)) {
                        errorToaster("can't select more players")
                      } else {
                        handleSelection(item);
                      }
                    }}>
                    <td>
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div className="userSection position-relative">
                            <div className="userImg">
                              <img src={item?.image} alt="bsk" className="img-fluid w-100 h-100" />
                            </div>
                            <div className="teamName">{item?.team_short_name}</div>
                          </div>
                        </div>
                        <div className="col ps-0">
                          <div className="userName">{item.name}</div>
                          <div className="userParcent">Sel By: {item.player_selection_percentage}%</div>
                        </div>
                      </div>
                    </td>
                    <td><span className="pointData">{item.totalpoints}</span></td>
                    <td><div className="d-flex align-items-center justify-content-end creditHeading">{parseFloat(item.credit).toFixed(1)}  <span className="d-inline-flex creditIocn"><img src={plusIcon} alt="plus" className="img-fluid w-100 h-100" /></span> </div>  </td>

                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default WicketKeepers