import React from "react";

const ChatRulesModal = () => {
    return (
        <div
            className="modal fade rulesmodal"
            id="chatrules"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"

        >
            <div className="modal-dialog rulesDialog modal-dialog-scrollable modal-dialog-centered">
                <div
                    className="modal-content rulescontent chatrulescard p-0"
                    style={{ backgroundColor: '#161a25' }}
                >
                    <div className="modal-header border border-0 rulesheader">
                        <div className="modal-title chatHeading " id="exampleModalLabel">Chat Rules</div>
                    </div>
                    <div className="modal-body rulebody">
                        <ul className="ruleMenu m-0 px-3">
                            <li className="rules">Do not harass or insult other users</li>
                            <li className="rules">Do not beg, ask for loans, trivias or tips</li>
                            <li className="rules">Do not insinuate BX has bad intent ("scam site" etc)</li>
                            <li className="rules">Do not spam or post non-BX links</li>
                            <li className="rules">Do not advertise any form of trading, buying or selling services</li>
                            <li className="rules">Do not share or advertise your referral code</li>
                            <li className="rules">Do not ask to become a staff member</li>
                            <li className="rules">English only</li>
                            <li className="rules">Respect mods, admins and other users</li>
                            <li className="rules">Enter "/" to see available chat commands</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ChatRulesModal;
