import React, { useEffect, useState } from 'react'
import './style.scss'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


const SupportOffcanvas = ({setshowSupport, showSupport}) => {
    const socialLinkData = useSelector((state) => state?.socialLinks);
    const [socialLinks, setSocialLinks] = useState([])

    useEffect(()=>{
        setSocialLinks(socialLinkData)
    },[socialLinkData])

    return (
        <>
            <div class={`offcanvas supportOffcanvas offcanvas-bottom ${showSupport ? 'show' : ''}`} tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
                <div class="offcanvas-title text-white" id="offcanvasExampleLabel">Support</div>
                <div className='closeBtn' onClick={()=>setshowSupport(false)}><img className='close' src="assets/img/sidebar/Close.png" alt="" /></div>
            </div>
            <div class="offcanvas-body py-0">
                <div>
                    <ul className='p-0'>
                    {
                        socialLinks?.length > 0 ?
                        socialLinks?.map((item)=>(
                            <li className='supportLi d-flex align-items-center' key={item?._id}>
                            <Link className='supportAnchor' to={item?.link} target="_blank"><img src={item?.image} alt="" />{item?.name}</Link></li>
                        ))
                        : 'no data'
                    }
                    </ul>
                </div>
            </div>
            </div>
        </>
    )
}

export default SupportOffcanvas