import React, { useEffect, useState } from "react";
import FantasyHeader from "../../fantasyComponents/fantasyHeader/FantasyHeader";
import MatchCard from "../../fantasyComponents/matchCard/MatchCard";
import CounterComp from "../../fantasyComponents/CounterComp/CounterComp";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import LightiningIcon from './../../fantasyAssets/img/lightining.png'
import LightiningIcon from '../../fantasyAssets/img/lightining.png'
import { getFantasyAuth } from "../../fantasyServices";
import { errorToaster } from "../../../utils/toaster";
import { Spinner } from "react-bootstrap";
import { ROUTES_CONST } from "../../../constants/routeConstant";
//--
import trophyImg from '../../fantasyAssets/img/trophyIcon.png'
import singleImg from '../../fantasyAssets/img/single.png'
import multipleImg from '../../fantasyAssets/img/multiple.png'
import guranteedImg from '../../fantasyAssets/img/guranteed.png'
import flexibleImg from '../../fantasyAssets/img/flexiable.png'


const ContestDetailsPage = () => {
    const state = useLocation()?.state?.match
    const myContest = useLocation()?.state?.myContest
    const [params, setParams] = useSearchParams()
    const contestName = params.get("contest_name")
    const decodedString = decodeURIComponent(contestName);
    const [contests, setContests] = useState([])
    const [loading, setLoading] = useState(true)
    console.log("state", state);
    const navigate = useNavigate()

    const getContest = async () => {
        try {
            const res = await getFantasyAuth(`/myjoinedcontests?matchkey=${state?.matchkey}`);
            if (res?.data?.success) {
                setContests(res.data.data);
            }

        } catch (error) {
            //error
            errorToaster("Error fetching data")
        } finally {
            setLoading(false);
        }
    };
    console.log({ contests });

    useEffect(() => {
        getContest()
    }, [])
    const handleLeaderBoard = (e, elem) => {
        e.stopPropagation();
        navigate(ROUTES_CONST.FANTASY_LEADERbOARD, {
            state: {
                data: state,
                el: elem,
                contest: elem,
                // selectingTeam: TeamSelection,
                live: true,
                // type: type,
            }
        })
    }
    return (
        <>
            <div className="row fantasyInner">
                <FantasyHeader gameHeader={true} title={decodedString} />
                <div className="col-12 px-0 flex-fill leaderBoardPage" data-page-name="indexPage">
                    <div className="row mx-0 h-100 flex-column flex-nowrap createTeam">
                        {/* match details */}
                        <div className="col-12 px-0 cricketheader z-2">
                            <div className="card border-0 matchesCard overflow-hidden border border-1 border-light rounded-0">
                                <div className="card-body p-0">
                                    <div
                                        className="matchesCard featuredCard overflow-hidden position-relative rounded-3 mb-3 cusor-pointer"
                                    // onClick={"navigateHandler"}
                                    >
                                        <div className="matchCardBody">
                                            <div className="teamHeadline row mx-0 pt-2 align-items-center border-0">
                                                <div className="col fw-normal fs-12 matchteams text-uppercase text-white-50 px-2">
                                                    {/* icc cricket world cup */}
                                                    {state?.series_name}
                                                </div>
                                                <div className="col-auto matchStatus text-success">
                                                    {
                                                        state?.playing11_status === 1 && state?.status !== 'closed' ? (
                                                            "Lineups Out"
                                                        ) : state?.status === 'closed' &&
                                                            state?.final_status === 'IsReviewed' ? (
                                                            'Under Review'
                                                        ) : state?.status === 'closed' &&
                                                            state?.final_status === 'pending' ? (
                                                            'In Progress'
                                                        ) : state?.status === 'closed' ? (
                                                            state?.final_status
                                                        ) : (
                                                            ''
                                                        )
                                                    }
                                                    {/* In Progress */}
                                                </div>
                                            </div>
                                            <div className="innerBody firstMatchBody py-3">
                                                <div className="row mx-0 align-items-center">
                                                    <div className="col-auto leftSideTeams px-2">
                                                        <div className="team d-flex align-items-center">
                                                            <div className="bgImg bgImg2">
                                                                <img src={state?.team1logo} className="w-100" alt="" />
                                                            </div>
                                                            <div className="teamLogo position-relative rounded-circle d-flex justify-content-center align-items-center">
                                                                <img src={state?.team1logo} className="w-100" alt="" />
                                                            </div>
                                                            <div className="teamName text-uppercase text-white fw-medium ms-2">
                                                                {state?.team1ShortName}
                                                            </div>
                                                        </div>
                                                        <div className="teamName2">{state?.teamfullname1}</div>
                                                    </div>
                                                    <div className="col px-0 matchTimer text-center">
                                                        {
                                                            // "upcoming"   ?
                                                            new Date() < new Date(state?.start_date) ? (
                                                                <>
                                                                    {
                                                                        // state?.status === 'closed' ? (
                                                                        //     <div className="text-center text-light">
                                                                        //         {/* {state?.final_status} */}
                                                                        //         {/* Completed */}
                                                                        //         {state?.status}
                                                                        //     </div>

                                                                        // ) : 
                                                                        <CounterComp start_date={state?.start_date} />
                                                                    }

                                                                </>
                                                            ) : (
                                                                <>
                                                                    <img src={LightiningIcon} className='lightiningImg' alt="" />
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="col-auto rightSideTeams px-2">
                                                        <div className="team d-flex align-items-center">
                                                            <div className="bgImg">
                                                                <img src={state?.team1logo} className="w-100" alt="" />
                                                            </div>
                                                            <div className="teamName text-uppercase text-white fw-medium me-2">
                                                                {state?.team2ShortName}
                                                            </div>
                                                            <div className="teamLogo position-relative rounded-circle d-flex justify-content-center align-items-center">
                                                                <img src={state?.team2logo} className="w-100" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="teamName2 text-end">{state?.teamfullname2}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* contest cards */}
                        {
                            loading ? (
                                <div className='w-100 h-100 d-flex justify-content-center'>
                                    <Spinner color='white' size='sm' />
                                </div>
                            ) : (
                                contests == 0 ? (
                                    <div className='w-100 h-100 d-flex justify-content-center text-light' >No Data Available</div>
                                ) : (
                                    contests.map((item) => (
                                        <div className="col-12 mb-3 mt-2 cursor-pointer" onClick={(e) => handleLeaderBoard(e, item)}>
                                            <div className="row mx-0 contestCard">
                                                <div className="col-12">
                                                    <div className="row align-items-center justify-content-between">
                                                        <div className="winnerPrz col">₹ {item?.winamount}</div>
                                                        <div className="col-auto">
                                                            <div
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    navigate(ROUTES_CONST.FANTASY_INVITE_FRIEND)
                                                                }}
                                                                className="entryPrz"
                                                            >
                                                                {
                                                                    myContest ? (
                                                                        "invite"
                                                                    ) : (
                                                                        item?.contest_name?.toLowerCase().includes("practice")
                                                                    ) ? (
                                                                        'Practice'
                                                                    ) : (
                                                                        `₹ ${item?.entryfee}`
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="row align-items-center justify-content-between">
                                                        <div className="col-12 mt-2 mb-1">
                                                            <div className="progress spotProgress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                                <div className="progress-bar progress-bar-striped"
                                                                    style={{ width: `${item?.maximum_user == 0 ? 0 : item?.joinedusers / item?.maximum_user * 100}%` }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                        <div className="col leftSpots text-danger">{item?.joinedusers ? `${item?.maximum_user - item?.joinedusers} Spots Left` : "Be First To Join"} </div>
                                                        {/* <div className="col-auto totalSpots">10 Spots Left</div> */}
                                                    </div>
                                                </div>
                                                <div className="col-12 contestCardFooter mt-2 py-2">
                                                    <div className="row align-items-center">
                                                        <div className="col d-flex align-items-center">
                                                            <div className="gurantyTxt me-2">
                                                                {
                                                                    item?.contest_name?.toLowerCase().includes("practice") ? 'Practice' :
                                                                        <>
                                                                            <img src={trophyImg} alt="" />
                                                                            {item?.winning_percentage}%
                                                                        </>
                                                                }
                                                            </div>
                                                            {
                                                                item?.multi_entry ? (
                                                                    <>
                                                                        <div className="gurantyTxt">
                                                                            <img src={multipleImg} alt="" />
                                                                            Multi Entry
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className="gurantyTxt">
                                                                        <img src={singleImg} alt="" />
                                                                        Single
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        {
                                                            item?.confirmed_challenge ? (
                                                                <div className="col-auto gurantyTxt">
                                                                    <img src={guranteedImg} alt="" />
                                                                    Guaranteed
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                            )
                        }

                    </div>

                </div>
            </div>
        </>
    );
};

export default ContestDetailsPage;
