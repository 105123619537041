import React, { useEffect, useState } from "react";
import { adminGetAPIAuth, adminPostAPIAuth } from "../../AdminServices";
import './style.css'
import { USER } from "../../AdminConstants/apiEndpoints";
import { succesToaster as successToaster } from "../../../utils/toaster";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { PORTAL, MARKET_PAGE } from "../../AdminConstants/urlPaths";
import { REPORTS } from "../../AdminConstants/apiEndpoints";
import moment from "moment";

const AddMarketCommission = () => {
    const [CatagoriesList, setCatagoriesList] = useState([]);
    const { state } = useLocation();
    // const findNumberData = CatagoriesList?.find(g => g.name === state.gameCate);

    console.log("findNumberData", state);
    const navigate = useNavigate();
    const adminData = useSelector(state => state.adminData);
    const [formData, setFormData] = useState({
        gameCate: state?.gameCate || "",
        stockCat: state?.stockCat || "",
        from: moment(state?.from).format("YYYY-MM-DD") || "",
        to: moment(state?.to).format("YYYY-MM-DD") || "",
        no: state?.no || "",
        noFluctuation: state?.noFluctuation || "",
        noSmall: state?.noSmall || "",
        noSmallFluctuation: state?.noSmallFluctuation || "",
        yes: state?.yes || "",
        yesFluctuation: state?.yesFluctuation || "",
        yesSmall: state?.yesSmall || "",
        yesSmallFluctuation: state?.yesSmallFluctuation || "",
        min_bet: state?.min_bet || "",
        max_bet: state?.max_bet || "",
        status: state?.status || "",
        result: state?.result || ""
    });
    // console.log("formData", formData);

    const GameCatagoriesList = async () => {
        try {
            const res = await adminGetAPIAuth(
                "market/getMarketGameCategory"
            );

            console.log("resres", res);
            if (res?.data?.status === 200) {
                setCatagoriesList(res?.data?.data?.data);
            }
        } catch (error) {
            setCatagoriesList([]);
        }
    };

    useEffect(() => {
        GameCatagoriesList()
    }, []);

    const GetMarketData = async () => {
        try {
            const res = await adminGetAPIAuth(
                REPORTS?.getMarketData
            );
            console.log('resresresres', res);
            if (res?.data?.data?.success) {
                // setTableData(res?.data?.data?.data);
            } else {
                // errorToaster("Something went wrong in fetching data");
            }
        } catch (error) {
            if (error?.response?.status === 401) {

            }
            // errorToaster(error?.response?.data?.data?.message);
            // setTableData([]);
            console.log("error -------------->", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log({ name, value });
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleInputBlur = () => {
        console.log('Input blurred');
    };

    function getGameId(gameName) {
        console.log("gameNamegameName", gameName);
        const game = CatagoriesList?.find(g => g.name === gameName);
        return game ? game.gameId : formData.gameCate;
    }



    const changeStatusApi = async () => {


        const payload = {
            gameCate: `${formData.gameCate}`,
            stockCat: `${formData.stockCat}`,
            min_bet: `${formData.min_bet}`,
            max_bet: `${formData.max_bet}`,
            status: `${formData.status}`,
            result: `${formData.result}`,
            from: `${formData.from}`,
            to: `${formData.to}`,
            no: `${formData.no}`,
            noFluctuation: `${formData.noFluctuation}`,
            noSmall: `${formData.noSmall}`,
            noSmallFluctuation: `${formData.noSmallFluctuation}`,
            yes: `${formData.yes}`,
            yesFluctuation: `${formData.yesFluctuation}`,
            yesSmall: `${formData.yesSmall}`,
            yesSmallFluctuation: `${formData.yesSmallFluctuation}`
        };
        try {
            const res = await adminPostAPIAuth(USER.market, payload);
            if (res.status === 200) {
                successToaster("Status Updated Successfully! ");
                setFormData({

                    stockCategoryId: "",
                    stockId: "",
                    from: "",
                    to: "",
                    no: "",
                    noFluctuation: "",
                    noSmall: "",
                    noSmallFluctuation: "",
                    yes: "",
                    yesFluctuation: "",
                    yesSmall: "",
                    yesSmallFluctuation: "",
                    min_bet: "",
                    max_bet: "",
                    status: "",
                    result: ""

                });

                navigate(`/${PORTAL}/${adminData.role}/${MARKET_PAGE}`);
                // setError("");
                // setShow(false);
                // updateData()
            }
        } catch (error) {
            // setError(error?.response?.data?.data?.message);
        }
    };

    const EditchangeStatusApi = async () => {
        console.log("formData.gameCate", formData.gameCate);
        const selectedGameId = getGameId(formData.gameCate);

        // console.log("selectedGameId", selectedGameId);

        const payload = {
            gameCate: `${selectedGameId}`,
            stockCat: `${formData.stockCat}`,
            min_bet: `${formData.min_bet}`,
            max_bet: `${formData.max_bet}`,
            status: `${formData.status}`,
            result: `${formData.result}`,
            from: `${formData.from}`,
            to: `${formData.to}`,
            no: `${formData.no}`,
            noFluctuation: `${formData.noFluctuation}`,
            noSmall: `${formData.noSmall}`,
            noSmallFluctuation: `${formData.noSmallFluctuation}`,
            yes: `${formData.yes}`,
            yesFluctuation: `${formData.yesFluctuation}`,
            yesSmall: `${formData.yesSmall}`,
            yesSmallFluctuation: `${formData.yesSmallFluctuation}`
        };

        try {
            const res = await adminPostAPIAuth(`${USER.EditMarket}?id=${state?._id}`, payload);
            if (res.status === 200) {
                successToaster("Edit sucessfully!");
                GetMarketData()

                setFormData({

                    gameCate: "",
                    stockCat: "",
                    from: "",
                    to: "",
                    no: "",
                    noFluctuation: "",
                    noSmall: "",
                    noSmallFluctuation: "",
                    yes: "",
                    yesFluctuation: "",
                    yesSmall: "",
                    yesSmallFluctuation: "",
                    min_bet: "",
                    max_bet: "",
                    status: "",
                    result: ""

                });

                navigate(`/${PORTAL}/${adminData.role}/${MARKET_PAGE}`);



                // setError("");
                // setShow(false);
                // updateData()
            }
        } catch (error) {
            // setError(error?.response?.data?.data?.message);
        }
    };

    return (
        <>
            <div className="app">
                <main className="app-main">
                    <div className="wrapper">
                        <div className="page">
                            <div className="page-inner ">
                                <header className="page-title-bar justify-content-between row align-items-center mb-2">
                                    <h1 className="page-title mb-0 text-nowrap px-0 mb-3">
                                        Market Categories
                                    </h1>
                                </header>


                                <div className="row">

                                    <div className="market-form-container">
                                        <div className="row">

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="gameCategory">Select Game Category</label>
                                                <select
                                                    id="gameCategory"
                                                    className="form-control"
                                                    name="gameCate"
                                                    // value={formData.gameCate}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select a category</option>

                                                    {
                                                        CatagoriesList.map((item) => {
                                                            // console.log("item", item);
                                                            return (
                                                                <>
                                                                    {/* {console.log('is selected', item?.name === formData.gameCate)} */}
                                                                    <option
                                                                        selected={item?.name === formData.gameCate}
                                                                        value={item.gameId} key={item.name}>{item?.name}</option>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="gameCategory">STOCK</label>
                                                <select
                                                    id="gameCategory"
                                                    className="form-control"
                                                    name="stockCat"
                                                    value={formData.stockCat}
                                                    onChange={handleChange}
                                                >
                                                    <option value="Select a category">Select a category</option>
                                                    <option value="Table">Table</option>
                                                    <option value="Cricket">Cricket</option>


                                                </select>
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">FROM</label>
                                                <input
                                                    type="date"
                                                    placeholder="Search User"
                                                    className="form-control adminmarketData"
                                                    name="from"
                                                    value={formData.from}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">To</label>
                                                <input
                                                    type="date"
                                                    placeholder="Search User"
                                                    className="form-control adminmarketData"
                                                    name="to"
                                                    value={formData.to}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">NO</label>
                                                <input
                                                    type="text"
                                                    placeholder="No (Price)"
                                                    className="form-control adminmarketData"
                                                    name="no"
                                                    value={formData.no}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">NO FLUCTUATION</label>
                                                <input
                                                    type="text"
                                                    placeholder="NO Fluctuation (Price)"
                                                    className="form-control adminmarketData"
                                                    name="noFluctuation"
                                                    value={formData.noFluctuation}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">NO(SMALL)</label>
                                                <input
                                                    type="text"
                                                    placeholder="NO(Small)"
                                                    className="form-control adminmarketData"
                                                    name="noSmall"
                                                    value={formData.noSmall}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">NO (SMALL) FLUCTUATION</label>
                                                <input
                                                    type="text"
                                                    placeholder="No (Small) Fluctuation"
                                                    className="form-control adminmarketData"
                                                    name="noSmallFluctuation"
                                                    value={formData.noSmallFluctuation}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">YES</label>
                                                <input
                                                    type="text"
                                                    placeholder="Yes (Price)"
                                                    className="form-control adminmarketData"
                                                    name="yes"
                                                    value={formData.yes}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">YES FLUCTUATION</label>
                                                <input
                                                    type="text"
                                                    placeholder="Yes Fluctuation (Price)"
                                                    className="form-control adminmarketData"
                                                    name="yesFluctuation"
                                                    value={formData.yesFluctuation}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">YES(SMALL)</label>
                                                <input
                                                    type="text"
                                                    placeholder="Yes(Small)"
                                                    className="form-control adminmarketData"
                                                    name="yesSmall"
                                                    value={formData.yesSmall}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">YES (SMALL) FLUCTUATION</label>
                                                <input
                                                    type="text"
                                                    placeholder="Yes (Small) Fluctuation"
                                                    className="form-control adminmarketData"
                                                    name="yesSmallFluctuation"
                                                    value={formData.yesSmallFluctuation}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">MIN BET</label>
                                                <input
                                                    type="text"
                                                    placeholder="MIN BET"
                                                    className="form-control adminmarketData"
                                                    name="min_bet"
                                                    value={formData.min_bet}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>





                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">MAX BET</label>
                                                <input
                                                    type="text"
                                                    placeholder="Max Bet"
                                                    className="form-control adminmarketData"
                                                    name="max_bet"
                                                    value={formData.max_bet}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>

                                            <div className="col-lg-12">

                                                <div className="form-group ">
                                                    <label>Status:</label><br />
                                                    <div className="enable-disable">
                                                        <input
                                                            type="radio"
                                                            id="statusEnable"
                                                            name="status"
                                                            value="Enable"
                                                            checked={formData.status === 'Enable'}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="statusEnable">Enable</label><br />

                                                        <input
                                                            type="radio"
                                                            id="statusDisable"
                                                            name="status"
                                                            value="Disable"
                                                            checked={formData.status === 'Disable'}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="statusDisable">Disable</label><br />
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="col-lg-12">


                                                <div className="form-group">
                                                    <label>Result:</label><br />

                                                    <div className="enable-disable">
                                                        <input
                                                            type="radio"
                                                            id="resultPending"
                                                            name="result"
                                                            value="pending"
                                                            checked={formData.result === 'pending'}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="resultPending">Pending</label><br />

                                                        <input
                                                            type="radio"
                                                            id="resultDeclared"
                                                            name="result"
                                                            value="declared"
                                                            checked={formData.result === 'declared'}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="resultDeclared">Declared</label><br />
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="col-lg-12">
                                                <div class="page-section d-flex align-items-center m-0 flex-row-reverse col-md-auto w-auto">
                                                    {
                                                        state == null ? <button type="button" class="me-2 btn btn-primary" onClick={changeStatusApi}>Submit</button> : <button type="button" class="me-2 btn btn-primary" onClick={EditchangeStatusApi}>Edit</button>
                                                    }
                                                </div>
                                            </div>







                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};
// https://api.bholaexchange.com/api/v1/user/gamereport/stage/1/6539fe66ba01c43e96234593?page=1&perPage=10&username=jon&endDate=2023-12-29
// https://api.bholaexchange.com/api/v1/user/gamereport/stage/2/6539fe66ba01c43e96234593?page=1&perPage=10&username=&endDate=2023-12-29
export default AddMarketCommission;
