
import React from 'react'



const FantasyLoader = () => {
  return (
    <div className='vh-100 vw-100 fantasyLoader position-fixed top-0 start-0' style={{zIndex: '99999999999999999'}}>
      <img src="assets/img/fantasyLoaderImg.gif" style={{mixBlendMode: 'lighten'}} className='h-100 w-100 object-fir-contain' alt="" />
    </div>
  )
}

export default FantasyLoader