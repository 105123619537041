import React, { useEffect, useState } from 'react'
import gameImg from "../../../../../src/Admin/AdminAssets/img/carddeshboard1.svg";
import gameImg2 from "../../../../../src/Admin/AdminAssets/img/carddeshboard2.png";
import gameImg3 from "../../../../../src/Admin/AdminAssets/img/deshboardcard3.png";
import { Link } from 'react-router-dom';
import { adminGetAPIAuth } from '../../../AdminServices';
import { useNavigate } from 'react-router-dom';
import { BET_LIST_URL, GAME_REPORT_URL, PORTAL } from '../../../AdminConstants/urlPaths';
import { useSelector } from 'react-redux';

const DashboardCards = () => {
  const [data, setData] = useState([])
  const [cricket, setCricket] = useState({})
  const [soccer, setSoccer] = useState({})
  const [tennis, setTennis] = useState({})
  const adminData = useSelector((state) => state?.adminData);


  const navigate = useNavigate()

  const getData = async () => {
    try {
      const res = await adminGetAPIAuth("user/getUserDashboardData")
      if (res?.data?.data?.success) {
        setData(res?.data?.data?.data)
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (data?.length > 0) {
      const cricketData = data?.filter((item) => item?._id === "Cricket")
      setCricket(cricketData)


      const soccerData = data?.filter((item) => item?._id === "Soccer")
      setSoccer(soccerData)


      const tennisData = data?.filter((item) => item?._id === "Tennis")
      setTennis(tennisData)
    }
  }, [data])

  console.log('cricket', cricket)

  const navigateHandler = () => {
    console.log("hello", 7)
    navigate(BET_LIST_URL)
  }



  return (
    <>
      <div className="row    g-3">
        <div className="  col-lg-4 col-sm-6 col-12">
          <div className="deshboardCardGame position-relative">
            <div className="gameImg ">
              <img
                src={gameImg}
                className="img-fluid w-100 h-100 "
                alt="deshboard card"
              />
            </div>
            <div className="cardTitle">Cricket</div>



            <ul className='list-unstyled d-flex align-items-center gameUl mb-0 gap-4' >
              <li className='d-flex align-items-cente flex-column cursor-pointer'  >
              <Link to={`/${PORTAL}/${adminData.role}/${GAME_REPORT_URL}`} >
                 <div className="gmaeSubtitle">Games</div>
                <div className="gameNumber">{cricket?.[0]?.matchCount ? cricket?.[0]?.matchCount : '0'}</div>
                </Link>
                </li>
                 <li   className="d-flex align-items-cente flex-column">
              <Link to={`/${PORTAL}/${adminData.role}/${BET_LIST_URL}`} >
              <div className="gmaeSubtitle">Bets</div>
              <div className="gameNumber">{cricket?.[0]?.bets ? cricket?.[0]?.bets : '0'}</div>
              </Link>
            </li>

            <li   className="d-flex align-items-cente flex-column">
            <Link to={`/${PORTAL}/${adminData.role}/${BET_LIST_URL}`} >
              <div className="gmaeSubtitle">Exposure</div>
              <div  className="gameNumber">
                {cricket?.[0]?.exposer ? cricket?.[0]?.exposer : '0.00'}
              </div>
              </Link>
            </li>
            </ul>


            {/* <ul   className="list-unstyled d-flex align-items-center gameUl mb-0 gap-4">
            <li   className="d-flex align-items-cente flex-column">
              <div   className="gmaeSubtitle">Games</div>
              <div   className="gameNumber">{cricket?.[0]?.matchCount ? cricket?.[0]?.matchCount : '0'}</div>
            </li>
            
            <li   className="d-flex align-items-cente flex-column">
              <div className="gmaeSubtitle">Bets</div>
              <div className="gameNumber">{cricket?.[0]?.bets ? cricket?.[0]?.bets : '0'}</div>
            </li>
            <li className="d-flex align-items-cente flex-column">
              <div className="gmaeSubtitle">Exposure</div>
              <div  className="gameNumber stretched-link">
                {cricket?.[0]?.exposer ? cricket?.[0]?.exposer : '0.00'}
              </div>
            </li>
          </ul> */}
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 col-12">
          <div className="deshboardCardGame position-relative">
            <div className="gameImg ">
              <img
                src={gameImg2}
                className="img-fluid w-100 h-100 "
                alt="deshboard card"
              />
            </div>
            <div className="cardTitle">Football</div>
            <ul className="list-unstyled d-flex align-items-center gameUl mb-0 gap-4">
              <li className="d-flex align-items-cente flex-column cursor-pointer"
              >
                <Link to={`/${PORTAL}/${adminData.role}/${GAME_REPORT_URL}`} >
                <div className="gmaeSubtitle cursor-pointer">Games</div>
                <div className="gameNumber">{soccer?.[0]?.matchCount ? soccer?.[0]?.matchCount : '0'}</div>
                </Link>
              </li>
              <li className="d-flex align-items-cente flex-column">
              <Link to={`/${PORTAL}/${adminData.role}/${BET_LIST_URL}`} >
                <div className="gmaeSubtitle">Bets</div>
                <div className="gameNumber">{soccer?.[0]?.bets ? soccer?.[0]?.bets : '0'}</div>
                </Link>
              </li>
              <li className="d-flex align-items-cente flex-column">
              <Link to={`/${PORTAL}/${adminData.role}/${BET_LIST_URL}`} >
                <div className="gmaeSubtitle">Exposure</div>
                <div className="gameNumber">
                  {soccer?.[0]?.exposer ? soccer?.[0]?.exposer : '0.00'}
                </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 col-12">
          <div className="deshboardCardGame position-relative">
            <div className="gameImg ">
              <img
                src={gameImg3}
                className="img-fluid w-100 h-100 "
                alt="deshboard card"
              />
            </div>
            <div className="cardTitle">Tennis</div>
            <ul className="list-unstyled d-flex align-items-center gameUl mb-0 gap-4">
              <li className="d-flex align-items-cente flex-column">
              <Link to={`/${PORTAL}/${adminData.role}/${GAME_REPORT_URL}`} >
                <div className="gmaeSubtitle">Games</div>
                <div className="gameNumber">{tennis?.[0]?.matchCount ? tennis?.[0]?.matchCount : '0'}</div>
                </Link>
              </li>
              <li className="d-flex align-items-cente flex-column">
              <Link to={`/${PORTAL}/${adminData.role}/${BET_LIST_URL}`} >
                <div className="gmaeSubtitle">Bets</div>
                <div className="gameNumber">{tennis?.[0]?.bets ? tennis?.[0]?.bets : '0'}</div>
                </Link>
              </li>
              <li className="d-flex align-items-cente flex-column">
              <Link to={`/${PORTAL}/${adminData.role}/${BET_LIST_URL}`} >
                <div className="gmaeSubtitle">Exposure</div>
                <div className="gameNumber">
                  {tennis?.[0]?.exposer ? tennis?.[0]?.exposer : '0.00'}
                </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardCards