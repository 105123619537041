import React, { useEffect, useState } from "react";
import { postTradingAPI } from "../../../TradingServices";
import moment from "moment";

const ExecutedComp = ({ executedData, loader }) => {
    return (
        <div className="col-12 px-0 mb-0 dambleTable sectionInner tableCard tab-content tab-pane table-responsive">
            <h5 className="text-light">Executed orders</h5>
            <table className="table align-middle text-white text-nowrap mb0">
                <thead>
                    <tr>
                        <th>
                            <div className="tabletxt d-flex align-items-center">
                                Sr No.
                            </div>
                        </th>
                        <th>
                            <div className="tabletxt d-flex align-items-center">
                                TRADING SYMBOL
                            </div>
                        </th>
                        <th>
                            <div className="tabletxt d-flex align-items-center">
                                Buy/Sell
                            </div>
                        </th>
                        <th>
                            <div className="tabletxt d-flex align-items-center">
                                Quantity
                            </div>
                        </th>
                        <th>
                            <div className="tabletxt d-flex align-items-center">
                                MIS/CNC
                            </div>
                        </th>
                        <th>
                            <div className="tabletxt d-flex align-items-center">
                                exchange
                            </div>
                        </th>
                        <th>
                            <div className="tabletxt d-flex align-items-center">
                                Execution Price
                            </div>
                        </th>
                        <th>
                            <div className="tabletxt d-flex align-items-center">
                                Execution Time
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!loader
                        ? executedData?.length > 0
                            ? executedData.map((item, index) => (
                                <tr key={item?.order_pk_id}>
                                    <td>
                                        <div className="d-flex align-items-center ">{index + 1}</div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center ">{item?.trading_symbol}</div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center ">{item?.buy_sell}</div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center ">{item?.quantity}</div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center ">{item?.mis_cnc}</div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center ">{item?.exchnage}</div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center ">{item?.execution_price}</div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center ">{item?.execution_time}</div>
                                    </td>
                                </tr>
                            ))
                            : "No Data Available"
                        : "loading"}
                </tbody>
            </table>
        </div>
    );
};

export default ExecutedComp;
