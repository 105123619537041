import React, { useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { postTradingAPI } from "../../../Trading/TradingServices";
import { useEffect } from "react";
import { stockAddUserData } from "../../../store/action";
import { useNavigate } from "react-router-dom";
import { getAPIAuth } from "../../../service/apiInstance";

const SubscriptionBettingCards = () => {
  // const [data, setData] = useState({});
  const { token } = useAuth();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  console.log("datadatadata", data);
  const fantasyUserData = useSelector((state) => state?.fantasyUserData);

  const getSubscriptionData = async () => {

    if (token) {
      try {
        const res = await getAPIAuth("user/getSubscriptionData", token);
        console.log("resadssasaws", res);
        if (res?.data?.data?.success) {

          setData(res?.data?.data?.response?.[0]);
        }
      } catch (error) {
        console.log("error", error);
      }
    }

  }

  useEffect(() => {
    getSubscriptionData();
  }, [token]);


  let number = data?.exposerPl;
  let roundedNumber = number?.toFixed(2);
  console.log(roundedNumber);

  return (
    <div className="casinoFantasyWallet mt-4">

      <div className="row row-col-5 g-3" >
        <div className="col-lg-4 col-md-4 col-sm-6">
          <div className="FantasyCard">
            <div className="fantasyImg">
              <img
                src="assets/img/sidebar/wallet.png"
                alt="fantasyLogo"
                className="w-100 h-100"
              />
            </div>
            <div className="fantasySubheading mt-2"> Subscribed Wallet </div>
            <div className="fantasyCardHeading ">
              ₹ {data?.exposerWallet ? data?.exposerWallet : 0.00}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6">
          <div className="FantasyCard">
            <div className="fantasyImg">
              <img
                src="assets/img/sidebar/mybets.png"
                alt="fantasyLogo"
                className="w-100 h-100"
              />
            </div>
            <div className="fantasySubheading mt-2">Subscription Exposure</div>
            <div className="fantasyCardHeading ">
              ₹ {data?.exposer ? data?.exposer : 0.00}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6">
          <div className="FantasyCard">
            <div className="fantasyImg">
              <img
                src="assets/img/sidebar/info.png"
                alt="fantasyLogo"
                className="w-100 h-100"
              />
            </div>
            <div className="fantasySubheading mt-2">Total Exposure % Of P/L</div>
            <div className="fantasyCardHeading ">
              ₹ {roundedNumber ? roundedNumber : 0.00}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionBettingCards;
