import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import close from '../../../fantasy/fantasyAssets/img/close.png'
import { errorToaster, succesToaster } from '../../../utils/toaster';
import { getFantasyAuth, postFantasyAuth } from '../../fantasyServices';
import { addJoinedContest } from '../../../store/action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONST } from '../../../constants/routeConstant';
const ConfirmationModal = ({
    setShow,
    show,
    modalBalance,
    jointeamid,
    setContestLoader,
    ContestLoader,
    selectedPlayers,
    data,
    selectedContest,
    getContests = () => { }
}) => {
    const encodedSN = encodeURIComponent(data?.series_name)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // Function for extracting Ids from selected players
    const extractingIds = () => {
        let jointeam = [];
        selectedPlayers.map(el => {
            jointeam.push(el.jointeamid);
        });

        return jointeam.join(',')
    }
    // Function for joining contests
    const JoinContest = async () => {
        const params = {
            matchkey: data.id,
            matchchallengeid: selectedContest.matchchallengeid,
            jointeamid: jointeamid ? jointeamid?.data?.data?.teamid : extractingIds(),
        };
        const res = await postFantasyAuth('joinContest', params);
        if (res.data?.success) {
            const result = await getFantasyAuth(
                `/myjoinedcontests?matchkey=${data?.id}`,
            );
            dispatch(addJoinedContest(result.data.data))
            navigate(`${ROUTES_CONST.FANTASY_HOME}/${data?.id}?contest_name=${encodedSN}`, { state: data })
            succesToaster(res.message)
            setShow(false);
            getContests();
        } else {
            errorToaster(res.data.message)
            setShow(false);
        }
    }

    return (
        <>
            <Modal className='confirmationModal' show={show} onHide={() => setShow(false)} centered>
                <Modal.Header className=''>
                    <Modal.Title className='w-100'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col modalTitle'>
                                Confirmation<span>Utilized Balanced + Winnings = ₹ 0.00</span></div>
                            <div className='col-auto'>
                                <div
                                    className='btn-close'
                                    onClick={() => setShow(false)}
                                    aria-label="Close"
                                >
                                    <img src={close} />
                                </div>
                            </div>
                        </div>
                        {/* <div className='row justify-content-between align-items-center'>
                            <div className='col modalTitle'>
                                Confirmation<span>Utilized Balanced + Winnings = ₹ 0.00</span></div>
                            <div className='col-auto'>
                                <div className='btn-close' onClick={() => setShow(false)} aria-label="Close"><img className='' src={close} alt="" /></div></div>
                        </div> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col'><div className='text'>Entry</div></div>
                        <div className='col-auto'><div className='rupee'>₹{modalBalance?.entryfee}</div></div>
                    </div>
                    <div className='row cashRow pb-3'>
                        <div className='col'><div className='text'>Usable Cash Bonus</div></div>
                        <div className='col-auto'><div className='rupee'>₹ {modalBalance?.usablebalance}</div></div>
                    </div>

                    <div className='row pt-3'>
                        <div className='col'><div className='text text-success '>To Pay</div></div>
                        <div className='col-auto'><div className='rupee text-success' >₹ {modalBalance?.entryfee}</div></div>
                        <div className=' col-12 para'>By joining this contest, you accept FastasyBox's T&C and confirm that you are not a resident of Assam, Odhisha, Telangana, Nagaland & Sikkam.</div>
                        <div className=' col-12 d-flex justify-content-center mt-3'><button onClick={JoinContest} className='joinBtn'>JOIN CONTEST</button></div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConfirmationModal