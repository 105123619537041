import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ROUTES_CONST } from "../constants/routeConstant";
// import GlobalSetting from "../pages/globalSetting/GlobalSetting";
import Promotion from "../pages/promotions";
import Casino from "../pages/casinoPage";
import PlaySlot from "../pages/playSlot";
import AccountTab from "../components/AccountTab";
import TermsAndConditions from "../pages/termsAndConditions/TermsAndConditions";
import ResponsibleGamble from "../pages/gamingWithResponsibility/ResponsibleGamble";
import NewAmlPolicy from "../pages/newAmlPolicy/NewAmlPolicy";
import DownloadApp from "../pages/DownloadApp/Index";
import Protected from "./protected/Protected";
import AffilliatePage from "../pages/affilliatePage/AffilliatePage";
import SportsPolicy from "../pages/sportsPolicy/SportsPolicy";
import { Spinner } from "react-bootstrap";
import GameProviders from "../pages/gameProviders/GameProviders";
import PromotionDetail from "../pages/promotionDetailPage";
import SignIn from "../Admin/AdminPages/sign-in/SignIn";
import {
  BET_LIST_URL,
  DISPUTED_BETS_URL,
  DASHBOARD_URL,
  LOGIN_URL,
  MY_ACCOUNT_STATEMENT_URL,
  PORTAL,
  USER_MANAGEMENT_URL,
  ADMIN_ACCOUNT_STATEMENT_URL,
  USER_ACCOUNT_STATEMENT_URL,
  PROFIT_AND_LOSS_STATEMENT_URL,
  GAME_REPORT_URL,
  ALERT_BETS_URL,
  COMMISSION_REPORT_URL,
  QR_DETAILS_URL,
  BANK_DETAILS_URL,
  UPI_DETAILS_URL,
  CRYPTO_DETAILS_URL,
  CRYPTO_VIEW_PAYMENTS,
  ADMIN_VIEW_PAYMENTS,
  CASINO_REPORT_URL,
  USER_DETAIL_PAGE,
  MARKET_PAGE,
  MARKET_COMMISSION,
  MARKET_ADD_PAGE,
  SHARING_REPORTS,
  MARKET_COMMISSION_ADD_PAGE,
  BINARY_BET_HISTORY_PAGE,
  DERBY_REPORTS
} from "../Admin/AdminConstants/urlPaths";
import UserManagement from "../Admin/AdminPages/UserManagement/UserManagement";
import SharingReports from "../Admin/AdminPages/sharingReports/SharingReports";
import FantasyHome from "../fantasy/fantasyPages/home";
import AdminProtectedRoute from "../Admin/Admincomponents/AdminProtectedRoute/AdminProtectedRoute";
// import SportsPage from "../pages/sports/Sports";
// import Binary from "../sports/sportsPages/SportsHome/Binary";
import SportsPage from "../sports/sportsPages/SportsHome/Sports";
import FantasyGameDetail from "../fantasy/fantasyPages/gameDetailPage";
import CreateTeam from "../fantasy/fantasyPages/createTeam/CreateTeam";
import TeamView from "../fantasy/fantasyPages/teamView/TeamView";
import SelectCaptain from "../fantasy/fantasyPages/selectCaptain/SelectCapain";
import MyMatchesPage from "../fantasy/fantasyPages/myMatchesPage";
import FantasyWallet from "../fantasy/fantasyPages/fantasyWallet/FantasyWallet";
import DepositCash from "../fantasy/fantasyPages/depositCash/DepositCash";
import Transaction from "../fantasy/fantasyPages/transaction/Transaction";
import LeaderBoard from "../fantasy/fantasyPages/leaderBoard/LeaderBoard";
import SportsDetailPage from "../sports/sportsPages/sportsDetailPage";
import MyBetsPage from "../sports/sportsPages/myBets";
import InviteFriend from "../fantasy/fantasyPages/inviteFriend/InviteFriend";
import VerifyAccount from "../fantasy/fantasyPages/verifyAccount/VerifyAccount";
import Profile from "../fantasy/fantasyPages/profilePage/Profile";
import PlayInsights from "../fantasy/fantasyPages/profilePage/PlayInsights";
import More from "../fantasy/fantasyPages/more/More";
import MyMatchesContest from "../fantasy/fantasyPages/myMatchContest";
import Dashboard from "../Admin/AdminPages/Dashboard/Dashboard";
import ContactHelp from "../fantasy/fantasyPages/contact&Help/ContactHelp";
import TermsAndCondition from "../fantasy/fantasyPages/term&Condition/TermsAndCondition";
import MyPortfolio from "../fantasy/fantasyPages/myPortfolio/MyPortfolio";
import Notification from "../fantasy/fantasyPages/notification/Notification";
import AdminPageNotFound from "../Admin/AdminPages/AdminPageNotFound/AdminPageNotFound";
import MyMatchesDetail from "../fantasy/fantasyPages/myMatchesDetail";
import FantasyWithdraw from "../fantasy/fantasyPages/fantasyWithdraw/FantasyWithdraw";
import MyTeamsPage from "../fantasy/fantasyPages/myTeamsPage";
import BetList from "../Admin/AdminPages/BetList/BetList";
import AccountStatement from "../Admin/AdminPages/AccountStatement/AccountStatement";
import ContestDetailsPage from "../fantasy/fantasyPages/contestDetails/ContestDetailsPage";
import UserAccountStatement from "../Admin/AdminPages/UserAccountStatement/UserAccountStatement";
import AdminAccountStatement from "../Admin/AdminPages/AdminAccountStatement/AdminAccountStatement";
import ProfitAndLosstatement from "../Admin/AdminPages/ProfitAndLossStatement/ProfitAndLosstatement";
import GameReport from "../Admin/AdminPages/GameReport/GameReport";
import AlertBets from "../Admin/AdminPages/AlertBets/AlertBets";
import CommissionReport from "../Admin/AdminPages/CommissionReport/CommissionReport";
import StockUsableBalance from "../fantasy/fantasyPages/stockUsableBalance/StockUsableBalance";
import SelectStocks from "../fantasy/fantasyPages/SelectStocks";
import TradingWatchlist from "../Trading/TradingPages/TradingWatchlist/TradingWatchlist";
import TradingTrades from "../Trading/TradingPages/TradingTrades/TradingTrades";
import StockList from "../fantasy/fantasyPages/stockList/StockList";
import StockLeaderBoard from "../fantasy/fantasyPages/stockLeaderBoard/StockLeaderBoard";
import TradingPortfolio from "../Trading/TradingPages/TradingPortfolio/TradingPortfolio";
import TradingBlockedScripted from "../Trading/TradingPages/TradingBlockedScripted/TradingBlockedScripted";
import TrangingMaxQuantityDetails from "../Trading/TradingPages/TrangingMaxQuantityDetails/TrangingMaxQuantityDetails";
import TrandingMarginManagement from "../Trading/TradingPages/TrandingMarginManagement/TrandingMarginManagement";
import TradingSummaryReport from "../Trading/TradingPages/TradingSummaryReport/TradingSummaryReport";
import FantasyProtectedRoute from "./protected/FantasyProtectedRoute";
import TradingHistory from "../Trading/TradingPages/TradingHistory/TradingHistory";
import TradingOrders from "../Trading/TradingPages/TradingOrders/TradingOrders";
import TradingProfitLoss from "../Trading/TradingPages/TradingProfitAndLoss/TradingProfitLoss";
import CasinoHome from "../casino/casino-home";
import BetListPage from "../pages/betList";
import MyAccountStatementPage from "../pages/myAccountStatement";
import CommissionReportPage from "../pages/commissionReport";
import QrDetails from "../Admin/AdminPages/qrDetails/QrDetails";
import BankDetails from "../Admin/AdminPages/bankDetails/BankDetails";
import UpiDetails from "../Admin/AdminPages/upiDetails/UpiDetails";
import CryptoDetails from "../Admin/AdminPages/cryptoDetails/CryptoDetails";
import ViewPayments from "../Admin/AdminPages/paymentDetails/view-Payment";
import CasinoReport from "../Admin/AdminPages/casinoReport/CasinoReport";
import CasinoReports from "../casino/casinoReports/CasinoReports";
import DerbyReports from "../Admin/AdminPages/derby/DerbyReports";
import DisputedBets from "../Admin/AdminPages/disputedBets/DisputedBets";
import UserDetail from "../Admin/AdminPages/userDetailPage/UserDetail";
import Market from "../Admin/AdminPages/Market/Market";
import Subscription from "../pages/subscription/Subscription";
import SubscriptionsportsPage from "../Subscribtion-Sports/subscribtionSportsPages/SportsHome/Sports";
import SportsSubscribtionProtectedRoutes from "./protected/SportsSubscribtionProtectedRoutes";
import Jackpot from "../pages/jackpot";
import SportsSubscriptionDetailPage from "../Subscribtion-Sports/subscribtionSportsPages/sportsDetailPage";
import SubscriptionBetListPage from "../pages/betList/Subscription-Bet";
import MySubscribtionBetsPage from "../Subscribtion-Sports/subscribtionSportsPages/myBets";
import SubscribtionAccount from "../pages/myAccountStatement/SubscribtionAccount";
import SubscriptionReports from "../pages/commissionReport/SubscriptionReports";
import WinMore from "../pages/winmore/WinMore";
import CasinoBetHistoryPage from "../pages/casinoReports/casinoBetHistory";
import MyAccountCasinoStatementPage from "../pages/casinoReports/myAccountCasinoStatement";
import WalletTransection from "../components/walletTransections/WalletTransection";
import AddMarket from "../Admin/AdminPages/Market/AddMarketUser";
import MarketCommissionTable from "../Admin/AdminPages/Marketcommissin/MarketCommission";
import AddMarketCommission from "../Admin/AdminPages/Marketcommissin/AddMarketCommission";
import DerbyPage from "../pages/derby/Derby";
import BinaryBetListPage from "../pages/binary/BetHistory";
import BinaryBetHistory from "../Admin/AdminPages/binary/BinaryBetHistory";
import DerbyBetListPage from "../pages/derby/DerbyHistory";






const RoutesComp = ({ socket }) => {
  return (
    <>
      <Routes fallbackElement={<Spinner color="red" />}>
        {/* <Route path={ROUTES_CONST.INDEX} element={<Home />} /> */}
        <Route path={ROUTES_CONST.INDEX} element={<Casino socket={socket} />} />
        <Route
          path={ROUTES_CONST.CASINO}
          element={<Casino socket={socket} />}
        />
        {/* <Route path={ROUTES_CONST.SPORTS} element={<Sports />} /> */}
        <Route
          path={ROUTES_CONST.ACCOUNT}
          element={<Protected Component={AccountTab} />}
        />
        <Route
          path={ROUTES_CONST.WALLET}
          element={<Protected Component={WalletTransection} />}
        />
        {/* <Route
          path={ROUTES_CONST.GLOBAL_SETTINGS}
          element={<GlobalSetting />}
        /> */}
        <Route path={ROUTES_CONST.PROMOTIONS} element={<Promotion />} />
        <Route
          path={`${ROUTES_CONST.CASINO_HOME}/:gameId`}
          element={<PlaySlot />}
        />
        <Route
          path={ROUTES_CONST.TERMS_AND_CONDITIONS}
          element={<TermsAndConditions />}
        />
        <Route
          path={ROUTES_CONST.RESPONSIBLE_GAMBLING}
          element={<ResponsibleGamble />}
        />
        <Route path={ROUTES_CONST.NEW_AML_POLICY} element={<NewAmlPolicy />} />
        <Route path={ROUTES_CONST.DOWNLOAD_APP} element={<DownloadApp />} />
        <Route
          path={ROUTES_CONST.AFFILIATE_PAGE}
          element={<AffilliatePage />}
        />
        <Route path={ROUTES_CONST.SPORTS_POLICY} element={<SportsPolicy />} />
        <Route path={ROUTES_CONST.PROVIDERS} element={<GameProviders />} />
        <Route
          path={`${ROUTES_CONST.PROMOTIONS}/:promotionId`}
          element={<PromotionDetail />}
        />

        <Route
          path={ROUTES_CONST.SUBSCRIBTION_BET_LIST}
          element={<Protected Component={SubscriptionBetListPage} />}
        />
        <Route
          path={ROUTES_CONST.MY_ACCOUNT_SUBSCRIBTION_STATEMENT}
          element={<Protected Component={SubscribtionAccount} />}
        />
        <Route
          path={ROUTES_CONST.COMMISSION_SUBSCRIBTION_REPORT}
          element={<Protected Component={SubscriptionReports} />}
        />


        <Route
          path={ROUTES_CONST.BET_LIST}
          element={<Protected Component={BetListPage} />}
        />
        <Route
          path={ROUTES_CONST.MY_ACCOUNT_STATEMENT}
          element={<Protected Component={MyAccountStatementPage} />}
        />
        <Route
          path={ROUTES_CONST.COMMISSION_REPORT}
          element={<Protected Component={CommissionReportPage} />}
        />
        <Route
          path={ROUTES_CONST.SUBSCRIPTION_PAGE}
          element={<Protected Component={Subscription} />}
        />
        <Route
          path={ROUTES_CONST.LOTTERY}
          element={<Protected Component={Jackpot} />}
        />
        <Route
          path={ROUTES_CONST.WIN_MORE}
          element={<Protected Component={WinMore} />}
        />

        {/* derby  */}

        <Route
          path={ROUTES_CONST.DERBY_HOME}
          element={<Protected Component={DerbyPage} />}
        />

        <Route
          path={ROUTES_CONST.DERBY_HOME_BET_HISTORY}
          element={<Protected Component={DerbyBetListPage} />}
        />

        {/* admin derby */}

        <Route
          path={`/${PORTAL}/:role/${DERBY_REPORTS}`}
          element={<AdminProtectedRoute Component={DerbyReports} />}
        />

        {/* end derby */}

        {/* binary */}

        <Route
          path={ROUTES_CONST.BINARY_BET_LIST}
          element={<Protected Component={BinaryBetListPage} />}
        />

        <Route path={ROUTES_CONST.BINARY} element={<SportsPage />} />

        {/* admin binary */}

        <Route
          path={`/${PORTAL}/:role/${BINARY_BET_HISTORY_PAGE}`}
          element={<AdminProtectedRoute Component={BinaryBetHistory} />}
        />


        {/* end binary */}



        {/* Casino reports -------------start*/}

        <Route
          path={ROUTES_CONST.CASINO_HISTORY}
          element={<Protected Component={CasinoBetHistoryPage} />}
        />

        <Route
          path={ROUTES_CONST.MY_ACCOUNT_CASINO_STATEMENT}
          element={<Protected Component={MyAccountCasinoStatementPage} />}
        />

        {/* Casino reports -------------end*/}



        {/* subscription sports  */}

        {/* <Route
          path={ROUTES_CONST.SPORTS_SUBSCRIPTION}
          element={<Protected Component={SubscriptionsportsPage} />}
        /> */}

        {/* sports subscription page */}
        <Route
          path={`${ROUTES_CONST.SPORTS_SUBSCRIPTION}/:matchId`}
          element={<SportsSubscriptionDetailPage />}
        />
        <Route
          path={ROUTES_CONST.SPORTS_SUBSCRIPTION}
          element={<SportsSubscribtionProtectedRoutes Component={SubscriptionsportsPage} />}
        />
        <Route
          path={ROUTES_CONST.SUBSCRIBTION_MY_BET_PAGE}
          element={<Protected Component={MySubscribtionBetsPage} />}
        />
        {/* end sports subscription page  */}



        {/* sports page */}
        <Route path={ROUTES_CONST.SPORTS} element={<SportsPage />} />
        {/* <Route path={ROUTES_CONST.SPORTS_DETAIL_PAGE} element={<SportsDetailPage />} /> */}
        <Route
          path={`${ROUTES_CONST.SPORTS}/:matchId`}
          element={<Protected Component={SportsDetailPage} />}
        />

        <Route
          path={ROUTES_CONST.SPORTS_MY_BET_PAGE}
          element={<Protected Component={MyBetsPage} />}
        />

        {/* ------------------admin routes */}
        {/* <AdminRoutes /> */}
        <Route path={"/" + PORTAL + "/" + LOGIN_URL} element={<SignIn />} />
        {/* <Route path={`/${userData.role}${DASHBOARD_URL}`} element={<Dashboard />} /> */}
        {/* <Route path={`/${userData.role}${USER_MANAGEMENT_URL}`} element={<Navigate to={`/${userData.role}${USER_MANAGEMENT_URL}/${userData._id}`} />} /> */}
        <Route
          path={`/${PORTAL}/:role/${USER_MANAGEMENT_URL}`}
          element={<AdminProtectedRoute Component={UserManagement} />}
        />
        <Route
          path={`/${PORTAL}/:role/${SHARING_REPORTS}`}
          element={<AdminProtectedRoute Component={SharingReports} />}
        />
        <Route
          path={`/${PORTAL}/:role/${DASHBOARD_URL}`}
          element={<AdminProtectedRoute Component={Dashboard} />}
        />
        <Route path={`/${PORTAL}/*`} element={<AdminPageNotFound />} />
        <Route
          path={`/${PORTAL}/:role/${BET_LIST_URL}`}
          element={<AdminProtectedRoute Component={BetList} />}
        />
        <Route
          path={`/${PORTAL}/:role/${MY_ACCOUNT_STATEMENT_URL}`}
          element={<AdminProtectedRoute Component={AccountStatement} />}
        />

        <Route
          path={`/${PORTAL}/:role/${ADMIN_ACCOUNT_STATEMENT_URL}`}
          element={<AdminProtectedRoute Component={AdminAccountStatement} />}
        />

        <Route
          path={`/${PORTAL}/:role/${USER_ACCOUNT_STATEMENT_URL}`}
          element={<AdminProtectedRoute Component={UserAccountStatement} />}
        />

        <Route
          path={`/${PORTAL}/:role/${PROFIT_AND_LOSS_STATEMENT_URL}`}
          element={<AdminProtectedRoute Component={ProfitAndLosstatement} />}
        />

        <Route
          path={`/${PORTAL}/:role/${GAME_REPORT_URL}`}
          element={<AdminProtectedRoute Component={GameReport} />}
        />

        <Route
          path={`/${PORTAL}/:role/${MARKET_PAGE}`}
          element={<AdminProtectedRoute Component={Market} />}
        />

        <Route
          path={`/${PORTAL}/:role/${MARKET_ADD_PAGE}`}
          element={<AdminProtectedRoute Component={AddMarket} />}
        />
        <Route
          path={`/${PORTAL}/:role/${MARKET_COMMISSION}`}
          element={<AdminProtectedRoute Component={MarketCommissionTable} />}
        />
        <Route
          path={`/${PORTAL}/:role/${MARKET_COMMISSION_ADD_PAGE}`}
          element={<AdminProtectedRoute Component={AddMarketCommission} />}
        />

        <Route
          path={`/${PORTAL}/:role/${ALERT_BETS_URL}`}
          element={<AdminProtectedRoute Component={AlertBets} />}
        />

        <Route
          path={`/${PORTAL}/:role/${COMMISSION_REPORT_URL}`}
          element={<AdminProtectedRoute Component={CommissionReport} />}
        />
        <Route
          path={`/${PORTAL}/:role/${QR_DETAILS_URL}`}
          element={<AdminProtectedRoute Component={QrDetails} />}
        />
        <Route
          path={`/${PORTAL}/:role/${BANK_DETAILS_URL}`}
          element={<AdminProtectedRoute Component={BankDetails} />}
        />
        <Route
          path={`/${PORTAL}/:role/${UPI_DETAILS_URL}`}
          element={<AdminProtectedRoute Component={UpiDetails} />}
        />
        <Route
          path={`/${PORTAL}/:role/${CRYPTO_DETAILS_URL}`}
          element={<AdminProtectedRoute Component={CryptoDetails} />}
        />
        <Route
          path={`/${PORTAL}/:role/${ADMIN_VIEW_PAYMENTS}`}
          element={<AdminProtectedRoute Component={ViewPayments} />}
        />
        <Route
          path={`/${PORTAL}/:role/${CASINO_REPORT_URL}`}
          element={<AdminProtectedRoute Component={CasinoReport} />}
        />
        <Route
          path={`/${PORTAL}/:role/${DISPUTED_BETS_URL}`}
          element={<AdminProtectedRoute Component={DisputedBets} />}
        />
        <Route
          path={`/${PORTAL}/:role/${USER_DETAIL_PAGE}/:userId`}
          element={<AdminProtectedRoute Component={UserDetail} />}
        />

        {/* derby */}



        {/* end derby */}


        {/* <Route path={`/${PORTAL}/${BET_LIST_URL}`} element={<BetList />} /> */}
        {/* <Route path={`/${PORTAL}/${MY_ACCOUNT_STATEMENT_URL}`} element={<AccountStatement />} /> */}

        {/* <Route path={`/${PORTAL}`} element={<AdminProtectedRoute />} /> */}

        {/* FANTASY ROUTES */}
        <Route
          path={ROUTES_CONST.FANTASY_HOME}
          element={<FantasyProtectedRoute Component={FantasyHome} />}
        />
        <Route
          path={`${ROUTES_CONST.FANTASY_HOME}/:id`}
          element={<FantasyProtectedRoute Component={FantasyGameDetail} />}
        />
        <Route
          path={`${ROUTES_CONST.FANTASY_SELECT_STOCKS_PAGE}`}
          element={<Navigate to={ROUTES_CONST.FANTASY_HOME} />}
        />
        <Route
          path={`${ROUTES_CONST.FANTASY_SELECT_STOCKS_PAGE}/:stockId`}
          element={<FantasyProtectedRoute Component={SelectStocks} />}
        />
        <Route
          path={ROUTES_CONST.CREATE_TEAM}
          element={<Navigate to={ROUTES_CONST.FANTASY_HOME} />}
        />
        <Route
          path={`${ROUTES_CONST.CREATE_TEAM}/:matchKey`}
          element={<FantasyProtectedRoute Component={CreateTeam} />}
        />
        <Route
          path={ROUTES_CONST.VIEW_TEAM}
          element={<FantasyProtectedRoute Component={TeamView} />}
        />
        <Route
          path={ROUTES_CONST.SELECT_CAPTAIN_VICECAPTAIN}
          element={<FantasyProtectedRoute Component={SelectCaptain} />}
        />
        <Route
          path={ROUTES_CONST.MY_MATCHES}
          element={<FantasyProtectedRoute Component={MyMatchesPage} />}
        />
        {/* <Route path={`${ROUTES_CONST.CREATE_TEAM}/:matchKey`} element={<CreateTeam />} /> */}
        {/* <Route path={ROUTES_CONST.VIEW_TEAM} element={<TeamView />} /> */}
        {/* <Route path={ROUTES_CONST.SELECT_CAPTAIN_VICECAPTAIN} element={<SelectCaptain />} /> */}
        {/* <Route path={ROUTES_CONST.MY_MATCHES} element={<MyMatchesPage />} /> */}
        <Route
          path={ROUTES_CONST.MY_MATCHES_CONTEST}
          element={<FantasyProtectedRoute Component={MyMatchesContest} />}
        />
        <Route
          path={ROUTES_CONST.CONTEST_DETAILS_PAGE}
          element={<FantasyProtectedRoute Component={ContestDetailsPage} />}
        />

        <Route
          path={ROUTES_CONST.FANTASY_WALLET}
          element={<FantasyProtectedRoute Component={FantasyWallet} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_DEPOSIT_CASH}
          element={<FantasyProtectedRoute Component={DepositCash} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_TRANSACTION}
          element={<FantasyProtectedRoute Component={Transaction} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_LEADERbOARD}
          element={<FantasyProtectedRoute Component={LeaderBoard} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_INVITE_FRIEND}
          element={<FantasyProtectedRoute Component={InviteFriend} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_VERIFY_ACCOUNT}
          element={<FantasyProtectedRoute Component={VerifyAccount} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_PROFILE}
          element={<FantasyProtectedRoute Component={Profile} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_PLAY_INSIGHT_PROFILE}
          element={<FantasyProtectedRoute Component={PlayInsights} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_MORE}
          element={<FantasyProtectedRoute Component={More} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_CONTACT_HELP}
          element={<FantasyProtectedRoute Component={ContactHelp} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_TERM_AND_CONDITION}
          element={<FantasyProtectedRoute Component={TermsAndCondition} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_MATCH_DETAIL}
          element={<FantasyProtectedRoute Component={MyMatchesDetail} />}
        />
        <Route
          path={ROUTES_CONST.MY_TEAMS_PAGE}
          element={<FantasyProtectedRoute Component={MyTeamsPage} />}
        />

        <Route
          path={ROUTES_CONST.FANTASY_WALLET}
          element={<FantasyProtectedRoute Component={FantasyWallet} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_DEPOSIT_CASH}
          element={<FantasyProtectedRoute Component={DepositCash} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_TRANSACTION}
          element={<FantasyProtectedRoute Component={Transaction} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_INVITE_FRIEND}
          element={<FantasyProtectedRoute Component={InviteFriend} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_VERIFY_ACCOUNT}
          element={<FantasyProtectedRoute Component={VerifyAccount} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_PROFILE}
          element={<FantasyProtectedRoute Component={Profile} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_PLAY_INSIGHT_PROFILE}
          element={<FantasyProtectedRoute Component={PlayInsights} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_MORE}
          element={<FantasyProtectedRoute Component={More} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_CONTACT_HELP}
          element={<FantasyProtectedRoute Component={ContactHelp} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_TERM_AND_CONDITION}
          element={<FantasyProtectedRoute Component={TermsAndCondition} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_MY_PORTFOLIO}
          element={<FantasyProtectedRoute Component={MyPortfolio} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_NOTIFICATION}
          element={<FantasyProtectedRoute Component={Notification} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_STOCK_USABLE_BALANCE}
          element={<FantasyProtectedRoute Component={StockUsableBalance} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_STOCK_LIST}
          element={<FantasyProtectedRoute Component={StockList} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_STOCK_LEADERBOARD}
          element={<FantasyProtectedRoute Component={StockLeaderBoard} />}
        />

        {/* trading routes */}
        <Route
          path={ROUTES_CONST.TRADING_WATCHLIST}
          element={<Protected Component={TradingWatchlist} />}
        />
        <Route
          path={ROUTES_CONST.TRADING_TRADES}
          element={<Protected Component={TradingTrades} />}
        />
        <Route
          path={ROUTES_CONST.TRADING_PORTFOLIO}
          element={<Protected Component={TradingPortfolio} />}
        />
        <Route
          path={ROUTES_CONST.TRADING_BLOCKED_SCRIPTS}
          element={<Protected Component={TradingBlockedScripted} />}
        />
        <Route
          path={ROUTES_CONST.TRADING_MAX_QUANTITY_DETAILS}
          element={<Protected Component={TrangingMaxQuantityDetails} />}
        />
        <Route
          path={ROUTES_CONST.TRADING_MARGIN_MANAGEMENT}
          element={<Protected Component={TrandingMarginManagement} />}
        />
        <Route
          path={ROUTES_CONST.TRADING_SUMMARY_REPORT}
          element={<Protected Component={TradingSummaryReport} />}
        />
        <Route
          path={ROUTES_CONST.TRADING_HISTORY}
          element={<Protected Component={TradingHistory} />}
        />
        <Route
          path={ROUTES_CONST.TRADING_ORDERS}
          element={<Protected Component={TradingOrders} />}
        />
        <Route
          path={ROUTES_CONST.TRADING_Profit_Loss}
          element={<Protected Component={TradingProfitLoss} />}
        />

        {/* Casino */}
        <Route
          path={ROUTES_CONST.CASINO_HOME}
          element={<Protected Component={CasinoHome} />}
        />
        <Route
          path={ROUTES_CONST.CASINO_REPORTS}
          element={<Protected Component={CasinoReports} />}
        />
      </Routes>
    </>
  );
};

export default RoutesComp;
