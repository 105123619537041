import React from 'react'
import { useAuthModal } from '../../hooks/useAuthModal';
import { AUTH_MODAL_TABS } from '../../constants';

const BannerBeforeLogin = () => {
  
  const { setAuthModalObject } = useAuthModal();
  return (
    <div className="row mx-0 beforeLogin">
      <div className="col-lg-5 text-center bannerInner">
        <div className="textDiv">
          <span className="d-block moreTxt">MORE</span>
          <span className="d-block promotionTxt">PROMOTIONS</span>
        </div>
        <div className='btnDiv'>
          <button className='signUpBtn'
              onClick={() =>
                setAuthModalObject((pre) => ({
                selectedTab: AUTH_MODAL_TABS.SIGN_UP,
                isAuthOpen: true,
                }))
            }>SIGN UP</button>
          <div className='signUpTxt'>SIGN UP & <span>GET REWARDS</span></div>
        </div>
      </div>
    </div>
  )
}

export default BannerBeforeLogin