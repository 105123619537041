import React, { useEffect, useState } from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import "./style.scss";
import { useAuth } from "../../hooks/useAuth";
import { getAPIAuth, postAPIAuth } from "../../service/apiInstance";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionUsers } from "../../store/action";
import moment from "moment/moment";
import { useLocation } from "react-router-dom";

const Subscription = () => {
  const [subscriptiondata, setSubscriptionData] = useState([]);
  const [packagesubscription, setPackageSubscription] = useState([]);
  const [toogelclass, setToogleClass] = useState([false, false, false]);
  const [subscriptionid, setSubscriptionId] = useState('');
  const currentUdateDate = moment(packagesubscription.createdAt);
  const userDetails = useSelector(state => state.userFullDetails);
  const location = useLocation();
  const userRole = location?.pathname?.includes('/portal') ? "admin" : "user"


  const dispatch = useDispatch()

  const { token } = useAuth();

  const getSubscriptionGetAll = async () => {
    if (token) {
      try {
        const res = await getAPIAuth("user/getAllPackages" + `?for=${userRole}`, token);
        // console.log("res", res);
        if (res?.data?.data?.success) {
          setSubscriptionData(res?.data?.data?.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  //console.log("kkkkkkkkkkkkkkkkkkk",packagesubscription)




  const getUserPackageSubscription = async () => {

    if (token) {
      try {
        const res = await getAPIAuth("user/getUserPackageSubscription", token);
        // console.log("res", res);
        if (res?.data?.data?.success) {
          setPackageSubscription(res?.data?.data?.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    }

  }
  useEffect(() => {
    getSubscriptionGetAll();
    getUserPackageSubscription();
  }, [token]);

  const handleClick = (index) => {
    const updatedClass = toogelclass.map((item, i) => (i === index ? !item : false));
    setToogleClass(updatedClass);
  }

  const subscribeHandle = async (id) => {
    const obj = {
      "packageSubscriptionId": id
    }

    try {
      const res = await postAPIAuth(`user/buyPackageSubscription`, obj, token);
      if (res?.data?.data?.success) {
        toast(`${res?.data?.data?.message}`);
        console.log(res?.data?.data?.data, "getSubscriptionUsers");
        getUserPackageSubscription();
        dispatch(getSubscriptionUsers(res?.data?.data?.data));
      }
    } catch (error) {
      toast(`${error.response.data.data.message}`);
    }

  }
  console.log(packagesubscription?.[0]?.status)
  return (
    <>
      <main className="main vh-100 d-flex flex-column" id="main">
        <div className="mainContaint flex-fill">
          <div className="homepage subscriptionPage">
            {/* cards */}
            <div className="container-fluid planSection">
              <div className="container">
                <div className="row">
                  <div className="col-12 mb-4">
                    <div className="sectionHeading">Our Portfolio Plans</div>
                  </div>
                  <div className="subscribtion-plan-details">

                    {
                      packagesubscription?.map((item, index) => (
                        <>
                          <div class="col-12" key={index}>
                            <div class="main-card">
                              <div class="name-button-subscribe d-flex">
                                <div class="main-card_title">
                                  {item?.packageData?.[0]?.name}
                                </div>
                                <div class="main-card_description">
                                  Subscribtion Details
                                </div>
                                <div class="main-card_button_subscribed">
                                  <button class={`button-subscribed-btn ${item?.dailyLossStatus == "active" ? 'lossDanger' : 'active'}`}>
                                    <span>
                                      <i class="bi bi-patch-check-fill"></i>
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="name-button-subscribe  ">
                                <div className="d-flex gap-2 mx-2 name-button-subscribe subscribtion-details ">
                                  <div class="pricing">
                                    ₹{item?.packageData?.[0]?.price}
                                    <span class="small color-only">/per Week</span>
                                  </div>
                                  <div class="small main-card_description">Subscription Points: {item?.points}</div>
                                  <div class="main-card_button_subscribed">
                                    {item?.archived ?
                                      <span class="small">Archived</span> :
                                      <span class="small">UnArchived</span>}
                                  </div>
                                </div>

                              </div>
                              <div class="border-div"></div>
                              <div class="features">
                                <table class="table table-dark table-hover">
                                  <tbody>
                                    <tr>
                                      <td scope="row">Purchase Period :</td>
                                      <td>{moment(item.createdAt).format("MMM DD YYYY , HH:mm")}</td>

                                      <td scope="row">Expiry period :</td>
                                      <td>{item?.packageData?.[0]?.subscription_period?.totalDays ? currentUdateDate?.add(item?.packageData?.[0]?.subscription_period?.totalDays, 'days').format("MMM DD YYYY , HH:mm") : `${item?.packageData?.[0]?.subscription_period?.totalBet} Bets`}</td>
                                    </tr>

                                    {
                                      item?.packageData?.[0]?.subscription_period?.totalBet ?

                                        <tr>
                                          <td scope="row">Remaning Bets :</td>
                                          <td>{item?.packageData?.[0]?.subscription_period?.totalBet - item?.totalBetCount}</td>

                                          <td scope="row">Inactive Expire Period :</td>
                                          <td>{`${item?.packageData?.[0]?.subscription_expiry_period} Days`}</td>
                                        </tr>

                                        :

                                        ""
                                    }




                                    <tr>
                                      <td scope="row">Maximum Profit :</td>
                                      <td>{item?.packageData?.[0]?.maximun_profit}</td>
                                      <td scope="row">User Profit:</td>
                                      <td>{item?.userWon}</td>

                                    </tr>

                                    <tr>
                                      <td scope="row">Maximum Loss:</td>
                                      <td>{item?.packageData?.[0]?.maximun_loss}</td>
                                      <td scope="row">User Loss:</td>
                                      <td>{item?.userLost}</td>

                                    </tr>

                                    <tr>
                                      <td scope="row">Leverage:</td>
                                      <td>{item?.packageData?.[0]?.leverage} x</td>

                                      <td scope="row">Profit Sharing:</td>
                                      <td>{item?.packageData?.[0]?.adminProfitType.profit_sharing ?
                                        `${item?.packageData?.[0]?.adminProfitType.profit_sharing}%`
                                        : item?.packageData?.[0]?.adminProfitType.fixed_revenue}</td>

                                    </tr>

                                  </tbody>
                                </table>
                              </div>

                            </div>
                          </div>
                        </>
                      ))
                    }

                  </div>
                  <div className="col-12">
                    <div className="row gy-4 pt-4 pt-sm-5">
                      {subscriptiondata.map((ele, index) => {
                        console.log("qwertele", ele);
                        let isSubscribed = false;
                        packagesubscription?.map(item => {

                          if (item.status) {
                            if (item?.packageData[0]?.name == ele?.name) {

                              // console.log(item , "itemmmm");
                              isSubscribed = true;

                            }
                          }

                        })

                        //console.log(isSubscribed , "isSubscribed");

                        return (
                          <>
                            <div key={index} className="col-12 col-sm-6 col-lg-4">
                              <div className={`main-card ${toogelclass[index] ? 'active' : ''}`} onClick={() => handleClick(index)}>
                                <div className="name-button-subscribe  d-flex ">
                                  <div className="main-card_title">
                                    {ele?.name}
                                  </div>
                                  <div className="main-card_button_subscribed">
                                    <button className={`button-subscribed-btn ${isSubscribed ? 'active' : ''}`}>
                                      <span>
                                        <i class="bi bi-patch-check-fill"></i>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="pricing">
                                  {ele?.price}₹
                                  <span className="small" style={{ fontSize: "small" }}>/per Week(for {userRole})</span>
                                </div>
                                {/* <div>Save $9</div> */}
                                <div className="border-div"></div>
                                <div className="features">
                                  <table class="table table-dark table-hover">
                                    <tbody>
                                      {

                                        ele.subscription_period.totalBet ?
                                          <tr>
                                            <td scope="row">Total Bet:</td>
                                            <td>{ele.subscription_period.totalBet}</td>
                                          </tr>
                                          :

                                          <tr>
                                            <td scope="row">Total Days :</td>
                                            <td>{ele.subscription_period.totalDays}</td>
                                          </tr>

                                      }
                                      <tr>
                                        <td scope="row">maximum Daily Loss:</td>
                                        <td>{ele?.maximun_daily_loss}</td>
                                      </tr>
                                      <tr>
                                        <td scope="row">maximum Loss:</td>
                                        <td>{ele?.maximun_loss}</td>
                                      </tr>
                                      <tr>
                                        <td scope="row">maximum Profit:</td>
                                        <td>{ele?.maximun_profit}</td>
                                      </tr>
                                      <tr>
                                        <td scope="row">Subscription Expiry Days:</td>
                                        <td>{ele?.subscription_expiry_period}</td>
                                      </tr>
                                      <tr>
                                        <td scope="row">Leverage:</td>
                                        <td>{ele?.leverage} x</td>
                                      </tr>
                                      {/* <tr>
                                        <td scope="row">Profit Sharing:</td>
                                        <td>{ele?.profit_sharing}%</td>
                                      </tr> */}
                                      {

                                        ele?.adminProfitType?.profit_sharing ?
                                          <tr>
                                            <td scope="row">Profit Sharing:</td>
                                            <td>{ele?.adminProfitType?.profit_sharing}%</td>
                                          </tr>
                                          :

                                          <tr>
                                            <td scope="row">Profit Sharing:</td>
                                            <td>{ele?.adminProfitType?.fixed_revenue}₹</td>
                                          </tr>

                                      }
                                    </tbody>
                                  </table>
                                </div>
                                {/* <ul className="features">
                                  <li>One account</li>
                                  <li>Unlimited benifits</li>
                                  <li>Customized Plans</li>
                                </ul> */}
                                {/* <!-- Button trigger modal --> */}
                                <button
                                  type="button"
                                  class="btn subsBtn"
                                  data-bs-toggle="modal"
                                  onClick={() => setSubscriptionId(ele._id)}
                                  data-bs-target="#exampleModalsubs">
                                  Buy Now
                                </button>


                                {/* <a href="javascript:;" className="subsBtn">
                                  
                                </a> */}
                              </div>
                            </div>
                          </>
                        );
                      })}
                      {/* <div className="col-12 col-sm-6 col-lg-4">
                        <div className="main-card active">
                          <div className="main-card_title">Monthly</div>
                          <div className="pricing">
                            39$<span className="small">/per month</span>
                          </div>
                          <div>Save $15</div>
                          <div className="border-div"></div>
                          <ul className="features">
                            <li>One account</li>
                            <li>Unlimited benifits</li>
                            <li>Customized Plans</li>
                          </ul>
                          <a href="javascript:;" className="subsBtn">
                            Buy Now
                          </a>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <div className="main-card">
                          <div className="main-card_title">Yearly</div>
                          <div className="pricing">
                            60$<span className="small">/per Year</span>
                          </div>
                          <div>Save $25</div>
                          <div className="border-div"></div>
                          <ul className="features">
                            <li>Six account</li>
                            <li>Unlimited benifits</li>
                            <li>Customized Plans</li>
                          </ul>
                          <a href="javascript:;" className="subsBtn">
                            Buy Now
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* cards */}
          </div>
        </div>
        <AppFooter />
      </main>





      {/* Modal */}
      <div class="modal fade subs-modal" id="exampleModalsubs" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content modalContent">
            <div class="modal-header border-0">
              {/* <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> */}
              <button type="button " class="btn-close subsCloseBtn" data-bs-dismiss="modal" aria-label="Close">
                <div className="">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path></svg>
                </div>
              </button>
            </div>
            <div class="modal-body modalBody border-0">
              Do You Want To Continue ?
            </div>
            <div class="modal-footer border-0">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                class="btn btnprimary customBtn navBankBtn border-0 text-black"
                data-bs-dismiss="modal"
                onClick={() => subscribeHandle(subscriptionid)}

              >Save</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
