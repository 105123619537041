import React, { useEffect, useState } from 'react'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getFantasyAuth } from '../../fantasyServices'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { errorToaster } from '../../../utils/toaster'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import check from "./../../fantasyAssets/img/check.svg";
import search from "./../../fantasyAssets/img/search.svg";


const SelectStocks = () => {
  const stockId = useParams()?.stockId
  const [stockSectors, setStockSectors] = useState([])
  const [activeTab, setActiveTab] = useState('All Sectors')
  const [companyStockData, setCompanyStockData] = useState([]);
  const [loader, setLoader] = useState(true)
  const [myPortfolio, setMyPortfolio] = useState([]);
  const [portfolioLoading, setPortfolioLoading] = useState(true)
  const currentHomeTab = useSelector(state => state?.currentHomeTab)
  // const [company, setCompany] = useState( navigation?.route?.params?.stocks ? navigation?.route?.params?.stocks : [],);
  const stockContest =  useLocation()?.state?.stockContest
  const stock = useLocation()?.state?.stocks
  const [company, setCompany] = useState(stock ? stock : []);
  const [editflag, setEditflag] = useState(stock ? true : false,);
  const [searched, setSearched] = useState('')
  const [searchedStock, setSearchedStock] = useState([])
  const navigate = useNavigate()
  console.log({ stockSectors, companyStockData, myPortfolio });

  console.log({ stockContest, company });

  const getCategoryList = async () => {
    try {
      const categoryList = await getFantasyAuth('/get-stock-category');
      if (categoryList.data.success) {
        setStockSectors([
          { name: 'All Sectors' },
          { name: 'My Portfolio' },
          ...categoryList.data.data,
        ]);

        getCompanyList('all');
      }

    } catch (error) {

    }
  };

  const getPortfolio = async () => {
    try {
      const MyPortData = await getFantasyAuth(
        `/my-portfolio-data?portfolioCat=${currentHomeTab}`,
      );
      if (MyPortData.data.success) {
        setMyPortfolio(MyPortData?.data?.data);
      }

    } catch (error) {

    } finally {
      setPortfolioLoading(false)
    }
  }

  const getCompanyList = async item => {
    setLoader(true);
    try {
      if (item?.name != 'My Portfolio') {
        if (item) {
          if (item == 'all' || item?.name == 'All Sectors') {
            const res = await getFantasyAuth('/getAllStockWithAllSelector');
            if (res?.data?.success) {
              setCompanyStockData(res?.data?.data);
            }
          } else {
            const res = await getFantasyAuth(
              `/getallstockaccordingcategory?stockcategory=${item?._id}`,
            );
            console.log("res", res?.data?.data)
            if (res?.data?.success) {
              setCompanyStockData(res?.data?.data);
            }
          }
        } else {
          const res = await getFantasyAuth('/getAllStockWithAllSelector');
          if (res?.data?.success) {
            setCompanyStockData(res?.data?.data);
          }
          // setActiveTab("All Sectors")
        }

      } else {
        setCompanyStockData([]);
      }
    } catch (error) {
      //error
    } finally {
      setLoader(false);
    }
    setSearchedStock([])
  };


  const handleSetCompany = item => {
    if (company.length != 2) {
      setCompany(prev => [...prev, item]);
    } else {
      // errorToaster('To chose a different stock, remove one selected stock');
      errorToaster('You can only choose two stocks at a time.');
    }
  };
  useEffect(() => {
    console.log("select---------------------------", currentHomeTab)
    if (currentHomeTab === 'STOCKS') {
      getCategoryList()
      getCompanyList()
      getPortfolio()
    }
  }, [currentHomeTab])
  // console.log("activeTab", { activeTab, companyStockData, searchedStock })

  const handleRemoveCompany = item => {
    let filtered = company.filter(el => el._id != item._id);
    setCompany(filtered);
    // setcompanyStockData(prev => [item, ...prev]);
  };

  useEffect(() => {
    if (searched) {
      let companiesNewData = companyStockData.filter(el =>
        el.name.toUpperCase().includes(searched.toUpperCase()),
      );
      setSearchedStock(companiesNewData);
    } else {
      setSearchedStock([])
    }
  }, [searched]);

  return (
    <>
      <div className="row fantasyInner">
        <FantasyHeader title={"Select Stocks"} gameHeader={true} />
        <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
          <div className="col-12 gameOptionsTabWrapper sticky-top">
            <div className="row mx-0 align-items-center flex-nowrap overflow-x-auto justify-content-between gameOptionsTabInner">
              {stockSectors?.map((item, index) => (
                <div
                  onClick={() => {
                    setActiveTab(item.name)
                    getCompanyList(item)
                  }}
                  className={`gameOptionTab col-auto text-nowrap text-center text-uppercase ${activeTab === item.name ? 'active' : ''}`}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
          <div className="row mx-0">
            <div className="row mainCenterPart gy-3 mx-0 mt-0 pt-1 py-4">
              <div className='text-light'>
                Selected Stocks
              </div>
              {
                company?.map(item => ((
                  <div className="col-12" key={item?._id}>
                    <div
                      className="warningCard addcase card cursor-pointer border-0 rounded-4 position-relative"
                      // onClick={() => handleCompany(item)}
                      onClick={() => handleRemoveCompany(item)}

                    >
                      <div className="card-body">
                        <div className="row mx-0 align-items-center">
                          <div className="col-auto ps-0">
                            <div className="circle "></div>
                          </div>
                          <div className="col px-0">
                            <div className="portfolioCardTxt">
                              {item?.name}
                            </div>
                            <div className="percentage">
                              {Number(item?.closePrice) >
                                Number(item?.openPrice)
                                ? (
                                  ((Number(item?.closePrice) -
                                    Number(item?.openPrice)) *
                                    100) /
                                  Number(item?.closePrice)
                                ).toFixed(2)
                                : Number(item?.closePrice) <
                                  Number(item?.openPrice)
                                  ? (
                                    ((Number(item?.openPrice) -
                                      Number(item?.closePrice)) *
                                      100) /
                                    Number(item?.openPrice)
                                  ).toFixed(2)
                                  : 0}
                              %
                              <span className="mx-2">
                                {item?.closePrice}
                              </span>
                            </div>
                          </div>
                          <div className="col-auto pe-0">
                            <img className="checkImg" src={check} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )))
              }

              <div className="col stocksTabWrapper ">
                <div className="inputWrapper position-relative d-flex align-items-center">
                  <input
                    className=" searchInp"
                    type="text"
                    value={searched}
                    placeholder='Search stocks here'
                    onChange={(e) => setSearched(e?.target?.value)}
                  />
                  <img className="searchImg" src={search} alt="" />
                </div>
              </div>

              <div className='text-light'>
                My PortFolio Data
              </div>
              {
                myPortfolio?.map(item => {
                  if (
                    !company?.filter(el => el._id == item._id).length
                  ) return (
                    <div className="col-12" key={item?._id}>
                      <div
                        className="warningCard addcase card cursor-pointer border-0 rounded-4 position-relative"
                        onClick={() => handleSetCompany(item)}
                      >
                        <div className="card-body">
                          <div className="row mx-0 align-items-center">
                            <div className="col-auto ps-0">
                              <div className="circle "></div>
                            </div>
                            <div className="col px-0">
                              <div className="portfolioCardTxt">
                                {item?.name}
                              </div>
                              <div className="percentage">
                                {Number(item?.closePrice) >
                                  Number(item?.openPrice)
                                  ? (
                                    ((Number(item?.closePrice) -
                                      Number(item?.openPrice)) *
                                      100) /
                                    Number(item?.closePrice)
                                  ).toFixed(2)
                                  : Number(item?.closePrice) <
                                    Number(item?.openPrice)
                                    ? (
                                      ((Number(item?.openPrice) -
                                        Number(item?.closePrice)) *
                                        100) /
                                      Number(item?.openPrice)
                                    ).toFixed(2)
                                    : 0}
                                %
                                <span className="mx-2">
                                  {item?.closePrice}
                                </span>
                              </div>
                            </div>
                            <div className="col-auto pe-0">
                              {/* <img className="checkImg" src={check} alt="" /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }

              <div className='text-light'>
                {!!searched && searchedStock?.length ? "Result" : " All Stocks"}
              </div>
              {
                // searchedStock
                loader ? (
                  <div className='w-100 h-100 d-flex justify-content-center'>
                    <Spinner color='white' size='sm' />
                  </div>
                ) : (
                  companyStockData?.length === 0 ? (
                    <div className='w-100 h-100 d-flex justify-content-center text-light' >No Data Available</div>
                  ) : !!searchedStock && searchedStock?.length ? (
                    searchedStock?.map(item => {
                      if (
                        !company?.filter(el => el._id == item._id).length &&
                        !myPortfolio?.filter(el => el._id == item._id).length
                      )
                        return (
                          <div className="col-12" key={item?._id}>
                            <div
                              className="warningCard addcase card cursor-pointer border-0 rounded-4 position-relative"
                              onClick={() => handleSetCompany(item)}
                            >
                              <div className="card-body">
                                <div className="row mx-0 align-items-center">
                                  <div className="col-auto ps-0">
                                    <div className="circle "></div>
                                  </div>
                                  <div className="col px-0">
                                    <div className="portfolioCardTxt">
                                      {item?.name}
                                    </div>
                                    <div className="percentage">
                                      {Number(item?.closePrice) >
                                        Number(item?.openPrice)
                                        ? (
                                          ((Number(item?.closePrice) -
                                            Number(item?.openPrice)) *
                                            100) /
                                          Number(item?.closePrice)
                                        ).toFixed(2)
                                        : Number(item?.closePrice) <
                                          Number(item?.openPrice)
                                          ? (
                                            ((Number(item?.openPrice) -
                                              Number(item?.closePrice)) *
                                              100) /
                                            Number(item?.openPrice)
                                          ).toFixed(2)
                                          : 0}
                                      %
                                      <span className="mx-2">
                                        {item?.closePrice}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-auto pe-0">
                                    {/* <img className="checkImg" src={check} alt="" /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                    })
                  ) : (
                    companyStockData?.map(item => {
                      if (
                        !company?.filter(el => el._id == item._id).length &&
                        !myPortfolio?.filter(el => el._id == item._id).length
                      )
                        return (
                          <div className="col-12" key={item?._id}>
                            <div
                              className="warningCard addcase card cursor-pointer border-0 rounded-4 position-relative"
                              onClick={() => handleSetCompany(item)}


                            >
                              <div className="card-body">
                                <div className="row mx-0 align-items-center">
                                  <div className="col-auto ps-0">
                                    <div className="circle "></div>
                                  </div>
                                  <div className="col px-0">
                                    <div className="portfolioCardTxt">
                                      {item?.name}
                                    </div>
                                    <div className="percentage">
                                      {Number(item?.closePrice) >
                                        Number(item?.openPrice)
                                        ? (
                                          ((Number(item?.closePrice) -
                                            Number(item?.openPrice)) *
                                            100) /
                                          Number(item?.closePrice)
                                        ).toFixed(2)
                                        : Number(item?.closePrice) <
                                          Number(item?.openPrice)
                                          ? (
                                            ((Number(item?.openPrice) -
                                              Number(item?.closePrice)) *
                                              100) /
                                            Number(item?.openPrice)
                                          ).toFixed(2)
                                          : 0}
                                      %
                                      <span className="mx-2">
                                        {item?.closePrice}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-auto pe-0">
                                    {/* <img className="checkImg" src={check} alt="" /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        );
                    })
                  )
                )
              }
            </div>

            <button className='createTeamBtn'
              onClick={() => {
                if (company?.length == 2) {
                  navigate(`${ROUTES_CONST?.FANTASY_STOCK_LIST}`,
                    {
                      state: {
                        companyData: company,
                        data: stockContest,
                        editflag
                      }
                    })
                } else {
                  errorToaster("Stocks has to be two !!")
                }
              }
              }
            >
              Select Stocks
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SelectStocks