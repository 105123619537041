import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import redArrow from "./../../TradingAssets/img/redicon.svg";
import greenArrow from "./../../TradingAssets/img/greenicon.svg";
import { postTradingAPI } from '../../TradingServices';
import { errorToaster, succesToaster } from '../../../utils/toaster';
import { isEqual } from 'lodash';
import GraphModal from './GraphModal';
import { useInterval } from '../../../hooks/useInterval';

const TradingModal = ({
  showModal,
  setShowModal,
  modalInputValues,
  modalData,
  setModalInputValues,
}) => {
  console.log(modalInputValues);

  const [modalError, setModalError] = useState("");
  const [data, setData] = useState([])
  const [blink, setBlink] = useState(false)
  const [showGraph, setShowGraph] = useState(false)
  const [previous, setPrevious] = useState({})

  const fetchData = async () => {
    try {
      const body = {
        action: "instrument-detail",
        symbol_instrument_token: modalData?.instToken,
      };
      const res = await postTradingAPI("instrument-detail.php", body);
      const result = await res.json();
      // if (!isEqual(result, data)) {
      setPrevious(data)
      setData(result?.data);
      // }
    } catch (error) {
      console.log("error", error);
    }
  };
  useInterval(() => {
    if (modalData?.instToken) {
      fetchData();
    }
  }, 3000)
  // useEffect(() => {

  //   const interval = setInterval(() => {
  //     if (modalData?.instToken) {
  //       fetchData();
  //     }
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, [modalData?.instToken]);



  const handleChange = (e) => {
    if (e.target.name === "lot_size") {
      console.log("--->>", modalInputValues);
      if (!isNaN(Number(e.target.value))) {
        setModalInputValues((pre) => ({
          ...pre,
          [e.target.name]: e.target.value
        }))
      }
      // setModalInputValues((prev) => {
      //   // if (Number(e.target.value)<=0) {
      //   if (!isNaN(Number(e.target.value))) {
      //     return { ...prev, [e.target.name]: e.target.value }
      //   }
      //   // if (Number(e.target.value)<=0) {
      //   //   return { ...prev,[e.target.name]:"1" }          
      //   // }else{
      //   //   return {...prev,[e.target.name]: e.target.value}
      //   // }
      // })
    } else {
      setModalInputValues((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleModalSubmit = () => {
    if (
      modalInputValues?.buy_sell === "" ||
      modalInputValues?.mis_cnc === "" ||
      modalInputValues?.mkt_lmt_sl === "" ||
      modalInputValues?.lot_size === "1"
    ) {
      setModalError("*Please fill all the fields");
    } else {
      if (
        modalInputValues?.mkt_lmt_sl === "SL" &&
        modalInputValues?.enter_trigger_price === ""
      ) {
        setModalError("*Please enter a trigger price");
      } else {
        handlePlacingOrder();
      }
    }
  };

  const handlePlacingOrder = async () => {
    if (Number(modalInputValues?.lot_size) < 1) {
      errorToaster("Minimum lot size is one")
      return
    }
    try {
      const body = {
        action: "order-place",
        symbol_instrument_token: data?.instrument_token,
        trading_symbol: data?.tradingSymbol,
        buy_sell: modalInputValues?.buy_sell,
        quantity: Number(modalData?.lot_size) * Number(modalInputValues?.lot_size),
        mis_cnc: modalInputValues?.mis_cnc,
        mkt_lmt_sl: modalInputValues?.mkt_lmt_sl,
        enter_price: data?.ltp_price,
        enter_trigger_price: modalInputValues?.enter_trigger_price,
        lot_size: modalInputValues?.lot_size,
      };
      const res = await postTradingAPI("order-place.php", body);
      const response = await res?.json();
      if (response.res_status === "1") {
        succesToaster("order placed successfully!");
        setShowModal(false);
      } else {
        errorToaster(response.res_msg);
      }
    } catch (error) {
      errorToaster("Something went wrong!");
    }
  };

  const resetModalData = () => {
    // setModalData({});
    setModalInputValues({
      buy_sell: "BUY",
      quantity: "",
      mis_cnc: "CNC",
      mkt_lmt_sl: "MARKET",
      enter_trigger_price: "",
      lot_size: "1",
    });
    setModalError("");
  };

  useEffect(() => {
    setBlink(true);
    const timeout = setTimeout(() => {
      setBlink(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, [data]);

  return (
    <>
      <Modal
        centered
        show={showModal}
        onHide={() => {
          setShowModal(false);
          resetModalData();
        }}
        size="xl"
        className="tradingPages "
      >
        <div className="col-12">
          <div className={`bankNifitCard JoinusSection ${modalInputValues?.buy_sell === "BUY" ? 'bg-primary' : 'bg-danger'}`}>
            <div className="row">
              <div className="col-12">
                <div className="row align-items-center  gap-3">
                  <div className="col-12">
                    <div className="BANKNIFTY d-inline-flex">
                      {modalData?.trading_name}
                    </div>
                    <img src="assets/img/graphIcon.png"
                      onClick={() => setShowGraph(true)}
                      className='rounded cursor-pointer ms-2' style={{ height: 40, width: 50, }} alt="" />
                  </div>
                  <div className="col-12 mb-3">
                    <ul className="row align-items-center list-unstyled mb-0 bankRateNifty g-2">
                      <li className={`col-sm col-4`}>
                        <div className={`dataBank redBackGround ${blink && previous?.bid_price != data?.bid_price ? 'blink' : ''}`}>
                          <div className=" rateHeading">BID RATE</div>
                          <div className="ratePrice ">{data?.bid_price ? data?.bid_price : '0.00'}</div>
                        </div>
                      </li>
                      <li className={` col-sm col-4`}>
                        <div className={`dataBank redBackGround ${blink && previous?.ask_price != data?.ask_price ? 'blink' : ''}`}>
                          <div className=" rateHeading">ASK RATE</div>
                          <div className="ratePrice ">{data?.ask_price ? data?.ask_price : '0.00'}</div>
                        </div>
                      </li>
                      <li className={`col-sm col-4`}>
                        <div className={`dataBank redBackGround ${blink && previous?.ltp_price != data?.ltp_price ? 'blink' : ''}`}>
                          <div className=" rateHeading">LTP</div>
                          <div className="ratePrice ">{data?.ltp_price ? data?.ltp_price : '0.00'}</div>
                        </div>
                      </li>
                      <li className=" col-sm col-4 ">
                        <div className='dataBank'>
                          <div className=" rateHeading">CHANGE %</div>
                          <div className="ratePrice ">
                            {data?.diff_val_percent ? data?.diff_val_percent : '0.00'}
                          </div>
                        </div>
                      </li>
                      <li className=" col-sm col-4 ">
                        <div className='dataBank'>
                          <div className=" rateHeading">NET CHG</div>
                          <div className="ratePrice d-flex align-items-center">
                            <div className="d-flex align-items-center me-1">
                              {!modalData?.diff_val > 0 ? (
                                <div className="redIcon d-inline-flex align-items-center">
                                  <img
                                    src={greenArrow}
                                    className="img-fluid w-100 h-100 "
                                    alt="table accordion"
                                  />
                                </div>
                              ) : (
                                <div className="redIcon d-inline-flex align-items-center">
                                  <img
                                    src={redArrow}
                                    className="img-fluid w-100 h-100 "
                                    alt="table accordion"
                                  />
                                </div>
                              )}
                            </div>
                            {data?.diff_val ? data?.diff_val : '0.00'}
                          </div>
                        </div>
                      </li>
                      <li className=" col-sm col-4">
                        <div className="dataBank">
                          <div className=" rateHeading">HIGH</div>
                          <div className="ratePrice ">{data?.ohlc_high ? data?.ohlc_high : '0.00'}</div>
                        </div>
                      </li>
                      <li className=" col-sm col-4">
                        <div className='dataBank'>
                          <div className=" rateHeading">LOW</div>
                          <div className="ratePrice ">{data?.ohlc_low ? data?.ohlc_low : '0.00'}</div>
                        </div>
                      </li>
                      <li className=" col-sm col-4">
                        <div className='dataBank'>
                          <div className=" rateHeading">OPEN</div>
                          <div className="ratePrice ">{data?.ohlc_open ? data?.ohlc_open : '0.00'}</div>
                        </div>
                      </li>
                      <li className=" col-sm col-4">
                        <div className='dataBank'>
                          <div className=" rateHeading">CLOSE</div>
                          <div className="ratePrice ">
                            {data?.ohlc_close ? data?.ohlc_close : '0.00'}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="row align-items-center gap-md-0 gap-2">
                      <div className="col-sm col-12">
                        <div className="radio-group d-flex align-items-center mt-md-0 mt-2">
                          <div>
                            <input
                              type="radio"
                              id="buy_sellBuy"
                              name="buy_sell"
                              value="BUY"
                              checked={modalInputValues?.buy_sell === "BUY"}
                              onChange={handleChange}
                            />
                            <label htmlFor="buy_sellBuy" className="bankLabel">
                              BUY
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="buy_sellSell"
                              name="buy_sell"
                              value="SELL"
                              checked={modalInputValues?.buy_sell === "SELL"}
                              onChange={handleChange}
                            />
                            <label htmlFor="buy_sellSell" className="bankLabel">
                              SELL
                            </label>
                          </div>
                        </div>
                        <div className=" socialmedia mt-1">
                          <div className="dropdown">
                            <button
                              className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {modalInputValues?.mis_cnc
                                ? modalInputValues?.mis_cnc
                                : "Product"}
                            </button>
                            <ul className="dropdown-menu dropdownMenu w-100">
                              {/* <li>
                                    <div className="dropdown-item dropdownItem">
                                      <div className="input-group w-100 searchDrop rounded-2 inputGroup">
                                        <span
                                          className="input-group-text  shadow-none border-0 bg-transparent searchIcon"
                                          id="basic-addon2"
                                        >
                                          <img
                                            src={searchIcon}
                                            className="img-fluid w-100 h-100"
                                            alt="table accordion"
                                          />
                                        </span>
                                        <input
                                          type="search"
                                          className="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none"
                                          placeholder="Search"
                                          aria-label="search"
                                          aria-describedby="basic-addon2"
                                        />
                                      </div>
                                      <div className="txt">
                                        Please enter 1 or More Character
                                      </div>
                                    </div>
                                  </li> */}
                              <li
                                className={`menuLinkDrop ${modalInputValues?.mis_cnc === "CNC"
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={() =>
                                  setModalInputValues((prev) => ({
                                    ...prev,
                                    mis_cnc: "CNC",
                                  }))
                                }
                              >
                                CNC
                              </li>
                              <li
                                className={`menuLinkDrop ${modalInputValues?.mis_cnc === "MIS"
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={() =>
                                  setModalInputValues((prev) => ({
                                    ...prev,
                                    mis_cnc: "MIS",
                                  }))
                                }
                              >
                                MIS
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12 mb-2 mb-sm-0 px-lg-0">
                        <div className="col-12 inputdata mt-1">
                          <div className="inputLabel">LOT</div>
                          <input
                            type="text"
                            id="lot_size"
                            className="form-control inputlogin border-0 shadow-none"
                            name="lot_size"
                            value={modalInputValues?.lot_size}
                            // value={"hello"}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {modalInputValues.mkt_lmt_sl === "SL" && (
                        <div className="col">
                          <div className="col-12 inputdata mt-sm-1 mt-2">
                            <div className="inputLabel">
                              enter trigger price
                            </div>
                            <input
                              type="text"
                              id="enter_trigger_price"
                              className="form-control inputlogin border-0 shadow-none"
                              name="enter_trigger_price"
                              value={modalInputValues?.enter_trigger_price}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="row align-items-center  gap-2">
                      <div className="col-12">
                        <div className="row align-items-center gap-sm-0">
                          {/* <div className="col-sm-4">
                            <div className="col-12 inputdata mt-sm-1 mt-2">
                              <div className="inputLabel">QTY</div>
                              <input
                                type="number"
                                id="quantity"
                                className="form-control inputlogin border-0 shadow-none"
                                name="quantity"
                                value={modalInputValues?.quantity}
                                onChange={handleChange}
                                disabled
                              />
                            </div>
                          </div> */}
                          <div className="col-sm-4 col-4 mt-2 mt-sm-0 pe-0">
                            <div className="priceInput mt-sm-1">QTY</div>
                            <div className=" priceDiv mt-1 text-start">
                              {Number(modalData?.lot_size) * Number(modalInputValues?.lot_size)}
                            </div>
                          </div>
                          <div className="col-sm-4 col-4 mt-2 mt-sm-0 pe-0">
                            <div className="priceInput mt-sm-1">PRICE</div>
                            <div className=" priceDiv mt-1 text-start">
                              {data?.ltp_price}
                            </div>
                          </div>
                          <div className="col-sm-4 col-4">
                            <div className=" socialmedia mt-sm-4 mt-4">
                              <div className="dropdown w-100">
                                <button
                                  className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle w-100"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  {modalInputValues?.mkt_lmt_sl
                                    ? modalInputValues?.mkt_lmt_sl
                                    : "Order"}
                                </button>
                                <ul className="dropdown-menu dropdownMenu w-100">
                                  {/* <li>
                                        <div className="dropdown-item dropdownItem">
                                          <div className="input-group w-100 searchDrop rounded-2 inputGroup">
                                            <span
                                              className="input-group-text  shadow-none border-0 bg-transparent searchIcon"
                                              id="basic-addon2"
                                            >
                                              <img
                                                src={searchIcon}
                                                className="img-fluid w-100 h-100"
                                                alt="table accordion"
                                              />
                                            </span>
                                            <input
                                              type="search"
                                              className="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none"
                                              placeholder="Search"
                                              aria-label="search"
                                              aria-describedby="basic-addon2"
                                            />
                                          </div>
                                          <div className="txt">
                                            Please enter 1 or More Character
                                          </div>
                                        </div>
                                      </li> */}
                                  <li
                                    className={`menuLinkDrop ${modalInputValues?.mkt_lmt_sl === "MARKET"
                                      ? "active"
                                      : ""
                                      }`}
                                    onClick={() =>
                                      setModalInputValues((prev) => ({
                                        ...prev,
                                        mkt_lmt_sl: "MARKET",
                                      }))
                                    }
                                  >
                                    MARKET
                                  </li>
                                  <li
                                    className={`menuLinkDrop ${modalInputValues?.mkt_lmt_sl === "LMT"
                                      ? "active"
                                      : ""
                                      }`}
                                    onClick={() =>
                                      setModalInputValues((prev) => ({
                                        ...prev,
                                        mkt_lmt_sl: "LMT",
                                      }))
                                    }
                                  >
                                    LMT
                                  </li>
                                  <li
                                    className={`menuLinkDrop ${modalInputValues?.mkt_lmt_sl === "SL"
                                      ? "active"
                                      : ""
                                      }`}
                                    onClick={() =>
                                      setModalInputValues((prev) => ({
                                        ...prev,
                                        mkt_lmt_sl: "SL",
                                      }))
                                    }
                                  >
                                    SL
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5">
                    {modalError && (
                      <span className="text-warning fs-6"> {modalError} </span>
                    )}
                    <div className="row align-items-center justify-content-center  mt-sm-4 mt-3">
                      <div className="col-6">
                        <button
                          className="sumbitBtn w-100 border-0"
                          onClick={handleModalSubmit}
                        >
                          SUBMIT
                        </button>
                      </div>
                      <div className="col-6 ps-0">
                        <button
                          className="sumbitBtn w-100 redBtn border-0"
                          onClick={() => {
                            setShowModal(false);
                            resetModalData();
                          }}
                        >
                          CANCEL{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <GraphModal
        showGraph={showGraph}
        setShowGraph={setShowGraph}
      />
    </>
  )
}

export default TradingModal