import { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { adminGetAPIAuth } from "../../AdminServices";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { Table } from "react-bootstrap";
import { REPORTS } from "../../AdminConstants/apiEndpoints";
import Pagination from "../../Admincomponents/pagination/Pagination";

const UserLogsForm = ({ setShow, data, updateData }) => {

    const [tableData, setTableData] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    console.log("totalPages", totalPages);
    const settlementCommision = async () => {
        try {
            const res = await adminGetAPIAuth(
                `${REPORTS?.settlementCommision}?id=${data?._id}&page=${currentPage}&perPage=10`
            );
            if (res?.data?.data?.success) {
                setTableData(res?.data?.data?.data?.[0]?.fetchDetails);
                setTotalPages(res?.data?.data?.data?.[0]?.pagination?.totalPages);

            } else {
                errorToaster("Something went wrong in fetching data");
            }
        } catch (error) {
            if (error?.response?.status === 401) {

            }
            errorToaster(error?.response?.data?.data?.message);
            setTableData([]);
            console.log("error -------------->", error);
        }
    };

    useEffect(() => {

        settlementCommision();

    }, [currentPage])

    return (
        <>
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table">
                        <thead className='text-uppercase text-nowrap'>
                            <tr>
                                <th>
                                    <div className="d-flex">
                                        S.NO
                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex">
                                        Username
                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex">
                                        Amount
                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex">
                                        Type
                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex">
                                        Transaction Id
                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex">
                                        Comments
                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex">
                                        Settled

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex">
                                        Status
                                    </div>
                                </th>


                            </tr>
                        </thead>
                        {tableData?.length ? <tbody>
                            {
                                tableData?.map((item, index) => (
                                    <tr>
                                        <td className="align-middle fw-medium text-capitalize">{index + 1}</td>
                                        <td className="align-middle fw-medium text-capitalize">{item?.userName}</td>
                                        <td className="align-middle fw-medium text-capitalize">{item?.amount}</td>
                                        <td className="align-middle fw-medium text-capitalize">{item?.type}</td>
                                        <td className="align-middle fw-medium text-capitalize">{item?.transaction_id}</td>
                                        <td className="align-middle fw-medium text-capitalize">{item?.comment}</td>
                                        <td className="align-middle fw-medium text-capitalize">{item?.settled}</td>
                                        <td className="align-middle fw-medium text-capitalize">{item?.status}</td>



                                    </tr>
                                ))
                            }
                        </tbody> :
                            <tbody>
                                <tr>
                                    <td colSpan={3} className='text-center py-5'>
                                        <b> No Data...</b>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>

            {
                totalPages > 1 ?
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                    : ''
            }
        </>



    );
};

export default UserLogsForm;
