import React from "react";
import { useAuth } from "../../hooks/useAuth";
import { useSignUpModal } from "../../hooks/useSignUpModal";
import { useWalletModal } from "../../hooks/useWallet";
import { AUTH_MODAL_TABS, WALLET_MODAL_TABS } from "../../constants";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const DepositBanner = () => {
    const socialMediaData = useSelector(state => state?.socialLinks);
    // console.log("socialMediaData", socialMediaData);
    const { token } = useAuth()
    const { setAuthModalObject } = useAuthModal();
    const { setWalletModalObject } = useWalletModal()


    // function for opening modal
    const handleModal = () => {
        if (token) {
            setWalletModalObject((prev) => ({
                isWalletOpen: true,
                selectedTab: WALLET_MODAL_TABS.DEPOSIT
            }))
        } else {
            setAuthModalObject((pre) => ({
                isAuthOpen: true,
                selectedTab: AUTH_MODAL_TABS.LOG_IN,
            }))
        }
    }

    return (

        <div className="container-fluid depositSection d-none d-lg-block px-0">
            <div className="depositInner">
                <div className="row mx-0 align-items-center">
                    <div className="col-auto px-lg-0 depositHeading">DEPOSIT NOW AND GET UP <br className="d-none depositBr" /> TO 100% BONUS  </div>
                    <div className="col-lg px-lg-0 mt-2 mt-lg-0">
                        <div className="row mx-0 align-items-center justify-content-between">
                            <div className="col depositImgCol px-4 d-flex justify-content-lg-center">
                                <div className="d-flex w-100 depositImgContainer justify-content-center align-items-center">
                                    {
                                        socialMediaData?.length > 0 ?
                                            socialMediaData?.map((item) => (
                                                <Link to={item?.link} target="_blank" className="depositImg depositImg2 mx-1" key={item?._id}>
                                                    <img src={item?.image} className="h-100 w-100 object-fit-contain" alt="" />
                                                </Link>
                                            ))
                                            : ''
                                    }
                                    <img src="assets/img/googlePay.png" className="depositImg mx-1" alt="googlePay" />
                                </div>
                            </div>
                            <div className="col-auto px-lg-0">
                                <div
                                    onClick={handleModal}
                                    className="depositAnchor cursor-pointer">DEPOSIT</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DepositBanner