import React, { useEffect, useState } from 'react'
import StocksCard from '../../fantasyComponents/stocksCard/StocksCard'
import { getFantasyAuth } from '../../fantasyServices'
import { Spinner } from 'react-bootstrap'

const Stocks = ({ activeTab }) => {
  const data = [1, 2, 3]
  const [contest, setContest] = useState([])
  const [loading, setLoading] = useState(true)
  // const [banner, setBanner] = useState('')
  // const [bannerLoading, setBannerLoading] = useState('')

  const getContestData = async () => {
    try {
      const respo = await getFantasyAuth(
        `get-stock-contest?stock_contest_cat=${activeTab}`,
      );
      // console.log({ respo });

      setContest(respo?.data?.data);

    } catch (error) {
      //err
    } finally {
      setLoading(false);

    }
  };

  useEffect(() => {
    if (activeTab === 'STOCKS') {
      getContestData()
      // getBanner()
    }
  }, [activeTab])
  console.log(contest);
  // myMatches m se stock leaderboard  pr jaega 

  return (
    <>
      <div className="col-12">
        {
          loading ? (
            <div className='w-100 h-100 d-flex justify-content-center'>
              <Spinner color='white' size='sm' />
            </div>
          ) : (
            !contest || contest == 0 ? (
              <div className='w-100 h-100 d-flex justify-content-center text-light' >No Data Available</div>
            ) : (
              contest?.map((item => (
                <StocksCard
                  el={item}
                  // navigation={navigation}
                  // route1={'SelectStock'}
                  params1={{ data: item }}
                  timer={true}
                  selected={true}

                />
              )))
            )
          )

        }
      </div>
    </>
  )
}

export default Stocks