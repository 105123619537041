import React, { useEffect, useState } from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import ConfirmationModal from '../../fantasyComponents/confirmationModal/ConfirmationModal'
import TeamCard from '../../fantasyComponents/myTeamCard/TeamCard'
import editIcon from './../../fantasyAssets/img/editIcon.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { postFantasyAuth } from '../../fantasyServices'
import { errorToaster, succesToaster } from '../../../utils/toaster'
import { useSelector } from 'react-redux'

const MyTeamsPage = () => {
    const state = useLocation()?.state
    const navigate = useNavigate()
    const [selected, setSelected] = useState([])
    const team = state?.team
    const numOfSel = state?.numOfSel
    const data = state?.data
    const contest = state?.contest
    const challenge = state?.challenge
    console.log("state", "from my team", state, selected)
    const fantasyUserData = useSelector(state => state?.fantasyUserData)

    const joinContest = async () => {
        try {
            let jointeam = [];
            selected.map(el => {
                jointeam.push(el);
            });
            const params = {
                matchkey: data?.id,
                matchchallengeid: challenge?.matchchallengeid,
                jointeamid: jointeam.join(','),
            };
            const res = await postFantasyAuth('joinContest', params);
            if (res.data.success) {
                succesToaster('Contest joined successfully!')
                setSelected([])
                navigate(ROUTES_CONST.FANTASY_HOME)
            } else {
                errorToaster(res.data.message)
                navigate(ROUTES_CONST.FANTASY_HOME)

            }

        } catch (error) {

        }
    }
    console.log({ selected })
    return (
        <>
            <div className="row fantasyInner">
                <FantasyHeader gameHeader={true} title={'More'} />
                <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                    <div className="col-12 mt-3">
                        <div className='my-2 text-light mx-1' >
                            CHOOSE TEAM TO JOIN THIS CONTEST WITH
                        </div>
                        <div className="row ">
                            {
                                team?.map((item) => (
                                    <>
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            className="col-12 mb-3"
                                        >
                                            <div className="card myTeamCard teamCard overflow-hidden">
                                                <div className="card-header pt-2">
                                                    <div className="row align-items-center mx-0">
                                                        <div className="col px-0">
                                                            {/* <div className="teamOwName">{`John (T${item?.teamnumber})`}</div> */}
                                                            <div className="teamOwName">{`${fantasyUserData?.team} (T${item?.teamnumber})`}</div>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <div
                                                                className="buttons d-flex align-items-center gap-3"
                                                            >
                                                                <div
                                                                    className="viewTeam d-flex align-items-center"
                                                                >
                                                                    {
                                                                        contest?.multi_entry ? (
                                                                            <input
                                                                                type='checkbox'
                                                                                value={item?.jointeamid}
                                                                                onChange={(e) => setSelected(pre => {
                                                                                    // if (contest?.multi_entry) {
                                                                                    if (selected.includes(e?.target?.value)) {
                                                                                        return selected.filter(el => el !== e.target.value)
                                                                                    }
                                                                                    return ([...pre, e?.target?.value])

                                                                                    // }
                                                                                })}

                                                                            />

                                                                        ) : (
                                                                            <input
                                                                                type='radio'
                                                                                value={item?.jointeamid}
                                                                                name='teams'
                                                                                onChange={e => setSelected([item?.jointeamid])}

                                                                            />

                                                                        )
                                                                    }
                                                                    {/* <input
                                                                        type='checkbox'
                                                                        value={item?.jointeamid}
                                                                        onChange={(e) => setSelected(pre => {
                                                                            // if (contest?.multi_entry) {
                                                                                if (selected.includes(e?.target?.value)) {
                                                                                    return selected.filter(el => el !== e.target.value)
                                                                                }
                                                                                return ([...pre, e?.target?.value])

                                                                            // }
                                                                        })}

                                                                    /> */}
                                                                </div>
                                                                <div
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        // navigate(Route1, { state: params1 });
                                                                        navigate(`${ROUTES_CONST?.CREATE_TEAM}/${data?.id}`, {
                                                                            state: {
                                                                                capVicePlr: {
                                                                                    captain: item.captin_name,
                                                                                    viceCaptain: item.viceCaptain_name,
                                                                                },
                                                                                players: item,
                                                                                myteam: true,
                                                                                contest: contest,
                                                                                data: data,
                                                                                edit: item.teamnumber,
                                                                            }
                                                                        });

                                                                    }}
                                                                    className="viewTeam d-flex align-items-center">
                                                                    <img className="w-100" src={editIcon} alt="" />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body px-0 pb-0">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto d-flex align-items-center">
                                                            <div className="caption text-center ">C</div>
                                                            <div className="imgOuter">
                                                                <img className="w-100" src={item?.captainimage} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col text-center">
                                                            <div className="teamDescc">{item?.team1_name}: <span>{item?.player?.filter(el => item?.team1Id == el?.team)?.length}</span></div>
                                                            <div className="teamDescc">{item?.team2_name}: <span>{item?.player?.filter(el => item?.team2Id == el?.team)?.length}</span></div>
                                                        </div>
                                                        <div className="col-auto d-flex align-items-center">
                                                            <div className="caption vCaption text-center  order-2">VC</div>
                                                            <div className="imgOuter order-1">
                                                                <img className="w-100" src={item?.vicecaptainimage} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 capNames p-1 d-flex justify-content-between">
                                                            <div className="captainName  px-4">{item?.captin_name}</div>
                                                            <div className="captainName  px-4">{item?.viceCaptain_name}</div>
                                                        </div>
                                                        <div className="col-12 py-2">
                                                            <div className="row mx-0">
                                                                <div className="col px-0 teamDivide text-center">
                                                                    <div className="playerCat">Wk</div>
                                                                    <div className="noOfPlyr">{item?.player?.filter(el => el?.role == 'keeper')?.length}</div>
                                                                </div>
                                                                <div className="col px-0 teamDivide border-start text-center">
                                                                    <div className="playerCat">BAT</div>
                                                                    <div className="noOfPlyr">{item?.player?.filter(el => el?.role == 'batsman')?.length}</div>
                                                                </div>
                                                                <div className="col px-0 teamDivide border-start text-center">
                                                                    <div className="playerCat">Ar</div>
                                                                    <div className="noOfPlyr">{item?.player?.filter(el => el?.role == 'allrounder')?.length}</div>
                                                                </div>
                                                                <div className="col px-0 teamDivide border-start text-center">
                                                                    <div className="playerCat">Bowl</div>
                                                                    <div className="noOfPlyr">{item?.player?.filter(el => el?.role == 'bowler')?.length}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                    <ConfirmationModal />
                </div>
                <FantasyBottomBar />
            </div>
            <button className='createTeamBtn'
                // onClick={() => navigate(`${ROUTES_CONST?.CREATE_TEAM}/${id}`, {
                //     state: {
                //         data: data.state,
                //         contest: contests.length ? contests[0].contest[0] : ''
                //     },
                // })}
                // onClick={()=>alert("hello")}
                onClick={joinContest}
            >
                Confirm
            </button >
        </>
    )
}

export default MyTeamsPage