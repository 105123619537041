import React, { useRef } from "react";

const FantasyOtpInput = ({ setOtp }) => {
  // Create an array of refs for each OTP input field
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  // Function to handle input change for each OTP input field
  const handleInputChange = (index, event) => {
    const value = event.target.value;

    // Check if the value is not an empty string
    if (value) {
      // Check if it's not the last input field
      if (index !== 3) {
        // Move the focus to the next input field
        inputRefs[index + 1].current.focus();
      } else {
        // When it's the last input, remove focus from it
        inputRefs[index].current.blur();
      }
    }

    // Get the values from the refs
    const otpValues = inputRefs.map((ref) => ref.current.value);
    // Send the concatenated values to the parent component
    setOtp(otpValues.join(''));
  };

  // Function to handle keydown events for each OTP input field
  const handleKeyDown = (index, event) => {
    // Get the key name of the key pressed
    const keyName = event.key;
    // Check for backspace key and that it's not the first input field
    if (keyName === "Backspace" && index !== 0) {
      // Get the current input where the key is pressed
      const currentInput = inputRefs[index].current;
      
      // Check if the current input value is empty
      if (currentInput.value === '') {
        // Move the focus to the previous input
        inputRefs[index - 1].current.focus();
      } else {
        // Remove the value from the current input
        currentInput.value = '';
      }
    } else if (keyName === "Backspace" && index === 0) {
      // Clear the value of the first input
      inputRefs[index].current.value = '';
    }

    // Get the values from the refs
    const otpValues = inputRefs.map((ref) => ref.current.value);
    // Send the concatenated values to the parent component
    setOtp(otpValues.join(''));
  };

  return (
    <div className="d-flex justify-content-center gap-3" >
      {/* Render four input fields for OTP */}
      <input
        id="codeBox1"
        className="form-control inp py-2 bg-transparent rounded-2 shadow-none"
        type="tel"
        max="1"           
        maxLength="1"
        ref={inputRefs[0]}
        onChange={(e) => handleInputChange(0, e)}
        onKeyDown={(e) => handleKeyDown(0, e)}
      />
      <input
        id="codeBox2"
        className="form-control inp py-2 bg-transparent rounded-2 shadow-none"
        type="tel"
        max="1"
        maxLength="1"
        ref={inputRefs[1]}
        onChange={(e) => handleInputChange(1, e)}
        onKeyDown={(e) => handleKeyDown(1, e)}
      />
      <input
        id="codeBox3"
        className="form-control inp py-2 bg-transparent rounded-2 shadow-none"
        type="tel"
        max="1"
        maxLength="1"
        ref={inputRefs[2]}
        onChange={(e) => handleInputChange(2, e)}
        onKeyDown={(e) => handleKeyDown(2, e)}
      />
      <input
        id="codeBox4"
        className="form-control inp py-2 bg-transparent rounded-2 shadow-none"
        type="tel"
        max="1"
        maxLength="1"
        ref={inputRefs[3]}
        onChange={(e) => handleInputChange(3, e)}
        onKeyDown={(e) => handleKeyDown(3, e)}
      />
    </div>
  );
};

export default FantasyOtpInput;
