import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { dateFormatter } from "../../AdminUtils/Utils";

const AdminTxModal = ({ show, setShow, data }) => {
  return (
    <Modal show={show} centered onHide={() => setShow(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Transactions Details</Modal.Title>
      </Modal.Header>

      <Modal.Body className="pb-4">
        <Container>
          <Row className="g-3" >
            <Col className="d-flex col-xs-12 col-sm-12 col-md-6 ">
              <span className="AdminTxModalSpan">Id:</span>
              <span>{data?.transaction_id}</span>
            </Col>
            <Col className="d-flex col-xs-12 col-sm-12 col-md-6 ">
              <span className="AdminTxModalSpan">Type:</span>
              <span>{data?.transactionType}</span>
            </Col>
            <Col className="d-flex col-xs-12 col-sm-12 col-md-6 ">
              <span className="AdminTxModalSpan">From:</span>
              <span>{data?.transferedBy}</span>
            </Col>
            <Col className="d-flex col-xs-12 col-sm-12 col-md-6 ">
              <span className="AdminTxModalSpan">To:</span>
              <span>{data?.transferedToUser}</span>
            </Col>
            <Col className="d-flex col-xs-12 col-sm-12 col-md-6 ">
              <span className="AdminTxModalSpan">Opening Balance:</span>
              <span>{data?.transferedByPreviousPoints}</span>
            </Col>
            <Col className="d-flex col-xs-12 col-sm-12 col-md-6 ">
              <span className="AdminTxModalSpan">Closing Balance:</span>
              <span>{data?.transferedByTotalPoints}</span>
            </Col>
            <Col className="d-flex col-xs-12 col-sm-12 col-md-6 ">
              <span className="AdminTxModalSpan">Date:</span>
              <span className="text-nowrap" >{dateFormatter(data?.createdAt, true)}</span>
            </Col>
            <Col className="d-flex col-xs-12 col-sm-12 col-md-6 ">
              <span className="AdminTxModalSpan">Amount:</span>
              <span>{data?.points}</span>
            </Col>
            <Col className="d-flex col-xs-12 col-sm-12 col-md-6 ">
              <span className="AdminTxModalSpan">Remarks:</span>
              <span>{data?.desc}</span>
            </Col>
            <Col className="d-flex col-xs-12 col-sm-12 col-md-6 ">
              <span className="AdminTxModalSpan">CR:</span>
              <span>{data?.transferedByTotalPoints}</span>
            </Col>
          </Row>

        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AdminTxModal;
