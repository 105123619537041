import React from 'react'
import { WALLET_MODAL_TABS } from '../../constants'
import { useWalletModal } from '../../hooks/useWallet'

const BannerAfterLogin = () => {
  const { setWalletModalObject } = useWalletModal()
  
  return (
    <div className="row mx-0 afterLogin position-relative">
      <div className="col-lg-auto posCol">
        <div className="d-flex flex-column justify-content-between h-100 detailTxt">
          <div className='text-uppercase'>1st deposit</div>
          {/* <div className='detail'>Details
            <img src="./assets/img/promotions/detailArrow.png" alt="" />
          </div> */}
        </div>
      </div>
      <div className="col-lg bannerInner text-center text-lg-start withoutPosCol">
        <div className="textDiv">
          <span className="d-block moreTxt">GREAT BONUS FOR</span>
          <span className="d-block promotionTxt">EVERY DEPOSIT</span>
        </div>
        <div className='btnDiv'>
          <div className='signUpTxt'>Bonus <span>100%</span></div>
          <button className='signUpBtn'
            onClick={()=>  
              setWalletModalObject((prev) => ({
              isWalletOpen: true,
              selectedTab: WALLET_MODAL_TABS.DEPOSIT
          }))}>DEPOSIT NOW</button>
        </div>
      </div>
    </div>
  )
}

export default BannerAfterLogin