import React from 'react'
import MatchSection from './MatchSection'
import Events from './Events'
import BetCard from './BetCard'
import LiveEvents from './LiveEvents'

const Highlight = () => {
  return (
    <>
      <MatchSection />
      <LiveEvents />
      <Events />
    </>
  )
}

export default Highlight