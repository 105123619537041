import { useState } from "react";
import { Form } from "react-bootstrap";
import "./customInput.css"

//eslint-disable-next-line
const PasswordInput = ({ value, handleChange, id, name, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputFocused,setInputFocused]=useState(false)

  return (
    <div className={`passWordGroup mb-3`}>
      <Form.Group className="mb-3 d-flex justify-content-evenly align-items-center flex-nowrap position-relative">
        <input
          type={showPassword ? "text" : "password"}
          className="form-control passwordInputFocus"
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          onFocus={()=>setInputFocused(true)}
          onBlur={()=>setInputFocused(false)}
          {...rest}
        />
        <span
          className="w-25 d-flex justify-content-center align-item-center showPassword"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <i className="bi bi-eye eye-icon"></i>
          ) : (
            <i className="bi bi-eye-slash eye-icon"></i>
          )}
        </span>
      </Form.Group>
    </div>
  );
};

export default PasswordInput;
