// import React, { useState } from "react";

// export const WalletModalContext = React.createContext(null)

// const WalletModalContextProvider = ({ children }) => {
//     const [walletInOpen, setWalletInOpen] = useState(false)
//     return <WalletModalContext.Provider value={{ walletInOpen, setWalletInOpen }}>{children}</WalletModalContext.Provider>;
// };

// export default WalletModalContextProvider;


import React, { useState } from "react";
import { WALLET_MODAL_TABS } from "../constants";


export const WalletModalContext = React.createContext(null)

const WalletModalContextProvider = ({ children }) => {
    // const [isAuthOpen, setIsAuthOpen] = useState(false)
    const [walletModalObject, setWalletModalObject] = useState({
        isWalletOpen: false,
        selectedTab: WALLET_MODAL_TABS.DEPOSIT
    })
    return <WalletModalContext.Provider value={{ walletModalObject, setWalletModalObject }}>{children}</WalletModalContext.Provider>;
};

export default WalletModalContextProvider;
