import React from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import inviteLogo from './../../fantasyAssets/img/inviteFriendBanner.png'
import send from './../../fantasyAssets/img/sendYellow.png'
import wallet from './../../fantasyAssets/img/walletYellow.png'
import cart from './../../fantasyAssets/img/cartYellow.png'
import copy from './../../fantasyAssets/img/copyy.png'
import { useState } from 'react'
import {errorToaster, succesToaster} from './../../../utils/toaster'

const InviteFriend = () => {
    const[reffralCode, setReffralCode] = useState("MG11-johnL79B")

    const share = ()=>{
        if (navigator.share) {
            navigator.share({
                title: 'Invite Your friends',
                url: 'http://206.189.129.134/'
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(err => {
                console.log(err);
            });
        } else {
            errorToaster("Browser doesn't support sharing!");
        }
    }

    const copyToClipboard = ()=>{
        navigator.clipboard.writeText(reffralCode)
        succesToaster('Successfully copied to clipboard')
    }


    return (
        <div className="row fantasyInner">
        <FantasyHeader gameHeader={true} title={'Invite Friend'}/>
            <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                <div class="row mainCenterPart gap-2 mx-0">
                    <div class="col-12 textBg">
                        <div class="referImg w-100 mx-auto mt-3 mb-2"><img src={inviteLogo} class="w-100" alt="Referrals"/></div>
                        {/* <div class="text-uppercase text-white fw-bold fs-6 text-center">Refer a friends</div> */}
                    </div>
                    <div className='col-12 worksWrapper py-3'>
                        <div className='invite fw-semibold mb-3'>How Does it Works?</div>
                        <div className='row mb-4'>
                            <div className='col-auto'><img className='workIcon' src={send}/></div>
                            <div className='col ps-0'>
                                <div className='worksMain worksLine'>Invite your friend</div>
                                <div className='worksSub'>Share your Referral code or invite your friend as many as possible</div>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-auto'><img className='workIcon' src={wallet}/></div>
                            <div className='col ps-0'>
                                <div className='worksMain worksLine'>Make a transaction</div>
                                <div className='worksSub'>Share your Referral code or invite your friend as many as possible</div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-auto'><img className='workIcon' src={cart}/></div>
                            <div className='col ps-0'>
                                <div className='worksMain '>Earn your coin</div>
                                <div className='worksSub'>Share your Referral code or invite your friend as many as possible</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 py-3'>
                        <div className='invite fw-semibold mb-3'>Share your code as many as possible !</div>
                        <div className='copyWrapper'>{reffralCode}
                            <button onClick={copyToClipboard} className='copyBtn'>
                                <img className='' src={copy}/>
                            </button>
                        </div>
                        <div class=""><button onClick={share} class="inviteBtn px-5 text-white w-100 btn-lg">INVITE YOUR FRIEND</button></div>
                    </div>
                </div>
            </div>  
        <FantasyBottomBar/>
        </div>
    )
}

export default InviteFriend