import axios from 'axios'
import { ADMIN_AUTH_ACCESS, adminBaseUrl } from '../../constants'

// no auth token required
export const adminGetAPI = async (url, body) => {
    const response = await axios.get(`${adminBaseUrl}/${url}`, body, {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",

        }
    })
    return (response)
}

export const adminGetAPIAuth = async (url) => {
    const bURL = adminBaseUrl;
    const token = localStorage.getItem(ADMIN_AUTH_ACCESS);
    try {
        const response = await axios.get(`${bURL}/${url}`, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            }
        })
        return response
    } catch (error) {
        if (error.response.data.message === 'Invalid token') {
            localStorage.clear();
        }
        throw error;
    }
}

// no auth token required
export const adminPostAPI = async (url, params) => {
    const bURL = adminBaseUrl
    const response = await axios.post(`${bURL}/${url}`, params, {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
        }
    })
    return response
}

export const adminPostAPIAuth = async (url, params) => {
    const bURL = adminBaseUrl
    const token = localStorage.getItem(ADMIN_AUTH_ACCESS);
    try {
        const response = await axios.post(`${bURL}/${url}`, params, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            }
        })
        return response
    } catch (error) {
        if (error.response.data.message === 'Invalid token') {
            localStorage.clear();
        }
        throw error;
    }
}


export const adminPostAPIAuthFormData = async (url, params) => {
    const token = localStorage.getItem(ADMIN_AUTH_ACCESS)
    try {
        const response = await axios({
            method: "post",
            url: `${adminBaseUrl}/${url}`,
            data: params,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        })
        return response
    } catch (error) {
        if (error.response.data.message === 'Invalid token') {
            localStorage.clear();
        }
        throw error;
    }
}

export const adminDeleteAPIAuth = async (url) => {
    const token = localStorage.getItem(ADMIN_AUTH_ACCESS);
    try {
        const response = await axios.delete(`${adminBaseUrl}/${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error.response.data.message === 'Invalid token') {
            localStorage.clear();
        }
        return error;
    }
};
