import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { returnEndDate } from '../../../utils/returnEndDate';
import { adminGetAPIAuth } from '../../AdminServices';
import { REPORTS, USER } from '../../AdminConstants/apiEndpoints';
import { errorToaster } from '../../../utils/toaster';
import exportToExcel from '../../../utils/exportExcel';
import { Button } from 'react-bootstrap';
import { dateFormatter } from '../../AdminUtils/Utils';
import Pagination from "../../Admincomponents/pagination/Pagination";
import { useParams } from 'react-router-dom';

const statusArr = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Open",
      value: "open",
    },
    {
      label: "won",
      value: "won",
    },
    {
      label: "lost",
      value: "lost",
    },
    {
      label: "settled ",
      value: "settled ",
    },
];

const BetListTab = () => {
  const [games, setGames] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ascending, setAscending] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const [childrenList,setChildrenList]=useState([]);
  const [searchedUserResult,setSearchedUserResult]=useState([])
  const [inputFocused,setInputFocused]=useState(false)
  const [searchUser,setSearchUser]=useState("")
  const [selectedUser,setSelectUser]=useState({})
  const [order, setOrder] = useState({
    name: false,
    selectionCountryName: false,
    betType: false,
    odds: false,
  });
  const [showOrders, setShowOrders] = useState(true)
  const [dateSort, setDateSort] = useState('')
  const [matchSort, setMatchSort] = useState('')
  const [tab, setTab] = useState('')
  const { userId } = useParams()

  const [filterValues, setFilterValues] = useState({
    gameId: "",
    status: "",
    startDate: "",
    endDate: "",
  });

  const adminData = useSelector((state) => state?.adminData);


  // set today's date as end date
  useEffect(()=> {
    const today = new Date()
    setFilterValues((prev)=>({
      ...prev,
      endDate: returnEndDate(today)
    }))
  },[])



  const getChildrenList=async ()=>{
    try {
      const res = await adminGetAPIAuth(
        USER.children + "/" + adminData?._id + "?perPage=0",
      );
      if (res?.data?.status === 200) {
        setChildrenList(res?.data?.data?.data?.children);
      }
    } catch (error) {
      setChildrenList([]);
    }
  }

  useEffect(()=>{
    if (adminData?._id) {
      getChildrenList()
    }
    //eslint-disable-next-line
  },[adminData])

  const handleChange = (e) => {
    setFilterValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setCurrentPage(1)
    setTotalPages(null)
  };

  useEffect(()=>{
    if (userId && childrenList.length > 0) {
      const filtered=childrenList.filter((item)=> item._id === userId)
      setSearchedUserResult(filtered[0])
    }else{
      setSearchedUserResult([])
      setSelectUser("")
    }
    //eslint-disable-next-line
  },[userId, childrenList])

  
  useEffect(() => {
    if (userId && searchedUserResult?.username) {
        handleGetList();
    }
    //eslint-disable-next-line
  }, [
    filterValues,
    selectedUser,
    filterValues.gameId,
    filterValues.status,
    filterValues.startDate,
    filterValues.endDate,
    currentPage,
    dateSort,
    matchSort,
    tab,
    searchedUserResult?.username
  ]);

  const handleGetList = async (item) => {
    const queryParams = Object.entries(filterValues)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    try {
      const res = await adminGetAPIAuth(
        REPORTS.betAllBetLists + `?page=${currentPage}&perPage=10&username=${searchedUserResult?.username}&dateSort=${dateSort}&matchSort=${matchSort}` + (tab === 'casino' ? `&sportType=${tab}`:'') + (queryParams!==""?`&${queryParams}`:"")
      );
      if (res?.data?.data?.success) {
        setTotalPages(res?.data?.data?.data?.pagination?.totalPages)
        setTableData(res?.data?.data.data?.getUserBets);
      } else {
        errorToaster("Something went wrong in fetching data");
      }
    } catch (error) {
      errorToaster(error?.response?.data?.data?.message);
      setTableData([])
    }
  };

  
  const handleExport = () => {
    exportToExcel(tableData, "bet-list");
  };

  const getGames = async () => {
    try {
      const res = await adminGetAPIAuth(REPORTS.betGames);
      if (res?.data?.data?.success) {
        setGames(res?.data?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getGames();
  }, []);


//   useEffect(()=>{
//     if (searchUser) {
//       const filtered=childrenList.filter((item)=>item.name.toLowerCase().includes(searchUser.toLowerCase()) || item.username.toLowerCase().includes(searchUser.toLowerCase()))
//       setSearchedUserResult(filtered)
//     }else{
//       setSearchedUserResult([])
//       setSelectUser("")
//     }
//     //eslint-disable-next-line
//   },[searchUser])

  const handleInputBlur=()=>{
    setTimeout(()=>{
      setInputFocused(false)
    },500)
  }

  const handleDateSort = ()=> {
    if(dateSort === '-1') {
      setDateSort('1')
      setMatchSort('')
    }else {
      setDateSort('-1')
      setMatchSort('')
    }
  }

  const handleNameSort = ()=> {
    if(matchSort === '-1') {
      setMatchSort('1')
      setDateSort('')
    }else {
      setMatchSort('-1')
      setDateSort('')
    }
  }

  return (
    <>
            <div className="page">
              <div className="page-inner ">
                <header className="page-title-bar row justify-content-between align-items-center">
                  <div className="page-title mb-0 text-nowrap col-md px-0 row"> 
                    <div className='col-12 px-0'>
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                            <button class="nav-link eventBtn active" onClick={() => setTab('')} id="pills-sports-tab" data-bs-toggle="pill" data-bs-target="#pills-sports" type="button" role="tab" aria-controls="pills-sports" aria-selected="true">Sports</button>
                            </li>
                            <li class="nav-item" role="presentation">
                            <button class="nav-link eventBtn" onClick={() => setTab('casino')} id="pills-casino-tab" data-bs-toggle="pill" data-bs-target="#pills-casino" type="button" role="tab" aria-controls="pills-casino" aria-selected="false">Casino</button>
                            </li>
                        </ul>
                    </div>
                  </div>
                  <div className="page-section d-flex align-items-center m-0 flex-row-reverse col-md-auto w-auto">
                    <Button onClick={() => handleExport()} className="">
                      Export
                    </Button>
                  </div>
                </header>
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row align-items-end">
                      {/* <div className="col-md-auto pe-md-0 mb-2 mb-md-0">
                        <div className="adminSearchInput">
                          <input
                            type="text"
                                  placeholder="Search User"
                                  className="form-control adminFilterInp"
                                  onFocus={()=>setInputFocused(true)} 
                                  onBlur={handleInputBlur}
                                  value={searchUser}
                                  onChange={(e)=>setSearchUser(e?.target?.value)}
                          />
                          <div className='overflow-hidden'>
                          {(searchedUserResult?.length && inputFocused) ? <ul className='list-unstyled gap-2 adminsearchDropDown overflow-Y-auto'>
                            {searchedUserResult?.map((item)=>(
                              <li className='userName' onClick={()=>{
                                setSearchUser(item.username)
                                setSelectUser(item)
                                setSearchedUserResult([])
                                }} >
                              {item.username}
                            </li>
                            ))}
                          </ul>:""}
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md px-0">
                        <div className="row justify-content-end mx-md-0">
                          {tab === '' && <div className="col-md-auto col-6 col-sm-3 pe-md-0 mb-2 mb-sm-0">
                            <label htmlFor="gameId" className="form-label">
                              Select Games
                            </label>
                            <select
                              name="gameId"
                              id="gameId"
                              className="form-select adminFilterInp"
                              value={filterValues.gameId}
                              onChange={handleChange}
                            >
                              <option value="">All</option>
                              {games?.map((item) => (
                                <option value={item.gameId} key={item?._id}>
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>}
                          <div className="col-md-auto col-6 col-sm-3 pe-md-0 mb-2 mb-sm-0">
                            <label htmlFor="status" className="form-label">
                              Filter Bets
                            </label>
                            <select
                              name="status"
                              id="status"
                              className="form-select adminFilterInp"
                              value={filterValues.status}
                              onChange={handleChange}
                            >
                              {statusArr.map((item) => (
                                <option value={item.value}>{item.label}</option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-auto col-6 col-sm-3 pe-md-0">
                            <label htmlFor="startDate" className="form-label">
                              From
                            </label>
                            <input
                              type="date"
                              className="form-control adminFilterInp"
                              name="startDate"
                              id="startDate"
                              value={filterValues?.startDate}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-auto col-6 col-sm-3 pe-md-0">
                            <label htmlFor="endDate" className="form-label">
                              To
                            </label>
                            <input
                              type="date"
                              className="form-control adminFilterInp"
                              name="endDate"
                              id="endDate"
                              value={filterValues?.endDate}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-content px-0" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-sports" role="tabpanel" aria-labelledby="pills-sports-tab" tabindex="0">
                      <div className="col-12 px-0">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead className="text-uppercase text-nowrap">
                              <tr>
                                <th>
                                  <div className="d-flex">
                                    username
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex cursor-pointer" onClick={handleDateSort}>
                                    place date
                                    <span className="ms-1">
                                      {dateSort === '-1' ?
                                      <i className="bi bi-arrow-down"></i>
                                      :<i className="bi bi-arrow-up"></i>}
                                    </span>
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex cursor-pointer" onClick={handleNameSort}>
                                    match
                                    <sp className="ms-1">
                                      {matchSort === '-1' ? (
                                        <i className="bi bi-sort-alpha-down"></i>
                                      ) : (
                                        <i className="bi bi-sort-alpha-down-alt"></i>
                                      )}
                                    </sp>
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex">
                                    match date
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex">
                                    market
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex">
                                    bet on
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex">
                                    bet type
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex">
                                    odds
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex">
                                    stake
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex">
                                    returns
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            {
                              tableData.length ?(
                                <tbody>
                                {tableData?.map((item) => (
                                  <tr>
                                    <td className="fw-bold text-decoration-none">{item?.username}</td>
                                    <td className="text-nowrap">
                                      {dateFormatter(
                                        // item?.matchId?.event?.openDate,
                                        item?.createdAt,
                                        true
                                      )}
                                    </td>
                                    <td>{item?.matchId?.event?.name}</td>
                                    <td>
                                      {dateFormatter(
                                        item?.matchId?.event?.openDate,
                                        false
                                      )}
                                    </td>
                                    <td>{item?.marketName}</td>
                                    <td className="text-nowrap">{item?.selectionCountryName}</td>
                                    <td>{item?.betType}</td>
                                    <td>{item?.odds}</td>
                                    <td>{item?.amount?.toFixed(2)}</td>
                                    <td>{item?.potentialAmount ? item?.potentialAmount : item?.potentialWin ? item?.potentialWin : '_'}</td>
                                  </tr>
                                ))}
                              </tbody>
                              ):(
                                  <tr>
                                  <td colSpan={10} className="text-center" style={{fontSize: 16, fontWeight: 500}}>No Bets Available</td>
                                </tr>
                              )
                            }

                          </table>
                        </div>
                      </div>
                      {
                        totalPages > 1 ? 
                          <Pagination
                          totalPages={totalPages}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />: ''
                      }
                    </div>
                    <div class="tab-pane fade" id="pills-casino" role="tabpanel" aria-labelledby="pills-casino-tab" tabindex="0">
                      <div className="col-12 px-0">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead className="text-uppercase text-nowrap">
                              <tr>
                                <th>
                                  <div className="d-flex">
                                    username
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex cursor-pointer" onClick={handleDateSort}>
                                    place date
                                    <span className="ms-1">
                                      {dateSort === '-1' ?
                                      <i className="bi bi-arrow-down"></i>
                                      :<i className="bi bi-arrow-up"></i>}
                                    </span>
                                  </div>
                                </th>
                                {/* <th>
                                  <div className="d-flex cursor-pointer" onClick={handleNameSort}>
                                    match
                                    <sp className="ms-1">
                                      {matchSort === '-1' ? (
                                        <i className="bi bi-sort-alpha-down"></i>
                                      ) : (
                                        <i className="bi bi-sort-alpha-down-alt"></i>
                                      )}
                                    </sp>
                                  </div>
                                </th> */}
                                {/* <th>
                                  <div className="d-flex">
                                    match date
                                  </div>
                                </th> */}
                                {/* <th>
                                  <div className="d-flex">
                                    market
                                  </div>
                                </th> */}
                                {/* <th>
                                  <div className="d-flex">
                                    bet on
                                  </div>
                                </th> */}
                                <th>
                                  <div className="d-flex">
                                    bet type
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex">
                                    odds
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex">
                                    stake
                                  </div>
                                </th>
                                <th>
                                  <div className="d-flex">
                                    returns
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            {
                              tableData.length ?(
                                <tbody>
                                {tableData?.map((item) => (
                                  <tr>
                                    <td className="fw-bold text-decoration-none">{item?.username}</td>
                                    <td className="text-nowrap">
                                      {dateFormatter(
                                        // item?.matchId?.event?.openDate,
                                        item?.createdAt,
                                        true
                                      )}
                                    </td>
                                    {/* <td>{item?.matchId?.event?.name}</td> */}
                                    {/* <td>
                                      {dateFormatter(
                                        item?.matchId?.event?.openDate,
                                        false
                                      )}
                                    </td> */}
                                    {/* <td>{item?.marketName}</td> */}
                                    {/* <td className="text-nowrap">{item?.selectionCountryName}</td> */}
                                    <td>{item?.betType}</td>
                                    <td>{item?.odds}</td>
                                    <td>{item?.amount?.toFixed(2)}</td>
                                    <td>{item?.potentialAmount ? item?.potentialAmount : item?.potentialWin ? item?.potentialWin : '_'}</td>
                                  </tr>
                                ))}
                              </tbody>
                              ):(
                                  <tr>
                                  <td colSpan={10} className="text-center" style={{fontSize: 16, fontWeight: 500}}>No Bets Available</td>
                                </tr>
                              )
                            }
                          </table>
                        </div>
                      </div>
                      {
                        tableData.length && totalPages > 1 ? 
                          <Pagination
                          totalPages={totalPages}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />: ''
                      }
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
    </>
  )
}

export default BetListTab
