import React from 'react'
import { useState } from 'react'
import PromotionCard from './PromotionCard'
import { getAPI } from './../../service/apiInstance'
import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { useAuth } from '../../hooks/useAuth';
import { getAPIAuth } from './../../service/apiInstance'

const PromotionTab = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1);
  const [jackpotdata, setJackpotData] = useState([])
  const { token } = useAuth();

  console.log("jackpotdatajackpotdata", jackpotdata);

  //   console.log("jackpotdatajackpotdata ", jackpotdata);

  const getLatestPromotions = async () => {
    setLoading(true)
    try {
      const res = await getAPI(`get-promotion-data?isArchived=false&page=1&limit=6`)
      // setData(res?.data?.data)
      if (res?.data?.data.success) {
        setData(res?.data?.data.data)
        setPageCount(res.data.pageCount)
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  const getMoraData = async () => {
    try {
      const res = await getAPI(`get-promotion-data?isArchived=false&page=${page}&limit=6`)
      // setData(res?.data?.data)
      if (res?.data?.data.success) {
        setData(pre => ([...pre, ...res?.data?.data.data]))
      } else {
        setData(data)
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getLatestPromotions()
  }, [])

  useEffect(() => {
    if (page >= 2) {
      getMoraData()
    }
  }, [page]);

  const getJackpotGetAll = async () => {
    if (token) {
      try {
        const res = await getAPIAuth("user/getAllJackpots", token);
        console.log("jackpotres", res);
        if (res?.data?.data?.success) {
          setJackpotData(res?.data?.data?.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    getJackpotGetAll();
  }, [token]);

  const FilteredData = jackpotdata.filter((item) => item.status !== "closed");

  // console.log("FilteredDataFilteredData", FilteredData);


  return (
    <div className="row g-3">
      {!loading ?
        jackpotdata?.length > 0 ?
          FilteredData?.map((item) => (
            <PromotionCard jackpotdata={item} getJackpotGetAll={getJackpotGetAll} />
          ))

          // <PromotionCard jackpotdata={jackpotdata} getJackpotGetAll={getJackpotGetAll} />
          : (
            <div className="w-100 h-100 text-light d-flex justify-content-center align-items-center">
              No data available
            </div>
          )
        : (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="light" size="lg" />
          </div>
        )
      }
      {
        page >= pageCount ? (
          <></>
        ) : (
          <div className="col-12 mt-5 text-center">
            <button onClick={() => setPage(pre => pre + 1)} className="loadMoreBtn">LOAD MORE</button>
          </div>
        )
      }

    </div>
  )
}

export default PromotionTab