import React, { useEffect, useState } from "react";
import { adminPostAPIAuth } from "../../AdminServices";
import { errorToaster, succesToaster } from "../../../utils/toaster";

const UpdateUpiDetails = ({ item, getPaymentDetails}) => {
    const initialValue = { type: item.type, displayName: item.displayName, upiDetails: item.upiDetails, status: item.status }
    const [uTableData, setUTableData] = useState(initialValue)
    const [validationError, setValidationError] = useState(false)

    useEffect(() =>{
        setUTableData({ type: item.type, displayName: item.displayName, upiDetails: item.upiDetails, status: item.status })
    },[item])

    const handleChange = (e) => {
        const { name, value } = e.target
        setUTableData({ ...uTableData, [name]: value })
    }

    const updatePaymentDetails = async(e) => {
      e.preventDefault()
      if (uTableData.displayName === '' || uTableData.upiDetails === '' || uTableData.status === '') {
        setValidationError(true)
      } else {
        try {
            const body = {
                typename : "userPaymentDetails",
                flag : uTableData.type,
                upiDetails : uTableData.upiDetails,
                displayName : uTableData.displayName,
                id : item._id,
                status : uTableData.status
            }
            const res = await adminPostAPIAuth('user/updateUserPaymentDetails', body)
            if (res?.data?.data?.message) {
              setUTableData(res?.data?.data?.data)
              getPaymentDetails()
              succesToaster('Upi Details Successfully Updated')
              setValidationError(false)
            }
        } catch (error) {
          errorToaster(error?.response?.data?.message)
        }
      }
    }
    
  return (
    <>
      <form onSubmit={updatePaymentDetails}>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Type<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            name='type'
            value={uTableData.type}
            onChange={handleChange}
          />
        </div>
        <h6>Editable Details</h6>
        <div className="mt-4 d-flex gap-2">
          <div>
            <label htmlFor="exampleInputEmail1" className="form-label">
              Display Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name='displayName'
              value={uTableData.displayName}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="exampleInputEmail1" className="form-label">
              UPI Details<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name='upiDetails'
              value={uTableData.upiDetails}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mt-4 d-flex gap-2">
          <div>
            <label htmlFor="exampleInputEmail1" className="form-label">
              Status<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              name='status'
              value={uTableData.status}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={`mt-2 ${validationError === true ? (uTableData.displayName === '' || uTableData.upiDetails === '' || uTableData.status === '') ? 'd-block' : 'd-none' : 'd-none' }`}>
              <span style={{color: 'red'}}>Fill all the fields</span>
        </div>
        <button 
          type="submit" 
          className="btn btn-primary mt-4" 
          data-bs-dismiss={`${(uTableData.displayName === '' || uTableData.upiDetails === '' || uTableData.status === '') ? '' : "offcanvas"}`}
          aria-label={`${(uTableData.displayName === '' || uTableData.upiDetails === '' || uTableData.status === '') ? '' : "Close"}`}
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default UpdateUpiDetails;
