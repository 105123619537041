import React, { useEffect, useRef, useState } from 'react'
import { postAPIAuthFormData } from '../../service/apiInstance'
import { errorToaster, succesToaster } from '../../utils/toaster'
import { WALLET_MODAL_TABS } from '../../constants'
import uploadss from "../../assets/img/plus.png";

const UpdateWithdrawDetails = ({setWalletModalObject, userWithdrawalDetail}) => {
  const initialValue = {
    accountHolderName: "",
    ifscCode: "",
    bankName: "",
    accountNo: "",
    upi: "",
}
const [accountDetails, setAccountDetails] = useState(initialValue)
const [validationError, setValidationError] = useState(false)
const [ifscValidation, setIfscValidation] = useState(false)
const [accountNoValidation, setAccountNoValidation] = useState(false)
const [withdrawLoading, setWithdrawLoading] = useState(false)
const [isUpi, setIsUpi] = useState(false)
const [image, setImage] = useState(uploadss);
const [imageUrl, setImgUrl] = useState("");
const qrCodeRef = useRef(null);
const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
const accountNumberRegex = /^[0-9]{9,18}$/;


// prefilled input values
    useEffect(()=> {
        if(userWithdrawalDetail) {  
            setAccountDetails((prev)=>({
            ...prev,
            accountHolderName: userWithdrawalDetail?.accountHolderName ? userWithdrawalDetail?.accountHolderName : '',
            ifscCode: userWithdrawalDetail?.ifscCode ? userWithdrawalDetail?.ifscCode : '',
            bankName: userWithdrawalDetail?.bankName ? userWithdrawalDetail?.bankName : '',
            accountNo: userWithdrawalDetail?.accountNumber ? userWithdrawalDetail?.accountNumber : '',
            upi: userWithdrawalDetail?.upi ? userWithdrawalDetail?.upi : '',
        }))

        if(userWithdrawalDetail?.upi) {
            setIsUpi(true)
        }
        }
    }, [userWithdrawalDetail])




    // onChnage validation
const withdrawChange = (e) => {
  const { name, value } = e.target
  setAccountDetails({ ...accountDetails, [name]: value })
  setValidationError(true)

  if(e.target.name === "ifscCode") {
      if (!ifscCodeRegex.test(e.target.value)) {
      setIfscValidation(true);
      } else {
          setIfscValidation(false)
      } 
  }
  if(e.target.name === "accountNo") {
        // Use a regular expression to allow only numeric characters
        const numericValue = value.replace(/[^0-9]/g, '');
        setAccountDetails({ ...accountDetails, accountNo: numericValue })
        if (!accountNumberRegex.test(e.target.value)) {
        setAccountNoValidation(true);
        } else {
        setAccountNoValidation(false)
        } 
  }
}

const handleWithdraw = async (e) => {
    e.preventDefault();
    const { accountHolderName, ifscCode, bankName, accountNo, upi } = accountDetails
    if(isUpi) {
        if(accountHolderName?.length <= 0 || upi?.length <= 0) {
            setValidationError(true)
        } else {
            setValidationError(false)
            handleApi()
        }
    } else {
        if (accountHolderName?.length <= 0 || ifscCode?.length <= 0 || accountNo?.length <= 0 || bankName?.length <= 0) {
            setValidationError(true)
        } else if (!ifscCodeRegex.test(ifscCode)) {
            setIfscValidation(true);
            setAccountNoValidation(false)
        } else if (!accountNumberRegex.test(accountNo)) {
            setAccountNoValidation(true)
            setIfscValidation(false)
        } else {
            setValidationError(false)
            handleApi()
        }
    }
}


const handleApi = async ()=> {
    setWithdrawLoading(true)
    try {
        // const body = isUpi ? { upi: isUpi ? accountDetails.upi : '',accountHolderName: accountDetails.accountHolderName, } : {
        //     accountHolderName: accountDetails.accountHolderName,
        //     ifscCode: isUpi ? '' : accountDetails.ifscCode,
        //     bankName: isUpi ? '' : accountDetails.bankName,
        //     accountNumber: isUpi ? '' : accountDetails.accountNo,
        //     // saveDetails : isEdit ? 1: 0
        // }

        //-------when api is ready -------------------------------------->>>>>
        const formData = new FormData();
        formData.append("accountHolderName",accountDetails.accountHolderName);

        if(isUpi){
            formData.append("typename", "withdrawQr");
            formData.append("upi", accountDetails.upi);
            formData.append("image", image);
            console.log({ image })

        }else{
            formData.append("ifscCode", accountDetails.ifscCode);
            formData.append("bankName", accountDetails.bankName);
            formData.append("accountNumber", accountDetails.accountNo);
        }


        const res = await postAPIAuthFormData("user/saveUserWithdrawalDetails", formData)
        if (res?.data?.data?.success) {
            succesToaster('Withdraw details saved Successfully')
            setIfscValidation(false)
            setAccountNoValidation(false)
            setValidationError(false)
            setWalletModalObject((prev) => ({
                ...prev,
                selectedTab: WALLET_MODAL_TABS?.WITHDRAW
            }))
        } else {
            errorToaster(res?.data?.data?.message)
        }
    } catch (error) {
        
    } finally {
        setWithdrawLoading(false)
    }
}

const onChangeFile = (e) => {
    const fileObj = e.target.files && e.target.files[0];
    if (
      fileObj &&
      (fileObj.type === "image/jpeg" ||
        fileObj.type === "image/jpg" ||
        fileObj.type === "image/png")
    ) {
      setImage(fileObj);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgUrl(reader.result);
      };
      reader.readAsDataURL(fileObj);
    } else {
      errorToaster("Select image in jpeg, jpg, png");
    }
  };

  return (
    <>
    <div className="row">
      <div className='col-12'>
          <form>
              <div className="row g-3">
                  <div className="col-sm-12 inputdata">
                      <label htmlFor="holderNamE1" className="inputLabel">
                          ACCOUNT HOLDER NAME<span>*</span>
                      </label>
                      <input
                          type="text"
                          id="holderNamE1"
                          className="form-control inputlogin border-0 shadow-none"
                          placeholder="Enter Account Holder Name"
                          name="accountHolderName"
                          value={accountDetails?.accountHolderName}
                          onChange={withdrawChange}
                      />
                      <span className={`text-danger ${validationError === true ? accountDetails?.accountHolderName?.length <= 0 ? 'd-block' : 'd-none' : 'd-none'}`}>
                          Required !
                      </span>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-auto">
                        <div className={`accountCheckbox ${!isUpi ? 'active' : ''}`}
                          onClick={()=>setIsUpi(false)}>
                          <div className="checkDiv"></div>
                          <div className="checkTxt">Bank</div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className={`accountCheckbox ${isUpi ? 'active' : ''}`}
                          onClick={()=>setIsUpi(true)}>
                          <div className="checkDiv"></div>
                          <div className="checkTxt">upi</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    !isUpi ? <>
                    <div className="col-sm-6 inputdata">
                        <label htmlFor="holderNamE2" className="inputLabel">
                            IFSC CODE<span>*</span>
                        </label>
                        <input
                            type="text"
                            id="holderNamE2"
                            className="form-control inputlogin border-0 shadow-none"
                            placeholder="Enter IFSC Code"
                            name="ifscCode"
                            value={accountDetails?.ifscCode}
                            onChange={withdrawChange}
                        />
                        <span className={`text-danger ${validationError === true ? accountDetails?.ifscCode?.length <= 0 ? 'd-block' : 'd-none' : 'd-none'}`}>
                            Required !
                        </span>
                        <span className={`text-danger ${ifscValidation === true && accountDetails?.ifscCode?.length > 0 ? 'd-block' : 'd-none'}`}>
                            Invalid IFSC Code
                        </span>
                    </div>
                    <div className="col-sm-6 inputdata">
                        <label htmlFor="holderName3" className="inputLabel">
                            BANK NAME <span>*</span>
                        </label>
                        <input
                            type="text"
                            id="holderName3"
                            className="form-control inputlogin border-0 shadow-none"
                            placeholder="Enter Bank Name"
                            name="bankName"
                            value={accountDetails?.bankName}
                            onChange={withdrawChange}
                        />
                        <span className={`text-danger ${validationError === true ? accountDetails?.bankName?.length <= 0 ? 'd-block' : 'd-none' : 'd-none'}`}>
                            Required !
                        </span>
                    </div>
                    <div className="col-sm-6 inputdata">
                        <label htmlFor="holderName4" className="inputLabel">
                            Account Number <span>*</span>
                        </label>
                        <input
                            type="text"
                            id="holderName4"
                            className="form-control inputlogin border-0 shadow-none"
                            placeholder="Enter Account Number"
                            name="accountNo"
                            value={accountDetails.accountNo}
                            onChange={withdrawChange}
                        />
                        <span className={`text-danger ${validationError === true ? accountDetails?.accountNo?.length <= 0 ? 'd-block' : 'd-none' : 'd-none'}`}>
                            Required !
                        </span>
                        <span className={`text-danger ${accountNoValidation === true && accountDetails?.accountNo?.length > 0 ? 'd-block' : 'd-none'}`}>
                            Invalid Account Number
                        </span>
                    </div>
                    </> : <>
                  <div className="col-sm-6 inputdata">
                      <label htmlFor="holderName5" className="inputLabel">
                          PhonePay / GooglePay / Paytm UPI <span>*</span>
                      </label>
                      <input
                          type="text"
                          id="holderName5"
                          className="form-control inputlogin border-0 shadow-none"
                          placeholder="Enter UPI Details"
                          name="upi"
                          value={accountDetails?.upi}
                          onChange={withdrawChange}
                      />
                      <span className={`text-danger ${validationError === true ? accountDetails?.upi?.length <= 0 ? 'd-block' : 'd-none' : 'd-none'}`}>
                          Required !
                      </span>
                  </div>

                    <div className="col-12 position-relative overflow-hidden">
              <div className="position-relative">
                <label
                  className="FantasyCard w-100 position-relative"
                  htmlFor="screenupload"
                >
                  <span className=" fantasyImg text-center d-flex align-items-center justify-content-center mx-auto">
                    <img
                      src={typeof image === "object" ? imageUrl : image}
                      className={`img-fluid w-100 h-100 object-fit-contain ${
                        imageUrl
                          ? "position-absolute top-50 start-50 translate-middle uploadedImg"
                          : ""
                      }`}
                      alt="upload screen suit"
                    />
                  </span>
                  <span
                    className={`fantasySubheading mt-2 text-center w-100 align-items-center justify-content-center ${
                      imageUrl ? "d-none" : "d-flex"
                    }`}
                  >
                    Click here to upload your UPI QR code
                  </span>
                  {/* <span className={`fantasySubheading mt-2 text-center w-100 align-items-center justify-content-center ${imageUrl ? 'd-flex' : 'd-none'}`}>
                    Click here to upload to payment screenshot
                  </span> */}
                </label>
              </div>

              <input
                className="form-control position-absolute h-100 w-100 top-0 start-0"
                style={{ opacity: "0.01" }}
                type="file"
                id="screenupload"
                onChange={onChangeFile}
                ref={qrCodeRef}
              ></input>
              {typeof image === "object"?<div className="position-absolute" style={{ right: 16, top: 10 }}>
                <button
                  className="border-0 bg-transparent shadow-none"
                  onClick={() => {
                    setImage(uploadss);
                    setImgUrl("");
                    if (qrCodeRef.current) {
                        qrCodeRef.current.value = "";
                    }
                  }}
                >
                  <i class="bi bi-x-lg text-danger"></i>
                </button>
              </div>:null}
            </div>

                    </>
                  }
                  <div className="col-12 pt-3">
                      <button
                          onClick={handleWithdraw}
                          className="customBtn w-100 shadow-none changeGmailBtn text-nowrap py-2 d-flex align-items-center justify-content-center "
                      >
                          Submit
                      </button>
                  </div>
              </div>
          </form>
      </div>
    </div>
    </>
  )
}

export default UpdateWithdrawDetails