import React, { useEffect, useState } from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getAPI, getAPIAuth, postAPIAuth, casinoPostAPIAuth } from "../../service/apiInstance";
import { useAuth } from "../../hooks/useAuth";
import { useSignUpModal } from "../../hooks/useSignUpModal";
import { errorToaster } from "../../utils/toaster";
import PlayingGame from "./PlayingGame";
import GameDesc from "./gameDesc";
import Sharing from "./sharing";
import { useSelector } from "react-redux";
import RelatedGames from "./RelatedGames";
import MoreFrom from "./MoreFrom";
import ProvidersListComp from "./ProvidersListComp";
import { useAuthModal } from "../../hooks/useAuthModal";
import { MOBILE_SCREEN_WIDTH, TOKEN_NAME } from "../../constants";
// import { isMobile } from "react-device-detect";
import { useMediaQuery } from 'react-responsive'
import { toast } from 'react-toastify';


const PlaySlot = () => {
  const { userDetails } = useSelector(state => state)
  const { gameId } = useParams();
  // const [getQuery,setQuery] = useSearchParams()
  const [isLoading, setIsLoading] = useState(true)
  const [game, setGame] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState()
  const { token } = useAuth()
  const location = useLocation()
  const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
  const ipAddress = useSelector(state => state.ipAddress)
  const [searchParams] = useSearchParams()
  const providerName = searchParams.get('provider')


  const queryPrams = new URLSearchParams(window.location.search)
  const infoPram = queryPrams.get("game")

  console.log("infoPramgame", infoPram)


  // Function for generating url
  const generateUrl = async () => {
    try {
      if (token) {
        // const resp = await getAPIAuth(`games/url-generate?game_uuid=${gameId}`)
        // const payload = {
        //   gameId: gameId,
        //   providerName: providerName,
        //   platformId: isMobile ? "mobile" : "desktop",
        //   clientIp: ipAddress
        // }
        // const resp = await postAPIAuth(`casino/urlGenerate?gameId=${gameId}`, payload, token)
        // console.log('resp', resp)
        // if (resp?.data?.data?.success) {
        //   // handleNewLink(resp.data.data.url)
        //   setGame(resp?.data?.data?.data)
        // } else {
        //   setGame(false)
        // }
        const obj = {
          "gameId": infoPram
        }

        try {
          const res = await postAPIAuth(`casinoApi/authentication`, obj, token);
          if (res?.data?.data?.success) {
            setGame(res.data.data.data);
          }
        } catch (error) {
          console.log(error.response.data.data.message);
          toast(error.response.data.data.message)

        }
      }
    } catch (error) {
      errorToaster(error.message)
    } finally {
      // setIsLoading(false)
    }
  }

  // const getDebitIframeData = async () => {
  //   try {

  //     if (token) {

  //       const obj = {
  //         "partnerKey": "nXJ4rDcd46N7s9ysLYlzeXCpDS9rj9yOoTbYdrLYLidCZ0n13iCmB4DH9j3eG7SFhqjHDVX5Im8=",
  //         "user": {
  //           "id": "66139dd63a84b1e307f22f36",
  //           "currency": "INR"
  //         },
  //         "gameData": {
  //           "providerCode": "SN",
  //           "providerTransactionId": "25406",
  //           "gameCode": "D3TP",
  //           "description": "bet",
  //           "providerRoundId": "2449691"
  //         },
  //         "transactionData": {
  //           "id": "250",
  //           "amount": 500.00,
  //           "referenceId": ""
  //         },
  //         "timestamp": "1628764434278"
  //       }

  //       try {
  //         const res = await casinoPostAPIAuth(`casinoApi/debit`, obj, token);
  //         if (res?.data?.data?.success) {
  //           console.log("getDebitIframeData", res);
  //         }
  //       } catch (error) {
  //         // toast(`${error.response.data.data.message}`);
  //         console.log("getDebitIframeDataerror", error);

  //       }

  //     }

  //   } catch (error) {

  //   }

  // }

  // const getCridetIframeData = async () => {
  //   try {

  //     if (token) {

  //       const obj = {
  //         "partnerKey": "nXJ4rDcd46N7s9ysLYlzeXCpDS9rj9yOoTbYdrLYLidCZ0n13iCmB4DH9j3eG7SFhqjHDVX5Im8=",
  //         "user": {
  //           "id": "66139dd63a84b1e307f22f36",
  //           "currency": "INR"
  //         },
  //         "gameData": {
  //           "providerCode": "SN",
  //           "providerTransactionId": "250",
  //           "gameCode": "D3TP",
  //           "description": "win",
  //           "providerRoundId": "2449691"
  //         },
  //         "transactionData": {
  //           "id": "214",
  //           "amount": 715.00,
  //           "referenceId": ""
  //         },
  //         "timestamp": "1628764919503"
  //       }

  //       try {
  //         const res = await casinoPostAPIAuth(`casinoApi/Credit`, obj, token);
  //         if (res?.data?.data?.success) {
  //           console.log("getCridetIframeData", res);
  //         }
  //       } catch (error) {

  //         console.log("getCridetIframeDataerror", error);
  //         // toast(`${error.response.data.data.message}`);
  //       }

  //     }

  //   } catch (error) {

  //   }

  // }

  // Function for getting gamedata
  const getGameData = async () => {
    let params = {
      userId: userDetails?.id,
      gameUuid: gameId,
      is_mobile: isMobile ? "true" : "false"
    }
    const result = await postAPIAuth(`crypto/get-games`, params)
    if (result.data.success) {
      setData(result.data.data[0])
    }
  }

  useEffect(() => {
    if (token) {
      // getGameData()
      generateUrl();
      // getDebitIframeData();
      // getCridetIframeData();
    }
  }, [location?.pathname, token])

  return (
    <main className="main vh-100" id="main">
      <div className="mainContaint">
        <div className=" holdwinSection homepage">
          <div className="gamesPage pt-3 pt-lg-4">
            {/* ***** hold win hero section start */}
            <PlayingGame getGameData={getGameData} data={data} game={game} isLoading={isLoading} providerName={providerName} setIsOpen={setIsOpen} />
            {/* ***** hold win hero section end */}
            {/* ***** rated games section start */}
            {/* <RelatedGames data={data} /> */}
            {/* ***** rated games section end */}
            {/* *****MORE FROM HACKSAW section start */}
            {/* <MoreFrom data={data} /> */}
            {/* *****MORE FROM HACKSAW section end */}
            {/* *****PROVIDERS section start */}
            {/* <ProvidersListComp data={data} /> */}
            {/* *****PROVIDERS section end */}
          </div>
        </div>
      </div>
      <AppFooter />
      <Sharing isOpen={isOpen} setIsOpen={setIsOpen} />
    </main>

  )
}
export default PlaySlot;
