import React, { useEffect, useState } from 'react'
import AppFooter from '../../../components/AppFooter/AppFooter'
import { getAPIAuth } from '../../../service/apiInstance'
import moment from 'moment/moment'
import DarkPagination from '../../../components/darkPagination/DarkPagination'
import { useAuth } from '../../../hooks/useAuth'

const transactionArr = [
  {
    value: '',
    label: "All",
  },
  {
    value: 'credit',
    label: "Deposit",
  },
  {
    value: 'debit',
    label: "Withdraw",
  },
  // {
  //   value:'settlements-deposit',
  //   label:"Settlements Deposit",
  // },
  // {
  //   value:'settlements-withdraw',
  //   label:"Settlements Withdraw",
  // },
  // {
  //   value:'bet-settlements',
  //   label:"Bet Settlements",
  // },
  // {
  //   value:'rollback',
  //   label:"Rollback",
  // },
  // {
  //   value:'Voided',
  //   label:"voided",
  // },
]

const MyAccountCasinoStatementPage = () => {
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(true)
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionType, setTransactionType] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const { token } = useAuth()
  const [itemsPerPage, setItemPerPage] = useState();
  const [totallprofit, setTotallProfits] = useState(0)
  const [totallloss, setTotallLoss] = useState(0)


  useEffect(() => {
    // Calculate total profit and loss when the component mounts or data changes
    let profit = 0;
    let loss = 0;

    data.forEach((item) => {
      if (item.type === 'credit') {
        profit += item.points;
      } else if (item.type === 'debit') {
        loss += item.points;
      }
    });

    // Update state with the calculated values
    setTotallProfits(profit);
    setTotallLoss(loss);
  }, [data]);

  // console.log("totallprofit", totallprofit);

  const getMyStatement = async () => {
    setLoader(true)
    try {
      // const res = await getAPIAuth(`user/myaccountstatement?page=${currentPage}&perPage=${itemsPerPage}&type=${transactionType}&startDate=${startDate}&endDate=${endDate}`, token)

      const res = await getAPIAuth(`casinoApi/casinoAccountStatement?page=${currentPage}&perPage=${itemsPerPage}&type=${transactionType}`, token)
      if (res?.data?.success) {
        console.log("resss", res);
        setTotalPages(res?.data?.data?.[0]?.pagination?.totalPages);
        setItemPerPage(res?.data?.data?.[0]?.pagination?.perPage)
        setData(res?.data?.data?.[0]?.fetchDetails)
      }
    } catch (error) {
      setData([])
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getMyStatement()
  }, [currentPage, transactionType, startDate, endDate])

  useEffect(() => {
    setCurrentPage(1);
  }, [transactionType])

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage tradingPages">
            {/* start here */}
            <div className="row mx-0 mt-2">
              <div className="col-12">
                <div className="row gap-3">
                  <div className="col-12 px-0">
                    <div className="row g-3 align-items-center ">
                      <div className="col-md">
                        <div className="tradingSecHead">My Account Statement</div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-6">
                        <div className="reportsDropdownTitle">Transaction Type</div>
                        <div className="dropdown reportsDropdown">
                          <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {transactionType === 'credit' ? "Deposit" : transactionType === "debit" ? 'Withdraw' : 'All'}
                          </button>
                          <ul className="dropdown-menu dropdownMenu w-100">
                            {
                              transactionArr?.map((item) => (
                                <li className={`menuLinkDrop ${transactionType === item?.value ? 'active' : ''}`}
                                  onClick={() => setTransactionType(item?.value)}
                                >{item?.label}</li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 px-0">
                    <div className="row pb-1 pt-1 g-3 align-items-center ">
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Total Credits</div>
                              <div class="adminTopCardSubtitle">{totallprofit}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Total Debit</div>
                              <div class="adminTopCardSubtitle">{totallloss}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Total Profit</div>
                              <div class="adminTopCardSubtitle">{totallprofit}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle">Total Loss</div>
                              <div class="adminTopCardSubtitle">{totallloss}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-3 col-sm-6 col-12">
                        <div class="adminTopCard p-3">
                          <div class="d-flex align-items-center">
                            <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                              <img src="assets/img/present.svg" alt="" />
                            </div>
                            <div class="">
                              <div class="adminTopCardTitle"> Total Exploser</div>
                              <div class="adminTopCardSubtitle">347890</div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 px-0 dambleTable sectionInner reportsTable  tableCard tab-content tab-pane table-responsive mb-0 mt-4">
                <table className="table align-middle text-white text-nowrap mb0">
                  <thead>
                    <tr>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          SR.NO.
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Bet DATE
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Previous points
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Total points
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          CREDIT/DEBIT
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          MY PROFITS
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          TRANSACTION
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !loader ?
                        data?.length > 0 ?
                          data?.map((item, index) => (
                            <tr>
                              <td>{startIndex + index}</td>
                              <td className='text-uppercase'>{moment(item?.casinoBetData?.createdAt).format('MMM Do YYYY, hh:mm a')}</td>
                              <td>{item?.previousPoints?.toFixed(1)}</td>
                              <td>{item?.totalPoints}</td>
                              <td>{item?.type}</td>
                              <td className={item?.type === "debit" ? "admin-forms-error-text" : "admin-forms-success-text"} >{item?.points?.toFixed(2)}</td>
                              <td>{item?.desc}</td>
                            </tr>
                          ))
                          : <tr>
                            <td colSpan={7} className='text-center'>No Data</td>
                          </tr>
                        : 'loading'
                    }
                  </tbody>
                </table>
              </div>
              {
                totalPages > 1 ?
                  <DarkPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                  : ''
              }
            </div>

            {/* end here */}
          </div>
        </div>

        {/*  **** Footer section *** */}
        <AppFooter />
      </main>
    </>
  )
}

export default MyAccountCasinoStatementPage;
