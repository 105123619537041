import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { GoogleLogin } from "../../socialMedia/GoogleLogin";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import * as Yup from "yup";
import { errorToaster } from "../../../utils/toaster";
// import MetaMaskModal from "../../socialMedia/MetamaskModal";
import { useAuthModal } from "../../../hooks/useAuthModal";
import TelegramComp from "../../socialMedia/TelegramLoginButton";
import TelegramInputModal from "../../socialMedia/TelegramInputModal";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ROUTES_CONST } from "../../../constants/routeConstant";

const validationSchema = Yup.object({
    username: Yup.string().required("This field is required"),
    fullName: Yup.string().required("This field is required"),
    email: Yup.string()
        .required("This field is required")
        .matches(
            /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
            "Invalid email"
        ),
    // agreement: Yup.boolean().required(),
});

const EmailField = ({
    setPasswordView,
    setActive,
    setUsername,
    setChecks,
    checks,
    Active,
    setEmail,
    setReferral,
    setIsReferral,
    setMobile,
    setFullName
}) => {
    const [isMetamask, setIsMetamask] = useState(false);
    const { authModalObject } = useAuthModal();
    const [isTelegram, setIsTelegram] = useState(false);
    const [userObj, setUserObj] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (values) => {
        if (!checks.terms) {
            errorToaster("Please accept the User Agreement");
            setIsLoading(false);
            return;
        }
        setEmail(values.email);
        setUsername(values.username);
        setFullName(values.fullName)
        setMobile(values.mobile)
        setReferral(values.refercode)
        setPasswordView(true);
        setActive(false);
        setIsLoading(false);
    };

    useEffect(() => {
        if (!Active) {
            setIsLoading(false);
        }
    }, [Active]);

    return (
        <>
            <Formik
                initialValues={{
                    email: "",
                    username: "",
                    fullName: "",
                    mobile: ''
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setIsLoading(true);
                    onSubmit(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                }) => (
                    <form>
                        <div className="row g-3">
                            <div className="col-sm-6 inputdata">
                                <label htmlFor="text" className="inputLabel">
                                    FULL NAME
                                    <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    id="text"
                                    className="form-control inputlogin border-0 shadow-none"
                                    placeholder="full name"
                                    value={values.fullName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="fullName"
                                />
                                <ErrorMessage>
                                    {errors.fullName && touched.fullName && errors.fullName}
                                </ErrorMessage>
                            </div>
                            <div className="col-sm-6 inputdata">
                                <label htmlFor="text" className="inputLabel">
                                    USERNAME
                                    <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    id="text"
                                    className="form-control inputlogin border-0 shadow-none"
                                    placeholder="Username"
                                    value={values.username}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="username"
                                />
                                <ErrorMessage>
                                    {errors.username && touched.username && errors.username}
                                </ErrorMessage>
                            </div>
                            <div className="col-12 inputdata">
                                {/* EMAIL */}
                                <label htmlFor="text" className="inputLabel">
                                    EMAIL
                                    <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    id="text"
                                    className="form-control inputlogin border-0 shadow-none"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    value={values.email}
                                    name="email"

                                />
                                <ErrorMessage>
                                    {errors.email &&
                                        touched.email &&
                                        errors.email
                                    }
                                </ErrorMessage>
                            </div>
                            <div className="col-12 inputdata">
                                {/* MOBILE NUMBER */}
                                <label htmlFor="text" className="inputLabel">
                                    MOBILE NUMBER
                                    <span>*</span>
                                </label>

                                <input
                                    type="text"
                                    id="text"
                                    className="form-control inputlogin border-0 shadow-none"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        const onlyDigits = e.target.value.replace(/[^\d]/g, '');
                                        const limitedValue = onlyDigits.slice(0, 12);
                                        handleChange({
                                            target: {
                                                name: 'mobile',
                                                value: limitedValue,
                                            },
                                        });
                                    }}
                                    placeholder="Mobile Number"
                                    value={values.mobile}
                                    name="mobile"
                                />
                                <ErrorMessage>
                                    {errors.mobile &&
                                        touched.mobile &&
                                        errors.mobile
                                    }
                                </ErrorMessage>
                            </div>
                            <div className="col-12 inputdata">
                                {/* MOBILE NUMBER */}
                                <label htmlFor="text" className="inputLabel">
                                    REFERRAL CODE
                                </label>
                                <input
                                    type="text"
                                    id="text"
                                    className="form-control inputlogin border-0 shadow-none"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Referral Code"
                                    value={values.refercode}
                                    name="refercode"
                                />
                            </div>
                            {/* checks */}
                            <div className="col-12 inputdata forgotmain">
                                <div className="form-check ">
                                    <input
                                        className="form-check-input shadow-none"
                                        type="checkbox"
                                        id="flexCheckC"
                                        value={checks.terms}
                                        name="terms"
                                        onChange={(e) =>
                                            setChecks((pre) => ({
                                                ...pre,
                                                [e.target.name]: e.target.checked,
                                            }))
                                        }
                                    />

                                    <label
                                        className="form-check-label forgotPassword"
                                        htmlFor="flexCheckC"
                                    >
                                        {/* I agree to the User Agreement & confirm I am at least 18
                                        years old */}
                                        I agree with <Link className="agreeAnchor" to={ROUTES_CONST?.SPORTS_POLICY}>Privacy Policy</Link> and <Link className="agreeAnchor" to={ROUTES_CONST?.TERMS_AND_CONDITIONS}>Terms of Use</Link> , Gambling isn't forbidden by my local authorities and I'm at least 18 years old.
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* footer section*/}
                        <div className="row mt-4 gap-4">
                            {/* button section*/}
                            <div className="col-12 mx-auto">
                                <span className="playBtn position-relative d-flex align-items-center justify-content-center cursor-pointer">
                                    <button
                                        type="submit"
                                        id="recaptcha2"
                                        className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"
                                        onClick={handleSubmit}
                                    >
                                        {/* Play now! */}
                                        {isLoading ? (
                                            <Spinner size="sm" color="dark" />
                                        ) : (
                                            "Continue"
                                        )}
                                    </button>
                                </span>
                            </div>

                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default EmailField;
