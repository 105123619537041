import React from 'react'
import TeamCard from '../../../fantasyComponents/myTeamCard/TeamCard'
import { useSelector } from 'react-redux'
import { ROUTES_CONST } from '../../../../constants/routeConstant'
import { Spinner } from 'react-bootstrap'

const MyTeam = ({ id, match, contests, teamLoader }) => {
  const myTeam = useSelector(state => state.myTeamData)

  return (
    <>
      <div className="col-12 mt-3">
        <div className="row ">
          {teamLoader ? (
            <div className='w-100 h-100 d-flex justify-content-center'>
              <Spinner color='white' size='sm' />
            </div>
          ) : (
            myTeam?.length === 0 ? (
              <div className='w-100 h-100 d-flex justify-content-center text-light' >
                No Data Available
              </div>
            ) : (
              myTeam?.map((item) => (
                <TeamCard
                  Route1={`${ROUTES_CONST?.CREATE_TEAM}/${id}`}
                  team={item}
                  mainRoute={ROUTES_CONST.VIEW_TEAM}
                  mainParams={{
                    state: {
                      myteam: true,
                      data: match,
                      contest: contests?.length ? contests[0].contest[0] : '',
                      edit: item.teamnumber,
                      playerData: item,
                    }
                  }}
                  params1={{
                    capVicePlr: {
                      captain: item.captin_name,
                      viceCaptain: item.viceCaptain_name,
                    },
                    players: item,
                    myteam: true,
                    contest: contests?.length ? contests[0].contest[0] : '',
                    data: match,
                    edit: item.teamnumber,
                  }}
                />
              ))
            )

          )
          }
        </div>
      </div>
    </>
  )
}

export default MyTeam