import React from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import wp from './../../fantasyAssets/img/wpcolourful.svg'
import mail from './../../fantasyAssets/img/emailColourful.svg'
import { Link } from 'react-router-dom'

const ContactHelp = () => {
    return (  
        <>
            <div className="row fantasyInner">
                <FantasyHeader gameHeader={true} title={'Contact & Help'}/>
                    <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                        <div className="row mainCenterPart g-4 mx-0 py-4">
                            <div className="col-12">
                                <Link to="tel:3698745236"className="dipositCard shadow py-3 text-center text-decoration-none d-block">
                                    <div className="warningImg rounded-circle mx-auto mb-2"><img src={wp} className="w-100" alt=""/></div>
                                    <div className="balance depositText ">3698745236</div>
                                    <div className="subbalance ">10:00am - 6:30pm(mon-fri)</div>
                                    {/* <div className="subbalanceTwo text-danger">₹ 0.00</div> */}
                                </Link>
                            </div>
                            <div className="col-12">
                                <Link to="mailto:support@riskle.com" className="dipositCard  shadow  py-3 text-center text-decoration-none d-block">
                                    <div className="warningImg rounded-circle mx-auto mb-2"><img src={mail} className="w-100" alt=""/></div>
                                    <div className="balance depositText">Customer Support Email</div>
                                    <div className="subbalance ">support@riskle.com</div>
                                    {/* <div className="subbalanceTwo fw-bold text-success">₹ 100.00</div> */}
                                </Link>
                            </div>
                        </div>
                    </div>
                <FantasyBottomBar/>
            </div>
        </>
    )
}

export default ContactHelp