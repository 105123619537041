import React from "react";
import { useSelector } from "react-redux";
import { number } from "yup";
import { WALLET_MODAL_TABS } from "../../../constants";
import { useWalletModal } from "../../../hooks/useWallet";

function Wallet() {
  const fantasyUserData = useSelector((state) => state?.fantasyUserData);
  const { setWalletModalObject } = useWalletModal()
  return (
    <>
      <div className="row">
        <div className="col-12 d-md-block d-none">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/wallet.png"
                alt="WALLET"
                className="h-100 w-100 img-fluid "
              />
            </div>
            WALLET
          </div>
        </div>
        <div className="col-12 pb-1">
          <div className="cardsubHeading d-flex align-items-center justify-content-between">
            <div className=" d-flex align-items-center">
              <span className="me-3 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/sidebar/switch.png"
                  alt="switch"
                  className="h-100 w-100 img-fluid"
                />
              </span>
              SWITCH WALLET
            </div>
            {/* <div className="totalWallet">
              Total : <span> ₹ {fantasyUserData?.mainWallet ? fantasyUserData?.mainWallet?.toFixed(2) : 0.00}</span>
            </div> */}
          </div>
        </div>
        <div className="col-12 pt-md-3 pt-sm-2 pt-1">
          <div className="mainCard">
            <div className="mainHeading padLef">MAIN</div>
            <div className="mainHeading mainHeadingVal pt-3 d-flex align-items-center gap-2">
              <div className="CoinIcon d-flex align-items-center justify-content-center overflow-hidden rounded-circle">
                <img
                  src="assets/img/sidebar/coin.png"
                  alt="coin"
                  className="h-100 w-100 img-fluid"
                />
              </div>
              ₹ {fantasyUserData?.mainWallet ? fantasyUserData?.mainWallet?.toFixed(2) : 0.00}
            </div>
          </div>
        </div>
        <div className="col-sm-8 col-auto mx-auto pt-md-5 pt-4">
          <button
                        onClick={() => setWalletModalObject((prev) => ({
                          isWalletOpen: true,
                          selectedTab: WALLET_MODAL_TABS.DEPOSIT
                        }))}
            className="customBtn customBtntwo d-flex align-items-center justify-content-center w-100 "
          >
            DEPOSIT
          </button>
        </div>
      </div>
    </>
  );
}

export default Wallet;
