import React from 'react'
import gifImg from './../../AdminAssets/img/pageNotFound.gif'

const AdminPageNotFound = () => {
  return (
    <div className="app">
        <main className="app-main bg-white">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner text-center">
                {/* <header className="page-title-bar justify-content-between row"> */}
                  {/* <h1 className="page-title text-center"> 404!:( The Page you are looking for is not here! </h1> */}
                  <img src={gifImg} alt=""  style={{maxWidth: '100%'}}/>
                  <div className=" page-section align-items-center w-auto m-0  flex-row-reverse col-md-auto"  >
                  </div>
                {/* </header> */}
                <div className="d-flex justify-content-between my-3">
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
  )
}

export default AdminPageNotFound