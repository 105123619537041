import React from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import playerImg from './../../fantasyAssets/img/ban.png'
import { useLocation } from 'react-router-dom'
import { TeamConstants } from '../../../constants/createTeamConstants'
import { useEffect } from 'react'
import { useState } from 'react'
import { getFantasyAuth } from '../../fantasyServices'
import { useSelector } from 'react-redux'

const TeamView = () => {
    const { state } = useLocation()
    const [teamData, setTeamData] = useState([]);
    const [TotalPoints, setTotalPoints] = useState(0);
    const selectedTeams = useSelector(state => state.selectedTeams)
    const playerConditionsData = useSelector(state => state.playerConditionsData)

    // Function for getting Team data
    const getData = async () => {
        const res = await getFantasyAuth(
            `viewteam?matchkey=${!!state?.contest.matchkey ? state?.contest.matchkey : state?.data.matchkey
            }&jointeamid=${state?.playerData.jointeamid}&teamnumber=${state?.playerData.teamnumber
            }`,
        );

        setTeamData(res.data.data);
        res.data.data.map(el => {
            setTotalPoints(prev => prev + Number(el.points));
        });
    };

    useEffect(() => {
        if (!!!state?.players) {
            getData();
        } else {
            setTeamData(state?.players);
        }
    }, []);

    return (
        <>
            <div className="row fantasyInner">
                <FantasyHeader teamViewHeader={true} />
                <div className="col-12 px-0 flex-fill viewTeamPage" data-page-name="indexPage">
                    <div className="row mx-0 teamViewDetailRow">
                        <div className="col-3 teamViewBoxx">
                            {
                                state?.players ? <>
                                    <div className="teamViewHT">Players</div>
                                    <div className="teamViewTxt"><span>{state?.players?.length}</span>/11</div>
                                </> : ''
                            }

                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-center">
                            <span className='hbox1'>{state?.data?.team1name}</span>
                            <span className='hbox2'>{state?.players ? state?.players?.filter((el) => (el.team == "team1")).length : teamData?.filter((el) => (el.team == "team1")).length}:{state?.players ? state?.players?.filter((el) => (el.team == "team2")).length : teamData?.filter((el) => (el.team == "team2")).length}</span>
                            <span className='hbox3'>{state?.data?.team2name}</span>
                        </div>
                        <div className="col-3 teamViewBoxx text-end">
                            {
                                state && state?.credits ? <><div className="teamViewHT">Credits Left</div>
                                    <div className="teamViewTxt"><span>{parseFloat(state?.credits).toFixed(1)}</span></div></> : ''
                            }
                        </div>
                    </div>
                    <div className="row viewTeam mx-0 h100">
                        <div class="col-12 bgImg">
                            <div class="row pt-4">
                                <div class="col-12">
                                    <div class="row px-4 py-3 justify-content-around">
                                        <div class="col-12">
                                            <div class="playerPosTitle text-center mb-2">Wicket-Keeper</div>
                                        </div>
                                        {
                                            teamData?.filter((el) => (el.role == TeamConstants.WK)).map((elem) => (
                                                <div class="col-auto">
                                                    <div class="plyerOuter position-relative text-center">
                                                        {/* <div className="captain rounded-circle overflow-hidden text-uppercase">c</div> */}
                                                        <div class="playerImg mx-auto">
                                                            <img class="w-100 h-100 object-fit-contain" src={elem?.image} alt="" />
                                                        </div>
                                                        <div class="player">
                                                            <span class="name rounded-1 d-block">{elem?.name}</span>
                                                            <span class="prz">{elem.credit}Cr</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row px-4 py-3 justify-content-around">
                                        <div class="col-12">
                                            <div class="playerPosTitle text-center mb-2">Batsmen</div>
                                        </div>
                                        {
                                            teamData?.filter((el) => (el.role == TeamConstants.BAT)).map((elem) => (
                                                <div class="col-auto">
                                                    <div class="plyerOuter position-relative text-center">
                                                        {/* <div className="captain rounded-circle overflow-hidden text-uppercase">c</div> */}
                                                        <div class="playerImg mx-auto">
                                                            <img class="w-100 h-100 object-fit-contain" src={elem?.image} alt="" />
                                                        </div>
                                                        <div class="player">
                                                            <span class="name rounded-1 d-block">{elem?.name}</span>
                                                            <span class="prz">{elem.credit}Cr</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row px-4 py-3 justify-content-around">
                                        <div class="col-12">
                                            <div class="playerPosTitle text-center mb-2">All-Rounders</div>
                                        </div>
                                        {
                                            teamData?.filter((el) => (el.role == TeamConstants.AR)).map((elem) => (
                                                <div class="col-auto">
                                                    <div class="plyerOuter position-relative text-center">
                                                        {/* <div className="captain rounded-circle overflow-hidden text-uppercase">c</div> */}
                                                        <div class="playerImg mx-auto">
                                                            <img class="w-100 h-100 object-fit-contain" src={elem?.image} alt="" />
                                                        </div>
                                                        <div class="player">
                                                            <span class="name rounded-1 d-block">{elem?.name}</span>
                                                            <span class="prz">{elem.credit}Cr</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row px-4 py-3 justify-content-around">
                                        <div class="col-12">
                                            <div class="playerPosTitle text-center mb-2">Bowlers</div>
                                        </div>
                                        {
                                            teamData?.filter((el) => (el.role == TeamConstants.BOWL)).map((elem) => (
                                                <div class="col-auto">
                                                    <div class="plyerOuter position-relative text-center">
                                                        {/* <div className="captain rounded-circle overflow-hidden text-uppercase">c</div> */}
                                                        <div class="playerImg mx-auto">
                                                            <img class="w-100 h-100 object-fit-contain" src={elem?.image} alt="" />
                                                        </div>
                                                        <div class="player">
                                                            <span class="name rounded-1 d-block">{elem?.name}</span>
                                                            <span class="prz">{elem.credit}Cr</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeamView