import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, } from 'swiper/modules';
import { getAPIAuth } from '../../service/apiInstance';
import { Spinner } from 'react-bootstrap';
import { ROUTES_CONST } from '../../constants/routeConstant';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setVideoUrlAction } from '../../store/action';
import { useAuth } from '../../hooks/useAuth';

const LiveCasinoGames = () => {
    const [gamesLoader, setGamesLoader] = useState(true);
    // const [gamedata, setGameData] = useState([]);
    // const [gamedataNmae, setGameDataNmae] = useState([]);

    const [games, setGames] = useState([]);
    console.log("games", games);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { token } = useAuth()

    const getCasino = async () => {
        if (token) {
            setGamesLoader(true)
            try {
                const res = await getAPIAuth("casino/newCasinoGame?perPage=8&page=1", token)

                // console.log("sfdsydsy", res);
                setGames(res?.data?.data?.data)
            } catch (error) {
            } finally {
                setGamesLoader(false)
            }
        }
    }

    useEffect(() => {
        getCasino()
    }, [])

    const handleClick = (item, name) => {

        //console.log("sdfd", item);
        // setGameData(item)
        // setGameDataNmae(name)
        //navigate(`${ROUTES_CONST.CASINO_HOME}/${item?.gameId}?provider=${item?.providerName}`)

    }

    return (
        <>
            {
                games?.map((item) => {

                    console.log("gfdlkkfd", item);

                    return (
                        <div className="container-fluid px-0">
                            <div className="topratedgames-tab-main position-relative">
                                <div className="topratedgames-tab-content">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-md">
                                            <div className="diceBox"><img src="assets/img/dice.png" alt="" />{item?.name}
                                                <span className='cursor-pointer'
                                                    onClick={() => navigate(ROUTES_CONST.CASINO_HOME)}
                                                >View all</span> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane fade show active">
                                    <div className="container-fluid toprated-games-main position-relative">
                                        <div className="toprated-games">
                                            <div className="row position-relative">
                                                <div className="col-12 games-card-main px-0">
                                                    <div className="mySwiper cardSwiper casinoCard1">
                                                        <Swiper
                                                            className="swiper"
                                                            navigation={{
                                                                nextEl: ".liveNext",
                                                                prevEl: ".livePrev",
                                                            }}
                                                            breakpoints={{
                                                                0: {
                                                                    slidesPerView: 2,
                                                                    spaceBetween: 8,
                                                                },
                                                                460: {
                                                                    slidesPerView: 3,
                                                                    spaceBetween: 8,
                                                                },
                                                                600: {
                                                                    slidesPerView: 3,
                                                                    spaceBetween: 8,
                                                                },
                                                                768: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 8,
                                                                },
                                                                992: {
                                                                    slidesPerView: 5,
                                                                    spaceBetween: 8,
                                                                },
                                                                1100: {
                                                                    slidesPerView: 5,
                                                                    spaceBetween: 8,
                                                                },
                                                                1200: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 10,
                                                                },
                                                                1400: {
                                                                    slidesPerView: 5,
                                                                    spaceBetween: 10,
                                                                },
                                                                1600: {
                                                                    slidesPerView: 6,
                                                                    spaceBetween: 10,
                                                                }
                                                            }}
                                                            mousewheel={true}
                                                            keyboard={true}
                                                            modules={[Navigation]}
                                                        >
                                                            {!gamesLoader ?
                                                                item?.gameData?.length > 0 ?
                                                                    item?.gameData?.map((item) => (
                                                                        <SwiperSlide key={item?._id} className="swiper-slide ">
                                                                            <div className={`casinoImg rounded-2 overflow-hidden cursor-pointer`}
                                                                                onClick={() => handleClick(item?.gameData, item?.name)}
                                                                            >
                                                                                <img src={item?.urlThumb} className="img-fluid w-100 h-100 " alt="CasinoHome" />
                                                                            </div>
                                                                        </SwiperSlide>
                                                                    ))
                                                                    : <div className="w-100 h-100">
                                                                        <h6 className="text-light"> No data available</h6>
                                                                    </div>
                                                                :
                                                                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                                    <Spinner animation="border" variant="light" size="sm" />
                                                                </div>
                                                            }
                                                        </Swiper>
                                                        <div className="swiper-button-prev-unique livePrev p-0  d-inline-flex align-items-center justify-content-center cursor-pointer"><img src="assets/img/carouselLeftArrow.png" alt="prev" className="h-100 w-100 img-fluid" /></div>
                                                        <div className="swiper-button-next-unique liveNext p-0  d-inline-flex align-items-center justify-content-center cursor-pointer"><img src="assets/img/carouselRightArrow.png" alt="prev" className="h-100 w-100 img-fluid" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )


                })
            }
            {/* second data */}

            {/* {
                gamedata?.length > 0 ? (
                    <>
                        <div className="container-fluid px-0">
                            <div className="topratedgames-tab-main position-relative">
                                <div className="topratedgames-tab-content">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-md">
                                            <div className="diceBox"><img src="assets/img/dice.png" alt="" />{gamedataNmae}
                                                <span className='cursor-pointer'
                                                    onClick={() => navigate(ROUTES_CONST.CASINO_HOME)}
                                                >View all</span> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane fade show active">
                                    <div className="container-fluid toprated-games-main position-relative">
                                        <div className="toprated-games">
                                            <div className="row position-relative">
                                                <div className="col-12 games-card-main px-0">
                                                    <div className="mySwiper cardSwiper casinoCard1">
                                                        <Swiper
                                                            className="swiper"
                                                            navigation={{
                                                                nextEl: ".liveNext",
                                                                prevEl: ".livePrev",
                                                            }}
                                                            breakpoints={{
                                                                0: {
                                                                    slidesPerView: 2,
                                                                    spaceBetween: 8,
                                                                },
                                                                460: {
                                                                    slidesPerView: 3,
                                                                    spaceBetween: 8,
                                                                },
                                                                600: {
                                                                    slidesPerView: 3,
                                                                    spaceBetween: 8,
                                                                },
                                                                768: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 8,
                                                                },
                                                                992: {
                                                                    slidesPerView: 5,
                                                                    spaceBetween: 8,
                                                                },
                                                                1100: {
                                                                    slidesPerView: 5,
                                                                    spaceBetween: 8,
                                                                },
                                                                1200: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 10,
                                                                },
                                                                1400: {
                                                                    slidesPerView: 5,
                                                                    spaceBetween: 10,
                                                                },
                                                                1600: {
                                                                    slidesPerView: 6,
                                                                    spaceBetween: 10,
                                                                }
                                                            }}
                                                            mousewheel={true}
                                                            keyboard={true}
                                                            modules={[Navigation]}
                                                        >
                                                            {!gamesLoader ?
                                                                gamedata?.length > 0 ?
                                                                    gamedata?.map((item) => (
                                                                        <SwiperSlide key={item?._id} className="swiper-slide ">
                                                                            <div className={`casinoImg rounded-2 overflow-hidden cursor-pointer`}
                                                                                onClick={() => handleClick(item)}
                                                                            >
                                                                                <img src={item?.urlThumb} className="img-fluid w-100 h-100 " alt="CasinoHome" />
                                                                            </div>
                                                                        </SwiperSlide>
                                                                    ))
                                                                    : <div className="w-100 h-100">
                                                                        <h6 className="text-light"> No data available</h6>
                                                                    </div>
                                                                :
                                                                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                                    <Spinner animation="border" variant="light" size="sm" />
                                                                </div>
                                                            }
                                                        </Swiper>
                                                        <div className="swiper-button-prev-unique livePrev p-0  d-inline-flex align-items-center justify-content-center cursor-pointer"><img src="assets/img/carouselLeftArrow.png" alt="prev" className="h-100 w-100 img-fluid" /></div>
                                                        <div className="swiper-button-next-unique liveNext p-0  d-inline-flex align-items-center justify-content-center cursor-pointer"><img src="assets/img/carouselRightArrow.png" alt="prev" className="h-100 w-100 img-fluid" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                    </>
                )
            } */}
        </>
    )
}

export default LiveCasinoGames