import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { ROUTES_CONST } from '../../constants/routeConstant';
import { getAPI } from '../../service/apiInstance';

const DambleUniverse = () => {
    const [games, setGames] = useState([])
    const [gamesLoader, setGamesLoader] = useState(true)
    const [sports, setSports] = useState([])
    const [sportloader, setSportLoader] = useState(true)

    // get api for games
    const getGames = async () => {
        setGamesLoader(true)
        try {
            const res = await getAPI(`games/big-wins`)
            setGames(res?.data?.data.slice(0, 4))
        } catch (error) {
        } finally {
            setGamesLoader(false)
        }
    }

    const getSports = async () => {
        setSportLoader(true)
        try {
            const res = await getAPI('crypto/sports-book')
            setSports(res?.data?.getData?.slice(1, 5))
        } catch (error) {
        } finally {
            setSportLoader(false)
        }
    }

    useEffect(() => {
        // getGames()
        // getSports()
    }, [])


    return (
        <>
            <div className="container-fluid dambleUniverseSection d-none d-lg-block px-0">
                <div className="dambleUniverseInner">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex align-items-center dambHeading">
                                <img src="./assets/img/dambleUniverse.png" className="me-3" alt="" />
                                BX UNIVERSE
                            </div>
                        </div>

                    </div>
                    {/*  */}

                    <div className="row">
                        <div className="col-4">
                            <div className="casinoCard mb-3">
                                <div className="row gap-3">
                                    <div className="col-12">
                                        <div className="row gap-lg-0 gap-2 align-items-center">
                                            <div className="col-auto">
                                                <div className="casinoIcon">  <img src="assets/img/diceYellow.png" alt="logo" className="img-fluid w-100 h-100" />                                                </div>
                                            </div>
                                            <div className="col-auto px-0">
                                                <div className="casinoTitle">SUBSCRIBTION </div>
                                            </div>
                                            <div className="col-lg-auto col-12">
                                                <span className="casinoSubTitle ms-lg-2 ">Epic Adventures. Subscribe Now.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="pera"> Subscribe and unlock a world of games - Stock Fantasy, Casino, and more - for endless chances to win big!                                        </div>
                                    </div>



                                    <div className="col-12 pt-lg-3 pt-1">
                                        <div className="row align-items-end gap-lg-0 gap-2">

                                            <div className="col-lg col pe-0">
                                                <Link to={ROUTES_CONST.SUBSCRIPTION_PAGE} className="text-decoration-none d-flex align-items-center justify-content-lg-end justify-content-start gameBtn">Go to Games <span className="icon d-inline-flex"> <img className="image img-fluid w-100 h-100" src="assets/img/aboutJonathan/right.svg" alt="right" /></span> </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="casinoCard">
                                <div className="row gap-3">
                                    <div className="col-12">
                                        <div className="row gap-lg-0 gap-2 align-items-center">
                                            <div className="col-auto">
                                                <div className="casinoIcon">  <img src="assets/img/diceYellow.png" alt="logo" className="img-fluid w-100 h-100" />                                                </div>
                                            </div>
                                            <div className="col-auto px-0">
                                                <div className="casinoTitle">CASINO </div>
                                            </div>
                                            <div className="col-lg-auto col-12">
                                                <span className="casinoSubTitle ms-lg-2 ">Unlock a World of Rewards </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="pera"> Play more than 20,000+ Casino games at BX, from live casino to your favorite slots.
                                        </div>
                                    </div>


                                    <div className="col-12 pt-lg-3 pt-1">
                                        <div className="row align-items-end gap-lg-0 gap-2">

                                            <div className="col-lg col pe-0">
                                                <Link to={ROUTES_CONST.CASINO_HOME} className="text-decoration-none d-flex align-items-center justify-content-lg-end justify-content-start gameBtn">Go to Games <span className="icon d-inline-flex"> <img className="image img-fluid w-100 h-100" src="assets/img/aboutJonathan/right.svg" alt="right" /></span> </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-4">
                            <div className="casinoCard mb-3">
                                <div className="row gap-3">
                                    <div className="col-12">
                                        <div className="row gap-lg-0 gap-2 align-items-center">
                                            <div className="col-auto">
                                                <div className="casinoIcon">  <img src="assets/img/diceYellow.png" alt="logo" className="img-fluid w-100 h-100" />                                                </div>
                                            </div>
                                            <div className="col-auto px-0">
                                                <div className="casinoTitle">WIN MORE </div>
                                            </div>
                                            <div className="col-lg-auto col-12">
                                                <span className="casinoSubTitle ms-lg-2 ">Win More. Every Time. </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="pera"> Unleash your competitive spirit. Subscribe and conquer a world of games - Stock Fantasy, thrilling Casinos, and epic adventures. Every challenge fuels your rise to the top. Win More.
                                        </div>
                                    </div>



                                    <div className="col-12 pt-lg-3 pt-1">
                                        <div className="row align-items-end gap-lg-0 gap-2">

                                            <div className="col-lg col pe-0">
                                                <Link to={ROUTES_CONST.WIN_MORE} className="text-decoration-none d-flex align-items-center justify-content-lg-end justify-content-start gameBtn">Go to Games <span className="icon d-inline-flex"> <img className="image img-fluid w-100 h-100" src="assets/img/aboutJonathan/right.svg" alt="right" /></span> </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="casinoCard">
                                <div className="row gap-3">
                                    <div className="col-12">
                                        <div className="row gap-lg-0 gap-2 align-items-center">
                                            <div className="col-auto">
                                                <div className="casinoIcon">  <img src="assets/img/diceYellow.png" alt="logo" className="img-fluid w-100 h-100" />                                                </div>
                                            </div>
                                            <div className="col-auto px-0">
                                                <div className="casinoTitle">FANTASY </div>
                                            </div>
                                            <div className="col-lg-auto col-12">
                                                <span className="casinoSubTitle ms-lg-2 ">Magic blooms, darkness fades.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="pera"> Step into extraordinary worlds.  Unleash magic, conquer mythical creatures, and claim legendary rewards.
                                        </div>
                                    </div>


                                    <div className="col-12 pt-lg-3 pt-1">
                                        <div className="row align-items-end gap-lg-0 gap-2">

                                            <div className="col-lg col pe-0">
                                                <Link to={ROUTES_CONST.FANTASY_HOME} className="text-decoration-none d-flex align-items-center justify-content-lg-end justify-content-start gameBtn">Go to Games <span className="icon d-inline-flex"> <img className="image img-fluid w-100 h-100" src="assets/img/aboutJonathan/right.svg" alt="right" /></span> </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-4">
                            <div className="casinoCard mb-3">
                                <div className="row gap-3">
                                    <div className="col-12">
                                        <div className="row gap-lg-0 gap-2 align-items-center">
                                            <div className="col-auto">
                                                <div className="casinoIcon">  <img src="assets/img/diceYellow.png" alt="logo" className="img-fluid w-100 h-100" />                                                </div>
                                            </div>
                                            <div className="col-auto px-0">
                                                <div className="casinoTitle">STOCK FANTASY </div>
                                            </div>
                                            <div className="col-lg-auto col-12">
                                                <span className="casinoSubTitle ms-lg-2 ">Trade. Outsmart. Win Big.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="pera"> Become a market titan. Build your dream portfolio, outsmart the competition, and chase epic jackpots.
                                        </div>
                                    </div>



                                    <div className="col-12 pt-lg-3 pt-1">
                                        <div className="row align-items-end gap-lg-0 gap-2">

                                            <div className="col-lg col pe-0">
                                                <Link to={ROUTES_CONST.FANTASY_SELECT_STOCKS_PAGE} className="text-decoration-none d-flex align-items-center justify-content-lg-end justify-content-start gameBtn">Go to Games <span className="icon d-inline-flex"> <img className="image img-fluid w-100 h-100" src="assets/img/aboutJonathan/right.svg" alt="right" /></span> </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="casinoCard">
                                <div className="row gap-3">
                                    <div className="col-12">
                                        <div className="row gap-lg-0 gap-2 align-items-center">
                                            <div className="col-auto">
                                                <div className="casinoIcon">  <img src="assets/img/diceYellow.png" alt="logo" className="img-fluid w-100 h-100" />                                                </div>
                                            </div>
                                            <div className="col-auto px-0">
                                                <div className="casinoTitle">JACKPOT </div>
                                            </div>
                                            <div className="col-lg-auto col-12">
                                                <span className="casinoSubTitle ms-lg-2 ">Dream Big. Win Bigger.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="pera"> Dream big, win bigger.  Unleash the potential for life-changing rewards with every game.
                                        </div>
                                    </div>


                                    <div className="col-12 pt-lg-3 pt-1">
                                        <div className="row align-items-end gap-lg-0 gap-2">

                                            <div className="col-lg col pe-0">
                                                <Link to={ROUTES_CONST.JACKPOT} className="text-decoration-none d-flex align-items-center justify-content-lg-end justify-content-start gameBtn">Go to Games <span className="icon d-inline-flex"> <img className="image img-fluid w-100 h-100" src="assets/img/aboutJonathan/right.svg" alt="right" /></span> </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  */}
                </div>
            </div>
        </>
    )
}

export default DambleUniverse