
export const LANGUAGE = [
    {
        displayName: "English",
        symbol: "en"
    },
    {
        displayName: "हिंदी",
        symbol: "hi"
    },
] //add languages here 

export const DEFAULT_LANG = {
    displayName: "English",
    symbol: "en"
}