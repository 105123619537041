import React from 'react'
import HomeIcon from './../../fantasyAssets/img/HomeIcon.png'
import ProfileIcon from './../../fantasyAssets/img/ProfileIcon.png'
import MoreIcon from './../../fantasyAssets/img/MoreIcon.png'
import PortfolioIcon from './../../fantasyAssets/img/portfolioIcon.png'
import MatchIcon from './../../fantasyAssets/img/myMatches.png'
import { Link } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'



const FantasyBottomBar = () => {
  return (
    <footer className={`col-12 fantasyFooter bottomBar d-flex align-items-center px-0`}>
        <div className="row mx-0 align-items-center innerBar w-100">
              <div className="col px-0 bottombarTabs text-center">
                <Link to={ROUTES_CONST.FANTASY_HOME} className="linkTab active text-decoration-none">
                      <div className="gameImg mx-auto mb-2">
                        <img src={HomeIcon} className="h-100" alt="cricket"/>
                      </div> 
                      <span className="gamesheading pt-1 d-flex justify-content-center">Home</span>
                </Link>
              </div>
              <div className="col px-0 bottombarTabs text-center">
                <Link to={ROUTES_CONST.MY_MATCHES} className="linkTab text-decoration-none">
                      <div className="gameImg mx-auto mb-2">
                        <img src={MatchIcon} className="h-100" alt="cricket"/>
                      </div> 
                      <span className="gamesheading pt-1 d-flex justify-content-center">My Matches</span>
                </Link>
              </div>
              <div className="col px-0 bottombarTabs text-center">
                  <Link to={ROUTES_CONST.FANTASY_PROFILE} className="linkTab text-decoration-none">
                      <div className="gameImg mx-auto mb-2">
                        <img src={ProfileIcon} className="h-100" alt="cricket"/>
                      </div> 
                      <span className="gamesheading pt-1 d-flex justify-content-center">Profile</span>
                  </Link>
              </div>
              <div className="col px-0 bottombarTabs text-center">
                <Link to={ROUTES_CONST.FANTASY_MY_PORTFOLIO} className="linkTab text-decoration-none">
                      <div className="gameImg mx-auto mb-2">
                        <img src={PortfolioIcon} className="h-100" alt="cricket"/>
                      </div> 
                      <span className="gamesheading pt-1 d-flex justify-content-center">Portfolio</span>
                </Link>
              </div>
              <div className="col px-0 bottombarTabs text-center">
                <Link to={ROUTES_CONST.FANTASY_MORE} className="linkTab text-decoration-none">
                      <div className="gameImg mx-auto mb-2">
                        <img src={MoreIcon} className="h-100" alt="cricket"/>
                      </div> 
                      <span className="gamesheading pt-1 d-flex justify-content-center">More</span>
                </Link>
              </div>
        </div>
    </footer>
  )
}

export default FantasyBottomBar