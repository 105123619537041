import React from 'react'
import trophyImg from './../../fantasyAssets/img/trophy.png'
import { errorToaster } from '../../../utils/toaster'
import { useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import CounterComp from '../CounterComp/CounterComp'
import moment from 'moment/moment'
import LightiningIcon from '../../fantasyAssets/img/lightining.png'

const StocksCard = ({
  isJoined,
  navigation,
  el,
  selectingTeam,
  route1,
  route2,
  params1,
  params2,
  setContestLoader,
  win,
  share,
  timer,
  selected,
}) => {
  const navigate = useNavigate()
  console.log(el?.joinedusers / el?.maximum_user,el)
  return (
    <>
      <div
        className="stocksCard rounded-3 overflow-hidden mb-3 p-2 cursor-pointer"
        onClick={() => {
          // if (!!route1) {
          if (selected && el?.is_selected) {
            errorToaster('You Already Joined This Contest');
          } else {
            // navigate(route1, params1);
            // if () {

            // }
            navigate(`${ROUTES_CONST?.FANTASY_SELECT_STOCKS_PAGE}/${el?._id}`, {
              state: {
                stockContest: el
              }
            });
          }
          // route1={'SelectStock'}
          // params1={{data: el}}
          // navigation.navigate(route1, params1);

          // }
        }}
      >

        <div className="row align-items-center">
          <div className="col-12">
            <div className="row progressRow mb-3">
              <div className="col">
                <div className="progressVal">
                  <img src={trophyImg} alt="" />
                  {el?.winning_percentage}%
                </div>
                <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                  <div className="progress-bar" style={{ width: '25%' }}></div>
                </div>
              </div>
              <div className="col-auto progressAuto">
                <img src={trophyImg} alt="" />
                {/* 900 */}
                {el?.win_amount}
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="gameImg rounded-circle overflow-hidden">
              <img src={el?.image} className='h-100 w-100 object-fit-cover' alt="" />
            </div>
          </div>
          <div className="col">

            {
              timer ? (
                <CounterComp start_date={el.start_date} stocks={true} />
              ) : (
                <>
                  <img src={LightiningIcon} className='lightiningImg' alt="" />
                </>
              )
            }
            {/* <div className="ramaningTym text-center text-danger">
              02d:01h:16m
              </div> */}
          </div>
          <div className="col-auto">
            <button className='priceBtn rounded border-0 text-white'>
              {/* {isJoined ? 'joined' : `₹ ${el?.entryfee}`} */}
              {selected && el?.is_selected ? 'joined' : `₹ ${el?.entryfee}`}
            </button>
          </div>
          <div className="col-12 my-2">
            <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
              <div className="progress-bar progress-bar-striped" style={{ width: `${el?.maximum_user == 0 ? 0 : (el?.joinedusers / el?.maximum_user) * 100}%` }}></div>
            </div>
          </div>
          <div className="col-12">
            <div className="row mx-0">
              <div className="col-4 ps-0">
                <div className="valueBox d-flex align-items-center">
                  <span>Starts: </span>
                  <span>
                    {/* 15 Oct | 15:47 */}
                    {moment(el?.start_date).format('DD MMM | HH:mm')}
                    {/*  */}

                  </span>
                </div>
              </div>
              <div className="col-4 px-0">
                <div className="valueBox d-flex align-items-center justify-content-center">
                  <span>Spots Left: </span>
                  <span>
                    {/* 3 */}
                    {el?.maximum_user - el?.joinedusers}
                  </span>

                </div>
              </div>
              <div className="col-4 pe-0">
                <div className="valueBox d-flex align-items-center justify-content-end">
                  <span>Ends: </span>
                  <span>
                    {/* 15 Oct | 16:47 */}
                    {moment(el?.end_date).format('DD MMM | HH:mm')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StocksCard