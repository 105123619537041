import React from 'react'

const HistoryItemList = ({ data, index }) => {
  return (
    <tr key={data?.instrument_token}>
      <td>
        <div className="d-flex align-items-center ">{index + 1}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.trading_symbol}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.buy_sell}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.quantity}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.mis_cnc}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.instrument_token}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.execution_price}</div>
      </td>
      <td>
        <div className="d-flex align-items-center ">{data?.execution_date}</div>
      </td>
    </tr>
  )
}

export default HistoryItemList
