import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { succesToaster as successToaster } from "../../../../utils/toaster";
import { adminPostAPI,adminPostAPIAuth } from "../../../AdminServices";
import { USER } from "../../../AdminConstants/apiEndpoints";
import { ADMIN_AUTH_ACCESS } from "../../../../constants";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";

const CommissionForm = ({ setShow, data, updateData }) => {
  // const [formValues, setFormValues] = useState({
  //   bookMakerOnOff: data?.isbookmakerCommissionOn,
  //   turnOverOnOff: data?.isFancyCommissionOn,
  //   bookMakerValue: data?.bookmakerCommission,
  //   turnOverValue: data?.fancyCommission,
  //   password: "",
  // });

  const [formValues, setFormValues] = useState({
    bookMakerValue: '',
    turnOverValue: '',
    bookMakerOnOff: false,
    turnOverOnOff: false,
    password: "",
  });

  const adminData = useSelector((state) => state.adminData);
  const [error, setError] = useState("");
  const adminToken=useLocalStorage(ADMIN_AUTH_ACCESS,"")

  const toggleSwitch = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: !formValues[e.target.name],
    }));
  };

  const handleChange = (e) => {
    const nameArray = ["bookMakerValue", "turnOverValue"];

    if (nameArray.includes(e.target.name) && isNaN(Number(e.target.value))) {
      setFormValues((prev) => ({
        ...prev,
        [e.target.name]: formValues[e.target.name],
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (formValues.password === "") {
      setError("Please fill in your password");
    } else {
      handleApiCall();
    }
  };

  const handleApiCall = async () => {
    const loginCred = {
      username: adminData.username,
      password: formValues.password,
    };
    setError("");
    try {
      const res = await adminPostAPI(USER.login, loginCred, true);
      if (res?.data?.data?.success) {
        adminToken.updateValue(res?.data?.data.token)
        depositApi();
      }
    } catch (error) {
      setError(error?.response?.data?.data?.message);
    }
  };

  // const depositApi = async () => {
  //   const payload = {
  //     userId: data?._id,
  //     bookmakerCommission: formValues.bookMakerValue,
  //     isbookmakerCommissionOn: formValues.bookMakerOnOff,
  //     fancyCommission: formValues.turnOverValue,
  //     isFancyCommissionOn: formValues.turnOverOnOff,
  //   };

  //   try {
  //     const res = await adminPostAPIAuth(USER.commission, payload, true);
  //     if (res.status === 200) {
  //       successToaster("Updated Successfully! ");
  //       setError("");
  //       updateData();
  //       setShow(false);
  //     }
  //   } catch (error) {
  //     setError(error?.response?.data?.data?.message);
  //   }
  // };

  const depositApi = async () => {
    const payload = {
      userId: data?._id,
      creatorId: data?.createdBy,
      bookMakerPercentage: Number(formValues.bookMakerValue),
      turnOverPercentage: Number(formValues.turnOverValue),
      bookMakerStatus: formValues.bookMakerOnOff === false ? '0' : '1',
      turnOverStatus: formValues.turnOverOnOff === false ? '0' : '1',
    };

    try {
      const res = await adminPostAPIAuth(USER.saveCommission, payload, true);
      if (res.status === 200) {
        successToaster("Data Added Successfully! ");
        setError("");
        updateData();
        setShow(false);
      }
    } catch (error) {
      setError(error?.response?.data?.data?.message);
    }
  };

  return (
    <Form>
      <Container>
        <Row className="mt-3">
          <Col>
            <div>Market</div>
          </Col>
          <Col>
            <div>OFF/ON</div>
          </Col>
          <Col>
            <div>Value(%)</div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div>Bookmaker</div>
          </Col>

          <Col>
            <div className="form-group">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="bookMakerOnOff"
                  name="bookMakerOnOff"
                  // checked={formValues.bookMakerOnOff}
                  onChange={toggleSwitch}
                />{" "}
                <label
                  className="custom-control-label"
                  htmlFor="bookMakerOnOff"
                ></label>
              </div>
            </div>
          </Col>

          <Col>
            <Form.Group>
              <Form.Control
                type="text"
                id="bookMakerValue"
                name="bookMakerValue"
                placeholder="Percentage"
                value={formValues.bookMakerValue}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div>Turn over</div>
          </Col>

          <Col>
            <div className="form-group">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="turnOverOnOff"
                  name="turnOverOnOff"
                  // checked={formValues.turnOverOnOff}
                  onChange={toggleSwitch}
                />{" "}
                <label
                  className="custom-control-label"
                  htmlFor="turnOverOnOff"
                ></label>
              </div>
            </div>
          </Col>

          <Col>
            <Form.Group>
              <Form.Control
                type="text"
                name="turnOverValue"
                id="turnOverValue"
                placeholder="Percentage"
                value={formValues.turnOverValue}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Form.Group>
              <Form.Control
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Button type="submit" onClick={handleClick} className="w-100">
              Save
            </Button>
          </Col>
        </Row>
        {/* admin-forms-error-text has css in userManagement.css  */}
        {error && (
          <Row className="mt-2 admin-forms-error-text">
            <Col>
              <div>{error}</div>
            </Col>
          </Row>
        )}
      </Container>
    </Form>
  );
};

export default CommissionForm;
