import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../constants/routeConstant'
import moment from 'moment'
import CircularProgressBar from './inputrange/Index'
import TicketModal from './Modal'
import { useAuth } from '../../hooks/useAuth'
import { postAPIAuth, getAPIAuth } from '../../service/apiInstance'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';





const PromotionCard = ({ jackpotdata, getJackpotGetAll }) => {
  // console.log("sdfhasdsad", jackpotdata);
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const [modalticket, setModalTicket] = useState(false);
  const [propsdata, setPropsData] = useState({});
  // console.log("propsdata", propsdata);
  const { token } = useAuth();
  const [jackpottickets, setJackPotTickets] = useState([]);
  // console.log("jackpottickets", jackpottickets);
  const [percentageSold, setPercentageSold] = useState("");
  const mainWalletData = useSelector((state) => state?.getWalletData.getUserBal);
  // console.log("dshgusdfadlssa", mainWalletData);
  const dateString = jackpotdata?.end_date;
  const calculateTimeLeft = () => {
    const difference = +new Date(dateString) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());


  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setTimeLeft(calculateTimeLeft());
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // });


  // console.log("jackpotticketsjackpottickets", jackpottickets);

  const handleClose = () => setModalTicket(false);

  const ModalTicketHandle = (data) => {
    setPropsData(data)
    setModalTicket(true);
  }

  const getAllJackpotTickets = async (id) => {

    if (token) {
      try {
        const res = await getAPIAuth(`user/getAllJackpotTickets?jackpotId=${jackpotdata?._id}`, token);
        // console.log("res", res);
        if (res?.data?.data?.success) {
          setJackPotTickets(res?.data?.data?.response);
          if (id) {
            const addNewTickets = JSON.parse(localStorage.getItem("ticketCards"));
            const filtered = addNewTickets.filter((item) => item.id !== id);
            localStorage.setItem("ticketCards", JSON.stringify(filtered));
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    }

  }

  useEffect(() => {
    // console.log('kiikkiikiikikiki')
    getAllJackpotTickets();
  }, [token, jackpotdata]);



  const buyTicketHandle = async (data) => {
    console.log("data", data);

    const obj = {
      jackpotId: data?._id,
      ticket_price: data?.ticket_price
    }

    try {
      const res = await postAPIAuth(`user/buyJackpot`, obj, token);
      if (res?.data?.data?.success) {
        //  console.log(res , 'euisfygudsiorpdfgisof');
        toast(res?.data?.data?.message);
        getJackpotGetAll()
        mainWalletData()

        // getAllJackpotTickets()
      }
    } catch (error) {
      console.log("dsafhdysgusiud", error);
      toast(`${error?.message}`);
    }
    handleClose();
  }
  let percentageSolds = Math.round((jackpotdata?.ticket_sold / jackpotdata?.total_tickets) * 100);
  // console.log("sfdgadsfgfsda", percentageSolds);
  return (
    <>
      {/* Modal */}
      <Modal Modal show={modalticket} className='ticket-buy-dataa' onHide={handleClose} >
        <Modal.Header>
          <div className="modal-icon" onClick={handleClose}>
            <i class="bi bi-x"></i>
          </div>
        </Modal.Header>
        <Modal.Body>Do You Want To Continue ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className='customBtn ' onClick={() => buyTicketHandle(propsdata)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal >
      {/* modal end */}
      <div className="col-lg-5 col-md-6">
        <TicketModal
          show={show}
          setShow={setShow}
          data={propsdata}
          buyTicketHandle={buyTicketHandle}
          jackpottickets={jackpottickets}
          getAllJackpotTickets={getAllJackpotTickets}
          percentageSolds={percentageSolds}
        />
        <div className={`lottery-cards`}>
          <div className="promotionTxt mx-0 align-items-center d-grid gap-3">

            <div>
              <div className='dscTxt jackpot-card-heading'>
                <span>
                  {jackpotdata?.name}
                </span>
              </div>
            </div>

            <div className="row" style={{ alignItems: 'center' }}>
              <div className="col-lg-12">
                <div className="jackpot-data" style={{ position: "relative" }}>
                  <div className='dscTxt jackpot-card-heading'>
                    <img src={jackpotdata.image} alt="" />
                  </div>
                  {/*  */}
                  <div className="border-bottom-counterdown-data">

                    <div className="time-blocks">
                      <div className="block">
                        <span className="side-text">D</span>
                        <span className="time-text" id="days">{timeLeft.days}</span>
                      </div>
                      <div className="block">
                        <span className="side-text">H</span>
                        <span className="time-text" id="hours">{timeLeft.hours}</span>
                      </div>
                      <div className="block">
                        <span className="side-text">MIN</span>
                        <span className="time-text" id="minutes">{timeLeft.minutes}</span>
                      </div>
                      <div className="block">
                        <span className="side-text">SEC</span>
                        <span className="time-text" id="seconds">{timeLeft.seconds}</span>
                      </div>
                    </div>

                  </div>
                  {/*  */}

                </div>


              </div>

            </div>


            <div className="jackpot-details-header">
              <div className="jackpot-details">
                <div className="right-hand-side">
                  <p className='jackpot-card-para'>
                    Winning Amount :

                  </p>

                </div>

                <div className="left-hand-side">
                  <p className='jackpot-card-para'>
                    {jackpotdata?.winning_amount}

                  </p>

                </div>
              </div>

              {/*  */}

              <div className="jackpot-details">
                <div className="right-hand-side">
                  <p className='jackpot-card-para'>
                    Ticket Price :

                  </p>

                </div>

                <div className="left-hand-side">
                  <p className='jackpot-card-para'>
                    {jackpotdata.ticket_price}

                  </p>

                </div>
              </div>

              {/*  */}

              <div className="jackpot-details">
                <div className="right-hand-side">
                  <p className='jackpot-card-para'>
                    Total Winner :

                  </p>

                </div>

                <div className="left-hand-side">
                  <p className='jackpot-card-para'>
                    {jackpotdata?.total_winner}

                  </p>

                </div>
              </div>
              {/*  */}
              <div className="jackpot-details">
                <div className="right-hand-side">
                  <p className='jackpot-card-para'>
                    Total Tickets :

                  </p>

                </div>

                <div className="left-hand-side">
                  <p className='jackpot-card-para'>
                    {jackpotdata?.total_tickets}

                  </p>

                </div>
              </div>
              {/*  */}
              <div className="jackpot-details">
                <div className="right-hand-side">
                  <p className='jackpot-card-para'>
                    Total Limits :

                  </p>

                </div>

                <div className="left-hand-side">
                  <p className='jackpot-card-para'>
                    {jackpotdata?.ticket_limit ? jackpotdata?.ticket_limit : 0}

                  </p>

                </div>
              </div>
              {/*  */}
              <div className="jackpot-details">
                <div className="right-hand-side">
                  <p className='jackpot-card-para'>
                    Total Sold :

                  </p>

                </div>

                <div className="left-hand-side">
                  <p className='jackpot-card-para'>
                    <CircularProgressBar tickets={jackpotdata?.total_tickets} sold={jackpotdata?.ticket_sold ? jackpotdata?.ticket_sold : 0} />
                  </p>
                </div>
              </div>
              {/*  */}
            </div>





            <div className="col-auto px-0">

              <div className="btn-jackpot-button">

                {
                  percentageSolds === 100 ? (
                    <>
                      <div className="sould-out-btn">
                        <img src="assets/img/sold-btn.png" alt="" />
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        className='progressBtn-jackpot-btn'
                        onClick={() => {
                          setShow(true);
                          setPropsData(jackpotdata)
                        }}
                        data-bs-target="#exampleModalsub"
                      > Buy Tickets  </button>
                    </>
                  )
                }
                {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
              </div>
            </div>
          </div>

        </div>


      </div>
    </>

  )
}

export default PromotionCard