import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation, Mousewheel, Keyboard } from 'swiper/modules';
import { getAPI, getAPIAuth } from '../../service/apiInstance';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONST } from '../../constants/routeConstant';
import { useAuth } from '../../hooks/useAuth';
import { useSelector } from 'react-redux';
import GamesCard from '../../components/common/GamesCard/GamesCard';

const breakpoints = {
    0: {
        slidesPerView: 3,
        spaceBetween: 8,
    },
    420: {
        slidesPerView: 3,
        spaceBetween: 8,
    },
    500: {
        slidesPerView: 4,
        spaceBetween: 8,
    },
    768: {
        slidesPerView: 5,
        spaceBetween: 8,
    },
    992: {
        slidesPerView: 6,
        spaceBetween: 8,
    },
    1100: {
        slidesPerView: 7,
        spaceBetween: 8,
    },
    1200: {
        slidesPerView: 6,
        spaceBetween: 10,
    },
    1400: {
        slidesPerView: 7,
        spaceBetween: 10,
    },
    1600: {
        slidesPerView: 8,
        spaceBetween: 10,
    }
}

const CategoriesGamesComp = () => {
    const [allCats, setAllCats] = useState([])
    const [games, setGames] = useState('')
    const [gamesLoader, setGamesLoader] = useState(true)
    const navigate = useNavigate()
    const { token } = useAuth()
    const userDetails = useSelector(state => state.userDetails)

    const getGames = async () => {
        setGamesLoader(true)
        try {
            if (token && userDetails?.id) {
                const res = await getAPIAuth(`games/get-games-by-cat`)
                if (res?.data?.success) {
                    setAllCats(res?.data?.data)
                } else {
                    setAllCats([])
                }
            } else {
                const res = await getAPI(`games/get-games-by-cat`)
                if (res?.data?.success) {
                    setAllCats(res?.data?.data)
                }

            }
        } catch (error) {
            setAllCats([])
        } finally {
            setGamesLoader(false)
        }
    }

    useEffect(() => {
        // getGames()
    }, [token, userDetails?.id])

    return (
        <>
            {
                allCats.map(elem => (
                    <div className="container-fluid px-0" key={elem._id}>
                        <div className="topratedgames-tab-main position-relative">
                            <div className="topratedgames-tab-content">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-md">
                                        <div className="diceBox"><img src="assets/img/dice.png" alt="" /> {elem?.name?.category_name} <span className='cursor-pointer' onClick={() => navigate(ROUTES_CONST.SLOT_PAGE)}>View all</span> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane fade show active">
                                <div className="container-fluid toprated-games-main position-relative">
                                    <div className="toprated-games">
                                        <div className="row position-relative">
                                            <div className="col-12 games-card-main px-0">
                                                <div className="mySwiper cardSwiper casinoCard1">
                                                    <Swiper
                                                        className="swiper"
                                                        navigation={{
                                                            nextEl: ".slotsNext",
                                                            prevEl: ".slotsPrev",
                                                        }}
                                                        breakpoints={breakpoints}
                                                        mousewheel={true}
                                                        keyboard={true}
                                                        modules={[Navigation, Mousewheel, Keyboard]}
                                                    >
                                                        {!gamesLoader ?
                                                            elem?.data?.length > 0 ?
                                                                elem?.data?.map((item) => (
                                                                    <SwiperSlide
                                                                        key={item?._id}
                                                                        className="swiper-slide "
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            navigate(`${ROUTES_CONST.SLOT_PAGE}/${item?.uuid}`)
                                                                        }}
                                                                    >
                                                                        <GamesCard
                                                                            RTP={item?.rtp}
                                                                            image={item?.image}
                                                                            name={item?.name}
                                                                            isFav={item?.isFav}
                                                                            id={item?._id}
                                                                            favButton={true}
                                                                            uuid={item?.uuid}
                                                                        />
                                                                    </SwiperSlide>
                                                                ))
                                                                : <div className="w-100 h-100">
                                                                    <h6 className="text-light">
                                                                        No data available
                                                                    </h6>
                                                                </div>
                                                            :
                                                            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                                <Spinner animation="border" variant="light" size="sm" />
                                                            </div>
                                                        }
                                                    </Swiper>
                                                    <div className="swiper-button-prev-unique slotsPrev p-0  d-inline-flex align-items-center justify-content-center cursor-pointer">
                                                        <img src="assets/img/carouselLeftArrow.png" alt="prev" className="h-100 w-100 img-fluid" />
                                                    </div>
                                                    <div className="swiper-button-next-unique slotsNext p-0  d-inline-flex align-items-center justify-content-center cursor-pointer">
                                                        <img src="assets/img/carouselRightArrow.png" alt="prev" className="h-100 w-100 img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }

        </>
    )
}

export default CategoriesGamesComp