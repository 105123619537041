import React, { useState, useEffect } from 'react'
import './style.css'
import { useAuth } from '../../../hooks/useAuth';
import { getAPIAuth, postAPIAuth } from '../../../service/apiInstance';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getMainWalletData } from '../../../store/action';

function Ticketcards({ getAllJackpotTickets, jackpottickets, data }) {

  console.log("dataaadd", jackpottickets);

  const JackPotData = JSON.parse(localStorage.getItem('ticketCards'));
  const { token } = useAuth();
  const dispatch = useDispatch();
  // console.log("JackPotDataJackPotData", JackPotData);

  const getUserBal = async () => {
    try {
        const res = await getAPIAuth(`user/balance`, token)
        if (res?.data?.data?.success) {
            // setUserBal((pre) => ({
            //     points: res.data.data.data.points,
            //     exposer: res.data.data.data.exposer,
            //     mainWallet: res.data.data.data.mainWallet
            // }))
            dispatch(getMainWalletData({
              "userBalance": res.data.data.data.mainWallet,
              "getUserBal": getUserBal
            }));
        }
    } catch (error) {

    }
}

  const getGenerateHandler = async (id) => {

    const obj = {
      jackpotId: data?._id,
      ticket_price: data?.ticket_price
    }

    try {
      const res = await postAPIAuth(`user/generateTicketNumber`, obj, token);
      if (res?.data?.data?.success) {
        // setToogleClass(id)
        console.log("res?.data?.data.message", res?.data?.data.message);
        toast(res?.data?.data.message);
        getUserBal();
        getAllJackpotTickets(id);
        // mainWalletData();
      }
    } catch (error) {
      console.log(error);

      toast(`${error.response.data.data.message}`);
    }

  }

  // const storedData = localStorage.getItem('generateticketList');

  // const dataArray = JSON.parse(storedData);

  // console.log("storedData", dataArray);

  return (

    <>
      <div className="row mt-5 mb-4 main-cards-wrapper">

        {
          jackpottickets?.length > 0 ? (
            <>

              {jackpottickets?.map(item => (

                <div className="col-12 col-md-4" key={item?._id}>
                  <div className="tickets-header-details">
                    <div className="tickets-header">
                      <p className='tickets-card-para'>
                        Your Ticket Number
                      </p>
                    </div>
                    <div className="bottom-header-data">
                      <div className={`bottom-header-data`}>
                        {item?.ticketNumber}
                      </div>

                      <div className="tickets-button">
                        <span></span>

                      </div>
                    </div>
                  </div>
                </div>
              ))}

            </>
          ) : (<></>)
        }

        {
          JackPotData?.map(item => {
            return (
              <>
                <div className="col-12 col-md-4" key={item?.id}>
                  <div className="tickets-header-details">
                    <div className="tickets-header">
                      <p className='tickets-card-para'>
                        Your Ticket Number
                      </p>
                    </div>
                    <div className="bottom-header-data">
                      <div className={`bottom-header-data`}>
                        <span>000000</span>
                      </div>

                      <div className="tickets-button">
                        {item?.ticketNumber == null ? (
                          <button
                            className='ttickets-jackpot-btn'
                            onClick={() => getGenerateHandler(item?.id)}
                          >Generate</button>
                        ) : (
                          <span></span>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })
        }
        {/*  */}
      </div>




    </>

  )
}

export default Ticketcards