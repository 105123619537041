import React, { useState } from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import {  postFantasyAuth } from '../../fantasyServices'
import { errorToaster } from '../../../utils/toaster'

const DepositCash = () => {
    const [addCash, setAddCash] = useState("")
    const [amt, setAmt] = useState("");

    const setAmount = e => {
        setAmt(e.target.value);
    };



    const handleAddCash = async () => {
        // try {
        //     if (!!addCash) {
        //       const params = {
        //         paymentby: 'phonepe',
        //         amount: `${addCash}`,
        //         max_amount:2000
        //       };
        //       const res = await postFantasyAuth('requestaddcash', params);
        //       console.log("res....navigate to  the phonePe page",res)
        //     }
        // } catch (error) {
        //     console.log("DepositCash page error ......" ,error)
        // }

            //validation
    if (!!!addCash) {
        errorToaster("Amount can't be empty")
        return
      }
      if (isNaN(Number(addCash))) {
        errorToaster('Please add valid amount')
        return
      }
  
      // amount in paisa
      // console.log("trim", `(${amount.trim()})`)
      // const paisaAmount = amount.trim() * 100
      try {
        const body = {
          // amount: paisaAmount
          amount: addCash * 100
        }
        // get orderId from backend
        const res = await postFantasyAuth(`requestaddcash`, body)
        if (res?.data?.status) {
          // desired options
          const options = {
            key: "rzp_test_ztRa3SOhInKYJn",
            // order_id: `RAZOR${Math.floor(Math.random()*20000)}`,
            order_id: res?.data?.orderId,
            handler: function (response) {
  
            },
            // amount: paisaAmount,
            amount: addCash * 100,
  
          };
          // create an instance for razorPay
          const paymentObject = new window.Razorpay(options);
          // open the pop up of razorPay 
          paymentObject.open();
        } else {
          // handle exceptions
          errorToaster(res?.data?.message)
        }
      } catch (error) {
        //handle errors
        errorToaster(error.message)
      }
      };

    return (
        <>
        <div className="row fantasyInner">
        <FantasyHeader gameHeader={true} title={'Deposit Cash'}/>
        <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
        <form action="">
            <div className="row mx-0 py-3 depositForm gap-4 ">
                <div className="col-12">
                    <label for="addcase" className="form-label labelHeading addText">Add Cash</label>
                    <input type="text" 
                        value={addCash}
                        onChange={(e)=>setAddCash(e.target.value)}
                        className="form-control shadow-none inp py-2" 
                        id="addcase" 
                        placeholder="Enter Amount"/>
                </div>
                <div className="col-12 px-0">
                    <div className="row mx-0 align-items-center">
                        <div className="col-auto labelHeading">Quick Add:</div>
                        <div className="col-auto filterInputCol ps-0">
                            <input onClick={()=>setAddCash((prev)=>`${Number(prev) + 100}`)} className='filterInp d-none' type="button" name="addCash" id='entry1' />
                            <label className='filterCheckBtn' htmlFor="entry1">₹ 100</label>
                        </div>
                        <div className="col-auto filterInputCol ps-0">
                            <input onClick={()=>setAddCash((prev)=>`${Number(prev) + 300}`)} className='filterInp d-none' type="button" name="addCash" id='entry2' />
                            <label className='filterCheckBtn' htmlFor="entry2">₹ 300</label>
                        </div>
                        <div className="col-auto filterInputCol ps-0">
                            <input onClick={()=>setAddCash((prev)=>`${Number(prev) + 600}`)} className='filterInp d-none' type="button" name="addCash" id='entry3' />
                            <label className='filterCheckBtn' htmlFor="entry3">₹ 600</label>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <input type="text" className="form-control inp shadow-none py-2" id="promocode" placeholder="Apply Promo Code Here" onChange={setAmount}  value={amt} />
                </div>
                <div className="col-12">
                    <button type="button" className=" btnStyle2" onClick={handleAddCash} >Add Cash</button>
                </div>
            </div>      
        </form>
        </div>
        <FantasyBottomBar/>
    </div>
        </>
    )
}

export default DepositCash