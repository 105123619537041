import React from 'react'
import MatchCard from '../../fantasyComponents/matchCard/MatchCard'
import { Spinner } from 'react-bootstrap'

const MyMatches = ({ myMatches, isLoading }) => {
  return (
    <>
      <div className="col-12">
        <div className="sectionHeading">My Matches</div>
        {
          isLoading ? (
            <div className='w-100 h-100 d-flex justify-content-center'>
              <Spinner color='white' size='sm' />
            </div>
          ) : (
            !myMatches || myMatches == 0 ? (
              <div className='w-100 h-100 d-flex justify-content-center text-light' >No Data Available</div>
            ) : (
              myMatches?.map((item) => (
                <MatchCard
                  key={item?.id}
                  matchName={item?.matchname}
                  series_name={item?.series_name}
                  team1ShortName={item?.team1ShortName}
                  team2ShortName={item?.team2ShortName}
                  status={item?.status}
                  final_status={item?.final_status}
                  winning_status={item?.winning_status}
                  team1logo={item?.team1logo}
                  team2logo={item?.team2logo}
                  start_date={item?.start_date}
                  upcoming={false}
                  playing11_status={item?.playing11_status}
                  teamfullname1={item?.teamfullname1}
                  teamfullname2={item?.teamfullname2}
                  id={item?.matchkey}
                  match={item}
                />
              ))
            )

          )
        }
      </div>
    </>
  )
}

export default MyMatches