import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { getAPIAuth, postAPIAuth } from '../../service/apiInstance'
import { useWalletModal } from '../../hooks/useWallet'
import { WALLET_MODAL_TABS } from '../../constants'
import { getCryptoListAction } from '../../store/action'
import { useDispatch, useSelector } from 'react-redux'
import { errorToaster, succesToaster } from '../../utils/toaster'

const Withdraw = ({setWalletModalObject, userWithdrawalDetail}) => {
    const { token } = useAuth()
    const { walletModalObject } = useWalletModal()
    const cryptoList = useSelector(state => state.cryptoList)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const [selectedCrypto, setSelectedCrypto] = useState({})
    const [address, setAddress] = useState("")
    const [amount, setAmount] = useState("")
    const userDetails = useSelector(state => state.userDetails)
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [availCrypto, setAvailCrypto] = useState([])
    const [filterData, setFilterData] = useState([])
    const [convertedPrice, setConvertedPrice] = useState(0.00)
    const initialValue = {
        accountHolderName: "",
        ifscCode: "",
        bankName: "",
        accountNo: "",
        upi: "",
        amount: ""
    }
    const [accountDetails, setAccountDetails] = useState(initialValue)
    const [validationError, setValidationError] = useState(false)
    const [ifscValidation, setIfscValidation] = useState(false)
    const [accountNoValidation, setAccountNoValidation] = useState(false)
    const [isUpi, setIsUpi] = useState(false);
    const timeoutRef = useRef()
    const [isEdit, setIsEdit] = useState(false)
    const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const accountNumberRegex = /^[0-9]{9,18}$/;

    const getAvailableCrypto = async () => {
        try {
            const res = await getAPIAuth("crypto/get-crypt-favorite-by-admin")
            if (res.data.success) {
                setAvailCrypto(res.data.data)
            }
        } catch (error) {
        }
    }

    const getCrypto = async () => {
        if (!!search) {
            let filterDataArr = cryptoList.filter((el) => {
                return el.code.toUpperCase().includes(search.toUpperCase())
            });
            setFilterData(filterDataArr)
        } else {
            if (
                walletModalObject.isWalletOpen &&
                walletModalObject.selectedTab === WALLET_MODAL_TABS.WITHDRAW &&
                cryptoList.length === 0
            ) {
                try {
                    const resp = await getAPIAuth("crypto/get-crypto", token);
                    dispatch(getCryptoListAction(resp?.data.data))
                    setSelectedCrypto(resp?.data?.data?.filter(item => item.code === 'eth')[0])
                    setFilterData(resp?.data.data)
                } catch (error) {

                }
            } else if (
                walletModalObject.isWalletOpen &&
                walletModalObject.selectedTab === WALLET_MODAL_TABS.WITHDRAW &&
                cryptoList.length !== 0
            ) {
                setSelectedCrypto(cryptoList.filter(item => item.code === 'eth')[0])
                setFilterData(cryptoList)
            }
            setIsLoading(false)
        }
    }

    const onWithdraw = async () => {
        if (!address) {
            errorToaster("Address can't be empty!")
            setWithdrawLoading(false)
            return
        }
        if (!!!amount || amount === '') {
            errorToaster("Add a valid amount to withdraw!")
            setWithdrawLoading(false)
            return
        }
        if (convertedPrice === 0 || convertedPrice < 0) {
            errorToaster("Converted Amount isn't Valid")
            setWithdrawLoading(false)
            return
        }
        try {
            const body = {
                userId: userDetails.id,
                address: address,
                withdraw_amount: amount,
                coinId: selectedCrypto._id,
                user_currency: userDetails?.usercurrency,
                chain: 'eth',
                type: "withdraw",
                convertedCurrency: convertedPrice
            }

            const res = await postAPIAuth("user/save-request-withdraw", body)
            if (res.data.success) {
                succesToaster(res.data.message)
            } else {
                errorToaster(res.data.message)
            }
        } catch (error) {
            errorToaster("Something went wrong")
        } finally {
            setWithdrawLoading(false)
            setAddress('')
            setAmount("")
        }
    }
    useEffect(() => {
        // getCrypto()
    }, [walletModalObject.selectedTab, walletModalObject.isWalletOpen, search])

    useEffect(() => {
        if (walletModalObject.isWalletOpen &&
            walletModalObject.selectedTab === WALLET_MODAL_TABS.WITHDRAW) {
            // getAvailableCrypto()
        }
    }, [walletModalObject.selectedTab, walletModalObject.isWalletOpen])

    const priceConverter = async () => {

        const body = {
            source: selectedCrypto.code,
            target: "usdt",
            amount: amount
        }
        try {
            const res = await postAPIAuth("convertPrice", body)
            if (res.data.success) {
                setConvertedPrice(res.data.data.amount)
            } else {
                setConvertedPrice(0.00)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (walletModalObject.isWalletOpen &&
            walletModalObject.selectedTab === WALLET_MODAL_TABS.WITHDRAW) {
            // debouncing
            if (!!amount) {
                setWithdrawLoading(true)
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            timeoutRef.current = setTimeout(() => {
                if (!!amount) {
                    // priceConverter()
                } else {
                    if (!amount) setConvertedPrice(0.00)
                }
                setWithdrawLoading(false)
            }, 400)
        }

        return () => clearTimeout(timeoutRef.current)
    }, [amount, walletModalObject.selectedTab, walletModalObject.isWalletOpen, selectedCrypto])


    // handle withdraw

    const handleWithdraw = async (e) => {
        try {
            e.preventDefault();
            if (accountDetails.amount < 1) {
                console.log('validationError', validationError)
                setValidationError(true)
            } else {
                setWithdrawLoading(true)
                const body = {
                    accountHolderName: accountDetails.accountHolderName,
                    ifscCode: accountDetails.ifscCode,
                    bankName: accountDetails.bankName,
                    accountNumber: accountDetails.accountNo,
                    amount: accountDetails.amount,
                    upi: accountDetails.upi,
                    saveDetails : isEdit ? 1: 0
                };
                console.log('body', body)
                const res = await postAPIAuth("user/submitWithdrawRequest", body)
                if (res?.data?.data?.success) {
                    succesToaster('Withdraw Successfully')
                    setIfscValidation(false)
                    setAccountNoValidation(false)
                    setValidationError(false)
                    setAccountDetails({ ...accountDetails, amount: '' })
                    // setAccountDetails(initialValue)
                } else {
                    errorToaster(res?.data?.data?.message)
                }
            }
        } catch (error) {
            errorToaster(error?.response?.data?.data?.message)
        } finally {
            setWithdrawLoading(false)
        }
    }

    const withdrawChange = (e) => {
        // const { name, value } = e.target
        // setAccountDetails({ ...accountDetails, [name]: value })
        setValidationError(true)
        const value = e.target.value;

        // Use a regular expression to allow only numeric characters
        const numericValue = value.replace(/[^0-9]/g, '');
        setAccountDetails({ ...accountDetails, amount: numericValue })
    }

    // prefilled input values
    useEffect(()=> {
        setAccountDetails((prev)=>({
            ...prev,
            accountHolderName: userWithdrawalDetail?.accountHolderName,
            ifscCode: userWithdrawalDetail?.ifscCode,
            bankName: userWithdrawalDetail?.bankName,
            accountNo: userWithdrawalDetail?.accountNumber,
            upi: userWithdrawalDetail?.upi,
        }))
    }, [userWithdrawalDetail])

    return (
        <div className='row mx-0 gx-0 gap-3'>
            <div className="col-12">
                    <div className="row">
                      <div className="col-auto">
                        <div className={`accountCheckbox ${!isUpi ? 'active' : ''}`}
                          onClick={()=>setIsUpi(false)}>
                          <div className="checkDiv"></div>
                          <div className="checkTxt">Bank</div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className={`accountCheckbox ${isUpi ? 'active' : ''}`}
                          onClick={()=>setIsUpi(true)}>
                          <div className="checkDiv"></div>
                          <div className="checkTxt">upi</div>
                        </div>
                      </div>
                    </div>
                  </div>
            <div className='d-flex justify-content-between withdrawEditBox'>
                <div>
                    <div className="withdrawalTxtHeading">Your Withdrawal Details</div>
                    <div className='text-light withdrawEditTxt'>
                        <span>Account Holder Name :</span> {userWithdrawalDetail?.accountHolderName}
                    </div>
                    {
                        isUpi ? <>
                            <div className='text-light withdrawEditTxt'>
                                <span>UPI ID :</span> {userWithdrawalDetail?.upi}
                            </div>
                            <div className='text-light withdrawEditTxt' >
                                <span>QR Code :</span> <img src={userWithdrawalDetail.image} style={{height:"100px"}} alt='QR code' />
                            </div>
                        </>
                        : <>
                            <div className='text-light withdrawEditTxt'>
                                <span>Bank Name :</span> {userWithdrawalDetail?.bankName}
                            </div>
                            <div className='text-light withdrawEditTxt'>
                                <span>IFSC CODE :</span> {userWithdrawalDetail?.ifscCode}
                            </div>
                            <div className='text-light withdrawEditTxt'>
                                <span>Account Number :</span> {userWithdrawalDetail?.accountNumber}
                            </div>
                        </>
                    }
                </div>
                <div>
                    <button
                        className='border-0 withdrawalEditBtn p-0 bg-transparent  shadow-none text-nowrap d-flex align-items-center justify-content-center'
                        onClick={() => setWalletModalObject((prev) => ({
                            ...prev,
                            selectedTab: WALLET_MODAL_TABS?.UPDATE_WITHDRAW_DETAILS
                        }))}
                    >
                        <img src="assets/img/editIcon.png" alt="" />
                    </button>
                </div>
            </div>
            <div className='col-12'>
                <div className="row g-3">
                        <div className="col-sm-12 inputdata">
                            <label htmlFor="holderName7" className="inputLabel">
                                Amount
                            </label>
                            <input
                                type="text"
                                id="holderName7"
                                className="form-control inputlogin border-0 shadow-none"
                                placeholder="Enter Amount"
                                name="amount"
                                value={accountDetails.amount}
                                onChange={withdrawChange}
                            />
                            <span className={`text-danger ${validationError === true ?  'd-block' : 'd-none' }`}>
                                {
                                    accountDetails.amount === '' ? 'Required' : accountDetails.amount === '0' ? 'Enter valid amount' : '' 
                                }
                            </span>
                        </div>
                </div>
            </div>
            <div className='col-12'>
                <div className="col-12 pt-3">
                    <button
                        onClick={handleWithdraw}
                        className="customBtn w-100 shadow-none changeGmailBtn text-nowrap py-2 d-flex align-items-center justify-content-center "
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div >
    )
}

export default Withdraw