import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { sportsGetAPIAuth } from '../../sportsServices';

const MatchSection = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [seriesData, setSeriesData] = useState([])
  const matchId = useSelector(state => state?.matchId?.gameid)
  const betGameList = useSelector(state => state?.betGameList)
  const [gameIcon, setGameIcon] = useState()
  const navigate = useNavigate()

  const getMatchSeries = async () => {
    setIsLoading(true)
    try {
      const res = await sportsGetAPIAuth(`bet/gameSeries?gameId=${matchId ? matchId : 4}`)
      if (res?.data?.data?.success) {
        setSeriesData(res?.data?.data?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getMatchSeries()
  }, [matchId])

  useEffect(() => {
    if (betGameList?.length) {
      const data = structuredClone(betGameList)
      let icon = data?.filter(item => item?.gameId === matchId)
      setGameIcon(icon?.[0]?.image)
    }
  }, [matchId, betGameList])

  return (
    <>
      <Swiper
        className='matchCarousel'
        modules={[Navigation]}
        navigation={{
          nextEl: ".matchNext",
          prevEl: ".matchPrev",
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 8,
          },
          420: {
            slidesPerView: 1.3,
            spaceBetween: 8,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 8,
          },
          992: {
            slidesPerView: 2.5,
            spaceBetween: 8,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1400: {
            slidesPerView: 3.4,
            spaceBetween: 10,
          },
          1600: {
            slidesPerView: 3.4,
            spaceBetween: 10,
          }
        }}>
        {
          isLoading ? (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="light" size="sm" />
            </div>
          ) : (
            seriesData === 0 ? (
              <div className="w-100 h-100">
                <h6 className="text-light"> No data available</h6>
              </div>
            ) : (

              seriesData?.map((item) => (
                <SwiperSlide key={item?._id}>
                  <div className="seriesCard">
                    <div className="seriesIcon">
                      <img src={gameIcon} alt="" />
                    </div>
                    <div className="seriesName">{item?.name}</div>
                  </div>
                </SwiperSlide>
              ))
            )
          )
        }
        <button className="matchNext matchNavBtn position-absolute top-50 border-0 shadow-none rounded-circle overflow-hidden d-flex align-items-center justify-content-center cursor-pointer">
          <img src="assets/img/carouselRightArrow.png" alt="prev" className="" />
        </button>
        <button className="matchPrev matchNavBtn position-absolute top-50 border-0 shadow-none rounded-circle overflow-hidden d-flex align-items-center justify-content-center cursor-pointer">
          <img src="assets/img/carouselLeftArrow.png" alt="prev" className="" />
        </button>
      </Swiper>
    </>
  )
}

export default MatchSection