import React, { useEffect, useState } from 'react'
import { ADMIN_AUTH_ACCESS } from '../../../constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { LOGIN_URL, PORTAL } from '../../AdminConstants/urlPaths'
import AdminLoader from '../adminLoader/AdminLoader'

const AdminProtectedRoute = ({ Component }) => {
    const [isLoggedIn, setIsLoggedIn ] = useState(false)
    const value = localStorage.getItem(ADMIN_AUTH_ACCESS)
    const navigate =useNavigate()
    const [loading, setLoading] = useState(true);

    useEffect(()=> {
      setTimeout(() => {
        setLoading(false)
      }, 2000);
    },[])

  useEffect(() => {
    if (!value) {
      navigate( "/" + PORTAL + "/" + LOGIN_URL);
    } else {
      setIsLoggedIn(true);
    }
    //eslint-disable-next-line
  }, [value]);


  return (
    isLoggedIn && <Component />
  )
}

export default AdminProtectedRoute