import React, { useEffect, useState } from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { getAPIAuth } from '../../service/apiInstance'
import moment from 'moment/moment'
import DarkPagination from '../../components/darkPagination/DarkPagination'
import { useAuth } from '../../hooks/useAuth'
import SubscriptionBettingCards from '../../Subscribtion-Sports/subscribtionSportsPages/SportsHome/SubscribtionBettingCards'

const transactionArr = [
    {
        value: '',
        label: "All",
    },
    {
        value: 'credit',
        label: "Deposit",
    },
    {
        value: 'debit',
        label: "Withdraw",
    },

]

const SubscribtionAccount = () => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [totalPages, setTotalPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [transactionType, setTransactionType] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [usersubscricription, setUserSubscription] = useState([]);
    const [userId, setUserID] = useState('');
    const [selecteduser, setSelectedUser] = useState('All')
    const { token } = useAuth()
    const itemsPerPage = 10;

    // console.log("transactionType", data);

    const getMyStatement = async () => {
        setLoader(true)
        try {
            const res = await getAPIAuth(`user/myAccountSubStatement?page=${currentPage}&perPage=${itemsPerPage}&packageId=${userId}&type=${transactionType}&startDate=${startDate}&endDate=${endDate}`, token)
            console.log('resresres', res)
            if (res?.data?.data?.success) {
                setTotalPages(res?.data?.data?.pagination?.totalPages)
                setData(res?.data?.data?.data)
            }
        } catch (error) {
            setData([])
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getMyStatement()
    }, [currentPage, transactionType, startDate, endDate, userId]);

    const getUserAllSub = async () => {
        try {
            const res = await getAPIAuth('user/getUserAllSub', token);
            if (res?.data?.data?.success) {
                console.log("asdfdd", res);
                setUserSubscription(res?.data?.data?.response)
                // setGames(res?.data?.data?.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getUserAllSub();
    }, []);


    const startIndex = (currentPage - 1) * itemsPerPage + 1;

    return (
        <>
            <main className="main vh-100" id="main">
                <div className="mainContaint">
                    <div className="homepage tradingPages">
                        {/* start here */}
                        <div className="row mx-0 mt-2">
                            <div className="col-12">
                                <div className="row gap-3">
                                    <div className="col-12 px-0">
                                        <div className="row g-3 align-items-center ">
                                            <div className="col-md">
                                                <div className="tradingSecHead">My Account Statement</div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-6">
                                                <div className="reportsDropdownTitle">Transaction Type</div>
                                                <div className="dropdown reportsDropdown">
                                                    <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {transactionType === 'credit' ? "Deposit" : transactionType === "debit" ? 'Withdraw' : 'All'}
                                                    </button>
                                                    <ul className="dropdown-menu dropdownMenu w-100">
                                                        {
                                                            transactionArr?.map((item) => (
                                                                <li className={`menuLinkDrop ${transactionType === item?.value ? 'active' : ''}`}
                                                                    onClick={() => setTransactionType(item?.value)}
                                                                >{item?.label}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-3 col-6">
                                                <div className="reportsDropdownTitle">My Subscribtion</div>
                                                <div className="dropdown reportsDropdown">
                                                    <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {selecteduser}
                                                    </button>
                                                    <ul className="dropdown-menu dropdownMenu w-100">
                                                        <li className={`menuLinkDrop ${selecteduser === "All" ? 'active' : ''}`}
                                                            onClick={() => {
                                                                setSelectedUser('All')
                                                                setUserID('')
                                                            }}>All</li>
                                                        {
                                                            usersubscricription?.map((item) => (
                                                                <li className={`menuLinkDrop ${item?.name === selecteduser ? 'active' : ''}`}
                                                                    key={item?.name}
                                                                    onClick={() => {
                                                                        setSelectedUser(item?.name)
                                                                        setUserID(item?._id)
                                                                    }}
                                                                >{item?.name}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-3 col-6">
                                                <div className="reportsDropdownTitle">From</div>
                                                <input type="date" class="dateTimeInput"
                                                    value={startDate}
                                                    onChange={(e) => {
                                                        setCurrentPage(1)
                                                        setStartDate(e.target.value)
                                                    }}
                                                ></input>
                                            </div>
                                            <div className="col-lg-2 col-md-3 col-6">
                                                <div className="reportsDropdownTitle">To</div>
                                                <input type="date" class="dateTimeInput"
                                                    value={endDate}
                                                    min={startDate}
                                                    onChange={(e) => {
                                                        setCurrentPage(1)
                                                        setEndDate(e.target.value)
                                                    }}
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row pt-3 pb-2 g-3 align-items-center ">
                        <div className="col-md-4 col-sm-6 col-12 ps-0">
                          <div class="adminTopCard p-3">
                            <div class="d-flex align-items-center"><div class="adminTopCardIcon d-flex align-items-center justify-content-center"><img src="assets/img/present.svg" alt=""/></div><div class=""><div class="adminTopCardTitle">Total Bets</div><div class="adminTopCardSubtitle">4000.00</div></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 ">
                          <div class="adminTopCard p-3">
                            <div class="d-flex align-items-center"><div class="adminTopCardIcon d-flex align-items-center justify-content-center"><img src="assets/img/present.svg" alt=""/></div><div class=""><div class="adminTopCardTitle">Winning Bets</div><div class="adminTopCardSubtitle">4000.00</div></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 pe-0">
                          <div class="adminTopCard p-3">
                            <div class="d-flex align-items-center"><div class="adminTopCardIcon d-flex align-items-center justify-content-center"><img src="assets/img/present.svg" alt=""/></div><div class=""><div class="adminTopCardTitle">Lost Bets</div><div class="adminTopCardSubtitle">4000.00</div></div>
                            </div>
                          </div>
                        </div>
                    </div> */}
                            </div>
                            <div className="col-lg-12 px-0 subscription-bet-dat">

                                <SubscriptionBettingCards />


                            </div>

                            <div className="col-12 px-0 dambleTable sectionInner reportsTable  tableCard tab-content tab-pane table-responsive mb-0 mt-4">
                                <table className="table align-middle text-white text-nowrap mb0">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    SR.NO.
                                                </div>
                                            </th>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    DATE
                                                </div>
                                            </th>
                                            {/* <th>
                        <div className="tabletxt d-flex align-items-center">
                          FROM & TO
                        </div>
                      </th> */}
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    OPENING BALANCE
                                                </div>
                                            </th>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    CREDIT/DEBIT
                                                </div>
                                            </th>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    MY PROFITS
                                                </div>
                                            </th>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    CLOSING BALANCE
                                                </div>
                                            </th>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    TRANSACTION
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !loader ?
                                                data?.length > 0 ?
                                                    data?.map((item, index) => (
                                                        <tr>
                                                            <td>{startIndex + index}</td>
                                                            <td className='text-uppercase'>{moment(item?.createdAt).format('MMM Do YYYY, hh:mm a')}</td>
                                                            {/* <td>{item?.transferedByUser} {"->"} {item?.transferedToUser}</td> */}
                                                            {/* <td>{item?.transferedByPreviousPoints?.toFixed(2)}</td> */}
                                                            <td>{item?.previousPoints?.toFixed(1)}</td>
                                                            <td className={!item?.isDeposit ? "admin-forms-error-text" : "admin-forms-success-text"} >{item?.points?.toFixed(2)}</td>
                                                            <td>{item?.profitLossPoints}</td>
                                                            <td>{item?.totalPoints}</td>
                                                            <td>{item?.transactionType}</td>
                                                        </tr>
                                                    ))
                                                    : <tr>
                                                        <td colSpan={7} className='text-center'>No Data</td>
                                                    </tr>
                                                : 'loading'
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {
                                totalPages > 1 ?
                                    <DarkPagination
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                    : ''
                            }
                        </div>

                        {/* end here */}
                    </div>
                </div>

                {/*  **** Footer section *** */}
                <AppFooter />
            </main>
        </>
    )
}

export default SubscribtionAccount
