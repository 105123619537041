import React from 'react'
import './style.scss'
import AppFooter from '../../components/AppFooter/AppFooter'
import { useAuth } from '../../hooks/useAuth';
import BannerBeforeLogin from './BannerBeforeLogin';
import BannerAfterLogin from './BannerAfterLogin';
import { useState } from 'react';
import SportsTab from './ArchiveTab';
import CasinoTab from './PromotionTab';

const Promotion = () => {
  const { token } = useAuth();
  const [archiveActive, setArchiveActive] = useState(false)
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage promotionPage">
            <div className="row h-100 align-items-center">
              <div className="col-12">
                <div className="promotionBtn d-inline-flex align-items-center justify-content-center cursor-pointer">
                  <img src="./assets/img/promotions/promotionWhite.png" alt="provider icon" className='promotionIcon me-3' />
                  PROMOTIONS
                </div>
              </div>
              <div className="col-12">
                <div className="promotionBanner">
                  {!token ?
                    <>
                      {/* before Login */}
                      <BannerBeforeLogin />
                    </>
                    :
                    <>
                      {/* after login */}
                      <BannerAfterLogin />
                    </>
                  }
                </div>
              </div>
              <div className="col-12">
                <div className="promotionsTabs d-inline-flex align-items-center">
                  <div
                    className={`promotionTab d-flex align-items-center justify-content-center ${!archiveActive ? 'active' : ''}`}
                    onClick={() => setArchiveActive(false)}>
                    CASINO
                  </div>
                  <div
                    className={`promotionTab d-flex align-items-center justify-content-center ${archiveActive ? 'active' : ''}`}
                    onClick={() => setArchiveActive(true)}
                  >
                    SPORTS
                  </div>
                </div>
              </div>
              <div className="col-12">
                {!archiveActive ? <CasinoTab /> : <SportsTab />}
              </div>
            </div>
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  )
}

export default Promotion