import React, { useEffect, useState } from 'react'
import reload from './../../AdminAssets/img/reload.svg'
import { adminGetAPIAuth, adminPostAPI, adminPostAPIAuth } from '../../AdminServices';
import { REPORTS } from '../../AdminConstants/apiEndpoints';
import { errorToaster } from '../../../utils/toaster';
import { returnEndDate } from '../../../utils/returnEndDate';
import Pagination from '../../Admincomponents/pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import { MARKET_ADD_PAGE, PORTAL } from '../../AdminConstants/urlPaths';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { succesToaster as successToaster } from "../../../utils/toaster";
const ProfitAndLosstatement = () => {
    const [tableData, setTableData] = useState([]);
    console.log("tableData", tableData);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [filterValues, setFilterValues] = useState({

        startDate: "",
        endDate: "",
    });

    // console.log("filterValuesfilterValues", filterValues);
    const [tableid, SetTableID] = useState();
    const [ischangevalue, SetIsChangeValue] = useState();
    console.log("ischangevalue", ischangevalue);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const adminData = useSelector(state => state.adminData);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [serchuserdata, setSearchUserData] = useState("");
    // console.log("serchuserdata", serchuserdata);
    // const navigate = useNavigate();

    console.log("tableid", tableid);

    // set today's date as end date
    // useEffect(() => {
    //     const today = new Date()
    //     setFilterValues((prev) => ({
    //         ...prev,
    //         endDate: returnEndDate(today)
    //     }))
    // }, []);

    const DeleteMarketData = async (itemm) => {
        // console.log("itemm", itemm);
        try {
            const res = await adminGetAPIAuth(
                `${REPORTS?.DeleteMarketData}?id=${itemm}`

            );
            console.log('resresresres', res);
            if (res?.data?.data?.success) {
                // setTableData(res?.data?.message);
                GetMarketData();
            } else {
                errorToaster("Something went wrong in fetching data");
            }
        } catch (error) {
            if (error?.response?.status === 401) {

            }
            errorToaster(error?.response?.data?.data?.message);
            setTableData([]);
            console.log("error -------------->", error);
        }
    };

    const handlechangeStatus = async (statusid) => {
        // console.log("itemm", itemm);
        try {
            const res = await adminGetAPIAuth(
                `${REPORTS?.changeStatus}?id=${statusid}`

            );
            console.log('rrrrusygdyudygu', res);
            if (res?.data?.data?.success) {
                SetIsChangeValue(res?.data?.data?.data?.status)
                // setTableData(res?.data?.message);
                GetMarketData();
            } else {
                errorToaster("Something went wrong in fetching data");
            }
        } catch (error) {
            if (error?.response?.status === 401) {

            }
            errorToaster(error?.response?.data?.data?.message);
            setTableData([]);
            console.log("error -------------->", error);
        }
    };

    const handleresultTypeStatus = async (statusid, resulttype) => {
        // console.log("itemm", itemm);
        try {
            const res = await adminGetAPIAuth(
                `${REPORTS?.resultType}?id=${statusid}&&type=${resulttype}`

            );
            console.log('rrrrusygdyudygu', res);
            if (res?.data?.data?.success) {
                SetIsChangeValue(res?.data?.data?.data?.status)
                // setTableData(res?.data?.message);
                GetMarketData();
            } else {
                errorToaster("Something went wrong in fetching data");
            }
        } catch (error) {
            if (error?.response?.status === 401) {

            }
            errorToaster(error?.response?.data?.data?.message);
            setTableData([]);
            console.log("error -------------->", error);
        }
    };


    const GetMarketData = async () => {
        try {
            const res = await adminGetAPIAuth(
                `${REPORTS?.getMarketData}?name=${serchuserdata}&page=${currentPage}&perPage=10&startDate=${filterValues.startDate}&endDate=${filterValues.endDate}`
            );
            console.log('resresresres', res);
            if (res?.data?.data?.success) {
                setTableData(res?.data?.data?.data);
                setTotalPages(res?.data?.data?.pagination?.totalPages);
            } else {
                errorToaster("Something went wrong in fetching data");
            }
        } catch (error) {
            if (error?.response?.status === 401) {

            }
            errorToaster(error?.response?.data?.data?.message);
            setTableData([]);
            console.log("error -------------->", error);
        }
    };


    useEffect(() => {

        GetMarketData();

    }, [serchuserdata, currentPage, filterValues]);






    const handleGetList = async (url) => {

        navigate(url)

    };

    const handleChange = (e) => {
        setFilterValues((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleEditData = async (url, data) => {

        console.log("url", data);

        navigate(`${url}`, {
            state: data
        });

    }

    const handleClearList = () => {

        setFilterValues({
            startDate: "",
            endDate: "",
        })


    }


    // const handleBinaryUpdate = async (id) => {
    //     console.log("qwertyu_id", id);
    //     try {
    //         const res = await adminPostAPI(
    //             `${REPORTS?.updateBinaryResultManual}`, id
    //         );
    //         console.log('qwertyu_idresresresres', res);
    //         if (res?.data?.data?.success) {
    //             setTableData(res?.data?.data?.data);
    //             setTotalPages(res?.data?.data?.pagination?.totalPages);
    //         } else {
    //             errorToaster("Something went wrong in fetching data");
    //         }
    //     } catch (error) {
    //         if (error?.response?.status === 401) {

    //         }
    //         errorToaster(error?.response?.data?.data?.message);
    //         setTableData([]);
    //         console.log("error -------------->", error);
    //     }
    // };


    const handleBinaryUpdate = async (data) => {


        const payload = {

            id: data

        };
        try {

            const res = await adminPostAPIAuth(REPORTS?.updateBinaryResultManual, payload);
            console.log("qwertyresresresres", res);
            if (res.status === 200) {
                successToaster(`${res.data.data.message}`);
                GetMarketData()
            }


        } catch (error) {
            console.log("sssssserrorr", error);
            errorToaster(error?.response?.data?.data?.message);
        }
    };





    return (
        <>

            <div className="app">
                <main className="app-main">
                    <div className="wrapper">
                        <div className="page">
                            <div className="page-inner ">

                                <header className="page-title-bar justify-content-between row align-items-center">
                                    <h1 className="page-title mb-0 text-nowrap"> Market </h1>
                                </header>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="row align-items-end">
                                            <div className="col-md-auto pe-md-0 mb-2 mb-md-0">
                                                <div className="adminSearchInput">
                                                    <input
                                                        type="text"
                                                        placeholder="Search User"
                                                        value={serchuserdata}
                                                        onChange={(e) => setSearchUserData(e.target.value)}
                                                        className="form-control adminFilterInp" />
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div className="row justify-content-end mx-md-0">
                                                    {/* <div className="col-md-auto col-12 col-sm-4 pe-md-0 mb-2 mb-sm-0">
                            <label htmlFor="" className="form-label">Transactions</label>
                            <select name="" id="" className="form-select adminFilterInp">
                              <option value="">All</option>
                            </select>
                          </div> */}
                                                    <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                                                        <label htmlFor="" className="form-label">From</label>
                                                        <input type="date" id="startDate" name="startDate" value={filterValues.startDate} onChange={handleChange} className='form-control adminFilterInp' />
                                                    </div>
                                                    <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                                                        <label htmlFor="" className="form-label">To</label>
                                                        <input type="date" id="endDate" name="endDate" value={filterValues?.endDate} onChange={handleChange} className='form-control adminFilterInp' />
                                                    </div>
                                                    <div className="col-md-auto col-6 d-flex align-items-end col-sm-6 pe-md-0 mt-md-0 mt-3">
                                                        <button type="button" class="me-2 ms-auto btn btn-primary btnCustom1" onClick={handleClearList}>Clear All</button>
                                                        <button type="button" class="me-2 ms-auto btn btn-primary btnCustom1" onClick={() => handleGetList(`/${PORTAL}/${adminData.role}/${MARKET_ADD_PAGE}`)}><i class="bi bi-plus"></i>Add More</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className='text-uppercase text-nowrap'>
                                                    <tr>
                                                        <th>
                                                            <div className="d-flex">
                                                                S.NO
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                CATEGORY
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                STOCK
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                FROM
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                TO
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                F TIME
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                T TIME
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                NO
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                NO FLUCTUATION

                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                NO(SMALL)
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                NO (SMALL) FLUCTUATION
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                YES
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                YES FLUCTUATION

                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                YES(SMALL)

                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                YES (SMALL) FLUCTUATION

                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                MIN BET

                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                MAX BET

                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                STATUS
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                RESULT
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                RESULT TYPE
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                ACTION
                                                            </div>
                                                        </th>

                                                    </tr>
                                                </thead>
                                                {tableData?.length ? <tbody>
                                                    {
                                                        tableData?.map((item, index) => (
                                                            <tr>
                                                                <td className="align-middle fw-medium text-capitalize">{index + 1}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.stockName}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.stockId}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.from}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.to}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.fromTime}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.toTime}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.no}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{`${item?.noFluctuation}%`}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.noSmall}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{`${item?.noSmallFluctuation} %`}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.yes}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{`${item?.yesFluctuation} %`}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.yesSmall}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{`${item?.yesSmallFluctuation}%`}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.min_bet}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.max_bet}</td>
                                                                <td className={`align-middle fw-medium text-capitalize ${item?.status == 'Enable' ? "greenvalue" : "redvalue"}`} onClick={() => { handlechangeStatus(item?._id); SetTableID(item?._id); }}>{item?.status}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.result}</td>
                                                                <td className={`align-middle fw-medium text-capitalize ${item?.resultType == 'auto' ? "greenvalue" : "redvalue"}`} onClick={() => { handleresultTypeStatus(item?._id, item.resultType); SetTableID(item?._id); }}>{item?.resultType}</td>

                                                                <td className="align-middle fw-medium text-capitalize">
                                                                    <div className="add-more-daata">


                                                                        {
                                                                            item.resultType === "Manual" && item.result === "Pending" ? <button><i class="bi bi-trophy-fill" onClick={() => { handleBinaryUpdate(item?._id); }}></i></button> : <></>
                                                                        }

                                                                        <button><i class="bi bi-pencil" onClick={() => { handleEditData(`/${PORTAL}/${adminData.role}/${MARKET_ADD_PAGE}`, item); }}></i></button>
                                                                        <button><i class="bi bi-trash" onClick={() => { handleShow(); SetTableID(item?._id); }}></i></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody> :
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={3} className='text-center py-5'>
                                                                <b> No Data...</b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                }
                                            </table>
                                        </div>
                                    </div>
                                    {
                                        totalPages > 1 ?
                                            <Pagination
                                                totalPages={totalPages}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                            /> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Click Confirm to delete this data!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => { handleClose(); DeleteMarketData(tableid) }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ProfitAndLosstatement