import React, { useEffect, useState } from "react";
import { getAPIAuth } from "../../../service/apiInstance";
import { useSelector } from "react-redux";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import DarkPagination from "../../darkPagination/DarkPagination";
import ImageModal from "../../imageModal/ImageModal";

function Deposits({ tabopen, openaccordian }) {
  const [cryptoList, setCryptoList] = useState([])
  const [activeCrypto, setActiveCrypto] = useState('ALL')
  const [deposits, setDeposits] = useState([])
  const [transactionId, setTransactionId] = useState('')
  const [loader, setLoader] = useState(false)
  const [listLoader, setListLoader] = useState(true)
  const [showTransaction, setShowTransaction] = useState(false)
  const userDetails = useSelector((state) => state.userDetails);
  const userId = userDetails?.id;
  const userBalance = userDetails?.totalbalance;
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [showImgModal, setShowImgModal] = useState(false)
  const [imgUrl, setImgUrl] = useState('')


  // getting all crypto id
  const getCryptoList = async () => {
    setListLoader(true)
    try {
      const res = await getAPIAuth(`crypto/get-crypt-favorite-by-admin`)
      if (res?.data?.success) {
        setCryptoList(res?.data?.data)
        // setActiveCrypto(res?.data?.data?.[0]?.code)
        setActiveCrypto("ALL")
      }
    } catch (error) {

    } finally {
      setListLoader(false)
    }
  }

  useEffect(() => {
    // getCryptoList()
  }, [])


  const getDeposits = async () => {
    setLoader(true)
    try {
      if (userId) {
        const res = await getAPIAuth(`user/find-transaction?userId=${userId}&chain=eth&type=deposit&transactionId=${transactionId}`)
        if (res?.data?.success) {
          setDeposits(res?.data?.data)
        } else {
          setDeposits([])
        }
      }
    } catch (error) {

    } finally {
      setLoader(false)
    }
  }

  const newDeposit = async () => {
    setLoader(true)

    try {
      // if (userId) {
        const res = await getAPIAuth(`user/deposit-transaction-details?page=${currentPage}&perPage=${itemsPerPage}&transactionId=${transactionId}`)
        // console.log('deppppppppppppp--------------------->', res)
        if (res?.data?.data?.success) {
          setDeposits(res?.data?.data?.data?.fetchDetails)
          setTotalPages(res?.data?.data?.data?.pagination?.totalPages)
          setTransactionId("")
        } else {
          setDeposits([])
        }
      // }
    } catch (error) {

    } finally {
      setLoader(false)
    }
  }
  useEffect(() => {
    // getDeposits()
    if (tabopen === "Deposits" || openaccordian === "Deposits") {
      // if (activeCrypto) {
        newDeposit()
      // }
    }
  }, [tabopen, openaccordian, currentPage])

  // getting data from transaction id
  const getDataFromTransactionId = () => {
    // newDeposit()
  }

  // for set  active crypto
  const handleActiveCrypto = (cryptoName) => {
    setActiveCrypto(cryptoName)
  }

  return (
    <>
      <div className="row pb-2">
        <div className="col-12 d-none d-md-block">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/deposits.png"
                alt="Deposits"
                className="h-100 w-100 img-fluid "
              />
            </div>
            DEPOSITS
          </div>
        </div>
        {/* <nav className="col-12">
          <div
            className="nav nav-tabs gap-2 pt-md-5 pb-2 flex-nowrap overflow-x-auto text-nowrap reffralsTab border-0"
          >
            {listLoader ?
              cryptoList?.length < 0 ?
                <>
                  <button
                    className={`nav-link refferalBtn ${"ALL" === activeCrypto ? 'active' : ''}`}
                    onClick={() => handleActiveCrypto("ALL")}
                  >
                    ALL 
                  </button>
                  {
                    cryptoList?.map((item) => (
                      <button
                        className={`nav-link refferalBtn ${item?.code === activeCrypto ? 'active' : ''}`}
                        onClick={() => handleActiveCrypto(item?.code)}
                      >
                        {item?.code}
                      </button>
                    ))

                  }
                </>
                : ''
              : (
                <>
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="light" size="lg" />
                </div>
                </>
              )
            }
          </div>
        </nav> */}
        <div className="tab-content col-12 pt-2 pt-md-4">
          <div
            className="tab-pane fade show active"
            id="nav-allChains"
            role="tabpanel"
            aria-labelledby="nav-allChains-tab"
            tabIndex={0}
          >
            {!loader ?
              deposits?.length > 0 ?
                <div className="col-12 pt-3">
                  <div className="table-responsive">
                    <table className="table affiliateTable mb-2 align-middle">
                      <thead>
                        <tr className="tableHead text-nowrap">
                          <th scope="col" className="border-0 rounded-start-2 px-4">
                            SR NO.
                          </th>
                          <th scope="col" className="border-0 px-4">
                            AMOUNT
                          </th>
                          <th scope="col" className="border-0 px-4 texter">
                            DATE/TIME
                          </th>
                          <th scope="col" className="border-0 px-4 texter">
                            IMAGE
                          </th>
                          <th scope="col" className="border-0 px-4 texter">
                            TRANSACTION ID
                          </th>
                          <th
                            scope="col"
                            className="border-0 px-4 text-er rounded-end-2"
                          >
                            STATUS
                          </th>
                        </tr>
                      </thead>
                      <tbody className="tablebody">
                        {deposits?.map((item, index) => (
                          <tr className="text-nowrap" key={item?._id}>
                            <td className="border-0  rounded-start-2 px-4">
                              {index + 1}
                            </td>
                            <td className="border-0 px-4">
                              {item?.amount ? Number(item?.amount).toFixed(2) : '0.00'}
                            </td>
                            <td className="border-0 px-4">{moment(item.createdAt).format("MMM DD YYYY , HH:mm")}</td>
                            <td className="border-0 px-4">

                              <img src={item?.image} 
                                onClick={()=> {
                                  setImgUrl(item?.image)
                                  setShowImgModal(true)
                                }}
                                className="object-fit-contain cursor-pointer" 
                                style={{height: 50, width: 100}} alt="" />
                            </td>
                            <td className="border-0 px-4">{item?.userTransactionId}</td>
                            <td className="border-0 px-4 rounded-end-2">{item?.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {
                totalPages > 1 ? 
                  <DarkPagination 
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
                : ''
              }
                </div>
                :( <div className="d-flex align-items-center justify-content-center">
                  <div className="NoEntry">NO ENTRIES</div>
                </div>)
              : (<div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="light" size="lg" />
              </div>)
              }
          </div>
        </div>
        {/* <div className="col-12 affiliateCardTitle">
          Missing contract ETH deposit?
          <span
            className="affliateProgram text-decoration-none cursor-pointer ps-1"
            onClick={() => setShowTransaction(true)}
          >
            Request deposit address sweep
          </span>
        </div>
        <div className="col-12 pt-3 affiliateCardTitle">
          Missing SOL deposits?
          <span
            className="affliateProgram text-decoration-none cursor-pointer ps-1"
            onClick={() => setShowTransaction(true)}
          >
            Request deposit address sweep
          </span>
        </div> */}
        {showTransaction && (
          <div className="col-12 pt-5">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="transactionCard">
                  <div className="card-header">
                    <label htmlFor="transactionid" className="transactionLabel">
                      ENTER TRANSACTION ID
                    </label>
                    <input
                      type="text"
                      id="transactionid"
                      value={transactionId}
                      onChange={(e) => setTransactionId(e.target.value)}
                      placeholder="Transaction Id"
                      className="transactionInput form-control"
                    />
                  </div>
                  <div className="cardBody">
                    <div className="d-flex align-items-center gap-2">
                      <button
                        className="cancelBtn text-decoration-none border-0"
                        onClick={() => {
                          setTransactionId('')
                          setShowTransaction(false)
                        }}
                      >
                        CANCEL
                      </button>
                      <button
                        onClick={getDataFromTransactionId}
                        className="customBtn shadow-none customBtntwo d-flex align-items-center justify-content-center "
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ImageModal
        showImgModal={showImgModal}
        setShowImgModal={setShowImgModal}
        imgUrl={imgUrl}
        setImgUrl={setImgUrl}
      />
    </>
  );
}

export default Deposits;
