import { useEffect, useState } from "react";
import "./SharingReports.css";
import AddUserModal from '../../Admincomponents/addUserModal/AddUserModal'
import { useSelector } from "react-redux";
import ActionModal from "../../Admincomponents/actionModal/ActionModal";
import { adminGetAPIAuth } from "../../AdminServices";
import { USER } from "../../AdminConstants/apiEndpoints";
import exportToExcel from "../../../utils/exportExcel";
import SharingReportsTable from "../../Admincomponents/sharingReportsTable/SharingReportsTables";
import { useLocation } from "react-router-dom";

const SharingReports = () => {
    const [show, setShow] = useState(false);
    const [showActionModal, setShowActionModal] = useState(false);
    const [actionModalType, setActionModalType] = useState("");
    const [actinModalUser, setActinModalUser] = useState({});
    const [tableData, setTableData] = useState([]);
    console.log("tableData", tableData);
    const [userData, setUserData] = useState({});
    const [hierarchy, setHierarchy] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usernameSort, setUsernameSort] = useState('')
    const [avialableBalSort, setAvialableBalSort] = useState('');
    console.log("usernameSort", usernameSort);
    const [exposerSort, setExposerSort] = useState('')
    const [totalPages, setTotalPages] = useState(null);
    const [searchUser, setSearchUser] = useState("")
    const [searchedUserResult, setSearchedUserResult] = useState([])
    const [selectRole, setSelectRole] = useState('')
    const adminData = useSelector((state) => state?.adminData);
    const { state } = useLocation()
    const [dashboardRole, setDashboardRole] = useState(state?.dashRole)
    const [userLevel, setUserLevel] = useState({})
    const location = useLocation();
    const [errorMsg, setErrMsg] = useState("");

    console.log("adminDataadminData", adminData);



    useEffect(() => {
        setUserLevel(location?.state?.level ? location?.state?.level : '')
        // setHierarchy(location?.state?.asdf ? location?.state?.asdf : '')
    }, [location])

    useEffect(() => {
        if (userLevel?.roleId) {
            getUserDetails(userLevel)
        }
    }, [userLevel])

    // console.log('hierarchy', hierarchy)


    console.log("selectrole", { selectRole })


    const updateHierarchy = (item) => {
        const obj = {
            superAdmin: 4,
            superMaster: 3,
            agent: 2,
            user: 1,
        };
        const data = structuredClone(hierarchy);
        const roles = data.map((el) => el.role);
        data.forEach((el) => (el.roleId = obj[el.role]));
        item.roleId = obj[item.role];

        if (hierarchy.length) {
            for (let i = 0; i < data.length; i++) {
                const el = data[i];
                if (el.roleId < item.roleId) {
                    data.splice(i, 1, item);
                    const sliced = data.slice(0, i);
                    return sliced;
                } else if (el.roleId > item.roleId) {
                    if (!roles.includes(item.role)) {
                        return [...data, item];
                    }
                }
            }
            return [...data];
        } else {
            return [...data, item];
        }
    };

    const getUserDetails = async (item) => {
        try {
            const res = await adminGetAPIAuth(
                USER.profile + "/" + item._id,
            );
            if (res?.data?.status === 200) {
                // const result = userLevel?.roleId ? location?.state?.asdf : updateHierarchy(item);
                const result = updateHierarchy(res.data.data.data);
                console.log('result', { item, data: res.data.data.data })
                setCurrentPage(1)
                setHierarchy(result);
                setUserData(res.data.data.data);
                getChildren(item);
                console.log('hierarchy?.[hierarchy?.length - 1]', hierarchy?.[hierarchy?.length - 1])
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const getChildren = async (item) => {
        try {
            const res = await adminGetAPIAuth(`${USER.adminChildern + "/" + item._id}?page=${currentPage}&avialableBalSort=${avialableBalSort}&usernameSort=${usernameSort}&exposerSort=${exposerSort}&perPage=10`);
            if (res?.data?.status === 200) {
                setTotalPages(res?.data?.data?.data?.pagination?.totalPages);
                setTableData(res?.data?.data?.data?.children);
            }
        } catch (error) {
            console.log("error", error)
            if (error?.response?.data?.data?.message === `Children of ${item.username} not found.`) {
                setTotalPages(null);
                setErrMsg(error?.response?.data?.data?.message);

            }
            setTableData([]);
            setErrMsg(error?.response?.data?.data?.message);

        }
    };

    useEffect(() => {
        if (adminData?._id) {
            // getUserDetails(adminData)   
            getChildren(adminData);
            const result = updateHierarchy(adminData);
            setHierarchy(result);
        }
        //eslint-disable-next-line
    }, [adminData?._id, selectRole, dashboardRole, usernameSort, avialableBalSort, exposerSort, currentPage])

    useEffect(() => {
        if (userData?._id) {
            getChildren(userData);
        }
        //eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        setTableData([adminData]);
        setUserData(adminData);
    }, [adminData]);

    const openActionModal = (type, data) => {
        // console.log("ASDHGFADFS", type);
        setActionModalType(type);
        setActinModalUser(data);
        setShowActionModal(true);
    };

    const handleExport = () => {
        exportToExcel(tableData, userData.username);
    };

    const handleNameSort = () => {
        if (usernameSort === '-1') {
            setUsernameSort('1')
            setAvialableBalSort('')
            setExposerSort('')
        } else {
            setUsernameSort('-1')
            setAvialableBalSort('')
            setExposerSort('')
        }
    }

    const handleAvialableBalSort = () => {
        if (avialableBalSort === '-1') {
            setAvialableBalSort('1')
            setUsernameSort('')
            setExposerSort('')
        } else {
            setAvialableBalSort('-1')
            setUsernameSort('')
            setExposerSort('')
        }
    }

    const handleExposerSort = () => {
        if (exposerSort === '-1') {
            setExposerSort('1')
            setUsernameSort('')
            setAvialableBalSort('')
        } else {
            setExposerSort('-1')
            setUsernameSort('')
            setAvialableBalSort('')
        }
    }

    const filterUser = async (name) => {
        try {
            if (name !== '') {
                const res = await adminGetAPIAuth(`user/userSearch?search=${name}`)
                setSearchedUserResult(res?.data?.data?.data)
                return;
            } else {
                setSearchedUserResult([])
                return;
            }
        } catch (error) {
            setSearchedUserResult([])
        }
    }




    // set current page 1 whenever role changes
    useEffect(() => {
        setCurrentPage(1)
    }, [selectRole])

    return (
        <>
            <div className="app">
                <main className="app-main">
                    <div className="wrapper">
                        <div className="page">
                            <div className="page-inner ">
                                <header Reports
                                    eader className="page-title-bar justify-content-between row">
                                    <h1 className="page-title col-md"> Sharing Reports </h1>
                                    <div className=" page-section align-items-center w-auto m-0  flex-row-reverse col-md-auto"  >
                                        {/* <CustomButton className="w-50 mx-1 d-flex justify-content-center">
                        <span className="mx-2">
                          <i className="bi bi-arrow-clockwise"></i>
                        </span>
                        <span>Refresh</span>
                      </CustomButton>
                      <div className="input-group mb3 mx-1 d-flex h-100 justify-content-evenly align-items-center flex-nowrap">
                        <CustomInput
                          type="text"
                          placeholder="Select User"
                          className="h-100 "
                        />
                        <span className="w-25 d-flex justify-content-center align-item-center">
                          <i className="bi bi-search"></i>
                        </span>
                      </div> */}

                                        {/* 
                      <div class="input-group-prepend">
                      <span className="input-group-text"><i className="bi bi-search"></i></span>
                    </div><input type="text" className="form-control" placeholder="Search"></input>
                      */}

                                        {/* <CustomButton
                        className="w-50 mx-1 d-flex justify-content-center m-0"
                        onClick={() => handleExport()}
                      >
                        <span className="mx-2">
                          <i className="bi bi-download"></i>
                        </span>
                        <span>Excel</span>
                      </CustomButton> */}
                                        {/* <Button onClick={() => handleExport()} className="mx-2" >Export</Button>
                                        <Button onClick={() => setShow(true)}  >Add User</Button> */}
                                        {/* <CustomButton
                        className="mx-1 my-0 d-flex justify-content-center"
                        onClick={() => setShow(true)}
                      >
                        <span className="mx-2">
                          <i className="bi bi-person-add"></i>
                        </span>
                        <span>Add User</span>
                      </CustomButton> */}
                                    </div>
                                </header>

                                {/* <div className="d-flex justify-content-between my-3">
                    <div>
                      <ol className="breadcrumb">
                        {hierarchy?.length && (
                          hierarchy.map((item, index) => (
                            <li className="breadcrumb-item" key={item?._id}>
                              <span
                                onClick={
                                  index === hierarchy?.length - 1
                                    ? () => {}
                                    : () => getUserDetails(item)
                                }
                                className={
                                  index === hierarchy?.length - 1
                                    ? "active-breadcrumb clickable-text"
                                    : "clickable-text"
                                }
                              >
                                {item.username}
                              </span>
                            </li>
                          ))
                        )}
                      </ol>
                    </div>
                  </div> */}

                                {hierarchy.length > 1 && <header className="page-title-bar">
                                    <div className="page-title pt-0">
                                        <nav aria-label="breadcrumb text-nowrap">
                                            <ol class="breadcrumb flex-nowrap overflow-auto pt-0 m-0">
                                                <li class="breadcrumb-item cursor-pointer"
                                                    onClick={() => getUserDetails(hierarchy[0])}
                                                >
                                                    <div className="d-grid align-items-center">
                                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M11.03 2.59a1.501 1.501 0 0 1 1.94 0l7.5 6.363a1.5 1.5 0 0 1 .53 1.144V19.5a1.5 1.5 0 0 1-1.5 1.5h-5.75a.75.75 0 0 1-.75-.75V14h-2v6.25a.75.75 0 0 1-.75.75H4.5A1.5 1.5 0 0 1 3 19.5v-9.403c0-.44.194-.859.53-1.144ZM12 3.734l-7.5 6.363V19.5h5v-6.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 .75.75v6.25h5v-9.403Z"></path></svg>
                                                    </div>
                                                </li>
                                                {hierarchy.length > 1 && hierarchy.map((item, index) => (
                                                    index === 0 ? '' : <li class="breadcrumb-item cursor-pointer"
                                                        onClick={
                                                            index === hierarchy?.length - 1
                                                                ? () => { }
                                                                :
                                                                () => getUserDetails(item)
                                                        }
                                                    > {item.username} </li>
                                                ))}
                                            </ol>
                                        </nav>
                                    </div>
                                </header>}

                                <div className="d-flex gap-3 align-items-end">
                                    <div className="adminSearchInput">
                                        <input
                                            type="text"
                                            placeholder="Search User"
                                            className="form-control adminFilterInp"
                                            value={searchUser}
                                            onChange={(e) => { setSearchUser(e?.target?.value); filterUser(e.target.value) }}
                                        />
                                    </div>

                                </div>

                                <SharingReportsTable
                                    tableData={tableData}
                                    getUserDetails={getUserDetails}
                                    adminData={adminData}
                                    openActionModal={openActionModal}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    getChildren={getChildren}
                                    userData={userData}
                                    parentData={hierarchy?.[hierarchy?.length - 1]}
                                    searchedUserResult={searchedUserResult}
                                    searchUser={searchUser}
                                    showPagination={true}
                                    setSearchUser={setSearchUser}
                                    usernameSort={usernameSort}
                                    avialableBalSort={avialableBalSort}
                                    exposerSort={exposerSort}
                                    handleNameSort={handleNameSort}
                                    handleAvialableBalSort={handleAvialableBalSort}
                                    handleExposerSort={handleExposerSort}
                                    hierarchy={hierarchy}
                                    errorMsg={errorMsg}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <AddUserModal userData={userData} show={show} setShow={setShow} parentData={{ ...hierarchy?.[hierarchy?.length - 1], availablePoints: 100 }} getChildren={getChildren} />
            <ActionModal
                show={showActionModal}
                setShow={setShowActionModal}
                type={actionModalType}
                data={actinModalUser}
                // parentData={hierarchy?.[hierarchy?.length - 1]}
                parentData={userData}
                updateData={() => getUserDetails(hierarchy?.[hierarchy?.length - 1])}
            />
        </>
    );
};

export default SharingReports;
