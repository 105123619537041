export const dummyData = [
    {
        name: "Ind",
        score: "295/7",
        extra_runs: {
            total: "17",
            penalty: "0",
            noballs: "4",
            wides: "6",
            legbyes: "5",
            byes: "2"
        },
        did_not_bat: 'K L Rahul, Suresh Iyer, Surya kumar yadav, Hardik Pandya, Ravindra Jadeja, Jasprit Bumrah, Kuldeep Yadav, Shardul thakur',//don't know what it is 
        batsmen: [
            {
                name: "Rohit Shrama",
                strike_rate: "100.0",
                sixes: "8",
                fours: "5",
                balls: "90",
                runs: "90",
                how_out: "caught behind",
            },
            {
                name: "Subhman Gill",
                strike_rate: "82.0",
                sixes: "1",
                fours: "5",
                balls: "54",
                runs: "34",
                how_out: "Bowled",
            },
            {
                name: "Virat Kholi",
                strike_rate: "80.4",
                sixes: "",
                fours: "6",
                balls: "59",
                runs: "41",
                how_out: "Bowled",
            }
        ],
        bowlers: [
            {
                economy_rate: "4",
                wickets: "5",
                runs: "40",
                maidens: "1",
                overs: "10",
                name: "Jasprit Bumrah",
            },
            {
                economy_rate: "5.1",
                wickets: "2",
                runs: "51",
                maidens: "1",
                overs: "10",
                name: "Kuldeep Yadav",
            },
            {
                economy_rate: "5",
                wickets: "2",
                runs: "40",
                maidens: "1",
                overs: "8",
                name: "Raviendra Jadeja",
            }
        ],
        fall_of_wickets: [
            {
                overs_at_dismissal: "25",
                runs: "121",
                balls: "150",// {elem.runs}-{elem.balls} show them this 
                name: "Rohit Shrama"
            },
            {
                overs_at_dismissal: "36",
                runs: "193",
                balls: "220",// {elem.runs}-{elem.balls} show them this 
                name: "Subhman Gill"
            },
        ]
    },
    {
        name: "SA",
        score: "291/9",
        extra_runs: {
            total: "30",
            penalty: "",
            noballs: "10",
            wides: "10",
            legbyes: "5",
            byes: "5"
        },
        did_not_bat: "Adein Markram, Russie Van der dusan",//don't know what it is 
        batsmen: [
            {
                name: "Quinten deCock",
                strike_rate: "90.00",
                sixes: "4",
                fours: "8",
                balls: "100",
                runs: "90",
                how_out: "Bowled",
            }
        ],
        bowlers: [
            {
                name: "Keshav Maharaj",
                wickets: "3",
                runs: "60",
                maidens: "1",
                overs: "10",
                economy_rate: "6",
            }
        ],
        fall_of_wickets: [
            {
                name: "Quinten deCock",
                runs: "90",
                balls: "100",// {elem.runs}-{elem.balls} show them this 
                overs_at_dismissal: "15",
            },
        ]
    }
]