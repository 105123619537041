import React from "react";
import { useLocation } from "react-router-dom";
import { FacebookShareButton, WhatsappShareButton, TelegramShareButton, TwitterShareButton, InstapaperShareButton, VKShareButton, } from "react-share";

const Sharing = ({ isOpen, setIsOpen }) => {

    const shareUrl = useLocation().pathname
    return (
        <div className={`modal fade  ${isOpen ? "show " : ""} sharemodal`} style={{
            display: isOpen ? "block" : "none",
        }} id="shareModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog shareDialog modal-dialog-centered">
                <div className="modal-content shareContent">
                    <div className="modal-header shareHeader border-0">
                        {/* <div class="modal-title " id="exampleModalLabel">Modal title</div> */}
                        <button onClick={() => setIsOpen(false)} type="button" className=" btn-close shadow-none d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close">
                            <img src="assets/img/slots/close.png" alt="close" className="w-100" />
                        </button>
                    </div>
                    <div className="modal-body shareBody pb-5">
                        <div className="row">
                            <div className="shareHeading text-center col-12">Share This Game</div>
                            <div className="col-md-10 mx-auto mt-4">
                                <div className="d-flex align-items-center gap-3 py-sm-3 py-2 justify-content-center flex-wrap">
                                    <FacebookShareButton title={`For Playing my game click`} separator="😀" url={shareUrl} className="h-100">
                                        <a href="javascript:;" className="socialIcon d-flex align-items-center justify-content-center rounded-circle">
                                            <img src="assets/img/slots/facebook.png" alt="facebook" className="h-100" />
                                        </a>
                                    </FacebookShareButton>
                                    <WhatsappShareButton title={`For Playing my game click`} separator="😀" url={shareUrl} className="h-100">
                                        <a href="javascript:;" className="socialIcon d-flex align-items-center justify-content-center rounded-circle">
                                            <img src="assets/img/slots/whatsapp.png" alt="whatsapp" className="h-100" />
                                        </a>
                                    </WhatsappShareButton>
                                    <TelegramShareButton title={`For Playing my game click`} separator="😀" url={shareUrl} className="h-100">
                                        <a href="javascript:;" className="socialIcon d-flex align-items-center justify-content-center rounded-circle">
                                            <img src="assets/img/slots/telegram.png" alt="telegram" className="h-100" />
                                        </a>
                                    </TelegramShareButton>
                                    <TwitterShareButton title={`For Playing my game click`} separator="😀" url={shareUrl}>
                                        <a href="javascript:;" className="socialIcon d-flex align-items-center justify-content-center rounded-circle">
                                            <img src="assets/img/slots/twitter.png" alt="twitter" className="h-100" />
                                        </a>
                                    </TwitterShareButton>
                                    {/* <InstapaperShareButton title={`For Playing my game click`} separator="😀" url={shareUrl}>
                                        <a href="javascript:;" className="socialIcon d-flex align-items-center justify-content-center rounded-circle">
                                            <img src="assets/img/slots/instagram.png" alt="instagram" className="h-100" />
                                        </a>
                                    </InstapaperShareButton> */}
                                    <VKShareButton title={`For Playing my game click`} separator="😀" url={shareUrl}>
                                        <a href="javascript:;" className="socialIcon d-flex align-items-center justify-content-center rounded-circle">
                                            <img src="assets/img/slots/Vector.png" alt="vk" className="w-100" />
                                        </a>
                                    </VKShareButton>
                                    {/* <a href="javascript:;" className="socialIcon d-flex align-items-center justify-content-center rounded-circle">
                                        <img src="assets/img/slots/skype.png" alt="skype" className="h-100" />
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
          </div> */}
                </div>
            </div>
        </div>

    )
}

export default Sharing