import React, { useState } from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import MatchCricket from './MatchCricket'
import MatchStocks from './MatchStocks'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import { useDispatch } from 'react-redux'
import { currentHomeTabAction } from '../../../store/action'

const MyMatchesPage = () => {
  const tabOptions = ['CRICKET', 'STOCKS', 'MCX']
  const [activeTab, setActiveTab] = useState('CRICKET')
  const dispatch = useDispatch()


  return (

    <>
      <div className="row fantasyInner">
        <FantasyHeader gameHeader={true} title={'My Matches'} />
        <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
          <div className="row mx-0">
            <div className="col-12 gameOptionsTabWrapper sticky-top">
              <div className="row mx-0 align-items-center justify-content-between gameOptionsTabInner">
                {tabOptions?.map((item, index) => (
                  <div
                    onClick={() => {
                      setActiveTab(item)
                      dispatch(currentHomeTabAction(item))
                    }}
                    className='col-4'>
                    <div className={`gameOptionTab text-center text-uppercase ${activeTab === item ? 'active' : ''}`}>
                      {item}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {
              activeTab === 'CRICKET' ? <MatchCricket /> :
                activeTab === 'STOCKS' ? <MatchStocks activeTab={activeTab} /> :
                  activeTab === 'MCX' ? <MatchStocks activeTab={activeTab} /> : ''
            }
            {/* activeTab === 'COMMODITY' ? <Commodity /> : '' */}

          </div>
        </div>
        <FantasyBottomBar />
      </div>
    </>
  )
}

export default MyMatchesPage