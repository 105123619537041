import React, { useEffect } from 'react'
import { useState } from 'react'
import PromotionCard from './PromotionCard'
import { getAPI } from '../../service/apiInstance'
import { Spinner } from 'react-bootstrap'
import { useAuth } from '../../hooks/useAuth'
import { getAPIAuth } from '../../service/apiInstance'

const ArchiveTab = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1);
    const [jackpotdata , setJackpotData] = useState([])
   const { token } = useAuth();
  
  const getArchivedPromotions = async () => {
    setLoading(true)
    try {
      const res = await getAPI(`get-promotion-data?isArchived=true&page=1&limit=6`)
      if (res?.data?.data.success) {
        setData(res?.data?.data.data)
        setPageCount(res?.data?.pageCount)
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getArchivedPromotions()
  }, [])

  const getMoraData = async () => {
    try {
      const res = await getAPI(`get-promotion-data?isArchived=true&page=${page}&limit=6`)
      if (res?.data?.data.success) {
        setData(pre => ([...pre, ...res?.data?.data.data]))
      } else {
        setData(data)
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    if (page >= 2) {
      getMoraData()
    }
  }, [page]);
      const getJackpotGetAll = async () => {
    if (token) {
      try {
        const res = await getAPIAuth("user/getAllJackpots", token);
        console.log("jackpotres", res);
        if (res?.data?.data?.success) {
          setJackpotData(res?.data?.data?.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

    useEffect(() => {
    getJackpotGetAll();
  }, [token]);

  return (
    <div className="row g-3">
         {!loading ?
        jackpotdata?.length > 0 ?
          jackpotdata?.map((item) => (
            <PromotionCard jackpotdata={item} />
          ))
          : (
            <div className="w-100 h-100 text-light d-flex justify-content-center align-items-center">
              No data available
            </div>
          )
        : (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="light" size="lg" />
          </div>
        )
      }

{
        page >= pageCount ? (
          <></>
        ) : (
          <div className="col-12 mt-5 text-center">
            <button onClick={() => setPage(pre => pre + 1)} className="loadMoreBtn">LOAD MORE</button>
          </div>
        )
      }
    </div>
  )
}

export default ArchiveTab