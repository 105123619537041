import React, { useEffect, useState } from "react";
import { getAPIAuth } from "../../../service/apiInstance";
import { useSelector } from "react-redux";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import DarkPagination from "../../darkPagination/DarkPagination";

function Withdraw({ tabopen, openaccordian }) {
  const [cryptoList, setCryptoList] = useState([])
  const [activeCrypto, setActiveCrypto] = useState('ALL')
  const [withdraws, setWithdraws] = useState([])
  const [transactionId, setTransactionId] = useState('')
  const [loader, setLoader] = useState(false)
  const [listLoader, setListLoader] = useState(true)
  const userDetails = useSelector((state) => state.userDetails);
  const userId = userDetails?.id;
  const userBalance = userDetails?.totalbalance;
  const [showTransaction, setShowTransaction] = useState(false)
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  // getting all crypto id
  const getCryptoList = async () => {
    setListLoader(true)
    try {
      const res = await getAPIAuth(`crypto/get-crypt-favorite-by-admin`)
      if (res?.data?.success) {
        setCryptoList(res?.data?.data)
        setActiveCrypto("ALL")
      }
    } catch (error) {

    } finally {
      setListLoader(false)
    }
  }

  useEffect(() => {
    // getCryptoList()
  }, [])


  const getWithdraws = async () => {
    setLoader(true)
    try {
      // if (userId) {
        // const res = await getAPIAuth(`user/find-transaction?userId=${userId}&chain=${activeCrypto}&type=withdraw&transactionId=${transactionId}`)
        const res = await getAPIAuth(`user/get-withdraw-list?perPage=${itemsPerPage}&transactionId=${transactionId}&page=${currentPage}`)
        console.log('res', res)
        if (res?.data?.data?.success) {
          setWithdraws(res?.data?.data?.data?.fetchDetails)
          setTotalPages(res?.data?.data?.data?.pagination?.totalPages)
          setTransactionId('')
        } else {
          setWithdraws([])
        }
      // }
    } catch (error) {

    } finally {
      setLoader(false)
    }
  }

  // 
  useEffect(() => {
    if (openaccordian === "Withdrawals" || tabopen === "Withdrawals") {
      // if (activeCrypto) {
        getWithdraws()
      }
    // }
  }, [currentPage, transactionId, tabopen, openaccordian])
  // getting data from transaction id
  const getDataFromTransactionId = () => {
    getWithdraws()
  }

  // for set  active crypto
  const handleActiveCrypto = (id) => {
    setActiveCrypto(id)
  }

  return (
    <>
      <div className="row pb-2">
        <div className="col-12 d-none d-md-block">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/withdraw.png"
                alt="WITHDRAWALS"
                className="h-100 w-100 img-fluid "
              />
            </div>
            WITHDRAWALS
          </div>
        </div>
        {/* <nav className="col-12">
          <div
            className="nav nav-tabs gap-2 pt-md-5 pb-2 flex-nowrap overflow-x-auto text-nowrap reffralsTab border-0"
          >
            {!listLoader ?
              cryptoList?.length > 0 ?
                (
                  <>
                    <button
                      className={`nav-link refferalBtn ${"ALL" === activeCrypto ? 'active' : ''}`}
                      onClick={() => handleActiveCrypto("ALL")}
                    >
                      ALL
                    </button>
                    {
                      cryptoList?.map((item) => (
                        <button
                          className={`nav-link refferalBtn ${item?._id === activeCrypto ? 'active' : ''}`}
                          onClick={() => handleActiveCrypto(item?._id)}
                        >
                          {item?.code}
                        </button>
                      ))
                    }
                  </>
                )
                : (
                  <>
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    No data available
                  </div>
                  </>
                )
              : (
                <>
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="light" size="sm" />
                </div>
                </>
              )
            }

          </div>
        </nav> */}
        <div className="tab-content col-12 pt-2 pt-md-4">
          <div
            className="tab-pane fade show active"
            id="nav-allChains"
            role="tabpanel"
            aria-labelledby="nav-allChains-tab"
            tabIndex={0}
          >
            {!loader ?
              withdraws?.length > 0 ?
                <div className="col-12 pt-3">
                  <div className="table-responsive">
                    <table className="table affiliateTable mb-2 align-middle">
                      <thead>
                        <tr className="tableHead text-nowrap">
                          <th scope="col" className="border-0 rounded-start-2 px-4">
                            SR NO.
                          </th>
                          <th scope="col" className="border-0 px-4">
                            ACCOUNT HOLDER NAME
                          </th>
                          <th scope="col" className="border-0 px-4">
                            ACCOUNT NO./ UPI ID
                          </th>
                          <th scope="col" className="border-0 px-4 texter">
                            AMOUNT
                          </th>
                          <th
                            scope="col"
                            className="border-0 px-4 text-er rounded-end-2"
                          >
                            DATE/TIME
                          </th>
                        </tr>
                      </thead>
                      <tbody className="tablebody">
                        {withdraws?.map((item, index) => (
                          <tr className="text-nowrap" key={item?._id}>
                            <td className="border-0  rounded-start-2 px-4">
                              {index + 1}
                            </td>
                            <td className="border-0 px-4">{item?.accountHolderName}</td>
                            <td className="border-0 px-4">{item?.accountNumber ? item?.accountNumber : item?.upi}</td>
                            <td className="border-0 px-4">{item?.amount ? Number(item?.amount).toFixed(2): '0.00'}</td>
                            <td className="border-0 px-4">{moment(item.createdAt).format("MMM DD YYYY , HH:mm")}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>         {
                totalPages > 1 ? 
                  <DarkPagination 
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
                : ''
              }
                </div>
                : (<div className="d-flex align-items-center justify-content-center">
                  <div className="NoEntry">NO ENTRIES</div>
                </div>)
              : (
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="light" size="sm" />
                </div>
              )}
          </div>
        </div>
        {/* <div className="col-12 affiliateCardTitle">
          Missing contract ETH withdraw ?
          <span
            className="affliateProgram text-decoration-none cursor-pointer"
            onClick={() => setShowTransaction(true)}
          >
            Request withdraw address sweep
          </span>
        </div> */}
        {/* <div className="col-12 pt-3 affiliateCardTitle">
          Missing SOL withdraws?
          <span
            className="affliateProgram text-decoration-none cursor-pointer"
            onClick={() => setShowTransaction(true)}
          >
            Request withdraw address sweep
          </span>
        </div> */}
        {
          showTransaction ? (
            <div className="col-12 pt-5">
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <div className="transactionCard">
                    <div className="card-header">
                      <label htmlFor="transactionid" className="transactionLabel">
                        ENTER TRANSACTION ID
                      </label>
                      <input
                        type="text"
                        id="transactionid"
                        value={transactionId}
                        onChange={(e) => setTransactionId(e.target.value)}
                        placeholder="Transaction Id"
                        className="transactionInput form-control"
                      />
                    </div>
                    <div className="cardBody">
                      <div className="d-flex align-items-center gap-2">
                        <button
                          onClick={() => setShowTransaction("")}
                          className="cancelBtn border-0 text-decoration-none"
                        >
                          CANCEL
                        </button>
                        <button
                          onClick={getDataFromTransactionId}
                          className="customBtn shadow-none customBtntwo d-flex align-items-center justify-content-center "
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )
        }

      </div>
    </>
  );
}

export default Withdraw;
