import React, { useEffect, useState } from "react";
import { errorToaster } from "../../utils/toaster";
import { useSignInModal } from "../../hooks/useSignInModal";
import { useSignUpModal } from "../../hooks/useSignUpModal";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../common/ErrorMessage/ErrorMessage";

const validationSchema = Yup.object({
    // name: Yup.string().required(),
    emailOrNumber: Yup.string()
        .required()
        .matches(
            // /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
            // /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{6,}$/i,
            // /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
            "Invalid email"
        ),
    password: Yup.string().required(),
});


const MetaMaskButton = ({ isOpen, setIsOpen }) => {
    // const [isOpen, setIsOpen] = useState(false)

    const { setIsSignInOpen } = useSignInModal()
    const { setIsSignUpOpen } = useSignUpModal()
    const metamaskHandler = () => {
        if (window.ethereum) {
            window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(res => {
                    // Return the address of the wallet
                    // alert('eht herre')

                    console.log(res)
                })
        } else {
            // alert('no eth')
            errorToaster("Wallet not available")
        }
    }

    return (
        <>
            {/* <a
                href="javascript:;"
                className="col-auto d-flex"
            >
                <div className="link">
                    <img
                        //  onClick={metamaskHandler} 
                        onClick={() => setIsOpen(true)}
                        src="assets/img/link3.png" alt=""
                    />
                </div>
            </a> */}
            <div
                className={`modal fade ${isOpen ? "show " : ""}`}
                id="metamask Modal"
                style={{
                    display: isOpen ? "block" : "none",
                }}
            >
                <div className="modal-dialog modal-dialog-centered w-50">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="btn-close btnClose"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="row align-items-center">

                                <div className="col-lg-12 align-items-center">
                                    <Formik
                                        initialValues={{
                                            email: "",
                                            password: ""
                                        }}
                                        validationSchema={validationSchema}
                                    >
                                        {
                                            ({
                                                values,
                                                errors,
                                                touched,
                                                handleSubmit,
                                                handleChange,
                                                handleBlur,

                                            }) => (
                                                <form className="row mx-0 form gap-3">
                                                    <div
                                                        className="col-12 fw-bold text-white registerHeading mb-3 d-lg-block d-none"
                                                    >
                                                        Metamask
                                                    </div>
                                                    <div
                                                        className="col-12">
                                                        <input
                                                            type="text"
                                                            className="form-control border-0 shadow-none"
                                                            id="email"
                                                            placeholder="Email"
                                                            name="email"
                                                            value={values.email}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <ErrorMessage>
                                                        {errors.email && touched.email && errors.email}
                                                    </ErrorMessage>
                                                    <div className="col-12 position-relative">
                                                        <input
                                                            type="text"
                                                            className="form-control border-0 shadow-none"
                                                            id="password"
                                                            placeholder="Login Password"
                                                            name="password"
                                                            value={values.password}
                                                            onChange={handleChange}
                                                        />
                                                        <div className="eye">
                                                            <img src="assets/img/eyeIcon.png" alt="" />
                                                        </div>
                                                        <ErrorMessage>
                                                            {errors.password && touched.password && errors.password}
                                                        </ErrorMessage>
                                                    </div>
                                                    <div className="col-12 mx-md-0 mx-auto">
                                                        <div className="col-12 mx-auto">
                                                            <button type="button"
                                                                className="btn ragisterBtn w-100 border-0"
                                                                onClick={metamaskHandler}
                                                            >
                                                                Continue
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )
                                        }

                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MetaMaskButton;