import React, { useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import Pagination from '../../Admincomponents/pagination/Pagination'
import reload from './../../AdminAssets/img/reload.svg'
import { adminGetAPIAuth } from '../../AdminServices'
import { returnEndDate } from '../../../utils/returnEndDate'
import { errorToaster } from '../../../utils/toaster'

const CasinoReport = () => {
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [searchGameName, setSearchGameName] = useState('');
  const [filterValues, setFilterValues] = useState({
    transactions: "",
    startDate: "",
    endDate: "",
  });
  const  [searchlistArr, setsearchlistArr] =useState();
  const  [selectSearchId, setSelectsearchId] =useState('');
  const [inputFocused, setInputFocused] = useState(false);

  // set today's date as end date
  useEffect(() => {
    const today = new Date()
    setFilterValues((prev) => ({
      ...prev,
      endDate: returnEndDate(today)
    }))
  }, [])

  const getCasinoReport = async () => {
    setLoader(true)
    const queryParams = Object.entries(filterValues)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    try {
      const res = await adminGetAPIAuth('casino/casinoAllUserReport' + `?gameCode=${selectSearchId}&page=${currentPage}&perPage=10` + (queryParams ? "&" + queryParams : ""));
      console.log('res', res)
      if (res?.data?.data?.success) {
        setData(res?.data?.data?.data);
        setTotalPages(res?.data?.data?.pagination?.totalPages)
      } else {
        errorToaster("Something went wrong in fetching data");
      }
    } catch (error) {
      errorToaster(error?.response?.data?.data?.message);
      setData([]);
    } finally {
      setLoader(false)
    }
  }
  useEffect(() => {
    getCasinoReport();
    if(!searchGameName){
      setSelectsearchId("");
    }
  }, [
    filterValues.endDate,
    filterValues.startDate,
    currentPage,
    selectSearchId,searchGameName
  ])

  const handleChange = (e) => {
    setFilterValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    getALLCasinoReport()
  }, [
    filterValues.endDate,
    filterValues.startDate,
    currentPage,
    searchGameName
  ]);

 const handleInputBlur = () => {
  setTimeout(() => {
    setInputFocused(false);
  }, 500);
};
  const getALLCasinoReport = async () => {
    setLoader(true)
    const queryParams = Object.entries(filterValues)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    try {
      // const res = await adminGetAPIAuth('casino/allCasinoGames' + `?page=${currentPage}&perPage=10&name=${searchGameName}` + (queryParams ? "&" + queryParams : ""));
      const res = await adminGetAPIAuth('casino/allCasinoGames' + `?name=${searchGameName}`);
      console.log('res', res)
      if (res?.data?.data?.success) {
        setsearchlistArr(res?.data?.data?.data);
        // setTotalPages(res?.data?.data?.pagination?.totalPages)
      } else {
        errorToaster("Something went wrong in fetching data");
      }
    } catch (error) {
      // errorToaster(error?.response?.data?.data?.message);
      setsearchlistArr();
    } finally {
      setLoader(false)
    }
  }

console.log(searchGameName,searchlistArr)
  return (
    <>
      <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner ">

                <header className="page-title-bar justify-content-between row align-items-center">
                  <h1 className="page-title mb-0 text-nowrap"> Casino Report </h1>
                </header>
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row align-items-end">
                      <div className="col-md-auto pe-md-0 mb-2 mb-md-0">
                        <div className="adminSearchInput">
                          <input type="text" placeholder='Search User' value={searchGameName} onChange={(e)=>setSearchGameName(e.target.value)} className='form-control adminFilterInp' 
                          onFocus={() => setInputFocused(true)}
                          onBlur={handleInputBlur}
                        />
                        <div className="overflow-hidden">
                          {searchGameName && inputFocused ? (
                            <>
                              <ul className="list-unstyled adminsearchDropDown overflow-Y-auto">
                                {
                                  searchlistArr?.map((item, i) => (
                                    <li
                                      className="userName"
                                      key={i}
                                      onClick={() => {
                                        console.log('item', item)
                                        // setSearchUser(item.username);
                                        // setTxWith(item.username)
                                        // setSelectUser(item);
                                        // handleGetList(item);
                                        setSelectsearchId(item.gameCode)
                                        setSearchGameName(item.name)
                                      }}
                                    >
                                      {item.name}
                                    </li>
                                  ))
                                }
                              </ul>
                              {/* <div onClick={()=>setSearchUser('')} className="searchLayer position-fixed top-0 start-0 h-100 w-100 bg-danger"></div> */}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="row justify-content-end mx-md-0">
                          {/* <div className="col-md-auto col-12 col-sm-4 pe-md-0 mb-2 mb-sm-0">
                            <label htmlFor="" className="form-label">Transactions</label>
                            <select name="" id="" className="form-select adminFilterInp">
                              <option value="">All</option>
                            </select>
                          </div> */}
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="" className="form-label">From</label>
                            <input type="date" id="startDate" name="startDate" value={filterValues.startDate} onChange={handleChange} className='form-control adminFilterInp' />
                          </div>
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="" className="form-label">To</label>
                            <input type="date" id="endDate" name="endDate" value={filterValues?.endDate} onChange={handleChange} className='form-control adminFilterInp' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead className='text-uppercase text-nowrap'>
                          <tr>
                            <th>
                              <div className="d-flex">
                                sr.No
                                <span className='ms-1'>
                                  {/* <i className="bi bi-sort-alpha-down"></i> */}
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Game Name
                                <span className='ms-1'>
                                  {/* <i className="bi bi-sort-alpha-down"></i> */}
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                User Name
                                <span className='ms-1'>
                                  {/* <i className="bi bi-sort-alpha-down"></i> */}
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Total Debit
                                <span className='ms-1'>
                                  {/* <i className="bi bi-sort-alpha-down"></i> */}
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                totalCredit
                                <span className='ms-1'>
                                  {/* <i className="bi bi-sort-alpha-down"></i> */}
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>

                            <th>
                              <div className="d-flex">
                                transaction Id
                                <span className='ms-1'>
                                  {/* <i className="bi bi-sort-alpha-down"></i> */}
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>

                            <th>
                              <div className="d-flex">
                                Profit
                                <span className='ms-1'>
                                  {/* <i className="bi bi-sort-alpha-down"></i> */}
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>

                            <th>
                              <div className="d-flex">
                                Provider Id
                                <span className='ms-1'>
                                  {/* <i className="bi bi-sort-alpha-down"></i> */}
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            !loader ?
                              data?.length > 0 ?
                                data?.map((item, index) => (
                                  <tr>
                                    <td className='align-middle fw-medium'>{index + 1}</td>
                                    <td className='align-middle fw-medium'>{item?.gameName}</td>
                                    <td className='align-middle fw-medium'>{item?.userName}</td>
                                    <td className={`align-middle fw-medium `}>{item?.totalDebit}</td>
                                    <td className='align-middle fw-medium'>{item?.totalCredit}</td>
                                    <td className='align-middle fw-medium'>{item?.transaction_id}</td>
                                    <td className='align-middle fw-medium'>{item?.profit}</td>
                                    <td className='align-middle fw-medium'>{item?.provider_id}</td>

                                  </tr>
                                ))
                                :
                                // no data
                                <tr>
                                  <td className='align-middle text-center fw-medium' colSpan={12}>No Data</td>
                                </tr>
                              :
                              // loader
                              <tr>
                                <td className='align-middle text-center fw-medium' colSpan={6}>
                                  <Spinner size="sm" color="dark" />
                                </td>
                              </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {
                    totalPages > 1 ?
                      <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      /> : ''
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default CasinoReport