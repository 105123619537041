import React, { useEffect, useState } from 'react'
import Banner from './../../fantasyComponents/banner/Banner'
import Cricket from './Cricket'
import Stocks from './Stocks'
import Mcx from './Mcx'
import Commodity from './Commodity'
import { getFantasyAuth } from '../../fantasyServices'
import { useDispatch, useSelector } from 'react-redux'
import { currentHomeTabAction } from '../../../store/action'
import { useAuth } from '../../../hooks/useAuth'

const OptionTab = () => {
  const [tabOptions, setTabOptions] = useState([])
  const [activeTab, setActiveTab] = useState('CRICKET')
  const currentHomeTab = useSelector(state => state?.currentHomeTab)
  const dispatch = useDispatch()
  const {token} = useAuth()
  
  const getHeaderCats = async () => {
    try {
      const res = await getFantasyAuth("get-stock-contest-category")
      if (res.data.success) {
        setTabOptions(res.data.data.sort((a, b) => a.order - b.order))
      }
    } catch (error) {
    }
  }
  console.log(tabOptions)
  useEffect(() => {
    if(token){
      getHeaderCats()
      dispatch(currentHomeTabAction("CRICKET"))
    }
  }, [token])

  return (
    <>
      <div className="col-12 gameOptionsTabWrapper sticky-top">
        <div className="row mx-0 align-items-center justify-content-between gameOptionsTabInner">
          {tabOptions?.map((item, index) => (
            <div
              onClick={() => {
                dispatch(currentHomeTabAction(item.name))
                // setActiveTab(item.name)
              }}
              className={`gameOptionTab col-3 text-center text-uppercase ${currentHomeTab === item.name ? 'active' : ''}`}>{item.name}</div>
          ))}
        </div>
      </div>
      <Banner />
      {
        currentHomeTab === 'CRICKET' ? <Cricket /> :
          currentHomeTab === 'STOCKS' ? <Stocks activeTab={currentHomeTab} /> : ""
        // activeTab === 'MCX' ? <Mcx /> :
        // activeTab === 'COMMODITY' ? <Commodity /> : ''
      }
    </>
  )
}

export default OptionTab