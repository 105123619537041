import React from 'react'
import AppFooter from '../../../components/AppFooter/AppFooter'
import downArrow from './../../TradingAssets/img/downarrowtable.svg'
import redArrow from './../../TradingAssets/img/redicon.svg'
import greenArrow from './../../TradingAssets/img/greenicon.svg'
import closeIcon from './../../TradingAssets/img/closeicon.svg'
import searchIcon from './../../TradingAssets/img/search.png'
import plusAdd from './../../TradingAssets/img/plus.svg'

import './../../TradingAssets/scss/tradingcommon.scss'




const TradingBlockedScripted = () => {
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage tradingPages">
            {/* start here */}

          <div class="row gap-4 blockedScriptWrapper">
            <div class="col-12">
              <div className='BlockedHeading'>Banned/Blocked Script</div>
            </div> 
            <div class="col-12 pb-4 pt-2">
              <div className='card-body cardBody'>
                <div className='row'>
                  <div className='col-6'>
                    <div className='BlockedSubHeading'>NMDC-NSEFUT</div>
                  </div>
                  <div className='col-6'>
                    <div className='BlockedSubHeading text-danger'>BLOCKED BY UPLINE</div>
                  </div>
                </div>
                <div className='row py-3'>
                  <div className='col-6'>
                    <div className='BlockedSubHeading'>GAIL-NSEFUT</div>
                  </div>
                  <div className='col-6'>
                    <div className='BlockedSubHeading text-danger'>BLOCKED BY UPLINE</div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <div className='BlockedSubHeading'>IDEA-NSEOPT</div>
                  </div>
                  <div className='col-6'>
                    <div className='BlockedSubHeading text-danger'>BLOCKED BY UPLINE</div>
                  </div>
                </div>
              </div>
            </div>
          </div>




            {/* end here */}
          </div>
        </div>
        {/*  **** Footer section *** */}
        <AppFooter />
      </main>
    </>
  )
}

export default TradingBlockedScripted