import React, { useEffect, useState } from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import CustomStockList from './CustomStockList'
import NormalStockList from './NormalStockList'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { errorToaster, succesToaster } from '../../../utils/toaster'
import ErrorMessage from '../../../components/common/ErrorMessage/ErrorMessage'
import { postFantasyAuth } from '../../fantasyServices'

const StockList = () => {
  const [isCustom, setIsCustom] = useState(false)
  const companyData = useLocation().state?.companyData
  const stockContest = useLocation().state?.data
  const editflag = useLocation().state?.editflag
  const [selectCoin, setSelectCoin] = useState(null)
  // const [distribution1,setDistribution1] = 
  const [distribute2, setDistribute2] = useState(0);
  const [distribute1, setDistribute1] = useState(0);
  const [distribution1, setDistribution1] = useState(null);
  const [distribution2, setDistribution2] = useState(null);
  const [percent1, setpercent1] = useState(5);
  const [percent2, setpercent2] = useState(5);
  const navigate = useNavigate()
  console.log("companyData", { companyData })

  const handleSelectCoin = id => {
    setSelectCoin(id);
    companyData[0].percentage = 5
    companyData[1].percentage = 5
    // console.log({ id });

    if (!!companyData[0]?.percentage && !!companyData[1].percentage) {
      if (id == 1) {
        setDistribution1('50.00%');
        setDistribution2('50.00%');

        companyData[0].percentage = 50;
        companyData[1].percentage = 50;
      } else if (id == 2) {
        setDistribution1('95.00%');
        setDistribution2('5.00%');
        companyData[0].percentage = 95;
        companyData[1].percentage = 5;
      } else if (id == 3) {
        setDistribution1('5.00%');
        setDistribution2('95.00%');
        companyData[0].percentage = 5;
        companyData[1].percentage = 95;
      }
    }

  };
  const EditStocks = async () => {
    try {

      let teamParams = {
        stock: [
          {
            stockId: companyData[0]?._id,
            percentage: companyData[0]?.percentage,
            instrument_token: companyData[0]?.instrument_token,
          },
          {
            stockId: companyData[1]?._id,
            percentage: companyData[1]?.percentage,
            instrument_token: companyData[1]?.instrument_token,
          },
        ],
        teamnumber: 1,
        contestId: stockContest?._id,
        stock_type: stockContest?.type,
      };
      const createTeam = await postFantasyAuth('/stock-create-team', teamParams);

      if (createTeam?.data?.status) {
        succesToaster(createTeam.message);
        console.log("hello", { createTeam })
        navigate(ROUTES_CONST.FANTASY_HOME, { stockContest: stockContest });
      } else {
        errorToaster(createTeam.message);
        navigate(ROUTES_CONST.FANTASY_HOME, { stockContest: stockContest });
      }
    } catch (error) {

    }
  };


  useEffect(() => {
    if (!!companyData) {
      handleSelectCoin(1)
    }
  }, [companyData])
  return (
    <>
      <div className="row fantasyInner">
        <FantasyHeader gameHeader={true} StockList={true} setIsCustom={setIsCustom} isCustom={isCustom} title={'Your Stock List'} />
        <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
          {
            isCustom ? (
              <CustomStockList
                companyData={companyData}
                distribute2={distribute2}
                distribute1={distribute1}
                stockContest={stockContest}
                percent1={percent1}
                percent2={percent2}
                setpercent1={setpercent1}
                setpercent2={setpercent2}
                setDistribute2={setDistribute2}
                setDistribute1={setDistribute1}
                EditStocks={EditStocks}
                editflag={editflag}
              />
            ) : (
              <NormalStockList
                distribution2={distribution2}
                distribution1={distribution1}
                companyData={companyData}
                handleSelectCoin={handleSelectCoin}
                selectCoin={selectCoin}
                setSelectCoin={setSelectCoin}
                stockContest={stockContest}
                EditStocks={EditStocks}
                editflag={editflag}
              />
            )

          }
        </div>
      </div>
    </>
  )
}

export default StockList