import { getFantasyAuth } from "."

export const fetchMatchList = async () => {
    try {
        const res = await getFantasyAuth("getmatchlist")
        return res
    } catch (error) {
        throw new Error(error)
    }
} 
