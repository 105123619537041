import React, { useState } from 'react'
import AppFooter from '../../../components/AppFooter/AppFooter'
import { postTradingAPI } from '../../TradingServices'
import ProfitLossItemList from './ProfitLossItemList'
import { useEffect } from 'react'

const TradingProfitLoss = () => {
  const [profiLossData, setProfitLossData] = useState([])
  const [profiLossLoading, setProfitLossLoading] = useState(false)
  const [tradeStartDate, setTradeStartDate] = useState("");
  const [tradeEndDate, setTradeEndDate] = useState("");

  // Date format Method
  let parts = tradeStartDate?.split("-");
  const startFormattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  let parts1 = tradeEndDate?.split("-");
  const endFormattedDate = `${parts1[2]}-${parts1[1]}-${parts1[0]}`;

  const todayDate = new Date();
  const formattedToday = `${todayDate.getDate()}-${todayDate.getMonth() + 1}-${todayDate.getFullYear()}`;

  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(todayDate.getDate() - 1);
  const formattedYesterday = `${yesterdayDate.getDate()}-${yesterdayDate.getMonth() + 1}-${yesterdayDate.getFullYear()}`;

  // get trading history data
  const getProfitLossData = async (e) => {
    try {
      if (tradeStartDate.length > 0 && tradeEndDate.length > 0) {
        e.preventDefault();
        setProfitLossLoading(true);
        const body = {
          action: "profit-loss",
          trade_start_date: startFormattedDate,
          trade_end_date: endFormattedDate,
        };
        const res = await postTradingAPI("profit-loss.php", body);
        const response = await res.json();
        setProfitLossData(response.data);
      } else {
        setProfitLossLoading(true);
        const body = {
          action: "trade-history",
          trade_start_date: formattedYesterday,
          trade_end_date: formattedToday,
        };
        const res = await postTradingAPI("profit-loss.php", body);
        const response = await res.json();
        setProfitLossData(response.data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
        setProfitLossLoading(false);
    }
  };

  useEffect(() => {
    getProfitLossData();
  }, []);

  console.log("profiLossData", profiLossData);

  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage tradingPages">
            {/* start here */}
            <div className="row">
              <div className="col-12">
                <div className="row gap-3">
                  <div className="col-12 px-0">
                    <div className="row g-3 align-items-center ">
                      <div className="col-auto">
                        <div className="tradingSecHead">Trading Profit And Loss</div>
                        <div>
                          <form
                            onSubmit={getProfitLossData}
                            className="d-flex gap-3 mt-2"
                          >
                            <input
                              type="date"
                              class="dateTimeInput"
                              value={tradeStartDate}
                              onChange={(e) =>
                                setTradeStartDate(e.target.value)
                              }
                            />
                            <input
                              type="date"
                              class="dateTimeInput"
                              value={tradeEndDate}
                              onChange={(e) => setTradeEndDate(e.target.value)}
                            />
                            <div className="btnClass">
                              <button class="sumbitBtn" type="submit">
                                Search
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 px-0 dambleTable sectionInner tableCard tab-content tab-pane table-responsive mb-0 mt-4">
                <table className="table align-middle text-white text-nowrap mb0">
                  <thead>
                    <tr>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Sr No.
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          TRADING SYMBOL
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                         Profit Loss Amount
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                        Profit Loss Type
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          MIS CNC
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Instrument Token
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Added Date
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!profiLossLoading
                      ? profiLossData?.length > 0
                        ? profiLossData.map((item, index) => (
                            <ProfitLossItemList data={item} index={index} />
                          ))
                        : ""
                      : "loading"}
                  </tbody>
                </table>
              </div>
            </div>

            {/* end here */}
          </div>
        </div>

        {/*  **** Footer section *** */}
        <AppFooter />
      </main>
    </>
  )
}

export default TradingProfitLoss
