import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES_CONST } from "../../constants/routeConstant";
import { useWalletModal } from "../../hooks/useWallet";
import { useSelector } from "react-redux";
import { postAPIAuth } from "../../service/apiInstance";
import { succesToaster } from "../../utils/toaster";
import { useAuth } from "../../hooks/useAuth";
import { AUTH_MODAL_TABS, WALLET_MODAL_TABS } from "../../constants";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useDownloadModal } from "../../hooks/useDownload";
import { socket } from "../../service/socket";
import { useTranslation } from "react-i18next";
import SupportOffcanvas from "../supportOffcanvas/SupportOffcanvas";

const AppFooter = () => {
  const [feedback, setFeedback] = useState("");
  const [showSupport, setshowSupport] = useState(false);
  const { setWalletModalObject } = useWalletModal()
  const userDetails = useSelector((state) => state.userDetails);
  const { token } = useAuth()
  const { setAuthModalObject } = useAuthModal();
  const { setDownloadObject } = useDownloadModal()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const footerLabel = t("appFooter", { returnObjects: true })
  // function for opening modal
  const handleModal = () => {
    if (token) {
      setWalletModalObject((prev) => ({
        isWalletOpen: true,
        selectedTab: WALLET_MODAL_TABS.DEPOSIT
      }))
    } else {
      setAuthModalObject((pre) => ({
        isAuthOpen: true,
        selectedTab: AUTH_MODAL_TABS.LOG_IN,
      }))
    }
  }
  const FeedbackFun = async () => {
    const body = {
      userId: userDetails.id,
      message: feedback,
    };
    const res = await postAPIAuth("user/add-feedback", body);
    if (res.data?.status) {
      succesToaster(res.data?.message);
    }
  };



  const sideAppHandler = () => {
    const exists = document.body.classList

    if (exists["value"] === "asideBar") {
      document.body.classList.remove("asideBar")
    } else {
      document.body.classList.add("asideBar")
    }
  }

  return (
    <>
    <div className="footerSection ">
      <div className="container-fluid footer footerLinks footerFluid pt-5">
        <div className="row mx-0">
          <div className="col-3 d-none d-lg-block">
            <div className="row">
              <div className="col-12 d-flex justify-content-start">
                <div className="footerLogo"><img src="assets/img/heroLogo.png" className="object-fit-contain" alt="" /></div>
              </div>
              <div className="col-12">
                <div className="para">Bx is a brand name of Vision  I-Gaming N.V. Company Address: Zuikertuintjeweg z/n (Zuikertuin Tower), Willemstad, Curaçao. Bx is licensed and authorized by the Government of Curaçao and operates under the Master License of Gaming Services Provider, N.V. #…….</div>
              </div>
            </div>
          </div>
          <div className="col-3 d-none d-lg-flex justify-content-center align-items-center">
            <div className="midLogo row align-items-center">
              <div className="col-12 d-flex px-0">
                <div className="gc me-4"><img src="assets/img/footer/gc.png" alt="" /></div>
                <div className="plus"><img src="assets/img/footer/eighteenplus.png" alt="" /></div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-6 col-sm-4">
                <ul className="linksUl list-unstyled">
                  <li className="linkLiHead">{footerLabel.Legal}</li>
                  <li className="">
                    <Link to={ROUTES_CONST.AFFILIATE_PAGE}
                      className="linkLi text-decoration-none">{footerLabel.Affiliate_Program}</Link></li>
                  <li className=""> <span className="linkLi text-decoration-none">{footerLabel.FAQ}</span></li>
                  <li className="d-lg-none"> <Link to={ROUTES_CONST.DOWNLOAD_APP} className="linkLi text-decoration-none">{footerLabel.DownloadApp}</Link></li>
                  <li className="d-none d-lg-block"> <div onClick={() => setDownloadObject({
                    isDownloadOpen: true
                  })} className="linkLi text-decoration-none d-inline cursor-pointer">{footerLabel.DownloadApp}</div></li>
                  <li className=""> <span className="linkLi text-decoration-none">{footerLabel.LiveSupport}</span></li>
                </ul>
              </div>
              <div className="col-6 col-sm-4 px-0">
                <ul className="linksUl list-unstyled">
                  <li className="linkLiHead">{footerLabel.Casino}Casino</li>
                  <li className=""> <Link to={ROUTES_CONST.RESPONSIBLE_GAMBLING} className="linkLi text-decoration-none">{footerLabel.ResponsibleGambling}</Link></li>
                  <li className=""> <Link to={ROUTES_CONST.NEW_AML_POLICY} className="linkLi text-decoration-none">{footerLabel.AML_Policy}</Link></li>
                  <li className=""> <Link to={ROUTES_CONST.SPORTS_POLICY} className="linkLi text-decoration-none">{footerLabel.SportsPolicy}</Link></li>
                  <li className=""> <Link to={ROUTES_CONST.TERMS_AND_CONDITIONS} className="linkLi text-decoration-none">{footerLabel.TermsCondition}
                  </Link></li>
                </ul>
              </div>
              <div className="col-6 col-sm-4">
                <ul className="linksUl list-unstyled">
                  <li className="linkLiHead">{footerLabel.Community}</li>
                  <li className=""> <Link target="_blank" to="https://www.instagram.com/" className="linkLi text-decoration-none">{footerLabel.Instagram}</Link></li>
                  {/* <li className=""> <Link target="_blank" to="#" className="linkLi text-decoration-none">Meta</Link></li> */}
                  <li className=""> <Link target="_blank" to="https://t.me" className="linkLi text-decoration-none">{footerLabel.Telegram}</Link></li>
                  {/* <li className=""> <Link target="_blank" to="#" className="linkLi text-decoration-none">Discord</Link></li> */}
                  <li className=""> <Link target="_blank" to="https://twitter.com" className="linkLi text-decoration-none">{footerLabel.TwitterX}</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footerrSection mt-4">
          <div className="row ">
            <div className="col-12">
              <div className="cryptoLogo card border-0 shadow-none">
                <div className="card-body">
                  <div className="row mx-0 gx-0 align-items-center">
                    <div className="col">
                      <div className="row mx-0 justify-content-center align-items-center">
                        <div className="col-lg-auto col-12 mb-3 mb-lg-0"><div className="txt text-white text-center text-md-start">{footerLabel.ACCEPTED_NETWORKS}</div></div>
                        <div className="col mt-1">
                          <div className="row justify-content-center justify-content-sm-between justify-content-lg-center footerIconRow">
                            <div className="col-lg col-auto px-0 footerIconWrapper"><div className="logos"><img src="assets/img/crypto.png" alt="Crypto" /></div></div>
                            <div className="col-lg col-auto px-0 footerIconWrapper"><div className="logos"><img src="assets/img/Ethereum_logo.png" alt="Ethereum" /></div></div>
                            <div className="col-lg col-auto px-0 footerIconWrapper"><div className="logos"><img src="assets/img/tether-usdt-logo.png" alt="Tether" /></div></div>
                            <div className="col-lg col-auto px-0 footerIconWrapper"><div className="logos"><img src="assets/img/xcrypto.png" alt="Xcrypto" /></div></div>
                            <div className="col-lg col-auto px-0 footerIconWrapper"><div className="logos"><img src="assets/img/Polkadot-Symbol.png" alt="Plokadot" /></div></div>
                            <div className="col-lg col-auto px-0 footerIconWrapper"><div className="logos"><img src="assets/img/matic-logo.png" alt="Matic" /></div></div>
                            <div className="col-lg col-auto px-0 footerIconWrapper"><div className="logos"><img src="assets/img/dogecoin-doge-logo.png" alt="Dogicoin" /></div></div>
                            <div className="col-lg col-auto px-0 footerIconWrapper"><div className="logos"><img src="assets/img/solana-sol-logo.png" alt="Solana" /></div></div>
                            <div className="col-lg col-auto px-0 footerIconWrapper"><div className="logos"><img src="assets/img/bnb-bnb-logo.png " alt="Bnb" /></div></div>
                            <div className="col-lg col-auto px-0 footerIconWrapper"><div className="logos"><img src="assets/img/cardano_ada-512.png" alt="Cardano" /></div></div>
                            <div className="col-lg col-auto px-0 footerIconWrapper"><div className="logos"><img src="assets/img/usd-coin-usdc-logo.png" alt="UsdCoin" /></div></div>
                          </div>
                        </div>
                        <div className="col-md-auto footerBtnCol">
                          <div className="row">
                            <div className="col-lg col-6 pe-0 d-flex justify-content-end mt-3 mt-md-0"><div className=""><button type="button" onClick={handleModal} className="ragisterBtn d-flex align-items-center justify-content-center text-nowrap text-white border-0 px-3 mx-md-0 mx-auto">{footerLabel.Deposit}</button></div></div>
                            <div className="col-lg col-6 mt-3 mt-md-0"><div className=""><Link to={ROUTES_CONST.BUY_CRYPTO_URL} target="_blank" className="ragisterBtn ragisterBtn1 d-flex align-items-center justify-content-center text-nowrap text-white border-0 px-3 text-decoration-none">{footerLabel.BuyCrypto}</Link></div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-lg-none">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-start">
                      <div className="footerLogo"><img src="assets/img/heroLogo.png" className="object-fit-contain" alt="" /></div>
                    </div>
                    <div className="col-12">
                      <div className="para">hwakgame is a brand name of Vision  I-Gaming N.V. Company Address: Zuikertuintjeweg z/n (Zuikertuin Tower), Willemstad, Curaçao. hwakgame is licensed and authorized by the Government of Curaçao and operates under the Master License of Gaming Services Provider, N.V. #…….</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <div className="midLogo row align-items-center">
                    <div className="col-12 d-flex flex-column px-0">
                      <div className="gc mb-4"><img src="assets/img/footer/gc.png" alt="" /></div>
                      <div className="plus"><img src="assets/img/footer/eighteenplus.png" alt="" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="text-center copyright text-white mt-md-4 mt-3">Copyright © 2023  hwakgame. All rights reserved.</div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile bottom bar start  */}
      <div className="container-fluid bottomBarFluid d-xl-none d-block">
        <div className="row">
          <div className="col-12 px-0">
            <div className="bottomBar">
              <div className=" row justify-content-around align-items-center mx-0 gx-0 h-100">
                <div className="col-auto"
                  onClick={sideAppHandler}
                >
                  <span className="bottomAnchor toggleButton">
                    <div className="iconParent">
                      <img
                        src="assets/img/givewayImg/allGamesMenu.png"
                        alt="menu" className="h-100 w-100" /></div>
                  </span>
                </div>
                {
                  token ? (
                    <>
                      <div className="col-auto" onClick={() => navigate(ROUTES_CONST.ACCOUNT)}>
                        <span className="bottomAnchor">
                          <div className="iconParent">
                            <img
                              src="assets/img/header/user.svg"
                              alt="" className="w-100 h-100" /></div>
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className="bottomAnchor"
                          onClick={() => setWalletModalObject((prev) => ({
                            isWalletOpen: true,
                            selectedTab: WALLET_MODAL_TABS.DEPOSIT
                          }))}>
                          <div className="iconParent">
                            <img
                              src="assets/img/givewayImg/walletVector.png"
                              alt="" className="w-100 h-100" /></div>
                        </span>
                      </div>
                      <div className="col-auto" onClick={() => setshowSupport(true)}>
                        <span
                          className="bottomAnchor">
                          <div className="iconParent">
                            <img
                              src="assets/img/sidebar/support.png"
                              alt=""
                              className="img-fluid w-100 h-100" />
                          </div>
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="col-auto" onClick={() => navigate(ROUTES_CONST.SLOT_PAGE)}>
                      <span
                        className="bottomAnchor">
                        <div className="iconParent">
                          <img
                            src="assets/img/header/search.svg"
                            alt=""
                            className="img-fluid w-100 h-100" />
                        </div>
                      </span>
                    </div>
                  )
                }
                <div className="col-auto">
                  <span
                    className="bottomAnchor"
                    data-bs-toggle="offcanvas"
                    role="button"
                    aria-controls="message"
                    onClick={() => {
                      console.log("runnning")
                      socket.emit('joinRoom', { msg: null })
                      document.body.classList.toggle('showMsgOffcanvas')
                    }}
                  >
                    <div className="iconParent">
                      <img
                        src="assets/img/header/chat.svg"
                        alt=""
                        className="img-fluid w-100 h-100" />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SupportOffcanvas
      showSupport={showSupport}
      setshowSupport={setshowSupport}
    />
  </>
  );
};

export default AppFooter;
