
import calender from "../../../../src/Admin/AdminAssets/img/calendar.svg";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import UserManagementTable from "../../Admincomponents/UserManagementTable/UserManagementTable";
import { adminGetAPIAuth } from "../../AdminServices";
import exportToExcel from "../../../utils/exportExcel";
import { useSelector } from "react-redux";
import { USER } from "../../AdminConstants/apiEndpoints";
import AddUserModal from "../../Admincomponents/addUserModal/AddUserModal";
import ActionModal from "../../Admincomponents/actionModal/ActionModal";
import { getAPIAuth } from "../../../service/apiInstance";
import { PORTAL, USER_MANAGEMENT_URL } from "../../AdminConstants/urlPaths";
import DashboardCards from "./component/DashboardCards";


const Dashboard = () => {

  const [show, setShow] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [actionModalType, setActionModalType] = useState("");
  const [actinModalUser, setActinModalUser] = useState({});
  const [tableData, setTableData] = useState([]);
  const [userData, setUserData] = useState({});
  const [hierarchy, setHierarchy] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState({
    createdAt: false,
  });
  const [totalPages, setTotalPages] = useState(null);
  const [searchUser, setSearchUser] = useState("")
  const [searchedUserResult, setSearchedUserResult] = useState([])
  const adminData = useSelector((state) => state?.adminData);
  const [errorMsg, setErrMsg] = useState("")


  const [userCount, setuserCount] = useState([])
  const navigate = useNavigate()


  const getUserCount = async () => {
    try {
      const res = await adminGetAPIAuth("user/getChildrenCount")
      setuserCount(res?.data?.data?.data)
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    getUserCount()
  }, [])

  const updateHierarchy = (item) => {
    const obj = {
      superAdmin: 4,
      superMaster: 3,
      agent: 2,
      user: 1,
    };
    const data = structuredClone(hierarchy);
    const roles = data.map((el) => el.role);
    data.forEach((el) => (el.roleId = obj[el.role]));
    item.roleId = obj[item.role];

    if (hierarchy.length) {
      for (let i = 0; i < data.length; i++) {
        const el = data[i];
        if (el.roleId < item.roleId) {
          data.splice(i, 1, item);
          const sliced = data.slice(0, i);
          return sliced;
        } else if (el.roleId > item.roleId) {
          if (!roles.includes(item.role)) {
            return [...data, item];
          }
        }
      }
      return [...data];
    } else {
      return [...data, item];
    }
  };

  const getUserDetails = async (item) => {
    try {
      const res = await adminGetAPIAuth(
        USER.profile + "/" + item._id,
      );
      if (res?.data?.status === 200) {
        const result = updateHierarchy(item);
        setCurrentPage(1)
        setHierarchy(result);
        setUserData(res.data.data.data);
        getChildren(item);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getChildren = async (item) => {
    try {
      const res = await adminGetAPIAuth(
        USER.children + "/" + item._id + `?page=${currentPage}&perPage=10`,
      );
      if (res?.data?.status === 200) {
        setTotalPages(res?.data?.data?.data?.pagination?.totalPages);
        setTableData(res?.data?.data?.data?.children.slice(0, 5));
      }
    } catch (error) {
      console.log("error", error)
      if (error?.response?.data?.data?.message === `Children of ${item.username} not found.`) {
        setTotalPages(null);
        setErrMsg(error?.response?.data?.data?.message)
      }
      setTableData([]);
    }
  };

  useEffect(() => {
    if (adminData?._id) {
      // getUserDetails(adminData)   
      getChildren(adminData);
      const result = updateHierarchy(adminData);
      setHierarchy(result);
    }
    //eslint-disable-next-line
  }, [adminData?._id])

  useEffect(() => {
    if (userData?._id) {
      getChildren(userData);
    }
    //eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    setTableData([adminData]);
    setUserData(adminData);
  }, [adminData]);

  const openActionModal = (type, data) => {
    setActionModalType(type);
    setActinModalUser(data);
    setShowActionModal(true);
  };


  const handleSort = (property) => {
    const data = structuredClone(tableData);
    if (property === 'createdAt') {
      const sorted = data.sort((item1, item2) => {
        const date1 = new Date(item1.createdAt).getTime();
        const date2 = new Date(item2.createdAt).getTime();
        return order[property] ? date1 - date2 : date2 - date1;
      });
      setOrder((prev) => ({ ...prev, [property]: !prev[property] }));
      setTableData(sorted.slice(0, 5)); // Display only the latest 5 data
    } else {
      // Handle sorting for other properties
      // ... your existing sorting logic
    }
  };


  return (
    <>
      <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="row px-sm-4 px-3">
                <div className="col-12">
                  <div className="row">
                    <div className="col">
                      <header className="page-title-bar justify-content-between row align-items-center mt-2">
                        <h1 className="page-title mb-0 text-nowrap">
                          {" "}
                          <span className="cursor-pointer">Dashboard</span>{" "}
                        </h1>
                      </header>
                    </div>
                  </div>
                </div>
                <div className="  col-12  ">
                  <DashboardCards />
                </div>
                <div className="col-12 mt-5">
                  <div className="row g-5 pb-5">
                    {/* <div className="col-md-6">
                      <div className="tableSection table-responsive">
                        <table className="table table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th colSpan={"3"}>
                                <div className="tableData d-flex align-items-center ">
                                  <span className="imgIocn d-inline-flex align-items-center">
                                    <img
                                      src={calender}
                                      className="img-fluid w-100 h-100 "
                                      alt="deshboard card"
                                    />
                                  </span>
                                  Summary
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td> Active Players</td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td> Turnover</td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td> Profit</td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td> Total Bets</td>
                              <td>0%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="tableSection table-responsive">
                        <table className="table table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th colSpan={"3"}>
                                <div className="tableData d-flex align-items-center ">
                                  <span className="imgIocn d-inline-flex align-items-center">
                                    <img
                                      src={calender}
                                      className="img-fluid w-100 h-100 "
                                      alt="deshboard card"
                                    />
                                  </span>
                                  Top Categories
                                </div>
                              </th>
                              <th className="p0">
                                <div className=" d-flex align-items-center justify-content-end">
                                  <div className="dropdown border-0 shadow-none p-0 d-flex align-items-center">
                                    <button
                                      className="btn bg-white dropdown-toggle border-0 shadow-none dropdownTable"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      Sports
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-start mb-0">
                                      <li className="dropdown-item" >Sports</li>
                                      <li className="dropdown-item" >International Casino </li>
                                      <li className="dropdown-item" >Indian Casino</li>
                                      <li className="dropdown-item" >Binary</li>
                                    </ul>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Category Name</td>
                              <td> No. Of Matches</td>
                              <td>No. Of Bets</td>
                              <td>Points</td>
                            </tr>
                            <tr>
                              <td>Cricket</td>
                              <td> 1</td>
                              <td>2</td>
                              <td>10.00</td>
                            </tr>
                            <tr>
                              <td>Casino</td>
                              <td> 0</td>
                              <td>0</td>
                              <td>0.00</td>
                            </tr>
                            <tr>
                              <td>Indian Casino</td>
                              <td> 0</td>
                              <td>0</td>
                              <td>0.00</td>
                            </tr>
                            <tr>
                              <td>Binary</td>
                              <td> 0</td>
                              <td>0</td>
                              <td>0.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> */}
                    <div className="col-12">
                      <div className="tableSection table-responsive">
                        <table className="table table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th colSpan={"4"}>
                                <div className="tableData d-flex align-items-center ">
                                  <span className="imgIocn d-inline-flex align-items-center">
                                    <img
                                      src={calender}
                                      className="img-fluid w-100 h-100 "
                                      alt="deshboard card"
                                    />
                                  </span>
                                  User management
                                </div>
                              </th>
                              <th className="">
                                <div className=" d-flex align-items-center justify-content-end">
                                  <div className="border-0 shadow-none p-0 d-flex align-items-center">
                                    <button
                                      onClick={() => navigate(`/${PORTAL}/${adminData.role}/${USER_MANAGEMENT_URL}`)}
                                      className="btn bg-white border-0 shadow-none dropdownTable"
                                      type="button"
                                      // data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      View Details
                                    </button>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              userCount?.length ? userCount.map((item) => (
                                <tr onClick={() => navigate(`/${PORTAL}/${adminData.role}/${USER_MANAGEMENT_URL}`,
                                  {
                                    state: {
                                      dashRole: item?.role
                                    }
                                  }
                                )}>
                                  <td colSpan={"4"}>{item?.role}</td>
                                  <td colSpan={"8"} className="admin-forms-success-text text-end">
                                    {item.count}
                                  </td>
                                </tr>
                              ))
                                : 'no data'
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <UserManagementTable
                    tableData={tableData}
                    handleSort={handleSort}
                    getUserDetails={getUserDetails}
                    order={order}
                    adminData={adminData}
                    openActionModal={openActionModal}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    getChildren={getChildren}
                    userData={userData}
                    parentData={hierarchy?.[hierarchy?.length - 1]}
                    searchedUserResult={searchedUserResult}
                    searchUser={searchUser}
                    tableHeading={'User management'}
                    tableHeadingCol={'8'}
                    errorMsg={errorMsg}
                  />

                </div>
              </div>
              <div className="d-flex align-items-center"></div>
            </div>
          </div>
        </main>
        <AddUserModal show={show} setShow={setShow} parentData={{ ...hierarchy?.[hierarchy?.length - 1], availablePoints: 100 }} getChildren={getChildren} />
        <ActionModal
          show={showActionModal}
          setShow={setShowActionModal}
          type={actionModalType}
          data={actinModalUser}
          parentData={hierarchy?.[hierarchy?.length - 1]}
          updateData={() => getUserDetails(hierarchy?.[hierarchy?.length - 1])}
        />
      </div>
    </>
  );
};

export default Dashboard;
