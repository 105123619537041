import React, { useEffect, useRef, useState } from "react";

const TimerComp = ({ endDate }) => {
    const [timeLeft, setTimeLeft] = useState()
    const timerRef = useRef(null)

    const getRemainingDays = (date) => {
        const targetDate = new Date(date)
        // const targetDate = new Date("Mon Oct 9 2023 05: 30:00 GMT +0530(India Standard Time)")
        
        const now = new Date();
        const difference = targetDate - now;
        const days = Math.abs(Math.floor(difference / (1000 * 60 * 60 * 24)));
        const hours = Math.abs(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        const minutes = Math.abs(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)));
        const seconds = Math.abs(Math.floor((difference % (1000 * 60)) / 1000));
        const timeDiffString = `${days}d:${hours}h:${minutes}m:${seconds}s`;
        // console.log({ targetDate, now, days, hours, minutes, seconds })
        setTimeLeft(timeDiffString)
        // return 
    }

    useEffect(() => {
        if (timerRef.current) {
            clearInterval(timerRef.current)
        }
        timerRef.current = setInterval(() => {
            getRemainingDays(endDate)

        }, 500)
        return () => {
            clearInterval(timerRef.current)
        }
    }, [endDate])

    return <div className="tymLft">Time Left: <span> {timeLeft}</span></div>;
};

export default TimerComp;
