import React from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import plus from './../../fantasyAssets/img/plus.png'
import minus from './../../fantasyAssets/img/minus.png'
import { useState } from 'react'
import {getFantasyAuth } from '../../fantasyServices'
import { useEffect } from 'react'

const Transaction = () => {
    const [transactions, setTransactions] = useState([])
    const [loader, setLoader] = useState(true)

    const transactionsData = async () => {
        setLoader(true)
        try {
            const res = await getFantasyAuth(`mytransactions`);
            if (res?.data?.status) {
                setTransactions(res.data.data)
            }
        } catch (error) {
        } finally {
            setLoader(false)
        }
    }
    useEffect(()=> {
        transactionsData()
    }, [])
    return (
        <div className="row fantasyInner">
        <FantasyHeader gameHeader={true} title={'Transaction'}/>
        <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
            <div class="row mainCenterPart g-3 mx-0 py-4">
                {!loader ? 
                    transactions?.length > 0 ?
                        transactions?.map((item)=> (
                            <div class="col-12">
                                <div class="card rounded-3 transectionCard">
                                    <div class="card-body px-0 py-2">
                                        <div class="row mx-0">
                                            <div class="col d-flex align-items-center pe-0">
                                                <div class="me-2">
                                                    <div class="additionImag d-flex rounded-circle">
                                                        <img src={item?.transaction_type === 'Debit' ? minus : plus} class="w-100 h-100" alt=""/>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <div class="transactionBonus">{item?.type}</div>
                                                    <div class="transactionId ">Transaction Id: {item?.txnid}</div>
                                                    <div class="transactionId ">{item?.date_time}</div>
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <div class={`transactionBonus fw-bold ${item?.transaction_type === 'Debit' ? 'text-danger' : 'text-success'}`}>₹ {item?.amount}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    : 'no transactions'
                : 'loader'}
                {/* <div class="col-12">
                    <div class="card rounded-3 transectionCard">
                        <div class="card-body px-0 py-2">
                            <div class="row mx-0">
                                <div class="col d-flex align-items-center pe-0">
                                    <div class="me-2"><div class="additionImag d-flex rounded-circle"><img src={plus} class="w-100 h-100" alt=""/></div></div>
                                    <div class="">
                                        <div class="transactionBonus">Mobile Bonus</div>
                                        <div class="transactionId ">Transaction Id: MG11-EBONUS-1679051956464</div>
                                        <div class="transactionId ">19 Mar 2023 16:49</div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="transactionBonus text-success fw-bold">Rs 50.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card rounded-3 transectionCard">
                        <div class="card-body px-0 py-2">
                            <div class="row mx-0">
                                <div class="col d-flex align-items-center pe-0">
                                    <div class="me-2"><div class="additionImag nagetiveImg d-flex rounded-circle"><img src={minus} class="w-100 h-100" alt=""/></div></div>
                                    <div class="">
                                        <div class="transactionBonus ">Contest Joining Fee</div>
                                        <div class="transactionId">Transaction Id: MG11-1679051954524-YNDR</div>
                                        <div class="transactionId">18 Mar 2023 09:27</div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="transactionBonus text-danger fw-bold">Rs 50.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card rounded-3 transectionCard">
                        <div class="card-body px-0 py-2">
                            <div class="row mx-0">
                                <div class="col d-flex align-items-center pe-0">
                                    <div class="me-2"><div class="additionImag d-flex rounded-circle"><img src={plus} class="w-100 h-100" alt=""/></div></div>
                                    <div class="">
                                        <div class="transactionBonus">Email Bonus</div>
                                        <div class="transactionId">Transaction Id: MG11-EBONUS-1679051957591</div>
                                        <div class="transactionId">17 Mar 2023 18:03</div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="transactionBonus text-success fw-bold">Rs 50.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
        <FantasyBottomBar/>
    </div>
    )
}

export default Transaction