import React, { useEffect, useState } from "react";
import FantasyHeader from "../../fantasyComponents/fantasyHeader/FantasyHeader";
import FantasyBottomBar from "../../fantasyComponents/fantasyBottombar/FantasyBottomBar";
import trophy from '../../../fantasy/fantasyAssets/img/trophyIcon.png'
import doller from '../../../fantasy/fantasyAssets/img/dollerSign.svg'
import StockListTab from "./StockListTab";
import StockLeaderboardTab from "./StockLeaderboardTab";
import { useLocation } from "react-router-dom";
import { getFantasyAuth } from "../../fantasyServices";
import { useSelector } from "react-redux";

const StockLeaderBoard = () => {
  const [activeTab, setActiveTab] = useState("StockList");
  const stockContest = useLocation()?.state?.stockContest
  const type = useLocation()?.state?.type
  console.log({ stockContest, type })
  const fantasyUserData = useSelector(state => state.fantasyUserData);
  const [leader, setleader] = useState([]);
  const [leaderboardData, setleaderboardData] = useState([]);
  const [loader, setLoader] = useState(true)

  const rank = leaderboardData?.jointeams?.filter(el => {
    return el?.teamname == fantasyUserData?.team;
  })[0]?.rank;
  const win_amount = leaderboardData?.jointeams?.filter(el => {
    return el?.teamname == fantasyUserData?.team;
  })[0]?.prize;

  console.log({leaderboardData});

  const getLeaderData = async () => {
    try {
      if (type == 'upcomming') {
        const result = await getFantasyAuth(
          `/my-contest-leaderboard?contestId=${stockContest?._id}`,
        );
        setleaderboardData(result?.data?.data);
      } else {
        const AllDataleader = await getFantasyAuth(
          `/live-stock-rank-leaderboard?contestId=${stockContest?._id}`,
        );
        setleaderboardData(AllDataleader?.data?.data);
      }


    } catch (error) {

    } finally {

    }

  };
  useEffect(() => {

  }, [])

  useEffect(() => {
    getLeaderData();
  }, []);
  return (
    <>
      <div className="row fantasyInner">
        <FantasyHeader gameHeader={true} title={"STOCK LEADERBOARD"} />
        <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
          <div className="row">
            <div className="col-12 py-3">
              <div className="warningCard stockLeadBoardCard trophyaddcase card cursor-pointer border-0 rounded4 position-relative">
                <div className="card-body">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto d-flex align-items-center justify-content-center">
                      <div className="trophyCol d-flex align-items-center justify-content-center">
                        <img src={trophy} alt="" />
                      </div>
                      <div className="trophyText ps-2">
                        {stockContest?.name ? stockContest?.name : "-----"}
                      </div>
                    </div>
                    <div className="col text-white subText d-flex justify-content-end">
                      {fantasyUserData?.team}
                    </div>
                    <div className="col-12 pt-3">
                      <div className="row">
                        <div className="col-3">
                          <div className="row mx-0">
                            <div className="col-12  text-start">
                              <div className="text-white font14 fontWeight700">Prize</div>
                              <div className="text-white">{win_amount > 0 ? win_amount : '--'}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-3 px-0">
                          <div className="row mx-0">
                            <div className="col-12 px-0 text-center">
                              <div className="text-white font14 fontWeight700">Rank</div>
                              <div className="text-white">{rank > 0 ? rank : '--'}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-3 px-0">
                          <div className="row mx-0">
                            <div className="col-12 px-0 text-center">
                              <div className="text-white font14 fontWeight700">Spots</div>
                              <div className="text-white font14">{stockContest?.maximum_user - stockContest?.joinedusers}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="row mx-0">
                            <div className="col-12  text-end">
                              <div className="text-white font14 fontWeight700">Entry</div>
                              <div className="text-white font14"><img className="dollerImg" src={doller} alt="" /> {stockContest?.entryfee}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 contestOptionsTabInner tabPortfolio mb-3">
            <div className="row mx-0 align-items-center justify-content-between">
              <div className="col-6">
                <div
                  onClick={() => setActiveTab("StockList")}
                  className={`contestOptionTab  text-center text-uppercase ${activeTab === "StockList" ? "active" : ""
                    }`}
                >
                  StockList
                </div>
              </div>
              <div className="col-6">
                <div
                  onClick={() => setActiveTab("leaderboard")}
                  className={`contestOptionTab text-center text-uppercase ${activeTab === "leaderboard" ? "active" : ""
                    }`}
                >
                  Leaderboard
                </div>
              </div>
            </div>
          </div>
          {
            activeTab === "StockList" ? <StockListTab stockContest={stockContest} /> :
              activeTab === "leaderboard" ? <StockLeaderboardTab stockContest={stockContest}type={type} leaderboardData={leaderboardData} /> : ""
          }
        </div>
        <FantasyBottomBar />
      </div>
    </>
  );
};

export default StockLeaderBoard;
