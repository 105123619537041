import { useContext } from "react"
import { LogoutModalContext } from "../context/LogoutContext"


export const useLogoutModal = () => {
    const { isLogoutOpen, setIsLogoutOpen } = useContext(LogoutModalContext)
    return {
        isLogoutOpen,
        setIsLogoutOpen
    }
}