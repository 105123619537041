import { useContext } from "react"
import { ReferralModalContext } from "../context/ReferralContext"


export const useReferralModal = () => {
    const { isReferral, setIsReferral } = useContext(ReferralModalContext)
    return {
        isReferral,
        setIsReferral
    }
}