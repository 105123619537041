import React,{useState , useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ADMIN_FRONTEND_LOGGED_IN_ID, AUTH_MODAL_TABS, TOKEN_NAME } from '../../constants'
import { useAuth } from '../../hooks/useAuth'
import { getAPIAuth } from '../../service/apiInstance'
import { Spinner } from 'react-bootstrap'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../constants/routeConstant'
import { useAuthModal } from '../../hooks/useAuthModal'

function SportsSubscribtionProtectedRoutes(props) {
     const { Component } = props
    const [Loading, setLoading] = useState(false);
       const [isLoggedIn, setLoggedIn] = useState(false);
    const [packagesubscription, setPackageSubscription] = useState([]);
    const { token } = useAuth();
     const navigate = useNavigate();
     const { setAuthModalObject } = useAuthModal();
     const userSubscribtionDetails = useSelector((state) => state?.getSubscribeUser);

     console.log("userSubscribtionDetails" , userSubscribtionDetails);

       useEffect(() => {
        if (!token) {
            navigate(ROUTES_CONST.INDEX)
            setAuthModalObject((pre) => ({
                selectedTab: AUTH_MODAL_TABS.LOG_IN,
                isAuthOpen: true,
            }))
        } else {
            setAuthModalObject((pre) => ({
                isAuthOpen: false,
            }))
            setLoggedIn(true)
            if (userSubscribtionDetails?.[0]?.status === "active") {
                navigate(ROUTES_CONST.SPORTS_SUBSCRIPTION)
            } else {
                navigate(ROUTES_CONST.INDEX)
              //  alert(userSubscribtionDetails)
            }
        }
    }, [token, userSubscribtionDetails]);
  return (
     Loading ? (<Spinner />) : (<Component />)
  )
}

export default SportsSubscribtionProtectedRoutes