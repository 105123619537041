import { ROUTES_CONST } from "../../constants/routeConstant";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { getFantasyAuth } from "../fantasyServices";

// Start for TeamSelection
const checkForTeamSelection = (teamData) => {
    return teamData.filter(el => el.isSelected === true).length;
};

const navigateToCreateTeam = (navigate, data, challenge, contest) => {
    console.log("navigateToCreateTeam")
    navigate(`${ROUTES_CONST?.CREATE_TEAM}/${data?.id}`,
        {
            state: {
                data: data,//yeh data fantasy home ke card ka h 
                contest: contest,
                challenge: challenge
            },
        })
};

const navigateToChooseTeam = (navigate, data, response1, teamLimit, elem) => {
    console.log("navigateToChooseTeam")
    navigate(ROUTES_CONST.MY_TEAMS_PAGE,
        {
            state: {
                team: response1.data.data,
                numOfSel: teamLimit - checkForTeamSelection(response1.data.data),
                data: data,
                challenge: elem,
                contest: elem,
            }
        }
    );
};

const handleTeamSelection = async (navigate, data, elem, setSelectionData, contests) => {
    console.log("all--->>>>", { data, elem, setSelectionData, contests })
    const response1 = await getFantasyAuth(`getMyTeams?matchkey=${data?.id ? data?.id : data?.matchkey}&matchchallengeid=${elem.matchchallengeid}`);
    const joinedUsers = elem.joinedusers;
    const maximumUsers = elem.maximum_user;
    const teamLimit = elem.team_limit;
    console.log({ response1, data, elem, setSelectionData, contests })
    if (!response1.data.status) {
        errorToaster(response1.data.message)
    }
    if (maximumUsers) {
        console.log("maximumUsers");

        if (maximumUsers !== joinedUsers) {
            console.log("maximumUsers != joinedUsers");
            console.log("maximumUsers != joinedUsers");

            if (response1?.data?.data?.length === 0) {
                console.log("response1?.data?.data?.length == 0");

                navigateToCreateTeam(navigate, data, elem, elem);
            } else if (response1?.data?.data?.length === 1) {
                console.log("response1?.data?.data?.length == 1");

                const selectedTeamsCount = checkForTeamSelection(response1.data.data);
                if (selectedTeamsCount) {
                    console.log("selectedTeamsCount");

                    if (elem?.joinedleauges >= 1) {//joinedleagues:n means u already joined it nth number of time
                        console.log("elem?.joinedleauges >= 1");

                        navigateToCreateTeam(navigate, data, elem, elem);//is joining is complete then , why to navigate on create team 
                    } else {
                        setSelectionData(response1?.data?.data, elem)
                        console.log("elem?.joinedleauges >= 1", "else");

                    }
                } else {
                    console.log("selectedTeamsCount", "else");
                    setSelectionData(response1.data.data, elem)
                }
            } else if (response1?.data?.data?.length > 1) {
                console.log("response1?.data?.data?.length > 1");

                if (elem?.isselected) {//isSelected true means limit of user to join is exceeded
                    // alert('Joining Completed');
                    console.log("elem?.isselected");

                    // succesToaster('Joining Completed');
                    succesToaster(' You already Joined this contest!');
                } else {
                    console.log("elem?.isselected", "else");

                    const teamsNotSelected = response1?.data?.data?.filter(el => el?.isSelected !== true);
                    if (teamsNotSelected?.length > 1) {
                        console.log("teamsNotSelected?.length > 1",);

                        navigateToChooseTeam(navigate, data, response1, teamLimit, elem)
                    } else if (teamsNotSelected?.length === 1) {
                        console.log("teamsNotSelected?.length == 1");
                        setSelectionData(teamsNotSelected, elem)
                    } else {
                        console.log("teamsNotSelected?.length == 1", "else");
                        navigateToCreateTeam(navigate, data, elem, elem);
                    }
                }
            }
        } else {
            // succesToaster('Joining Completed');
            errorToaster('Limit to join this contest is reached');

            console.log("maximumUsers != joinedUsers", "else");

        }
    } else {
        console.log("maximumUsers", "else");

        if (response1?.data?.data?.length === 0) {
            console.log("response1?.data?.data?.length == 0");
            navigateToCreateTeam(navigate, data, elem, elem);
        } else if (response1?.data?.data?.length === 1) {
            console.log("response1?.data?.data?.length == 1");
            setSelectionData(response1.data.data, elem)
        } else if (response1?.data?.data?.length > 1) {
            console.log("response1?.data?.data?.length > 1");
            navigateToChooseTeam(navigate, data, response1, teamLimit, elem, contests)
        }
    }
};

export const selectingTeam = async (navigate, data, elem, contests, setSelectionData) => {
    handleTeamSelection(navigate, data, elem, setSelectionData, contests);
};
// End of TeamSelection 

// Start getting Balance Funtion
export const getUsableBalance = async elem => {
    let response1 = await getFantasyAuth(
        `/getUsableBalance?matchchallengeid=${elem.matchchallengeid}&team_count=1`,
    );
    return response1.data.data
};
// End for getting Balance Function

