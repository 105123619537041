import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { adminGetAPIAuth } from "../../AdminServices";
import { REPORTS } from "../../AdminConstants/apiEndpoints";
import { errorToaster } from "../../../utils/toaster";

const Balance = () => {
    const [balance, setBalance] = useState([]);

    const { userId } = useParams()

    const handleGetList = async () => {
            try {
                const res = await adminGetAPIAuth(
                    REPORTS?.balance + "/" + userId
                );
                if (res?.data?.data?.success) {
                    setBalance(res?.data?.data?.data[0]);
                } else {
                    errorToaster("Something went wrong in fetching data");
                }
            } catch (error) {
                errorToaster(error?.response?.data?.data?.message);
                setBalance([]);
                console.log("error -------------->", error);
            }
    }

    useEffect(() => {
        if (userId) {
            handleGetList();
        }
        //eslint-disable-next-line
    }, []);


    return (
        <>
            <div className="row g-3 mb-4 mt-3">
                <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Exposer</div>
                          <div className="adminTopCardSubtitle">{balance?.exposer ? balance?.exposer?.toFixed(2) : 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Opening Balance</div>
                          <div className="adminTopCardSubtitle">{balance?.openingBal ? balance?.openingBal?.toFixed(2) : 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Closing Balance</div>
                          <div className="adminTopCardSubtitle">{balance?.clossingBal ? balance?.clossingBal?.toFixed(2) : 0}</div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Balance;
