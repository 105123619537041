import React, { useEffect, useState } from 'react'
import reload from './../../AdminAssets/img/reload.svg'
import { adminGetAPIAuth } from '../../AdminServices';
import { REPORTS } from '../../AdminConstants/apiEndpoints';
import { errorToaster } from '../../../utils/toaster';
import { returnEndDate } from '../../../utils/returnEndDate';
import Pagination from '../../Admincomponents/pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import { MARKET_ADD_PAGE, PORTAL, MARKET_COMMISSION_ADD_PAGE, MARKET_COMMISSION } from '../../AdminConstants/urlPaths';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ActionModal from '../../Admincomponents/actionModal/ActionModal';



const MarketCommissionTable = () => {
    const [tableData, setTableData] = useState([]);
    console.log("tableData", tableData);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [filterValues, setFilterValues] = useState({
        transactions: "",
        startDate: "",
        endDate: "",
    });
    const [tableid, SetTableID] = useState();
    const [ischangevalue, SetIsChangeValue] = useState();
    console.log("ischangevalue", tableid);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const adminData = useSelector(state => state.adminData);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [serchuserdata, setSearchUserData] = useState("");
    const [showActionModal, setShowActionModal] = useState(false);
    const [actionModalType, setActionModalType] = useState("");
    const [istoogledata, setIsToogleData] = useState(false);
    // console.log("istoogledata", istoogledata);


    // console.log("functiondata", functiondata);
    // console.log("serchuserdata", serchuserdata);
    // const navigate = useNavigate();

    console.log("tableid", tableid);

    // set today's date as end date
    useEffect(() => {
        const today = new Date()
        setFilterValues((prev) => ({
            ...prev,
            endDate: returnEndDate(today)
        }))
    }, []);

    const DeleteMarketData = async (itemm) => {
        // console.log("itemm", itemm);
        try {
            const res = await adminGetAPIAuth(
                `${REPORTS?.deleteStockCategory}?_id=${itemm}`

            );
            console.log('resresresres', res);
            if (res?.data?.data?.success) {
                // setTableData(res?.data?.message);
                GetMarketData();
            } else {
                errorToaster("Something went wrong in fetching data");
            }
        } catch (error) {
            if (error?.response?.status === 401) {

            }
            errorToaster(error?.response?.data?.data?.message);
            setTableData([]);
            console.log("error -------------->", error);
        }
    };




    const GetMarketData = async () => {
        try {
            const res = await adminGetAPIAuth(
                `${REPORTS?.getStockCategory}?name=${serchuserdata}`
            );
            console.log('resresresres', res);
            if (res?.data?.data?.success) {
                setTableData(res?.data?.data?.data);
            } else {
                errorToaster("Something went wrong in fetching data");
            }
        } catch (error) {
            if (error?.response?.status === 401) {

            }
            errorToaster(error?.response?.data?.data?.message);
            setTableData([]);
            console.log("error -------------->", error);
        }
    };


    useEffect(() => {

        if (istoogledata) {
            setIsToogleData(false)

        }

        GetMarketData();


    }, [serchuserdata, istoogledata]);



    const handleChange = (e) => {
        setFilterValues((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const openActionModal = (type, data) => {
        // console.log("ASDHGFADFS", type);
        setActionModalType(type);
        setShowActionModal(true);
    };



    return (
        <>

            <div className="app">
                <main className="app-main">
                    <div className="wrapper">
                        <div className="page">
                            <div className="page-inner ">

                                <header className="page-title-bar justify-content-between row align-items-center">
                                    <h1 className="page-title mb-0 text-nowrap"> Market Categories </h1>
                                </header>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="row align-items-end">
                                            <div className="col-md-auto pe-md-0 mb-2 mb-md-0">
                                                <div className="adminSearchInput">
                                                    <input
                                                        type="text"
                                                        placeholder="Search User"
                                                        value={serchuserdata}
                                                        onChange={(e) => setSearchUserData(e.target.value)}
                                                        className="form-control adminFilterInp" />
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div className="row justify-content-end mx-md-0">
                                                    {/* <div className="col-md-auto col-12 col-sm-4 pe-md-0 mb-2 mb-sm-0">
                            <label htmlFor="" className="form-label">Transactions</label>
                            <select name="" id="" className="form-select adminFilterInp">
                              <option value="">All</option>
                            </select>
                          </div> */}

                                                    <div className="col-md-auto col-6 d-flex align-items-end col-sm-6 pe-md-0 mt-md-0 mt-3">
                                                        <button type="button" class="me-2 ms-auto btn btn-primary btnCustom1" onClick={() => openActionModal(MARKET_COMMISSION)}><i class="bi bi-plus"></i>Add More</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className='text-uppercase text-nowrap'>
                                                    <tr>
                                                        <th>
                                                            <div className="d-flex">
                                                                S.NO
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                Name
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex">
                                                                Action
                                                            </div>
                                                        </th>

                                                    </tr>
                                                </thead>
                                                {tableData?.length ? <tbody>
                                                    {
                                                        tableData?.map((item, index) => (
                                                            <tr>
                                                                <td className="align-middle fw-medium text-capitalize">{index + 1}</td>
                                                                <td className="align-middle fw-medium text-capitalize">{item?.name}</td>

                                                                <td className="align-middle fw-medium text-capitalize">
                                                                    <div className="add-more-daata">

                                                                        <button><i class="bi bi-trash" onClick={() => { handleShow(); SetTableID(item?._id); }}></i></button>
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody> :
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={3} className='text-center py-5'>
                                                                <b> No Data...</b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                }
                                            </table>
                                        </div>
                                    </div>
                                    {
                                        totalPages > 1 ?
                                            <Pagination
                                                totalPages={totalPages}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                            /> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Click Confirm to delete this data!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => { handleClose(); DeleteMarketData(tableid) }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            <ActionModal
                show={showActionModal}
                setShow={setShowActionModal}
                type={actionModalType}
                data={setIsToogleData}
            // parentData={hierarchy?.[hierarchy?.length - 1]}
            //updateData={() => getUserDetails(hierarchy?.[hierarchy?.length - 1])}
            />
        </>
    )
}

export default MarketCommissionTable