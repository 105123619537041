import React, { useEffect } from 'react'
import { useState } from 'react'
import StocksCard from '../../fantasyComponents/stocksCard/StocksCard'
import { getFantasyAuth } from '../../fantasyServices/index'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { errorToaster } from '../../../utils/toaster'
import { Experimental_CssVarsProvider } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import trophyImg from '../../fantasyAssets/img/trophy.png'
import LightiningIcon from '../../fantasyAssets/img/lightining.png'
import CounterComp from '../../fantasyComponents/CounterComp/CounterComp'


const MatchStocks = ({ activeTab }) => {
  const [stocksActiveTab, setStocksActiveTab] = useState('upcoming')
  const upcomingMatches = [1, 2, 3]
  const liveMatches = [1, 2]
  const [UpcomingData, setUpcomingData] = useState([]);
  // const currentHomeTab = useSelector(state => state?.currentHomeTab);
  const [upcomingLoading, setUpcomingLoading] = useState(true);
  const [LiveData, setLiveData] = useState([]);
  const [liveLoading, setLiveLoading] = useState(true);
  const [completedData, setCompletedData] = useState([]);
  const [completedLoading, setCompleteLoading] = useState(true);
  const navigate = useNavigate()

  // upcoming stocks
  const getUpcoming = async () => {
    try {
      let res = await getFantasyAuth(
        `/newjoinedcontest?stock_contest_cat=${activeTab}`,
      );
      if (res.data.success) {
        console.log(res);
        setUpcomingData(res?.data?.data);
      }
    } catch (error) {
      console.log(error.message)
    } finally {
      setUpcomingLoading(false)
    }
  };

  // live stocks
  const getLiveData = async () => {
    try {
      let res = await getFantasyAuth(
        `/newjoinedcontestlive?stock_contest_cat=${activeTab}`,
      );
      if (res.data.success) {
        setLiveData(res.data.data);
      }
    } catch (error) {
      errorToaster(error.message)
    } finally {
      setLiveLoading(false);
    }
  };

  const getCompletedData = async () => {
    try {
      let res = await getFantasyAuth(
        `/all-completed-contest?stock_contest_cat=${activeTab}`,
      );
      if (res.data.success) {
        setCompletedData(res.data.data);
      }
    } catch (error) {
      //error
    } finally {
      setCompleteLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === 'STOCKS' && stocksActiveTab === 'upcoming') {
      getUpcoming();
    } else if (activeTab === 'STOCKS' && stocksActiveTab === 'live') {
      getLiveData();
    } else if (activeTab === 'STOCKS' && stocksActiveTab === 'completed') {
      getCompletedData()
    }

  }, [activeTab, stocksActiveTab]);

  return (
    <>
      <div className="col-12 contestOptionsTabInner mb-3">
        <div className="row mx-0 align-items-center justify-content-between">
          <div className="col-4">
            <div
              onClick={() => setStocksActiveTab('upcoming')}
              className={`contestOptionTab text-center text-uppercase ${stocksActiveTab === 'upcoming' ? 'active' : ''}`}
            >
              Upcoming
            </div>
          </div>
          <div className="col-4">
            <div
              onClick={() => setStocksActiveTab('live')}
              className={`contestOptionTab text-center text-uppercase ${stocksActiveTab === 'live' ? 'active' : ''}`}
            >
              Live
            </div>
          </div>
          <div className="col-4">
            <div
              onClick={() => setStocksActiveTab('completed')}
              className={`contestOptionTab text-center text-uppercase ${stocksActiveTab === 'completed' ? 'active' : ''}`}
            >
              Completed
            </div>
          </div>
        </div>
      </div>
      {
        stocksActiveTab === 'upcoming' ? (
          <>
            <div className="col-12">
              {
                upcomingLoading ? (
                  <div className='w-100 h-100 d-flex justify-content-center'>
                    <Spinner color='white' size='sm' />
                  </div>
                ) : (
                  !UpcomingData || UpcomingData == 0 ? (
                    <div className='w-100 h-100 d-flex justify-content-center text-light' >No Data Available</div>
                  ) : (
                    UpcomingData?.map((item) => (
                      // <StocksCard
                      //   el={item}
                      //   // navigation={navigation}
                      //   // route1={'SelectStock'}
                      //   params1={{ data: item }}
                      //   timer={true}
                      //   selected={true}
                      // />
                      <div
                        className="stocksCard rounded-3 overflow-hidden mb-3 p-2 cursor-pointer"
                        onClick={() => {
                          if (item?.is_selected) {
                            errorToaster('You Allready Joined This Contest');
                          } else {
                            navigate(`${ROUTES_CONST?.FANTASY_STOCK_LEADERBOARD}`, {
                              state: {
                                stockContest: item,
                                type: 'upcomming'
                              }
                            });
                          }
                        }}
                      >

                        <div className="row align-items-center">
                          <div className="col-12">
                            <div className="row progressRow mb-3">
                              <div className="col">
                                <div className="progressVal">
                                  <img src={trophyImg} alt="" />
                                  {item?.winning_percentage}%
                                </div>
                                <div
                                  className="progress"
                                  role="progressbar"
                                  aria-label="Basic example"
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div className="progress-bar" style={{ width: '25%' }}></div>
                                </div>
                              </div>
                              <div className="col-auto progressAuto">
                                <img src={trophyImg} alt="" />
                                {/* 900 */}
                                {item?.win_amount}
                              </div>
                            </div>
                          </div>
                          <div className="col-auto">
                            <div className="gameImg rounded-circle overflow-hidden">
                              <img src={item?.image} className='h-100 w-100 object-fit-cover' alt="" />
                            </div>
                          </div>
                          <div className="col">
                            <CounterComp start_date={item.start_date} stocks={true} />
                          </div>
                          <div className="col-auto">
                            <button className='priceBtn rounded border-0 text-white'>
                              {item?.is_selected ? 'joined' : `₹ ${item?.entryfee}`}
                            </button>
                          </div>
                          <div className="col-12 my-2">
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Basic example"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                className="progress-bar progress-bar-striped"
                                style={{ width: `${item?.maximum_user == 0 ? 0 : (item?.joinedusers / item?.maximum_user) * 100}%` }}
                              ></div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row mx-0">
                              <div className="col-4 ps-0">
                                <div className="valueBox d-flex align-items-center">
                                  <span>Starts: </span>
                                  <span>
                                    {/* 15 Oct | 15:47 */}
                                    {moment(item?.start_date).format('DD MMM | HH:mm')}
                                  </span>
                                </div>
                              </div>
                              <div className="col-4 px-0">
                                <div className="valueBox d-flex align-items-center justify-content-center">
                                  <span>Spots Left: </span>
                                  <span>
                                    {/* 3 */}
                                    {item?.maximum_user - item?.joinedusers}
                                  </span>
                                </div>
                              </div>
                              <div className="col-4 pe-0">
                                <div className="valueBox d-flex align-items-center justify-content-end">
                                  <span>Ends: </span>
                                  <span>
                                    {/* 15 Oct | 16:47 */}
                                    {moment(item?.end_date).format('DD MMM | HH:mm')}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )
                )
              }
            </div>
          </>
        ) :
          stocksActiveTab === 'live' ? (
            <>
              <div className="col-12">
                {
                  liveLoading ? (
                    <div className='w-100 h-100 d-flex justify-content-center'>
                      <Spinner color='white' size='sm' />
                    </div>
                  ) : (
                    !LiveData || LiveData == 0 ? (
                      <div className='w-100 h-100 d-flex justify-content-center text-light' >No Data Available</div>
                    ) : (
                      LiveData?.map((item) => (
                        <StocksCard
                          el={item}
                          // navigation={navigation}
                          // route1={'SelectStock'}
                          params1={{ data: item }}
                          // timer={true}
                          timer={false}
                          selected={true}
                        />
                      ))
                    )
                  )
                }
              </div>
            </>
          ) :
            stocksActiveTab === 'completed' ? (
              <>
                <div className="col-12">
                  {
                    completedLoading ? (
                      <div className='w-100 h-100 d-flex justify-content-center'>
                        <Spinner color='white' size='sm' />
                      </div>
                    ) : (
                      !completedData || completedData == 0 ? (
                        <div className='w-100 h-100 d-flex justify-content-center text-light' >No Data Available</div>
                      ) : (
                        completedData?.map((item) => (
                          <StocksCard
                            el={item}
                            timer={true}
                            // selected={true}
                            // route1={'StockLeaderboard'}
                            params1={{ data: item, type: 'upcomming' }}
                          />
                        ))
                      )
                    )
                  }
                </div>
              </>
            ) : (
              ''
            )
      }
    </>
  )
}

export default MatchStocks