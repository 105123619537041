import React, { useEffect } from "react";
import { useState, createContext } from "react";
import { getAPIAuth } from "../service/apiInstance";
import { useAuth } from "../hooks/useAuth";
export const CountryContext = createContext(null);
function CountryProvider({ children }) {
  const [Countrydata, setcountrydata] = useState([]);
  console.log("Countrydata", Countrydata);
  const { token } = useAuth()
  const countryAPI = async () => {
    if (!token) {
      console.log("token not available")
      return
    }
    try {
      const res = await getAPIAuth("get-countries");
      setcountrydata(res.data?.data);
    } catch (error) {
      console.log(error, 'error');
    }
  };
  useEffect(() => {
    // countryAPI();
  }, []);
  return (
    <CountryContext.Provider value={{ Countrydata, setcountrydata }}>
      {children}
    </CountryContext.Provider>
  );
}

export default CountryProvider;
