import React from "react";
import { dateFormatter } from "../../AdminUtils/Utils";


const GRStage4Table = ({ data }) => {
  return (
    <div className="col-12">
      <div className="table-responsive">
        <table className="table">
          <thead className="text-uppercase text-nowrap">
            <tr>
              <th>
                <div>Settlement Date</div>
              </th>
              <th>
                <div>Bet On</div>
              </th>
              <th>
                <div>Bet Type</div>
              </th>
              <th>
                <div>Odds</div>
              </th>
              <th>
                <div>Amount</div>
              </th>
              <th>
                <div>Profit/Loss</div>
              </th>
              <th>
                <div>IP Address</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr>
                <td>{dateFormatter(item?.updatedAt,true)}</td>
                <td>{item?.betOn}</td>
                <td>{item?.betType}</td>
                <td>{item?.odds}</td>
                <td>{item?.amount}</td>
                <td
                  className={
                    item?.status !== "lost"
                      ? "admin-forms-success-text"
                      : "admin-forms-error-text"
                  }
                >
                  {item?.status==="lost"?`-${item?.amount}`:`+${item?.potentialWin}`}
                </td>
                <td>{item?.ip}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GRStage4Table;
