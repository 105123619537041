import React, { useEffect } from "react";
import Profile from "./AccountSection/Profile";
import Wallet from "./AccountSection/Wallet";
import Affiliate from "./AccountSection/Affiliate";
import Settings from "./AccountSection/Settings";
import Withdraw from "./AccountSection/Withdraw";
import Deposits from "./AccountSection/Deposits";
import AppFooter from "../../components/AppFooter/AppFooter";
import Transaction from "./AccountSection/Transaction";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLogoutModal } from "../../hooks/useLogout";
import Converter from "./AccountSection/Converter";
import { useLocation } from "react-router-dom";

const array = [
  // { name: "Profile", img: "profile" },
  { name: "Wallet", img: "wallet" },
  { name: "Converter", img: "switch" },
  { name: "Affiliate", img: "affiliate" },
  { name: "Deposits", img: "deposits" },
  { name: "Withdrawals", img: "withdraw" },
  { name: "Transactions", img: "Transactions" },
  { name: "Settings", img: "setting" },

];

function AccountTab() {
  const accontsstore = useSelector((state) => state.accounts);
  const location = useLocation();
  const isHomeTab = location?.state?.tabname || '';
  const isopen = location?.state?.tabopen || ""
  const isopenAccordian = location?.state?.openaccordian || ""
  console.log("accontsstore", accontsstore);
  const [tabopen, setTabopen] = useState(isopen);
  // console.log("tabopen", tabopen);
  const [openaccordian, setAccordian] = useState(isopenAccordian);
  const { setIsLogoutOpen } = useLogoutModal();

  useEffect(() => {
    if (!isHomeTab) {
      setTabopen(accontsstore.length ? accontsstore : "Wallet");
    }
  }, [accontsstore]);

  const logoutFunction = () => {
    setIsLogoutOpen(true)
    // localStorage.removeItem(TOKEN_NAME);
    // localStorage.removeItem(BT_TOKEN_NAME);
    // setToken("");
    // setBtToken("");
    // const auth = getAuth();
    // dispatch(getUserDetails({}))
    // signOut(auth)
    //   .then(() => {
    //     // succesToaster("Logged Out")
    //   })
    //   .catch((error) => {
    //     // An error happened.
    //   });
  };
  console.log(!isHomeTab, tabopen, openaccordian)

  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage">
            <div className="container-flui accountTab">
              <div className="row d-md-flex d-none">
                {!isHomeTab &&
                  <div className="col-md-3 col-lg-3 col-xxl-3">
                    <nav>
                      <div
                        className="nav nav-tabs tabMain flex-column border-0"
                        id="nav-tab"
                        role="tablist"
                      >
                        {array.map((item) => (
                          <button
                            key={item?.name}
                            className={`nav-link accountBtn d-flex align-items-center ${tabopen == `${item.name}` ? "active" : ""
                              }`}
                            onClick={() => setTabopen(item.name)}
                          >
                            <span className="accounttabIcon d-flex align-items-center justify-content-center">
                              <img
                                src={`assets/img/sidebar/${item.img}.png`}
                                alt="profile"
                                className="h-100 w-100 img-fluid"
                              />
                            </span>
                            {item.name}
                          </button>
                        ))}
                        <div onClick={logoutFunction}
                          className="nav-link accountBtn text-decoration-none d-flex align-items-center cursor-pointer"
                        >
                          <span className="accounttabIcon d-flex align-items-center justify-content-center">
                            <img
                              src="assets/img/sidebar/logout.png"
                              alt="Log Out"
                              className="h-100 w-100 img-fluid"
                            />
                          </span>
                          Log Out
                        </div>
                      </div>
                    </nav>
                  </div>
                }
                <div className="col-md-9 col-lg-9 col-xxl-9">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className={`tab-pane fade show active`}>
                      <div className="accountTabcard">
                        {
                          // tabopen === "Profile" ? <Profile /> :
                          tabopen === "Wallet" ? <Wallet /> :
                            tabopen === "Converter" ? <Converter tabopenn={tabopen} /> :
                              tabopen === "Affiliate" ? <Affiliate /> :
                                tabopen === "Deposits" ? <Deposits tabopen={tabopen} /> :
                                  tabopen === "Withdrawals" ? <Withdraw tabopen={tabopen} /> :
                                    tabopen === "Transactions" ? <Transaction tabopen={tabopen} /> :
                                      tabopen === "Settings" ? <Settings tabopen={tabopen} /> : ''
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-md-none d-flex tabMain">
                {/* <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == "Profile" ? "" : "collapsed"
                          }`}
                        type="button "
                        onClick={() => {
                          if (openaccordian == "Profile") {
                            setAccordian('')
                          } else {
                            setAccordian("Profile")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/profile.png"
                            alt="profile"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        Profile
                      </button>
                    </div>
                    <div
                      id="Profile"
                      className={`accordion-collapse col-12 ${openaccordian == "Profile" ? "collapse show" : "collapse"
                        }`}
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard">
                          <Profile />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == 'Wallet' ? "" : "collapsed"
                          }`}
                        onClick={() => {
                          if (openaccordian == 'Wallet') {
                            setAccordian('')
                          } else {
                            setAccordian("Wallet")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/wallet.png"
                            alt="Wallet"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        Wallet
                      </button>
                    </div>
                    <div
                      id="Wallet"
                      className={`accordion-collapse col-12 ${openaccordian == 'Wallet' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard">
                          <Wallet />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian === 'Converter' ? "" : "collapsed"
                          }`}
                        onClick={() => {
                          if (openaccordian === 'Converter') {
                            setAccordian('')
                          } else {
                            setAccordian("Converter")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/switch.png"
                            alt="Wallet"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        Converter
                      </button>
                    </div>
                    <div
                      id="Converter"
                      className={`accordion-collapse col-12 ${openaccordian == 'Converter' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard">
                          <Converter />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == 'Affiliate' ? "collapse" : "collapsed"
                          }`}
                        onClick={() => {
                          if (openaccordian == 'Affiliate') {
                            setAccordian('')
                          } else {
                            setAccordian("Affiliate")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/Affiliate.png"
                            alt="Affiliate"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        Affiliate
                      </button>
                    </div>
                    <div
                      id="Affiliate"
                      className={`accordion-collapse col-12 ${openaccordian == 'Affiliate' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard">
                          <Affiliate />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == 'Deposits' ? "" : "collapsed"
                          }`}
                        type="button "
                        onClick={() => {
                          if (openaccordian == 'Deposits') {
                            setAccordian('')
                          } else {
                            setAccordian("Deposits")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/deposits.png"
                            alt="Deposits"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        Deposits
                      </button>
                    </div>
                    <div
                      id="Deposits"
                      className={`accordion-collapse col-12 ${openaccordian == 'Deposits' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard pb-md-4 pb-3">
                          <Deposits openaccordian={openaccordian} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == 'Withdrawals' ? "" : "collapsed"
                          }`}
                        onClick={() => {
                          if (openaccordian == 'Withdrawals') {
                            setAccordian('')
                          } else {
                            setAccordian("Withdrawals")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/withdraw.png"
                            alt="Withdrawals"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        Withdrawals
                      </button>
                    </div>
                    <div
                      id="Withdrawals"
                      className={`accordion-collapse col-12 ${openaccordian == 'Withdrawals' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard">
                          <Withdraw openaccordian={openaccordian} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row accordion-item border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == 'Deposits' ? "" : "collapsed"
                          }`}
                        type="button "
                        onClick={() => {
                          if (openaccordian == 'Deposits') {
                            setAccordian('')
                          } else {
                            setAccordian("Deposits")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/deposits.png"
                            alt="Deposits"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        Depositsdxcvzxd
                      </button>
                    </div>
                    <div
                      id="Deposits"
                      className={`accordion-collapse col-12 ${openaccordian == 'Deposits' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard pb-md-4 pb-3">
                          <Deposits openaccordian={openaccordian} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row accordion-item settingtab border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${openaccordian == 'Settings' ? "" : "collapsed"
                          }`}
                        type="button "
                        onClick={() => {
                          if (openaccordian == 'Settings') {
                            setAccordian('')
                          } else {
                            setAccordian("Settings")
                          }
                        }}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/setting.png"
                            alt="Settings"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        Settings
                      </button>
                    </div>
                    <div
                      id="Settings"
                      className={`accordion-collapse col-12 ${openaccordian == 'Settings' ? "collapse show" : "collapse"
                        }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="accountTabcard">
                          <Settings openaccordian={openaccordian} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row accordion-item settingtab border-0 tabMain">
                    <div className="col-12 accordion-header">
                      <button
                        className={`accordion-button accountBtn rounded-2 d-flex align-items-center collapsed`}
                        type="button "
                        onClick={logoutFunction}
                      >
                        <span className="accounttabIcon d-flex align-items-center justify-content-center">
                          <img
                            src="assets/img/sidebar/logout.png"
                            alt="logout"
                            className="h-100 w-100 img-fluid"
                          />
                        </span>
                        Log Out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AppFooter />
      </main >

      {/* <Profile/>
    <Wallet/>
    <Affiliate/>
    <Settings/>
    <Withdraw/> */}
    </>
  );
}

export default AccountTab;
