import React from "react";

const GRStage1Table = ({ data,setSelectUser,setStage,loader }) => {
  return (
    <div className="col-12">
      <div className="table-responsive">
        <table className="table">
          <thead className="text-uppercase text-nowrap">
            <tr>
              <th>
                <div>Username</div>
              </th>
              <th>
                <div>Game Count</div>
              </th>
              <th>
                <div>Bets</div>
              </th>
              <th>
                <div>Lost</div>
              </th>
              <th>
                <div>Won</div>
              </th>
              <th>
                <div>Profit/Loss</div>
              </th>
            </tr>
          </thead>
          {!loader && <tbody>
            {
              data?.length > 0 ?
            data.map((item) => (
              <tr>
                <td className="fw-bold cursor-pointer" onClick={()=>{
                  setStage(2)
                  setSelectUser(item)
                }} >{item?.username}</td>
                <td>{item?.gameCount}</td>
                <td>{item?.betCount}</td>
                <td>{item?.totalLoss}</td>
                <td>{item?.totalWins}</td>
                <td
                  className={
                    item?.totalAmountWon - item?.totalAmountLost > 0
                      ? "admin-forms-success-text"
                      : "admin-forms-error-text"
                  }
                >
                  {item?.totalAmountWon - item?.totalAmountLost > 0
                    ? `+${Number(item?.totalAmountWon - item?.totalAmountLost)?.toFixed(2)}`
                    : Number(item?.totalAmountWon - item?.totalAmountLost)?.toFixed(2)}
                </td>
              </tr>
            )) : 
            <tr>
              <td colSpan={6} className="text-center" style={{fontSize: 16, fontWeight: 500}}>No Data Available</td>
            </tr>
            }
          </tbody>}
        </table>
      </div>
    </div>
  );
};

export default GRStage1Table;
