import React, { useEffect, useRef, useState } from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'

const CommissionReportPage = () => {
//   const [show, setShow] = useState(false)
//   const [collapsing, setCollapsing] = useState(false)
//   const demoRef = useRef();
//   const elemHeight = demoRef?.current?.offsetHeight;
//   const timeOut = ''

// const handleClick = ()=> {
//   setShow(!show)
//   setCollapsing(true)
//   timeOut = setTimeout(() => {
//     setCollapsing(false)
//   }, 300);
// }

// useEffect(()=> {
//   return ()=> {
//     clearInterval(timeOut)
//   }
// }, [collapsing, show])


  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage tradingPages">
            {/* start here */}
            <div className="row mx-0 mt-2">
              <div className="col-12">
                <div className="row gap-3">
                  <div className="col-12 px-0">
                    <div className="row g-3 align-items-center ">
                      <div className="col-md">
                        <div className="tradingSecHead">Commission Statement</div>
                      </div>
                      
                      <div className="col-lg-2 col-md-3 col-6">
                        <div className="reportsDropdownTitle">From</div>
                        <input type="date" class="dateTimeInput" value=""></input>
                      </div>
                      <div className="col-lg-2 col-md-3 col-6">
                        <div className="reportsDropdownTitle">To</div>
                        <input type="date" class="dateTimeInput" value=""></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 px-0 dambleTable sectionInner tableCard tab-content tab-pane table-responsive mb-0 mt-4">
                <table className="table align-middle text-white text-nowrap mb0">
                  <thead>
                    <tr>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          SR.NO.
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          EVENT NAME
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                        EVENT DATE
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          COMMISION
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    No Data
                  </tbody>
                </table>
              </div>
              {/* <div className="col-12">
                <div className="p-2 text-white bg-danger" onClick={handleClick}>Test Btn</div>
                <div className={`p-2 text-white ${show ? '' : 'd-none'} ${collapsing ? 'collapsing' : ''}`}  ref={demoRef}>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis iure asperiores libero corporis quam illum doloribus neque nisi quia numquam?
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas excepturi, ea quis voluptates quae quo doloremque similique velit eveniet cupiditate nisi nostrum ipsum distinctio mollitia deserunt suscipit harum labore quas consequuntur amet vero sapiente voluptatem ullam! Eaque earum accusantium ea ipsa iusto facilis delectus, voluptatum cumque omnis. Laborum praesentium rerum ducimus modi incidunt temporibus blanditiis quibusdam. Ex sit dolore molestiae inventore temporibus sequi totam, quo, voluptas rerum odio culpa repellendus esse optio molestias! Et, soluta voluptatibus, dolorum quos, at exercitationem sit similique ad facere quae eum incidunt quam laborum cumque eius molestias? Laborum, fugit dolor. Dolores iste aut optio esse.
                </div>
              </div> */}
            </div>

            {/* end here */}
          </div>
        </div>

        {/*  **** Footer section *** */}
        <AppFooter />
      </main>
    </>
  )
}

export default CommissionReportPage
