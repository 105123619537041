import React, { useEffect, useState } from 'react'
import BackBtnIcon from './../../fantasyAssets/img/backArrow.png'
import ContestCard from '../contestCard/ContestCard';
import filterIcon from '../../fantasyAssets/img/filterIcon.png'
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONST } from '../../../constants/routeConstant';
import { selectingTeam } from '../commonFunctions';

const FilteredDataOffcanvas = ({
  isOpen,
  setIsOpen,
  setShowFilter,
  addedFilter,
  contestData,
  TeamSelection,
  match
}) => {
  const handleFilter = () => {
    setIsOpen(false)
    setShowFilter(true)
    document.body.classList.add('offcanvasOpen')
  }
  const [filteredCon, setFilterCon] = useState([])
  const navigate = useNavigate()

  // function for applying filter
  const Filtering = () => {
    const filteredContests = [];

    contestData.forEach(contest => {
      contest.contest?.forEach(elem => {
        let isMatch = true;

        addedFilter?.forEach(item => {
          switch (item.type) {
            case 'Entry':
              if (isMatch && !(elem.entryfee >= item.min && elem.entryfee <= item.max)) {
                isMatch = false;
              }
              break;
            case 'Number of Teams':
              if (isMatch && !(elem.maximum_user - elem.joinedusers >= item.min && elem.maximum_user - elem.joinedusers <= item.max)) {
                isMatch = false;
              }
              break;
            case 'Prize Pool':
              if (isMatch && !(elem.win_amount >= item.min && elem.win_amount <= item.max)) {
                isMatch = false;
              }
              break;
            case 'Contest Type':
              if (isMatch) {
                switch (item.label) {
                  case 'Single Entry':
                    isMatch = elem.multi_entry === 0;
                    break;
                  case 'Multi Entry':
                    isMatch = elem.multi_entry === 1;
                    break;
                  case 'Single Winner':
                    isMatch = elem.totalwinners === 1;
                    break;
                  case 'Multi Winner':
                    isMatch = elem.totalwinners > 1;
                    break;
                  case 'Confirmed':
                    isMatch = elem.confirmed_challenge;
                    break;
                  default:
                    isMatch = false;
                }
              }
              break;
            default:
              isMatch = false;
          }
        });

        if (isMatch) {
          filteredContests.push(elem);
        }
      });
    });

    // filteredContests now contains the filtered contest elements
    setFilterCon(filteredContests);
  };

  useEffect(() => {
    if (isOpen) {
      Filtering()
    }
  }, [isOpen, addedFilter])

  return (
    <>
      <div className={`offcanvas filterOffcanvas offcanvas-bottom ${isOpen ? 'show' : ''}`} tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <div className="row w-100 mx-0 justify-content-between">
            <div className='col ps-0'>
              <img src={BackBtnIcon}
                onClick={() => {
                  setIsOpen(false);
                  document.body.classList.remove('offcanvasOpen')
                }}
                alt="" />
              Filtered Contest
            </div>
            <div className="col-auto filterBtnCol pe-0">
              <button className='shadow-none bg-transparent p-0 border-0 d-flex align-items-center filterBtn'
                onClick={handleFilter}
              >
                <img src={filterIcon} alt="" />
              </button>
            </div>
          </div>
        </div>

        <div className="offcanvas-body pt-0">
          <div className="row mt-4">
            {/* <div className="col-12 contestHeading">
              <div className="title">{"item?.catname"}</div>
              <div className="subtitle">{"item?.sub_title"}</div>
            </div> */}
            {
              filteredCon.length === 0 ? (
                <div className='w-100 h-100 d-flex justify-content-center text-light' >No Data Available</div>
              ) : (
                filteredCon?.map((elem) => (
                  <ContestCard
                    // practice={item?.name?.toLowerCase().includes("practice")}
                    // practice={item?.name?.toLowerCase().includes("practice")}
                    win_amount={elem?.win_amount}
                    entryfee={elem?.entryfee}
                    maximum_user={elem?.maximum_user}
                    joinedusers={elem?.joinedusers}
                    winning_percentage={elem?.winning_percentage}
                    multi_entry={elem?.multi_entry}
                    confirmed_challenge={elem?.confirmed_challenge}
                    handleJoin={(e) => { e.stopPropagation(); TeamSelection(elem) }}
                    handleLeader={(e) => {
                      e.stopPropagation();
                      navigate(ROUTES_CONST.FANTASY_LEADERbOARD, {
                        state: {
                          data: match,
                          el: elem,
                          contest: elem,
                          // selectingTeam: TeamSelection,
                          live: true,
                          // type: type,
                        }
                      })
                    }}
                  // win_amount={elem?.win_amount}
                  // entryfee={elem?.entryfee}
                  // maximum_user={elem?.maximum_user}
                  // joinedusers={elem?.joinedusers}
                  // winning_percentage={elem?.winning_percentage}
                  // multi_entry={elem?.multi_entry}
                  // confirmed_challenge={elem?.confirmed_challenge}
                  />
                ))
              )
            }
          </div>

        </div>
        {/* <div className="offcanvas-footer">
          <button className="subFilterBtn" onClick={() => setIsOpen(false)}>Apply Filter</button>
        </div> */}
      </div>
    </>
  )
}

export default FilteredDataOffcanvas