import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { adminGetAPIAuth } from '../../AdminServices';
import UpdateUpiDetails from './UpdateUpiDetails';
import AddUpiDetails from './AddUpiDetails';

const UpiDetails = () => {
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState('')

  const getPaymentDetails = async () => {
    try {
      const res = await adminGetAPIAuth("user/getUserPaymentDetails?flag=UPI")
      setTableData(res?.data?.data?.data);
    } catch (error) {
      console.log('error------', error)
    }
  }

  useEffect(() => {
    getPaymentDetails()
  }, [])

  return (
    <>
      <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner ">
                <header className="page-title-bar justify-content-between row">
                  <h1 className="page-title col-md"> UPI DETAILS </h1>
                  <div className=" page-section align-items-center w-auto m-0  flex-row-reverse col-md-auto">
                    {!tableData.length > 0 && <AddUpiDetails getPaymentDetails={getPaymentDetails}/>}
                  </div>
                </header>
                <div className="d-flex justify-content-between my-3">
                  <div>
                    <ol className="breadcrumb"></ol>
                  </div>
                </div>

                <div className="page-section">
                  <div className=" card-fluid">
                    <div className="card-body px-0">
                      <div className="table-responsive">
                        <Table>
                          <thead>
                            <tr>
                              <th
                                style={{ minWidth: "140px", cursor: "pointer" }}
                                className="p-2 d-flex justify-content-evenly"
                              >
                                <span>S.No</span>
                              </th>

                              <th
                                style={{ minWidth: "150px", cursor: "pointer" }}
                                className="p-2"
                              >
                                <span>Type</span>
                              </th>
                              <th
                                style={{ minWidth: "140px", cursor: "pointer" }}
                                className="p-2"
                              >
                                <span>Display Name</span>
                              </th>
                              <th
                                style={{ minWidth: "140px", cursor: "pointer" }}
                                className="p-2"
                              >
                                <span>UPI Details</span>
                              </th>
                              <th
                                style={{ minWidth: "140px", cursor: "pointer" }}
                                className="p-2"
                              >
                                <span>Status</span>
                              </th>
                              <th
                                style={{ minWidth: "140px", cursor: "pointer" }}
                                className="p-2"
                              >
                                <span>Action</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData?.length > 0 ? tableData?.map((item, index) => (
                              <tr key={item._id}>
                                <td className="align-middle  d-flex justify-content-evenly pt-3">{index + 1}</td>
                                <td className="align-middle">{item.type}</td>
                                <td className="align-middle">{item.displayName}</td>
                                <td className="align-middle">{item.upiDetails}</td>
                                <td className="align-middle">{item.status}</td>
                                <td className="align-middle" onClick={() => {
                                  setId(item)
                                }}>
                                  <button
                                    class="btn btn-primary"
                                    type="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRight"
                                    aria-controls="offcanvasRight"
                                  >
                                    <i class="bi bi-pen"></i>
                                  </button>
                                </td>
                              </tr>
                            )) : 
                            <tr>
                            <td colSpan={6} className="text-center" style={{fontSize: 16, fontWeight: 500}}>Please add UPI details</td>
                          </tr>}
                          </tbody>
                        </Table>
                      </div>

                      <div
                        class='offcanvas offcanvas-end'
                        tabindex="-1"
                        id="offcanvasRight"
                        aria-labelledby="offcanvasRightLabel"
                      >
                        <div class="offcanvas-header">
                          <h5
                            class="offcanvas-title"
                            id="offcanvasRightLabel"
                          >
                            Edit Details
                          </h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="offcanvas-body">
                          <UpdateUpiDetails item={id} getPaymentDetails={getPaymentDetails} />
                        </div>
                      </div>

                    </div>
                  </div>
                  <hr className="my-5" />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default UpiDetails
