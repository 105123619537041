import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { useBtAuth } from "../../../hooks/useBtAuth";
import { useAuth } from "../../../hooks/useAuth";
import { useAuthModal } from "../../../hooks/useAuthModal";
import * as Yup from "yup";
import { signInWithEmailAndPassword } from "firebase/auth";
import { postAPI } from "../../../service/apiInstance";
import { auth } from "../../../config/firebase";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { useSelector } from "react-redux";
import { USER_LANG } from "../../../constants";
import { Spinner } from "react-bootstrap";

const validationSchema = Yup.object({
    password: Yup.string().required("This field is required"),
});

const LoginPassword = ({ setEmailPassword, setLogOn, emailOrNumber, setForgetpass, emailPassword }) => {
    const { setBtToken } = useBtAuth()
    const { setToken } = useAuth()
    // const { resetForm } = useFormik()//throws an error 
    const { authModalObject, setAuthModalObject } = useAuthModal()
    const [isHidden, setIsHidden] = useState(true)
    const ipAddress = useSelector(state => state.ipAddress)
    const country = useSelector(state => state.country)
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        if (!authModalObject.isAuthOpen) {
            setEmailPassword(false)
            setLogOn(true)
        }
    }, [authModalObject.isAuthOpen])

    async function signInWEAP(values) {
        try {
            if (emailOrNumber) {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    emailOrNumber,
                    values.password
                );

                // Signed in
                const lang = localStorage.getItem(USER_LANG)
                const body = {
                    email: emailOrNumber,
                    password: values.password,
                    ipAddress: ipAddress ? ipAddress : "",
                    country: country ? country : "",
                    language: lang
                };
                const user = userCredential.user.auth.email;
                const res = await postAPI("user/login-user", body);
                if (res?.data?.success) {
                    // alert("inside")
                    setBtToken(res?.data?.data?.betByToken);
                    setToken(res?.data?.data?.auth_token);
                    succesToaster("Login successful")
                    // setIsSignInOpen(false);
                    setEmailPassword(false)
                    setLogOn(true)
                    // resetForm()
                    setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))

                } else {
                    errorToaster(res?.data?.message);
                }
            }

        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            // errorToaster(error.message)
            errorToaster("Invalid credentilas !!")
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        if (!emailPassword) {
            setIsLoading(false)
        }
    }, [emailPassword])

    return (
        <Formik
            initialValues={{
                emailOrNumber: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                setIsLoading(true)
                signInWEAP(values)
            }}
        >
            {
                ({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                }) => (
                    <form>
                        <div className="row gap-3 gx-0">

                            <div className="col-12 inputdata passwordInput pt-0">
                                <label
                                    htmlFor="password1"
                                    className="inputLabel"
                                >
                                    PASSWORD
                                    <span>*</span>
                                </label>
                                <div className="position-relative passwordGroup">
                                    <input
                                        type={`${isHidden ? "password" : "text"}`}
                                        id="password1"
                                        className="form-control inputlogin border-0 shadow-none"
                                        placeholder="Enter your password"
                                        name="password"
                                        value={values.password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    {
                                        isHidden ? (
                                            <img onClick={() => setIsHidden(false)} src="assets/img/newIcon/eyeclose.svg" className="eye active  open cursor-pointer" alt="" />
                                        ) : (
                                            <img onClick={() => setIsHidden(true)} src="assets/img/newIcon/eyeopen.svg" className="eye active close cursor-pointer" alt="" />
                                        )
                                    }
                                </div>
                                <ErrorMessage>
                                    {errors.password && touched.password && errors.password}
                                </ErrorMessage>
                                {/* <div className="inputdata forgotmain">
                                    <span
                                        className="forgotPassword text-decoration-none"
                                        onClick={handleClick}
                                    >
                                        Forgot password?
                                    </span>
                                </div> */}
                            </div>
                        </div>
                        <div className="row mt-4 gap-4">
                            <div className="col-12 mx-auto">
                                <span
                                    className="playBtn position-relative d-flex align-items-center justify-content-center cursor-pointer"
                                >
                                    <button
                                        type="submit"
                                        className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"
                                        onClick={handleSubmit}
                                    >
                                        {
                                            isLoading ? <Spinner size="sm" color="dark" /> : "Play now!"
                                        }
                                    </button>
                                </span>
                            </div>
                        </div>
                    </form>
                )
            }
        </Formik>
    );
};

export default LoginPassword;
