import React, { useState, useEffect } from 'react'
import { ADMIN_FRONTEND_LOGGED_IN_ID, AUTH_MODAL_TABS, TOKEN_NAME } from '../../constants'
import { ROUTES_CONST } from '../../constants/routeConstant'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useAuthModal } from '../../hooks/useAuthModal'
import { useAuth } from '../../hooks/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getAPIAuth } from '../../service/apiInstance'
import { userFullDetailsAction } from '../../store/action'
import { Spinner } from 'react-bootstrap'


const FantasyProtectedRoute = (props) => {
    const { Component } = props
    const [isLoggedIn, setLoggedIn] = useState(false)
    const { token } = useAuth();
    const { setAuthModalObject } = useAuthModal();
    const [error, setError] = useState('')
    const userFullDetails = useSelector(state => state?.userFullDetails)
    const navigate = useNavigate()
    const adminID = localStorage.getItem(ADMIN_FRONTEND_LOGGED_IN_ID)
    const dispatch = useDispatch()
    const [Loading, setLoading] = useState(false)
    console.log({ isLoggedIn, userFullDetails })
    console.log("from protected", userFullDetails);

    /// userDetails for user loggin in through login/signUp modal
    const getUserInfo = async () => {
        setLoading(true)

        try {
            const res = await getAPIAuth(`user/profile/${adminID}`, token)
            // const res = await axios.get(`${adminBaseUrl}/user/profile/${adminID?.value}`, {
            //   headers: {
            //     "Content-Type": "application/json; charset=utf-8",
            //     Accept: "application/json",
            //     Authorization: `Bearer ${token}`,
            //   }
            // })
            if (res?.data?.data?.success) {
                dispatch(userFullDetailsAction(res.data.data.data))
            }
        } catch (error) {
            //error
            setError(error.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!userFullDetails?._id) {
            getUserInfo()
        }
    }, [])

    useEffect(() => {
        if (!token) {
            navigate(ROUTES_CONST.INDEX)
            setAuthModalObject((pre) => ({
                selectedTab: AUTH_MODAL_TABS.LOG_IN,
                isAuthOpen: true,
            }))
        } else {
            setAuthModalObject((pre) => ({
                isAuthOpen: false,
            }))
            setLoggedIn(true)
            if (!error) {
                if (!!userFullDetails?._id) {
                    console.log("userFullDetails?._id", userFullDetails)
                    if (!userFullDetails?.isCricket) {
                        console.log(userFullDetails?.isCricket)
                        navigate(ROUTES_CONST.INDEX)
                    }
                }
            } else {
                navigate(ROUTES_CONST.INDEX)
            }
        }

        // if (!token) {
        //     navigate(ROUTES_CONST.INDEX)
        //     setAuthModalObject((pre) => ({
        //         selectedTab: AUTH_MODAL_TABS.LOG_IN,
        //         isAuthOpen: true,
        //     }))
        // } else {
        //     setAuthModalObject((pre) => ({
        //         isAuthOpen: false,
        //     }))
        //     setLoggedIn(true)
        //     if (!!userFullDetails?._id) {
        //         console.log("userFullDetails?._id", userFullDetails)
        //         if (!userFullDetails?.isCricket) {
        //             console.log(userFullDetails?.isCricket)
        //             navigate(ROUTES_CONST.INDEX)
        //         }
        //     } else {
        //         navigate(ROUTES_CONST.INDEX)
        //     }
        // }
    }, [token, userFullDetails?._id])
    return (
        // userFullDetails?.isCricket && isLoggedIn ? <Component /> : (<Navigate to={ROUTES_CONST.INDEX}/>)
        // isLoggedIn ? <Component /> : (<Navigate to={ROUTES_CONST.INDEX}/>)
        Loading ? (<Spinner />) : (isLoggedIn && <Component />)
    )
}

export default FantasyProtectedRoute