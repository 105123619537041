import React, { useEffect, useState } from 'react'
import { useWalletModal } from '../../hooks/useWallet'
import Deposit from './Deposit'
import Withdraw from './Withdraw'
import { WALLET_MODAL_TABS } from '../../constants'
import { useTranslation } from 'react-i18next'
import UpdateWithdrawDetails from './UpdateWithdrawDetails'
import { getAPIAuth } from '../../service/apiInstance'

const NewWallet = () => {
  const { walletModalObject, setWalletModalObject } = useWalletModal()
  const { t } = useTranslation()
  const walletLabels = t("wallet", { returnObjects: true })
  const [userWithdrawalDetail, setUserWithdrawalDetails] = useState({})

      // api for getting user withdraw details
      const getUserWithdrawalDetails = async ()=> {
        try {
            const res = await getAPIAuth('user/getUserWithdrawalDetails')
            setUserWithdrawalDetails(res?.data?.data?.data)
            if(res?.data?.data?.data?.accountHolderName) {
              setWalletModalObject((prev) => ({
                ...prev,
                selectedTab: WALLET_MODAL_TABS?.WITHDRAW
              }))
            }else {
              setWalletModalObject((prev) => ({
                ...prev,
                selectedTab: WALLET_MODAL_TABS?.UPDATE_WITHDRAW_DETAILS
              }))
            }
        } catch (error) {
            setWalletModalObject((prev) => ({
                ...prev,
                selectedTab: WALLET_MODAL_TABS?.UPDATE_WITHDRAW_DETAILS
              }))
        }
    }


    useEffect(()=> {
      if(walletModalObject?.selectedTab === WALLET_MODAL_TABS?.WITHDRAW) {
        getUserWithdrawalDetails()
      }
    }, [walletModalObject?.selectedTab])//eslint-disable-line

  return (
    <>
      <div className={`modal fade bankModal ${walletModalObject?.isWalletOpen ? 'show d-block' : ''}`} id="bankModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog bankdialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content bankContent">
            <div className="modal-header bankHeader border-0">
              {
                walletModalObject?.selectedTab === WALLET_MODAL_TABS?.UPDATE_WITHDRAW_DETAILS ? 
                <>
                  <div className="row">
                    <div className="col-12 text-white editHeader d-flex align-items-center gap-3">
                        <div className="backIcon"
                          onClick={() => setWalletModalObject((prev) => ({
                            ...prev,
                            selectedTab: userWithdrawalDetail?.accountHolderName ? WALLET_MODAL_TABS?.WITHDRAW : WALLET_MODAL_TABS?.DEPOSIT
                          }))}>
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path></svg>
                        </div>
                      {userWithdrawalDetail?.accountHolderName ? 'Edit' : 'Add'} Withdrawal Details
                    </div>
                  </div>
                </>
                : <>
                <nav className="overflow-x-auto py-1">
                  <div className="nav nav-tabs border-0 flex-nowrap text-nowrap bankTab">
                    <button
                      onClick={() => setWalletModalObject((prev) => ({
                        ...prev,
                        selectedTab: WALLET_MODAL_TABS?.DEPOSIT
                      }))}
                      className={`nav-link bankBtn ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.DEPOSIT ? 'active' : ''}`}
                    >{walletLabels.Deposit}</button>
                    <button
                      // onClick={() => setWalletModalObject((prev) => ({
                      //   ...prev,
                      //   selectedTab: WALLET_MODAL_TABS?.WITHDRAW
                      // }))}
                      onClick={getUserWithdrawalDetails}
                      className={`nav-link bankBtn ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.WITHDRAW ? 'active' : ''}`}
                    >{walletLabels.Withdraw}</button>
                  </div>
                </nav> 
                <div className="ps-3">
                  <button type="button"
                    onClick={() => setWalletModalObject((prev) => ({
                      isWalletOpen: false,
                    }))}
                    className="btn-close p-0 shadow-none border-0 d-flex align-items-center justify-content-center">
                    <img src="assets/img/sidebar/close.png" alt="close" className="h-100 w-100 img-fluid" />
                  </button>
                </div>
                </>
              }
            </div>
            <div className="modal-body bankbody pb-4 ">
              <div className="tab-content banktabContent">
              {
                walletModalObject?.selectedTab === WALLET_MODAL_TABS?.DEPOSIT ?
                  <Deposit setWalletModalObject={setWalletModalObject}/> :
                walletModalObject?.selectedTab === WALLET_MODAL_TABS?.WITHDRAW ?
                  <Withdraw setWalletModalObject={setWalletModalObject} userWithdrawalDetail={userWithdrawalDetail}/> : 
                walletModalObject?.selectedTab === WALLET_MODAL_TABS?.UPDATE_WITHDRAW_DETAILS ? 
                  <UpdateWithdrawDetails setWalletModalObject={setWalletModalObject} userWithdrawalDetail={userWithdrawalDetail}/> : ''
                } 
              </div>
            </div>
          </div>
        </div>
      </div> 
    </>
  )
}

export default NewWallet