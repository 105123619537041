import React, { useEffect } from 'react'
import { useState } from 'react'
import PromotionCard from './PromotionCard'
import { getAPI } from '../../service/apiInstance'
import { Spinner } from 'react-bootstrap'

const ArchiveTab = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  
  const getArchivedPromotions = async () => {
    setLoading(true)
    try {
      const res = await getAPI(`get-promotion-data?isArchived=true&page=1&limit=6`)
      if (res?.data?.data.success) {
        setData(res?.data?.data.data)
        setPageCount(res?.data?.pageCount)
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getArchivedPromotions()
  }, [])

  const getMoraData = async () => {
    try {
      const res = await getAPI(`get-promotion-data?isArchived=true&page=${page}&limit=6`)
      if (res?.data?.data.success) {
        setData(pre => ([...pre, ...res?.data?.data.data]))
      } else {
        setData(data)
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    if (page >= 2) {
      getMoraData()
    }
  }, [page])

  return (
    <div className="row g-3">
      {!loading ?
        data?.length > 0 ?
          data?.map((item) => (
            <PromotionCard 
            // disabled={true}
             data={item} />
          ))
          : (
            <div className="w-100 h-100 text-light d-flex justify-content-center align-items-center">
              No data available
            </div>
          )
        : (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="light" size="lg" />
          </div>
        )
      }

{
        page >= pageCount ? (
          <></>
        ) : (
          <div className="col-12 mt-5 text-center">
            <button onClick={() => setPage(pre => pre + 1)} className="loadMoreBtn">LOAD MORE</button>
          </div>
        )
      }
    </div>
  )
}

export default ArchiveTab