import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import * as Yup from "yup";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../config/firebase";
import { postAPI } from "../../../service/apiInstance";
import { useBtAuth } from "../../../hooks/useBtAuth";
import { useAuth } from "../../../hooks/useAuth";
import { useAuthModal } from "../../../hooks/useAuthModal";
import { useSelector } from "react-redux";
import { AUTH_MODAL_TABS, USER_LANG, adminBaseUrl } from "../../../constants";
import { Spinner } from "react-bootstrap";
import axios from "axios";

const validationSchema = Yup.object({
    password: Yup.string().required("This field is required"),
});

const PasswordField = ({ checks, email, setPasswordView, setActive, username, passwordView, referral, fullName, mobile }) => {
    const { setBtToken } = useBtAuth()
    const { setToken } = useAuth()
    const { authModalObject, setAuthModalObject } = useAuthModal()
    const [isHidden, setIsHidden] = useState(true)
    const ipAddress = useSelector(state => state.ipAddress)
    const country = useSelector(state => state.country)
    const [isLoading, setIsLoading] = useState(false)
    const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
    const [passError, setPassError] = useState(false)

    useEffect(() => {
        if (!authModalObject.isAuthOpen) {
            setPasswordView(false)
            setActive(true)
        }
    }, [authModalObject.isAuthOpen])

    const onRegister = async (values) => {
        try {
            const payload = {
                name: fullName,
                username: username,
                email: email,
                mobile: mobile,
                password: values.password,
                refercode: referral,
                ipAddress : ipAddress,
                country : country
            }
            // const res = await axios.post(`${adminBaseUrl}/user/self/register`, payload, {
            //     headers: {
            //         "Content-Type": "application/json; charset=utf-8",
            //         Accept: "application/json",
            //     }
            // })
            if(!passwordRegx.test(payload?.password)) {
                setPassError(true)
                // errorToaster('Password must be at least 8 characters and include one uppercase letter, one lowercase letter, and one number.')
            } else {
                setPassError(false)
                const res = await postAPI("user/self/register", payload)
                // console.log(re.data.data.data)
                if (res.data.data) {
                    setAuthModalObject(pre => ({
                        ...pre,
                        selectedTab: AUTH_MODAL_TABS.LOG_IN
                    }))
                }
                succesToaster('User Register Successfully')
            }

        } catch (error) {
            errorToaster(error.response.data.data.message)
        } finally {
            setIsLoading(false)
        }


    }
    async function createUserWithEmailAndPasswordF(values) {
        if (!checks.terms) {
            errorToaster("Please accept the User Agreement");
            setIsLoading(false)
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                values.password,
            );
            const user = userCredential.user;
            const lang = localStorage.getItem(USER_LANG)
            const body = {
                email: userCredential._tokenResponse.email,
                idToken: userCredential._tokenResponse.idToken,
                localId: userCredential._tokenResponse.localId,
                refreshToken: userCredential._tokenResponse.refreshToken,
                password: values.password,
                terms: checks.terms,
                promotion: checks.promotions,
                username: username,
                ipAddress: ipAddress ? ipAddress : "",
                country: country ? country : "",
                language: lang,
                refercode: referral

            };
            const res = await postAPI("user/register-user", body);
            if (res.data.success) {
                succesToaster(res.data.message);
                setBtToken(res?.data.data?.betByToken);
                setToken(res?.data.data?.auth_token);
                setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
                // resetForm()
            } else {
                errorToaster("Invalid credentilas !!");
            }
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message.substr(9, 30);
            errorToaster(errorMessage);
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        if (!passwordView) {
            setIsLoading(false)
        }
    }, [passwordView])

    

    return (
        <Formik
            initialValues={{
                password: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                setIsLoading(true)
                // createUserWithEmailAndPasswordF(values)
                onRegister(values)
            }}
        >
            {
                ({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                }) => (
                    <form>
                        <div className="row gap-3 gx-0">
                            <div className="col-12 inputdata passwordInput pt-0">
                                <label
                                    htmlFor="password1"
                                    className="inputLabel"
                                >
                                    PASSWORD
                                    <span>*</span>
                                </label>
                                <div className="position-relative passwordGroup">
                                    <input
                                        type={`${isHidden ? "password" : "text"}`}
                                        id="password1"
                                        className="form-control inputlogin border-0 shadow-none"
                                        placeholder="enter your password"
                                        name="password"
                                        value={values.password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    {
                                        isHidden ? (
                                            <img onClick={() => setIsHidden(false)} src="assets/img/newIcon/eyeclose.svg" className="eye active  open cursor-pointer" alt="" />
                                        ) : (
                                            <img onClick={() => setIsHidden(true)} src="assets/img/newIcon/eyeopen.svg" className="eye active close cursor-pointer" alt="" />
                                        )
                                    }
                                </div>
                                {passError ? 
                                <ErrorMessage>
                                    Password must be at least 8 characters and include one uppercase letter, one lowercase letter, and one number.
                                </ErrorMessage>
                                : ''}
                                {/* <ErrorMessage>
                                    {errors.password && touched.password && errors.password}
                                </ErrorMessage> */}
                                {/* <div className="inputdata forgotmain">
                                    <span
                                        // href="javascript:;"
                                        className="forgotPassword text-decoration-none"
                                    >
                                        Forgot password?
                                    </span>
                                </div> */}
                            </div>


                        </div>
                        {/* footer section*/}
                        <div className="row mt-4 gap-4">
                            {/* button section*/}
                            <div className="col-12 mx-auto">
                                <span
                                    className="playBtn position-relative d-flex align-items-center justify-content-center">
                                    <button
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"
                                    >
                                        {
                                            isLoading ? <Spinner size="sm" color="dark" /> : "Play now!"
                                        }
                                    </button>
                                </span>
                            </div>
                        </div>
                    </form>
                )
            }
        </Formik>
    );
};

export default PasswordField;
