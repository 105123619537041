import React, { useState, } from 'react';
import Modal from 'react-bootstrap/Modal';
import './modal.css'
import Ticketcards from './ticketscards/Ticketcards';
import { useSelector } from 'react-redux';


function TicketModal({ show, setShow, data, buyTicketHandle, jackpottickets, getAllJackpotTickets, percentageSolds }) {

  console.log("jackpotticketsjackpottickets", jackpottickets);

  // let LocalStoreData = [0]

  // console.log(LocalStoreData, "LocalStoreData");

  const [ticketCards, setTicketCards] = useState(
    () => {
      const storedData = localStorage.getItem('ticketCards');
      return storedData ? JSON.parse(storedData) : [];
    }
  );

  const mainWalletData = useSelector((state) => state?.getWalletData);

  const handleClose = () => {
    setShow(false)
  }

  const handleAddNew = () => {
    const newTicketCard = {
      id: ticketCards.length + 1,

    };
    setTicketCards([...ticketCards, newTicketCard]);
    localStorage.setItem('ticketCards', JSON.stringify([...ticketCards,newTicketCard]));

  };

  return (
    <div className="Ticketsmodalsdetails">
      <Modal
        className='moddallllticketss'
        show={show}
        size='xl'
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div class="row mx-0 align-items-center w-100 justify-content-between">
            <div class="col modalTitle">
              Jackpot
            </div>
            <div class="col-auto modalClose" onClick={handleClose}>
              <img src="assets/img/sidebar/close.png" alt="" />
            </div>
          </div>
        </Modal.Header>

        <div className="row">
          <div className="col-12">
            <div className="main-para-seeection">
              <div className="ticketmodalcards px-4">
                <div className="ticketmodalleftdata">
                  <span>Available Tickets : {data?.total_tickets - data?.ticket_sold}</span>
                  <span>Price : {data?.ticket_price}</span>

                </div>

                <div className="ticketmodalbottomdata">
                  <h4>Balance : {mainWalletData?.userBalance ? Number(mainWalletData?.userBalance).toFixed(2) : '0.00'}</h4>
                </div>



                {
                  percentageSolds === 100 ? (
                    <>
                      <div className="ticketmodalbuttondata">
                        <button className='addBtn'>SOLD OUT</button>

                      </div>
                    </>
                  ) : (
                    <>
                      <div className="ticketmodalbuttondata">
                        <button className='addBtn'
                          onClick={handleAddNew}
                        >Add new</button>

                      </div>
                    </>
                  )
                }

              </div>


              <Ticketcards
                JackPotData={ticketCards}
                data={data}
                getAllJackpotTickets={getAllJackpotTickets}
                jackpottickets={jackpottickets}
              />

            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default TicketModal