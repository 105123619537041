import axios from 'axios'
import { ADMIN_FRONTEND_LOGGED_IN_ID, sportsBaseUrl, STOCK_USER_ID, TOKEN_NAME } from '../../constants'

const token = localStorage.getItem(TOKEN_NAME)
// no auth token required
export const sportsGetAPI = async (url, body) => {
    // try {
    const response = await axios.get(`${sportsBaseUrl}/${url}`, body, {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",

        }
    })
    return response
    // } catch (error) {
    // console.log("error=>", error)
    // return error
    // }

}

export const sportsGetAPIAuth = async (url, tokenInit, admin) => {
    const bURL = sportsBaseUrl
    const token = localStorage.getItem(TOKEN_NAME)
    // const token ="lfaskdfhsjkhfkajsdfkasfsakfhasjkfkjjshfkhfkhiuerhfjksdf"
    try {
        const response = await axios.get(`${bURL}/${url}`, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Accept: "application/json",
                Authorization: `Bearer ${tokenInit ? tokenInit : token}`,
            }
        })
        return response
    } catch (error) {

        console.log("errorSPORTS",error,error?.response,);
        
        if (error?.response?.data?.message === "Invalid token") {
            localStorage.removeItem(TOKEN_NAME)
            // localStorage.removeItem(FANTASY_TOKEN_NAME);
            // localStorage.removeItem(TRADING_TOKEN_NAME);
            localStorage.removeItem(ADMIN_FRONTEND_LOGGED_IN_ID)
            localStorage.removeItem(STOCK_USER_ID)
            window.location.reload(true);
        }
        throw error
        // console.log("error=>", error)
        // return  error
    }
}

// no auth token required
export const sportsPostAPI = async (url, params, admin) => {
    const bURL = sportsBaseUrl
    // try {
    const response = await axios.post(`${bURL}/${url}`, params, {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
        }
    })
    return response
    // } catch (error) {
    // console.log("error=>", error)
    // return error

    // }
}

export const sportsPostAPIAuth = async (url, params, tokenInit) => {
    // const token = localStorage.getItem(TOKEN_NAME)
    const bURL = sportsBaseUrl
    const token = localStorage.getItem(TOKEN_NAME)
    // try {
    const response = await axios.post(`${bURL}/${url}`, params, {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
            Authorization: `Bearer ${tokenInit ? tokenInit : token}`,
        }
    })
    return response
    // } catch (error) {
    // console.log("error=>", error)
    // return error
    // }
}

export const sportsPostAPIAuthFormData = async (url, params) => {
    const token = localStorage.getItem(TOKEN_NAME)
    const response = await axios({
        method: "post",
        url: `${sportsBaseUrl}/${url}`,
        data: params,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        },
    })
    return response
}
export const sportsDeleteAPIAuth = async (url) => {
    const token = localStorage.getItem(TOKEN_NAME);
    try {
        const response = await axios.delete(`${sportsBaseUrl}/${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        // console.log("error=>", error);
        return error;
    }
};
