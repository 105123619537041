import { reducerConst } from "../constants/storeConstants"

export const getUserDetails = payload => {
    return {
        type: reducerConst.USER_DETAILS,
        payload,
    }
}


export const isFavLoadingAction = payload => {
    return {
        type: reducerConst.Fav_LOADING,
        payload,
    }
}

export const addTeamList = payload => {
    return {
        type: reducerConst.TEAM_LIST,
        payload,
    }
}

export const addJoinedContest = payload => {
    return {
        type: reducerConst.JOINED_CONTEST,
        payload,
    }
}



export const getFavFiatAction = payload => {
    return {
        type: reducerConst.FAV_FIAT,
        payload,
    }
}


export const getFavCryptoAction = payload => {
    return {
        type: reducerConst.FAV_CRYPTO,
        payload,
    }
}

export const getNewsCat = payload => {
    return {
        type: reducerConst.NEWS_CATEGORIES,
        payload,
    }
}

export const getCryptoListAction = payload => {
    return {
        type: reducerConst.CRYPTO_LIST,
        payload,
    }
}

export const ipAddressAction = payload => {
    return {
        type: reducerConst.IP_ADDRESS,
        payload,
    }
}


export const countryAction = payload => {
    return {
        type: reducerConst.COUNTRY_NAME,
        payload,
    }
}



export const adminLogIn = (payload) => {
    return {
        type: reducerConst.ADMIN_LOGGED_IN,
        payload: payload
    }
}


export const adminLogOut = (payload) => {
    return {
        type: reducerConst.ADMIN_LOGGED_OUT,
        payload: payload
    }
}

export const updateBetSlip = payload => {
    return {
        type: reducerConst.UPDATE_BET_SLIP,
        payload,
    }
}

export const fantasyAddUserData = (payload) => {
    return {
        type: reducerConst.FANTASY_ADD_USER_DATA,
        payload
    }
}

export const stockAddUserData = (payload) => {
    return {
        type: reducerConst.STOCK_ADD_USER_DATA,
        payload
    }
}
export const setGameId = payload => {
    return {
        type: reducerConst.MATCH_ID,
        payload,
    }
}

export const userFullDetailsAction = payload => {
    return {
        type: reducerConst.USER_FULL_DETAILS,
        payload,
    }
}

export const selectTeamsAction = payload => {
    return {
        type: reducerConst.SELECT_TEAM,
        payload,
    }
}

export const playerConditionsAction = payload => {
    return {
        // type: reducerConst.playerConditions,
        type: reducerConst.PLAY_CONDITIONS,
        payload,
    }
}


export const currentHomeTabAction = payload => {
    return {
        // type: reducerConst.playerConditions,
        type: reducerConst.CURRENT_HOME,
        payload,
    }
}

export const setCapAndViceCap = payload => {
    return {
        // type: reducerConst.playerConditions,
        type: reducerConst.SELECT_CAP_VICE_CAP,
        payload,
    }
}


export const setSocialLinksData = payload => {
    return {
        type: reducerConst.SOCIAL_LINKS,
        payload,
    }
}


export const setVideoUrlAction = payload => {
    return {
        type: reducerConst.VIDEO_URL,
        payload,
    }
}


export const getBetGames = payload => {
    return {
        type: reducerConst.BET_GAMES_LIST,
        payload
    }
}

export const getSubscriptionUsers = payload => {
    console.log("getSubscriptionUsers-->>", { payload })
    return {
        type: reducerConst.GET_SUBSCRIPTION_USERS,
        payload

    }
}

export const getMainWalletData = payload => {
    return {
        type: reducerConst.GET_MAIN_WALLET_DATA,
        payload

    }
}

