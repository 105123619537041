import React from 'react'
import BackBtnIcon from './../../fantasyAssets/img/backArrow.png'
import { filterItem } from '../../../constants/filterData';

const FilterOffcanvas = ({
  showFilter,
  setShowFilter,
  contestData,
  setContestData,
  setShowFilterData,
  addedFilter,
   setAddedFilters
}) => {
  // const [addedFilter, setAddedFilters] = useState([])
  const onChange = (elem) => {
    // setAddedFilters(prev => [...prev, elem]);
    if (addedFilter.filter(el => el.type === elem.type).length) {
      if (addedFilter.filter(
        el => el.label === elem.label,
      ).length) {
        let a = addedFilter.filter(
          el => el.label !== elem.label,
        );
        setAddedFilters(a);
      } else {
        let a = addedFilter.filter(
          el => el.type !== elem.type,
        );
        setAddedFilters([...a, elem]);
      }

    } else {
      setAddedFilters(prev => [...prev, elem]);
    }
  }

  return (
    <>
      <div className={`offcanvas filterOffcanvas offcanvas-bottom ${showFilter ? 'show' : ''}`} tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <img src={BackBtnIcon}
            onClick={() => {
              setShowFilter(false);
              document.body.classList.remove('offcanvasOpen')
            }}
            alt="" />
          Filter
        </div>
        <div className="offcanvas-body pt-0">
          {
            filterItem.map(item => (
              <div className="row" key={item.id}>
                <div className="col-12 filterTitle">{item.name}</div>
                <div className="col-12">
                  <div className="row mx-0">
                    {
                      item.value.map(elem => {
                        return (
                          <div key={elem.id} className="col-auto filterInputCol ps-0">
                            {/* <input
                              className='filterInp d-none'
                              type="checkbox"
                              id={`${id}-entry1`}
                              name={item.name}
                              checked={!!addedFilter.filter(el => el.label === elem.label).length}
                              onChange={() => onChange(elem, item)}
                            /> */}
                            <div onClick={() => onChange(elem, item)} style={{ background: !!addedFilter.filter(el => el.label === elem.label).length ? "red" : "transparent" }} className='filterCheckBtn' >{elem.label}</div>
                          </div>
                        )
                      })
                    }
                    {/* <div className="col-auto filterInputCol ps-0">
                      <input className='filterInp d-none' type="checkbox" id='entry2' />
                      <label className='filterCheckBtn' htmlFor="entry2">₹ 51 to ₹ 100</label>
                    </div>
                    <div className="col-auto filterInputCol ps-0">
                      <input className='filterInp d-none' type="checkbox" id='entry3' />
                      <label className='filterCheckBtn' htmlFor="entry3">₹ 101 to ₹ 1,000</label>
                    </div>
                    <div className="col-auto filterInputCol ps-0">
                      <input className='filterInp d-none' type="checkbox" id='entry4' />
                      <label className='filterCheckBtn' htmlFor="entry4">₹ 1.001 & above</label>
                    </div> */}
                  </div>
                </div>
              </div>
            ))
          }
          {/* <div className="row">
            <div className="col-12 filterTitle">Entry</div>
            <div className="col-12">
              <div className="row mx-0">
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='entry1' />
                  <label className='filterCheckBtn' htmlFor="entry1">₹ 1 to ₹ 50</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='entry2' />
                  <label className='filterCheckBtn' htmlFor="entry2">₹ 51 to ₹ 100</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='entry3' />
                  <label className='filterCheckBtn' htmlFor="entry3">₹ 101 to ₹ 1,000</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='entry4' />
                  <label className='filterCheckBtn' htmlFor="entry4">₹ 1.001 & above</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 filterTitle">Number of Teams</div>
            <div className="col-12">
              <div className="row mx-0">
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='team1' />
                  <label className='filterCheckBtn' htmlFor="team1">2</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='team2' />
                  <label className='filterCheckBtn' htmlFor="team2">23 - 10</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='team3' />
                  <label className='filterCheckBtn' htmlFor="team3">11 - 100</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='team4' />
                  <label className='filterCheckBtn' htmlFor="team4">101 - 1000</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='team4' />
                  <label className='filterCheckBtn' htmlFor="team4">1001 & above</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 filterTitle">Prize Pool</div>
            <div className="col-12">
              <div className="row mx-0">
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='prize1' />
                  <label className='filterCheckBtn' htmlFor="prize1">₹ 1 to ₹ 50</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='prize2' />
                  <label className='filterCheckBtn' htmlFor="prize2">₹ 51 to ₹ 100</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='prize3' />
                  <label className='filterCheckBtn' htmlFor="prize3">₹ 101 to ₹ 1,000</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='prize4' />
                  <label className='filterCheckBtn' htmlFor="prize4">₹ 1.001 & above</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 filterTitle">Contest Type</div>
            <div className="col-12">
              <div className="row mx-0">
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='contest1' />
                  <label className='filterCheckBtn' htmlFor="contest1">₹ 1 to ₹ 50</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='contest2' />
                  <label className='filterCheckBtn' htmlFor="contest2">₹ 51 to ₹ 100</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='contest3' />
                  <label className='filterCheckBtn' htmlFor="contest3">₹ 101 to ₹ 1,000</label>
                </div>
                <div className="col-auto filterInputCol ps-0">
                  <input className='filterInp d-none' type="checkbox" id='contest4' />
                  <label className='filterCheckBtn' htmlFor="contest4">₹ 1.001 & above</label>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="offcanvas-footer">
          <button className="subFilterBtn" onClick={() => {
            setShowFilter(false)
            setShowFilterData(true)
          }}>Apply Filter</button>
        </div>
      </div>
    </>
  )
}

export default FilterOffcanvas