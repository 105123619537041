import React from 'react'
import MatchCard from '../../fantasyComponents/matchCard/MatchCard'
import { Spinner } from 'react-bootstrap'

const UpcomingMatches = ({ upcomingMatch, isLoading }) => {
  return (
    <>
      <div className="col-12">
        <div className="sectionHeading">Upcoming Matches</div>
        {
          isLoading ? (
            <div className='w-100 h-100 d-flex justify-content-center'>
              <Spinner color='white' size='sm' />
            </div>
          ) : (
            upcomingMatch == 0 ? (
              <div className='w-100 h-100 d-flex justify-content-center text-light' >No Data Available</div>
            ) : (
              upcomingMatch?.map((item) => (
                <MatchCard
                  key={item?.id}
                  upcoming={true}
                  matchName={item?.matchname}
                  series_name={item?.seriesname}
                  team1ShortName={item?.team1name}
                  team2ShortName={item?.team2name}
                  status={item?.status}
                  match={item}
                  final_status={item?.final_status}
                  winning_status={item?.winnerstatus}
                  team1logo={item?.team1logo}
                  team2logo={item?.team2logo}
                  start_date={item?.start_date}
                  playing11_status={item?.playing11_status}
                  teamfullname1={item?.teamfullname1}
                  teamfullname2={item?.teamfullname2}
                  time_start={item?.time_start}
                  id={item?.id}
                />
              ))
            )

          )
        }
      </div>
    </>
  )
}

export default UpcomingMatches