import React, { useState } from "react";
import call from "./../../fantasyAssets/img/callWhite.png";
import mobile from "./../../fantasyAssets/img/mobileWhite.png";
import mail from "./../../fantasyAssets/img/mailWhite.png";
import { useDispatch } from "react-redux";
import { getFantasyAuth, postFantasyAuth } from "../../fantasyServices";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { fantasyAddUserData } from "../../../store/action";
import { Modal } from "react-bootstrap";
import FantasyOtpInput from "../../fantasyComponents/fantasyOtpInput/FantasyOtpInput";

const Mobile = ({ userData }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpEmail, setOtpEmail] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleNumberInput = async () => {
    let params = {
      mobile: mobileNumber,
      email: "",
      code: Number(otp),
    };
    try {
      if (otp.length === 4) {
        const res = await postFantasyAuth("/verifyCode", params);
        if (res.status) {
          upDateData();
          succesToaster("Update successful");
          setShow(false);
        } else {
          errorToaster("Something went wrong");
        }
      }
      setOtp("");
    } catch (error) {
      errorToaster("Something went wrong");
    }
  };

  const handleEmailInput = async () => {
    let params = {
      mobile: "",
      email: email,
      code: Number(otp),
    };

    try {
      if (otp.length === 4) {
        const res = await postFantasyAuth("verifyCode", params);
        if (res?.data?.status) {
          upDateData();
          succesToaster("Update successful");
          setShow(false);
        } else {
          errorToaster(res?.data?.message);
        }
      }
      setOtp("");
    } catch (error) {
      errorToaster("Something went wrong");
    }
  };

  const upDateData = async () => {
    try {
      const res = await getFantasyAuth(`userFullDetails`);
      dispatch(fantasyAddUserData(res?.data?.data));
    } catch (error) {
    console.log("error",error)      
    }
  };

  const handleClickMobile = (e) => {
    e.preventDefault();
    generateMobile();
  };

  const generateMobile = async () => {
    let phnRegex = /^[0-9]{10}$/;
    if (phnRegex.test(mobileNumber)) {
      try {
        const res = await postFantasyAuth("verifyMobileNumber", {
          mobile: mobileNumber,
        });
        if (res?.data?.status) {
          setShow(true);
          setOtpEmail(false);
          succesToaster("OTP sent to your number");
        } else {
          errorToaster(res?.data?.message);
        }
      } catch (error) {
        errorToaster("Something went wrong!");
      }
    } else {
      errorToaster("Please enter valid number");
    }
  };

  const handleClickEmail = (e) => {
    e.preventDefault();
    generateEmail();
  };

  const generateEmail = async () => {
    //eslint-disable-next-line
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailRegex.test(email)) {
      try {
        const res = await postFantasyAuth("verifyEmail", { email: email });
        if (res?.data?.status) {
          succesToaster("OTP sent to your email");
          setShow(true);
          setOtpEmail(true);
        } else {
          errorToaster(res?.data?.message);
        }
      } catch (error) {
        errorToaster("Something went wrong!");
      }
    } else {
      errorToaster("Please enter valid email");
    }
  };

  const handleOtpModalClick = () => {
    if (otpEmail) {
      handleEmailInput();
    } else {
      handleNumberInput();
    }
  };
  return (
    <div className="row mx-0 pt-3 g-3">
      {userData?.mobileVerified === 1 ? (
        <div className="col-12">
          <div className="verifyEmail mobileCard card">
            <div className="card-body pill row mx-0 px-1 py-2 align-items-center">
              <div className="col-auto">
                <div className="mobileImg">
                  <img src={mobile} className="w-100" alt="" />
                </div>
              </div>
              <div className="col ps-0">
                <div className=" mobileLabel">
                  Your Mobile number is verified
                </div>
                <div className="lbl mobileLabel">{userData?.mobile}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-12 px-0">
          <div className="card border-0 mobileCard">
            <form className="card-body row mx-0 px-2 g-2">
              <div className="col-12 d-flex align-items-center gap-2">
                <div className="mobileImg">
                  <img src={call} className="w-100" alt="" />
                </div>
                <div className="verifyMobile">Verify Your Mobile</div>
              </div>
              {/* <div className="col-12 mobileLabel">Mobile</div> */}
              <div className="col-12">
                <input
                  type="tel"
                  className="form-control inp shadow-none py-2"
                  id="promocode"
                  placeholder="Mobile"
                  maxLength={10}
                  onChange={(e) => {
                    if(!isNaN(Number(e.target.value))){

                      setMobileNumber(e.target.value)
                    }
                    }}
                  value={mobileNumber}
                />
              </div>
              <div className="col-12  otpverfication">
                We will send you a 4 digit OTP for verfication.
              </div>
              <div className="col-12">
                <button
                  className="btn btn-success btn-sm otpBtn otpBtnn rounded-0 w-100 text-white fw-semibold text-center"
                  onClick={handleClickMobile}
                >
                  Send OTP
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {userData?.emailVerified === 1 ? (
        <div className="col-12">
          <div className="verifyEmail mobileCard card">
            <div className="card-body pill row mx-0 px-1 py-2 align-items-center">
              <div className="col-auto">
                <div className="mobileImg">
                  <img src={mobile} className="w-100" alt="" />
                </div>
              </div>
              <div className="col ps-0">
                <div className=" mobileLabel">Your Email is verified</div>
                <div className="lbl mobileLabel">{userData.email}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-12 px-0">
          <div className="card border-0 mobileCard">
            <form className="card-body row mx-0 px-2 g-2">
              <div className="col-12 d-flex align-items-center gap-2">
                <div className="mobileImg">
                  <img src={mail} className="w-100" alt="" />
                </div>
                <div className="verifyMobile">Verify Your Email</div>
              </div>
              {/* <div className="col-12 mobileLabel">Email ID</div> */}
              <div className="col-12">
                <input
                  type="email"
                  className="form-control inp shadow-none py-2"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-12 otpverfication">
                We will send you a 4 digit OTP for verfication.
              </div>
              <div className="col-12">
                <button
                  className="btn btn-success btn-sm otpBtn otpBtnn rounded-0 w-100 text-white text-center"
                  onClick={handleClickEmail}
                >
                  Send OTP
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <Modal
        className="confirmationModal"
        onHide={() => setShow(false)}
        show={show}
        centered
      >
        <Modal.Header>
          <Modal.Title>Please Enter your OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FantasyOtpInput setOtp={setOtp} />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success btn-sm otpBtn otpBtnn rounded-0 w-100 text-white text-center"
            onClick={handleOtpModalClick}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Mobile;
