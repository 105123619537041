import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "../../../hooks/useAuth";
import { succesToaster } from "../../../utils/toaster";
import { postAPIAuth } from "../../../service/apiInstance";
import { useAuthModal } from "../../../hooks/useAuthModal";
import { AUTH_MODAL_TABS } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONST } from "../../../constants/routeConstant";

const GamesCard = ({ name, image, RTP, onClick, isFav, id, favButton, uuid, FavGame }) => {
    const [isFavourite, setIsFavourite] = useState(isFav)
    const userDetails = useSelector(state => state.userDetails)
    const { token } = useAuth()
    const { setAuthModalObject } = useAuthModal()
    const navigate = useNavigate()

    useEffect(() => {
        setIsFavourite(isFav)
    }, [isFav])
    // console.log("isFavourite", { isFav, isFavourite, name })

    const favouriteGame = async () => {
        if (token) {
            let params = {
                "gameId": id,
                "userId": userDetails?.id
            }
            const res = await postAPIAuth('crypto/create-favourite-games', params)
            if (res.data.status) {
                if (res.data.message === 'games removed in fav!') {
                    setIsFavourite(false)
                } else if (res.data.message === 'games added in fav!') {
                    setIsFavourite(true)
                }
                if (!!FavGame) FavGame()
                succesToaster(res.data.message)
            }
        } else {
            setAuthModalObject(pre => ({ isAuthOpen: true, selectedTab: AUTH_MODAL_TABS.LOG_IN }))
        }
    }
    return (
        <div className="games-card h-100"
            onClick={(e) => {
                e.stopPropagation()
                navigate(`${ROUTES_CONST.SLOT_PAGE}/${uuid}`)
            }}
        >
            <div className="games-img rounded-2 position-relative bigImg">
                <img src={image} alt="card" className="h-100 w-100 img-fluid rounded-2" />
                <div className="overlay">
                    <div className="row mx-0">
                        <div className="col-12"><div className="overlayTxt text-uppercase">{name}</div></div>
                        {/* <div className="col-12"><div className="overlayTxt1">$450.30</div></div>*/}
                        <div className="col-12"><div className="overlayTxt mt-1">EFFECTIVE RTP</div></div>
                        <div className="col-12"><div className="overlayTxt1 overlayTxt2">{RTP ? RTP : '75.20'}%</div></div>
                        {
                            favButton ? (<div className="col-12">
                                <button
                                    className={`addToFav ${isFavourite ? "active" : ""}`}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        favouriteGame(id)
                                    }}
                                // onClick={onClick}
                                > {isFavourite ? "Remove favorites" : "Add to favorites"}</button>
                            </div>) : (<></>)
                        }
                    </div>
                </div>
                {/* <div className="d-flex justify-content-end"><button className="overlayBtn d-flex align-items-center justify-content-center">$75.293 <img src="assets/img/index/infinite.png" alt=""/></button></div> */}
            </div>
        </div >
    );
};

export default GamesCard;
