import React, { useEffect, useState } from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import "./style.scss";
import { Spinner } from "react-bootstrap";
import { getAPI, getAPIAuth } from "../../service/apiInstance";
import { useNavigate } from "react-router-dom";

const GameProviders = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const res = await getAPI("games/get-all-providers");
      if (res.data.success) {
        setProviders(res.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
console.log('providers',providers)
  const handleFunction = (provider ) => {
    console.log(provider)
    navigate("/all-games", { state: { provider_id:provider} });
  };
  useEffect(() => {
    getData();
  }, []);
  // console.log("providers",providers)
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage providerPage">
            <div className="row">
              <div className="col-12">
                <div className="providerBtn d-inline-flex align-items-center justify-content-center">
                  <img
                    src="./assets/img/provider/provider.png"
                    alt="provider icon"
                    className="providerIcon me-3"
                  />
                  Providers
                </div>
              </div>
              <div className="col-12">
                <div className="searchInputBox position-relative">
                  <img
                    src="./assets/img/provider/search.png"
                    alt="search icon"
                    className="searchIcon position-absolute"
                  />
                  <input
                    type="text"
                    className="form-control searchInput shadow-none"
                    placeholder="Search your game"
                  />
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="row row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2 g-3">
                  {isLoading ? (
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <Spinner animation="border" variant="light" size="sm" />
                    </div>
                  ) : providers?.length === 0 ? (
                    <div className="w-100 h-100">
                      <h6 className="text-light"> No data available</h6>
                    </div>
                  ) : (
                    providers?.map((item) => (
                      <div className="col">
                        <div
                          className="providerCard"
                          onClick={() =>
                            handleFunction( item?.provider)
                          }
                        >
                          <img
                            src={item.image}
                            alt={`${item.provider}`}
                            className="providerImg img-fluid"
                         
                          />
                          <div className="providerGame">{item.provider}</div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  );
};

export default GameProviders;
