import React, { useEffect, useState } from "react";
import { postAPI } from "../../service/apiInstance";
import {
  auth,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "../../config/firebase";
import SignIn from "../signIn/signIn";
import { GoogleLogin } from "../socialMedia/GoogleLogin";
import { errorToaster, succesToaster } from "../../utils/toaster";
import OtpInput from "./otp";
import { useSignUpModal } from "../../hooks/useSignUpModal";
import TelegramComp from "../socialMedia/TelegramLoginButton";
import { useBtAuth } from "../../hooks/useBtAuth";
import { useAuth } from "../../hooks/useAuth";
// import MetaMaskModal from "../socialMedia/MetamaskModal";
import TelegramInputModal from "../socialMedia/TelegramInputModal";
import { useSignInModal } from "../../hooks/useSignInModal";
import Poaster from "../Poaster/poaster";
import { Spinner } from "react-bootstrap";

function SignUp() {
  const [values, setValues] = useState({ email: "", password: "", phone: "" });
  const { isSignUpOpen, setIsSignUpOpen } = useSignUpModal();
  const { setIsSignInOpen } = useSignInModal();
  const [error, setemailErr] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passworderr, setPassworderr] = useState("");
  const [Active, setActive] = useState(true);
  const [phoneerr, setphoneErr] = useState("");
  const [referralcode, setRferralcode] = useState("");
  const [open, setopen] = useState(false);
  const [optid, setotpid] = useState("");
  const { setBtToken } = useBtAuth();
  const { setToken } = useAuth();
  const [terms, setTerms] = useState(false);
  const [showGoogleLogin, setShowGoogleLogin] = useState(false);
  const [isMetamask, setIsMetamask] = useState(false);
  const [isTelegram, setIsTelegram] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [signupLoader, setSignupLoader] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const referraldata = localStorage.getItem("middlePart");
  const [localstoredata, setLocalstoreDAta] = useState(referraldata);
  let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  const validatePasswords = (password) => {
    return password.length >= 8;
  };
  async function createUserWithEmailAndPasswordF() {
    setSignupLoader(true);
    if (!terms) {
      errorToaster("Please accept the User Agreement");
      setSignupLoader(false);
      return;
    }
    if (values.password.length === 0 || values.password.length < 8) {
      setPasswordError("Password should be at least 8 characters long");
      setSignupLoader(false);
      return;
    } else if (values.password.length > 8) {
      setPasswordError("");
    } else {
      setPasswordError("");
    }
    if (emailPattern.test(values.email)) {
      setemailErr("");
    } else if (values.email == "") {
      setSignupLoader(false);
      return setemailErr("please enter email");
    } else {
      setSignupLoader(false);
      return setemailErr("Email is invalid");
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password,
        localstoredata
      );
      const user = userCredential.user;
      const body = {
        email: userCredential._tokenResponse.email,
        idToken: userCredential._tokenResponse.idToken,
        localId: userCredential._tokenResponse.localId,
        refreshToken: userCredential._tokenResponse.refreshToken,
        password: values.password,
        refercode: localstoredata
      };
      const res = await postAPI("user/register-user", body);
      if (res.data.success) {
        succesToaster(res.data.message);
        setBtToken(res?.data.data?.betByToken);
        setToken(res?.data.data?.auth_token);
        setIsSignUpOpen(false);
        setSignupLoader(false);
      } else {
        errorToaster("Something went wrong");
        setSignupLoader(false);
      }
      setValues({
        email: "",
        password: "",
      });
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message.substr(9, 30);

      errorToaster(errorMessage);
      setSignupLoader(false);
    }
  }
  const handleChange = (e) => {
    const { value, name } = e.target;
    // console.log("namenamename", name);
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
    if (value.length === 0) {
      setemailErr("");
      setPassworderr("");
    } else if (emailPattern.test(values.email)) {
      setemailErr("");
    }
  };
  useEffect(() => {
    setActive((prev) => !prev);
  }, []);
  const handleGoogleLoginClick = () => {
    setShowGoogleLogin(true);
  };

  const generateRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
        callback: (response) => {},
      },
      auth
    );
  };

  const handleSend = (e) => {
    if (!terms) {
      errorToaster("Please accept the User Agreement");
      setOtpLoader(false);
      return;
    }
    e.preventDefault();
    generateRecaptcha();
    const { phone } = values;
    if (phone.length === 0 || phone.length !== 10) {
      setphoneErr("number not valid!");
      setOtpLoader(false);
      return;
    } else {
      setphoneErr("");
    }
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, `+91 ${phone}`, appVerifier)
      .then((confirmationResult) => {
        succesToaster("OTP SENT!");
        setotpid(confirmationResult.verificationId);
        window.confirmationResult = confirmationResult;
        setOtpLoader(false);
      })
      .catch((error) => {
        errorToaster("Something Went Wrong!!");
        setOtpLoader(false);
        // window.recaptchaVerifier.render().then(function (widgetId) {
        //   appVerifier.reset(widgetId);
        // });
      });
  };

  const handleChanges = (e) => {
    const passwordValue = e.target.value;
    setValues({
      ...values,
      password: passwordValue,
    });
    if (!!passwordValue) {
      if (passwordValue.length < 8) {
        setPasswordError("Password must contain at least 8 characters!");
      } else {
        setPasswordError("");
      }
    } else {
      setPasswordError("");
    }
  };

  return (
    <>
      <div
        className={`modal fade ragisterModal  ${isSignUpOpen ? "show" : ""}`}
        id="exampleModal1"
        tabIndex={-1}
        style={{
          display: isSignUpOpen ? "block" : "none",
        }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => setIsSignUpOpen(false)}
                type="button"
                className="btn-close btnClose"
              />
            </div>
            <div className="modal-body">
              <div className="row align-itemscenter">
                <Poaster />
                <div className="col-lg-6">
                  <form className="row mx-0 form gap-3">
                    <div className="col-12 fw-bold text-white registerHeading mb-3 d-lg-block d-none">
                      Sign Up
                    </div>
                    <div className="col-12">
                      <ul className="pills d-flex align-items-start mb-0 ps-0">
                        <li className="casinoPill w-50">
                          <a
                            onClick={() => {
                              setActive(true);
                            }}
                            className={`pillAnchor ${
                              Active ? "active" : ""
                            } d-flex align-items-center justify-content-center`}
                          >
                            Email{" "}
                          </a>
                        </li>
                        <li className="casinoPill w-50">
                          <a
                            onClick={() => {
                              setActive(false);
                            }}
                            className={`pillAnchor ${
                              Active ? "" : "active"
                            } d-flex align-items-center justify-content-center`}
                          >
                            Phone number{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                    {Active ? (
                      <>
                        <div className="col-12">
                          <input
                            type="text"
                            name="email"
                            className="form-control border-0 shadow-none"
                            id="Email"
                            value={values.email}
                            placeholder="Email"
                            onChange={(e) => handleChange(e)}
                          />
                          <div className="para text-danger text-start mt-1">
                            {error}
                          </div>
                        </div>
                        <div className="col-12 position-relative">
                          <input
                            type={open ? "text" : "password"}
                            name="password"
                            className="form-control border-0 shadow-none"
                            id="password"
                            placeholder="Login Password"
                            value={values.password}
                            onChange={handleChanges}
                          />
                          <div className="para text-danger text-start mt-1">
                            {passwordError}
                          </div>
                          <div className="eye">
                            <img
                              src="assets/img/eyeIcon.png"
                              alt=""
                              className="mb-3"
                              onClick={() => setopen((prev) => !prev)}
                            />
                          </div>
                        </div>
                        <div className="col-12 my-2">
                          <input
                            type="text"
                            className="form-controll border-0 shadow-none text-light ps-2"
                            id="promocode"
                            placeholder="Enter Referral/Promo Code "
                            value={localstoredata}
                            onChange={(e) => {
                              setLocalstoreDAta(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-12 mx-md-0 mx-auto">
                          <div className="form-check d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="kk"
                              checked={terms}
                              onChange={(e) => setTerms(e.target.checked)}
                            />
                            <label htmlFor="kk" className="form-check-label">
                              I agree to the <span>User Agreement</span> &amp;
                              Confirm I am at least 18 years old
                            </label>
                          </div>
                          <div className="col-12 mx-auto">
                            <button
                              type="button"
                              className="btn ragisterBtn w-100 border-0"
                              onClick={createUserWithEmailAndPasswordF}
                            >
                              {signupLoader ? (
                                <Spinner
                                  size="sm"
                                  animation="border"
                                  variant="light"
                                />
                              ) : (
                                "Sign Up"
                              )}
                            </button>
                          </div>
                          <div className="col-12">
                            <div className="para">
                              Already have an account?{" "}
                              <a
                                onClick={() => {
                                  setIsSignUpOpen(false);
                                  setIsSignInOpen(true);
                                }}
                                href="#"
                                className="paraAnchor"
                              >
                                Log In
                              </a>
                            </div>
                          </div>
                          <div className="col-12 mt-3">
                            <div className="para">Log in directly with</div>
                          </div>
                          <div className="col-12">
                            <div className="row align-items-center justify-content-center mt-1">
                              <div className="col-auto d-flex pe-0">
                                <div className="link">
                                  {
                                    <GoogleLogin parent={"signUp"}>
                                      <img src="assets/img/link1.png" alt="" />
                                    </GoogleLogin>
                                  }
                                </div>
                              </div>
                              {/* telegram */}
                              <a
                                href="#"
                                className="col-auto d-flex"
                              >
                                <div className="link">
                                  <img
                                    onClick={() => setIsMetamask(true)}
                                    src="assets/img/link3.png"
                                    alt=""
                                  />
                                </div>
                              </a>
                            </div>
                            {isSignUpOpen ? (
                              <div className="col-auto d-flex justify-content-center my-3">
                                <TelegramComp
                                  isOpen={isTelegram}
                                  setIsOpen={setIsTelegram}
                                  userObj={userObj}
                                  setUserObj={setUserObj}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </>
                    ) : !optid ? (
                      <>
                        <div className="col-12">
                          <input
                            type="text"
                            name="phone"
                            className="form-control border-0 shadow-none"
                            id="phone"
                            value={values.phone}
                            placeholder="Enter phone number"
                            onChange={(e) => {
                              if (
                                e.target.value.length > 0 &&
                                e.target.value.length > 10
                              ) {
                                setphoneErr("number must be 10 digit");
                              } else if (e.target.value.length === 10) {
                                setphoneErr("");
                              } else {
                                setphoneErr("");
                              }
                              setValues({ ...values, phone: e.target.value });
                            }}
                          />
                          <div className="para text-danger text-start mt-1">
                            {phoneerr}
                          </div>
                        </div>
                        {/* <div className="col-12 my-2">
                          <input
                            type="text"
                            className="form-controll border-0 shadow-none text-light ps-2"
                            id="exampleForm4"
                            placeholder="Enter Referral/Promo Code "
                            value={referraldata ? referraldata :referralcode}
                            onChange={(e)=>setRferralcode(e.target.value)}
                          />
                        </div> */}
                        <div className="col-12 mx-md-0 mx-auto">
                          <div className="form-check d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="kk"
                              checked={terms}
                              onChange={(e) => setTerms(e.target.checked)}
                            />
                            <label htmlFor="kk" className="form-check-label">
                              I agree to the <span>User Agreement</span> &amp;
                              Confirm I am at least 18 years old
                            </label>
                          </div>
                          <div className="col-12 mx-auto">
                            <button
                              type="button"
                              id="recaptcha"
                              className="btn ragisterBtn w-100 border-0"
                              onClick={(e) => {
                                setOtpLoader(true);
                                handleSend(e);
                              }}
                            >
                              {otpLoader ? (
                                <Spinner
                                  size="sm"
                                  animation="border"
                                  variant="light"
                                />
                              ) : (
                                "Get Otp"
                              )}
                            </button>
                          </div>
                          <div className="col-12">
                            <div className="para">
                              Already have an account?{" "}
                              <a
                                onClick={() => setIsSignInOpen(true)}
                                href="#"
                                className="paraAnchor"
                              >
                                Log In
                              </a>
                            </div>
                          </div>
                          <div className="col-12 mt-3">
                            <div className="para">Log in directly with</div>
                          </div>
                          <div className="col-12 mt-1">
                            <div className="row align-items-center justify-content-center">
                              <div
                                // href="#"
                                className="col-auto d-flex pe-0"
                              >
                                <div className="link">
                                  {
                                    <GoogleLogin parent={"signUp"}>
                                      <img src="assets/img/link1.png" alt="" />
                                    </GoogleLogin>
                                  }
                                </div>
                              </div>
                              {/* <a
                                href="#"
                                className="col-auto d-flex pe-0"
                              >
                                <div className="link">
                                  <img src="assets/img/link2.png" alt="" />
                                </div>
                              </a> */}
                              {/* <MetaMaskButton /> */}
                              <a
                                href="#"
                                className="col-auto d-flex"
                              >
                                <div className="link">
                                  <img
                                    //  onClick={metamaskHandler}
                                    onClick={() => setIsMetamask(true)}
                                    src="assets/img/link3.png"
                                    alt=""
                                  />
                                </div>
                              </a>
                            </div>
                            {/* <div className="col-auto d-flex justify-content-center my-3">
                                <TelegramComp isOpen={isTelegram} setIsOpen={setIsTelegram} userObj={userObj} setUserObj={setUserObj} />
                              </div> */}
                            {isSignUpOpen ? (
                              <div className="col-auto d-flex justify-content-center my-3">
                                <TelegramComp
                                  isOpen={isTelegram}
                                  setIsOpen={setIsTelegram}
                                  userObj={userObj}
                                  setUserObj={setUserObj}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <OtpInput setotpid={setotpid} setActive={setActive} />
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignIn />
      <TelegramInputModal
        isOpen={isTelegram}
        setIsOpen={setIsTelegram}
        userObj={userObj}
        setUserObj={setUserObj}
      />
      {/* <MetaMaskModal
        isOpen={isMetamask}
        setIsOpen={setIsMetamask}
        parent={"signUp"}
      /> */}
    </>
  );
}

export default SignUp;
