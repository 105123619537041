import React, { useState } from "react";
import AppFooter from "../../../components/AppFooter/AppFooter";
import "./../../TradingAssets/scss/trading.scss";
import { postTradingAPI } from "../../TradingServices";
import { useEffect } from "react";

const TradingPortfolio = () => {
  const [activeTab, setActiveTab] = useState("Positions");
  const [loading, setLoading] = useState(false);
  const [positionsData, setPositionsData] = useState([]);
  const [holdingsData, setHoldingsData] = useState([]);
  const [totalResult, setTotalResult] = useState({
    Positions: "",
    Holdings: "",
  });

  const getTableData = async () => {
    try {
      setLoading(true);
      const body = {
        action: "order-position",
      };
      const res = await postTradingAPI("order-position.php", body);
      const response = await res.json();
      setPositionsData(response?.data?.mis);
      setHoldingsData(response?.data?.cnc);
      setTotalResult((prev) => ({
        ...prev,
        Positions: response?.mis?.ttl_pl,
        Holdings: response?.cnc?.ttl_pl,
      }));
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage tradingPages">
            <div class="row gap-4">
              <div className="col-12">
                <ul className="list-unstyled d-flex align-items-center gap-2">
                  <li>
                    <button
                      className={`border-0 btn shadow-none navButton ${
                        activeTab === "Positions" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("Positions")}
                    >
                      Positions
                    </button>
                  </li>
                  <li>
                    <button
                      className={`border-0 btn shadow-none navButton ${
                        activeTab === "Holdings" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("Holdings")}
                    >
                      Holdings
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-12">
                <div>
                  <span className="totalPAndL">
                    Total P/L: {totalResult[activeTab]}{" "}
                  </span>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div className="col-12 px-0 mb-0 dambleTable sectionInner tableCard tab-content tab-pane table-responsive">
                <table class="table align-middle text-white text-nowrap">
                  <thead>
                    <tr>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Trading Symbol
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          Product
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          quantity
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          exchange
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          avg price
                        </div>
                      </th>
                      <th>
                        <div className="tabletxt d-flex align-items-center">
                          P/L
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading
                      ? 
                          activeTab === "Positions"
                            ? positionsData?.length
                            : holdingsData?.length
                        ? [
                            activeTab === "Positions"
                              ? [...positionsData]
                              : [...holdingsData],
                          ]?.[0]?.map((item) => (
                            <tr>
                              <td>
                                <div className=" d-flex align-items-center ">
                                  {item?.trading_symbol}
                                </div>
                              </td>
                              <td>
                                <div className=" d-flex align-items-center ">
                                  {item?.mis_cnc}
                                </div>
                              </td>
                              <td>
                                <div className=" d-flex align-items-center ">
                                  {item?.quantity}
                                </div>
                              </td>
                              <td>
                                <div className=" d-flex align-items-center ">
                                  {item?.exchange}
                                </div>
                              </td>
                              <td>
                                <div className=" d-flex align-items-center ">
                                  {item?.avg_price}
                                </div>
                              </td>
                              <td>
                                <div className=" d-flex align-items-center ">
                                  {item?.profit_loss}
                                </div>
                              </td>
                            </tr>
                          ))
                        : "No Data"
                      : "Loading"}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  );
};

export default TradingPortfolio;
