import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'

const NormalStockList = ({
  stockContest,
  companyData,
  handleSelectCoin,
  selectCoin,
  distribution1,
  distribution2,
  EditStocks,
  editflag
}) => {
  const navigate = useNavigate()
  // console.log(companyData[0]?.percentage , companyData[1]?.percentage);

  return (
    <>
      <div className="row mx-0 mt-3">
        {
          companyData?.length && companyData?.map((item, index) => (
            <div className="col-6 pe-2">
              <div className={`normalStockCrad h-100 d-flex flex-column ${index !== 0 ? "normalStockCrad2" : ""}`}>
                <div className="stockInner d-flex align-items-center flex-fill">
                  <div className="stockIcon me-3">
                    <img src="" alt="" />
                  </div>
                  <div className="txt">
                    <div className="stockTitle">{item?.name}
                      <span className="stockPercentage"> {" "}({' '}
                        {
                          Number(item?.closePrice) > Number(item?.openPrice)
                            ? (
                              ((Number(item?.closePrice) -
                                Number(item?.openPrice)) *
                                100) /
                              Number(item?.closePrice)
                            ).toFixed(2)
                            : Number(item?.closePrice) < Number(item?.openPrice)
                              ? (
                                ((Number(item?.openPrice) -
                                  Number(item?.closePrice)) *
                                  100) /
                                Number(item?.openPrice)
                              ).toFixed(2)
                              : 0
                        }%
                        )</span></div>
                    {/* <div className="stockPercentage">0%</div> */}
                  </div>
                </div>
                <div className="stockFinalPer py-2">
                  {/* 95% */}
                  {index == 0 ? distribution1 : distribution2}
                </div>

              </div>
            </div>
          ))
        }
      </div>
      <div className="row mx-0 mt-3 gy-3">
        <div className='text-light'>
          Select your coin distribution
        </div>
        {[1, 2, 3].map((el, index) => (
          <BarContainer
            selectCoin={selectCoin}
            handleSelectCoin={handleSelectCoin}
            id={el}
            key={index}
            cmp1={companyData.length ? companyData[0]?.name : ""}
            cmp2={companyData.length ? companyData[1]?.name : ""}
          />
        ))}

      </div>
      <button className="createTeamBtn"
        onClick={() => {
          if (companyData?.length) {
            if (Number(companyData[0]?.percentage + companyData[1]?.percentage) === 100) {
              if (editflag) {
                EditStocks();
                return
              } else {
                navigate(ROUTES_CONST.FANTASY_STOCK_USABLE_BALANCE, {
                  // companyData: companyData,
                  // stockContest: stockContest,
                  state: {
                    companyData: companyData,
                    stockContest: stockContest,
                  }
                })

              }
            }

          }
        }
        }
      >
        Continue
      </button>
    </>
  )
}

export default NormalStockList

const BarContainer = ({ selectCoin, handleSelectCoin, id, cmp1, cmp2 }) => {
  return (
    <div className="col-12 cursor-pointer"
      onClick={() => handleSelectCoin(id)}
    >
      <div className={`row stockSelectCard align-items-end ${selectCoin == id ? "active" : ""} mx-0`}>
        <div className="col-auto pe-0">
          <div className="stockCheck"></div>
        </div>
        <div className="col">
          <div className="progress-stacked">
            <div
              className="progress"
              role="progressbar"
              aria-label="Segment one"
              aria-valuenow="15"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{
                // '50%' 
                width: id == 1 ? '50%' : id == 2 ? '80%' : id == 3 ? '20%' : null
              }}
            >
              <div className="progress-bar type1">
                {/* 50% */}
                {
                  id == 1
                    ? '50.00%'
                    : id == 2
                      ? '95.00%'
                      : id == 3
                        ? '5.00%'
                        : null
                }
              </div>
              <div className="progressTxt">
                {
                  // cmp1.split(' ')[0]
                  cmp1?.length > 6 ? `${cmp1.slice(0, 5)}...` : cmp1
                }</div>
            </div>
            <div
              className="progress"
              role="progressbar"
              aria-label="Segment one"
              aria-valuenow="15"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{
                width:
                  //  '50%'
                  id == 1 ? '50%' : id == 2 ? '20%' : id == 3 ? '80%' : null
              }}
            >
              <div className="progress-bar type2">
                {/* 50% */}
                {
                  id == 1 ?
                    '50.00%'
                    : id == 2
                      ? '5.00%'
                      : id == 3
                        ? '95.00%'
                        : null
                }
              </div>
              <div className="progressTxt">{
                // cmp2.split(' ')[0]
                // cmp2
                cmp2?.length > 6 ? `${cmp2.slice(0, 5)}...` : cmp2
              }</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// {/* <div className="col-12">
//         <div className="row stockSelectCard align-items-end active mx-0">
//           <div className="col-auto pe-0">
//             <div className="stockCheck"></div>
//           </div>
//           <div className="col">
//             <div className="progress-stacked">
//               <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{ width: '50%' }}>
//                 <div className="progress-bar type1">50%</div>
//                 <div className="progressTxt">HANGSENG</div>
//               </div>
//               <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{ width: '50%' }}>
//                 <div className="progress-bar type2">50%</div>
//                 <div className="progressTxt">nifty</div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="col-12">
//         <div className="row stockSelectCard align-items-end mx-0">
//           <div className="col-auto pe-0">
//             <div className="stockCheck"></div>
//           </div>
//           <div className="col">
//             <div className="progress-stacked">
//               <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{ width: '75%' }}>
//                 <div className="progress-bar type1">95%</div>
//                 <div className="progressTxt">HANGSENG</div>
//               </div>
//               <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{ width: '25%' }}>
//                 <div className="progress-bar type2">5%</div>
//                 <div className="progressTxt">nifty</div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="col-12">
//         <div className="row stockSelectCard align-items-end mx-0">
//           <div className="col-auto pe-0">
//             <div className="stockCheck"></div>
//           </div>
//           <div className="col">
//             <div className="progress-stacked">
//               <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{ width: '25%' }}>
//                 <div className="progress-bar type1">5%</div>
//                 <div className="progressTxt">HANGSENG</div>
//               </div>
//               <div className="progress" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{ width: '75%' }}>
//                 <div className="progress-bar type2">95%</div>
//                 <div className="progressTxt">nifty</div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div> */}