import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import * as Yup from 'yup'
import { sendPasswordResetEmail } from "firebase/auth";
import { useAuthModal } from "../../../hooks/useAuthModal";
import { auth } from "../../../config/firebase";
import { succesToaster } from "../../../utils/toaster";
import { Spinner } from "react-bootstrap";

const validationSchema = Yup.object({
  email: Yup.string()
    .required("This field is required")
    .matches(
      // /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      "Invalid email"
    ),
});

const LoginForgotPassword = ({ setForgetpass, setLogOn, forgetpass }) => {
  const { authModalObject } = useAuthModal()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!authModalObject.isAuthOpen) {
      setForgetpass(false)
      setLogOn(true)
    }
  }, [authModalObject.isAuthOpen])

  const handle = async (values) => {
    try {
      const res = await sendPasswordResetEmail(auth, values.email);
      succesToaster('Link sent Succesfully!!')
    } catch (error) {
      console.error("Error occurred during password reset:", error);
    } finally {
      setIsLoading(false)
    }
  };
  const handleLogin = () => {
    setLogOn(true);
    setForgetpass(false);
  };
  useEffect(() => {
    if (!forgetpass) {
      setIsLoading(false)
    }
  }, [forgetpass])
  return (
    <Formik
      initialValues={{
        email: ""
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setIsLoading(true)
        handle(values)
      }}
    >
      {
        ({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
        }) => (
          <form>
            <div className="row gap-3 gx-0">
              <div className="col-12 inputdata">
                {/* email */}
                <label
                  htmlFor="text"
                  className="inputLabel"
                >
                  Your Email
                  <span>*</span>
                </label>
                <input
                  type="text"
                  id="text"
                  className="form-control inputlogin border-0 shadow-none"
                  placeholder="Enter your email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="email"
                />
                <ErrorMessage>
                  {errors.email && touched.email && errors.email}
                </ErrorMessage>
              </div>

            </div>
            <div className="row mt-4 gap-4">
              <div className="col-12 mx-auto">
                <span
                  className="playBtn position-relative d-flex align-items-center justify-content-center"
                >
                  <button
                    type="submit"
                    className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100 cursor-pointer"
                    onClick={handleSubmit}
                  >
                    {
                      isLoading ? <Spinner size="sm" color="dark" /> : " Send Link"
                    }
                  </button>
                </span>
              </div>
            </div>
          </form>
        )
      }
    </Formik>
  );
};

export default LoginForgotPassword;
