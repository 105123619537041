import React, { useState } from "react";
import Pagination from "../../Admincomponents/pagination/Pagination";
import { adminGetAPIAuth } from "../../AdminServices";
import { REPORTS, USER } from "../../AdminConstants/apiEndpoints";
import { useEffect } from "react";
import { dateFormatter } from "../../AdminUtils/Utils";
import { errorToaster } from "../../../utils/toaster";
import { useSelector } from "react-redux";
import AdminTxModal from "../../Admincomponents/AdminTxModal/AdminTxModal";
import { returnEndDate } from "../../../utils/returnEndDate";
import { useNavigate } from "react-router-dom";

const transactionArr = [
  {
    value: "",
    label: "All",
  },
  {
    value: 'Deposit',
    label: "Deposit",
  },
  {
    value: 'Withdraw',
    label: "Withdraw",
  },
  {
    value: 'Settlement Deposit',
    label: "Settlement Deposit",
  },
  {
    value: 'Settlement Withdraw',
    label: "Settlement Withdraw",
  },
  // {
  //   value: "bet-settlements",
  //   label: "Bet Settlements",
  // },
  // {
  //   value: "rollback",
  //   label: "Rollback",
  // },
  // {
  //   value: "Voided",
  //   label: "voided",
  // },
];

const UserAccountStatement = () => {
  const [tableData, setTableData] = useState([]);
  const [showTxModal, setShowTxModal] = useState(false);
  const [txDetails, setTxDetails] = useState({});
  const[userData, setUserData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const [childrenList, setChildrenList] = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const [selectedUser, setSelectUser] = useState({});
  const [searchedUserResult, setSearchedUserResult] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);

  const [filterValues, setFilterValues] = useState({
    // transactions: "",
    type: "",
    startDate: "",
    endDate: "",
  });

  const adminData = useSelector((state) => state?.adminData);

  // set today's date as end date
  useEffect(() => {
    const today = new Date()
    setFilterValues((prev) => ({
      ...prev,
      endDate: returnEndDate(today)
    }));
    getUserDetails();
  }, [])

  const getChildrenList = async () => {
    const queryParams = Object.entries(filterValues)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    try {
      const res = await adminGetAPIAuth(
        "user/allaccountstatement" + "?perPage=10"+`&search=${searchUser}&page=${currentPage}`+ (queryParams ? "&" + queryParams : "")
      );
      if (res?.data?.status === 200) {
        setChildrenList(res.data.data.data);
        setTotalPages(res?.data?.data?.pagination?.totalPages);
      }
    } catch (error) {
      setChildrenList([]);
    }
  };


  useEffect(() => {
    if (adminData?._id) {
      getChildrenList();
    }
    //eslint-disable-next-line
  }, [adminData, filterValues, currentPage, searchUser]);

  const handleChange = (e) => {
    setFilterValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleGetList = async (item) => {
    const queryParams = Object.entries(filterValues)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    try {
      const res = await adminGetAPIAuth(
        REPORTS?.myaccountstatement +
        `?page=${currentPage}&perPage=10&userId=` +
        (selectedUser?._id ? selectedUser?._id : item?._id)
        + (queryParams ? "&" + queryParams : "")
      );
      if (res?.data?.data?.success) {
        setTableData(res?.data?.data?.data);
        setTotalPages(res?.data?.data?.pagination?.totalPages);
        // setSearchUser('');
      } else {
        errorToaster("Something went wrong in fetching data");
      }
    } catch (error) {
      errorToaster(error?.response?.data?.data?.message);
      setTableData([]);
      console.log("error -------------->", error);
    }
  };
 

  useEffect(() => {
    if (searchUser) {
      const filtered = childrenList?.filter(
        (item) =>
          item?.name?.toLowerCase()?.includes(searchUser?.toLowerCase()) ||
          item?.username?.toLowerCase()?.includes(searchUser?.toLowerCase())
      );
      setSearchedUserResult(filtered);
    } else {
      setSearchedUserResult([]);
      setSelectUser({});
    }
    //eslint-disable-next-line
  }, [searchUser]);

  useEffect(() => {
    if (selectedUser?._id) {
      handleGetList();
    }
    //eslint-disable-next-line
  }, [
    selectedUser,
    currentPage,
    filterValues.endDate,
    filterValues.startDate,
    filterValues.type,
    filterValues
  ]);

  console.log('selectedUser', selectedUser)
  console.log('searchUser', searchUser)
  // console.log('searchedUserResult', searchedUserResult)

  const [txWith, setTxWith] = useState();

  const handleShowAllTx = () => {
    setTxWith();
    setSearchUser("");
    setSelectUser({});
    setTableData([]);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setInputFocused(false);
    }, 500);
  };
  useEffect(() => {
   
    getUserDetails();
  }, [adminData._id]);

  const getUserDetails = async () => {
    try {
      const res = await adminGetAPIAuth(`user/allaccountbox?userId=`)
      if (res?.data?.status === 200) {
        setUserData(res.data.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
console.log(userData)
  return (
    <>
      <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner ">
                <header className="page-title-bar justify-content-between row align-items-center">
                  <h1 className="page-title mb-0 text-nowrap">
                    {" "}
                    User Account Statement{" "}
                  </h1>
                </header>
               <div className="row g-3 mb-4">
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Total Debit </div>
                          <div className="adminTopCardSubtitle">{userData?.totalDebits?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Total Credit</div>
                          <div className="adminTopCardSubtitle">{userData.totalCredits?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Total IN Wallet</div>
                          <div className="adminTopCardSubtitle">{userData.totalInWallet?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle"> Total Loss</div>
                          <div className="adminTopCardSubtitle">{userData.totalLossPoints?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle"> Total Profit</div>
                          <div className="adminTopCardSubtitle">{userData.totalProfitPoints?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle"> Total Betting Wallet Balance</div>
                          <div className="adminTopCardSubtitle">{userData.points? userData.points?.toFixed(2):0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle"> Total Stock Wallet Balance</div>
                          <div className="adminTopCardSubtitle">{userData.stockBalance? userData.stockBalance?.toFixed(2):0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">  Total Exposure</div>
                          <div className="adminTopCardSubtitle">{userData.totalExposer?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {txWith &&
                  <header className="page-title-bar">
                    <div className="page-title pt-0">
                      <nav aria-label="breadcrumb text-nowrap">
                          <ol class="breadcrumb flex-nowrap overflow-auto pt-0 m-0">
                            <li class="breadcrumb-item cursor-pointer" onClick={handleShowAllTx}>
                              <div className="d-grid align-items-center">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M11.03 2.59a1.501 1.501 0 0 1 1.94 0l7.5 6.363a1.5 1.5 0 0 1 .53 1.144V19.5a1.5 1.5 0 0 1-1.5 1.5h-5.75a.75.75 0 0 1-.75-.75V14h-2v6.25a.75.75 0 0 1-.75.75H4.5A1.5 1.5 0 0 1 3 19.5v-9.403c0-.44.194-.859.53-1.144ZM12 3.734l-7.5 6.363V19.5h5v-6.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 .75.75v6.25h5v-9.403Z"></path></svg>
                              </div>
                            </li>
                            <li class="breadcrumb-item"> {txWith} </li>
                        </ol>
                      </nav>
                    </div>
                  </header>
                    }
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row align-items-end">
                      {/* <div className="col-md-auto pe-md-0 mb-2 mb-md-0">
                        <div className="adminSearchInput ">
                          <input
                            type="text"
                            placeholder="Search User"
                            value={searchUser}
                            onChange={(e) => setSearchUser(e?.target?.value)}
                            className="form-control adminFilterInp"
                            onFocus={() => setInputFocused(true)}
                            onBlur={handleInputBlur}
                          />
                          <div className="overflow-hidden">
                            {searchedUserResult?.length && inputFocused ? (
                              <>
                                <ul className="list-unstyled adminsearchDropDown overflow-Y-auto">
                                  {
                                    searchedUserResult?.map((item) => (
                                      <li
                                        className="userName"
                                        key={item?._id}
                                        onClick={() => {
                                          console.log('item', item)
                                          setTxWith(item.username)
                                          setSelectUser(item);
                                        }}
                                      >
                                        {item.username}
                                      </li>
                                    ))
                                  }
                                </ul>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md">
                        <div className="row justify-content-end mx-md-0">
                          {txWith &&
                          <div className="col-md-auto col-12 col-sm-4 pe-md-0 mb-2 mb-sm-0">
                            <label
                              htmlFor="type"
                              className="form-label"
                            >
                              Transactions
                            </label>
                            <select
                              name="type"
                              id="type"
                              className="form-select adminFilterInp"
                              onChange={handleChange}
                            >
                              {transactionArr.map((item) => (
                                <option value={item.value}>{item.label}</option>
                              ))}
                            </select>
                          </div>
                            }
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="startDate" className="form-label">
                              From
                            </label>
                            <input
                              type="date"
                              name="startDate"
                              id="startDate"
                              className="form-control adminFilterInp"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="endDate" className="form-label">
                              To
                            </label>
                            <input
                              type="date"
                              name="endDate"
                              min={filterValues?.startDate}
                              value={filterValues?.endDate}
                              id="endDate"
                              className="form-control adminFilterInp"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table">
                        <thead className="text-uppercase text-nowrap">
                          <tr>
                          <th>
                              <div className="d-flex">Username</div>
                            </th>
                            <th>
                              <div className="d-flex">Date & Time</div>
                            </th>
                            <th>
                              <div className="d-flex">Open Balance</div>
                            </th>
                            {/* <th>
                                  <div className="d-flex">Event</div>
                                </th>
                                <th>
                                  <div className="d-flex">Market Type</div>
                                </th> */}
                            <th>
                              <div className="d-flex">Closed Balance</div>
                            </th>
                            <th>
                              <div className="d-flex">Transaction Type</div>
                            </th>
                            <th>
                              <div className="d-flex">Transaction ID</div>
                            </th>
                          </tr>
                        </thead>
                        {!Object.keys(selectedUser).length ?
                          childrenList.length ?
                            <tbody>
                              {childrenList.map((item) => (
                                <tr>
                                 <td>{item.username}</td>
                                  <td>{dateFormatter(item.createdAt, true)}</td>
                                  <td
                                    className="fw-bold cursor-pointer"
                                    onClick={() => {
                                      setTxDetails(item);
                                      setShowTxModal(true);
                                    }}
                                  >
                                    {item?.opningBal}{" "}
                                  </td>
                                  {/* <td> </td>
                                    <td> </td> */}
                                  {/* <td className={`${!item?.isDeposit ? 'admin-forms-error-text' : 'admin-forms-success-text'}`}> */}
                                  <td>
                                    {item?.clossingBal ? item?.clossingBal?.toFixed(2) : 0.00}
                                  </td>
                                  <td>{item?.transactionType}</td>
                                  <td>{item.transaction_id}</td>
                                </tr>
                              ))}
                            </tbody>
                            :
                            <tbody>
                              <tr>
                                <td colSpan={9}>
                                  <div className="text-center" style={{ fontSize: 18, fontWeight: 500 }} >Account statement not found</div>
                                </td>
                              </tr>
                            </tbody>
                          :
                         <div className="text-center" style={{ fontSize: 18, fontWeight: 500 }} >Please select a user to view statement</div>

                          // <tbody>
                          //   {childrenList.map((item,i)=>{
                          //     return(
                          //   <tr
                          //   onClick={() => {
                          //     // setTxDetails(item);
                          //     // setShowTxModal(true);
                          //     // setSearchUser(item.username);
                          //     setTxWith(item.username)
                          //     setSelectUser(item);
                          //     handleGetList(item);
                          //     setCurrentPage(1);
                          //   }}
                          //   >
                          //     {/* <td colSpan={9}> */}
                          //       {/* <div className="text-center" style={{ fontSize: 18, fontWeight: 500 }} >Please select a user to view statement</div> */}
                          //       <td> {dateFormatter(
                          //               // item?.matchId?.event?.openDate,
                          //               item?.createdAt,
                          //               true
                          //             )}</td>
                          //         <td
                          //           className="fw-bold cursor-pointer"
                          //           onClick={() => {
                          //             // setTxDetails(item);
                          //             setShowTxModal(true);
                          //             // setSearchUser(item.username);
                          //             // setTxWith(item.username)
                          //             // setSelectUser(item);
                          //             // handleGetList(item);
                          //             // setCurrentPage(1);
                          //           }}
                          //         >
                          //            {item?.opningBal ? item?.opningBal?.toFixed(2) : 0.00}
                          //         </td>
                          //         {/* <td> </td>
                          //           <td> </td> */}
                          //         {/* <td className={`${!item?.isDeposit ? 'admin-forms-error-text' : 'admin-forms-success-text'}`}> */}
                          //         <td>
                          //           {item?.clossingBal ? item?.clossingBal?.toFixed(2) : 0.00}
                          //         </td>
                          //         <td>{item?.totalMainBal?.toFixed(2)}</td>
                          //         <td>{item.transaction_id}</td>
                          //     {/* </td> */}
                          //   </tr>
                          //   )
                          // })}
                          // </tbody>
                        }
                      </table>
                    </div>
                  </div>
                  {
                    childrenList || tableData.length || searchUser?.length && totalPages  > 1 ?
                      <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      /> : ''
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <AdminTxModal
        show={showTxModal}
        setShow={setShowTxModal}
        data={txDetails}
      />
    </>
  );
};

export default UserAccountStatement;
