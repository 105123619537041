import React from 'react'

const AdminLoader = () => {
  return (
    <div className="position-fixed top-0 start-0" style={{display:"flex", justifyContent:'center', alignItems:'center', width:"100%", height:"100vh", zIndex: 9999999999999, background: '#0D131C'}}>
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100">
          <circle cx="30" cy="50" r="8" fill="#a7df00">
            <animate attributeName="r" values="8;15;8" dur="1.5s" repeatCount="indefinite" />
          </circle>
          <circle cx="50" cy="50" r="15" fill="#ccff00">
            <animate attributeName="r" values="15;8;15" dur="1.5s" repeatCount="indefinite" />
          </circle>
          <circle cx="70" cy="50" r="8" fill="#a7df00">
            <animate attributeName="r" values="8;15;8" dur="1.5s" repeatCount="indefinite" />
          </circle>
        </svg>
      </span>
    </div>
  )
}

export default AdminLoader