import React, { useState } from "react";


export const UserModalContext = React.createContext(null)

const UserModalContextProvider = ({ children }) => {
    const [isUserOpen, setIsUserOpen] = useState(false)
    return <UserModalContext.Provider value={{ isUserOpen, setIsUserOpen }}>{children}</UserModalContext.Provider>;
};

export default UserModalContextProvider;
