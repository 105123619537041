import React, { useState, useEffect } from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import TrophyIcon from './../../fantasyAssets/img/trophyIcon.png'
import deposit from './../../fantasyAssets/img/depositIcon.png'
import transaction from './../../fantasyAssets/img/transactionhistory.svg'
import inviteFriend from './../../fantasyAssets/img/invitefriends.svg'
import verify from './../../fantasyAssets/img/verify.svg'
import cashbonus from './../../fantasyAssets/img/cashBonus.png'
import rytArrow from './../../fantasyAssets/img/rytChewan.png'
import addcash from './../../fantasyAssets/img/Addcase.svg'
import { Link } from 'react-router-dom'
import { getFantasyAuth } from '../../fantasyServices'
import { useSelector } from 'react-redux'
import { ROUTES_CONST } from '../../../constants/routeConstant'

const FantasyWallet = () => {
    const [walletData, setWalletData] = useState({})
    const fantasyUserData = useSelector(state => state?.fantasyUserData)

    const getContest = async () => {
        try {
            let res = await getFantasyAuth(`/mywalletdetails`);
            setWalletData(res.data.data);
        } catch (error) {
            console.log("FantasyWallet error ....", error)
        }
    };

    useEffect(() => {
        getContest()
    }, [fantasyUserData])

    return (
        <>
            <div className="row fantasyInner">
                <FantasyHeader gameHeader={true} title={'Wallet'} />
                <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                    <div className="row mainCenterPart g-4 mx-0 py-4">
                        <div className="col-12">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto"><div className="rounded-circle d-flex align-items-center justify-content-center rupees text-white fw-bold">₹</div></div>
                                <div className="col ps-0">
                                    <div className="balance text-white">Total Balance</div>
                                    <div className="balanceAmount text-white">₹ {walletData?.totalamount}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="warningCard card border-0">
                                <div className="card-body">
                                    <div className="row mx-0 align-items-center">
                                        <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={TrophyIcon} className="w-100" alt="" /></div></div>
                                        <div className="col">
                                            <div className="balanceAmountSubTxt text-white ">Your Winnings</div>
                                            <div className="balanceSubTxt text-white">Withdraw Your Winnings</div>
                                        </div>
                                        <div className="col-auto pe-0"><div className="winningAmount balanceAmount">₹ {walletData?.winning}</div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="withdrawAmount card border-0  pt-3 position-relative">
                                <Link
                                    to={fantasyUserData.verified ? ROUTES_CONST.FANTASY_WITHDRAW : ROUTES_CONST.FANTASY_VERIFY_ACCOUNT}
                                    className="py-4 pb-3 text-decoration-none balanceAmount text-center text-white">Withdraw Amount</Link>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="dipositCard shadow p-2 text-center">
                                <div className="warningImg rounded-circle mx-auto mb-2"><img src={deposit} className="w-100" alt="" /></div>
                                <div className="balance depositText ">Deposited</div>
                                <div className="subbalance ">Money You Added</div>
                                <div className="subbalanceTwo text-danger">₹ {walletData?.balance?walletData?.balance:0}</div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="dipositCard  shadow  p-2 text-center">
                                <div className="warningImg rounded-circle mx-auto mb-2"><img src={cashbonus} className="w-100" alt="" /></div>
                                <div className="balance depositText">Cash Bonus</div>
                                <div className="subbalance ">Offer Earned Cash Bonus</div>
                                <div className="subbalanceTwo fw-bold text-success">₹ {walletData?.bonus?walletData?.bonus:0}</div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="warningCard addcase card border-0 rounded-4 position-relative">
                                <div className="card-body">
                                    <div className="row mx-0 align-items-center">
                                        <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={addcash} className="w-100" alt="" /></div></div>
                                        <div className="col"        >
                                            <Link to={ROUTES_CONST.FANTASY_DEPOSIT_CASH} className="balanceAmount balanceAmountTxt stretched-link ">Add Cash</Link>
                                            <div className="balance text-white">UPI,Credit Card,Debit Card & more</div>
                                        </div>
                                        <div className='col-auto'><img className='rytArrow' src={rytArrow} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="warningCard card border-0 rounded-4 position-relative">
                                <div className="card-body">
                                    <div className="row mx-0 align-items-center">
                                        <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={transaction} className="w-100" alt="" /></div></div>
                                        <div className="col">
                                            <Link to={ROUTES_CONST.FANTASY_TRANSACTION} className="balanceAmount balanceAmountTxt stretched-link ">Transaction History</Link>
                                        </div>
                                        <div className='col-auto'><img className='rytArrow' src={rytArrow} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="warningCard card border-0 rounded-4 position-relative">
                                <div className="card-body">
                                    <div className="row mx-0 align-items-center">
                                        <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={inviteFriend} className="w-100" alt="" /></div></div>
                                        <div className="col">
                                            <Link to={ROUTES_CONST.FANTASY_INVITE_FRIEND} className="balanceAmount balanceAmountTxt stretched-link">Invite Your Friends</Link>
                                        </div>
                                        <div className='col-auto'><img className='rytArrow' src={rytArrow} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="warningCard card border-0 rounded-4 position-relative">
                                <div className="card-body">
                                    <div className="row mx-0 align-items-center">
                                        <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={verify} className="w-100" alt="" /></div></div>
                                        <div className="col">
                                            <Link to={ROUTES_CONST.FANTASY_VERIFY_ACCOUNT} className="balanceAmount balanceAmountTxt stretched-link">Kyc & Verify Your Account</Link>
                                        </div>
                                        <div className='col-auto'><img className='rytArrow' src={rytArrow} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FantasyBottomBar />
            </div>
        </>
    )
}

export default FantasyWallet