import React, { useEffect, useState } from 'react'
import { getFantasyAuth } from '../../fantasyServices';
import scorecardData from '../../../constants/scorecardData.json'
import { dummyData } from '../../../constants/ScoreDataDummy';

const ScoreCard = ({ matchKey }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [score, setScore] = useState([])
  const [open, setOpen] = useState('')
  const getScoreData = async () => {
    setIsLoading(true);
    try {
      const res = await getFantasyAuth(`/matchlivescore?matchkey=${matchKey}`);
      console.log(res)
      if (res.data) {
        setScore(res?.data?.data);
      }
    } catch (error) {

    } finally {
      setIsLoading(false);
    }

  };
  useEffect(() => {
    // getScoreData()
    setScore(dummyData)
  }, [])
  useEffect(() => {
    setOpen(score[0]?.name)
    // getScoreData()
  }, [score])
  return (
    <>
      <div className='row mx-0'>

        <div
          className={`d-flex col-6  ${score[0]?.name === open ? "active" : ""} scoreCradTeams`}
          onClick={() => setOpen(score[0]?.name)}
        >
          <div className="text-center">
            {score[0]?.name}
          </div>
          <div className="text-center">
            (
            {
              score[0]?.score
            }
            )
          </div>
        </div>
        <div
          className={`d-flex col-6  ${score[1]?.name === open ? "active" : ""} scoreCradTeams`}
          onClick={() => setOpen(score[1]?.name)}
        >
          <div className="text-center">
            {score[1]?.name}
          </div>
          <div className="text-center">
            ({score[1]?.score})
          </div>
        </div >
      </div >

      <div className="col-12 px-0 mt-3">
        <div>
          {
            score.map(item => (
              <div className='my-2'>
                {/* <div className='d-flex bg-dark py-3 justify-content-evenly text-light ' onClick={() => setOpen(item.name)}>
                  <div className="text-center">
                    {item.name}
                  </div>
                  <div className="text-center">
                    {item.score}
                  </div>
                </div> */}
                <div className='createTeam'>
                  {open === item.name ? (
                    <div className='tableSectionMain'>
                      {/* batting */}
                      <table className='table tableSection  align-middle table-hover'>
                        <thead className='w-100' >
                          <tr className='w-100'>
                            <th scope="col">Batting</th>
                            <th scope="col">R</th>
                            <th scope="col">B</th>
                            <th scope="col">4s</th>
                            <th scope="col">6s</th>
                            <th scope="col" className='text-end'>S/R</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            item.batsmen.map(elem => (
                              <tr>
                                <td className=''><div className='creditHeading'>{elem.name}</div></td>
                                <td className=''><div className='creditHeading'>{elem.runs}</div></td>
                                <td className=''><div className='creditHeading'>{elem.balls}</div></td>
                                <td className=''><div className='creditHeading'>{elem.fours}</div></td>
                                <td className=''><div className='creditHeading'>{elem.sixes}</div></td>
                                <td className=''><div className='text-end creditHeading'>{elem.strike_rate}</div></td>
                              </tr>
                            ))
                          }
                        </tbody>

                      </table>
                      <hr className='text-light' />
                      {/* extras */}
                      <div className='row mx-0 px-2 scoreCardTxt'>
                        <div className='col'>
                          Extras
                        </div>
                        <div className='col-auto'>
                          {/* {`${}`} */}
                          {`b ${item.extra_runs.byes}, lb ${item.extra_runs.legbyes}, w ${item.extra_runs.wides}, nb ${item.extra_runs.noballs}, p ${item.extra_runs.penalty}`}
                        </div>
                      </div>
                      <hr className='text-light' />
                      {/* Yet to bat */}
                      <div className='row mx-0 px-2 scoreCardTxt'>
                        <div className='col-auto'>
                          Yet to bat
                        </div>
                        <div className='col'>
                          {/* {`${}`} */}
                          {item.did_not_bat}
                          {/* {`b ${item.extra_runs.byes}, lb ${item.extra_runs.legbyes}, w ${item.extra_runs.wides}, nb ${item.extra_runs.noballs}, p ${item.extra_runs.penalty}`} */}
                        </div>
                      </div>
                      <hr className='text-light' />

                      {/* bowlers */}

                      <table className='table tableSection  align-middle table-hover'>
                        <thead className='w-100' >
                          <tr className='w-100'>
                            <th scope="col">Bowling</th>
                            <th scope="col">O</th>
                            {/* <th scope="col">B</th> */}
                            <th scope="col">M</th>
                            <th scope="col">R</th>
                            <th scope="col">W</th>
                            <th scope="col" className='text-end'>ER</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            item.bowlers.map(elem => (
                              // last td k dive pr text-end ki class aaygi
                              <tr>
                                <td><div className='creditHeading'>{elem.name}</div></td>
                                <td><div className='creditHeading'>{elem.overs}</div></td>
                                <td><div className='creditHeading'>{elem.maidens}</div></td>
                                <td><div className='creditHeading'>{elem.runs}</div></td>
                                <td><div className='creditHeading'>{elem.wickets}</div></td>
                                <td><div className='text-end creditHeading'>{elem.economy_rate}</div></td>
                                {/* <td><div className='text-end creditHeading'>{elem.strike_rate}</div></td> */}
                              </tr>
                            ))
                          }
                        </tbody>

                      </table>
                      <hr className='text-light' />

                      {/* Fall of wickets */}

                      <table className='table tableSection  align-middle table-hover'>
                        <thead className='w-100' >
                          <tr className='w-100'>
                            <th scope="col">Fall of wickets</th>
                            <th scope="col">Score</th>
                            <th scope="col" className='text-end'>Over</th>
                            {/* <th scope="col">M</th> */}
                            {/* <th scope="col">R</th> */}
                            {/* <th scope="col">W</th> */}
                            {/* <th scope="col"  >ER</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            item?.fall_of_wickets?.map(elem => (
                              <tr>
                                <td><div className='creditHeading'>{elem?.name}</div></td>
                                <td><div className='creditHeading'>{elem?.runs}-{elem?.balls}</div></td>
                                <td><div className='text-end creditHeading'>{elem?.overs_at_dismissal}</div></td>
                                {/* <td><div className='creditHeading'>{elem.runs}</div></td> */}
                                {/* <td><div className='creditHeading'>{elem.wickets}</div></td> */}
                                {/* <td><div className='creditHeading'>{elem.economy_rate}</div></td> */}
                                {/* <td><div className='creditHeading'>{elem.strike_rate}</div></td> */}
                              </tr>
                            ))
                          }
                        </tbody>

                      </table>
                      <hr className='text-light' />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

            ))
          }
        </div>

      </div >
    </>
  )
}

export default ScoreCard