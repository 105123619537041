import React from 'react';
import { Line } from 'rc-progress';

const  CircularProgressBar = ({ tickets, sold }) => {
  let percentageSold = Math.round((sold / tickets) * 100);
  const strokeWidth = 10; 
  const strokeColor = '#cf0';
  const trailWidth = 10; 
  const trailColor = '#E0E0E0';
  const containerHeight = 16;

  return (
    <div style={{ position: 'relative', width: '100px', height: `${containerHeight}px` , display: 'flex' }}>
      <Line
        percent={percentageSold}
        strokeWidth={strokeWidth}
        strokeColor={strokeColor}
        trailWidth={trailWidth}
        trailColor={trailColor}
      />
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' , fontSize : '10px' , color : 'black' }}>
        <strong>{percentageSold}%</strong>
      </div>
    </div>
  );
};

export default CircularProgressBar;
