import React, { useEffect, useState } from "react";
import MatchCard from "../../fantasyComponents/matchCard/MatchCard";
import { getFantasyAuth } from "../../fantasyServices";
import { useLocation } from "react-router-dom";

const MatchCricket = () => {
  const [activeTab, setActiveTab] = useState("upcoming");
  const [LiveData, setLiveData] = useState([]);
  const [upcomingData, setUpcomingData] = useState([]);
  const [CompletedData, setCompletedData] = useState([]);
  // const { active }= useLocation().state
  const state= useLocation().state
  const active =state?.active
  console.log({ active })
  useEffect(() => {
    if (active) {
      setActiveTab(active)
    }
  }, [active])
  const getCompletedData = async () => {
    try {
      let res = await getFantasyAuth(`all-completed-matches`);
      setCompletedData(res.data.data);
    } catch (error) {
      console.log("error", error)
    }
  };

  const getLiveData = async () => {
    try {
      let res = await getFantasyAuth(`livematches`);
      setLiveData(res.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getUpcoming = async () => {
    try {
      let res = await getFantasyAuth(`newjoinedmatches`);
      setUpcomingData(res.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUpcoming()
    getLiveData();
    getCompletedData()
  }, []);

  return (
    <>
      <div className="col-12 contestOptionsTabInner mb-3">
        <div className="row mx-0 align-items-center justify-content-between">
          <div className="col-4">
            <div
              onClick={() => setActiveTab("upcoming")}
              className={`contestOptionTab text-center text-uppercase ${activeTab === "upcoming" ? "active" : ""
                }`}
            >
              Upcoming
            </div>
          </div>
          <div className="col-4">
            <div
              onClick={() => setActiveTab("live")}
              className={`contestOptionTab text-center text-uppercase ${activeTab === "live" ? "active" : ""
                }`}
            >
              Live
            </div>
          </div>
          <div className="col-4">
            <div
              onClick={() => setActiveTab("completed")}
              className={`contestOptionTab text-center text-uppercase ${activeTab === "completed" ? "active" : ""
                }`}
            >
              Completed
            </div>
          </div>
        </div>
      </div>
      {activeTab === "upcoming" ? upcomingData.length ? (
        <>
          <div className="col-12">
            {upcomingData?.map((item) => (
              <MatchCard
                upcoming={true}
                series_name={item?.series_name}
                matchname={item?.matchname}
                team1ShortName={item?.team1ShortName}
                team2ShortName={item?.team2ShortName}
                team1fullname={item?.team1fullname}
                team2fullname={item?.team2fullname}
                status={item?.status}
                final_status={item?.final_status}
                team1logo={item?.team1logo}
                team2logo={item?.team2logo}
                time_start={item?.start_date}
                playing11_status={item?.playing11_status}
                id={item?.matchkey ? item?.matchkey : item?.id}
                match={item}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="text-white text-center">No data</div>
        </>
      ) : activeTab === "live" ? LiveData.length ? (
        <>
          <div className="col-12">
            {LiveData?.map((item) => (
              <MatchCard
                series_name={item?.series_name}
                matchname={item?.matchname}
                team1ShortName={item?.team1ShortName}
                team2ShortName={item?.team2ShortName}
                team1fullname={item?.team1fullname}
                team2fullname={item?.team2fullname}
                status={item?.status}
                final_status={item?.final_status}
                team1logo={item?.team1logo}
                team2logo={item?.team2logo}
                start_date={item?.start_date}
                playing11_status={item?.playing11_status}
                id={item?.matchkey ? item?.matchkey : item?.id}
                match={item}
                live={true}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="text-white text-center">No data</div>
        </>
      ) : activeTab === "completed" ? CompletedData.length ? (
        <>
          <div className="col-12">
            {CompletedData?.map((item) => (
              <MatchCard
                series_name={item?.series_name}
                matchname={item?.matchname}
                team1ShortName={item?.team1ShortName}
                team2ShortName={item?.team2ShortName}
                team1fullname={item?.team1fullname}
                team2fullname={item?.team2fullname}
                status={item?.status}
                final_status={item?.final_status}
                team1logo={item?.team1logo}
                team2logo={item?.team2logo}
                start_date={item?.start_date}
                playing11_status={item?.playing11_status}
                match={item}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="text-white text-center">No data</div>
        </>
      ) : ""}
    </>
  );
};

export default MatchCricket;
