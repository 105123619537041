import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import { getAPIAuth, postAPI } from "../../service/apiInstance";
// import { GoogleLogin } from "../../pages/socialMedia/GoogleLogin";
import { GoogleLogin } from "../socialMedia/GoogleLogin";
// import { MetaMasks } from "../../pages/socialMedia/metaMask";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../common/ErrorMessage/ErrorMessage";
import { useSignInModal } from "../../hooks/useSignInModal";
import { useBtAuth } from "../../hooks/useBtAuth";
import { useAuth } from "../../hooks/useAuth";
import { useSignUpModal } from "../../hooks/useSignUpModal";
import SignUp from "../signUp/signUp";
import Forgetpassword from "./forgotpassword/forgetpassword";
import Emailverify from "./forgotpassword/emailverify";
import EmailOtp from "./forgotpassword/emailOtp";
import { useEffect } from "react";
import { isEmail } from "../../utils/isEmail";
import PhoneOtp from "./PhoneOtp";
import TelegramComp from "../socialMedia/TelegramLoginButton";
import MetaMaskButton from "../socialMedia/MetaMaskButton";
// import MetaMaskModal from "../socialMedia/MetamaskModal";
import TelegramInputModal from "../socialMedia/TelegramInputModal";
import Poaster from "../Poaster/poaster";
import { Spinner } from "react-bootstrap";
import { reducerConst, sagaConst } from "../../constants/storeConstants";
import { useDispatch } from "react-redux";
import axios from "axios";
import { TOKEN_NAME } from "../../constants";
import { fetchUserDetails } from "../../service/fetchUserDetails";
// const generateRecaptcha = () => {
//   window.recaptchaVerifier = new RecaptchaVerifier(
//     "recaptcha",
//     {
//       size: "invisible",
//       callback: (response) => {
//         // reCAPTCHA solved, allow signInWithPhoneNumber.
//         // ...
//       },
//     },
//     auth
//   );
// };

// const handleSend = (event) => {
//   // event.preventDefault();
//   setHasFilled(true);
//   generateRecaptcha();
//   let appVerifier = window.recaptchaVerifier;
//   signInWithPhoneNumber(auth, `+91 ${phone}`, appVerifier)
//     .then((confirmationResult) => {
//       succesToaster("OTP SENT!");
//       window.confirmationResult = confirmationResult;
//     })
//     .catch((error) => {
//       errorToaster("Something Went Wrong!!");
//     });
//   setIsSignInOpen(false);
// };

const validationSchema = Yup.object({
  emailOrNumber: Yup.string()
    .required("this field is required")
    .matches(
      /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
      "Invalid email or mobile number"
    ),
  password: Yup.string().required(),
});

function SignIn() {
  const [phone, setPhone] = useState("+91");
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");
  const [phoneerrmsg, setphonemsg] = useState("");
  const { isSignInOpen, setIsSignInOpen } = useSignInModal();
  const { isSignUpOpen, setIsSignUpOpen } = useSignUpModal();
  const { btToken, setBtToken } = useBtAuth();
  const { token, setToken } = useAuth();
  const [forgetpass, setForgetpass] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [logOn, setLogOn] = useState(true);
  const [emailPassword, setEmailPassword] = useState(false);
  const [phoneOtp, setPhoneOtp] = useState(false);
  const [optid, setotpid] = useState("");
  const [openPass, setOpenpass] = useState(false);
  const [isMetamask, setIsMetamask] = useState(false)
  const [open, setopen] = useState(false);
  const [isTelegram, setIsTelegram] = useState(false)
  const [userObj, setUserObj] = useState({})
  const [otpLoader, setOtpLoader] = useState(false)
  const dispatch = useDispatch()
  const initialValue = {
    emailOrNumber: "",
    password: "",
  };

  const generateRecaptcha = () => {
    if (window?.recaptchaVerifier?.recaptcha) {
      window.recaptchaVerifier.recaptcha.reset()
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
        callback: (response) => {
          // console.log("response ==from == recaptcha", response)
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
      },
      auth
    );
  };

  const handleSend = (emailOrNumber) => {
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, `+91 ${emailOrNumber}`, appVerifier)
      .then((confirmationResult) => {
        succesToaster("OTP SENT!");
        setotpid(confirmationResult.verificationId);
        window.confirmationResult = confirmationResult;
        setLogOn(false)
        setPhoneOtp(true)
        setOtpLoader(false)
      })
      .catch((error) => {
        errorToaster("Something Went Wrong!!");
        setOtpLoader(false)
        // window.recaptchaVerifier.render().then(function (widgetId) {
        //   appVerifier.reset(widgetId);
        // });
      });
  };

  const verifyOtp = (event) => {
    let otp = event.target.value;
    setOtp(otp);

    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          let user = result.user;
          // alert("User signed in successfully");
        })
        .catch((error) => {
          // alert("User couldn't sign in (bad verification code?)");
        });
    }
  };
  async function signInWEAP(values, resetForm) {
    try {
      if (values.emailOrNumber) {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          values.emailOrNumber,
          values.password
        );

        // Signed in
        const body = {
          email: values.emailOrNumber,
          password: values.password,
        };
        const user = userCredential.user.auth.email;
        const res = await postAPI("user/login-user", body);
        if (res?.data?.success) {
          // alert("inside")
          setBtToken(res?.data?.data?.betByToken);
          setToken(res?.data?.data?.auth_token);
          setIsSignInOpen(false);
          setEmailPassword(false)
          setLogOn(true)
          resetForm()
        } else {
          errorToaster(res?.data?.message);
        }
      }

    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      // errorToaster(error.message)
      errorToaster("Something went wrong!")
    }
  }

  const Checkfunc = () => {
    return true
  }

  useEffect(() => {
    if (token != null) {
      // dispatch({ type: sagaConst.FETCH_USER_DETAILS });
      // const getProfileData = async () => {
      //   const res = await fetchUserDetails(token)
      //   dispatch({ type: reducerConst.USER_DETAILS, payload: res?.data?.data ? res.data.data : {} })
      // }
      // getProfileData()
    }
  }, [token])

  const handleClick = () => {
    setForgetpass(true);
    setLogOn(false);
    setEmailPassword(false)
  };
  return (
    <>
      <div
        className={`modal fade ragisterModal ${isSignInOpen ? "show" : ""}`}
        id="exampleModal"
        tabIndex={-1}
        style={{
          display: isSignInOpen ? "block" : "none",
        }}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close btnClose"
                onClick={() => {
                  setIsSignInOpen(false)
                  setLogOn(true)
                  setEmailPassword(false)
                }}
              />
            </div>
            <div className="modal-body">
              <div className="row align-itemscenter">
                <Poaster />
                <div className="col-lg-6">
                  <Formik
                    initialValues={initialValue}
                    validationSchema={validationSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      resetForm
                    }) => (
                      <Form
                        className="row mx-0 form gap-3"
                      >
                        <div className="col-12 fw-bold text-white registerHeading mb-3 d-lg-block d-none">
                          Sign In
                        </div>
                        {logOn ? (
                          <>
                            <div className="col-12">
                              <input
                                type="text"
                                className="form-control border-0 shadow-none"
                                id="emailOrNumber"
                                placeholder="Email\Phone number"
                                value={values.emailOrNumber}
                                onBlur={handleBlur}
                                name="emailOrNumber"
                                onChange={handleChange}
                              />
                              <ErrorMessage>
                                {errors.emailOrNumber &&
                                  touched.emailOrNumber &&
                                  errors.emailOrNumber}
                              </ErrorMessage>
                            </div>

                            <div className="col-12 forget d-flex justify-content-end text-white">
                              <span
                                className="cursor-pointer"
                                onClick={handleClick}
                              >
                                Forgot your Password?
                              </span>
                            </div>
                            <div className="col-12 mx-auto">
                              <button
                                type="button"
                                className="btn ragisterBtn w-100 border-0"
                                id="recaptcha"
                                disabled={errors.emailOrNumber &&
                                  touched.emailOrNumber &&
                                  errors.emailOrNumber}
                                onClick={() => {
                                  setOtpLoader(true)
                                  if (!!!values.emailOrNumber || values.emailOrNumber == '') {
                                    errorToaster("Provide Email/Phone")
                                    return
                                  }
                                  if (isEmail(values.emailOrNumber)) {
                                    setLogOn(false)
                                    setEmailPassword(true)
                                  } else {
                                    handleSend(values.emailOrNumber)
                                  }
                                }}
                              >
                                {
                                  otpLoader ? <Spinner size="sm" animation="border" variant="light" /> : 'Continue'
                                }
                              </button>
                            </div>
                            <div className="col-12">
                              <div className="para1">
                                New to BX ?{" "}
                                <span
                                  // href="javascript:;"
                                  className="paraAnchor cursor-pointer"
                                  onClick={() => {
                                    setIsSignInOpen(false);
                                    setIsSignUpOpen(true);
                                  }}
                                >
                                  Create account
                                </span>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="row align-items-center justify-content-center topMargin">
                                <span
                                  className="col-auto d-flex pe-0 cursor-pointer"
                                >
                                  <div className="link">
                                    <GoogleLogin parent={"signIn"}>
                                      <img src="assets/img/link1.png" alt="" />
                                    </GoogleLogin>
                                  </div>
                                </span>
                                {/* telegram */}
                                {/* <a
                                  href="javascript:;"
                                  className="col-auto d-flex pe-0"
                                >
                                  <div className="link">
                                    <img src="assets/img/link2.png" alt="" />
                                  </div>
                                </a> */}
                                {/* <MetaMaskButton /> */}
                                <span
                                  // href="javascript:;"
                                  className="col-auto d-flex cursor-pointer"
                                >
                                  <div className="link">
                                    <img
                                      onClick={() => setIsMetamask(true)}
                                      src="assets/img/link3.png" alt=""
                                    />
                                  </div>
                                </span>
                              </div>
                              {
                                isSignInOpen ? (
                                  <div className="col-auto d-flex h-25 justify-content-center my-3">
                                    <TelegramComp setIsOpen={setIsTelegram} userObj={userObj} setUserObj={setUserObj} />
                                  </div>
                                ) : (<></>)
                              }
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {
                          emailPassword ? (
                            <>
                              <div className="col-12 position-relative">
                                <input
                                  type={open ? "text" : "password"}
                                  name="password"
                                  className="form-control border-0 shadow-none"
                                  id="password"
                                  placeholder="Login Password"
                                  value={values.password}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                                <div className="eye">
                                  <img
                                    src="assets/img/eyeIcon.png"
                                    className="mb-1"
                                    onClick={() => setopen((prev) => !prev)}
                                    alt=""
                                  />
                                  {/* <img
                                  src="assets/img/eyeIcon.png"
                                  className="mb-1"
                                  onClick={() => setopen((prev) => !prev)}
                                  alt=""
                                /> */}
                                </div>

                                <ErrorMessage>
                                  {errors.password &&
                                    touched.password &&
                                    errors.password}
                                </ErrorMessage>
                              </div>
                              <div className="col-12 forget d-flex justify-content-end text-white">
                                <span
                                  className="cursor-pointer"
                                  onClick={handleClick}
                                >
                                  Forgot your Password?
                                </span>
                              </div>
                              <div className="col-12 mx-auto">
                                <button
                                  type="button"
                                  className="btn ragisterBtn w-100 border-0"
                                  onClick={(e) => signInWEAP(values, resetForm)}
                                  disabled={(errors.password &&
                                    touched.password &&
                                    errors.password)}
                                >
                                  Sign In
                                </button>
                              </div>
                            </>
                          ) : (<></>)
                        }

                        {
                          phoneOtp ? (
                            <PhoneOtp
                              setLogOn={setLogOn}
                              setPhoneOtp={setPhoneOtp}
                            />
                          ) : (
                            <></>
                          )
                        }
                        {forgetpass ? (
                          <Emailverify
                            setForgetpass={setForgetpass}
                            setOtpOpen={setOtpOpen}
                            setLogOn={setLogOn}
                            setOpenpass={setOpenpass}
                          />
                        ) : (
                          <></>
                        )}

                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="modal-footer">
          <div class='footerImg'><img src={ModalFooter} alt='ModalFooter' /></div>
          </div> */}
      </div>
      {/* <MetaMaskModal isOpen={isMetamask} setIsOpen={setIsMetamask} parent={"signIn"} /> */}
      <TelegramInputModal isOpen={isTelegram} setIsOpen={setIsTelegram} userObj={userObj} setUserObj={setUserObj} />
    </>
  );
}

export default SignIn;
