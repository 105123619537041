import React from 'react'

const PriceBreakup = ({ data }) => {
  return (
    <>
      <div className="col-12 px-0">
        <div className="tableSectionMain">
          <table className="table tableSection  align-middle table-hover">
            <thead>
              <tr>
                <th scope="col" className=''>Rank</th>
                <th scope="col" className='text-end'>Price</th>
              </tr>
            </thead>
            <tbody>
              {data?.price_card?.map((el) => (
                <tr>
                  <td className='creditHeading'>Rank: {el.start_position}</td>
                  <td><div className="text-end creditHeading">{el.gift_type == 'gift'
                    ? `${el.price.split('.')[0]}`
                    : !!el.price
                      ? isNaN(el.price)
                        ? `₹ ${el.price.split('.')[0]}`
                        : `₹ ${el.price}`.split('.')[0]
                      : ''}</div></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-9 mx-auto px-1">
        <div class="prizeBreakupTxt text-center">Note: The actual price money may be different than the prize money mentioned above if there is a tie for any of the winning position. Check FAQs for futher deatils. As per govrenment regulations, a tax of 31.2% will be deducted if an individual wins more than ₹ 10,000.</div>
      </div>
    </>
  )
}

export default PriceBreakup