import React, { useEffect, useState } from "react";
import { getAPIAuth } from "../../service/apiInstance";
import { useSelector } from "react-redux";
import moment from "moment";
import { Spinner } from "react-bootstrap";
// import DarkPagination from "../../darkPagination/DarkPagination";
import DarkPagination from "../darkPagination/DarkPagination";
import ImageModal from "../imageModal/ImageModal";

const array = [
  // { name: "Profile", img: "profile" },
  { name: "Wallet", img: "wallet" },
  { name: "Converter", img: "switch" },
  { name: "Affiliate", img: "affiliate" },
  { name: "Deposits", img: "deposits" },
  { name: "Withdrawals", img: "withdraw" },
  { name: "Transactions", img: "Transactions" },
  { name: "Settings", img: "setting" },

];

function WalletTransection() {
     // console.log("tabopentabopen", tabopen);
     const tabopen="TRANSACTIONS";
     const openaccordian = "Transactions";
     const [cryptoList, setCryptoList] = useState([])
     const [activeCrypto, setActiveCrypto] = useState('ALL')
     const [transaction, setTransaction] = useState([])
     // console.log("transaction", transaction);
     const [transactionId, setTransactionId] = useState('')
     const [loader, setLoader] = useState(false)
     const [listLoader, setListLoader] = useState(true)
     const [showTransaction, setShowTransaction] = useState(false)
     const userDetails = useSelector((state) => state.userDetails);
     const userId = userDetails?.id;
     const userBalance = userDetails?.totalbalance;
     const [totalPages, setTotalPages] = useState(null);
     const [currentPage, setCurrentPage] = useState(1);
     const itemsPerPage = 10;
     const [showImgModal, setShowImgModal] = useState(false)
     const [imgUrl, setImgUrl] = useState('')
 
 
     // getting all crypto id
     const getCryptoList = async () => {
         setListLoader(true)
         try {
             const res = await getAPIAuth(`crypto/get-crypt-favorite-by-admin`)
             if (res?.data?.success) {
                 setCryptoList(res?.data?.data)
                 // setActiveCrypto(res?.data?.data?.[0]?.code)
                 setActiveCrypto("ALL")
             }
         } catch (error) {
 
         } finally {
             setListLoader(false)
         }
     }
 
     useEffect(() => {
         // getCryptoList()
     }, [])
 
 
     const getDeposits = async () => {
         setLoader(true)
         try {
             if (userId) {
                 const res = await getAPIAuth(`user/find-transaction?userId=${userId}&chain=eth&type=deposit&transactionId=${transactionId}`)
                 if (res?.data?.success) {
                     setTransaction(res?.data?.data)
                 } else {
                     setTransaction([])
                 }
             }
         } catch (error) {
 
         } finally {
             setLoader(false)
         }
     }
 
     const newDeposit = async () => {
         setLoader(true)
 
         try {
             // if (userId) {
             const res = await getAPIAuth(`user/getTransactionData`+`?page=${currentPage}`)
             console.log('deppppppppppppp', res);
             setCurrentPage(res.data.data.pagination.page)
             setTotalPages(res.data.data.pagination.totalPage);
 
             if (res?.data?.data?.success) {
                 setTransaction(res?.data?.data?.response)
                 // setTotalPages(res?.data?.data?.data?.pagination?.totalPages)
                 // setTransactionId("")
             } else {
                 setTransaction([])
             }
             // }
         } catch (error) {
 
             console.log("error", error);
 
         } finally {
             setLoader(false)
         }
     }
     useEffect(() => {
         // getDeposits()
         if (tabopen === "Transactions" || openaccordian === "Transactions") {
             // if (activeCrypto) {
             newDeposit()
             // }
         }
     }, [tabopen, openaccordian, currentPage])
 
     // getting data from transaction id
     const getDataFromTransactionId = () => {
         // newDeposit()
     }
 
     // for set  active crypto
     const handleActiveCrypto = (cryptoName) => {
         setActiveCrypto(cryptoName)
     }
     console.log(transaction)
     const startIndex = (currentPage - 1) * itemsPerPage + 1;

  return (
    <>
     <main className='main  vh-100'>
            <div className='mainContaint'>
              <div className='homepage tradingPages'>
              <div className="text-white font-weight-bold fs-4">Main Wallet Report</div>  
             <div className="">
            <div
                className="tab-pane fade show active"
                id="nav-allChains"
                role="tabpanel"
                aria-labelledby="nav-allChains-tab"
                tabIndex={0}
            >
                {!loader ?
                    transaction?.length > 0 ?
                        <div className="col-12 pt-3">
                            <div className="table-responsive">
                                <table className="table affiliateTable mb-2 align-middle text-white mx-5">
                                    <thead>
                                        <tr className="tableHead text-nowrap">
                                            <th scope="col" className="border-0 rounded-start-2 px-4">
                                                SR NO.
                                            </th>
                                            <th scope="col" className="border-0 px-4">
                                                AMOUNT
                                            </th>
                                            <th scope="col" className="border-0 px-4 texter">
                                                DATE/TIME
                                            </th>

                                            <th scope="col" className="border-0 px-4 texter">
                                                TRANSACTION ID
                                            </th>
                                            <th
                                                scope="col"
                                                className="border-0 px-4 text-er rounded-end-2"
                                            >
                                                STATUS
                                            </th>
                                            <th
                                                scope="col"
                                                className="border-0 px-4 text-er rounded-end-2"
                                            >
                                                DESC
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="tablebody">
                                        {transaction?.map((item, index) => (
                                            <tr className="text-nowrap" key={item?._id}>
                                                <td className="border-0  rounded-start-2 px-4">
                                                    {startIndex + index}
                                                </td>
                                                <td className="border-0 px-4">
                                                    {item?.amount ? Number(item?.amount).toFixed(2) : '0.00'}
                                                </td>
                                                <td className="border-0 px-4">{moment(item.createdAt).format("MMM DD YYYY , HH:mm")}</td>
                                                {/* <td className="border-0 px-4">

                                                    <img src={item?.image}
                                                        onClick={() => {
                                                            setImgUrl(item?.image)
                                                            setShowImgModal(true)
                                                        }}
                                                        className="object-fit-contain cursor-pointer"
                                                        style={{ height: 50, width: 100 }} alt="" />
                                                </td> */}
                                                <td className="border-0 px-4">{item?.transaction_id}</td>
                                                <td className="border-0 px-4 rounded-end-2">{item?.status}</td>
                                                <td className="border-0 px-4 rounded-end-2">{item?.desc}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {
                                totalPages > 1 ?
                                    <DarkPagination
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                    : ''
                            }
                        </div>
                        : (<div className="d-flex align-items-center justify-content-center">
                            <div className="NoEntry">NO ENTRIES</div>
                        </div>)
                    : (<div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <Spinner animation="border" variant="light" size="lg" />
                    </div>)
                }
            </div>
        </div>
      </div>
      </div>
    </main>
</>
  );
}

export default WalletTransection;