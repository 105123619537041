import React from 'react'
import AppFooter from '../../../components/AppFooter/AppFooter'
import downArrow from './../../TradingAssets/img/downarrowtable.svg'
import redArrow from './../../TradingAssets/img/redicon.svg'
import greenArrow from './../../TradingAssets/img/greenicon.svg'
import closeIcon from './../../TradingAssets/img/closeicon.svg'
import searchIcon from './../../TradingAssets/img/search.png'
import plusAdd from './../../TradingAssets/img/plus.svg'

import './../../TradingAssets/scss/trading.scss'




const TrangingMaxQuantityDetails = () => {
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage tradingPages">
            {/* start here */}

           <div class="row gap4 ">
           <div class="col-12">
              <div class="row gap-3">
              <div class="col "><div class="d-flex align-items-center dambHeading">BX UNIVERSE</div></div>
                <div class="col-12">
                  <div class="row g-3 align-items-center ">
                    <div className="col-lg-2 col-sm-6">
                      <div className="row">
                        <div className="col-12">
                          <label For="Tarde" className="tradeLabel text-white">LEVEL</label>
                        </div>
                        <div className="col-12">
                        <div class="dropdown">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Level
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100">
                            <li>
                              <div class="dropdown-item dropdownItem">
                              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                  <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                    <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                  </span>
                                  <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                               </div>
                                <div className="txt">
                                  Please enter 1 or More Character
                                </div>
                              </div>
                              </li>
                          </ul>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                      <div className="row">
                        <div className="col-12">
                          <label For="Tarde" className="tradeLabel text-white">MARKET</label>
                        </div>
                        <div className="col-12">
                        <div class="dropdown">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Market
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100">
                            <li>
                              <div class="dropdown-item dropdownItem">
                              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                  <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                    <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                  </span>
                                  <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                               </div>
                                <div className="txt">
                                  Please enter 1 or More Character
                                </div>
                              </div>
                              </li>
                          </ul>
                      </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                      <div className="row">
                        <div className="col-12">
                          <label For="Tarde" className="tradeLabel text-white">SCRIPT</label>
                        </div>
                        <div className="col-12">
                        <div class="dropdown">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Script
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100">
                            <li>
                              <div class="dropdown-item dropdownItem">
                              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                  <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                    <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                  </span>
                                  <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                               </div>
                                <div className="txt">
                                  Please enter 1 or More Character
                                </div>
                              </div>
                              </li>
                          </ul>
                      </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                      <div className="row">
                        <div className="col-12">
                          <label For="Tarde" className="tradeLabel text-white">POSITION</label>
                        </div>
                        <div className="col-12 ">
                        <input type="number" id="QTY" class="form-control inputlogin border-0 shadow-none" placeholder="30" name="QTY"></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                      <div className="row">
                        <div className="col-12">
                          <label For="Tarde" className="tradeLabel text-white">MAX ORDER</label>
                        </div>
                        <div className="col-12">
                        <div class="dropdown">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Market
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100">
                            <li>
                              <div class="dropdown-item dropdownItem">
                              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                  <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                    <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                  </span>
                                  <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                               </div>
                                <div className="txt">
                                  Please enter 1 or More Character
                                </div>
                              </div>
                              </li>
                          </ul>
                      </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 d-sm-none d-block">
                      <div className="row">
                        <div className="col-12">
                          <label For="Tarde" className="tradeLabel text-white">MARKET</label>
                        </div>
                        <div className="col-12">
                        <div class="dropdown">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Market
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100">
                            <li>
                              <div class="dropdown-item dropdownItem">
                              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                  <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                    <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                  </span>
                                  <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                               </div>
                                <div className="txt">
                                  Please enter 1 or More Character
                                </div>
                              </div>
                              </li>
                          </ul>
                      </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-6 col-auto d-sm-block d-none">
                    <div class="mt-sm-4 d-flex align-items-center justify-content-end ms-auto" >
                    <button class=" p-0 bg-transparent border-0 shadow-none d-flex align-items-center addBtnSection" type='sumbit'>
                      <span class="d-inline-flex align-items-center addBtn me-1">
                        <img src={plusAdd} class="img-fluid w-100 h-100 " alt="table accordion"/>
                      </span> ADD
                    </button>
                  </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 d-sm-block d-none">
                      <div className="row">
                        <div className="col-12">
                          <label For="Tarde" className="tradeLabel text-white">MARKET</label>
                        </div>
                        <div className="col-12">
                        <div class="dropdown">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Market
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100">
                            <li>
                              <div class="dropdown-item dropdownItem">
                              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                  <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                    <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                  </span>
                                  <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                               </div>
                                <div className="txt">
                                  Please enter 1 or More Character
                                </div>
                              </div>
                              </li>
                          </ul>
                      </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-6 col-auto d-sm-none d-block">
                    <div class="mt-sm-4 d-flex align-items-center justify-content-end ms-auto" >
                    <button class=" p-0 bg-transparent border-0 shadow-none d-flex align-items-center addBtnSection" type='sumbit'>
                      <span class="d-inline-flex align-items-center addBtn me-1">
                        <img src={plusAdd} class="img-fluid w-100 h-100 " alt="table accordion"/>
                      </span> ADD
                    </button>
                  </div>
                    </div>
                    <div class="col-lg-2 col-sm-6 col-auto">
                    <div class="mt-sm-4 d-flex align-items-center " >
                    <button class=" p-0 bg-transparent border-0 shadow-none d-flex align-items-center addBtnSection" type='sumbit'>
                      <span class="d-inline-flex align-items-center addBtn me-1">
                        <img src={plusAdd} class="img-fluid w-100 h-100 " alt="table accordion"/>
                      </span> FIND
                    </button>
                  </div>
                    </div>
                  </div>
                </div>
      
              </div>
           </div>
          
           </div>
           <div class="row mx-0">
           <div className="col-12 px-0 mb-0 dambleTable sectionInner tableCard tab-content tab-pane table-responsive">
             
             <table class="table align-middle text-white text-nowrap ">
             <thead>
               <tr>
                 <th >
                 <div className='tabletxt d-flex align-items-center'>
                 LEVEL
                 {/* <spna class="d-inline-flex align-items-center ms-1">
                  <div class="d-flex flex-column align-items-center ">
                     <div class="tableArrowIcon d-inline-flex align-items-center">
                     <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                     </div>
                     <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                     <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                     </div>
                  </div>
                 </spna> */}
                 </div>
                 </th>
                 <th >
                 <div className='tabletxt d-flex align-items-center'>
                 MARKET
                 {/* <spna class="d-inline-flex align-items-center ms-1">
                  <div class="d-flex flex-column align-items-center ">
                     <div class="tableArrowIcon d-inline-flex align-items-center">
                     <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                     </div>
                     <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                     <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                     </div>
                  </div>
                 </spna> */}
                 </div>
                 </th>
                 <th >
                 <div className='tabletxt d-flex align-items-center'>
                 SCRIPT
                 {/* <spna class="d-inline-flex align-items-center ms-1">
                  <div class="d-flex flex-column align-items-center ">
                     <div class="tableArrowIcon d-inline-flex align-items-center">
                     <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                     </div>
                     <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                     <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                     </div>
                  </div>
                 </spna> */}
                 </div>
                 </th>
                 <th >
                 <div className='tabletxt d-flex align-items-center'>
                 POSITION LIMIT
                 {/* <spna class="d-inline-flex align-items-center ms-1">
                  <div class="d-flex flex-column align-items-center ">
                     <div class="tableArrowIcon d-inline-flex align-items-center">
                     <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                     </div>
                     <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                     <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                     </div>
                  </div>
                 </spna> */}
                 </div>
                 </th>
                 <th >
                 <div className='tabletxt d-flex align-items-center'>
                 MAX ORDER
                 {/* <spna class="d-inline-flex align-items-center ms-1">
                  <div class="d-flex flex-column align-items-center ">
                     <div class="tableArrowIcon d-inline-flex align-items-center">
                     <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                     </div>
                     <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                     <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                     </div>
                  </div>
                 </spna> */}
                 </div>
                 </th>
               </tr>
             </thead>
             <tbody>
               <tr>
                 <td >
                   <div className=" d-flex align-items-center ">
                 xyz
                   </div>
                 </td>
             
         
                 <td >
                   <div className=" d-flex align-items-center ">
                   a b c
                   </div>
                 </td>
                 <td >
                   <div className=" d-flex align-items-center ">
                qwer
                   </div>
                 </td>
                 <td >
                   <div className=" d-flex align-items-center ">
                   a b c
                   </div>
                 </td>
                 <td >
                   <div className=" d-flex align-items-center ">
             5963
                   </div>
                 </td>

               </tr>
             </tbody>
             </table>
           </div>

           </div>




            {/* end here */}
          </div>
        </div>
        {/*  **** Footer section *** */}
        <AppFooter />
      </main>
    </>
  )
}

export default TrangingMaxQuantityDetails