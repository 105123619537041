import React, { useEffect } from "react"
import { useAuth } from "../../hooks/useAuth";
import DepositBanner from "./DepositBanner";
import { useLocation } from "react-router-dom";
import DambleUniverse from "./DambleUniverse";
import SportsCards from "./SportsCards";
import AppFooter from "../../components/AppFooter/AppFooter";
import LiveCasinoGames from "./LiveCasinoGames";
import CategoriesGamesComp from "./CategoriesGamesComp";
import { useAuthModal } from "../../hooks/useAuthModal";
import BetBanner from "./BetBanner";
import FantasyWalletCard from "./FantasyWalletCard";
import LiveEvents from "../../sports/sportsPages/SportsHome/LiveEvents";
import Events from "../../sports/sportsPages/SportsHome/Events";
import ProviderSlider from "../../components/providerSlider/ProviderSlider";
import LiveCasinoGamesTwo from "./LiveCasinoGamesTwo";

const Casino = ({ socket }) => {
  const { setAuthModalObject } = useAuthModal()
  const { token } = useAuth();
  const location = useLocation()
  useEffect(() => {
    console.log("location", location.state?.extractedStart)
    if (location.state?.extractedStart) {
      // setAuthModalObject(pre => ({
      //   isAuthOpen: true,
      //   selectedTab: AUTH_MODAL_TABS.SIGN_UP
      // }))
    } else {

    }
  }, [])

  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage">
            <BetBanner />
            {token ? <FantasyWalletCard /> : ''}
            {!token ?
              <DambleUniverse /> : ''
            }

            {token ?
              <DepositBanner />
              : ''}

            <CategoriesGamesComp />

            {token ?
              <>
                <LiveCasinoGames />
                {/* <LiveCasinoGamesTwo /> */}
                {/* <ProviderSlider/> */}
              </>
              : ''}

            {/* top-rated-games section start */}
            {/* table start */}
            {/* <DambleTable socket={socket} /> */}
            {/* <LiveEvents /> */}

            <div className="col-12" >

              <SportsCards />
              <LiveEvents hasPadding={true} />
              <Events hasPadding={true} />
              {/* <Highlight /> */}
            </div>
          </div>
        </div>
        {/*  **** Footer section *** */}
        <AppFooter />
      </main>
    </>
  );
};

export default Casino;
