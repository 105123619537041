import React from 'react'
import trophyImg from './../../fantasyAssets/img/trophyIcon.png'
import singleImg from './../../fantasyAssets/img/single.png'
import multipleImg from './../../fantasyAssets/img/multiple.png'
import guranteedImg from './../../fantasyAssets/img/guranteed.png'
import flexibleImg from './../../fantasyAssets/img/flexiable.png'
import { ROUTES_CONST } from '../../../constants/routeConstant'


const ContestCard = ({
  practice,
  win_amount,
  entryfee,
  maximum_user,
  joinedusers,
  winning_percentage,
  multi_entry,
  confirmed_challenge,
  handleJoin = () => { },
  handleLeader = () => { },
  myContest,
  params1,
  route1,
  isselected
}) => {
  return (
    <>
      <div className="col-12 mb-3 cursor-pointer"
        onClick={handleLeader}
      >
        <div className="row mx-0 contestCard">
          <div className="col-12">
            <div className="row align-items-center justify-content-between">
              <div className="winnerPrz col">₹ {win_amount}</div>
              <div className="col-auto">
                <div onClick={handleJoin} className="entryPrz">
                  {/* {myContest ? ("invite") : (practice) ? ('Practice') : (`₹ ${entryfee}`)} */}
                  {myContest ? (
                    'Invite'
                  ) : isselected? (
                    'Joined'
                  ) : entryfee===0 ? (
                    'Free'
                  ) : (
                    `₹ ${entryfee}`
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 mt-2 mb-1">
                <div className="progress spotProgress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                  <div className="progress-bar progress-bar-striped"
                    style={{ width: `${maximum_user == 0 ? 0 : joinedusers / maximum_user * 100}%` }}
                  ></div>
                </div>
              </div>
              <div className="col leftSpots text-danger">{joinedusers ? `${maximum_user - joinedusers} Spots Left` : "Be First To Join"} </div>
              {/* <div className="col-auto totalSpots">10 Spots Left</div> */}
            </div>
          </div>
          <div className="col-12 contestCardFooter mt-2 py-2">
            <div className="row align-items-center">
              <div className="col d-flex align-items-center">
                <div className="gurantyTxt me-2">
                  {
                    practice ? 'Practice' :
                      <>
                        <img src={trophyImg} alt="" />
                        {winning_percentage}%
                      </>
                  }
                </div>
                {
                  multi_entry ? (
                    <>
                      <div className="gurantyTxt">
                        <img src={multipleImg} alt="" />
                        Multi Entry
                      </div>
                    </>
                  ) : (
                    <div className="gurantyTxt">
                      <img src={singleImg} alt="" />
                      Single
                    </div>
                  )
                }
              </div>
              {
                confirmed_challenge ? (
                  <div className="col-auto gurantyTxt">
                    <img src={guranteedImg} alt="" />
                    Guaranteed
                  </div>
                ) : (
                  <></>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContestCard