import React, { useRef } from "react";
import closeIcon from "./../../TradingAssets/img/closeicon.svg";
import { useState } from "react";
import { postTradingAPI } from "../../TradingServices";
import { useEffect } from "react";
import { errorToaster } from "../../../utils/toaster";
import { useInterval } from "../../../hooks/useInterval";
// import isEqual from 'lodash/isEqual';

const WatchListItemComponent = ({ data, index, handleRedCross, showModal, setShowModal, setModalData }) => {
  const [apiTableData, setApiTableData] = useState({});
  const [isBussinessHour, setIsBusinessHour] = useState(true);
  const [blink, setBlink] = useState(false)
  const [previous, setPrevious] = useState({})

  const fetchData = async () => {
    try {
      const body = {
        action: "instrument-detail",
        symbol_instrument_token: data?.instrument_token,
      };
      const res = await postTradingAPI("instrument-detail.php", body);
      const result = await res.json();
      setPrevious(apiTableData)
      setApiTableData(result.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useInterval(() => {
    if (data?.instrument_token) {
      fetchData();
    }
  }, 3000)


  // // Call the fetchData function every 3 seconds
  // useEffect(() => {

  //   const interval = setInterval(() => {
  //     if (data?.instrument_token) {
  //       fetchData();
  //     }
  //   }, 3000);
  //   // }, 5000);
  //   return () => clearInterval(interval);
  // }, [data?.instrument_token]);

  useEffect(() => {
    const newTime = new Date();
    // Check if the current time is between 9am and 3.30pm
    const currentTime = newTime.getHours() >= 9 && (newTime.getHours() < 15 || (newTime.getHours() === 15 && newTime.getMinutes() <= 30));
    setIsBusinessHour(currentTime)
  }, [])

  useEffect(() => {
    setBlink(true);
    let timeout = setTimeout(() => {
      setBlink(false);
      // }, 2000);
    }, 300);

    return () => {
      if (previous?.bid_price !== apiTableData?.bid_price) {
        clearTimeout(timeout);
      }
    }
  }, [apiTableData]);

  // Helper hook to get the previous value of a prop or state
  // const usePrevious = (value) => {
  //   const ref = useRef();
  //   useEffect(() => {
  //     ref.current = value;
  //   });
  //   return ref.current;
  // };

  // Keep track of the previous data within the component function
  // const previousData = usePrevious(apiTableData);

  // Function to compare old and new data for a specific key
  // const isDataChangedForKey = (rowIndex, key) => {
  //   console.log('key', apiTableData[rowIndex]?.[key] !== previousData?.[rowIndex]?.[key])
  //   // Compare with the previous data
  //   return apiTableData[rowIndex]?.[key] !== previousData?.[rowIndex]?.[key];
  // };
    // handle delete function in mobile view
    const handleDelete = (e, data)=> {
      e.stopPropagation()
      handleRedCross(data)
    }



  return (
    <div className="watchLisBody" key={data?.instrument_token}>
      <div className="watchtableTd">
        <div className="d-flex align-items-center ">{index + 1}</div>
      </div>
      <div className="watchtableTd">
        <div className="d-flex align-items-center cursor-pointer"
          onClick={() => {
            if (apiTableData?.ask_price < 1) {
              errorToaster('Ask price is zero')
            } else {
              setShowModal(true)
              setModalData({
                instToken: data?.instrument_token,
                trading_name: data?.trading_name,
                lot_size: data?.lot_size
              })
            }
          }
          }
        >{data?.trading_name}</div>
      </div>
      {/* <div className="watchtableTd">
    <div className="d-flex align-items-center ">{data?.tradingsymbol}</div>
  </div> */}
      <div className={`watchtableTd blinkTd ${blink && previous?.bid_price != apiTableData?.bid_price ? 'blink' : ''}`}
      // className={isDataChangedForKey(index, apiTableData?.bid_price) ? 'changed' : ''}
      >
        <div className="d-flex align-items-center ">{apiTableData?.bid_price ? apiTableData?.bid_price : '0.00'}</div>
      </div>
      <div className={`watchtableTd blinkTd ${blink && previous?.ask_price != apiTableData?.ask_price ? 'blink' : ''}`}>
        <div className="d-flex align-items-center ">{apiTableData?.ask_price ? apiTableData?.ask_price : '0.00'}</div>
      </div>
      <div className={`watchtableTd blinkTd ${blink && previous?.ltp_price != apiTableData?.ltp_price ? 'blink' : ''}`}>
        <div className="d-flex align-items-center ">{apiTableData?.ltp_price ? apiTableData?.ltp_price : '0.00'}</div>
      </div>
      <div className="watchtableTd">
        <div className="d-flex align-items-center ">{data?.exchnage}</div>
      </div>
      <div className="watchtableTd">
        <div className="d-flex align-items-center ">
          {
            apiTableData ?
              <img className="incDecImg" src={`assets/img/${apiTableData?.diff_val > 0 ? 'increment' : 'decrement'}.png`} alt="" />
              : ''
          }
          {apiTableData?.diff_val ? apiTableData?.diff_val : '0.00'}
        </div>
      </div>
      <div className="watchtableTd">
        <div className="d-flex align-items-center ">
          {apiTableData?.diff_val ? apiTableData?.diff_val : '0.00'}
        </div>
      </div>
      <div className="watchtableTd">
        <div className="d-flex align-items-center ">
          {apiTableData?.ohlc_high ? apiTableData?.ohlc_high : '0.00'}
        </div>
      </div>
      <div className="watchtableTd">
        <div className="d-flex align-items-center ">
          {apiTableData?.ohlc_low ? apiTableData?.ohlc_low : '0.00'}
        </div>
      </div>
      <div className="watchtableTd">
        <div className="d-flex align-items-center ">
          {apiTableData?.ohlc_open ? apiTableData?.ohlc_open : '0.00'}
        </div>
      </div>
      <div className="watchtableTd">
        <div className="d-flex align-items-center ">
          {apiTableData?.ohlc_close ? apiTableData?.ohlc_close : '0.00'}
        </div>
      </div>
      <div className="watchtableTd">
        <div
          className="d-flex align-items-center "
          onClick={() => handleRedCross(data)}
        >
          <img
            src={closeIcon}
            style={{ height: "15px", width: "15px" }}
            alt=""
          />
        </div>
      </div>

      <div className="watchListForMobile w-100 d-lg-none"
        onClick={() => {
          if (apiTableData?.ask_price < 1) {
            errorToaster('Ask price is zero')
          } else {
            setShowModal(true)
            setModalData({
              instToken: data?.instrument_token,
              trading_name: data?.trading_name,
              lot_size: data?.lot_size
            })
          }
        }
        }>
        <div className="upperRow row mx-0 g-2">
          <div className="col">
            <div className="d-flex align-items-center smallTxt">
              {
                apiTableData ?
                  <img className="incDecImg" src={`assets/img/${apiTableData?.diff_val > 0 ? 'increment' : 'decrement'}.png`} alt="" />
                  : ''
              }
              <span className="me-1">{apiTableData?.diff_val ? apiTableData?.diff_val : '0.00'}</span>
              <span>{apiTableData?.diff_val ? apiTableData?.diff_val : '0.00'} </span>
            </div>
          </div>
          <div className="col-auto smallTxt text-end d-flex align-items-center">
            LTP: {apiTableData?.ltp_price ? apiTableData?.ltp_price : '0.00'}
            <div className="removeBtn ms-2" onClick={(e) => handleDelete(e, data)}>
              <img
                src={closeIcon}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="middleRow row mx-0 g-2">
          <div className="col-6">
            <div className="tradingName">{data?.trading_name}</div>
            <div className="exchangeName">{data?.exchnage}</div>
          </div>
          <div className="col-6">
            <div className="row g-1">
              <div className="col-6">
                <div className="mobWatchBox">
                  <div className="bigTxt">{apiTableData?.bid_price ? apiTableData?.bid_price : '0.00'}</div>
                  <div className="smallTxt">H : {apiTableData?.ohlc_high ? apiTableData?.ohlc_high : '0.00'}</div>
                  {/* <div className="smallTxt">O : {apiTableData?.ohlc_open ? apiTableData?.ohlc_open : '0.00'}</div> */}
                </div>
              </div>
              <div className="col-6">
                <div className="mobWatchBox">
                  <div className="bigTxt">{apiTableData?.bid_price ? apiTableData?.bid_price : '0.00'}</div>
                  <div className="smallTxt">L : {apiTableData?.ohlc_low ? apiTableData?.ohlc_low : '0.00'}</div>
                  {/* <div className="smallTxt">C : {apiTableData?.ohlc_close ? apiTableData?.ohlc_close : '0.00'}</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
//  may be of any use in future
  // return (
  //   <tr key={data?.instrument_token}>
  //     <td>
  //       <div className="d-flex align-items-center ">{index + 1}</div>
  //     </td>
  //     <td>
  //       <div className="d-flex align-items-center cursor-pointer" onClick={() => {
  //         if (apiTableData?.ask_price < 1) {
  //           errorToaster('Ask price is zero')
  //         } else {
  //           setShowModal(true)
  //           setModalData({
  //             instToken: data?.instrument_token,
  //             trading_name: data?.trading_name,
  //             lot_size: data?.lot_size
  //           })
  //         }
  //       }
  //       }
  //       >{data?.trading_name}</div>
  //     </td>
  //     {/* <td>
  //       <div className="d-flex align-items-center ">{data?.tradingsymbol}</div>
  //     </td> */}
  //     <td className={`blinkTd ${blink && previous?.bid_price != apiTableData?.bid_price ? 'blink' : ''}`}
  //     // className={isDataChangedForKey(index, apiTableData?.bid_price) ? 'changed' : ''}
  //     >
  //       <div className="d-flex align-items-center ">{apiTableData?.bid_price ? apiTableData?.bid_price : '0.00'}</div>
  //     </td>
  //     <td className={`blinkTd ${blink && previous?.ask_price != apiTableData?.ask_price ? 'blink' : ''}`}>
  //       <div className="d-flex align-items-center ">{apiTableData?.ask_price ? apiTableData?.ask_price : '0.00'}</div>
  //     </td>
  //     <td className={`blinkTd ${blink && previous?.ltp_price != apiTableData?.ltp_price ? 'blink' : ''}`}>
  //       <div className="d-flex align-items-center ">{apiTableData?.ltp_price ? apiTableData?.ltp_price : '0.00'}</div>
  //     </td>
  //     <td>
  //       <div className="d-flex align-items-center ">{data?.exchnage}</div>
  //     </td>
  //     <td>
  //       <div className="d-flex align-items-center ">
  //         {
  //           apiTableData ?
  //             <img className="incDecImg" src={`assets/img/${apiTableData?.diff_val > 0 ? 'increment' : 'decrement'}.png`} alt="" />
  //             : ''
  //         }
  //         {apiTableData?.diff_val ? apiTableData?.diff_val : '0.00'}
  //       </div>
  //     </td>
  //     <td>
  //       <div className="d-flex align-items-center ">
  //         {apiTableData?.diff_val ? apiTableData?.diff_val : '0.00'}
  //       </div>
  //     </td>
  //     <td>
  //       <div className="d-flex align-items-center ">
  //         {apiTableData?.ohlc_high ? apiTableData?.ohlc_high : '0.00'}
  //       </div>
  //     </td>
  //     <td>
  //       <div className="d-flex align-items-center ">
  //         {apiTableData?.ohlc_low ? apiTableData?.ohlc_low : '0.00'}
  //       </div>
  //     </td>
  //     <td>
  //       <div className="d-flex align-items-center ">
  //         {apiTableData?.ohlc_open ? apiTableData?.ohlc_open : '0.00'}
  //       </div>
  //     </td>
  //     <td>
  //       <div className="d-flex align-items-center ">
  //         {apiTableData?.ohlc_close ? apiTableData?.ohlc_close : '0.00'}
  //       </div>
  //     </td>
  //     <td>
  //       <div
  //         className="d-flex align-items-center "
  //         onClick={() => handleRedCross(data)}
  //       >
  //         <img
  //           src={closeIcon}
  //           style={{ height: "15px", width: "15px" }}
  //           alt=""
  //         />
  //       </div>
  //     </td>
  //   </tr>
  // );


};

export default WatchListItemComponent;
