import React, { useEffect, useRef, useState } from 'react'
import { sportSocket } from '../../sportsServices/sportsSocket'
import { useDispatch, useSelector } from 'react-redux'
import { adminGetAPIAuth } from '../../../Admin/AdminServices'
import BetSlipOffcanvas from '../../sportsComponents/betSlipOffcanvas/BetSlipOffcanvas'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useAuthModal } from '../../../hooks/useAuthModal';
import { getAPIAuth } from '../../../service/apiInstance';
import { sportsGetAPIAuth, sportsPostAPIAuth } from '../../sportsServices/index';
import { errorToaster, succesToaster } from '../../../utils/toaster';
import { AUTH_MODAL_TABS } from '../../../constants';
import Spinner from "react-bootstrap/Spinner";
import moment from 'moment'

function Binary() {
    const [CatagoriesList, setCatagoriesList] = useState([]);
    console.log('CatagoriesListCatagoriesList', CatagoriesList);
    const [selectedCatlist, setSelectedCatList] = useState('');
    const [selectedFancyKey, setSelectedFancyKey] = useState('ALL');
    const [marketgamelist, setMarketGameList] = useState([]);
    console.log("marketgamelistsdafhwersfdesadc", marketgamelist);
    // console.log("marketgamelistmarketgamelistmarketgamelist", CatagoriesList);

    // console.log("marketgamelist", marketgamelist);
    const [selectedCat, setSelectedCat] = useState('4');
    const [seriesMatches, setSeriesMatches] = useState([]);
    const [sportstabingicon, setSportsTabingIcon] = useState('')

    const dispatch = useDispatch()
    const matchId = useSelector(state => state.matchId.gameid)
    const betGameList = useSelector(state => state?.betGameList);
    const getWalletData = useSelector(state => state?.getWalletData);
    // console.log("asdqwertsxcvb", getWalletData);
    const [stockcatgoriesid, setStockCatagoriesId] = useState();
    const [matchBetsData, setMatchBetsData] = useState();
    const [betAmount, setBetAmount] = useState(0);
    const [smallamout, setSmallAmout] = useState(0);
    const [betInputValue, setBetInputValue] = useState();
    const [selectionId, setSelectionId] = useState('');

    const [profit, setProfit] = useState(0);
    const [sectionName, setSectionName] = useState('');
    const [relatedMatches, setRelatedMatches] = useState([]);
    const [relatedMatchLoader, setRelatedMatchLoader] = useState(true)
    const id = useParams()?.matchId
    const [eventId, marketId] = ("|+|")

    const [indMarketId, setIndMarketId] = useState("")
    const { token } = useAuth()
    const { authModalObject, setAuthModalObject } = useAuthModal()
    const [isApplyingBet, setIsApplyingBet] = useState(false)
    const [isBetOpen, setIsBetOpen] = useState(false)
    const [oddsType, setOddsType] = useState('')
    const [bookmakerComm, setBookmakerComm] = useState([]);
    const [bookmakerToss, setBookmakerToss] = useState([]);
    const [bookmakerTied, setBookmakerTied] = useState([]);
    const [showBet, setShowBet] = useState(false)
    const [openBets, setOpenBets] = useState([])
    const [betLoader, setBetLoader] = useState(false)
    const [itemIndex, setItemIndex] = useState(5)
    const [parentIndex, setParentIndex] = useState(5)
    const [arrType, setArrType] = useState('')
    const [secType, setSecType] = useState('')
    const [fancyKeys, setFancyKeys] = useState([]);
    // console.log("fancyKeys", fancyKeys);
    const [fancyData, setFancyData] = useState([])
    // console.log("fancyData", fancyData);
    const [blink, setBlink] = useState(false)
    const [previous, setPrevious] = useState({});
    // console.log("prevpreviousious", previous[0].no);
    const [previousOverUnderFootball, setpreviousOverUnderFootball] = useState([]);
    const [matchOddsFootBallTennis, setMatchOddsFootBallTennis] = useState({});
    const [overUnderDataFootball, setOverUnderDataFootball] = useState([]);
    const [marketstockCategoryId, setMarketStockCatagoriesId] = useState("");
    const [yesnovalue, setYesNOValue] = useState("");
    console.log("yesnovalue", yesnovalue);
    const [yesnosmallvalue, setYesNOSmallValue] = useState("");
    const [typemarke, setTypeMarket] = useState("");
    const [betType, setBetType] = useState('');
    const [marketName, setMarketName] = useState("");
    const [marketStockName, setMarketStockName] = useState('');
    const [isSeriesLoading, setIsSeriesLoading] = useState(true);
    const [yesnovlaue, setYesNoVaue] = useState("");
    const [filterdata, setFilterDeta] = useState("");



    const currentDate = moment();

    // Format the date in "DAY MONTH YEAR" format
    const formattedDate = currentDate.format('YYYY-MM-DD HH:mm');

    // console.log("Formatted Date:", formattedDate);





    // stockCategoryId, type, betType, yesNoValue, yesNoSmallValue

    // console.log("stockcatgoriesid", stockcatgoriesid);
    // console.log("betGameListbetGameListbetGameList", betGameList);

    const [activeGame, setActiveGame] = useState();

    //  console.log("sportstabingicon", selectedCat);

    const dummyOdds = [
        {
            price: 0,
            size: 0,
            level: 0
        },
        {
            price: 0,
            size: 0,
            level: 0
        },
        {
            price: 0,
            size: 0,
            level: 0
        }
    ]

    const getBetDelay = async () => {
        if (token) {
            try {
                const res = await getAPIAuth(`bet/getBetDelay`, token)
                // console.log('ressssssssssssssssss', res)
            } catch (error) {

            }
        }
    }

    useEffect(() => {
        getBetDelay()
    }, [])//eslint-disable-line
    // https://stream.1ex99.com/sportRadarScore?eventId=32887420


    useEffect(() => {
        if (betType === "back") {
            applyProfit(betInputValue);
        } else {
            applyLayProfit(betInputValue)
        }
        //eslint-disable-next-line
    }, [betAmount, betType])


    useEffect(() => {
        setBlink(true);
        let timeout = setTimeout(() => {
            setBlink(false);
            setPrevious(marketgamelist)
            // setpreviousOverUnderFootball(overUnderDataFootball);
        }, 300);

        return () => {
            // if (previous?.bid_price !== apiTableData?.bid_price) {
            clearTimeout(timeout);
            // }
        }
    }, [marketgamelist]);//eslint-disable-line

    const setBettingSystem = (marketId, stockCategoryId, type, betType, yesNoValue, yesNoSmallValue, marketname, stockName) => {

        console.log("csvxdzsfxgc", yesNoValue);

        setIndMarketId(marketId);
        setMarketStockCatagoriesId(stockCategoryId);
        setTypeMarket(type);
        setBetType(betType);
        setYesNOValue(yesNoValue);
        setYesNOSmallValue(yesNoSmallValue);
        setMarketName(marketname);
        setMarketStockName(stockName)
    }

    /** calculate the profit amount */
    const applyProfit = (inputValue) => {
        setProfit((inputValue * betAmount) - inputValue);
    }

    const applyLayProfit = (inputValue) => {
        setProfit(inputValue * (betAmount - 1))
    }
    /** configure the input value */
    const changeBetInputValue = (inputValue) => {
        setBetInputValue(inputValue);
        if (oddsType === "bookmakers" || oddsType === "fancy") {
            if (betType === "back" || betType === "yes") {
                setProfit((inputValue * betAmount) / 100);
            } else {
                setProfit((inputValue * betAmount) / 100)
            }
        } else {
            if (betType === "back") {
                applyProfit(inputValue);
            } else {
                applyLayProfit(inputValue)
            }
        }
    }

    const changeBetAmount = (value) => {
        setBetAmount((pre) => pre + value);
    };
    // get related matches

    const getRelatedMatches = async () => {
        setRelatedMatchLoader(true)
        try {
            const res = await sportsGetAPIAuth(
                `bet/getMatchList?seriesId=${matchBetsData?.seriesId}`
            )
            if (res?.data?.data?.success) {
                setRelatedMatches(res?.data?.data?.data);
            }
        } catch (error) {
        } finally {
            setRelatedMatchLoader(false);
        }
    };

    useEffect(() => {
        if (matchBetsData?.seriesId) {
            getRelatedMatches()
        }
        //eslint-disable-next-line
    }, [matchBetsData?.seriesId])
    /** bet apply  */
    const applyBet = async () => {
        if (!betInputValue) {
            errorToaster("Please add amount to stack")
            return
        }
        if (token) {
            setBetLoader(true)
            placigBet()
        } else {
            setAuthModalObject({
                isAuthOpen: true,
                selectedTab: AUTH_MODAL_TABS.LOG_IN
            })
        }
    }

    const getMyBets = async () => {

        try {
            const res = await getAPIAuth(`bet/getAllMyBinaryBetsDuplicate`, token);
            console.log('resresres', res)
            if (res?.data?.data?.success) {

                setFilterDeta(res?.data?.data?.data)

            }
        } catch (error) {
            setFilterDeta([])
        }
    }

    useEffect(() => {
        getMyBets()
    }, [])

    const returnExposerAmount = (id) => {
        console.log("zxcvqwerty", id);
        if (filterdata?.length > 0) {
            let total = 0;
            const data = filterdata?.filter((item) => item?.marketName === id)
            console.log("zxcvqwertydataaa", data);
            for (let i = 0; i < data?.length; i++) {
                if (data?.[i]?.betType === "back" || data?.[i]?.betType === "yes") {
                    total += data?.[i]?.amount * 2
                } else if (data?.[i]?.betType === "lay" || data?.[i]?.betType === "no") {
                    total -= data?.[i]?.amount
                }
            }
            if (total === 0) return

            else return (
                <span className={`binary-profit-loss backLayVal ms-2 ${total > 0 ? 'blueVal' : 'pinkVal'}`} style={{ fontSize: "9px" }}>{total?.toFixed(0)}</span>
            )
        }
    }

    // console.log('matchBetsData', matchBetsData)
    const placigBet = async () => {
        let updatedPrice = 0;
        try {

            const params = {

                stake: betInputValue,
                marketId: indMarketId,
                stockId: marketstockCategoryId,
                type: "stock",
                betType: betType,
                odds: Number(yesnovalue),
                yesNoValue: yesnovalue,
                yesNoSmallValue: yesnosmallvalue,
                marketName: marketName,
                StockName: marketStockName,
            }
            console.log("qwertyuioasdfghzxcvbn", params);
            const res = await sportsPostAPIAuth('bet/place-stock', params, token);
            if (res?.status) {
                setBetAmount(0);
                returnExposerAmount();
                getMyBets();
                setBetType('');
                setSelectionId("");
                setProfit(0);
                setBetInputValue("");
                setSectionName('');
                succesToaster(res?.data?.data?.message)
                // getOpenBets()
                getWalletData.getUserBal()

            } else {
                errorToaster(res.data.message)
            }


        } catch (error) {
            console.log('catch error', error)

            if (error.message === "Request failed with status code 401") return errorToaster("Unauthorized error")
            errorToaster(error?.response?.data?.data?.message)
        } finally {
            setBetLoader(false)
        }
    }

    useEffect(() => {
        const data = structuredClone(matchBetsData?.[0]?.bookmakersOdds);
        const bookmakerCommData = data?.filter(
            (item) => item?.mname === "Bookmaker"
        );
        const bookmakerTiedData = data?.filter(
            (item) => item?.mname === "Tied Match"
        );
        const bookmakerTossData = data?.filter((item) => item?.mname === "TOSS");
        setBookmakerComm(bookmakerCommData);
        setBookmakerToss(bookmakerTossData);
        setBookmakerTied(bookmakerTiedData)
    }, [matchBetsData?.[0]?.bookmakersOdds?.[0]]);


    useEffect(() => {
        const data = structuredClone(matchBetsData?.[0]?.matchfancies)

        const gTypeSet = new Set();

        data?.forEach(obj => {
            gTypeSet?.add(obj?.gtype)
        })

        const gTypeArray = [...gTypeSet]
        setFancyKeys(gTypeArray)
        // setSelectedFancyKey(gTypeArray?.[0])

    }, [matchBetsData?.[0]?.matchfancies])//eslint-disable-line

    useEffect(() => {
        if (selectedFancyKey) {
            return
        } else {
            setSelectedFancyKey(fancyKeys?.[0])

        }
    }, [fancyKeys, selectedFancyKey])

    useEffect(() => {
        const data = structuredClone(matchBetsData?.[0]?.matchfancies)
        const filteredData = data?.filter(item => item?.gtype?.toLowerCase() === selectedFancyKey?.toLowerCase())
        setFancyData(filteredData)
    }, [selectedFancyKey])//eslint-disable-line





    // in case there are less than 3 data in match odds
    const returnMatchOdds = (data) => {
        switch (data?.length) {
            case 3:
                return data;
            case 2:
                return [...data, dummyOdds?.[0]]
            case 1:
                return [...data, dummyOdds?.[0], dummyOdds?.[1]];
            case 0:
                return [...dummyOdds];
            default:
                return data;
        }
    }

    useEffect(() => {
        if (!!matchId) {
            setSelectedCat(matchId)
        }
    }, [matchId]);

    useEffect(() => {
        if (betGameList?.length) {
            const data = structuredClone(betGameList)
            let name = data?.filter(item => item?.gameId === matchId)
            setActiveGame(name?.[0]?.name)
        }
    }, [matchId, betGameList]);

    const GameCatagoriesList = async () => {
        try {
            const res = await adminGetAPIAuth(
                "market/getStockCategory"
            );

            console.log("qwerty", res);
            if (res?.data?.status === 200) {
                setCatagoriesList(res?.data?.data?.data);
            }
        } catch (error) {
            setCatagoriesList([]);
        }
    };
    useEffect(() => {
        GameCatagoriesList()
    }, [])

    let socketInterval = useRef(null);

    const getMarketGameList = async () => {
        let apiEndpoint = '';
        if (!selectedFancyKey || selectedFancyKey === "ALL") {
            apiEndpoint = `market/getMarketGame`;
        } else if (selectedFancyKey) {
            apiEndpoint = `market/getMarketGame?stockCategoryId=${stockcatgoriesid}`;
        }

        try {
            const res = await adminGetAPIAuth(apiEndpoint);

            console.log("qwerty", res);
            if (res?.data?.status === 200) {
                setMarketGameList(res?.data?.data?.data);
            } else {
                setMarketGameList([]);
            }
        } catch (error) {
            console.log("setMarketGameList Error", error);
            setMarketGameList([]);

        } finally {
            setIsSeriesLoading(false);
        }
    };

    const isWithinBusinessHours = () => {
        // Get the current time
        const now = moment();

        // Define start and end times
        const startTime = moment().set({ hour: 9, minute: 30, second: 0, millisecond: 0 });
        const endTime = moment().set({ hour: 15, minute: 30, second: 0, millisecond: 0 });

        // Check if the current time is within the range
        return now.isBetween(startTime, endTime, 'minute', '[]');
    };

    console.log("checdxckgsshcfghxfhvch", isWithinBusinessHours());

    useEffect(() => {
        getMarketGameList();
        if (isWithinBusinessHours()) {
            console.log('The condition is true');
            socketInterval.current = setInterval(getMarketGameList, 2000);
        } else {
            console.log('The condition is false');
        }

        return () => {
            clearInterval(socketInterval.current);
        };
    }, [stockcatgoriesid]);

    return (
        <>
            <div className="row">


                <div className="col-lg-8">

                    {
                        CatagoriesList?.length ?
                            <>
                                <div className="main-wapper-tabing-catagories d-flex">
                                    <div className="eventPillsWrapper mb-0">
                                        <div className="eventPills d-flex align-items-center flex-nowrap" >
                                            <div onClick={() => setSelectedFancyKey("ALL")} className={`eventPill rounded-pill me-2 text-nowrap text-capitalize ${selectedFancyKey === "ALL" ? 'active' : ''}`}>
                                                ALL
                                            </div>
                                        </div>
                                    </div>
                                    <div className="eventPillsWrapper mb-0">
                                        <div className="eventPills d-flex align-items-center flex-nowrap">
                                            {
                                                CatagoriesList?.map((item) => (
                                                    <div onClick={() => {
                                                        setSelectedFancyKey(item?.name);
                                                        setStockCatagoriesId(item?._id)

                                                    }} className={`eventPill rounded-pill me-2 text-nowrap text-capitalize ${selectedFancyKey === item?.name ? 'active' : ''}`}>
                                                        {item?.name}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="main-wrapper-tabing-categories d-flex">
                                            <div className="eventPillsWrapper mb-0">
                                                <div className="eventPills d-flex align-items-center flex-nowrap">
                                                    <div
                                                        onClick={() => setSelectedFancyKey("ALL")}
                                                        data={`eventPill rounded-pill me-2 text-nowrap text-capitalize ${selectedFancyKey === "ALL" ? 'active' : ''}`}
                                                    >
                                                        ALL
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="eventPillsWrapper mb-0">
                                                <div className="eventPills d-flex align-items-center flex-nowrap">
                                                    {
                                                        CatagoriesList?.map((item) => (
                                                            <div
                                                                key={item?._id}
                                                                onClick={() => {
                                                                    setSelectedFancyKey(item?.name);
                                                                    setStockCatagoriesId(item?._id)
                                                                }}
                                                                className={`eventPill rounded-pill me-2 text-nowrap text-capitalize ${selectedFancyKey === item?.name ? 'active' : ''}`}
                                                            >
                                                                {item?.name}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div> */}

                            </>
                            : ''
                    }

                    {/* check */}
                    <div className="matchBettingSection">
                        <div className="matchTextSection">
                            <div className="matchTxtSecTitle text-capitalize">{selectedFancyKey}</div>
                        </div>
                    </div>
                    <div className="d-flex fancyHalfContainerWrapper">
                        <div className="matchOddsDetailsSection d-flex align-items-center justify-content-between prDiv fancyHalfContainer">
                            <div className="matchesBetLayFirst flex-fill">Market</div>
                            <div className="d-flex" style={{ gap: 2 }}>
                                <div className="matchesLaySection text-uppercase d-flex align-items-center justify-content-center shorttt">
                                    <span>
                                        {
                                            selectedFancyKey === "oddeven" ? 'ODD' :
                                                selectedFancyKey === "meter" ? 'NO' :
                                                    selectedFancyKey === "fancy1" ? 'LAY' :
                                                        selectedFancyKey === "session" ? 'NO' :
                                                            selectedFancyKey === "khado" ? 'LAY' : 'NO'
                                        }
                                    </span>
                                </div>
                                <div className="matchesBackSection d-flex align-items-center justify-content-center shorttt">
                                    <span>
                                        {
                                            selectedFancyKey === "oddeven" ? 'EVEN' :
                                                selectedFancyKey === "meter" ? 'YES' :
                                                    selectedFancyKey === "fancy1" ? 'BACK' :
                                                        selectedFancyKey === "session" ? 'YES' :
                                                            selectedFancyKey === "khado" ? 'BACK' : 'YES'
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="matchOddsDetailsSection d-none d-lg-flex align-items-center justify-content-between fancyHalfContainer">
                            <div className="matchesBetLayFirst flex-fill">Market</div>
                            <div className="d-flex" style={{ gap: 2 }}>
                                <div className="matchesLaySection text-uppercase d-flex align-items-center justify-content-center shorttt">
                                    <span>
                                        {
                                            selectedFancyKey === "oddeven" ? 'ODD' :
                                                selectedFancyKey === "meter" ? 'NO' :
                                                    selectedFancyKey === "fancy1" ? 'LAY' :
                                                        selectedFancyKey === "session" ? 'NO' :
                                                            selectedFancyKey === "khado" ? 'LAY' : 'NO'
                                        }
                                    </span>
                                </div>
                                <div className="matchesBackSection d-flex align-items-center justify-content-center shorttt">
                                    <span>
                                        {
                                            selectedFancyKey === "oddeven" ? 'EVEN' :
                                                selectedFancyKey === "meter" ? 'YES' :
                                                    selectedFancyKey === "fancy1" ? 'BACK' :
                                                        selectedFancyKey === "session" ? 'YES' :
                                                            selectedFancyKey === "khado" ? 'BACK' : 'YES'
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* check */}
                    <div className="matchesBetLaySection fancyHalfContainerWrapper">
                        {isSeriesLoading ? (
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        ) : (

                            <>

                                {
                                    marketgamelist?.length < 1 ? <>No market Data</> : (
                                        <>
                                            {marketgamelist?.map((fancyData, pIndex) => {
                                                const isDisabled = formattedDate <= fancyData?.from
                                                console.log("fdsfadsygsyd", { FANCE: fancyData });

                                                if (pIndex === 0) { console.log('qwertfancyData', formattedDate <= fancyData?.from, { formattedDate, data: fancyData?.from, isDisabled }); }
                                                return (
                                                    <div className="matchesBetLayContainer fancyContainer fancyHalfContainer stockmanage" key={fancyData?.nation}>
                                                        <div className="matchesBetLayFirst flex-fill">
                                                            {fancyData?.name?.toLowerCase()}
                                                            {/* {returnExposerAmount(fancyData?.name)} */}
                                                            {formattedDate <= fancyData?.from ? "" : returnExposerAmount(fancyData?.name)}
                                                            {/* {returnExposerAmount(fancyData?.SelectionId)} */}
                                                        </div>
                                                        {/* <div
                                                className={`matchesBetLaySecond ${fancyData.status !== 'active' ? "suspend" : ""
                                                    }`}
                                                > */}
                                                        <div
                                                            className={`matchesBetLaySecond ${fancyData.status !== 'active' ? "active" : ""
                                                                }`}
                                                        >
                                                            <div className="suspendDiv d-none">{fancyData?.status}</div>

                                                            <div className="main-cat">

                                                                <div
                                                                    className={`matchesLaySection ${formattedDate <= fancyData?.from ? "disabled" : " "}
${blink && previous?.length && (previous?.[pIndex])?.no !== fancyData?.no ? 'blink' : ''} `}

                                                                    // marketId, stockCategoryId, type, betType, yesNoValue, yesNoSmallValue
                                                                    onClick={() => {
                                                                        setBettingSystem(
                                                                            fancyData?._id,
                                                                            fancyData?.stockId,
                                                                            "no",
                                                                            "lay",
                                                                            fancyData?.no,
                                                                            fancyData?.noSmall,
                                                                            fancyData?.name,
                                                                            fancyData?.stockName


                                                                        )

                                                                        setSecType('fancySec')
                                                                        setYesNoVaue(fancyData?.no)
                                                                        setArrType('availableToLay')
                                                                        setParentIndex(pIndex)
                                                                        setShowBet(true)
                                                                    }
                                                                    }
                                                                >
                                                                    <span> {fancyData?.no.toFixed(2)} </span>
                                                                    <span> {fancyData?.noSmall} </span>
                                                                </div>

                                                            </div>

                                                            <div className="main-cat">
                                                                <div
                                                                    className={`matchesBackSection    ${formattedDate <= fancyData?.from ? "disabled" : " "}
${blink && previous?.length && (previous?.[pIndex])?.no !== fancyData?.no ? 'blink' : ''} `}
                                                                    onClick={() => {
                                                                        setBettingSystem(
                                                                            fancyData?._id,
                                                                            fancyData?.stockId,
                                                                            "yes",
                                                                            "back",
                                                                            fancyData?.yes,
                                                                            fancyData?.yesSmall,
                                                                            fancyData?.name,
                                                                            fancyData?.stockName
                                                                        )
                                                                        setYesNoVaue(fancyData?.no)
                                                                        setSecType('fancySec')
                                                                        setArrType('availableToBack')
                                                                        setParentIndex(pIndex)
                                                                        setShowBet(true)
                                                                    }
                                                                    }
                                                                >

                                                                    <span> {fancyData?.yes.toFixed(2)} </span>
                                                                    <span> {fancyData?.yesSmall} </span>
                                                                </div>


                                                            </div>
                                                        </div>

                                                    </div>
                                                );
                                            })}
                                        </>
                                    )
                                }


                            </>
                        )
                        }


                    </div>


                </div>
                <div className={`col-lg-4 mt-4 mt-lg-0 betSlipOffcanvasParent ${showBet ? "show" : ""}`}>

                    <BetSlipOffcanvas
                        profit={betInputValue} // profit
                        betInputValue={betInputValue} //  amout stack value 
                        changeBetInputValue={changeBetInputValue} // change value
                        applyBet={applyBet} // apply bets
                        betAmount={yesnovalue}
                        // betAmount={2.00} // ooddds
                        setBetAmount={setYesNOValue} // increment decrement  
                        isApplyingBet={isApplyingBet}  // loader value 
                        setIsApplyingBet={setIsApplyingBet} // set loader value                                    
                        betType={betType}
                        oddsType={oddsType}
                        setShowBet={setShowBet}
                    />

                </div>
            </div>

        </>
    )
}

export default Binary