import React from 'react'
import './style.scss'

const ImageModal = ({imgUrl, setImgUrl, showImgModal, setShowImgModal}) => {
  return (
    
    <>
    {
      showImgModal ? 
      <>
      <div className='imageModal vh-100 vw-100 p-2'>
        <div className="closeBtn" onClick={()=>{
          setShowImgModal(false)
          setImgUrl('')
        }}>
          <img src="assets/img/sidebar/close.png" alt="" />
        </div>
        <div className="imageModalInner">
          <img src={imgUrl} alt="" />
        </div>
      </div> 
      </>
      : ''
    }
    
    </>
  )
}

export default ImageModal