import React from 'react'
import AppFooter from '../../../components/AppFooter/AppFooter'
import './../../TradingAssets/scss/trad.scss'
import searchIcon from './../../TradingAssets/img/search.png'
import tabletop from './../../TradingAssets/img/tabletop.svg'
import tabledown from './../../TradingAssets/img/tabledown.svg'
import paginationArrow from './../../TradingAssets/img/peginationArrow.png'
import paginationarrowright from './../../TradingAssets/img/paginationarrowright.png'




const TradingTrades = () => {
  return (
    <>
          <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage tradingPages">
            {/* start here */}

            <div className="row mb-lg-4 mb-3">
              <div className="col-12">
                <div className="row align-items-center">
                  <div className="col-xxl-2">
                    <div className="row">
                      <div className="col-xxl-12 col-6">
                      <div class="new">
                        <form>
                          <div class="form-group">
                            <input type="checkbox" id="pending"/>
                            <label for="pending" class="text-white checklabel">PENDING ORDERS</label>
                          </div>
                          
                        </form>
                      </div>
                      </div>
                      <div className="col-xxl-12 col-6">
                      <div class="new">
                        <form>
                        <div class="form-group">
                            <input type="checkbox" id="executed"/>
                            <label for="executed" class="text-white checklabel">EXECUTED ORDERS</label>
                          </div>
                        </form>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-12">
                    <div className="row">
                      <div className="col-12">
                        <label For="Tarde" className="tradeLabel text-white">TRADE AFTER</label>
                      </div>
                      <div className="col-12">
                        <input type="datetime-local" class="dateTimeInput"/>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-12">
                    <div className="row">
                      <div className="col-12">
                        <label For="Tarde" className="tradeLabel text-white">TRADE BEFORE</label>
                      </div>
                      <div className="col-12">
                        <input type="datetime-local" class="dateTimeInput"/>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-12">
                    <div className="row">
                      <div className="col-12">
                        <label For="Tarde" className="tradeLabel text-white">MARKET</label>
                      </div>
                      <div className="col-12">
                      <div class="dropdown">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Market
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100">
                            <li>
                              <div class="dropdown-item dropdownItem">
                              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                  <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                    <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                  </span>
                                  <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                               </div>
                                <div className="txt">
                                  Please enter 1 or More Character
                                </div>
                              </div>
                              </li>
                          </ul>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-12">
                    <div className="row">
                      <div className="col-12">
                        <label For="Tarde" className="tradeLabel text-white">SCRIPT</label>
                      </div>
                      <div className="col-12">
                      <div class="dropdown">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Script
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100">
                            <li>
                              <div class="dropdown-item dropdownItem">
                              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                  <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                </span>
                                <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                                 </div>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Action</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Another action</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Something else</a></li>
                              </div>
                              </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-12">
                    <div className="row">
                      <div className="col-12">
                        <label For="Tarde" className="tradeLabel text-white">BROKER</label>
                      </div>
                      <div className="col-12">
                      <div class="dropdown">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Broker
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100">
                            <li>
                              <div class="dropdown-item dropdownItem">
                              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                  <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                    <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                  </span>
                                  <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                               </div>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Action</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Another action</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Something else</a></li>
                              </div>
                              </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-12 mt4">
                    <div className="row">
                      <div className="col-12">
                        <label For="Tarde" className="tradeLabel text-white">MASTER</label>
                      </div>
                      <div className="col-12">
                      <div class="dropdown">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Master
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100">
                            <li>
                              <div class="dropdown-item dropdownItem">
                              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                  <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                    <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                  </span>
                                  <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                               </div>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Action</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Another action</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Something else</a></li>
                              </div>
                              </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-12 mt-lg-3">
                    <div className="row">
                      <div className="col-12">
                        <label For="Tarde" className="tradeLabel text-white">CLIENT</label>
                      </div>
                      <div className="col-12">
                      <div class="dropdown">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Client
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100">
                            <li>
                              <div class="dropdown-item dropdownItem">
                              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                  <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                </span>
                                <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                               </div>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Action</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Another action</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Something else</a></li>
                              </div>
                              </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-8 col-12 mt-lg-3">
                    <div className="row">
                      <div className="col-12">
                        <label For="Tarde" className="tradeLabel text-white">ORDER TYPE</label>
                      </div>
                      <div className="col-12">
                      <div class="dropdown">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Order Type
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100">
                            <li>
                              <div class="dropdown-item dropdownItem">
                              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                                  <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                                    <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                                  </span>
                                  <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
                                 </div>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Action</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Another action</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">Something else</a></li>
                              </div>
                              </li>
                          </ul>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-6 mt-lg-5 mt-3">
                    <div className="btnClass">
                      <a href="javascript:;" class="sumbitBtn">FIND ORDERS</a>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-6 mt-lg-5 mt-3">
                  <div className="btnClass">
                      <a href="javascript:;" class="sumbitBtn redBtn">CLEAR FILTER</a>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-12 mt-lg-5 mt-3">
                  <div className="btnClass">
                      <a href="javascript:;" class="sumbitBtn">TRADE EXPORT</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="row justify-content-sm-between justify-content-center text-sm-start text-center align-items-center gap-sm-0 gap-2">
              <div className="col-sm-auto col">
                <div className="row">
                  <div className="col-sm-auto col px-1">
                    <div className="txt">
                      SHOW
                    </div>
                  </div>
                  <div className="col px-1">
                    <div className="row">
                      <div className="col-12">
                      </div>
                      <div className="col-12">
                      <div class="dropdown w-100">
                          <button class="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            10
                          </button>
                          <ul class="dropdown-menu dropdownMenu w-100 entryMenu">
                            <li>
                              <div class="dropdown-item dropdownItem">
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">20</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">50</a></li>
                                <li><a class="dropdown-item dropdownItem colorChange" href="#">80</a></li>
                              </div>
                              </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col px-1">
                    <div className="txt">
                      Entries
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 ">
              <div class="input-group w-100 searchDrop rounded-2 inputGroup">
                <span class="input-group-text  shadow-none border-0 bg-transparent searchIcon" id="basic-addon2">
                  <img src={searchIcon} class="img-fluid w-100 h-100"  alt="table accordion"/>
                </span>
                <input type="search" class="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none" placeholder="Search" aria-label="search" aria-describedby="basic-addon2"/>
              </div>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col-12 px-0 dambleTable sectionInner tableCard tab-content tab-pane table-responsive mb-0">
                <table class="table align-middle text-white text-nowrap mb0">
                <thead>
                  <tr>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                      D
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    Time
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    Client
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    Market
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    Script
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    B/S
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    Order Type
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    Lot
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    Qty
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    Order Price
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    Status
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    User IP
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    O. Time
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    Modify
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                    <th >
                    <div className='tabletxt d-flex align-items-center'>
                    Cancel
                    <spna class="d-inline-flex align-items-center ms-1">
                     <div class="d-flex flex-column align-items-center ">
                        <div class="tableArrowIcon d-inline-flex align-items-center">
                        <img src={tabletop} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                        <div class="tableArrowIcon dnone d-inline-flex align-items-center">
                        <img src={tabledown} class="img-fluid w-100 h-100 " alt="table accordion"/>
                        </div>
                     </div>
                    </spna>
                    </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td >
                      <div className="d-flex align-items-center ">
                      a b c
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                    123
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                      ---
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                     xxx
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                    xyz
                      </div>
                    </td>
                
            
                    <td >
                      <div className=" d-flex align-items-center ">
                      a b c
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                   qwer
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                      a b c
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                5963
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                      a b d
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                      a b c
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                      a b c
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                    28852
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                      a b c
                      </div>
                    </td>
                    <td >
                      <div className=" d-flex align-items-center ">
                     ---
                      </div>
                    </td>

                  </tr>
                </tbody>
                </table>
              </div>
              <div class="col-12 mt-2">
                <div class="peginationTable d-flex align-items-center gap-2 justify-content-end">
                  <a href='javascript:;' class="arrowpagination d-inline-flex align-items-center justify-content-center">
                  <img src={paginationArrow} class="img-fluid w-100 h-100 " alt="table accordion"/>
                  </a>
                  <a href='javascript:;' class="arrowpagination d-inline-flex align-items-center justify-content-center">1</a>
                  <a href='javascript:;' class="arrowpagination d-inline-flex align-items-center justify-content-center">2</a>
                  <a href='javascript:;' class="arrowpagination d-inline-flex align-items-center justify-content-center">3</a>
                  <a href='javascript:;' class="arrowpagination d-inline-flex align-items-center justify-content-center">
                  <img src={paginationarrowright} class="img-fluid w-100 h-100 " alt="table accordion"/>
                  </a>
                </div>
              </div>
            </div>
            {/* end here */}
          </div>
        </div>
        {/*  **** Footer section *** */}
        <AppFooter />
      </main>
    </>
  )
}

export default TradingTrades