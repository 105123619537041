import React, { useEffect, useRef, memo } from 'react';

const GraphModal = ({showGraph, setShowGraph}) => {
  const container = useRef();
  useEffect(  () => {
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      {
        "autosize": true,
        "symbol": "NASDAQ:AAPL",
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "dark",
        "style": "1",
        "locale": "in",
        "enable_publishing": false,
        "allow_symbol_change": true,
        "support_host": "https://www.tradingview.com"
      }`;
    container.current.appendChild(script);
  },
  []
);
  return (
    <>
    <div class={`modal graphModal fade ${showGraph ? 'show d-block' : ''} `} style={{zIndex: 9999}} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
              <div className="row mx-0 align-items-center w-100 justify-content-between">
                <div className="col modalTitle">Graph</div>
                <div className="col-auto modalClose" onClick={()=> setShowGraph(false)}>
                  <img src="assets/img/sidebar/close.png" alt="" />
                </div>
              </div>
          </div>
          <div class="modal-body p-0" style={{height: 500}}>
          <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
      <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
      {/* <div className="tradingview-widget-copyright"><a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div> */}
    </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default memo(GraphModal)