import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { adminPostAPIAuth } from "../../../AdminServices";
import { errorToaster, succesToaster } from "../../../../utils/toaster";

const UserDetailsForm = ({ setShow, data, updateData }) => {

  console.log('data', data)
  const [formValues, setFormValues] = useState({
    name: data.name,
    username: data.username,
    role: data.role,
    share: data?.share,
    myShare: data?.myShare,
    creditReference: data.totalPoints,
    notes: "",
    maxProfit: data.maxProfit,
    maxProfitPerDay: data.maxProfitPerDay,
    maxLoss: data.maxLoss,
    maxLossPerDay: data.maxLossPerDay,
    stopProfit: data.stop_profit,
    stopLoss: data.stop_loss,
    cricket: data.isCricket,
    stock: data.isStock,
    minBet: data?.minBet,
    maxBet: data?.maxBet
  });

  const handleChange = (e) => {
    const nameArray = [
      "currentPoints",
      "toCurrentPoints",
      "points",
      "creditReference",
      "maxProfit",
      "maxProfitPerDay",
      "maxLoss",
      "maxLossPerDay",
      "stopProfit",
      "stopLoss",
      "minBet",
      "maxBet"
    ];

    if (e.target.name === "cricket" || e.target.name === "stock") {
      setFormValues((prev) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    } else if (nameArray.includes(e.target.name) && isNaN(Number(e.target.value))) {
      setFormValues((prev) => ({
        ...prev,
        [e.target.name]: formValues[e.target.name],
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleEditUserDetails = async(e) => {
    e.preventDefault();
    try {
      const body = {
        isCricket : formValues.cricket,
        isStock : formValues.stock,
        maxProfit : Number(formValues.maxProfit),
        maxProfitPerDay : Number(formValues.maxProfitPerDay),
        maxLoss : Number(formValues.maxLoss),
        maxLossPerDay : Number(formValues.maxLossPerDay),
        stop_profit : Number(formValues.stopProfit),
        stop_loss : Number(formValues.stopLoss),
        minBet: Number(formValues.minBet),
        maxBet: Number(formValues.maxBet)
      }
      const res = await adminPostAPIAuth(`user/editUserDetails/${data._id}`, body)
      if (res?.data?.data?.data) {
        succesToaster(res?.data?.data?.message)
        updateData()
      }
      console.log('res---', res)
      setShow(false);
    } catch (error) {
      errorToaster(error.message)
    }
  };

  return (
    <Form>
      <Container>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="name">Name</Form.Label>
              <Form.Control
                type="text"
                id="name"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="username">Username</Form.Label>
              <Form.Control
                type="text"
                id="username"
                name="username"
                value={formValues.username}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="role">User Role</Form.Label>
              <Form.Control
                type="text"
                id="role"
                name="role"
                value={formValues.role}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="share">Share</Form.Label>
              <Form.Control
                type="text"
                id="share"
                name="share"
                value={formValues.share}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="myShare">My Share</Form.Label>
              <Form.Control
                type="text"
                id="myShare"
                name="myShare"
                value={formValues.myShare}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="creditReference">
                Credit Reference
              </Form.Label>
              <Form.Control
                type="text"
                id="creditReference"
                name="creditReference"
                onChange={handleChange}
                value={formValues.creditReference}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Fantasy"
                id="cricket"
                name="cricket"
                onChange={handleChange}
                value={formValues.cricket}
                checked={formValues.cricket}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Stock"
                id="stock"
                name="stock"
                onChange={handleChange}
                value={formValues.stock}
                checked={formValues.stock}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="maxProfit">Max Profit (optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Max Profit"
                id="maxProfit"
                name="maxProfit"
                onChange={handleChange}
                value={formValues.maxProfit}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="maxProfitPerDay">
                Max Profit Per Day (optional)
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Max Profit Per Day"
                id="maxProfitPerDay"
                name="maxProfitPerDay"
                onChange={handleChange}
                value={formValues.maxProfitPerDay}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="maxLoss">Max Loss (optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Max Loss"
                id="maxLoss"
                name="maxLoss"
                onChange={handleChange}
                value={formValues.maxLoss}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="maxLossPerDay">
                Max Loss Per Day (optional)
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Max Loss Per Day"
                id="maxLossPerDay"
                name="maxLossPerDay"
                onChange={handleChange}
                value={formValues.maxLossPerDay}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="minBet">
                      Minimum Bet 
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Min Bet"
                      id="minBet"
                      name="minBet"
                      onChange={handleChange}
                      value={formValues.minBet}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="maxBet">
                      Maximum Bet
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Maximum Bet"
                      id="maxBet"
                      name="maxBet"
                      onChange={handleChange}
                      value={formValues.maxBet}
                    />
                  </Form.Group>
                </Col>
              </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="stopProfit">
                Stop Profit
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Stop Profit"
                id="stopProfit"
                name="stopProfit"
                onChange={handleChange}
                value={formValues.stopProfit}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="stopLoss">
                Stop Loss
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Stop Loss"
                id="stopLoss"
                name="stopLoss"
                onChange={handleChange}
                value={formValues.stopLoss}
              />
            </Form.Group>
          </Col>
        </Row>


        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="notes">Notes</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Notes"
                id="notes"
                name="notes"
                onChange={handleChange}
                value={formValues.notes}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button
              type="button"
              variant="danger"
              onClick={(e) => setShow(false)}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="mx-2"
              onClick={handleEditUserDetails}
            >
              Edit
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default UserDetailsForm;
