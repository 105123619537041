import { connect } from 'socket.io-client';

// export const socket = connect("https://api.beta.damble.io");
// export const socket = connect("here comes socket url");
export const socket = {
  emit:()=>{
    //
  },
  on:()=>{

  }
};
