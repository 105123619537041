import { call, put, takeLatest } from "redux-saga/effects";
// import { getUser, getFavCurrency } from "./sagaServices";
import { reducerConst, sagaConst } from "../constants/storeConstants";

// function* fetchUser() {
//     const res = yield call(getUser)
//     yield put({ type: reducerConst.USER_DETAILS, payload: res?.data?.data ? res.data.data : {} })
// }

function* watchApp() {
    // yield takeLatest(sagaConst.FETCH_USER_DETAILS, fetchUser);
}
export default watchApp
