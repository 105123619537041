import React, { useState } from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { useEffect } from 'react';
import { getAPIAuth } from '../../service/apiInstance';
import DarkPagination from '../../components/darkPagination/DarkPagination';
import { useAuth } from '../../hooks/useAuth';
import { errorToaster } from '../../utils/toaster';

const filterBetsOptions = [
    {
        label: "All",
        value: "",
    },
    {
        label: "Open",
        value: "open",
    },
    {
        label: "won",
        value: "won",
    },
    {
        label: "lost",
        value: "lost",
    },
    {
        label: "settled ",
        value: "settled ",
    },
];


const DerbyBetListPage = () => {
    const [selectedGame, setSelectedGame] = useState('All')
    const [selectedBet, setSelectedBet] = useState('')
    const [data, setData] = useState([]);
    console.log("dgtyghcvdyshdata", data);
    const [gameId, setGameId] = useState('')
    const [status, setStatus] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [games, setGames] = useState([])
    const [betSummary, setBetSummary] = useState({});
    // console.log("betdeailtsss", betSummary);
    const [totalPages, setTotalPages] = useState(null);
    console.log("totalPagestotalPages", totalPages);
    const itemsPerPage = 10;
    const { token } = useAuth();

    const getMyBets = async () => {
        setLoader(true)


        try {

            const res = await getAPIAuth(`bet/derbyBetLists?status=${selectedBet}&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&perPage=${itemsPerPage}`, token);
            console.log('qazresresres', res)
            if (res?.data?.data?.success) {
                setTotalPages(res?.data?.data?.data?.pagination?.totalPages)
                setData(res?.data?.data?.data?.getUserBets)
                setBetSummary(res?.data?.data?.data?.betDetails)
            }
        } catch (error) {
            setData([])
            console.log("tttttttttttttt", error);
            errorToaster(error?.response?.data?.data?.message);
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getMyBets()
    }, [selectedBet, gameId, startDate, endDate, currentPage])


    const getGames = async () => {
        try {
            const res = await getAPIAuth('bet/games', token);
            if (res?.data?.data?.success) {
                setGames(res?.data?.data?.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getGames();
    }, []);

    console.log('data', games)


    const FilterSubscribtionBetData = data?.filter((item) => {
        return item?.packageSubscriptionId === null;
    });

    // console.log(filterBetsOptions);

    const startIndex = (currentPage - 1) * itemsPerPage + 1;
    return (
        <>
            <main className="main vh-100" id="main">
                <div className="mainContaint">
                    <div className="homepage tradingPages">
                        {/* start here */}
                        <div className="row mx-0 mt-2">
                            <div className="col-12">
                                <div className="row gap-3">
                                    <div className="col-12 px-0">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-lg">
                                                <div className="tradingSecHead">Derby BetList Page</div>
                                            </div>

                                            <div className="col-lg-2 col-md-3 col-6">
                                                <div className="reportsDropdownTitle">Filter Bets</div>
                                                <div className="dropdown reportsDropdown">
                                                    <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {selectedBet ? selectedBet : 'All'}
                                                    </button>
                                                    <ul className="dropdown-menu dropdownMenu w-100">
                                                        {
                                                            filterBetsOptions?.map((item) => (
                                                                <li className={`menuLinkDrop ${item?.label === selectedBet ? 'active' : ''}`}
                                                                    onClick={() => setSelectedBet(item?.label?.toLowerCase() === "all" ? '' : item?.label?.toLowerCase())}
                                                                    key={item?.label}
                                                                >{item?.label}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-3 col-6">
                                                <div className="reportsDropdownTitle">From</div>
                                                <input type="date" class="dateTimeInput" value={startDate} onChange={(e) => setStartDate(e.target.value)}></input>
                                            </div>
                                            <div className="col-lg-2 col-md-3 col-6">
                                                <div className="reportsDropdownTitle">To</div>
                                                <input type="date" class="dateTimeInput" min={startDate} value={endDate} onChange={(e) => setEndDate(e.target.value)}></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 px-0">
                                        <div className="row pb-1 pt-1 g-3 align-items-center ">
                                            <div className="col-lg-3 col-sm-6 col-12">
                                                <div class="adminTopCard p-3">
                                                    <div class="d-flex align-items-center">
                                                        <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                            <img src="assets/img/present.svg" alt="" />
                                                        </div>
                                                        <div class="">
                                                            <div class="adminTopCardTitle">Total Bets</div>
                                                            <div class="adminTopCardSubtitle">{betSummary?.totalBets ? betSummary?.totalBets : 0}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 col-12">
                                                <div class="adminTopCard p-3">
                                                    <div class="d-flex align-items-center">
                                                        <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                            <img src="assets/img/present.svg" alt="" />
                                                        </div>
                                                        <div class="">
                                                            <div class="adminTopCardTitle">Won</div>
                                                            <div class="adminTopCardSubtitle">{betSummary?.wonBets ? betSummary?.wonBets : 0}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 col-12">
                                                <div class="adminTopCard p-3">
                                                    <div class="d-flex align-items-center">
                                                        <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                            <img src="assets/img/present.svg" alt="" />
                                                        </div>
                                                        <div class="">
                                                            <div class="adminTopCardTitle">Lost</div>
                                                            <div class="adminTopCardSubtitle">{betSummary?.lostBets ? betSummary?.lostBets : 0}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-sm-6 col-12">
                                                <div class="adminTopCard p-3">
                                                    <div class="d-flex align-items-center">
                                                        <div class="adminTopCardIcon d-flex align-items-center justify-content-center">
                                                            <img src="assets/img/present.svg" alt="" />
                                                        </div>
                                                        <div class="">
                                                            <div class="adminTopCardTitle">Open</div>
                                                            <div class="adminTopCardSubtitle">{betSummary?.openBets ? betSummary?.openBets : 0}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 px-0 dambleTable reportsTable sectionInner tableCard tab-content tab-pane table-responsive mb-0 mt-4">
                                <table className="table align-middle text-white text-nowrap mb0">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    Sr No.
                                                </div>
                                            </th>
                                            {/* <th>
                        <div className="tabletxt d-flex align-items-center">
                          username
                        </div>
                      </th> */}
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    User Name
                                                </div>
                                            </th>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    Transaction Id
                                                </div>
                                            </th>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    User Id
                                                </div>
                                            </th>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    GameId
                                                </div>
                                            </th>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    Type
                                                </div>
                                            </th>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    Amount
                                                </div>
                                            </th>
                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    status
                                                </div>
                                            </th>

                                            <th>
                                                <div className="tabletxt d-flex align-items-center">
                                                    Potential Win
                                                </div>
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !loader ?
                                                data?.length > 0 ?
                                                    data?.map((item, index) => (
                                                        <tr>
                                                            <td>{startIndex + index}</td>
                                                            <td>{item?.username}</td>

                                                            <td>{item?.transaction_id}</td>
                                                            <td>{item?.userId}</td>
                                                            <td>{item?.gameId}</td>
                                                            <td>{item?.type}</td>
                                                            <td>{item?.amount}</td>
                                                            <td>{item?.status}</td>
                                                            <td className={`${item?.status === 'lost' ? 'text-danger' : item?.status === 'won' ? 'text-success' : ''}`}>{item?.potentialWin?.toFixed(2)}</td>
                                                        </tr>
                                                    ))
                                                    : <tr>
                                                        <td colSpan={10} className='text-center'>No Data</td>
                                                    </tr>
                                                : 'loading'
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {
                                totalPages > 1 ?
                                    <DarkPagination
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                    : ''
                            }
                        </div>
                    </div>
                </div>

                <AppFooter />
            </main>
        </>
    )
}

export default DerbyBetListPage
