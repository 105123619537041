import React from "react";

const ErrorMessage = ({ children }) => {
  return (
    <span
      style={{ color: "red", display: "flex", width: "100%", fontSize: "12px" }}
    >
      {children}
    </span>
  );
};

export default ErrorMessage;
