import React, { useEffect, useState } from "react";
import { auth, provider } from "../../config/firebase";
import { signInWithPopup } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { postAPI } from "../../service/apiInstance";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { useAuth } from "../../hooks/useAuth";
import { useBtAuth } from "../../hooks/useBtAuth";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useSelector } from "react-redux";
import { USER_LANG } from "../../constants";
import { useReferralModal } from "../../hooks/useReferralModal";

export function GoogleLogin({ children, parent }) {
  const [value, setValue] = useState("");
  const { setToken } = useAuth()
  const { setBtToken } = useBtAuth()
  const { setAuthModalObject } = useAuthModal()
  const ipAddress = useSelector(state => state.ipAddress)
  const country = useSelector(state => state.country)
  const { isReferral, setIsReferral } = useReferralModal()

  // for registration
  const handleRegister = async () => {
    const data = await signInWithPopup(auth, provider)
    const lang = localStorage.getItem(USER_LANG)

    const body = {
      email: data._tokenResponse.email,
      idToken: data._tokenResponse.idToken,
      localId: data._tokenResponse.localId,
      refreshToken: data._tokenResponse.refreshToken,
      loginMethod: "google",
      ipAddress: ipAddress ? ipAddress : "",
      country: country ? country : "",
      language: lang
    }
    const res = await postAPI("user/social-authentication", body);
    if (res?.data?.success) {
      succesToaster("login successful!")
      setToken(res.data.data.token)
      setBtToken(res.data.data.betByToken)
      setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
      setIsReferral(true)
    } else {
      errorToaster("something went wrong")
    }
    setValue(data.user.email);
    localStorage.setItem("email", data.user.email);

  };

  // for Logging in
  const handleLogin = async () => {
    const data = await signInWithPopup(auth, provider)
    const lang = localStorage.getItem(USER_LANG)
    const body = {
      email: data._tokenResponse.email,
      idToken: data._tokenResponse.idToken,
      localId: data._tokenResponse.localId,
      refreshToken: data._tokenResponse.refreshToken,
      loginMethod: "google",
      ipAddress: ipAddress ? ipAddress : "",
      country: country ? country : "",
      language: lang
    }
    const res = await postAPI("user/social-authentication", body);
    if (res.data.success) {
      succesToaster("login successful!")
      setToken(res.data.data.token)
      setBtToken(res.data.data.betByToken)
      setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))

    } else {
      errorToaster("something went wrong")
    }
    setValue(data.user.email);
    localStorage.setItem("email", data.user.email);

  };

  const handleCall = () => {
    if (parent == 'signUp') {
      handleRegister()
    } else if (parent == 'signIn') {
      handleLogin("signIn")
    }
  }

  useEffect(() => {
    setValue(localStorage.getItem("email"));
  }, []);

  return (
    <button
      type="button"
      style={{ background: 'transparent', border: 'none' }}
      onClick={handleCall}
    >
      {children}
    </button>
  );
}

