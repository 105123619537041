import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Pagination from '../../Admincomponents/pagination/Pagination'
import reload from './../../AdminAssets/img/reload.svg'
import { returnEndDate } from '../../../utils/returnEndDate'

const AlertBets = () => {
  const [filterValues, setFilterValues] = useState({
    startDate: "",
    endDate: "",
  });


    // set today's date as end date
    useEffect(()=> {
      const today = new Date()
      setFilterValues((prev)=>({
        ...prev,
        endDate: returnEndDate(today)
      }))
    },[])


    
  const handleChange = (e) => {
    setFilterValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
    <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner ">
                
                <header className="page-title-bar justify-content-between row align-items-center">
                  <h1 className="page-title mb-0 text-nowrap"> Alert Bets </h1>
                </header>
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row align-items-end">
                      <div className="col-md-auto pe-md-0 mb-2 mb-md-0">
                        <div className="adminSearchInput">
                          <input type="text" placeholder='Search User' className='form-control adminFilterInp' />
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="row justify-content-md-end mx-md-0">
                          <div className="col-md-auto col-12 pe-md-0 mb-2 mb-sm-0">
                            <label htmlFor="" className="form-label">Transactions</label>
                            <select name="" id="" className="form-select adminFilterInp">
                              <option value="">All</option>
                            </select>
                          </div>
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="" className="form-label">From</label>
                            <input type="date" name='startDate' value={filterValues?.startDate} onChange={handleChange} className='form-control adminFilterInp' />
                          </div>
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="" className="form-label">To</label>
                            <input type="date" name='endDate' value={filterValues?.endDate} onChange={handleChange} className='form-control adminFilterInp' />
                          </div>
                          {/* <div className="col-md-auto col-6 d-flex align-items-end col-sm-6 pe-md-0">
                            <button type="button" class="mx-2 btn btn-primary"><img className='reloadImg' src={reload}/>Refresh</button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table">
                        <thead className='text-uppercase text-nowrap'>
                          <tr>
                            <th>
                              <div className="d-flex">
                                  User
                                <span className='ms-1'>
                                  <i className="bi bi-sort-alpha-down"></i>
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Bet Time
                                <span className='ms-1'>
                                  <i className="bi bi-sort-alpha-down"></i>
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Sport 
                                <span className='ms-1'>
                                  <i className="bi bi-sort-alpha-down"></i>
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Event 
                                <span className='ms-1'>
                                  <i className="bi bi-sort-alpha-down"></i>
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Odd 
                                <span className='ms-1'>
                                  <i className="bi bi-sort-alpha-down"></i>
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Bet on 
                                <span className='ms-1'>
                                  <i className="bi bi-sort-alpha-down"></i>
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Stake 
                                <span className='ms-1'>
                                  <i className="bi bi-sort-alpha-down"></i>
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Reported By 
                                <span className='ms-1'>
                                  <i className="bi bi-sort-alpha-down"></i>
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Status 
                                <span className='ms-1'>
                                  <i className="bi bi-sort-alpha-down"></i>
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Action 
                                <span className='ms-1'>
                                  <i className="bi bi-sort-alpha-down"></i>
                                  {/* <i className="bi bi-sort-alpha-down-alt"></i> */}
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          
                        <tr>
                          <td colSpan={10} className="text-center" style={{fontSize: 16, fontWeight: 500}}>No Data Available</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <Pagination
                    totalPages={10}
                    currentPage={1}
                    setCurrentPage={1}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </main>
    </div>
    </>
  )
}

export default AlertBets