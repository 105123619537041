import React from 'react'
import editIcon from './../../fantasyAssets/img/editIcon.png'
import playerImg from './../../fantasyAssets/img/ban.png'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const TeamCard = ({ team, params1, Route1, mainRoute, mainParams }) => {
    const navigate = useNavigate()
    const fantasyUserData = useSelector(state => state?.fantasyUserData)

    return (
        <>
            <div
             onClick={(e) => { e.stopPropagation(); navigate(mainRoute, mainParams) }}
             className="col-12 mb-3 cursor-pointer"
             >
                <div className="card myTeamCard teamCard overflow-hidden">
                    <div className="card-header pt-2">
                        <div className="row align-items-center mx-0">
                            <div className="col px-0">
                                <div className="teamOwName">{`${fantasyUserData?.team} (T${team?.teamnumber})`}</div>
                            </div>
                            <div className="col-auto px-0">
                                <div onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(Route1, { state: params1 });
                                }} className="buttons d-flex align-items-center gap-3">
                                    <div className="viewTeam d-flex align-items-center"><img className="w-100" src={editIcon} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body px-0 pb-0">
                        <div className="row align-items-center">
                            <div className="col-auto d-flex align-items-center">
                                <div className="caption text-center ">C</div>
                                <div className="imgOuter">
                                    <img className="w-100" src={team?.captainimage} alt="" />
                                </div>
                            </div>
                            <div className="col text-center">
                                <div className="teamDescc">{team?.team1_name}: <span>{team?.player?.filter(el => team?.team1Id == el?.team)?.length}</span></div>
                                <div className="teamDescc">{team?.team2_name}: <span>{team?.player?.filter(el => team?.team2Id == el?.team)?.length}</span></div>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                                <div className="caption vCaption text-center  order-2">VC</div>
                                <div className="imgOuter order-1">
                                    <img className="w-100" src={team?.vicecaptainimage} alt="" />
                                </div>
                            </div>
                            <div className="col-12 capNames p-1 d-flex justify-content-between">
                                <div className="captainName  px-4">{team?.captin_name}</div>
                                <div className="captainName  px-4">{team?.viceCaptain_name}</div>
                            </div>
                            <div className="col-12 py-2">
                                <div className="row mx-0">
                                    <div className="col px-0 teamDivide text-center">
                                        <div className="playerCat">Wk</div>
                                        <div className="noOfPlyr">{team?.player?.filter(el => el?.role == 'keeper')?.length}</div>
                                    </div>
                                    <div className="col px-0 teamDivide border-start text-center">
                                        <div className="playerCat">BAT</div>
                                        <div className="noOfPlyr">{team?.player?.filter(el => el?.role == 'batsman')?.length}</div>
                                    </div>
                                    <div className="col px-0 teamDivide border-start text-center">
                                        <div className="playerCat">Ar</div>
                                        <div className="noOfPlyr">{team?.player?.filter(el => el?.role == 'allrounder')?.length}</div>
                                    </div>
                                    <div className="col px-0 teamDivide border-start text-center">
                                        <div className="playerCat">Bowl</div>
                                        <div className="noOfPlyr">{team?.player?.filter(el => el?.role == 'bowler')?.length}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeamCard