import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { adminGetAPIAuth } from "../../AdminServices";
import Pagination from "../../Admincomponents/pagination/Pagination";
import { Spinner } from "react-bootstrap";
import { returnEndDate } from "../../../utils/returnEndDate";
import { REPORTS } from "../../AdminConstants/apiEndpoints";

const statusArray = [
  {
    value:'',
    label:"All",
  },
  {
    value:'active',
    label:"Active",
  },
  {
    value:'completed',
    label:"Completed",
  },
]

const DisputedBets = () => {
  const [games, setGames] = useState([]);
  const [matchData, setMatchData] = useState([])
  const [loader, setLoader] = useState(true)
  const [matchList, setMatchList] = useState([])
  const [selectedMatchId, setSelectedMatchId] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(null);
  const perPage = 10;
  const [matchFilter, setMatchFilter] = useState({
    gameId: '',
    startDate: '',
    endDate: '',
    status: '',
    matchName: ''
  })

      // set today's date as end date
      useEffect(()=> {
        const today = new Date()
        setMatchFilter((prev)=>({
          ...prev,
          endDate: returnEndDate(today),
          // startDate: returnStartDate(today)
        }))
      },[])

  // get match list
  const getMatchList = async ()=> {
    try {
      const res = await adminGetAPIAuth(`bet/getAllMatches?page=${currentPage}&perPage=${perPage}&gameId=${matchFilter?.gameId}&matchName=${matchFilter?.matchName}&startDate=${matchFilter?.startDate}&endDate=${matchFilter?.endDate}&status=${matchFilter?.status}`)

      console.log("ressssssssssssssss",{res})
      if(res?.data?.data?.success) {
        setMatchList(res?.data?.data?.data)
        setTotalPages(res?.data?.data?.pagination?.totalPages)
        setSelectedMatchId(res?.data?.data?.data?.[0]?._id)
      }
      console.log(res)
    } catch (error) {
      setMatchList([])
      setTotalPages(null)
      setSelectedMatchId('')
    }
  }

  useEffect(()=> {
    getMatchList()
  },[currentPage,perPage, matchFilter, matchFilter?.gameId, matchFilter?.matchName, matchFilter?.startDate, matchFilter?.endDate, matchFilter?.status])


  // get selected match data
  const getmatchData =  async ()=> {
    if(selectedMatchId) {
      setLoader(true)
      try {
        const res = await adminGetAPIAuth(`bet/matchAnalysis/${selectedMatchId}`)
        setMatchData(res?.data?.data?.data?.[0])
      } catch (error) {
        
      } finally {
        setLoader(false)
      }
    }
  }

  useEffect(()=> {
    getmatchData()
  },[selectedMatchId])

  const handleChange = (e) => {
    setMatchFilter((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const getGames = async () => {
    try {
      const res = await adminGetAPIAuth(REPORTS.betGames);
      
      if (res?.data?.data?.success) {
        setGames(res?.data?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getGames();
  }, []);



  return (
    <>
      <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner ">
                <header className="page-title-bar row justify-content-between align-items-center">
                  <h1 className="page-title mb-0 text-nowrap col-md px-0"> 
                    <div className="row">
                      <div className="col">Market Analysis</div>
                      {/* <div className="col-md col-12 ">
                          <nav aria-label="breadcrumb border-0">
                            <ol class="breadcrumb flex-nowrap overflowauto border-0">
                              <li class="breadcrumb-item">
                                <Link className="d-grid align-items-center">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M11.03 2.59a1.501 1.501 0 0 1 1.94 0l7.5 6.363a1.5 1.5 0 0 1 .53 1.144V19.5a1.5 1.5 0 0 1-1.5 1.5h-5.75a.75.75 0 0 1-.75-.75V14h-2v6.25a.75.75 0 0 1-.75.75H4.5A1.5 1.5 0 0 1 3 19.5v-9.403c0-.44.194-.859.53-1.144ZM12 3.734l-7.5 6.363V19.5h5v-6.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 .75.75v6.25h5v-9.403Z"></path></svg>
                                </Link>
                              </li>
                              <li class="breadcrumb-item"><Link>Level One</Link></li>
                              <li class="breadcrumb-item"><Link>Level Two</Link></li>
                              <li class="breadcrumb-item active" aria-current="page">Level Three</li>
                            </ol>
                          </nav>
                      </div> */}
                      {/* <div className="col-md-auto  col-12 d-flex align-items-center">
                        <div className="row align-items-center mx-0">
                          <div className="col-sm-auto col-12">
                            <div class="adminSearchInput me-2 my-2 my-md-0"><input type="text" placeholder="Search User" class="form-control adminFilterInp" value=""/><div class="overflow-hidden"></div></div>
                          </div>
                            <div className="balance col-auto">Balance <strong>14444545.22</strong></div>
                            <div className="balance col-auto">lorem72/465</div>
                        </div>
                      </div> */}
                    </div>
                  </h1>
                </header>
                <div className="row justify-content-end align-items-end mb-4">
                  <div className="col-md mb-2 mb-md-0">
                    <div className="adminSearchInput">
                    <input
                      type="text"
                      placeholder="Search Match"
                      className="form-control adminFilterInp"
                      name="matchName"
                      value={matchFilter?.matchName}
                      onChange={handleChange}
                    />
                    </div>
                  </div>
                  <div className="col-md-auto col-6 col-sm-3 pe-md-0 mb-2 mb-sm-0">
                    <label htmlFor="gameId" className="form-label">Select Games</label>
                    <select
                      name="gameId"
                      id="gameId"
                      className="form-select adminFilterInp"
                      value={matchFilter.gameId}
                      onChange={handleChange}
                    >
                      <option value="">All</option>
                      {games?.map((item) => (
                        <option value={item.gameId} key={item?._id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-auto col-6 col-sm-3 pe-md-0 mb-2 mb-sm-0">
                    <label htmlFor="status" className="form-label">Match Status</label>
                    <select name="status" id="status" onChange={handleChange} className="form-select adminFilterInp">
                      {
                        statusArray.map((item)=>(
                          <option value={item.value} >{item.label}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-md-auto col-6 col-sm-3 pe-md-0">
                    <label htmlFor="startDate" className="form-label">From</label>
                    <input type="date" id="startDate" name="startDate" value={matchFilter?.startDate} onChange={handleChange} className='form-control adminFilterInp' />
                  </div>
                  <div className="col-md-auto col-6 col-sm-3 pe-md-0">
                    <label htmlFor="endDate" className="form-label">To</label>
                    <input type="date" id="endDate" name="endDate" value={matchFilter?.endDate} onChange={handleChange} className='form-control adminFilterInp' />
                  </div>
                </div>
                {
                  matchList?.length ? 
                    matchList?.map((item)=> (
                      <div key={item?._id}>
                        <div className={`row heading mx-0 matchAnalysisHeader cursor-pointer ${item?._id === selectedMatchId ? 'active' : ''}`} onClick={()=>setSelectedMatchId(item?._id)}>
                          <div className="col">
                            <div className="">{item?.event?.name}</div>
                          </div>
                          {item?._id === selectedMatchId ? 
                            <div className="col-auto">
                              <div className="">
                                {matchData?.totalExposer?.[0]?.exposer}
                              </div>
                            </div>
                          : ''}
                          <div className="col-auto ">
                            <div className="arrow">
                              <svg stroke="currentColor" fill="currentColor" stroke-width="1" viewBox="0 0 512 512" height="1.3em" width="1.3em" xmlns="http://www.w3.org/2000/svg"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path></svg>
                            </div>
                          </div>
                        </div>
                        {
                          item?._id === selectedMatchId ? 
                            <div className="row ">
                              { !loader ?
                                (matchData?.bookmaker?.length || matchData?.odds?.length || matchData?.fancy?.length) ? 
                                  <>
                                  <div className="col-md-6 col-12">
                                    {
                                      matchData?.bookmaker?.length ? 
                                      <div className="table-responsive mb-4">
                                        <table className="table table-hover">
                                          <thead className="text-uppercase text-nowrap">
                                            <tr >
                                              <th colspan="2">
                                                <div className="d-flex">
                                                    BOOKMAKER
                                                </div>
                                              </th>
                                            </tr>
                                          </thead>
                                              <tbody>
                                                {
                                                  matchData?.bookmaker?.map((itemm)=> (
                                                    <tr>
                                                      <td>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                          <div className="txt">{itemm?.nation}</div>
                                                          <div className="amount">{itemm?.exposer}</div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  ))
                                                }
                                            </tbody>
                                        </table>
                                      </div>
                                      : ''
                                    }
                                    {
                                      matchData?.odds?.length ? 
                                        <div className="table-responsive mb-4">
                                          <table className="table table-hover">
                                            <thead className="text-uppercase text-nowrap">
                                              <tr >
                                                <th colspan="2">
                                                  <div className="d-flex">
                                                      MATCH_ODDS
                                                  </div>
                                                </th>
                                              </tr>
                                            </thead>
                                                <tbody>
                                                  {
                                                    matchData?.odds?.map((itemm)=> (
                                                      <tr>
                                                        <td>
                                                          <div className="d-flex align-items-center justify-content-between">
                                                            <div className="txt">{itemm?.nation}</div>
                                                            <div className="amount">{itemm?.exposer}</div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    ))
                                                  }
                                              </tbody>
                                          </table>
                                        </div>
                                      : ''
                                    }
                                  </div>
                                  <div className="col-md-6 col-12">
                                    {
                                      matchData?.fancy?.length ? 
                                        <div className="table-responsive mb-4">
                                          <table className="table table-hover">
                                            <thead className="text-uppercase text-nowrap">
                                              <tr >
                                                <th colspan="2">
                                                  <div className="d-flex">
                                                      FANCY
                                                  </div>
                                                </th>
                                              </tr>
                                            </thead>
                                                <tbody>
                                                  {
                                                    matchData?.fancy?.map((itemm)=> (
                                                      <tr>
                                                        <td>
                                                          <div className="d-flex align-items-center justify-content-between">
                                                            <div className="txt">{itemm?.nation}</div>
                                                            <div className="amount">{itemm?.exposer}</div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    ))
                                                  }
                                              </tbody>
                                          </table>
                                        </div>
                                      : ''
                                    }
                                  </div>
                                  </>
                                : <div className="col-12 mb-4">
                                  <div class="text-center" style={{fontSize: 18, fontWeight: 500}}>No Bets on this match</div>
                                </div>
                                : <div className="col-12 mb-4 d-flex align-items-center justify-content-center">
                                  <Spinner size='sm'/>
                                </div>
                              }
                            </div>
                          : ''
                        }
                      </div>
                    ))
                  : <div class="text-center" style={{fontSize: 18, fontWeight: 500}}>No Match Available</div>
                }
                  {
                    totalPages > 1 ? 
                      <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    : ''
                  }
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default DisputedBets;
