import React from 'react'
import LightiningIcon from './../../fantasyAssets/img/lightining.png'
import CounterComp from '../CounterComp/CounterComp'
import { useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'

const MatchCard = ({
  upcoming,
  matchName,
  series_name,
  team1ShortName,
  team2ShortName,
  status,
  final_status,
  winning_status,
  team1logo,
  team2logo,
  start_date,
  playing11_status,
  teamfullname2,
  teamfullname1,
  time_start,
  id,
  match,
  live,
  leaderBoard
}) => {
  const navigate = useNavigate()
  const encodedSN = encodeURIComponent(series_name)
  const navigateHandler = () => {
    if (upcoming) {
      if (new Date(time_start) <= new Date()) {

      } else {
        navigate(`${ROUTES_CONST.FANTASY_HOME}/${id}?contest_name=${encodedSN}`, { state: match })
      }
    } else if (live) {
      navigate(`${ROUTES_CONST.FANTASY_MATCH_DETAIL}`, { state: { match: match } })
    } else if ((playing11_status === 1) && (playing11_status === 1 && status !== 'closed')) {

      navigate(`${ROUTES_CONST.CONTEST_DETAILS_PAGE}?contest_name=${encodedSN}`, { state: { match: match, myContest: true } })

    } else if (status === 'closed' && final_status === 'pending') {

      navigate(`${ROUTES_CONST.CONTEST_DETAILS_PAGE}?contest_name=${encodedSN}`, { state: { match: match, myContest: true } })

    } else if (status === 'closed') {
      navigate(`${ROUTES_CONST.CONTEST_DETAILS_PAGE}?contest_name=${encodedSN}`, { state: { match: match, myContest: true } })

    } else {
      if (final_status === 'IsReviewed') {
        navigate(`${ROUTES_CONST.FANTASY_HOME}/${id}?contest_name=${encodedSN}`)
      } else if (final_status === 'pending') {
        if (new Date() < new Date(start_date)) {
          navigate(`${ROUTES_CONST.FANTASY_HOME}/${id}?contest_name=${encodedSN}`, { state: match })
        } else {
          navigate(`${ROUTES_CONST.CONTEST_DETAILS_PAGE}?contest_name=${encodedSN}`, { state: { match: match, myContest: true } })
        }
      }

    }
  }
  return (
    <div
      className="matchesCard featuredCard overflow-hidden position-relative rounded-3 mb-3 cursor-pointer"
      onClick={() => {
        if (leaderBoard) return
        navigateHandler()
      }}
    >
      <div className="matchCardBody">
        <div className="teamHeadline row mx-0 pt-2 align-items-center border-0">
          <div className="col fw-normal fs-12 matchteams text-uppercase text-white-50 px-2">
            {/* icc cricket world cup */}
            {series_name}
          </div>
          <div className="col-auto matchStatus text-success">
            {
              upcoming ? (
                playing11_status === 1 ? "Lineups Out" : ""
              ) : (
                playing11_status === 1 && status !== 'closed' ? (
                  "Lineups Out"
                ) : status === 'closed' &&
                  final_status === 'IsReviewed' ? (
                  'Under Review'
                ) : status === 'closed' &&
                  final_status === 'pending' ? (
                  'In Progress'
                ) : status === 'closed' ? (
                  final_status
                ) : (
                  ''
                )
              )
            }
            {/* In Progress */}
          </div>
        </div>
        <div className="innerBody firstMatchBody py-3">
          <div className="row mx-0 align-items-center">
            <div className="col-auto leftSideTeams px-2">
              <div className="team d-flex align-items-center">
                <div className="bgImg bgImg2">
                  <img src={team1logo} className="w-100" alt="" />
                </div>
                <div className="teamLogo position-relative rounded-circle d-flex justify-content-center align-items-center">
                  <img src={team1logo} className="w-100" alt="" />
                </div>
                <div className="teamName text-uppercase text-white fw-medium ms-2">
                  {team1ShortName}
                </div>
              </div>
              <div className="teamName2">{teamfullname1}</div>
            </div>
            <div className="col px-0 matchTimer text-center">
              {
                upcoming ?
                  <>
                    <CounterComp start_date={time_start} />
                  </> :
                  <>
                    <img src={LightiningIcon} className='lightiningImg' alt="" />
                  </>
              }
            </div>
            <div className="col-auto rightSideTeams px-2">
              <div className="team d-flex align-items-center">
                <div className="bgImg">
                  <img src={team2logo} className="w-100" alt="" />
                </div>
                <div className="teamName text-uppercase text-white fw-medium me-2">
                  {team2ShortName}
                </div>
                <div className="teamLogo position-relative rounded-circle d-flex justify-content-center align-items-center">
                  <img src={team2logo} className="w-100" alt="" />
                </div>
              </div>
              <div className="teamName2 text-end">{teamfullname2}</div>
            </div>
          </div>
        </div>
        <div className="row mx-0 bottomBody align-items-center py-2">
          <div className="col-auto">
            <div className="playersPlaying">2K Playing</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MatchCard