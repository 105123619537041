import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fantasySportsLogo from "../../assets/img/fantasysports.png";
import tradeLogo from "../../assets/img/Frame.png";
import { postTradingAPI } from "../../Trading/TradingServices";
import { useEffect } from "react";
import { stockAddUserData } from "../../store/action";
import { useAuth } from "../../hooks/useAuth";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useWalletModal } from "../../hooks/useWallet";
import { AUTH_MODAL_TABS, WALLET_MODAL_TABS } from "../../constants";
import { ROUTES_CONST } from "../../constants/routeConstant";
import { useNavigate } from "react-router-dom";

const FantasyWalletCard = () => {
  // const [data, setData] = useState({});
  const dispatch = useDispatch()
  const fantasyUserData = useSelector((state) => state?.fantasyUserData);
  const stockUserData = useSelector((state) => state?.stockUserData);
  const { token } = useAuth()
  const { setAuthModalObject } = useAuthModal();
  const { setWalletModalObject } = useWalletModal()
  const navigate = useNavigate()

  const getData = async () => {
    try {
      const body = {
        action: "account-balance",
      };
      const res = await postTradingAPI("account-balance.php", body);
      const response = await res.json();
      // setData(response);
      dispatch(stockAddUserData(response));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);


  const handleModal = () => {
    if (token) {
      setWalletModalObject((prev) => ({
        isWalletOpen: true,
        selectedTab: WALLET_MODAL_TABS.DEPOSIT
      }))
    } else {
      setAuthModalObject((pre) => ({
        isAuthOpen: true,
        selectedTab: AUTH_MODAL_TABS.LOG_IN,
      }))
    }
  }

  const handleConverter = () => {
    navigate(ROUTES_CONST.ACCOUNT)
    dispatch({ type: "CONVERTER", payload: "Converter" })
  }



  return (
    <div className="casinoFantasyWallet mt-4">
      <div className="row mb-3 align-items-center">
        <div className="col myWalletHeading" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
          My Wallet

        </div>
        <div className="col-auto">
          <div className="d-flex align-items-center">
            <button className="walletBtns me-3" onClick={handleModal}>Deposit</button>
            <button className="walletBtns walletBtns2" onClick={handleConverter}>Converter</button>
          </div>
        </div>
      </div>
      <div className="collapse" id="collapseExample">

        <div className="row row-col-5 g-3" >
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="FantasyCard">
              <div className="fantasyImg">
                <img
                  src={fantasySportsLogo}
                  alt="fantasyLogo"
                  className="w-100 h-100"
                />
              </div>
              <div className="fantasySubheading mt-2">Main Wallet</div>
              <div className="fantasyCardHeading ">
                ₹ {fantasyUserData?.mainWallet ? fantasyUserData?.mainWallet?.toFixed(2) : 0.00}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="FantasyCard">
              <div className="fantasyImg">
                <img
                  src={fantasySportsLogo}
                  alt="fantasyLogo"
                  className="w-100 h-100"
                />
              </div>
              <div className="fantasySubheading mt-2">Exposure</div>
              <div className="fantasyCardHeading ">
                ₹ {fantasyUserData?.exposer ? fantasyUserData?.exposer?.toFixed(2) : 0.00}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="FantasyCard">
              <div className="fantasyImg">
                <img
                  src={fantasySportsLogo}
                  alt="fantasyLogo"
                  className="w-100 h-100"
                />
              </div>
              <div className="fantasySubheading mt-2">Betting Wallet</div>
              <div className="fantasyCardHeading ">
                ₹ {fantasyUserData?.points ? fantasyUserData?.points?.toFixed(2) : 0.00}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="FantasyCard">
              <div className="fantasyImg">
                <img
                  src={fantasySportsLogo}
                  alt="fantasyLogo"
                  className="w-100 h-100"
                />
              </div>
              <div className="fantasySubheading mt-2">Fantasy Wallet</div>
              <div className="fantasyCardHeading ">
                ₹{" "}
                {fantasyUserData?.totalbalance
                  ? Number(fantasyUserData?.totalbalance).toFixed(2)
                  : "0.00"}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="FantasyCard">
              <div className="fantasyImg">
                <img
                  src={fantasySportsLogo}
                  alt="fantasyLogo"
                  className="w-100 h-100"
                />
              </div>
              <div className="fantasySubheading mt-2">Fantasy Bonus</div>
              <div className="fantasyCardHeading ">
                ₹{" "}
                {fantasyUserData?.totalbonus
                  ? Number(fantasyUserData?.totalbonus).toFixed(2)
                  : "0.00"}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="FantasyCard">
              <div className="fantasyImg">
                <img
                  src={fantasySportsLogo}
                  alt="fantasyLogo"
                  className="w-100 h-100"
                />
              </div>
              <div className="fantasySubheading mt-2">Fantasy winning</div>
              <div className="fantasyCardHeading ">
                ₹{" "}
                {fantasyUserData?.totalwinning
                  ? Number(fantasyUserData?.totalwinning).toFixed(2)
                  : "0"}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="FantasyCard">
              <div className="fantasyImg">
                <img src={tradeLogo} alt="tradeLogo" className="w-100 h-100" />
              </div>
              <div className="fantasySubheading mt-2">Stock Wallet</div>
              <div className="fantasyCardHeading ">
                ₹{" "}
                {stockUserData?.amount_balance
                  ? Number(stockUserData?.amount_balance).toFixed(2)
                  : "0"}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="FantasyCard">
              <div className="fantasyImg">
                <img src={tradeLogo} alt="tradeLogo" className="w-100 h-100" />
              </div>
              <div className="fantasySubheading mt-2">P/L percent</div>
              <div className="fantasyCardHeading ">
                {stockUserData?.profit_loss_per ? stockUserData?.profit_loss_per : "0%"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FantasyWalletCard;
