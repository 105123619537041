import React, { useEffect, useState } from 'react'
import check from "./../../fantasyAssets/img/check.svg";
import { getFantasyAuth } from '../../fantasyServices';
import { Spinner } from 'react-bootstrap';
import { ROUTES_CONST } from '../../../constants/routeConstant';
import { useNavigate } from 'react-router-dom';

const StockListTab = ({ stockContest }) => {
  const [stocks, setstocks] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate()

  const getStocks = async () => {
    try {
      const AllStocks = await getFantasyAuth(
        `/getStockMyTeams?teamId=${stockContest?.jointeamid}`,
      );

      setstocks(AllStocks?.data?.data);

    } catch (error) {

    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    if (stockContest?.jointeamid) {
      getStocks()
    }
  }, [stockContest?.jointeamid])
  return (
    <>
      <div className="row g-3 mx-0">
        {loader ? (
          <div className='w-100 h-100 d-flex justify-content-center'>
            <Spinner color='white' size='sm' />
          </div>
        ) : (
          !stocks || stocks == 0 ? (
            <div className='w-100 h-100 d-flex justify-content-center text-light' >No Data Available</div>

          ) : (
            stocks?.map((item) => (
              <div className="col-12">
                <div className="warningCard addcase card cursor-pointer border-0 rounded4 position-relative">
                  <div className="card-body">
                    <div className="row mx-0 align-items-center">
                      <div className="col-auto ps-0">
                        <div className="circle "></div>
                      </div>
                      <div className="col px-0">
                        <div className="portfolioCardTxt">{item?.name}</div>
                        <div className="percentage">
                          {/* 0% */}
                          {
                            Number(item.closePrice) > Number(item?.openPrice)
                              ? (
                                ((Number(item?.closePrice) - Number(item?.openPrice)) * 100) /
                                Number(item?.closePrice)
                              ).toFixed(2)
                              : Number(item?.closePrice) < Number(item?.openPrice)
                                ? (
                                  ((Number(item?.openPrice) - Number(item?.closePrice)) * 100) /
                                  Number(item?.openPrice)
                                ).toFixed(2)
                                : 0
                          }
                          <span className="mx-2">{isNaN(Number(item?.closePrice)) ? "---" : item.closePrice}</span></div>
                      </div>
                      <div className="col-auto pe-0"><img className="checkImg" src={check} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )
        )
        }
      </div>
      {
        stockContest?.status == 'notstarted' ? (
          <button className='createTeamBtn bottomUp'
            onClick={() =>
              navigate(`${ROUTES_CONST?.FANTASY_SELECT_STOCKS_PAGE}/${stockContest._id}`,
                {
                  state: {
                    stocks: stocks,
                    stockContest: stockContest
                  }
                }
              )
            }
          >
            Edit Stocks
          </button>
        ) : (
          <></>
        )
      }
    </>
  )
}

export default StockListTab