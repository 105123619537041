import React from "react";
import { getAuth, signOut } from 'firebase/auth';
import { getUserDetails, getSubscriptionUsers } from "../store/action";
import { ADMIN_LOGGED_IN_ID, ADMIN_FRONTEND_LOGGED_IN_ID, BT_TOKEN_NAME, STOCK_USER_ID, TOKEN_NAME } from "../constants";

const logoutFunction = (setToken, dispatch, setIsLogoutOpen) => {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(BT_TOKEN_NAME);
    localStorage.removeItem(ADMIN_FRONTEND_LOGGED_IN_ID);
    localStorage.removeItem(STOCK_USER_ID);
    setToken("");

    const auth = getAuth();
    dispatch(getUserDetails({}));
    dispatch(getSubscriptionUsers([]));

    signOut(auth)
        .then(() => {
            // succesToaster("Logged Out")
        })
        .catch((error) => {
            // An error happened.
        });

    setIsLogoutOpen(false);
};

export default logoutFunction;