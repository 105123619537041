import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { USER } from "../../AdminConstants/apiEndpoints";
import { succesToaster as successToaster } from "../../../utils/toaster";
import { adminPostAPI, adminPostAPIAuth } from "../../AdminServices";
import { useEffect } from "react";
import { ADMIN_AUTH_ACCESS } from "../../../constants";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import PasswordInput from "../passwordInput/PasswordInput";

const superAdminRolesArray = [
  {
    role: "Choose a role",
    value: "",
  },
  {
    role: "Super Master",
    value: "superMaster",
  },
  {
    role: "Agent",
    value: "agent",
  },
  {
    role: "User",
    value: "user",
  },
];

const superMasterRolesArray = [
  {
    role: "Choose a role",
    value: "",
  },
  {
    role: "Agent",
    value: "agent",
  },
  {
    role: "User",
    value: "user",
  },
];

const agentRolesArray = [
  {
    role: "Choose a role",
    value: "",
  },
  {
    role: "Agent",
    value: "agent",
  },
  {
    role: "User",
    value: "user",
  },
];


//eslint-disable-next-line
function AddUserModal({ setShow, show, parentData, getChildren, userData }) {
  // const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
  const passwordRegx = /^(?=.*[a-z])(?=.*[a-z])(?=.*\d).{8,}$/
  const ipAddress = useSelector(state => state.ipAddress)
  const country = useSelector(state => state.country)
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobileNumber: '',
    userName: "",
    password: "",
    confirmPassword: "",
    role: "",
    share: "",
    myShare: "",
    points: "",
    maxProfit: "",
    maxProfitPerDay: "",
    maxLoss: "",
    maxLossPerDay: "",
    creditReference: "",
    notes: "",
    userPassword: "",
    cricket: false,
    stock: false,
    minBet: '',
    maxBet: ''
  });

  // useEffect(() => {
  //   setFormValues((prev) => ({
  //     ...prev,
  //     creditReference: prev.creditReference - formValues.points
  //   }))
  // }, [formValues.points])



  useEffect(() => {
    if (parentData) {
      setFormValues((prev) => ({
        ...prev,
        creditReference: parentData.totalPoints - prev.points,
      }));
    }
  }, [parentData,formValues.points])

  console.log("creditRefrence" , formValues.creditReference)

  // console.log("formValuesformValues", formValues);

  const [error, setError] = useState("");
  const [ss, ssSet] = useState([]);

  const adminData = useSelector((state) => state.adminData);

console.log(adminData)
  const handleChange = (e) => {
    const nameArray = [
      "points",
      "creditReference",
      "myShare",
      "share",
      "maxProfit",
      "maxProfitPerDay",
      "maxLoss",
      "maxLossPerDay",
    ];
    if (e.target.name === "cricket" || e.target.name === "stock") {
      setFormValues((prev) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    } else if (
      nameArray.includes(e.target.name) &&
      isNaN(Number(e.target.value))
    ) {
      setFormValues((prev) => ({
        ...prev,
        [e.target.name]: formValues[e.target.name],
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const resetFormValues = () => {
    setFormValues((prev) => ({
      name: "",
      email: "",
      mobileNumber: '',
      userName: "",
      password: "",
      confirmPassword: "",
      role: "",
      share: "",
      myShare: "",
      points: "",
      maxProfit: "",
      maxProfitPerDay: "",
      maxLoss: "",
      maxLossPerDay: "",
      creditReference: "",
      notes: "",
      userPassword: "",
      cricket: false,
      stock: false,
      minBet: '',
      maxBet: ''
    }));
  };

  const adminToken = useLocalStorage(ADMIN_AUTH_ACCESS, "");

  const handleClick = () => {
    if (
      formValues.name === "" ||
      formValues.userName === "" ||
      formValues.password === "" ||
      formValues.confirmPassword === "" ||
      formValues.points === "" ||
      formValues.role === "" ||
      formValues.notes === "" ||
      formValues.email === "" ||
      formValues.mobileNumber === ""||
      formValues.share === ""

    ) {
      setError("Fill all the fields");
    } else if (!passwordRegx.test(formValues.password)) {
      setError("Password must be at least 8 characters and include one uppercase letter, one lowercase letter, and one number")
    } else if (!passwordRegx.test(formValues.confirmPassword)) {
      setError("Password must be at least 8 characters and include one uppercase letter, one lowercase letter, and one number")
    } else if (formValues.confirmPassword !== formValues.password) {
      setError("Created password and confirm password should match");
    } else if (formValues.maxBet < formValues.minBet) {
      setError("Max Bet value should be equal or greater than min bet value");
    } else {
      handleAddUser();
    }
  };

  useEffect(() => {
    if (formValues.points) {
      if (formValues.points > parentData?.points) {
        setError("Points should be less than parent's available points");
      } else {
        setError("");
      }
    }
    //eslint-disable-next-line
  }, [formValues.points]);

  const handleAddUser = async () => {
    const payload = {
      name: formValues.name,
      username: formValues.userName,
      role: formValues.role,
      password: formValues.password,
      createdBy: adminData._id,
      points: Number(formValues.points),
      email: formValues.email,
      mobile: formValues.mobileNumber,
      maxProfit: Number(formValues.maxProfit),
      maxProfitPerDay: Number(formValues.maxProfitPerDay),
      maxLoss: Number(formValues.maxLoss),
      maxLossPerDay: Number(formValues.maxLossPerDay),
      isCricket: formValues.cricket,
      minBet: Number(formValues.minBet),
      maxBet: Number(formValues.maxBet),
      ipAddress: ipAddress,
      email: formValues.email,
      share: formValues.share
    };

    if (formValues.stock) {
      payload.isStock = formValues.stock;
    }

    if (formValues.notes) {
      payload.desc = formValues.notes;
    }

    const loginCred = {
      username: adminData.username,
      password: formValues.userPassword,
    };
    console.log(payload)

    setError("");
    try {
      const res = await adminPostAPI(USER.login, loginCred, true);
      if (res?.data?.data?.success) {
        adminToken.updateValue(res?.data?.data.token);
        addNewUser(payload);
      }
    } catch (error) {
      setError(error?.response?.data?.data?.message);
    }
  };

  const addNewUser = async (data) => {
    // alert("hii")
    try {
      const res = await adminPostAPIAuth(USER.register, data);
      if (res.status === 200) {
        successToaster("User Created Successfully! ");
        resetFormValues();
        getChildren(parentData);
        setShow(false);
      }
    } catch (error) {
      console.log("sadffsdcvx", error);
      setError(error?.response?.data?.data?.message);
    }
  };

  return (
    <>
      <Modal
        show={show}
        scrollable
        onHide={() => {
          resetFormValues();
          setShow(false);
          setError("");
        }}
      >
        <Modal.Header>
          <Modal.Title>Add Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="name">Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      id="name"
                      name="name"
                      onChange={handleChange}
                      value={formValues.name}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="email">Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={formValues.email}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="mobileNumber">Mobile Number</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Mobile Number"
                      id="mobileNumber"
                      name="mobileNumber"
                      onChange={handleChange}
                      value={formValues.mobileNumber}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="username">Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Username"
                      id="username"
                      name="userName"
                      onChange={handleChange}
                      value={formValues.userName}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="role">User Role</Form.Label>
                    {/* Form.Select have css in app.css */}
                    <Form.Select
                      id="role"
                      name="role"
                      placeholder="role"
                      onChange={handleChange}
                    >
                      {
                        adminData?.role === "superMaster" ?
                          superMasterRolesArray?.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.role}
                            </option>
                          ))
                          : adminData?.role === "agent" ?
                            agentRolesArray?.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.role}
                              </option>
                            )) :
                            superAdminRolesArray?.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.role}
                              </option>
                            ))
                      }
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Label htmlFor="password">Create a Password</Form.Label>
                  <PasswordInput
                    placeholder="password"
                    id="password"
                    name="password"
                    onChange={handleChange}
                    value={formValues.password}
                  />
                </Col>

                <Col>
                  <Form.Label htmlFor="confirmPassword">
                    Confirm Password
                  </Form.Label>
                  <PasswordInput
                    placeholder="Confirm Password"
                    id="confirmPassword"
                    name="confirmPassword"
                    onChange={handleChange}
                    value={formValues.confirmPassword}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="share">Share</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Share"
                      id="share"
                      name="share"
                      onChange={handleChange}
                      value={formValues.share}

                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="myShare">My Share</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="My Share"
                      id="myShare"
                      name="myShare"
                      onChange={handleChange}
                      value={formValues.myShare}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="points">Points</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Points"
                      id="points"
                      name="points"
                      value={formValues.points}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="creditReference">
                      Credit Reference
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Credit Reference"
                      id="creditReference"
                      name="creditReference"
                      onChange={handleChange}
                      value={formValues.creditReference}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Fantasy"
                      id="cricket"
                      name="cricket"
                      onChange={handleChange}
                      value={formValues.cricket}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Stock"
                      id="stock"
                      name="stock"
                      onChange={handleChange}
                      value={formValues.stock}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="maxProfit">
                      Max Profit (optional)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Max Profit"
                      id="maxProfit"
                      name="maxProfit"
                      onChange={handleChange}
                      value={formValues.maxProfit}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="maxProfitPerDay">
                      Max Profit Per Day (optional)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Max Profit Per Day"
                      id="maxProfitPerDay"
                      name="maxProfitPerDay"
                      onChange={handleChange}
                      value={formValues.maxProfitPerDay}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="maxLoss">
                      Max Loss (optional)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Max Loss"
                      id="maxLoss"
                      name="maxLoss"
                      onChange={handleChange}
                      value={formValues.maxLoss}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="maxLossPerDay">
                      Max Loss Per Day (optional)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Max Loss Per Day"
                      id="maxLossPerDay"
                      name="maxLossPerDay"
                      onChange={handleChange}
                      value={formValues.maxLossPerDay}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="minBet">
                      Minimum Bet
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Min Bet"
                      id="minBet"
                      name="minBet"
                      onChange={handleChange}
                      value={formValues.minBet}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="maxBet">
                      Maximum Bet
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Maximum Bet"
                      id="maxBet"
                      name="maxBet"
                      onChange={handleChange}
                      value={formValues.maxBet}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="notes">Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Notes"
                      id="notes"
                      name="notes"
                      onChange={handleChange}
                      value={formValues.notes}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Label htmlFor="userPassword">Password</Form.Label>
                  <PasswordInput
                    placeholder="Password"
                    id="userPassword"
                    name="userPassword"
                    onChange={handleChange}
                    value={formValues.userPassword}
                  />
                </Col>
              </Row>
              {/* admin-forms-error-text has css in userManagement.css  */}
              {error && (
                <Row className="mb-2 admin-forms-error-text">
                  <Col>
                    <div>{error}</div>
                  </Col>
                </Row>
              )}
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="text-dark"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
          <Button
            variant="primary px-5"
            onClick={handleClick}
            disabled={formValues.points > parentData?.points}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddUserModal;
