import React, { useEffect, useRef, useState } from "react";
import { postAPI, postAPIAuth, casinoPostAPIAuth } from "../../service/apiInstance";
import { useSelector } from "react-redux";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { useAuth } from "../../hooks/useAuth";
import { useAuthModal } from "../../hooks/useAuthModal";
import { AUTH_MODAL_TABS, MOBILE_SCREEN_WIDTH } from "../../constants";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { getAPIAuth } from "../../service/apiInstance";
import './style.css'

const WinMore = () => {

    const { token } = useAuth();
    const isSmScreen = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
    const iframeRef = useRef()
    const [showIframe, setShowIframe] = useState(false)
    const navigate = useNavigate()
    const [useriframedata, setUserIframeData] = useState([]);

    // console.log("useriframedata", useriframedata);



    const toggleFullScreen = (e) => {
        e.stopPropagation();
        console.log("iframe click")
        const iframe = iframeRef.current;

        if (iframe) {
            if (iframe.requestFullscreen) {
                iframe.requestFullscreen();
            } else if (iframe.mozRequestFullScreen) {
                iframe.mozRequestFullScreen();
            } else if (iframe.webkitRequestFullscreen) {
                iframe.webkitRequestFullscreen();
            } else if (iframe.msRequestFullscreen) {
                iframe.msRequestFullscreen();
            }
        }
    };

    const getUserIframe = async () => {

        if (token) {
            try {
                const res = await getAPIAuth("user/getUserIframe", token);
                console.log("ressdss", res);

                if (res?.data?.data?.success) {
                    setUserIframeData(res?.data?.data?.response);
                    // succesToaster(res?.data?.message)
                }
            } catch (error) {
                // console.log("error", error);
                errorToaster(error?.response?.data?.data?.message)
            }
        }

    }

    useEffect(() => {

        getUserIframe()

    }, [token])


    return (
        <div className="row pt-3 sdafsg">
            {token ? <>
                <div className="col-12 px-0 position-relative">
                    {
                        !isSmScreen ? <>
                            <div className="iframeMain rounded-2 overflow-hidden">
                                <iframe

                                    title="game iframe"
                                    ref={iframeRef}
                                    className="game-iframe w-100 h-100"
                                    src={`${useriframedata?.IframeURL}`}
                                    allow="autopaly"
                                    allowFullScreen />
                            </div>

                        </> : ''
                    }
                </div>


            </> : <>
                <div className="col-12 ">
                    <div className="blinkLayer">
                        <div className="msgText">Please Log In To Play</div>
                    </div>
                </div>
            </>
            }
        </div>
    )
}

export default WinMore