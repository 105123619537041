import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { adminGetAPIAuth } from "../../AdminServices";
import UpdateQrDetails from "./UpdateQrDetails";
import AddQrDetails from "./AddQrDetails";

const QrDetails = () => {
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState('')

  const getPaymentDetails = async () => {
    try {
      const res = await adminGetAPIAuth("user/getUserPaymentDetails?flag=QR Code");
      setTableData(res?.data?.data?.data);
    } catch (error) {
      console.log("error------", error);
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);


  return (
    <>
      <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner ">
                <header className="page-title-bar justify-content-between row">
                  <h1 className="page-title col-md"> QR DETAILS </h1>
                  <div className=" page-section align-items-center w-auto m-0  flex-row-reverse col-md-auto">
                    {!tableData.length > 0 && <AddQrDetails getPaymentDetails={getPaymentDetails}/>}
                  </div>
                </header>
                <div className="d-flex justify-content-between my-3">
                  <div>
                    <ol className="breadcrumb"></ol>
                  </div>
                </div>

                <div className="page-section">
                  <div className=" card-fluid">
                    <div className="card-body px-0">
                      <div className="table-responsive">
                        <Table>
                          <thead>
                            <tr>
                              <th
                                style={{ minWidth: "140px", cursor: "pointer" }}
                                className="p-2  d-flex justify-content-evenly"
                              >
                                <span>S.No</span>
                              </th>

                              <th
                                style={{ minWidth: "150px", cursor: "pointer" }}
                                className="p-2"
                              >
                                <span>Type</span>
                              </th>
                              <th
                                style={{ minWidth: "140px", cursor: "pointer" }}
                                className="p-2"
                              >
                                <span>Display Name</span>
                              </th>
                              <th
                                style={{ minWidth: "140px", cursor: "pointer" }}
                                className="p-2"
                              >
                                <span>QR Image</span>
                              </th>
                              <th
                                style={{ minWidth: "140px", cursor: "pointer" }}
                                className="p-2"
                              >
                                <span>Status</span>
                              </th>
                              <th
                                style={{ minWidth: "140px", cursor: "pointer" }}
                                className="p-2"
                              >
                                <span>Action</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData?.length > 0 ? tableData?.map((item, index) => (
                              <tr key={item?._id}>
                                <td className="align-middle  d-flex justify-content-evenly pt-4">{index + 1}</td>
                                <td className="align-middle">{item?.type}</td>
                                <td className="align-middle">{item?.displayName}</td>
                                <td className="align-middle"><img src={item?.image} alt="img" style={{ borderRadius: '5px', height: '40px', width: '40px'  }} /></td>
                                <td className="align-middle">{item?.status}</td>
                                <td className="align-middle" onClick={() => {
                                  setId(item)
                                }}>
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRight"
                                    aria-controls="offcanvasRight"
                                  >
                                    <i className="bi bi-pen"></i>
                                  </button>
                                </td>
                              </tr>
                            )) :  <tr>
                            <td colSpan={6} className="text-center" style={{fontSize: 16, fontWeight: 500}}>Please add QR Code details</td>
                          </tr>}
                          </tbody>
                        </Table>
                      </div>

                      <div
                        className="offcanvas offcanvas-end"
                        tabindex="-1"
                        id="offcanvasRight"
                        aria-labelledby="offcanvasRightLabel"
                      >
                        <div className="offcanvas-header">
                          <h5 className="offcanvas-title" id="offcanvasRightLabel">
                            Edit Details
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="offcanvas-body">
                          <UpdateQrDetails item={id} getPaymentDetails={getPaymentDetails}/>
                        </div>
                      </div>

                    </div>
                  </div>
                  <hr className="my-5" />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default QrDetails;
