import React, { useEffect, useState } from 'react'
import MyMatches from './MyMatches'
import UpcomingMatches from './UpcomingMatches'
import { fetchMatchList } from '../../fantasyServices/fetchMatchList'

const Cricket = () => {
  const [myMatches, setMyMatches] = useState([])
  const [upcomingMatch, setUpcomingMatches] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  
  const getMatchList = async () => {
    try {
      const res = await fetchMatchList()
      if (res.data.success) {
        setMyMatches(res.data.data.joinedMatches)
        setUpcomingMatches(res.data.data.upcomingMatches)
      }

    } catch (error) {

    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getMatchList()
  }, [])
  return (
    <>
      <MyMatches myMatches={myMatches} isLoading={isLoading} />
      <UpcomingMatches upcomingMatch={upcomingMatch} isLoading={isLoading} />
    </>
  )
}

export default Cricket