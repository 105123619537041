import axios from "axios";
import {
  ADMIN_AUTH_ACCESS,
  ADMIN_FRONTEND_LOGGED_IN_ID,
  STOCK_USER_ID,
  TOKEN_NAME,
  adminBaseUrl,
  baseURL,
} from "../constants";
import { useAuth } from "../hooks/useAuth";
import { signOut } from "firebase/auth";
// const baseURL = 'http://3.6.235.42:3000/api'
// export const baseURL = "https://api.beta.damble.io/api"
const token = localStorage.getItem(TOKEN_NAME);
const CasinoHeader = 'kcuug8eBtxlF0hp%2fCoPb4w%2b099oABmDv8%2boHAYiC91INPlIBosl%2boqyicBGDH9CjJg6y24Ob%2fguMwxlYhk16Jbb6HyHhboSo'
// no auth token required
export const getAPI = async (url, body) => {
  // try {
  const response = await axios.get(`${baseURL}/${url}`, body, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
  });
  return response;
  // } catch (error) {
  // console.log("error=>", error)
  // return error
  // }
};

export const getAPIAuth = async (url, tokenInit) => {
  // console.log("dsafyuahsd", url);
  const bURL = baseURL;
  const token = localStorage.getItem(TOKEN_NAME);
  // console.log("token from getapiAuth", token)
  try {
    const response = await axios.get(`${bURL}/${url}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: `Bearer ${tokenInit ? tokenInit : token}`,
      },
    });
    return response;
  } catch (error) {
    // console.log("error from  getApiAuth",error?.response?.data ,error?.response ,error );

    if (error?.response?.data?.message === "Invalid token") {
      // alert('getAPIAuth')
      localStorage.removeItem(TOKEN_NAME);
      localStorage.removeItem(ADMIN_FRONTEND_LOGGED_IN_ID);
      localStorage.removeItem(STOCK_USER_ID);
      // window.location.reload(true);
    }
    throw error;
  }
};

// no auth token required
export const postAPI = async (url, params, admin) => {
  const bURL = baseURL;
  // try {
  const response = await axios.post(`${bURL}/${url}`, params, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
  });
  return response;
  // } catch (error) {
  // console.log("error=>", error)
  // return error

  // }
};

export const casinoPostAPIAuth = async (url, params, tokenInit, sessionID) => {

  // console.log("sessionIDsessionIDsessionID", sessionID);
  // const token = localStorage.getItem(TOKEN_NAME)
  const bURL = baseURL;
  const token = localStorage.getItem(TOKEN_NAME);
  try {
    const response = await axios.post(`${bURL}/${url}`, params, {
      headers: {

        "Content-Type": "application/json; charset=utf-8",
        sessionId: `${sessionID}`,
        Accept: "application/json",
        Authorization: `Bearer ${tokenInit ? tokenInit : token}`,

      },
    });
    return response;
  } catch (error) {
    if (error?.response?.data?.msg === "Invalid token") {
      // alert('postAPIAuth')
      localStorage.removeItem(TOKEN_NAME);
      localStorage.removeItem(ADMIN_FRONTEND_LOGGED_IN_ID);
      localStorage.removeItem(STOCK_USER_ID);
      // signOut(auth)
      //     .then(() => {
      //         // succesToaster("Logged Out")
      //     })
      //     .catch((error) => {
      //         // An error happened.
      //     });
      // window.location.reload(true);
    }
    // console.log("error=>", error);
    throw error;
  }
};

export const postAPIDerbyAuth = async (url, body) => {
  console.log("Request URL:", url);


  try {
    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        ApiSecret: "d4b65560e871c865ea83782ec3915df3644c497c52bc001293c71e9e474d6db4",
        ApiKey: "FIRSTAGENTCOM",
        origin: "https://bholaexchange.com",
        domainIp: "139.59.77.243"

      },
    });

    // Try to parse the response as JSON
    try {
      return response.data
    } catch (parseError) {
      console.error("Failed to parse response as JSON", parseError);
      return response.data;  // Return the raw response data if JSON parsing fails
    }

  } catch (error) {
    if (error?.response?.data?.msg === "Invalid token") {
      localStorage.removeItem('TOKEN_NAME');
      localStorage.removeItem('ADMIN_FRONTEND_LOGGED_IN_ID');
      localStorage.removeItem('STOCK_USER_ID');
      // signOut(auth)
      //     .then(() => {
      //         console.log("Logged Out")
      //     })
      //     .catch((error) => {
      //         console.error("Sign out error", error);
      //     });
      window.location.reload(true);
    } else {
      console.error("Error:", error);
    }
    throw error;
  }
};

export const postAPIAuth = async (url, params, tokenInit) => {
  // const token = localStorage.getItem(TOKEN_NAME)
  const bURL = baseURL;
  const token = localStorage.getItem(TOKEN_NAME);
  try {
    const response = await axios.post(`${bURL}/${url}`, params, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: `Bearer ${tokenInit ? tokenInit : token}`,
      },
    });
    return response;
  } catch (error) {
    if (error?.response?.data?.msg === "Invalid token") {
      // alert('postAPIAuth')
      localStorage.removeItem(TOKEN_NAME);
      localStorage.removeItem(ADMIN_FRONTEND_LOGGED_IN_ID);
      localStorage.removeItem(STOCK_USER_ID);
      // signOut(auth)
      //     .then(() => {
      //         // succesToaster("Logged Out")
      //     })
      //     .catch((error) => {
      //         // An error happened.
      //     });
      // window.location.reload(true);
    }
    // console.log("error=>", error);
    throw error;
  }
};

export const postAPIAuthFormData = async (url, params) => {
  const token = localStorage.getItem(TOKEN_NAME);
  try {
    const response = await axios({
      method: "post",
      url: `${baseURL}/${url}`,
      data: params,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    if (error?.response?.data?.msg === "Invalid token") {
      // alert('postAPIAuthFormData')
      localStorage.removeItem(TOKEN_NAME);
      localStorage.removeItem(ADMIN_FRONTEND_LOGGED_IN_ID);
      localStorage.removeItem(STOCK_USER_ID);
      // signOut(auth)
      //     .then(() => {
      //         // succesToaster("Logged Out")
      //     })
      //     .catch((error) => {
      //         // An error happened.
      //     });
      // window.location.reload(true);
    }
    // console.log("error=>", error);
    throw error;
  }
};
export const deleteAPIAuth = async (url) => {
  const token = localStorage.getItem(TOKEN_NAME);
  try {
    const response = await axios.delete(`${baseURL}/${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    // console.log("error=>", error);
    return error;
  }
};
