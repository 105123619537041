import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Crypto from "./Crypto";
import Flat from "./Flat";

import qrlogomain from "../../assets/img/qrCode.png";
import qrdownload from "../../assets/img/Download.svg";
import uploadss from "../../assets/img/plus.png";
import smallQR from "../../assets/img/ic_outline-qrcode.svg";
import googlePay from "../../assets/img/googlepay.svg";
import crypto from "../../assets/img/crypto.png";
import bank from "../../assets/img/Bank.svg";
import { getAPIAuth, postAPIAuthFormData } from "../../service/apiInstance";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import axios from "axios";
import { saveAs } from "file-saver";

const Deposit = ({ setWalletModalObject }) => {
  const [activeTab, setActiveTab] = useState("QR Code");
  const [detailsData, setDetailsData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [image, setImage] = useState(uploadss);
  const [imageUrl, setImgUrl] = useState("");
  // console.log("imageurrll", {
  //   imageUrl,
  //   image
  // });
  const [amount, setAmount] = useState("");
  const { token } = useAuth();
  const [transactionId, setTransactionId] = useState("");
  const qrCodeFileRef = useRef(null);
  const upiFileRef = useRef(null);
  const bankFileRef = useRef(null);
  const cryptoFileRef = useRef(null);

  const download = () => {
    var element = document.createElement("a");
    var file = new Blob(
      [
        // "https://timesofindia.indiatimes.com/thumb/msid-70238371,imgsize-89579,width-400,resizemode-4/70238371.jpg"
        detailsData?.["QR Code"]?.image,
      ],
      { type: "image/*" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "image.jpg";
    element.click();
  };

  const handleDownload = async () => {
    axios({
      url: detailsData?.["QR Code"]?.image,
      method: "GET",
      responseType: "arraybuffer",
    })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        saveAs(blob, "downloaded_image"); // You can set a default filename here
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  const getPaymentMethods = async () => {
    if (token) {
      try {
        const res = await getAPIAuth("user/getPaymentMethods", token);
        if (res?.data?.data?.success) {
          // console.log(res)
          setDetailsData(res?.data?.data?.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  // console.log('detailsData', detailsData)

  useEffect(() => {
    getPaymentMethods();
  }, [token]);

  // useEffect(() => {
  //   const filtered = detailsData?.filter((item) => item?.type === activeTab);
  //   setSelectedData(filtered?.[0]);

  // }, [activeTab, detailsData]);

  const handleSubmit = async (e, type) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("typename", "paymentRecord");
    formData.append("type", type);
    formData.append("image", image);
    formData.append("amount", amount);
    formData.append("userTransactionId", transactionId);

    try {
      const res = await postAPIAuthFormData(
        "user/submitPaymentRecord",
        formData
      );
      if (res?.data?.data?.success) {
        succesToaster(res?.data?.data?.message);
        setWalletModalObject(false);
        setImage("");
        setAmount("");
        setTransactionId("");
      } else {
        errorToaster(res.data.message);
      }
    } catch (error) {
      console.log("error", error);
      errorToaster("Something went wrong !");
    }
  };

  const onChangeFile = (e) => {
    const fileObj = e.target.files && e.target.files[0];
    // console.log("sdfsddsf", fileObj);
    if (
      fileObj &&
      (fileObj.type === "image/jpeg" ||
        fileObj.type === "image/jpg" ||
        fileObj.type === "image/png")
    ) {
      setImage(fileObj);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgUrl(reader.result);
      };
      reader.readAsDataURL(fileObj);
    } else {
      errorToaster("Select image in jpeg, jpg, png");
    }
  };

  // const handleAmount = (e) => {
  //   if (isNaN(Number(e?.target?.value))) {

  //     let newValue = e.target.value;

  //     // Remove non-digit characters
  //     newValue = newValue.replace(/[^\d]/g, '');

  //     // Limit the value to 6 characters
  //     newValue = newValue.slice(0, 6);

  //     // Update the state with the new value
  //     setAmount(newValue);
  //   } else {
  //     setAmount(0);
  //   }
  // };

  const handleAmount = (e) => {
    let newValue = e.target.value;

    if (isNaN(Number(newValue))) {
      setAmount("0");
    } else {
      newValue = newValue.replace(/[^\d]/g, "");
      newValue = newValue.slice(0, 6);
      setAmount(newValue);
    }
  };

  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
    succesToaster("Successfully copied to clipboard");
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <div className="inputdata">
            <label for="text" class="inputLabel">
              Amount
              {/* <span>*</span> */}
            </label>
            <input
              type="text"
              className="form-control inputlogin border-0 shadow-none"
              placeholder="Amount"
              value={amount}
              onChange={handleAmount}
            />
          </div>
        </div>
        <div className="col-12 my-3">
          <ul
            className="nav nav-tabs border-0 shadow-none modalNavBank mb-0 gap-2 d-inline-flex flex-nowrap"
            id="myTab"
            role="tablist"
          >
            <li
              className="nav-item border-0 shadow-none rounded-0 bg-transparent"
              role="presentation"
            >
              <button
                className={`nav-link border-0 shadow-none navBtnModal rounded-0 ${
                  activeTab === "QR Code" ? "active" : ""
                }`}
                onClick={() => setActiveTab("QR Code")}
              >
                <span className="d-inline-flex align-items-center googleIcon">
                  <img
                    src={smallQR}
                    className="img-fluid w-100 h-100 "
                    alt="table accordion"
                  />
                </span>
                <span className="d-block"> QR CODE</span>
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className={`nav-link border-0 shadow-none navBtnModal rounded-0 ${
                  activeTab === "UPI" ? "active" : ""
                }`}
                onClick={() => setActiveTab("UPI")}
              >
                <span className="d-inline-flex align-items-center googleIcon">
                  {" "}
                  <img
                    src={googlePay}
                    className="img-fluid w-100 h-100 "
                    alt="table accordion"
                  />
                </span>
                <span className="d-block">UPI</span>
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className={`nav-link border-0 shadow-none navBtnModal rounded-0 ${
                  activeTab === "Bank Transfer" ? "active" : ""
                }`}
                onClick={() => setActiveTab("Bank Transfer")}
              >
                <span className="d-inline-flex align-items-center googleIcon">
                  {" "}
                  <img
                    src={bank}
                    className="img-fluid w-100 h-100 "
                    alt="table accordion"
                  />
                </span>
                <span className="d-block">Bank Transfer</span>
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className={`nav-link border-0 shadow-none navBtnModal rounded-0 ${
                  activeTab === "Crypto" ? "active" : ""
                }`}
                onClick={() => setActiveTab("Crypto")}
              >
                <span className="d-inline-flex align-items-center googleIcon">
                  <img
                    src={crypto}
                    className="img-fluid w-100 h-100 "
                    alt="table accordion"
                  />
                </span>
                <span className="d-block">Crypto</span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      {activeTab === "QR Code" && (
        <div className="row mt-3">
          <div className="col-12">
            <div className="headingQR">QR Code for payment</div>
          </div>
          <div className="col-12 mt-1 mb-3">
            <div className="row align-items-end">
              <div className="col-sm-auto col-12">
                <div className="qrIMg mb-2 mb-sm-0">
                  <img
                    src={detailsData?.["QR Code"]?.image}
                    className="img-fluid w-100 h-100 object-fit-cover"
                    alt="table accordion"
                  />
                </div>
              </div>
              <div className="col-sm col-12">
                <div className="nameQr">Display Name</div>
                <div className="nameInput ">
                  {detailsData?.["QR Code"]?.displayName}
                </div>

                <div
                  className="downladqr position-relative"
                  // onClick={download}
                  onClick={handleDownload}
                >
                  <span className="d-inline-flex align-items-center downladIocn">
                    <img
                      src={qrdownload}
                      className="img-fluid w-100 h-100 "
                      alt="qrdowbload"
                    />
                  </span>
                  <span className="d-block mt-2"> Open QR</span>
                  {/* <Link download="Example-PDF-document"
                          target="_blank"
                          rel="noreferrer" to={detailsData?.["QR Code"]?.image}>asdfgh</Link> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 position-relative overflow-hidden">
            <div className="position-relative">
              <label
                className="FantasyCard position-relative w-100"
                htmlFor="screenupload"
              >
                <span className=" fantasyImg text-center d-flex align-items-center justify-content-center mx-auto">
                  <img
                    src={typeof image === "object" ? imageUrl : image}
                    className={`img-fluid w-100 h-100 object-fit-contain ${
                      imageUrl
                        ? "position-absolute top-50 start-50 translate-middle uploadedImg"
                        : ""
                    }`}
                    alt="upload screen suit"
                  />
                </span>
                <span
                  className={`fantasySubheading mt-2 text-center w-100 align-items-center justify-content-center ${
                    imageUrl ? "d-none" : "d-flex"
                  }`}
                >
                  Click here to upload to payment screenshot
                </span>
              </label>
            </div>
            <input
              className="form-control position-absolute h-100 w-100 top-0 start-0"
              style={{ opacity: "0.01" }}
              type="file"
              onChange={onChangeFile}
              ref={qrCodeFileRef}
              id="screenupload"
            ></input>
            {typeof image === "object"?<div className="position-absolute" style={{ right: 16, top: 10 }}>
              <button
                className="border-0 bg-transparent shadow-none"
                onClick={() => {
                  setImage(uploadss);
                  setImgUrl("");
                  if (qrCodeFileRef.current) {
                    qrCodeFileRef.current.value = "";
                  }
                }}
              >
                <i class="bi bi-x-lg text-danger"></i>
              </button>
            </div>:null}
          </div>
          <div className="col-12 mt-2">
            <div className="inputdata">
              <label for="text" class="inputLabel">
                Transaction Id
                {/* <span>*</span> */}
              </label>
              <input
                type="text"
                className="form-control inputlogin border-0 shadow-none"
                placeholder="Enter Transaction Id"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 pt-3">
            <button
              className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center py-2 justify-content-center w-100"
              onClick={(e) => handleSubmit(e, selectedData?.type)}
            >
              Submit
            </button>
          </div>
        </div>
      )}

      {activeTab === "UPI" && (
        <div>
          <div className="row gap-3">
            <div className="col-12">
              <div className="upiCard">
                <div className="row">
                  <div className="col-auto">
                    <div className="upiHeading">Name</div>
                    <div className="userName mt-1">
                      {detailsData?.UPI?.displayName}
                    </div>
                  </div>
                  <div className="col">
                    <div className="upiHeading">UPI Details</div>
                    <span className="userName mt-1 d-flex align-items-center text-decoration-none ">
                      {detailsData?.UPI?.upiDetails}
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() =>
                          copyToClipboard(detailsData?.UPI?.upiDetails)
                        }
                      >
                        <span className="text-decoration-none d-flex align-items-center text-success">
                          Copy
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 position-relative overflow-hidden">
              <div className="position-relative">
                <label
                  className="FantasyCard w-100 position-relative"
                  htmlFor="screenupload"
                >
                  <span className=" fantasyImg text-center d-flex align-items-center justify-content-center mx-auto">
                    <img
                      src={typeof image === "object" ? imageUrl : image}
                      className={`img-fluid w-100 h-100 object-fit-contain ${
                        imageUrl
                          ? "position-absolute top-50 start-50 translate-middle uploadedImg"
                          : ""
                      }`}
                      alt="upload screen suit"
                    />
                  </span>
                  <span
                    className={`fantasySubheading mt-2 text-center w-100 align-items-center justify-content-center ${
                      imageUrl ? "d-none" : "d-flex"
                    }`}
                  >
                    Click here to upload to payment screenshot
                  </span>
                  {/* <span className={`fantasySubheading mt-2 text-center w-100 align-items-center justify-content-center ${imageUrl ? 'd-flex' : 'd-none'}`}>
                    Click here to upload to payment screenshot
                  </span> */}
                </label>
              </div>

              <input
                className="form-control position-absolute h-100 w-100 top-0 start-0"
                style={{ opacity: "0.01" }}
                type="file"
                id="screenupload"
                onChange={onChangeFile}
                ref={upiFileRef}
              ></input>
              {typeof image === "object"?<div className="position-absolute" style={{ right: 16, top: 10 }}>
                <button
                  className="border-0 bg-transparent shadow-none"
                  onClick={() => {
                    setImage(uploadss);
                    setImgUrl("");
                    if (upiFileRef.current) {
                      upiFileRef.current.value = "";
                    }
                  }}
                >
                  <i class="bi bi-x-lg text-danger"></i>
                </button>
              </div>:null}
            </div>
            <div className="col-12 mt-2">
              <div className="inputdata">
                <label for="text" class="inputLabel">
                  Transaction Id
                  {/* <span>*</span> */}
                </label>
                <input
                  type="text"
                  className="form-control inputlogin border-0 shadow-none"
                  placeholder="Enter Transaction Id"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12 pt-3">
              <button
                className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center py-2 justify-content-center w-100"
                onClick={(e) => handleSubmit(e, selectedData?.type)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {activeTab === "Bank Transfer" && (
        <div>
          <div className="row gap-3">
            <div className="col-12">
              <div className="headingQR">Id Details</div>
            </div>
            <div className="col-12">
              <div className="upiCard">
                <div className="row">
                  <div className="col-auto">
                    <div className="userName mt-1 ">Bank Name</div>
                    <div className="userName mt-1 ">Account Number</div>
                    <div className="userName mt-1 ">IFSC Code</div>
                    <div className="userName mt-1 ">Account Holder Name </div>
                  </div>
                  <div className="col">
                    <span className="userName mt-1  d-flex align-items-center text-decoration-none ">
                      {detailsData?.["Bank Transfer"]?.bankName}
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() =>
                          copyToClipboard(
                            detailsData?.["Bank Transfer"]?.bankName
                          )
                        }
                      >
                        <span className="text-decoration-none d-flex align-items-center text-success">
                          Copy
                        </span>
                      </span>
                    </span>
                    <span className="userName mt-1  d-flex align-items-center text-decoration-none ">
                      {detailsData?.["Bank Transfer"]?.AccountNumber}
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() =>
                          copyToClipboard(
                            detailsData?.["Bank Transfer"]?.AccountNumber
                          )
                        }
                      >
                        <span className="text-decoration-none d-flex align-items-center text-success">
                          Copy
                        </span>
                      </span>
                    </span>

                    <span className="userName mt-1  d-flex align-items-center text-decoration-none ">
                      {detailsData?.["Bank Transfer"]?.ifscCode}
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() =>
                          copyToClipboard(
                            detailsData?.["Bank Transfer"]?.ifscCode
                          )
                        }
                      >
                        <span className="text-decoration-none d-flex align-items-center text-success">
                          Copy
                        </span>
                      </span>
                    </span>
                    <span className="userName mt-1  d-flex align-items-center text-decoration-none">
                      {detailsData?.["Bank Transfer"]?.accHolderName}
                      <span
                        className="ms-2 cursor-pointer "
                        onClick={() =>
                          copyToClipboard(
                            detailsData?.["Bank Transfer"]?.accHolderName
                          )
                        }
                      >
                        <span className="text-decoration-none d-flex align-items-center text-success">
                          Copy
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 position-relative overflow-hidden">
              <div className="position-relative">
                <label
                  className="FantasyCard position-relative w-100"
                  htmlFor="screenupload"
                >
                  <span className=" fantasyImg text-center d-flex align-items-center justify-content-center mx-auto">
                    <img
                      src={typeof image === "object" ? imageUrl : image}
                      className={`img-fluid w-100 h-100 object-fit-contain ${
                        imageUrl
                          ? "position-absolute top-50 start-50 translate-middle uploadedImg"
                          : ""
                      }`}
                      alt="upload screen suit"
                    />
                  </span>
                  <span
                    className={`fantasySubheading mt-2 text-center w-100 align-items-center justify-content-center ${
                      imageUrl ? "d-none" : "d-flex"
                    }`}
                  >
                    Click here to upload to payment screenshot
                  </span>
                </label>
              </div>

              <input
                className="form-control position-absolute h-100 w-100 top-0 start-0"
                style={{ opacity: "0.01" }}
                type="file"
                id="screenupload"
                onChange={onChangeFile}
                ref={bankFileRef}
              ></input>

              {typeof image === "object"?<div className="position-absolute" style={{ right: 16, top: 10 }}>
                <button
                  className="border-0 bg-transparent shadow-none"
                  onClick={() => {
                    setImage(uploadss);
                    setImgUrl("");
                    if (bankFileRef.current) {
                      bankFileRef.current.value = "";
                    }
                  }}
                >
                  <i class="bi bi-x-lg text-danger"></i>
                </button>
              </div>:null}
            </div>

            <div className="col-12 mt-2">
              <div className="inputdata">
                <label for="text" class="inputLabel">
                  Transaction Id
                  {/* <span>*</span> */}
                </label>
                <input
                  type="text"
                  className="form-control inputlogin border-0 shadow-none"
                  placeholder="Enter Transaction Id"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12 pt-3">
              <span
                className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center py-2 justify-content-center "
                onClick={(e) => handleSubmit(e, selectedData?.type)}
              >
                Submit
              </span>
            </div>
          </div>
        </div>
      )}

      {activeTab === "Crypto" && (
        <div>
          <div className="row gap-3">
            <div className="col-12">
              <div className="upiCard">
                <div className="row">
                  <div className="col-auto">
                    <div className="upiHeading">Name</div>
                    <div className="userName mt-1">
                      {detailsData?.Crypto?.displayName}
                    </div>
                  </div>
                  <div className="col">
                    <div className="upiHeading">UPI Details</div>
                    <span className="userName mt-1 d-flex align-items-center text-decoration-none ">
                      {detailsData?.Crypto?.network}
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() =>
                          copyToClipboard(detailsData?.cryptodetails?.network)
                        }
                      >
                        <span className="text-decoration-none d-flex align-items-center text-success">
                          Copy
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 position-relative overflow-hidden">
              <div className="position-relative">
                <label
                  className="FantasyCard w-100 position-relative"
                  htmlFor="screenupload"
                >
                  <span className=" fantasyImg text-center d-flex align-items-center justify-content-center mx-auto">
                    <img
                      src={typeof image === "object" ? imageUrl : image}
                      className={`img-fluid w-100 h-100 object-fit-contain ${
                        imageUrl
                          ? "position-absolute top-50 start-50 translate-middle uploadedImg"
                          : ""
                      }`}
                      alt="upload screen suit"
                    />
                  </span>
                  <span
                    className={`fantasySubheading mt-2 text-center w-100 align-items-center justify-content-center ${
                      imageUrl ? "d-none" : "d-flex"
                    }`}
                  >
                    Click here to upload to payment screenshot
                  </span>
                </label>
              </div>
              <input
                className="form-control position-absolute h-100 w-100 top-0 start-0"
                style={{ opacity: "0.01" }}
                type="file"
                id="screenupload"
                onChange={onChangeFile}
                ref={cryptoFileRef}
              ></input>
             
              {typeof image === "object" ? <div className="position-absolute" style={{ right: 16, top: 10 }}>
                <button
                  className="border-0 bg-transparent shadow-none"
                  onClick={() => {
                    setImage(uploadss);
                    setImgUrl("");
                    if (cryptoFileRef.current) {
                      cryptoFileRef.current.value = "";
                    }
                  }}
                >
                  <i class="bi bi-x-lg text-danger"></i>
                </button>
              </div>:null}
            </div>

            <div className="col-12 mt-2">
              <div className="inputdata">
                <label for="text" class="inputLabel">
                  Transaction Id
                  {/* <span>*</span> */}
                </label>
                <input
                  type="text"
                  className="form-control inputlogin border-0 shadow-none"
                  placeholder="Enter Transaction Id"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12 pt-3">
              <button
                className="customBtn shadow-none changeGmailBtn text-nowrap d-flex align-items-center py-2 justify-content-center w-100"
                onClick={(e) => handleSubmit(e, selectedData?.type)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Deposit;
