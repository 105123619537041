import React from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import play from './../../fantasyAssets/img/contestplayed.svg'
import series from './../../fantasyAssets/img/series.svg'
import matches from './../../fantasyAssets/img/matches.svg'
import won from './../../fantasyAssets/img/contestwon.svg'
import profileImg from './../../fantasyAssets/img/profile.jpg'
import editIcon from './../../fantasyAssets/img/editIcon.png'
import { useSelector } from 'react-redux'

const PlayInsight = () => {
    const fantasyUserData = useSelector(state => state?.fantasyUserData)
    return(
        <>
        <div className="row fantasyInner">
            <FantasyHeader gameHeader={true} title={'Play Insights'}/>
                <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                <div className="row mainCenterPart g-4 mx-0 py-4">
                    <div className='col-12'>
                        <div className='profileBack'>
                            <div className='profileImg position-relative'>
                                <img className='w-100 h-100' src={fantasyUserData?.image}/>
                            </div>
                        <div className='profileName'>{fantasyUserData?.name}</div>
                        </div>
                    </div>
                    {/* <div className="row mx-0 align-items-center">
                        <div className="col-auto"><div className="rounded-circle d-flex align-items-center justify-content-center rupees text-white fw-bold">₹</div></div>
                        <div className="col ps-0">
                            <div className="balance text-white">Total Balance</div>
                            <div className="balanceAmount text-white">₹ 100.00</div>
                        </div>
                    </div> */}
                {/* <div className="col-12">
                    <div className="warningCard card border-0">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={TrophyIcon} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <div className="balanceAmountSubTxt text-white ">Your Winnings</div>
                                    <div className="balanceSubTxt text-white">Withdraw Your Winnings</div>
                                </div>
                                <div className="col-auto pe-0"><div className="winningAmount balanceAmount">₹ 0.00</div></div>
                            </div>
                        </div>
                    </div>
                    <div className="withdrawAmount card border-0  pt-3 position-relative">
                        <a href="verifyAccount.html" className="py-4 pb-3 text-decoration-none balanceAmount text-center text-white">Withdraw Amount</a>
                    </div>
                </div> */}
                <div className="col-6">
                    <div className="dipositCard shadow p-2 text-center">
                        <div className="warningImg rounded-circle mx-auto mb-2"><img src={play} className="w-100" alt=""/></div>
                        <div className="balance depositText ">Contest Played</div>
                        {/* <div className="subbalance ">Mony You Add</div> */}
                        <div className="subbalanceTwo text-white">{fantasyUserData?.totalchallenges}</div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="dipositCard  shadow  p-2 text-center">
                        <div className="warningImg rounded-circle mx-auto mb-2"><img src={series} className="w-100" alt=""/></div>
                        <div className="balance depositText">Series</div>
                        {/* <div className="subbalance ">Offer Earned Cash Bonus</div> */}
                        <div className="subbalanceTwo  text-white">{fantasyUserData?.totalseries}</div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="dipositCard  shadow  p-2 text-center">
                        <div className="warningImg rounded-circle mx-auto mb-2"><img src={matches} className="w-100" alt=""/></div>
                        <div className="balance depositText">Matches</div>
                        {/* <div className="subbalance ">Offer Earned Cash Bonus</div> */}
                        <div className="subbalanceTwo  text-white">{fantasyUserData?.totalmatches}</div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="dipositCard  shadow  p-2 text-center">
                        <div className="warningImg rounded-circle mx-auto mb-2"><img src={won} className="w-100" alt=""/></div>
                        <div className="balance depositText">Contest Won</div>
                        {/* <div className="subbalance ">Offer Earned Cash Bonus</div> */}
                        <div className="subbalanceTwo text-white">{fantasyUserData?.totalwinning}</div>
                    </div>
                </div>
                </div>
                </div>  
            <FantasyBottomBar/>
            </div>
        </>
    )
}

export default PlayInsight