import React, { useEffect, useRef, useState } from "react";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { Formik, useFormik } from "formik";
import countryCode from "../../../constants/countryCode.json";
import * as Yup from "yup";
import { isEmail } from "../../../utils/isEmail";
import {
  auth,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "../../../config/firebase";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { GoogleLogin } from "../../socialMedia/GoogleLogin";
// import MetaMaskModal from "../../socialMedia/MetamaskModal";
import { useAuthModal } from "../../../hooks/useAuthModal";
import TelegramComp from "../../socialMedia/TelegramLoginButton";
import TelegramInputModal from "../../socialMedia/TelegramInputModal";
import { useDispatch, useSelector } from "react-redux";
import { getAPI, getAPIAuth, postAPI } from "../../../service/apiInstance";
import { Spinner } from "react-bootstrap";
import { ADMIN_AUTH_ACCESS, ADMIN_FRONTEND_LOGGED_IN_ID, ADMIN_LOGGED_IN_ID, STOCK_USER_ID } from "../../../constants";
import { USER } from "../../../Admin/AdminConstants/apiEndpoints";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { adminLogIn } from "../../../store/action";
import { useAuth } from "../../../hooks/useAuth";

const validationSchema = Yup.object({
  // emailOrNumber: Yup.string()
  //   .required("This field is required")
  //   .matches(
  //     /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
  //     "Invalid email or mobile number"
  //   ),
  username: Yup.string().required(),
  password: Yup.string().required()

});

let preWidgetId;

const LoginEmailOrNo = ({
  setEmailPassword,
  setLogOn,
  setPhoneOtp,
  setEmailOrNumber,
  setForgetpass,
  logOn,
}) => {
  const [select, setSelect] = useState("");
  // const [isMetamask, setIsMetamask] = useState(false)
  const [isMetamask, setIsMetamask] = useState(false);
  const { authModalObject, setAuthModalObject } = useAuthModal();
  const [isTelegram, setIsTelegram] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [preWidgetId, setPreWidgetId] = useState(null);
  const [matchedItem, setmatchedItem] = useState([]);
  const [countrycodedata, setCountryCode] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const recaptchaWrapperRef = useRef()
  const [isHidden, setIsHidden] = useState(true)
  const adminToken = useLocalStorage(ADMIN_AUTH_ACCESS, "")
  const adminID = useLocalStorage(ADMIN_FRONTEND_LOGGED_IN_ID, "")
  const { setToken } = useAuth();
  const dispatch = useDispatch();
  const [demoDetails, setDemoDetails] = useState({})
  const ipAddress = useSelector(state => state.ipAddress)
  const country = useSelector(state => state.country)
  // console.log("demoDetails", demoDetails);

  useEffect(() => {
    if (!logOn) {
      setIsLoading(false);
    }
  }, [logOn]);

  const loginHandler = async (values) => {
    // console.log('values', values)

    const payload = {
      username: values.username,
      password: values.password,
      ipAddress: ipAddress,
      country: country
    }
    try {
      const res = await postAPI(USER.login, payload)
      console.log('qwwwwwresddddddd', res)
      if (res?.status === 200) {

        if (res?.data?.data?.data?.role === "user") {
          dispatch(adminLogIn(res.data))
          setToken(res.data.data.token);
          localStorage.setItem(STOCK_USER_ID, res.data.data.data.stockUserId)
          localStorage.setItem(ADMIN_FRONTEND_LOGGED_IN_ID, res?.data?.data?.data?._id)
          sessionStorage.setItem("DemoSessionId", `${values?.DemoSessionId ? true : false}`);
          setAuthModalObject(pre => ({ ...pre, isAuthOpen: false, }))
          succesToaster('User Login Successfully')
        } else {
          errorToaster("Please login using different credentials")
        }
      } else {
        errorToaster(res?.data?.data?.message)
      }
    } catch (error) {
      // console.log(error.message)
      errorToaster(error?.response?.data?.data?.message)
    } finally {
      setIsLoading(false)
    }
  }

  // get demo login details
  const getDemoLoginDetails = async () => {
    try {
      const res = await getAPI('user/getDemoUser')
      if (res?.data?.data?.success) {
        console.log(
          "resresresres", res
        );
        setDemoDetails(res?.data?.data?.demoDetails)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getDemoLoginDetails()
  }, [])

  return (
    <>
      <Formik
        initialValues={{
          username: "",
          password: ""
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsLoading(true);
          loginHandler(values)
          // onSubmit(values);

        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => (
          <form>
            <div className="row gap-3 gx-0">

              <div className="col-12 inputdata">
                {/* username */}
                <label htmlFor="text" className="inputLabel">
                  USERNAME
                  <span>*</span>
                </label>
                <input
                  type="text"
                  id="text"
                  className="form-control inputlogin border-0 shadow-none"
                  placeholder="Username"
                  value={values.username}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="username"

                />
                <ErrorMessage>
                  {errors.username && touched.username && errors.username}
                </ErrorMessage>
              </div>

              <div className="col-12 inputdata passwordInput pt-0">
                <label
                  htmlFor="password1"
                  className="inputLabel"
                >
                  PASSWORD
                  <span>*</span>
                </label>
                <div className="position-relative passwordGroup">
                  <input
                    type={`${isHidden ? "password" : "text"}`}
                    id="password1"
                    className="form-control inputlogin border-0 shadow-none"
                    placeholder="Enter your password"
                    name="password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {
                    isHidden ? (
                      <img onClick={() => setIsHidden(false)} src="assets/img/newIcon/eyeclose.svg" className="eye active  open cursor-pointer" alt="" />
                    ) : (
                      <img onClick={() => setIsHidden(true)} src="assets/img/newIcon/eyeopen.svg" className="eye active close cursor-pointer" alt="" />
                    )
                  }
                </div>
                <ErrorMessage>
                  {errors.password && touched.password && errors.password}
                </ErrorMessage>
              </div>
            </div>
            {/* <div id="recaptcha-container" ref={recaptchaWrapperRef} /> */}
            <div id="recaptcha-parent"> </div>
            <div className="row mt-4 gap-4">
              <div className="col-12 mx-auto">
                <span className="playBtn position-relative d-flex align-items-center justify-content-center cursor-pointer">
                  <button
                    type="submit"
                    className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"

                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <Spinner size="sm" color="dark" />
                    ) : (
                      "Login"
                    )}
                  </button>
                </span>
              </div>
              <div className="col-12 mx-auto">
                <span className="playBtn position-relative d-flex align-items-center justify-content-center cursor-pointer">
                  <button
                    type="button"
                    className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"
                    onClick={() => {
                      // setFieldValue('username', demoDetails?.username)
                      // setFieldValue("password", demoDetails?.password)
                      loginHandler({ username: demoDetails?.username, password: demoDetails?.password, DemoSessionId: "true" })
                    }}
                  >
                    {/* {isLoading ? (
                      <Spinner size="sm" color="dark" />
                    ) : ( */}
                    Login with Demo ID
                    {/* )} */}
                  </button>
                </span>
              </div>
              {/* divider */}
              {/* <div className="col-12 mx-auto ">
                <div className="loginbutton text-center">
                  <span className="directLogin text-decoration-none ">
                    Log in directly with
                  </span>
                </div>
              </div> */}
              {/* social links */}
              {/* <div className="col-12 mx-auto">
                <div className="row align-items-center justify-content-center g-2">
                  <div className="col-auto">

                    <span className="text-decoration-none">
                      <GoogleLogin parent={"signIn"}>
                        <div className="link">
                          <img src="assets/img/newIcon/google.svg" alt="" />
                        </div>
                      </GoogleLogin>
                    </span>
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-decoration-none cursor-pointer"
                      onClick={() => setIsMetamask(true)}
                    >
                      <div className="link">
                        <img
                          src="assets/img/newIcon/metamask-grayscale-logo.svg"
                          alt=""
                        />
                      </div>
                    </span>
                  </div>

                  {authModalObject.isAuthOpen ? (
                    <div className="col-auto d-flex h-25 justify-content-center my-3">
                      <TelegramComp
                        setIsOpen={setIsTelegram}
                        userObj={userObj}
                        setUserObj={setUserObj}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div> */}
            </div>
          </form>
        )}
      </Formik>
      {/* <MetaMaskModal
        isOpen={isMetamask}
        setIsOpen={setIsMetamask}
        parent={"signIn"}
      /> */}
      <TelegramInputModal
        isOpen={isTelegram}
        setIsOpen={setIsTelegram}
        userObj={userObj}
        setUserObj={setUserObj}
      />
    </>
  );
};

export default LoginEmailOrNo;





// const onSubmit = (values) => {
//   if (isEmail(values.emailOrNumber)) {
//     setEmailPassword(true);
//     setLogOn(false);
//     setEmailOrNumber(values.emailOrNumber);
//     setIsLoading(false);
//   } else {
//     // if (select == 0) {
//     //   errorToaster("Choose a country code");
//     //   setIsLoading(false);

//     //   return;
//     // }
//     handleSend(values);
//     setEmailOrNumber(values.emailOrNumber);
//   }
// };
// const generateRecaptcha = (random) => {
//   // if (preWidgetId != null || preWidgetId != undefined) {
//   //   window.recaptchaVerifier.recaptcha.reset(preWidgetId); //remove previous captcha
//   //   // }
//   // if (window.recaptchaVerifier) {
//   //   window.recaptchaVerifier.clear();
//   // }
//   // console.log('recaptchaWidgetId',window.recaptchaWidgetId,window?.recaptchaVerifier?.recaptcha)
//   // if (window.recaptchaWidgetId || window.recaptchaWidgetId === 0) {
//   //   console.log("widgetId", 'if', window.recaptchaWidgetId, window?.recaptchaVerifier?.recaptcha)
//   //   window?.recaptchaVerifier?.recaptcha?.reset(window.recaptchaWidgetId)
//   // }
//   // --------------------------------------------------------------------
//   // if ((window.recaptchaVerifier || window.recaptchaWidgetId === 0) && recaptchaWrapperRef) {
//   //   window?.recaptchaVerifier?.recaptcha?.reset(window.recaptchaWidgetId)
//   //   recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
//   // }
//   const recaptchaParent = document.getElementById("recaptcha-parent");
//   if (recaptchaParent) {
//     recaptchaParent.innerHTML = `<span id=${random} />`
//   }
//   window.recaptchaVerifier = new RecaptchaVerifier(
//     // "recaptcha-container",
//     `${random}`,
//     {
//       size: "invisible",
//       callback: (response) => { },
//     },
//     auth
//   );
//   window.recaptchaVerifier.render().then((widgetId) => {
//     console.log("widgetId", widgetId)
//     window.recaptchaWidgetId = widgetId;
//   });
//   // window.recaptchaVerifier
//   //   .render()
//   //   .then((widgetId) => {
//   //     // preWidgetId =
//   //     setPreWidgetId(widgetId);
//   //   })
//   //   .catch((err) => console.log(err));
//   // ---------------------------------------------------------
//   // if (!window.recaptchaverifier) {
//   //   window.recaptchaVerifier = new RecaptchaVerifier(
//   //     "recaptcha-container",
//   //     {
//   //       size: "invisible",
//   //     },
//   //     auth
//   //   );
//   // // }

//   // window.recaptchaverifier.render().then((widgetId) => {

//   // });
// };