import React from "react";
import { dateFormatter } from "../../AdminUtils/Utils";

const GRStage3Table = ({ setSelectedMarket,data }) => {
  return (
    <div className="col-12">
      <div className="table-responsive">
        <table className="table">
          <thead className="text-uppercase text-nowrap">
            <tr>
              <th>
                <div>Settlement Date</div>
              </th>
              <th>
                <div>Market</div>
              </th>
              <th>
                <div>Bets</div>
              </th>
              <th>
                <div>Won</div>
              </th>
              <th>
                <div>Lost</div>
              </th>
              <th>
                <div>Profit/Loss</div>
              </th>
              <th>
                <div>Opening Bal.</div>
              </th>
              <th>
                <div>Closing Bal.</div>
              </th>
              <th>
                <div>Result</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr>
                <td>{dateFormatter(item?.settlementDate,true)}</td>
                <td className="fw-bold cursor-pointer" onClick={()=>setSelectedMarket({name:item?.marketName,id:item?._id})} >{item?.marketName}</td>
                <td>{item?.betCount}</td>
                <td>{item?.totalWinCount}</td>
                <td>{item?.totalLossCount}</td>
                <td
                  className={
                    item?.totalAmountWon - item?.totalAmountLost > 0
                      ? "admin-forms-success-text"
                      : "admin-forms-error-text"
                  }
                >
                  {item?.totalAmountWon - item?.totalAmountLost > 0
                    ? `+${Number(item?.totalAmountWon - item?.totalAmountLost).toFixed(2)}`
                    : Number(item?.totalAmountWon - item?.totalAmountLost).toFixed(2)}
                </td>
                <td>{item?.opening}</td>
                <td>{item?.closing}</td>
                <td>{item?.result}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GRStage3Table;
