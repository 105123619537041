import React, { useEffect } from 'react'
import { useState } from 'react'
import Contest from './Contest'
import MyContest from './MyContest'
import MyTeam from './MyTeam'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ROUTES_CONST } from '../../../../constants/routeConstant'
import { getFantasyAuth } from '../../../fantasyServices'
import { useDispatch, useSelector } from 'react-redux'
import { addJoinedContest, addTeamList } from '../../../../store/action'

const Normal = () => {
  const [activeTab, setActiveTab] = useState('contest')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const myTeam = useSelector(state => state.myTeamData)
  const joined = useSelector(state => state.joinedContest)
  const [joinedContest, setJoinedContest] = useState([])
  const [contests, setContests] = useState([])
  const [joinedTeams, setJoinedTeams] = useState([])
  const data = useLocation()
  // console.log({ contests });
  const [joinContestLoading, setJoinContestLoading] = useState(true)
  const [upcomingLoader, setUpcomingLoading] = useState(true)
  const [teamLoader, setTeamLoading] = useState(true)
  // console.log("data", "by location in normal", data)
  const getJoinedContestData = async () => {
    try {
      const res = await getFantasyAuth(`myjoinedcontests?matchkey=${id}`);
      if (res.data.success) {
        dispatch(addJoinedContest(res.data.data))
        setJoinedContest(res.data.data)
      }
    } catch (error) {

    } finally {
      setJoinContestLoading(false)
    }
  };


  const getContests = async () => {
    // console.log("id--->>>>>", id)
    try {
      const res = await getFantasyAuth(`getAllNewContests?matchkey=${id}`);
      if (res.data.success) {
        setContests(res.data.data)
      }
    } catch (error) {

    } finally {
      setUpcomingLoading(false)
    }
  };

  const getJoinedTeamData = async () => {
    try {
      const res = await getFantasyAuth(`getMyTeams?matchkey=${id}`);
      if (res.data.success) {
        dispatch(addTeamList(res.data.data))
        setJoinedTeams(res.data.data)
      }
    } catch (error) {
      console.log("error", error)
    } finally {
      setTeamLoading(false)
    }
  };
  useEffect(() => {
    if (id) {
      getJoinedContestData()
      getContests()
      getJoinedTeamData()
    }
  }, [id])
  return (
    <>
      <div className="row align-items-center justify-content-between contestOptionsTabInner">
        <div className="col-4">
          <div
            onClick={() => setActiveTab('contest')}
            className={`contestOptionTab text-center text-uppercase ${activeTab === 'contest' ? 'active' : ''}`}
          >
            Contest
          </div>
        </div>
        <div className="col-4">
          <div
            onClick={() => setActiveTab('myContest')}
            className={`contestOptionTab text-center text-uppercase ${activeTab === 'myContest' ? 'active' : ''}`}
          >
            My Contest ({joined ? joined?.length : 0})
          </div>
        </div>
        <div className="col-4">
          <div
            onClick={() => setActiveTab('myTeam')}
            className={`contestOptionTab text-center text-uppercase ${activeTab === 'myTeam' ? 'active' : ''}`}
          >
            My Teams ({myTeam ? myTeam?.length : 0})
          </div>
        </div>
      </div>
      {
        activeTab === 'contest' ? (
          <Contest
            upcomingLoader={upcomingLoader}
            contests={contests}
            match={data.state}
            getContests={getContests}
          />
        ) :
          activeTab === 'myContest' ? (
            <MyContest
              joinContestLoading={joinContestLoading}
              joinedContest={joinedContest}
              match={data.state}
            />
          ) :
            activeTab === 'myTeam' ? (
              <MyTeam
                contests={contests}
                match={data.state}
                joinedTeams={joinedTeams}
                id={id}
                teamLoader={teamLoader}
              />
            ) : ''
      }
      <button
        className='createTeamBtn'
        onClick={() => navigate(`${ROUTES_CONST?.CREATE_TEAM}/${id}`, {
          state: {
            data: data.state,
            contest: contests.length ? contests[0].contest[0] : ''
          },
        })}
      >
        Create Team {myTeam ? myTeam.length + 1 : 1}
      </button>
    </>
  )
}

export default Normal