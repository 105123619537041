import React, { useEffect } from 'react'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import OptionTab from './OptionTab'
import { FANTASY_TOKEN_NAME } from '../../../constants'

const FantasyHome = () => {
  // useEffect(() => {
  //   localStorage.setItem(FANTASY_TOKEN_NAME, "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTM5ZmU2NmJhMDFjNDNlOTYyMzQ1OTMiLCJuYW1lIjoiSm9uIERvZSIsInJvbGUiOiJ1c2VyIiwiX2lkIjoiNjUzOWZlNjZiYTAxYzQzZTk2MjM0NTkzIiwiaWF0IjoxNjk5NTE0MDQ5LCJleHAiOjE3MDIxMDYwNDl9.wKWhX_H1EayFeqxsZUgC83fn-XAJLFbDgP_GVNQ-D0Q")
  // }, [])
  return (
    <>
      <div className="row fantasyInner">
        <FantasyHeader />
        <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
          <div className="row mx-0">
            <OptionTab />
          </div>
        </div>
        <FantasyBottomBar />
      </div>
    </>
  )
}

export default FantasyHome