import React, { useEffect } from "react";
import { GoogleLogin } from "../../socialMedia/GoogleLogin";
import { useSignInModal } from "../../../hooks/useSignInModal";
import { useSignUpModal } from "../../../hooks/useSignUpModal";
import EmailOtp from "./emailOtp";
import { useState } from "react";
import { postAPI } from "../../../service/apiInstance";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { auth, sendPasswordResetEmail } from "../../../config/firebase";

function Emailverify({ setForgetpass, setOtpOpen, setOpenpass, setLogOn }) {
  const [otp, setOtp] = useState(false);
  const { isSignInOpen, setIsSignInOpen } = useSignInModal();
  const { isSignUpOpen, setIsSignUpOpen } = useSignUpModal();
  const [emailVerify, setEmailVerify] = useState("");
  const [emailErr, setEmailErr] = useState("");
  let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


  useEffect(() => {
    if (!isSignInOpen) {
      setForgetpass(false)
      setOtpOpen(false)
      setLogOn(true)
    }
  }, [isSignInOpen])

  const handleChange = (event) => {
    const val = event.target.value;
    setEmailVerify(val);
    if (val.length === 0) {
      setEmailErr("");
    } else if (emailPattern.test(emailVerify)) {
      setEmailErr("");
    } else {
      setEmailErr("");
    }
  };

  const handle = async () => {
    if (emailVerify.length === 0) {
      setEmailErr("please enter your email!");
      return;
    } else if (emailPattern.test(emailVerify)) {
      setEmailErr("");
    } else {
      return setEmailErr("Email is invalid");
    }
    try {
      const res = await sendPasswordResetEmail(auth, emailVerify);
      succesToaster('Link sent Succesfully!!')
    } catch (error) {
      console.error("Error occurred during password reset:", error);
    }
  };
  const handleLogin = () => {
    setLogOn(true);
    setForgetpass(false);
  };
  return (
    <>
      <div className="col-12">
        <input
          type="text"
          className="form-control border-0 shadow-none"
          id="emailOrNumber"
          placeholder="Email"
          value={emailVerify}
          name="emailOrNumber"
          onChange={(e) => handleChange(e)}
        />
        <p style={{ color: "red" }}>{emailErr}</p>
      </div>

      <div className="col-12 forget d-flex justify-content-end text-white">
        <span className="cursor-pointer" onClick={handleLogin}>
          login?
        </span>
      </div>
      <div className="col-12 mx-auto">
        <button
          type="button"
          className="btn ragisterBtn w-100 border-0"
          onClick={handle}
        >
          Send Link
        </button>
      </div>
      <div className="col-12">
        <div className="para1">
          New to DAMBLE ?{" "}
          <a
            href="javascript:;"
            // data-bs-toggle="modal"
            // data-bs-target="#exampleModal1"
            className="paraAnchor"
            onClick={() => {
              setIsSignInOpen(false);
              setIsSignUpOpen(true);
            }}
          >
            Create account
          </a>
        </div>
      </div>
      <div className="col-12">
        <div className="row align-items-center justify-content-center ">
          <span className="col-auto d-flex pe-0 cursor-pointer">
            <div className="link">
              {
                <GoogleLogin>
                  <img src="assets/img/link1.png" alt />
                </GoogleLogin>
              }
            </div>
          </span>
          <span className="col-auto d-flex pe-0 cursor-pointer">
            <div className="link">
              <img src="assets/img/link2.png" alt />
            </div>
          </span>
          <span className="col-auto d-flex cursor-pointer">
            <div className="link">
              <img src="assets/img/link3.png" alt />
              {/* {
                <MetaMasks>
                  <img src="assets/img/link3.png" alt />{" "}
                </MetaMasks>
              } */}
            </div>
          </span>
        </div>
      </div>
    </>
  );
}

export default Emailverify;
