import React, { useEffect, useState } from "react";
import AppFooter from "../../../components/AppFooter/AppFooter";
// import downArrow from "./../../TradingAssets/img/downarrowtable.svg";
// import redArrow from "./../../TradingAssets/img/redicon.svg";
// import greenArrow from "./../../TradingAssets/img/greenicon.svg";
// import closeIcon from "./../../TradingAssets/img/closeicon.svg";
// import searchIcon from "./../../TradingAssets/img/search.png";
// import plusAdd from "./../../TradingAssets/img/plus.svg";

import "./../../TradingAssets/scss/trading.scss";
import PendingComp from "./components/PendingComp";
import ExecutedComp from "./components/ExecutedComp";
import { postTradingAPI } from "../../TradingServices";
// import DambleUniverse from "./../../../pages/casinoPage/DambleUniverse";

const tabOption = {
  pending: "pending",
  executed: "executed"
}
const TradingOrders = () => {
  const [active, setActive] = useState(tabOption.pending)
  const [orderList, setOrderList] = useState([])
  const [executedData, setExecutedData] = useState([])
  const [pendingData, setPendingData] = useState([])
  const [loader, setLoader] = useState(true)

  const getOrderList = async (e) => {
    try {
      const body = {
        action: "order-list",
      };
      // https://www.algoniti.com/learnotrade/server/order-list.php
      const res = await postTradingAPI("order-list.php", body);
      const response = await res.json();
      if (response?.res_status === '1') {
        setExecutedData(response?.data?.executed)
        setPendingData(response?.data?.pending)
      }

    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getOrderList()
  }, [])
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage tradingPages">
            {/* start here */}
            <div class="row gap-4">
              <div className="col-12">
                <ul className="list-unstyled d-flex align-items-center gap-2">
                  <li>
                    <button
                      onClick={() => {
                        setActive(tabOption.pending)
                      }}
                      className={`border-0 btn shadow-none navButton ${active === tabOption.pending ? "active" : ""}`}> Pending</button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setActive(tabOption.executed)
                      }}
                      // className="border-0 btn shadow-none navButton"
                      className={`border-0 btn shadow-none navButton ${active === tabOption.executed ? "active" : ""}`}
                    >Executed </button>
                  </li>
                </ul>
              </div>

            </div>
            <div class="row mx-0">
              {
                tabOption.pending === active ? (
                  <PendingComp
                  pendingData={pendingData}
                    loader={loader}
                  />
                ) : (
                  <></>
                )
              }
              {
                tabOption.executed === active ? (
                  <ExecutedComp
                    executedData={executedData}
                    loader={loader}
                  />
                ) : (
                  <></>
                )
              }
            </div>
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  );
};

export default TradingOrders;
