import React, { useEffect, useState } from "react";
import AppFooter from "../../../components/AppFooter/AppFooter";
// import downArrow from "./../../TradingAssets/img/downarrowtable.svg";
import searchIcon from "./../../TradingAssets/img/search.png";
import plusAdd from "./../../TradingAssets/img/plus.svg";
// import tabletop from "./../../TradingAssets/img/tabletop.svg";
// import tabledown from "./../../TradingAssets/img/tabledown.svg";
import "./../../TradingAssets/scss/trading.scss";
import { postTradingAPI } from "../../TradingServices";
import { Spinner } from "react-bootstrap";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import WatchListItemComponent from "./WatchListItemComponent";
import TradingModal from "./TradingModal";

const TradingWatchlist = () => {
  const [marketWatchData, setMarketWatchData] = useState([]);
  const [marketLoading, setMarketLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalInputValues, setModalInputValues] = useState({
    buy_sell: "BUY",
    quantity: "",
    mis_cnc: "CNC",
    mkt_lmt_sl: "MARKET",
    enter_trigger_price: "",
    lot_size: "1",
  });
  const [exFilter,setExFilter]=useState('All')
  const [ filteredMarketData,setFilteredMarketData ]=useState([])

  // get market list data
  const getMarketWatchData = async () => {
    setMarketLoading(true);
    try {
      const body = {
        action: "list-of-watchlist",
        watch_list_number: "1",
      };
      const res = await postTradingAPI("list-of-watchlist.php", body);
      const response = await res.json();
      setMarketWatchData(response.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setMarketLoading(false);
    }
  };

  // get search data
  const getSearchData = async () => {
    setSearchLoading(true);
    try {
      const body = {
        search_txt: searchQuery,
        action: "search-in-watchlist",
      };
      if (searchQuery?.length > 0) {
        const res = await postTradingAPI("search-in-watchlist.php", body);
        const response = await res.json();
        setSearchData(response.data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    const searchTimer = setTimeout(() => getSearchData(), 500);
    return () => clearTimeout(searchTimer);
    //eslint-disable-next-line
  }, [searchQuery]);

  // useEffect(() => {
  //   getSearchData();
  //   //eslint-disable-next-line
  // }, [searchQuery]);

  useEffect(() => {
    getMarketWatchData();
  }, []);

  const handleClickSearchItem = (item) => {
    if (
      marketWatchData
        .map((el) => el?.instrument_token)
        .includes(item?.instrument_token)
    ) {
      handleRemoveSearchItem(item);
    } else {
      handleAddToList(item);
    }
  };

  const handleAddToList = async (item) => {
    try {
      const body = {
        symbol_instrument_token: item?.instrument_token,
        watch_list_number: "1",
        action: "add-in-watchlist",
      };
      const res = await postTradingAPI("add-in-watchlist.php", body);
      const response = await res.json();
      if (response.res_msg === "SUCCESS") {
        succesToaster("Successfully added to the list!");
        getMarketWatchData();
        getSearchData();
      } else {
        errorToaster("Something went wrong!");
      }
    } catch (error) {
      console.log("error", error);
      errorToaster("Something went wrong!");
    }
  };

  const handleRemoveSearchItem = async (item) => {
    try {
      const body = {
        symbol_instrument_token: item?.instrument_token,
        watch_list_number: "1",
        action: "remove-in-watchlist",
      };
      const res = await postTradingAPI("remove-in-watchlist.php", body);
      const response = await res.json();
      console.log("added", response);
      if (response.res_msg === "SUCCESS") {
        succesToaster("Successfully removed from the list!");
        getMarketWatchData();
        getSearchData();
      } else {
        errorToaster("Something went wrong!");
      }
    } catch (error) {
      console.log("error", error);
      errorToaster("Something went wrong!");
    }
  };

  const handleRedCross = async (item) => {
    try {
      const body = {
        symbol_instrument_token: item?.instrument_token,
        watch_list_number: "1",
        action: "delete-from-watchlist",
      };
      const res = await postTradingAPI("delete-from-watchlist.php", body);
      const response = await res.json();
      if (response.res_msg === "SUCCESS") {
        succesToaster("Successfully removed from the list!");
        getMarketWatchData();
      } else {
        errorToaster("Something went wrong!");
      }
    } catch (error) {
      console.log("error", error);
      errorToaster("Something went wrong!");
    }
  };

  useEffect(()=>{
    if (exFilter!=="All") {
      const filteredData = marketWatchData?.filter(
        (item) => item?.exchnage === exFilter
      );
      setFilteredMarketData(filteredData);
    }else{
      setFilteredMarketData(marketWatchData)
    }
  },[exFilter,marketWatchData])

  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage tradingPages">
            {/* start here */}
            <div className="row mx-0">
              <div className="col-12">
                <div className="row mt-3 d-md-none">
                  <div className="col-12 ">
                        <div className="tradingSecHead">Marketwatch</div>
                  </div>
                </div>
                <div className="row gap-3 mt-1 ">
                  <div className="col px-0">
                    <div className="row g-3 align-items-center ">
                      <div className="col d-none d-md-block">
                        <div className="tradingSecHead">Marketwatch</div>
                      </div>
                      <div className="col-lg-4 col">
                        <div className="input-group w-100 searchDrop rounded-2 inputGroup">
                          <span
                            className="input-group-text  shadow-none border-0 bg-transparent searchIcon"
                            id="basic-addon2"
                          >
                            <img
                              src={searchIcon}
                              className="img-fluid w-100 h-100"
                              alt="table accordion"
                            />
                          </span>
                          <input
                            type="search"
                            className="form-control ps-0 py-0 searchInput m-0 border-0 bg-transparent shadow-none"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                          {searchQuery?.length > 0 ? (
                            <>
                              <div className="tradingSearchBox">
                                {!searchLoading ? (
                                  searchData?.length > 0 ? (
                                    searchData?.map((item) => (
                                      <div
                                        className="row mx-0 align-items-center tradingSearchItem"
                                        key={item?.instrument_token}
                                      >
                                        <div className="col">
                                          <div className="d-flex align-items-center">
                                            <div className="exchangeName">
                                              {item?.exchange}
                                            </div>
                                            <div className="tradeName">
                                              <div className="title">
                                                {item?.tradingsymbol}
                                              </div>
                                              <div className="subtitle">
                                                {item?.trading_name}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="col-auto tradeAddImg"
                                          onClick={() =>
                                            handleClickSearchItem(item)
                                          }
                                        >
                                          {!item?.watch_list_number ? (
                                            <img src={plusAdd} alt="" />
                                          ) : (
                                            <i
                                              className="bi bi-trash3-fill"
                                              style={{
                                                color: "white",
                                              }}
                                            ></i>
                                          )}
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <>
                                      <div className="row mx-0 align-items-center tradingSearchItem">
                                        <div className="col-12 text-center py-2">
                                          <div className="tradeName">
                                            <div className="title">
                                              No data available
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                ) : (
                                  <>
                                    <div className="row mx-0 align-items-center tradingSearchItem">
                                      <div className="col-12 text-center py-2">
                                        <Spinner
                                          animation="border"
                                          variant="light"
                                          size="sm"
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div 
                                onClick={()=>setSearchQuery('')}
                                className="tradingSearchBoxLayer vh-100 vw-100 position-fixed top-0 start-0"></div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 px-0">
                    <div className=" socialmedia">
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {exFilter}
                        </button>
                        <ul className="dropdown-menu dropdownMenu w-100">
                        <li
                            className={`menuLinkDrop ${
                              exFilter === "All" ? "active" : ""
                            }`}
                            onClick={() =>
                              setExFilter("All")
                            }
                          >
                            All
                          </li>
                          <li
                            className={`menuLinkDrop ${
                              exFilter === "NSE" ? "active" : ""
                            }`}
                            onClick={() =>
                              setExFilter("NSE")
                            }
                          >
                            NSE
                          </li>
                          <li
                            className={`menuLinkDrop ${
                              exFilter === "BSE" ? "active" : ""
                            }`}
                            onClick={() =>
                              setExFilter("BSE")
                            }
                          >
                            BSE
                          </li>
                          <li
                            className={`menuLinkDrop ${
                              exFilter === "NFO" ? "active" : ""
                            }`}
                            onClick={() =>
                              setExFilter("NFO")
                            }
                          >
                            NFO
                          </li>
                          <li
                            className={`menuLinkDrop ${
                              exFilter === "MCX" ? "active" : ""
                            }`}
                            onClick={() =>
                              setExFilter("MCX")
                            }
                          >
                            MCX
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 my-4">
              <div className="tableSectionMain tableCard ">
                <div className="row">
                  <div className="col-12">
                    <ul className="mb-0 list-unstyled d-flex align-items-center tableHeader flex-nowrap">
                      <li className="tableTh firstdiv d-flex align-items-center">
                        <button className="accordion-button border-0 shadow-none d-flex align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <span className="d-inline-flex align-items-center accordionbtn">
                        <img src={downArrow} className="img-fluid w-100 h-100 " alt="table accordion"/>
                        </span>
                        </button>
                        NSEFUT SYM
                      </li>
                      <li className="tableTh d-flex align-items-center">
                      BID RATE
                      </li>
                      <li className="tableTh d-flex align-items-center">
                      ASK RATE
                      </li>
                      <li className="tableTh d-flex align-items-center ">
                      LTP
                      </li>
                      <li className="tableTh d-flex align-items-center ">
                      CHANGE %
                      </li>
                      <li className="tableTh d-flex align-items-center">
                      NET CHANGE
                      </li>
                      <li className="tableTh d-flex align-items-center">
                      HIGH
                      </li>
                      <li className="tableTh d-flex align-items-center">
                      LOW
                      </li>
                      <li className="tableTh d-flex align-items-center">
                      OPEN
                      </li>
                      <li className="tableTh d-flex align-items-center">
                      CLOSE
                      </li>
                      <li className="tableTh d-flex align-items-center">
                      REMOVE
                      </li>
                    </ul>
                  </div>
                  <div className="col-12">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item border-0  shadow-none bg-transparent ">
                        
                          <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div className="accordion-body p-0">
                             <ul className="mb-0 d-flex align-items-center accordinItems p-0">
                              <li className="d-flex align-items-center tbThData firstdiv">
                                NIFTY 30NOV2023
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData greenBackGround">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData redBackGround">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData greenBackGround">
                              0.49	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                 <div className="d-flex align-items-center me-1">
                                    <div className="redIcon d-inline-flex align-items-center">
                                    <img src={greenArrow} className="img-fluid w-100 h-100 " alt="table accordion"/>
                                    </div>
                                    <div className="redIcon d-none">
                                    <img src={redArrow} className="img-fluid w-100 h-100 " alt="table accordion"/>
                                    </div>
                                 </div> 96.70
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                    <a href="javascript:;" type='button' className=" d-flex align-items-center border-0 bg-transparent shadow-none">
                                    <span className="d-inline-flex align-items-center justiify-content-center closeIconTable">
                                      <img src={closeIcon} className="img-fluid w-100 h-100 " alt="table accordion"/>
                                    </span>
                                    </a>
                              </li>
                             </ul>
                             <ul className="mb-0 d-flex align-items-center accordinItems p-0">
                              <li className="d-flex align-items-center tbThData firstdiv">
                                NIFTY 30NOV2023
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                              0.49	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                 <div className="d-flex align-items-center me-1">
                                    <div className="redIcon d-inline-flex align-items-center">
                                    <img src={greenArrow} className="img-fluid w-100 h-100 " alt="table accordion"/>
                                    </div>
                                    <div className="redIcon d-none">
                                    <img src={redArrow} className="img-fluid w-100 h-100 " alt="table accordion"/>
                                    </div>
                                 </div> 96.70
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                    <a href="javascript:;" type='button' className=" d-flex align-items-center border-0 bg-transparent shadow-none">
                                    <span className="d-inline-flex align-items-center justiify-content-center closeIconTable">
                                      <img src={closeIcon} className="img-fluid w-100 h-100 " alt="table accordion"/>

                                    </span>
                                    </a>
                              </li>

                             </ul>
                             <ul className="mb-0 d-flex align-items-center accordinItems p-0">
                              <li className="d-flex align-items-center tbThData firstdiv">
                                NIFTY 30NOV2023
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                              0.49	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                 <div className="d-flex align-items-center me-1">
                                    <div className="redIcon d-inline-flex align-items-center">
                                    <img src={greenArrow} className="img-fluid w-100 h-100 " alt="table accordion"/>
                                    </div>
                                    <div className="redIcon d-none">
                                    <img src={redArrow} className="img-fluid w-100 h-100 " alt="table accordion"/>
                                    </div>
                                 </div> 96.70
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                19,331.70	
                              </li>
                              <li className="d-flex align-items-center tbThData">
                                    <a href="javascript:;" type='button' className=" d-flex align-items-center border-0 bg-transparent shadow-none">
                                    <span className="d-inline-flex align-items-center justiify-content-center closeIconTable">
                                      <img src={closeIcon} className="img-fluid w-100 h-100 " alt="table accordion"/>

                                    </span>
                                    </a>
                              </li>

                             </ul>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

              <div className="col-12 px-0 mt-4">
                <div className="tradingWatchListTable">
                  <div>
                    <div className="tableTr">
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          Sr No.
                        </div>
                      </div>
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          Trading Name
                        </div>
                      </div>
                      {/* <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          Trading Symbol
                        </div>
                      </div> */}
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          Bid Rate
                        </div>
                      </div>
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          Ask Rate
                        </div>
                      </div>
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          ltp
                        </div>
                      </div>
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          Exchange
                        </div>
                      </div>
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          net change
                        </div>
                      </div>
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          change %
                        </div>
                      </div>
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          High
                        </div>
                      </div>
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          Low
                        </div>
                      </div>
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          Open
                        </div>
                      </div>
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          Close
                        </div>
                      </div>
                      <div className="tableTh">
                        <div className="tabletxt d-flex align-items-center">
                          Cancel
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    {!marketLoading
                      ? filteredMarketData?.length > 0
                        ? filteredMarketData?.map((item, index) => (
                            <WatchListItemComponent
                              data={item}
                              index={index}
                              showModal={showModal}
                              handleRedCross={handleRedCross}
                              setShowModal={setShowModal}
                              setModalData={setModalData}
                            />
                          ))
                        : "No Data"
                      : "loading"}
                  </div>
                </div>
              </div>
            </div>
            {/* end here */}
          </div>
        </div>
        {/*  **** Footer section *** */}
        <AppFooter />
      </main>
      <TradingModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalInputValues={modalInputValues}
        modalData={modalData}
        setModalInputValues={setModalInputValues}
      />
    </>
  );
};

export default TradingWatchlist;
