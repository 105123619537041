import React, { useState } from "react";
import { adminPostAPIAuth } from "../../AdminServices";
import { errorToaster, succesToaster } from "../../../utils/toaster";

const AddBankDetails = ({ getPaymentDetails }) => {
  const initialValue = {
    type: "Bank Transfer",
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    accHolderName: "",
    status: "",
  };
  const [addTableData, setAddTableData] = useState(initialValue);
  const [validationError, setValidationError] = useState(false)

  const addPaymentDetails = async (e) => {
    e.preventDefault();
    if (addTableData.bankName === '' || addTableData.accountNumber === '' || addTableData.ifscCode === '' || addTableData.accHolderName === '' || addTableData.status === '') {
      setValidationError(true)
    } else {
      try {
        const formData = new FormData();
        formData.append("typename", "userPaymentDetails");
        formData.append("flag", addTableData.type);
        formData.append("AccountNumber", addTableData.accountNumber);
        formData.append("bankName", addTableData.bankName);
        formData.append("ifscCode", addTableData.ifscCode);
        formData.append("accHolderName", addTableData.accHolderName);
        const res = await adminPostAPIAuth("user/createUserPaymentDetails", formData);
        if (res?.data?.data?.data) {
          setAddTableData(res?.data?.data?.data);
          getPaymentDetails();
          succesToaster("Bank Details Successfully Added")
          setValidationError(false)
        }
        setAddTableData(initialValue);
      } catch (error) {
        errorToaster(error?.response?.data?.message)
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddTableData({ ...addTableData, [name]: value });
  };

  return (
    <div>
      <button
        className="btn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        Add
      </button>

      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Offcanvas right
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <form onSubmit={addPaymentDetails}>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Type<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="type"
                className="form-control"
                id="exampleInputEmail1"
                value={addTableData.type}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <h6>Editable Details</h6>
            <div className="mt-4 d-flex gap-2">
              <div>
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Bank Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="bankName"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={addTableData.bankName}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Account No.<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="accountNumber"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={addTableData.accountNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-4 d-flex gap-2">
              <div>
                <label htmlFor="exampleInputEmail1" className="form-label">
                  IFSC Code<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="ifscCode"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={addTableData.ifscCode}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Account Holder Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="accHolderName"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={addTableData.accHolderName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-4 d-flex gap-2">
              <div>
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Status<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="status"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={addTableData.status}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={`mt-2 ${validationError === true ? (addTableData.bankName === '' || addTableData.accountNumber === '' || addTableData.ifscCode === '' || addTableData.accHolderName === '' || addTableData.status === '') ? 'd-block' : 'd-none' : 'd-none' }`}>
              <span style={{color: 'red'}}>Fill all the fields</span>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-4"
              data-bs-dismiss={`${(addTableData.bankName === '' || addTableData.accountNumber === '' || addTableData.ifscCode === '' || addTableData.accHolderName === '' || addTableData.status === '') ? '' : "offcanvas"}`}
              aria-label={`${(addTableData.bankName === '' || addTableData.accountNumber === '' || addTableData.ifscCode === '' || addTableData.accHolderName === '' || addTableData.status === '') ? '' : "Close"}`}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddBankDetails;
