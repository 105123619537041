import React, { useEffect, useState } from 'react'
import reload from './../../AdminAssets/img/reload.svg'
import { adminGetAPIAuth } from '../../AdminServices';
import { REPORTS } from '../../AdminConstants/apiEndpoints';
import { errorToaster } from '../../../utils/toaster';
import { useAdminLogout } from '../../AdminUtils/Utils';
import { returnEndDate } from '../../../utils/returnEndDate';
import Pagination from '../../Admincomponents/pagination/Pagination';

const ProfitAndLosstatement = () => {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [filterValues, setFilterValues] = useState({
    transactions: "",
    startDate: "",
    endDate: "",
  });

    // set today's date as end date
    useEffect(()=> {
      const today = new Date()
      setFilterValues((prev)=>({
        ...prev,
        endDate: returnEndDate(today)
      }))
    },[])

  const logOut=useAdminLogout()

  const handleGetList = async (item) => {
    const queryParams = Object.entries(filterValues)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    try {
      const res = await adminGetAPIAuth(
        REPORTS?.profitlossreport + `?page=${currentPage}&perPage=10` + (queryParams?"&"+queryParams:"")
        // +
          // "id=" 
          // +
          // (selectedUser?._id ? selectedUser?._id : item?._id) +
          // (queryParams?"&"+queryParams:"")
      );
      if (res?.data?.data?.success) {
        setTableData(res?.data?.data?.data);
        setTotalPages(res?.data?.data?.pagination?.totalPages)
      } else {
        errorToaster("Something went wrong in fetching data");
      }
    } catch (error) {
      if (error?.response?.status===401) {
        logOut()
      }
      errorToaster(error?.response?.data?.data?.message);
      setTableData([]);
      console.log("error -------------->", error);
    }
  };
  
  useEffect(() => {
    // if (selectedUser?._id) {
      handleGetList();
    // }
    //eslint-disable-next-line
  }, [
    // selectedUser,
    filterValues.endDate,
    filterValues.startDate,
    filterValues.transactions,
    currentPage
  ]);

  const handleChange = (e) => {
    setFilterValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
    <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner ">
                
                <header className="page-title-bar justify-content-between row align-items-center">
                  <h1 className="page-title mb-0 text-nowrap"> Profit And Loss Statement </h1>
                </header>
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row align-items-end">
                      <div className="col-md-auto pe-md-0 mb-2 mb-md-0">
                        {/* <div className="adminSearchInput">
                          <input type="text" placeholder="Search User" className="form-control adminFilterInp"/>
                        </div> */}
                      </div>
                      <div className="col-md">
                        <div className="row justify-content-end mx-md-0">
                          {/* <div className="col-md-auto col-12 col-sm-4 pe-md-0 mb-2 mb-sm-0">
                            <label htmlFor="" className="form-label">Transactions</label>
                            <select name="" id="" className="form-select adminFilterInp">
                              <option value="">All</option>
                            </select>
                          </div> */}
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="" className="form-label">From</label>
                            <input type="date" id="startDate" name="startDate" value={filterValues.startDate} onChange={handleChange} className='form-control adminFilterInp' />
                          </div>
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="" className="form-label">To</label>
                            <input type="date" id="endDate" name="endDate" value={filterValues?.endDate} onChange={handleChange} className='form-control adminFilterInp' />
                          </div>
                          <div className="col-md-auto col-6 d-flex align-items-end col-sm-6 pe-md-0 mt-md-0 mt-3">
                            <button type="button" class="me-2 ms-auto btn btn-primary btnCustom1" onClick={() => handleGetList()}><img className='reloadImg' src={reload} alt="" />Refresh</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table">
                        <thead className='text-uppercase text-nowrap'>
                          <tr>
                            <th>
                              <div className="d-flex">
                                  Sport
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Market Name
                              </div>
                            </th>
                            <th>
                              <div className="d-flex">
                                Amount 
                              </div>
                            </th>
                          </tr>
                        </thead>
                        {tableData.length ? <tbody>
                          {
                            tableData?.map((item)=>(
                          <tr>
                            <td className="align-middle fw-medium text-capitalize">{item?.sport}</td>
                            <td className="align-middle fw-medium text-capitalize">{item?.type}</td>
                            <td className={`align-middle fw-medium ${item?.overallProfitLoss < 0 ? 'admin-forms-error-text' : 'admin-forms-success-text'}`}>{item?.overallProfitLoss}</td>
                          </tr>
                            ))
                          }
                        </tbody>:
                        <tbody>
                          <tr>
                            <td colSpan={3} className='text-center py-5'>
                              <b> No Data...</b>
                            </td>
                          </tr>
                        </tbody>
                        }
                      </table>
                    </div>
                  </div>
                  {
                    totalPages > 1 ?
                    <Pagination
                      totalPages={totalPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    /> : ''
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
    </div>
    </>
  )
}

export default ProfitAndLosstatement