import React, { useState, useRef, useEffect } from "react";
import { useSignInModal } from "../../hooks/useSignInModal";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { firebase, auth } from "../../config/firebase";
import { postAPI } from "../../service/apiInstance";
import { useBtAuth } from "../../hooks/useBtAuth";
import { useAuth } from "../../hooks/useAuth";
import { Spinner } from "react-bootstrap";
// import "./emailotp.css";
// import Forgetpassword from "./forgetpassword";

function PhoneOtp({ setPhoneOtp, setLogOn }) {
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [otpLoader, setOtpLoader] = useState(false);
    const inputRefs = useRef([]);
    const { btToken, setBtToken } = useBtAuth();
    const { token, setToken } = useAuth();
    const { isSignInOpen, setIsSignInOpen } = useSignInModal();

    useEffect(() => {
        if (!isSignInOpen) {
            setPhoneOtp(false)
            setLogOn(true)
        }
    }, [isSignInOpen])

    const handleOtpChange = (e, index) => {
        const updatedOtp = [...otp];
        updatedOtp[index] = e.target.value;
        setOtp(updatedOtp);

        if (e.target.value !== "" && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };
    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace" && index > 0 && event.target.value === "") {
            inputRefs.current[index - 1].focus();
        }
    };
    const handleKeyPress = (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    };

    const handleVerifyOtp = (event) => {
        const otpValue = otp.join("");
        if (otpValue.length === 6) {
            let confirmationResult = window.confirmationResult;
            confirmationResult?.confirm(otpValue).then((result) => {
                let user = result.user;
                const body = {
                    phone: user.phoneNumber,
                }
                postAPI("user/login-user", body).then((res) => {
                    if (res?.data?.success) {
                        succesToaster(res.data.message);
                        setBtToken(res?.data.data?.betByToken);
                        setToken(res?.data.data?.auth_token);
                        setLogOn(true)
                        setPhoneOtp(false)
                        setIsSignInOpen(false)
                        setOtpLoader(false)
                    } else {
                        errorToaster(res.data.message)
                        setOtpLoader(false)
                    }
                }).catch((err) => {
                    errorToaster("something went wrong")
                    setOtpLoader(false)
                });
                setOtp(["", "", "", "", "", ""])
                setOtpLoader(false)
            }).catch((error) => {
                setLogOn(true)
                setPhoneOtp(false)
                setOtp(["", "", "", "", "", ""])
                errorToaster("User couldn't sign in (bad verification code?)");
                setOtpLoader(false)
            });
        }
    };

    return (

        <>
            <div className="otp-container">
                {otp?.map((digit, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(event) => handleKeyDown(event, index)}
                        onKeyPress={handleKeyPress}
                        ref={(ref) => (inputRefs.current[index] = ref)}
                        className="otp-input"
                    />
                ))}
            </div>
            <button
                type="submit"
                className="btn ragisterBtn w-100 border-0"
                onClick={() => {
                    setOtpLoader(true)
                    handleVerifyOtp()
                }}
            >
               {
                otpLoader ? <Spinner size='sm' animation="border" variant="light" /> : 'Verify otp'
               }
            </button>
        </>

    );
}

export default PhoneOtp;
