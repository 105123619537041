import React from "react";
import { dateFormatter } from "../../AdminUtils/Utils";


const GRStage2Table = ({ setSelectedMatch,data,loader,setStage }) => {
  return (
    <div className="col-12">
      <div className="table-responsive">
        <table className="table">
          <thead className="text-uppercase text-nowrap">
            <tr>
              <th>
                <div>Match Date</div>
              </th>
              <th>
                <div>Match Name</div>
              </th>
              <th>
                <div>Markets Count</div>
              </th>
              <th>
                <div>Bets</div>
              </th>
              <th>
                <div>Lost</div>
              </th>
              <th>
                <div>Won</div>
              </th>
              <th>
                <div>Profit/Loss</div>
              </th>
            </tr>
          </thead>
          {!loader &&<tbody>
            {data.map((item) => (
              <tr>
                <td>{dateFormatter(item?.matchDate,true)}</td>
                <td className="fw-bold cursor-pointer" onClick={()=>{
                  setSelectedMatch({name:item?.matchName,id:item?.matchId})
                  setStage(3)
                  }} >{item?.matchName}</td>
                <td>{item?.marketCount}</td>
                <td>{item?.betCount}</td>
                <td>{item?.totalLoss}</td>
                <td>{item?.totalWins}</td>
                <td
                  className={
                    item?.totalAmountWon - item?.totalAmountLost > 0
                      ? "admin-forms-success-text"
                      : "admin-forms-error-text"
                  }
                >
                  {item?.totalAmountWon - item?.totalAmountLost > 0
                    ? `+${Number(item?.totalAmountWon - item?.totalAmountLost)?.toFixed(2)}`
                    : Number(item?.totalAmountWon - item?.totalAmountLost)?.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>}
        </table>
      </div>
    </div>
  );
};

export default GRStage2Table;
