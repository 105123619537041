import React from 'react'
import betSlipIcon from '../../sportsAssets/img/betslip.svg'
import NormalBets from './NormalBets'
import QuickBets from './QuickBets'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'

const BetSlipOffcanvas = (props) => {
  const [quickBetsActive, setQuickBetsActive] = useState(false)
  const { profit, betInputValue, changeBetInputValue, applyBet, betAmount, isApplyingBet, setIsApplyingBet, setBetAmount, betType, oddsType, setShowBet, betLoader } = props;
  return (
    // <div className={`betSlipOffcanvas ${quickBetsActive ? 'quickBetActive' : ''}`}>
    <div className={`betSlipOffcanvas ${''}`}>
      <div className="betSlipHeader">
        <div className="row mx-1 h-100 align-items-center">
          <div className="col betSlipHeading" >
            <img src={betSlipIcon} className='betSlipIcon me-1' alt="" />
            Betslip

            {
              betAmount ? <span className='ms-1'>1</span> : <></>
            }
          </div>
          <div className="col-auto d-lg-none text-white" onClick={() => setShowBet(false)}>
            <img src="assets/img/sidebar/close.png" className='cursor-pointer' style={{ height: 16 }} alt="" />
          </div>
          {/* <div className="col-auto">
            <input type="checkbox" id='quickBet' className='d-none quickBetInput'
              onChange={() => setQuickBetsActive(!quickBetsActive)} />
            <label htmlFor="quickBet" className='quickBetLabel'>
              <span className='imgSpan'>
                <svg class="bt3648" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path d="M18.3333 4.0434C18.1035 3.9464 17.8335 4.0164 17.6874 4.20939L7.09864 18.2085C6.98428 18.3605 6.96734 18.5605 7.0584 18.7285C7.14841 18.8935 7.33053 18.9995 7.5296 18.9995H15.3398L13.3661 27.3899C13.311 27.6219 13.4371 27.8589 13.6668 27.9559C13.8955 28.0549 14.1687 27.9819 14.3127 27.7899L24.9015 13.7908C25.0169 13.6398 25.0317 13.4388 24.9417 13.2718C24.8506 13.1038 24.6685 12.9998 24.4705 12.9998H16.6592L18.634 4.60836C18.6891 4.37638 18.562 4.14039 18.3333 4.0434Z" fill="currentColor">
                  </path>
                </svg>
              </span>
            </label>
          </div> */}
        </div>
      </div>
      <div className="betSlipBody position-relative">
        {/* {!quickBetsActive ? */}
        {/* <NormalBets /> : */}
        {
          // isBetOpen ? (
          true ? (
            <>
              <QuickBets
                profit={profit}
                betInputValue={betInputValue}
                changeBetInputValue={changeBetInputValue}
                applyBet={applyBet}
                betAmount={betAmount}
                isApplyingBet={isApplyingBet}
                setIsApplyingBet={setIsApplyingBet}
                setBetAmount={setBetAmount}
                betType={betType}
                oddsType={oddsType}
              />
              {
                betLoader ?
                  <div className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center"
                    style={{ backgroundColor: 'rgba(36, 41, 55 , 0.8)' }}>
                    <Spinner variant='light' />
                  </div>
                  : ''
              }
            </>
          ) : (
            <></>
          )
        }

        {/* } */}
      </div>
    </div>
  )
}

export default BetSlipOffcanvas