import React from 'react'
// import '.././style.scss'
import '../../sportsAssets/scss/style.scss'
import AppFooter from '../../../components/AppFooter/AppFooter'
import highlightIcom from '../../sportsAssets/img/highlight.svg'
import scheduleIcon from '../../sportsAssets/img/schedule.svg'
import Highlight from './Highlight'
import { useState } from 'react'
import Schedule from './Schedule'
import BetSlipOffcanvas from '../../sportsComponents/betSlipOffcanvas/BetSlipOffcanvas'
import SubscriptionBettingCards from './SubscribtionBettingCards'


const SubscriptionsportsPage = () => {
  const [scheduleActive, setScheduleActive] = useState(false)
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="sportsPage">
            <div className="row">
              <div className="col-12">
                <div className="highLightBtns">

                  <SubscriptionBettingCards />
                  {/* <button 
                    onClick={()=> setScheduleActive(false)}
                    className={`highLightTabBtn rounded-pill text-uppercase ${!scheduleActive ? 'active' : ''}`}><img src={highlightIcom} alt="" /> highlights</button> */}
                  {/* <button 
                    onClick={()=> setScheduleActive(true)}
                    className={`highLightTabBtn rounded-pill text-uppercase ${scheduleActive ? 'active' : ''}`}><img src={scheduleIcon} alt="" /> schedule</button> */}
                </div>
              </div>
              <div className="col-12">
                {!scheduleActive ?
                  <Highlight /> :
                  <Schedule />
                }
              </div>
            </div>
            {/* <BetSlipOffcanvas/> */}
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  )
}

export default SubscriptionsportsPage