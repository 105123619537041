import React from 'react'
import user from '../../../fantasy/fantasyAssets/img/ban.png'
import TimeCard from '../../fantasyComponents/timeCard/TimeCard'
import { useSelector } from 'react-redux'

const StockLeaderboardTab = ({ stockContest, leaderboardData, type }) => {
  console.log("leaderboardData------->>>", leaderboardData)
  const fantasyUserData = useSelector(state => state.fantasyUserData)
  return (
    <>
      {
        new Date(stockContest?.start_date) > new Date() ? (
          <TimeCard start_date={stockContest?.start_date} />
        ) : (
          leaderboardData?.jointeams?.map(item => (
            < div className="row mx-0">
              <div class="col-12 mb-3  cursor-pointer stockDataUi pt-2 pb-0">
                <div class="row mx-0 contestCard">
                  <div class="col-12">
                    <div class="row align-items-center justify-content-between">
                      <div class="  col-auto d-flex align-items-center">
                        <div class="userImg">
                          <img src={user} alt='' />
                        </div>
                        <div className="userName ps-3">{item?.teamname ? item.teamname : item.team}</div>
                      </div>
                      <div class="col-auto">
                        <div class="entryPrz">{item?.entryfee}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 contestCardFooter mt-2 py-2">
                    <div class="row align-items-center">
                      <div class="col-6 d-flex align-items-center">
                        <div class="gurantyTxt me-2">Final Value:</div>
                        <div class="gurantyTxt fw-bold">{item?.finalvalue}</div>
                      </div>
                      <div class="col-6 d-flex align-items-center justify-content-end">
                        <div class="gurantyTxt">Won:</div>
                        <div class="gurantyTxt fw-bold">
                          {type == 'upcomming' ? ("") : (
                            122
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )
      }
    </>
  )
}

export default StockLeaderboardTab