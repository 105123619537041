import React from 'react'
import playerIcon from './../../fantasyAssets/img/ban.png'
import { useState, useEffect } from 'react';
import { getFantasyAuth } from '../../fantasyServices';

const MyRank = ({
  handleViewTeam,
  data,
  live,
  item,
  joinedMatches
}) => {
  const [leaders, setLeaders] = useState([]);

  const getLeader = async () => {
    try {
      const res = await getFantasyAuth(
        `myleaderboard?matchchallengeid=${data?.matchchallengeid}&matchkey=${data?.matchkey}`,
      );
      if (res.data.success) {
        setLeaders(res.data.data);
      }
    } catch (error) {

    }

  };

  useEffect(() => {
    getLeader();
  }, []);

  console.log(data)
  return (
    <>
      <div className="col-12 px-0">
        <div className="tableSectionMain">
          <table className="table tableSection  align-middle table-hover">
            <thead>
              <tr>
                <th scope="col">Team Name</th>
                <th scope="col" className='text-center'>Winning</th>
                <th scope="col" className='text-center'>Rank</th>
              </tr>
            </thead>
            <tbody>
              {
                leaders?.length ?
                  leaders?.map((item) => (
                    <tr>
                      <td>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <div className="userSection position-relative">
                              <div className="userImg rounded-circle overflow-hidden">
                                {!!item?.image ? (
                                  <img src={item?.image} alt="bsk" className="img-fluid w-100 h-100" />
                                ) : (
                                  <div />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col ps-0">
                            <div className="userName">{`${item?.team ? item?.team : item?.teamname}  ${item?.teamnumber ? `${item?.teamnumber}` : ''}`}</div>
                            <div className="userParcent"></div>
                          </div>
                        </div>
                      </td>
                      <td className='pointData text-center'>-</td>
                      <td><div className="d-flex align-items-center justify-content-center creditHeading"></div></td>
                    </tr>
                  )) :
                  <div className='text-center text-light' style={{ width: "100%" }}>No data</div>
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default MyRank