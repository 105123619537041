import React, { useState } from "react";
import Pagination from "../../Admincomponents/pagination/Pagination";
import { adminGetAPIAuth } from "../../AdminServices";
import { REPORTS, USER } from "../../AdminConstants/apiEndpoints";
import { errorToaster } from "../../../utils/toaster";
import { useEffect } from "react";
import { dateFormatter } from "../../AdminUtils/Utils";
import { useSelector } from "react-redux";
import AdminTxModal from "../../Admincomponents/AdminTxModal/AdminTxModal";
import { returnEndDate } from "../../../utils/returnEndDate";

const transactionArr = [
  {
    value: "",
    label: "All",
  },
  {
    value: "debit",
    label: "Deposit",
  },
  {
    value: "credit",
    label: "Withdraw",
  },
  // {
  //   value: "settlements-deposit",
  //   label: "Settlements Deposit",
  // },
  // {
  //   value: "settlements-withdraw",
  //   label: "Settlements Withdraw",
  // },
  // {
  //   value: "bet-settlements",
  //   label: "Bet Settlements",
  // },
  // {
  //   value: "rollback",
  //   label: "Rollback",
  // },
  // {
  //   value: "Voided",
  //   label: "voided",
  // },
];

const AdminAccountStatement = () => {
  const [tableData, setTableData] = useState([]);
  const [tableDataAllTx, setTableDataAllTx] = useState([]);
  const [txWith, setTxWith] = useState({});
  const [showTxModal,setShowTxModal]=useState(false)
  const [txDetails,setTxDetails]=useState({})

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const [childrenList, setChildrenList] = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const [selectedUser, setSelectUser] = useState({});
  const [searchedUserResult, setSearchedUserResult] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);
  const[userData, setUserData] = useState({});
  const [temporaryFiltered, setTemporaryFiltered] = useState([]);

  const [filterValues, setFilterValues] = useState({
    startDate: "",
    endDate: "",
  });
  const [txFilter,setTxFilter]=useState('')
  const [dateSort, setDateSort] = useState('')
  const adminData = useSelector((state) => state?.adminData);

  const handleChange = (e) => {
    setFilterValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

    // set today's date as end date
    useEffect(()=> {
      const today = new Date()
      setFilterValues((prev)=>({
        ...prev,
        endDate: returnEndDate(today)
      }))
    
    },[])

  const getChildrenList = async () => {
    try {
      const res = await adminGetAPIAuth(
        USER.children + "/" + adminData?._id + "?perPage=0"
      );
      if (res?.data?.status === 200) {
        setChildrenList(res?.data?.data?.data?.children);
      }
    } catch (error) {
      setChildrenList([]);
    }
  };

  useEffect(() => {
    if (adminData?._id) {
      getChildrenList();
      getUserDetails();
    }
   
  }, [adminData]);

  // useEffect(() => {
  //   if (searchUser) {
  //     const filtered = tableData.filter(
  //       (item) =>
  //         item.name.toLowerCase().includes(searchUser.toLowerCase()) ||
  //         item.username.toLowerCase().includes(searchUser.toLowerCase())
  //     );
  //     setSearchedUserResult(filtered);
  //   } else {
  //     setTemporaryFiltered([]);
  //     setSearchedUserResult([]);
  //     setSelectUser("");
  //   }
  //   //eslint-disable-next-line
  // }, [searchUser]);

  const handleGetList = async () => {
    const queryParams = Object.entries(filterValues)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    try {
      const res = await adminGetAPIAuth(
        REPORTS?.adminaccountstatement + `?page=${currentPage}&perPage=10&username=${searchUser}` + (queryParams?"&"+queryParams:"")
      );
      if (res?.data?.data?.success) {
        setTableData(res?.data?.data?.data);
        setTotalPages(res?.data?.data?.pagination?.totalPages)
      } else {
        errorToaster("Something went wrong in fetching data");
      }
    } catch (error) {
      errorToaster(error?.response?.data?.data?.message);
      setTableData([]);
      console.log("error -------------->", error);
    }
  };

  useEffect(() => {
    handleGetList();
    //eslint-disable-next-line
  }, [
    filterValues,
    selectedUser,
    filterValues.startDate,
    filterValues.endDate,
    currentPage
  ]);

  useEffect(()=>{
    if(txWith.name) handleGetTransactions()
    //eslint-disable-next-line
  },[txFilter, dateSort])

  const handleGetTransactions = async (data) => {
    const queryParams = Object.entries(filterValues)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    if(!txWith.name) setTxWith(data);
    try {
      const res = await adminGetAPIAuth(
        REPORTS?.myaccountstatement +
          "?page=1&perPage=100&id=" +
          (data?._id?data?._id:txWith._id+"&type="+txFilter) + (queryParams?"&"+queryParams:"") + `&dateSort=${dateSort}`
      );
      if (res?.data?.data?.success) {
        setTableDataAllTx(res?.data?.data?.data);
      } else {
        errorToaster("Something went wrong in fetching data");
      }
    } catch (error) {
      errorToaster(error?.response?.data?.data?.message);
      setTableDataAllTx([]);
      console.log("error -------------->", error);
    }
  };

  const handleShowAllTx = () => {
    setTxWith({});
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setInputFocused(false);
    }, 500);
  };

  useEffect(()=>{
    if (searchUser) {
      const filtered=childrenList.filter((item)=>item.name.toLowerCase().includes(searchUser.toLowerCase()) || item.username.toLowerCase().includes(searchUser.toLowerCase()))
      setSearchedUserResult(filtered)
    }else{
      setTemporaryFiltered([]);
      setSearchedUserResult([]);
      setSelectUser("");
    }
    //eslint-disable-next-line
  },[searchUser])

  const handleDateSort = ()=> {
    if(dateSort === '-1') {
      setDateSort('1')
    }else {
      setDateSort('-1')
    }
  }
  const getUserDetails = async () => {
    try {
      const res = await adminGetAPIAuth(`user/allaccountbox?userId=${adminData?._id}`)
      if (res?.data?.status === 200) {
        setUserData(res.data.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner ">
                <header className="page-title-bar justify-content-between row align-items-center mb-2">
                  <h1 className="page-title mb-0 text-nowrap px-0">
                      Admin Account Statement
                  </h1>
                </header>

                <div className="row g-3 mb-4">
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Total Debit</div>
                          <div className="adminTopCardSubtitle">{userData.totalDebits?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Total Credit</div>
                          <div className="adminTopCardSubtitle">{userData.totalCredits?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Total IN Wallet</div>
                          <div className="adminTopCardSubtitle">{userData.totalInWallet?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Total Loss</div>
                          <div className="adminTopCardSubtitle">{userData.totalLossPoints?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Total Profit</div>
                          <div className="adminTopCardSubtitle">{userData.totalProfitPoints?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="adminTopCard bg-white p-3">
                      <div className="d-flex align-items-center">
                        <div className="adminTopCardIcon d-flex align-items-center justify-content-center">
                          <img src="assets/img/present.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="adminTopCardTitle">Total Exposure</div>
                          <div className="adminTopCardSubtitle">{userData.totalExposer?.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                {txWith?.name && 
                  <header className="page-title-bar">
                    <div className="page-title pt-0">
                      <nav aria-label="breadcrumb text-nowrap">
                          <ol class="breadcrumb flex-nowrap overflow-auto pt-0 m-0">
                            <li class="breadcrumb-item cursor-pointer" onClick={handleShowAllTx}>
                              <div className="d-grid align-items-center">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M11.03 2.59a1.501 1.501 0 0 1 1.94 0l7.5 6.363a1.5 1.5 0 0 1 .53 1.144V19.5a1.5 1.5 0 0 1-1.5 1.5h-5.75a.75.75 0 0 1-.75-.75V14h-2v6.25a.75.75 0 0 1-.75.75H4.5A1.5 1.5 0 0 1 3 19.5v-9.403c0-.44.194-.859.53-1.144ZM12 3.734l-7.5 6.363V19.5h5v-6.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 .75.75v6.25h5v-9.403Z"></path></svg>
                              </div>
                            </li>
                            <li class="breadcrumb-item"> {txWith?.name} </li>
                        </ol>
                      </nav>
                    </div>
                  </header>
                }
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row align-items-end">
                      <div className="col-md-auto pe-md-0 mb-2 mb-md-0">
                        <div className="adminSearchInput">
                          <input
                            type="text"
                            placeholder="Search User"
                            name="searchUser"
                            value={searchUser}
                            onChange={(e) => setSearchUser(e.target.value)}
                            className="form-control adminFilterInp"
                            onFocus={() => setInputFocused(true)}
                            onBlur={handleInputBlur}
                          />
                          <div className="overflow-hidden">
                            {searchedUserResult?.length && inputFocused ? (
                              <ul className="list-unstyled gap-2 adminsearchDropDown overflow-Y-auto">
                                {searchedUserResult?.map((item) => (
                                  <li
                                    className="userName"
                                    onClick={() => {
                                      setTemporaryFiltered([item]);
                                      setSearchUser(item.username);
                                      setSelectUser(item);
                                      setSearchedUserResult([]);
                                      setTxWith({})
                                      setCurrentPage(1)
                                    }}
                                  >
                                    {item.username}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      {txWith.name && <div className="col-md-auto col-12 col-sm-4 pe-md-0 mb-2 mb-sm-0">
                        <label htmlFor="transactions" className="form-label">
                          Transactions
                        </label>
                        <select
                          name="transactions"
                          id="transactions"
                          onChange={(e)=>setTxFilter(e.target.value)}
                          className="form-select adminFilterInp"
                        >
                          {transactionArr.map((item) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                        </select>
                      </div>}
                      <div className="col-md">
                        <div className="row justify-content-end mx-md-0">
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="startDate" className="form-label">
                              From
                            </label>
                            <input
                              type="date"
                              value={filterValues.startDate}
                              id="startDate"
                              name="startDate"
                              className="form-control adminFilterInp"
                              onChange={handleChange}
                              disabled={txWith.name?true:false}
                            />
                          </div>
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="endDate" className="form-label">
                              To
                            </label>
                            <input
                              type="date"
                              value={filterValues.endDate}
                              id="endDate"
                              name="endDate"
                              className="form-control adminFilterInp"
                              onChange={handleChange}
                              disabled={txWith.name?true:false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="table-responsive">
                      {!txWith?.name ? (
                        <table className="table">
                          <thead className="text-uppercase text-nowrap">
                            <tr>
                              <th>
                                <div className="d-flex">Username</div>
                              </th>
                              <th>
                                <div className="d-flex">Open Balance</div>
                              </th>
                              <th>
                                <div className="d-flex">Closed Balance</div>
                              </th>
                              <th>
                                <div className="d-flex">CR</div>
                              </th>
                              <th>
                                <div className="d-flex">Transaction Count</div>
                              </th>
                            </tr>
                          </thead>
                          {tableData.length ? 
                          <tbody>
                            {[
                              temporaryFiltered?.length
                                ? [...temporaryFiltered]
                                : [...tableData],
                            ]?.[0].map((item) => (
                              <tr>
                                <td
                                  className=" fw-bold text-decoration-none"
                                  onClick={() => handleGetTransactions(item)}
                                >
                                  {item?.username}
                                </td>
                                <td>{item?.openingBal?.toFixed(2)}</td>
                                <td>{item?.points?.toFixed(2)}</td>
                                <td>{item?.openingBal?.toFixed(2)}</td>
                                {/* <td>
                                  {(item?.totalTransactions?.totalCredit -
                                    item?.totalTransactions?.totalDebit)?.toFixed(2)}
                                </td> */}
                                <td>{item?.transactionCount}</td>
                              </tr>
                            ))}
                          </tbody>
                          : <tr>
                              <td colSpan={10} className="text-center" style={{fontSize: 16, fontWeight: 500}}>Admin account statement not found</td>
                            </tr>  
                          }
                        </table>
                      ) : (
                        <table className="table">
                          <thead className="text-uppercase text-nowrap">
                            <tr>
                              <th>
                                <div className="d-flex" onClick={handleDateSort}>Date & Time
                                <span className="ms-1">
                                  {dateSort === '-1' ?
                                  <i className="bi bi-arrow-down"></i>
                                  :<i className="bi bi-arrow-up"></i>}
                                </span>
                                </div>
                              </th>
                              <th>
                                <div className="d-flex">TX Type</div>
                              </th>
                              {/* <th>
                                <div className="d-flex">Event</div>
                              </th>
                              <th>
                                <div className="d-flex">Market</div>
                              </th> */}
                              <th>
                                <div className="d-flex">Credit / Debit</div>
                              </th>
                              <th>
                                <div className="d-flex">balance</div>
                              </th>
                              <th>
                                <div className="d-flex">Transaction ID</div>
                              </th>
                              <th>
                                <div className="d-flex">Txn Count</div>
                              </th>
                            </tr>
                          </thead>
                          {tableDataAllTx.length ? 
                          <tbody>
                            {tableDataAllTx.map((item) => (
                              <tr>
                                <td>{dateFormatter(item?.createdAt, true)}</td>
                                <td className="fw-bold cursor-pointer" onClick={()=>{
                                  setTxDetails(item)
                                  setShowTxModal(true)
                                  }} >{item?.transactionType}</td>
                                {/* <td></td>
                                <td></td> */}
                              <td className={`${!item?.isDeposit ? 'admin-forms-error-text' : 'admin-forms-success-text'}`}>
                                      {item?.points ? item?.points?.toFixed(2) : 0.00}
                                    </td>
                                <td>{item?.totalPoints?.toFixed(2)}</td>
                                <td>BetApp-{item?._id}</td>
                                <td>1</td>
                              </tr>
                            ))}
                          </tbody>
                           : <tr>
                           <td colSpan={10} className="text-center" style={{fontSize: 16, fontWeight: 500}}>Account statement not found</td>
                         </tr> 
                         } 
                        </table>
                      )}
                    </div>
                  </div>
                  {
                    totalPages > 1 ?
                    <Pagination
                      totalPages={totalPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    /> : ''
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <AdminTxModal 
      show={showTxModal}
      setShow={setShowTxModal}
      data={txDetails}
      />
    </>
  );
};

export default AdminAccountStatement;
