import React from "react";

const InGameModal = ({game}) => {
    return (
        <>
            {
                !game && (
                <div className="playwithBalance mx-auto rounded-3 p-md-4 p-sm-3 p-2 position-absolute ">
                    <div className="row">
                    <div className="col-12 d-flex flex-column justify-content-center">
                        <div className="oops mx-auto ">
                            <img src="assets/img/affiliateDashboard/oops.png" style={{ height: 100 }} alt />
                        </div>
                        <div className="oppsTxt text-light text-center mt-5">Oops! Something went wrong!</div>
                    </div>
                </div>
                </div>
                )
            }
        </>
        
    )
}

export default InGameModal;
